import { API } from '../commons';
import { MAP_APIS_ENDPOINTS, setEndpointParams } from './EndPoints';
import { removeElementsFromJson } from '../commons/utils';
import { MapGlobalVars } from '../pages/PriceMap/support/PriceMapConsts';
import { EventBus, EVENT_NAMES } from '../commons/EventBus';
import { User_PropertyReportChosen } from '../pages/PriceMap/PropertyCalculator/dataModel/User_PropertyReportChosen';
import { getIndoorQuestionValue } from '../pages/PriceMap/support/common';
import { getKVEntity } from './userCenter';
import { UserCenterEntityType } from '../models/backend/userCenter';

export function api_getSoldProperties(mapCorner_period: any) {
    EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_MAP_PROGRESSBAR, { show: true });
    return new Promise((resolve, reject) => {
        API.post(null, MAP_APIS_ENDPOINTS.GET_SOLDPROPERTIES_IN_MAPVIEW, mapCorner_period, {}).then(
            (result) => {
                resolve(result);
                EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_MAP_PROGRESSBAR, { show: false });
            },
            (err) => {
                resolve([null]);
                EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_MAP_PROGRESSBAR, { show: false });
            },
        );
    });
}

export function api_getProjectSolds(projectId: string, interval: number) {
    const endPoint = setEndpointParams(MAP_APIS_ENDPOINTS.GET_PROJECT_SOLDS, {
        projectId: projectId,
        interval: interval,
    });
    return new Promise((resolve, reject) => {
        API.get(null, endPoint).then(
            (result: any) => {
                resolve(result);
            },
            (err) => {
                resolve([]);
            },
        );
    });
}

export function api_getPropertyFeatures(propId: string) {
    return new Promise((resolve, reject) => {
        API.get(null, MAP_APIS_ENDPOINTS.GET_PROPERTY_DETAILS + propId).then(
            (returnedProperty) => {
                console.log(JSON.stringify(returnedProperty));
                if (returnedProperty && !returnedProperty[0].smart_tags) {
                    returnedProperty[0].noTagMarked = true;
                    returnedProperty[0].smart_tags = [
                        {
                            id: 0,
                            feature: 'storey_class',
                            selected: 0,
                            tag_name: 'Multi-storey',
                        },
                        {
                            id: 2,
                            feature: 'pool_class',
                            selected: 0,
                            tag_name: 'Swimming pool',
                        },
                        {
                            id: 3,
                            feature: 'decor_level',
                            selected: 0,
                            tag_name: 'Need major renovation',
                        },
                        {
                            id: 3,
                            feature: 'decor_level',
                            selected: 0,
                            tag_name: 'Impressive decor',
                        },
                        {
                            id: 4,
                            feature: 'light_class',
                            selected: 0,
                            tag_name: 'Lack of natural lights',
                        },
                        {
                            id: 5,
                            feature: 'brick_class',
                            selected: 0,
                            tag_name: 'Weatherboard house',
                        },
                        {
                            id: 6,
                            feature: 'new_class',
                            selected: 0,
                            tag_name: 'Recently built',
                        },
                        {
                            id: 7,
                            feature: 'tennis_class',
                            selected: 0,
                            tag_name: 'Tennis court',
                        },
                        {
                            id: 8,
                            feature: 'outdoor_class',
                            selected: 0,
                            tag_name: 'Well designed outdoor space',
                        },
                        {
                            id: 9,
                            feature: 'connection_class',
                            selected: 0,
                            tag_name: 'Indoor-outdoor connected living',
                        },
                        {
                            id: 10,
                            feature: 'grannyflat_class',
                            selected: 0,
                            tag_name: 'Granny flat',
                        },
                        {
                            id: 11,
                            feature: 'layout_class',
                            selected: 1,
                            tag_name: 'Reasonable floor plan',
                        },
                        {
                            id: 12,
                            feature: 'attic_class',
                            selected: 0,
                            tag_name: 'Bedroom(s) on attic floor',
                        },
                        {
                            id: 13,
                            area: 80,
                            feature: 'indoor_space',
                            tag_name: 'Indoor space',
                        },
                    ];
                }
                resolve(returnedProperty);
            },
            (err) => {
                resolve([null]);
            },
        );
    });
}

export function api_getPropertiesOnMapView(topRight, bottomLeft) {
    return new Promise((resolve, reject) => {
        const postData = {
            topRight: topRight,
            bottomLeft: bottomLeft,
        };
        API.post(null, MAP_APIS_ENDPOINTS.GET_ALL_PROPERTIES_MAP_VIEW, postData).then(
            (returnResult) => {
                resolve(returnResult);
            },
            (err) => {
                resolve([]);
            },
        );
    });
}

export async function api_crossAddressEstimatePropertyValue(prop1Features, prop2Features, byWhichAddress) {
    let propFeaturesForEstimate = null;
    if ((byWhichAddress = '1')) {
        propFeaturesForEstimate = prop2Features;
        propFeaturesForEstimate.land_value_reset = prop1Features.land_value_reset;
        propFeaturesForEstimate.x_wynyard = prop1Features.x_wynyard;
        propFeaturesForEstimate.y_wynyard = prop1Features.y_wynyard;
        propFeaturesForEstimate.prop_zoning = prop1Features.prop_zoning;
        propFeaturesForEstimate.region = prop1Features.region;
        propFeaturesForEstimate.sec_pri_lv_time = prop1Features.sec_pri_lv_time;
        propFeaturesForEstimate.sec_pri_sold_time = prop1Features.sec_pri_sold_time;
    } else {
        propFeaturesForEstimate = prop1Features;
        propFeaturesForEstimate.land_value_reset = prop2Features.land_value_reset;
        propFeaturesForEstimate.x_wynyard = prop2Features.x_wynyard;
        propFeaturesForEstimate.y_wynyard = prop2Features.y_wynyard;
        propFeaturesForEstimate.prop_zoning = prop2Features.prop_zoning;
        propFeaturesForEstimate.region = prop2Features.region;
        propFeaturesForEstimate.sec_pri_lv_time = prop2Features.sec_pri_lv_time;
        propFeaturesForEstimate.sec_pri_sold_time = prop2Features.sec_pri_sold_time;
    }

    return api_estimatePropertyValue(propFeaturesForEstimate);
}

export async function api_estimatePropertyValue(propFeatures, spaceParam?) {
    console.log('space param is ' + spaceParam);
    let estimateParams = '';
    Object.keys(propFeatures).forEach((key) => {
        const featureValue = propFeatures[key];

        if (typeof featureValue != 'undefined') {
            if (!Array.isArray(featureValue)) {
                estimateParams += key + '=' + featureValue + '&';
            }
        }
    });
    if (spaceParam) {
        estimateParams += spaceParam;
    }

    const inDoorSpaceTag = propFeatures.smart_tags.filter((loopTag) => {
        return loopTag.feature == 'indoor_space';
    });

    let space_scoreParam = '';
    if (inDoorSpaceTag) {
        const indoorQuestion = getIndoorQuestionValue(
            propFeatures.region,
            propFeatures.prop_bed,
            propFeatures.prop_bath,
        );

        if (indoorQuestion) {
            if (inDoorSpaceTag.area < indoorQuestion.question_a) {
                space_scoreParam = 'space_score=1';
            }
            if (inDoorSpaceTag.area < indoorQuestion.question_c) {
                space_scoreParam = 'space_score=2';
            }
            if (inDoorSpaceTag.area >= indoorQuestion.question_a && inDoorSpaceTag.area <= indoorQuestion.question_c) {
                space_scoreParam = 'space_score=0';
            }
        }
    }

    estimateParams += space_scoreParam;
    console.log(estimateParams);
    const postURL = setEndpointParams(MAP_APIS_ENDPOINTS.ESTIMATE_PROPERTY_VALUE, { allFeatureParams: estimateParams });

    const estimateResult = await API.post(
        null,
        postURL,
        propFeatures.smart_tags.filter((loopTag) => {
            return loopTag.feature != 'indoor_space';
        }),
    );
    return estimateResult;
}

export async function api_querySuburbs(suburbKey) {
    return new Promise((resolve, reject) => {
        const endPoint = setEndpointParams(MAP_APIS_ENDPOINTS.SEARCH_LOCALITY_BY_STRING, {
            queryKey: suburbKey,
        });

        API.get(null, endPoint).then(
            (suburbLists: any) => {
                resolve(suburbLists);
            },
            (err) => {
                reject(err);
            },
        );
    });
}

export async function api_getAroundTranscations(
    prop_type,
    center_lat,
    center_lon,
    interval,
    prop_id,
    distance?,
    reportHouseValue?,
    bed?,
    car?,
    bath?,
    sort_by?,
) {
    return new Promise((resolve, reject) => {
        const endPoints = setEndpointParams(MAP_APIS_ENDPOINTS.GET_AROUND_SOLD_PROPERTIES, {
            distance: distance || 0.5,
            lon: center_lon,
            lat: center_lat,
            interval: interval,
            prop_type: prop_type,
            prop_id: prop_id,
            reportHouseValue: reportHouseValue || 0,
            prop_bed: bed || 0,
            prop_car: car || 0,
            prop_bath: bath || 0,
            start: 0,
            end: 9999,
            sort_by: sort_by,
        });
        console.log(endPoints);

        API.get(null, endPoints).then(
            (returnedTrans: any) => {
                resolve(returnedTrans);
            },
            (err) => {
                reject(err);
            },
        );
    });
}

export async function api_saveUserReportChosen(reportChosen: User_PropertyReportChosen) {}

export async function api_getUserReportChosen(propId) {}

export async function api_getReportLink(propId: string) {
    const savedReportEntity = await getKVEntity(UserCenterEntityType.PropertyReportAddtion, propId);

    return (
        'HouseReport?reportPropId=' + propId + '&estimateInfo=' + JSON.stringify(JSON.parse(savedReportEntity?.content))
    );
}

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { rst } from 'rt-state';
import { TR } from '../../../commons';

export interface NewQuestionButtonProps {
    onClick: () => void;
    label: string;
    isClicked: boolean;
}
const useStyles = makeStyles((theme) => ({
    button: {
        margin: '10px 0px',
        background: 'rgb(249 249 249)',
        width: '146px',
        height: '42px',
        borderRadius: '21px',
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            background: 'rgb(249 249 249)',
            width: '90px',
            height: '20px',
            color: '#009E90',
        },
        '& h3': {
            fontSize: '18px',
            color: 'rgb(103 103 103)',
            fontWeight: 500,
            cursor: 'pointer',
            margin: '0 auto',
            '&:hover': {
                color: '#009E90',
            },
            position: 'relative',
            top: '-1px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px',
                lineHeight: '15px',
            },
        },
    },
    buttonClicked: {
        margin: '10px 0px',
        background: '#009E90',
        width: '146px',
        height: '42px',
        borderRadius: '4px',
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            background: 'rgb(249 249 249)',
            width: '90px',
            height: '20px',
            color: '#009E90',
        },

        '& h3': {
            fontSize: '18px',
            color: 'rgb(215 237 235)',
            fontWeight: 600,
            cursor: 'pointer',
            margin: '0 auto',
            position: 'relative',
            top: '-1px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px',
                lineHeight: '15px',
                color: '#009E90',
            },
        },
    },
}));

export const NewQuestionButton = rst.create<NewQuestionButtonProps>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const { label, isClicked, onClick } = props;
        if (isClicked) {
            return (
                <button className={classes.buttonClicked} onClick={onClick}>
                    <h3>
                        <TR name={label} />
                    </h3>
                </button>
            );
        } else {
            return (
                <button className={classes.button} onClick={onClick}>
                    <h3>
                        <TR name={label} />
                    </h3>
                </button>
            );
        }
    };
});

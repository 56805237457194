/* eslint-disable prettier/prettier */
import { Button, CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { SecurityProvider, TR, TRHtml } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { IntervalChoose } from './NearByTranscationsChart';
import {
  convertToM,
  isMobileDevice,
  numToMillion,
  reverseToD,
  sortJsonArrayByAttr,
  sortNumArray,
} from '../../../commons/utils';
import { api_getAroundTranscations } from '../../../services/property';
import { MapGlobalVars } from '../support/PriceMapConsts';

import { PropertyIcon } from '../MapContainer/PropertyIcon';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ProeprtyPeriodDropdown } from '../Layers/PropertyPeriodChooser';
import { GlossaryHelp } from '../components/GlossaryHelp';
import { Permission } from '../../../commons/security/authz';
import { HelpComponent } from '../components/HelpComponent';

const useStyles = makeStyles((theme) => {
  return {
    nearTranDiv: {
      marginTop: isMobileDevice() ? 0 : 20,
      height: isMobileDevice() ? 145 : '180px',
      position: 'relative',
      paddingRight: 10,
    },
    title: {
      fontSize: 16,
      color: '#333333',
      fontWeight: 500,
    },
    barItem: {
      display: 'flex',
      marginTop: 8,
    },
    barTitle: {
      fontSize: isMobileDevice() ? '10px' : '12px',
      fontWeight: 400,

      color: '#666666',
      width: isMobileDevice() ? '50px' : '60px',
      display: 'flex',
      alignItems: 'center',
    },
    barArea: {
      flexGrow: 1,
      height: isMobileDevice() ? 16 : 25,
      borderRadius: 8,
      background: '#FCFAF5',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    barPriceRange: {
      position: 'absolute',
      top: 0,
      fontSize: isMobileDevice() ? 10 : 12,
      fontWeight: 400,
      color: ' #999999;',
      height: isMobileDevice() ? 16 : 25,
    },
    loading: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'black',
      opacity: 0.8,
      zIndex: 2,
    },
    bedBar: {
      background: '#FBF0C4',
      borderRadius: 8,
      position: 'absolute',
      top: 0,
      paddingLeft: 10,
      paddingRight: 10,
      height: isMobileDevice() ? 16 : 25,
      display: 'flex',
      alignItems: 'center',
    },
    bedPoint: {
      background: '#FFFFFF',
      width: 8,
      borderRadius: '50%',
      height: 8,
      position: 'absolute',
      cursor: 'pointer',
    },
    estimateValueBar: {
      position: 'absolute',

      top: '-5px',
      height: isMobileDevice() ? '75px' : '100px',
      width: '5px',
      background: '#FF9F00',

      zIndex: 500,
      borderRadius: '3px',
    },
    estimateValueLabel: {
      position: 'absolute',

      top: isMobileDevice() ? '75px' : '100px',

      fontSize: isMobileDevice() ? '10px' : '12px',
      color: '#666666',

      zIndex: 500,
      display: 'flex',
      alignItems: 'center',
    },

    estimateValue: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 60,
      height: 34,
    },
    estimateValueFrame: {
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: -1,
    },
    estimateValueNumber: {
      fontSize: 16,
      color: '#ffffff',
      fontWeight: 700,
    },
  };
});

export const NearByTranscationsSimpleChart = rst.create<{ prop: any; showForReport?: boolean; changeChartEvt?: any }>(
  (ctx) => {
    const securityProvider = SecurityProvider.use();
    const choosedInterval = rst.stateS(12);
    const state = rst.state({
      bedsData: [[], [], []],
      estimateValue: 0,
      estimateConf: '',
      soldInMonth: false,
      trans: null,
      loadingTrans: false,
      showAroundPoints: MapGlobalVars.showAroundTransCircle,
      expensive: false,
      showingBed: false,
      bedIdx: 0
    });
    EventBus.on(EVENT_NAMES.EVENT_ESTIMAGE_VALUE_CHANGE, (estimateValue) => {
      console.log('now what is the estimate value ===>' + estimateValue.middle);
      console.log(estimateValue);
      state.estimateValue = estimateValue.middle;
      state.estimateConf = estimateValue.conf;
      state.soldInMonth = estimateValue.inMonth;
      state.expensive = estimateValue.expensive;
    });

    EventBus.on(EVENT_NAMES.EVENT_SYNC_INTERVAL_BETWEEN_2_CHARTS, (interval) => {
      choosedInterval.value = interval;
      console.log('so now state interval is ' + choosedInterval.value);
      getAroundTrans(choosedInterval.value);
      choosedInterval.forceUpdate();
    });

    EventBus.on(MapGlobalVars.nearByTransEventName, async (evt) => {
      console.log(ctx.props.prop);
      if (evt.removeAroundTrans) {
        switchAroundPointsOnMap('N');
      }
      if (evt.chart == 0 || evt.chart == -1 || evt.chart == 3) {
        console.log('calling api to....');
        await getAroundTrans(choosedInterval.value);
        if (evt.chart != 3) {
          switchAroundPointsOnMap('Y', evt);
        }
      }
    });

    const getTransByInterval = async (interval) => {
      choosedInterval.value = interval;
      await getAroundTrans(interval);
      console.log(state.trans);
      if (state.showAroundPoints) {
        EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, {
          property: ctx.props.prop,
          aroundTrans: state.trans,
          propType: 'House',
          showAroundTrans: true,
        });
      }
      EventBus.dispatch(EVENT_NAMES.EVENT_SYNC_INTERVAL_BETWEEN_2_CHARTS, interval);
    };

    const switchAroundPointsOnMap = (show?, evt?) => {
      if (show == 'N') {
        state.showAroundPoints = false;
        EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, {
          showAroundTrans: false,
        });
      } else {
        if (evt && evt.chart == -1) {
          state.showAroundPoints = true;
        } else {
          state.showAroundPoints = !state.showAroundPoints;
        }

        EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, {
          property: ctx.props.prop,
          aroundTrans: state.trans,
          propType: 'House',
          showAroundTrans: state.showAroundPoints,
        });
        MapGlobalVars.showAroundTransCircle = state.showAroundPoints;
      }
    };
    const dotClick = (dot_propId) => {
      // if (!MapGlobalVars.showAroundTransCircle) {
      //     state.showAroundPoints = true;
      //     EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, {
      //         property: ctx.props.prop,
      //         aroundTrans: state.trans,
      //         propType: 'House',
      //         showAroundTrans: true,
      //     });
      // }
      // setTimeout(() => {
      //     EventBus.dispatch(EVENT_NAMES.EVENT_NEAR_BY_TRANS_AXIS_POINT_CLICKED, { propId: dot_propId });
      // }, 200);
    };

    const barClick = (barIdx) => {
      state.showAroundPoints = true;
      if (state.bedIdx == barIdx) {
        state.showingBed = !state.showingBed;
      }
      state.bedIdx = barIdx;

      if (state.bedsData[barIdx] && state.bedsData[barIdx].length == 0) {
        EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
          show: true,
          title: 'ERROR',
          content: 'NO_AROUND_TRANS',
        });
      }
      EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, {
        property: ctx.props.prop,
        aroundTrans: state.showingBed ? state.bedsData[barIdx] : state.trans,
        propType: 'House',
        showAroundTrans: true,
      });
    };

    function getAroundTrans(interval?, distance?) {
      state.loadingTrans = true;
      return new Promise((resolve, reject) => {
        api_getAroundTranscations(
          'House',
          ctx.props.prop.lat,
          ctx.props.prop.lon,
          interval || 12,
          '',
          distance,
        ).then(
          (trans: any) => {
            console.log(trans);
            console.log(state.bedsData);
            state.trans = trans;
            var bed_3 = [];
            var bed_4 = [];
            var bed_5 = [];

            if (trans && trans.length > 0) {
              try {
                trans.forEach((loopTran) => {
                  if (loopTran.prop_bed == 3) {
                    bed_3.push(loopTran);
                  }
                  if (loopTran.prop_bed == 4) {
                    bed_4.push(loopTran);
                  }
                  if (loopTran.prop_bed == 5) {
                    bed_5.push(loopTran);
                  }
                });
                bed_3 = sortJsonArrayByAttr(bed_3, 'sold_price', false, 'ASC');

                bed_4 = sortJsonArrayByAttr(bed_4, 'sold_price', false, 'ASC');
                bed_5 = sortJsonArrayByAttr(bed_5, 'sold_price', false, 'ASC');

                state.bedsData = [bed_3, bed_4, bed_5];

                console.log(state.bedsData);
              } catch (e) { }
            }
            state.loadingTrans = false;
            resolve(true);
          },
          (err) => {
            state.loadingTrans = false;
          },
        );
      });
    }

    return (props) => {
      const classes = useStyles();
      return (
        <div className={classes.nearTranDiv}>
          <div style={{ display: 'flex' }}>
            {isMobileDevice() ? null : (
              <div className={classes.title}>
                <TRHtml
                  html={isMobileDevice() ? 'NEAR_TRANS_TITLE' : 'NEAR_TRANS_TITLE2'}
                  params={{ period: choosedInterval.value }}></TRHtml>
                <span style={{ fontSize: '10px', color: '#999999' }}>
                  {' '}
                                    (<TR name="AROUND_500m"></TR>)
                </span>
              </div>
            )}
            {!isMobileDevice() && !props.showForReport ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                <Button
                  variant={'outlined'}
                  color="primary"
                  onClick={() => {
                    props.changeChartEvt(0);
                  }}
                  value="left">
                  <TR name="SWITCH_TO_TIME_CHART"></TR>
                </Button>

                <div style={{ marginLeft: 5 }}></div>

                <Button
                  variant={state.showAroundPoints ? 'outlined' : 'outlined'}
                  // variant={state.showAroundPoints ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={switchAroundPointsOnMap}
                  value="left">
                  <TR name={state.showAroundPoints ? 'showOnMap2' : 'showOnMap'}></TR>
                </Button>
              </div>
            ) : null}
          </div>

          <div
            style={{
              display: 'flex',
              marginTop: isMobileDevice() ? 0 : 7,
              justifyContent: 'flex-start',
              flexGrow: 1,
              alignItems: 'center',
            }}>
            {props.showForReport ? null : isMobileDevice() ? (
              <>
                <div className={classes.title}>
                  <TRHtml html="NEARBY_SOLD_500" params={{ period: choosedInterval.value }}></TRHtml>
                </div>
                <div style={{ marginLeft: 10 }}></div>
                <ProeprtyPeriodDropdown changeInterval={getTransByInterval}></ProeprtyPeriodDropdown>
              </>
            ) : (
              <IntervalChoose
                choosed={choosedInterval.value}
                changeIntervalEvt={getTransByInterval}></IntervalChoose>
            )}

            {!isMobileDevice() || props.showForReport ? null : (
              <div style={{ marginLeft:'20px', display: 'flex', flexDirection:'column' }}>
                <Button
                  variant={'outlined'}
                  color="primary"
                  onClick={() => {
                    props.changeChartEvt(0);
                  }}
                  style={{height: '30px'}}
                  value="left">
                  <TR name="SWITCH_TO_TIME_CHART"></TR>
                </Button>

                <Button
                  variant={state.showAroundPoints ? 'outlined' : 'outlined'}
                  color="primary"
                  onClick={switchAroundPointsOnMap}
                  value="left"
                  style={{ padding: 0 ,marginTop : 10,height: '30px'}}>
                  <TR name={state.showAroundPoints ? 'showOnMap2' : 'showOnMap'}></TR>
                </Button>
              </div>
            )}
          </div>
          {!state.loadingTrans ? (
            <BedBars
              barClickEvt={barClick}
              dotClickEvt={dotClick}
              bedsData={state.bedsData}
              estimateConf={state.estimateConf}
              estimateValue={state.estimateValue}
              soldInMonth={state.soldInMonth}
              expensive={state.expensive}
              showForReport={props.showForReport}></BedBars>
          ) : (
            <div className={classes.loading}>
              <CircularProgress color="primary" />
            </div>
          )}
        </div>
      );
    };
  },
);
const BedBars = rst.create<{
    bedsData;
    estimateConf;
    soldInMonth;
    estimateValue;
    dotClickEvt;
    barClickEvt?;
    showForReport?;
    expensive?;
}>((ctx) => {
  const securityProvider = SecurityProvider.use();
  const state = rst.state({
    barWidth: 0,
    priceEachPX: 0,
    isInit: true,
  });
  console.log(state.isInit);
  const minPrice = 0;
  const maxPrice = 0;

  function getPriceRange() {
    var tempMinPriceArray = [];
    if (ctx.props.bedsData[0] && ctx.props.bedsData[0].length > 0) {
      tempMinPriceArray.push(ctx.props.bedsData[0][0].sold_price);
    }
    if (ctx.props.bedsData[1] && ctx.props.bedsData[1].length > 0) {
      tempMinPriceArray.push(ctx.props.bedsData[1][0].sold_price);
    }
    if (ctx.props.bedsData[2] && ctx.props.bedsData[2].length > 0) {
      tempMinPriceArray.push(ctx.props.bedsData[2][0].sold_price);
    }
    tempMinPriceArray.push(ctx.props.estimateValue);
    tempMinPriceArray = sortNumArray(tempMinPriceArray);

    var tempMaxPriceArray = [];
    if (ctx.props.bedsData[0] && ctx.props.bedsData[0].length > 0) {
      tempMaxPriceArray.push(ctx.props.bedsData[0][ctx.props.bedsData[0].length - 1].sold_price);
    }
    if (ctx.props.bedsData[1] && ctx.props.bedsData[1].length > 0) {
      tempMaxPriceArray.push(ctx.props.bedsData[1][ctx.props.bedsData[1].length - 1].sold_price);
    }
    if (ctx.props.bedsData[2] && ctx.props.bedsData[2].length > 0) {
      tempMaxPriceArray.push(ctx.props.bedsData[2][ctx.props.bedsData[2].length - 1].sold_price);
    }
    tempMaxPriceArray.push(ctx.props.estimateValue);
    tempMaxPriceArray = sortNumArray(tempMaxPriceArray);

    return {
      min: reverseToD(Math.floor(convertToM(tempMinPriceArray[0]))) - 500000,
      max: reverseToD(Math.ceil(convertToM(tempMaxPriceArray[tempMaxPriceArray.length - 1]))) + 500000,
    };
  }

  function setBedBar(barElement, barData, priceRange, priceEachPX) {
    var bedBarEl = document.getElementById(barElement);
    if (bedBarEl) {
      bedBarEl.style.left = ((barData[0] - priceRange.min) / priceEachPX).toString() + 'px';
      console.log(bedBarEl.style.left);
    }
  }

  return (props) => {
    setTimeout(() => {
      var barWidth = document.getElementById('bedArea')?.clientWidth;
      if (!barWidth || barWidth == 0) {
        barWidth = 360;
      }
      state.priceEachPX = barWidth / (priceRange.max - priceRange.min);
    }, 100);

    const { bedsData, estimateValue } = props;
    const priceRange = getPriceRange();

    const classes = useStyles();

    const setBedbarPosition = (bedData, beds?) => {
      if (!isFinite(state.priceEachPX)) {
        return;
      }
      if (bedData && bedData.length > 0) {
        console.log(bedData);
        const barLeft = (bedData[0].sold_price - priceRange.min) * state.priceEachPX - 8;
        const barRight = (bedData[bedData.length - 1].sold_price - priceRange.min) * state.priceEachPX;
        console.log(barLeft + ';' + barRight);
        const barWidth = barRight - barLeft + 16;
        return (
          <>
            <div
              className={classes.bedBar}
              style={{
                width: barWidth,
                left: barLeft,
                background: beds == 4 ? 'linear-gradient(270deg,#fddc54 79%, #f9ad5f 100%)' : '',
              }}></div>
            <div className={classes.barPriceRange} style={{ left: barLeft - 30 }}>
              {convertToM(bedData[0].sold_price)}
            </div>
            {bedData.length > 1 ? (
              <div className={classes.barPriceRange} style={{ left: barLeft + barWidth + 5 }}>
                {convertToM(bedData[bedData.length - 1].sold_price)}
              </div>
            ) : null}

            {bedData.map((loopPoint) => {
              return (
                <div
                  className={classes.bedPoint}
                  onClick={() => props.dotClickEvt(loopPoint.prop_id)}
                  style={{
                    left: (loopPoint.sold_price - priceRange.min) * state.priceEachPX + 'px',
                  }}></div>
              );
            })}
          </>
        );
      } else {
        return <></>;
      }
    };
    EventBus.on(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE_ISCLICKED, () => {
      state.isInit = false;
      console.log(state.isInit);
    });

    return (
      <div style={{ position: 'relative' }}>
        {securityProvider.hasPermission(Permission.PROPERTY_BUYER) ||
                    securityProvider.hasPermission(Permission.TRIAL) ? (
            <>
              {props.expensive ? null : (
                <div
                  className={classes.estimateValueBar}
                  style={{
                    left: (props.estimateValue - priceRange.min) * state.priceEachPX + 50 + 'px',
                  }}></div>
              )}

              <div
                className={classes.estimateValueLabel}
                style={{
                  left:
                                    (props.estimateValue - priceRange.min) * state.priceEachPX -
                                    (MapGlobalVars.currentUserLan == 'zh'
                                      ? isMobileDevice()
                                        ? props.showForReport
                                          ? 15
                                          : 33
                                        : props.showForReport
                                          ? 20
                                          : 34
                                      : isMobileDevice()
                                        ? props.showForReport
                                          ? 35
                                          : 70
                                        : props.showForReport
                                          ? 40
                                          : 90) +
                                    'px',
                }}>
                {props.expensive ? null : !props.soldInMonth ? (
                  props.showForReport ? (
                    <div style={{ display: 'flex' }}>
                      <TR name="ESTIMATE_MEDIAN_PRICE"></TR>
                      <GlossaryHelp glossaryId="08" />
                    </div>
                  ) : state.isInit ? (
                    <div style={{ display: 'flex' }}>
                      <TR name="Inaccurate_estimation" />
                    </div>
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <TR name="ESTIMATE_MEDIAN_PRICE"></TR>
                      <GlossaryHelp glossaryId="08" />
                    </div>
                  )
                ) : (
                  <div style={{ display: 'flex' }}>
                    <TR name="SOLD_PRICE"></TR>
                    <GlossaryHelp glossaryId="08" />
                  </div>
                )}
                <div style={{ marginLeft: '5px' }}></div>
                <div className={classes.estimateValue}>
                  <img
                    src="assets/icons/frame.png"
                    width="60px"
                    height="34px"
                    className={classes.estimateValueFrame}></img>
                  <div
                    className={classes.estimateValueNumber}
                    style={{
                      textDecoration: !props.soldInMonth
                        ? props.showForReport
                          ? 'none'
                          : state.isInit && !props.expensive
                            ? 'line-through'
                            : 'none'
                        : 'none',
                    }}>
                    {props.expensive ? (
                      <div style={{ display: 'flex' }}>
                        <TR name="VALUE_GREATER_THAN_5M"></TR>{' '}
                        <HelpComponent content={'EXPENSIVE_EXPLAIN'} maxWidth="xs" />
                      </div>
                    ) : (
                      convertToM(props.estimateValue)
                    )}
                  </div>
                </div>
                {props.expensive ? null : props.estimateConf == 'low' ? (
                  <div>
                                    (<TR name={'CONFIDENCE_LOW'}></TR>)
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        <div
          className={classes.barItem}
          onClick={() => {
            props.barClickEvt(0);
          }}>
          <div className={classes.barTitle}>
            <TR name="3Beds"></TR>
            <div style={{ marginLeft: 10 }}></div>
            {props.showForReport ? null : (
              <PropertyIcon propId="" color={'#62DE70'} showLabel={false}></PropertyIcon>
            )}
          </div>
          <div id="bedArea" className={classes.barArea}>
            {setBedbarPosition(bedsData[0], 3)}
          </div>
        </div>
        <div
          className={classes.barItem}
          onClick={() => {
            props.barClickEvt(1);
          }}>
          <div className={classes.barTitle}>
            <TR name="4Beds"></TR>
            <div style={{ marginLeft: 10 }}></div>
            {props.showForReport ? null : (
              <PropertyIcon propId="" color={'#B676E8'} showLabel={false}></PropertyIcon>
            )}
          </div>
          <div className={classes.barArea}> {setBedbarPosition(bedsData[1], 4)}</div>
        </div>
        <div
          className={classes.barItem}
          onClick={() => {
            props.barClickEvt(2);
          }}>
          <div className={classes.barTitle}>
            <TR name="5Beds"></TR>
            <div style={{ marginLeft: 10 }}></div>
            {props.showForReport ? null : (
              <PropertyIcon propId="" color={'#F3A42E'} showLabel={false}></PropertyIcon>
            )}
          </div>
          <div className={classes.barArea}>{setBedbarPosition(bedsData[2], 5)}</div>
        </div>
      </div>
    );
  };
});

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';

export const ConfirmDialog = rst.create<{ open: boolean; cancelEvt; okEvt }>((ctx) => {
    const handleCancel = () => {
        ctx.props.cancelEvt();
    };
    const handleOK = () => {
        ctx.props.okEvt();
    };
    return (props) => {
        return (
            <Dialog open={props.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <TR name="OPEN_MAP_IN_FULL"></TR>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TR name="OPEN_MAP_IN_FULL_MORE_FUNC"></TR>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        <TR name="CANCEL"></TR>
                    </Button>
                    <Button onClick={handleOK} color="primary" autoFocus>
                        <TR name="OK"></TR>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
});

import { UserCenterEntityType } from './UserCenterEntityType';
import { Type } from 'class-transformer';

export class FavouriteDto {
    entityType: UserCenterEntityType;
    top: boolean;
    id: string;
    @Type(() => Date)
    createTime: Date;
    @Type(() => Date)
    updateTime: Date;
    @Type(() => ViewUserCenterProperty)
    entity: ViewUserCenterProperty;
}

export class ViewUserCenterProperty {
    stdAddress: string;

    propType: 'apartment' | 'House' | null;

    propCarpark: number;

    propBed: number;

    propBath: number;

    propX: number;

    propY: number;

    propId: string;

    landValue: number;

    landSize: number;

    soldPrice: number;

    @Type(() => Date)
    soldSettlementDate: Date;

    @Type(() => Date)
    builtDate: Date;

    numberOfLots: number;

    closestStationDistance: number;

    primarySchool: SchoolNearProperty[];

    secondarySchool: SchoolNearProperty[];
}

interface SchoolNearProperty {
    name: string;
    rank: number;
    score: number;
    rank_disp: string;
}

/* eslint-disable prettier/prettier */
import { makeStyles } from '@material-ui/core';

import React from 'react';
import { rst } from 'rt-state';
import { SecurityProvider, TR } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { useRunOnce, useRunOnceAsync } from '../../../commons/hooks';
import { Permission } from '../../../commons/security/authz';
import {
  convertToM,
  getItemFromArray,
  getPriceRank,
  isMobileDevice,
  numToMillion,
  withinMonth,
} from '../../../commons/utils';
import { SemiCircle } from '../../../components/SemiCircle';
import { api_estimatePropertyValue } from '../../../services/property';
import { Glossary } from '../../PropertyReport/components/Glossary';
import { HelpComponent } from '../components/HelpComponent';
import { MapButton } from '../components/MapButton';
import { PropertyPeriodChooser } from '../Layers/PropertyPeriodChooser';

import { getIndoorQuestionValue, triggrMemberWindowForVisitor } from '../support/common';
import { MapGlobalVars } from '../support/PriceMapConsts';
import { getPropLastRecord } from '../support/propertyFeatureHelper';
import { ALL_TAGS, ENGLISH_ALL_TAGS, resetAllTags, updateTagStatus } from './CalculatorConst';
import { PropertyBedsChange } from './PropertyBedsChange';
import { TagButton } from './TagButton';
const useStyles = makeStyles((theme) => {
  return {
    tagsDiv: {
      marginTop: 10,
    },
    tagsRowDiv: {
      marginTop: 10,
      display: 'flex',
    },
    calculatorTitle: {
      fontSize: isMobileDevice() ? 14 : 16,
      fontWeight: 500,
      color: '#333333',
      flexGrow: 1,
    },

    calResult: {
      marginTop: 6,
      background: 'linear-gradient(180deg,#f3f9ff 16%, #fbfbfb 99%)',
      borderRadius: 4,
      boxShadow: ' 0px 1px 5px 0px rgba(0,0,0,0.08) inset',
      display: 'flex',
      width: '100%',
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 13,
      paddingBottom: 13,
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    moreParamToEst: {
      fontSize: 12,
      color: '#666666',
    },
    rowTitle: {
      fontSize: 14,
      fontWeight: 500,
      color: '#333333',
      whiteSpace: 'nowrap',
    },
    middleValueLabel: {
      fontSize: 14,
      fontWeight: 400,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    middleValue: {
      marginLeft: 10,
      fontSize: 16,
      fontWeight: 700,
      color: '#e02020',
    },
  };
});

export const PropertyCalculator = rst.create<{ property: any; chart?: any; showAllParams? }>((ctx) => {
  const securityProvider = SecurityProvider.use();
  const appProvider = AppProvider.use();
  console.log(JSON.stringify(ctx.props.property));
  let choosedSpaceScore;
  const estimateResult = rst.state({
    lower: 0,
    middle: 0,
    upper: 0,
    conf: '',
    inMonth: false,
    expensive: false,
  });
  const pageState = rst.state({
    showAllEstParams: ctx.props.showAllParams,
  });
  const estimatePrice = async (tag, selected?, choosedValue?) => {
    var spaceParam = '';
    console.log(tag);

    const lastPropertySold = getPropLastRecord(ctx.props.property.allFeatures);
    if (lastPropertySold.sold_price) {
      const soldYear = parseInt(lastPropertySold.sold_contract_date.substr(0, 4));
      if (lastPropertySold.sold_price * (1 + Math.max(0, 2018 - soldYear) * 0.07) > 5000000) {
        estimateResult.expensive = true;
      } else {
        estimateResult.expensive = false;
      }
    }

    if (tag && !estimateResult.expensive) {
      // if (tag === 'init') {
      //     EventBus.dispatch(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE_ISCLICKED, {})
      // }
      if (tag != 'decor_level' && tag != 'space_score') {
        const updatingTag = getItemFromArray(ctx.props.property.allFeatures?.smart_tags, 'feature', tag);
        if (updatingTag) {
          updatingTag.selected = selected;
          console.log(updatingTag);
          updateTagStatus(tag, selected);
        }
      } else {
        if (tag == 'decor_level') {
          const dectorTags = ctx.props.property.allFeatures?.smart_tags.filter((loopItem) => {
            return loopItem.feature == tag;
          });

          if (choosedValue == 1) {
            dectorTags[0].selected = 1;
            dectorTags[1].selected = 0;
          }
          if (choosedValue == 2) {
            dectorTags[0].selected = 0;
            dectorTags[1].selected = 0;
          }
          if (choosedValue == 3) {
            dectorTags[0].selected = 0;
            dectorTags[1].selected = 1;
          }
          EventBus.dispatch(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE_ISCLICKED, {});
        }

        if (tag == 'space_score' || typeof choosedSpaceScore !== 'undefined') {
          console.log('================');

          console.log(tag);
          if (tag == 'space_score') {
            choosedSpaceScore = choosedValue;
            spaceParam = tag + '=' + choosedSpaceScore;
          } else {
            spaceParam = 'space_score=' + choosedSpaceScore;
          }
          EventBus.dispatch(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE_ISCLICKED, {});
        }
      }
      const estimateFromBK: any = await api_estimatePropertyValue(ctx.props.property.allFeatures, spaceParam);
      let isPropertySoldInMonth = false;

      isPropertySoldInMonth = withinMonth(lastPropertySold.sold_contract_date, 'YYYY-MM-DD');
      console.log(isPropertySoldInMonth);
      if (ctx.props.property.allFeatures) console.log(ctx.props.property.allFeatures);
      if (estimateFromBK.midPrice) {
        if (parseFloat(estimateFromBK.midPrice) > 5000000) {
          estimateResult.expensive = true;
        }
        if (!estimateResult.expensive) {
          if (isPropertySoldInMonth) {
            estimateResult.middle = lastPropertySold.sold_price;
          } else {
            estimateResult.middle = estimateFromBK.midPrice;
          }

          estimateResult.lower = estimateFromBK.lowerPrice;
          estimateResult.upper = estimateFromBK.upperPrice;
          estimateResult.inMonth = isPropertySoldInMonth;
          estimateResult.conf = estimateFromBK.priceConfidence;

          EventBus.dispatch(EVENT_NAMES.EVENT_ESTIMAGE_VALUE_CHANGE, estimateResult);
        }
      } else {
        EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
          title: 'ERROR',
          content: 'FAILED_TO_ESTIMATE',
        });
      }
    }
    if (estimateResult.expensive) {
      EventBus.dispatch(EVENT_NAMES.EVENT_ESTIMAGE_VALUE_CHANGE, {
        expensive: true,
        lower: 0,
        upper: 0,
        middle: 5000000,
        conf: 'low',
      });
    }
    console.log(ALL_TAGS);
  };

  EventBus.on(EVENT_NAMES.EVENT_REQUEST_RESEND_ESTIMATE_VALUE, () => {
    console.log('resent estimate value');
    setTimeout(() => {
      EventBus.dispatch(EVENT_NAMES.EVENT_ESTIMAGE_VALUE_CHANGE, estimateResult);
    }, 100);
  });

  EventBus.on(EVENT_NAMES.EVENT_CHANGE_PROP_BEDS, (evt) => {
    ctx.props.property.allFeatures[evt.tagId] = evt.value;
    estimatePrice('prop_bed');
  });

  EventBus.on(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE, () => {
    if (ctx.props.property.propId == MapGlobalVars.currentClickedPropId) {
      estimatePrice('init'); //first time estimate price
    }
  });

  const showSignupConfirm = () => {
    appProvider.openLoginDialog(null);
  };

  const switchToShowAllEstParam = () => {
    pageState.showAllEstParams = !pageState.showAllEstParams;
    const mobileTabDiv = document.getElementById('mobileTabDiv');
    if (mobileTabDiv) {
      if (pageState.showAllEstParams) {
        mobileTabDiv.style.maxHeight = '320px';
      } else {
        mobileTabDiv.style.maxHeight = '220px';
      }
    }
  };

  const requestURL = window.location.search;
  let blinkingInterval: any = 0;

  if (requestURL.indexOf('showAICalcu') > -1) {
    switchToShowAllEstParam();

    if (!isMobileDevice()) {
      setTimeout(() => {
        blinkingInterval = setInterval(() => {
          blinkChartBorder();
        }, 500);

        setTimeout(() => {
          clearInterval(blinkingInterval);
          blinkChartBorder(true);
        }, 15 * 1000);
      }, 1000);
    }
  }

  const blinkChartBorder = (removeBorder?: any) => {
    const blinkEle = document.getElementById('blinkingBorder');
    var objDiv = blinkEle.parentElement;
    console.log(objDiv);
    blinkEle.style.top = '550px';
    blinkEle.style.height = '400px';
    if (blinkEle.style.display == 'block' || removeBorder) {
      document.getElementById('blinkingBorder').style.display = 'none';
    } else {
      document.getElementById('blinkingBorder').style.display = 'block';
    }
  };

  const hasLogin = () => {
    return securityProvider.hasLogin();
};

  return (props) => {
    const classes = useStyles();
    return hasLogin() ? (
        <div>
          {isMobileDevice() ? (
            <div style={{ display: 'flex', width: '100%', marginTop: 6, paddingRight: 15 }}>
              {estimateResult.expensive ? (
                <div style={{ display: 'flex', width: '30%', justifyContent: 'space-around' }}>
                  <TR name="EXPENSIVE_HOUSE"></TR>
                  <HelpComponent content={'EXPENSIVE_EXPLAIN'} maxWidth="xs" />
                </div>
              ) : (
                <>
                  <div style={{ color: 'red' }} className={classes.calculatorTitle}>
                    <TR name="AI_Calculator1"></TR>
                  </div>

                  {props.showAllParams ? null : (
                    <div
                      style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                      onClick={switchToShowAllEstParam}>
                      <div className={classes.moreParamToEst}>
                        <TR name="MoreParamsToEst"></TR>
                      </div>

                      <img
                        src="assets/icons/arrow-lower-ico.svg"
                        width="14px"
                        height="14px"
                        style={{ marginLeft: 5 }}></img>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <>
              <div style={{ display: 'flex', width: '100%', marginTop: 16 }}>
                <div className={classes.calculatorTitle} style={{ display: 'flex', alignItems: 'center' }}>
                  <TR name="AI_Calculator"></TR>{' '}
                  {isMobileDevice() ? null : (
                    <div
                      className={classes.calculatorTitle}
                      style={{ color: 'red', marginLeft: 10, fontSize: 13 }}>
                      <TR name="AI_Calculator1"></TR>
                    </div>
                  )}
                </div>

                <div
                  style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                  onClick={switchToShowAllEstParam}>
                  <div className={classes.moreParamToEst}>
                    {!pageState.showAllEstParams ? (
                      <TR name="MoreParamsToEst"></TR>
                    ) : (
                      <TR name="OnlyShowMainTags"></TR>
                    )}
                  </div>
                  <img src="assets/icons/arrow-lower-ico.svg"></img>
                </div>

                <div></div>
              </div>

              <div className={classes.calResult}>
                {estimateResult.expensive ? (
                  <div style={{ display: 'flex', width: '30%', justifyContent: 'space-around' }}>
                    <TR name="EXPENSIVE_HOUSE"></TR>
                    <HelpComponent content={'EXPENSIVE_EXPLAIN'} maxWidth="xs" />
                  </div>
                ) : (
                  <>
                    {' '}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <span className={classes.middleValueLabel}>
                        <TR name="MIDDLE_VALUE"></TR>
                      </span>

                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                        {!estimateResult.inMonth ? (
                          <>
                            {convertToM(estimateResult.lower)}m -
                            <span className={classes.middleValue}>
                              {convertToM(estimateResult.middle)}m
                            </span>
                            <span style={{ marginLeft: 5 }}></span> -{' '}
                            {convertToM(estimateResult.upper)}m
                          </>
                        ) : (
                          <TR name={'SOLD_IN_MONTH_DESC'}></TR>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: 200,
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <SemiCircle
                          value={getPriceRank(
                            estimateResult.middle,
                            props.property?.thisSuburbPriceList,
                          )}
                          borderColor="#FF9F40"></SemiCircle>
                        <TR name="PRICERANK_INSUBURB"></TR>
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <SemiCircle
                          value={Math.ceil(
                            (props.property.allFeatures?.land_value_reset /
                                                        estimateResult.middle) *
                                                        100,
                          )}
                          borderColor="#0C66DF"></SemiCircle>

                        <TR name="LAND_VALUE_PCT"></TR>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {isMobileDevice() ? (
                <div className={classes.calculatorTitle} style={{ color: 'red' }}>
                  <TR name="AI_Calculator1"></TR>
                </div>
              ) : null}
            </>
          )}

          {estimateResult.expensive ? null : (
            <Tags
              showAllParams={pageState.showAllEstParams}
              tagEvent={estimatePrice}
              prop={props.property}
              tags={props.property.allFeatures?.smart_tags}></Tags>
          )}
        </div>
      ) : (
        <>
          <MapButton
            shadow={'4px 4px #999999'}
            bg="#009E90"
            bgOpcacity={1}
            textColor={'white'}
            fontSize="14px"
            w={'100%'}
            clickEvent={showSignupConfirm}
            label={'ESTIMATE_WITH_HOMEON_CALCULATOR'}></MapButton>
        </>
      );
  };
});

export const Tags = rst.create<{ tags: any; tagEvent: any; showAllParams: boolean; prop: any }>((ctx) => {
  console.log(' new props is ', ctx.props.prop);
  const SMART_TAGS = MapGlobalVars.currentUserLan == 'zh' ? ALL_TAGS : ENGLISH_ALL_TAGS;
  const state = rst.state({
    haveSpaceOption: false,
    spaceOption: null,
  });

  // when changing bed, need to know it has indoor option or not
  EventBus.on(EVENT_NAMES.EVENT_CHANGE_PROP_BEDS, (evt) => {
    ctx.props.prop[evt.tagId] = evt.value;
    checkIndoorOption();
  });
  EventBus.on(EVENT_NAMES.EVENT_PROP_SIDE_BAR, (evt) => {
    checkIndoorOption(evt.prop);
  });

  function checkIndoorOption(property?: any) {
    const checkingProp = property || ctx.props.prop;
    const indoorQuestion = getIndoorQuestionValue(
      checkingProp.allFeatures.region,
      checkingProp.allFeatures.prop_bed,
      checkingProp.allFeatures.prop_bath,
    );
    console.log(indoorQuestion);
    if (indoorQuestion) {
      state.haveSpaceOption = true;
      state.spaceOption = indoorQuestion;
    } else {
      state.haveSpaceOption = false;
      state.spaceOption = null;
    }
  }
  checkIndoorOption();

  return (props) => {
    const getTagsContent = () => {
      let paramTags: any = [];
      if (props.showAllParams) {
        paramTags = SMART_TAGS;
      } else {
        paramTags = { row1: SMART_TAGS.row1 };
      }
      console.log(paramTags);
      const content = [];
      Object.keys(paramTags).map((loopKey) => {
        if (loopKey == 'row1' || loopKey == 'row2' || loopKey == 'row3' || loopKey == 'row6') {
          content.push(
            <RowTags
              propSmartTags={props.prop.allFeatures?.smart_tags}
              tagEvent={props.tagEvent}
              rowTags={SMART_TAGS[loopKey]}></RowTags>,
          );
        }
        if (loopKey == 'row4') {
          content.push(
            <PropertyOutsideTag
              tagEvt={props.tagEvent}
              outsideTag={SMART_TAGS[loopKey]}></PropertyOutsideTag>,
          );
        }

        if (loopKey == 'row5') {
          if (state.spaceOption) {
            content.push(
              <PropertyOutsideTag
                outsideTag={SMART_TAGS[loopKey]}
                tagEvt={props.tagEvent}
                spaceOption={state.spaceOption}></PropertyOutsideTag>,
            );
          }
        }
      });
      if (props.showAllParams) {
        content.push(
          <div style={{ display: 'flex', alignItems: 'center', marginTop: isMobileDevice() ? 15 : 25 }}>
            <div className={classes.rowTitle}>
              <TR name={'PROP_BEDS_MODIFY'}></TR>
            </div>
            <div style={{ marginLeft: isMobileDevice() ? 5 : 16 }}></div>
            <PropertyBedsChange prop={props.prop}></PropertyBedsChange>
          </div>,
        );
      }

      return content;
    };
    const classes = useStyles();
    return (
      <div className={classes.tagsDiv}>
        {getTagsContent()}
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
          {/* <Glossary glossaryId="01" /> */}
        </div>
      </div>
    );
  };
});

const RowTags = rst.create<{ rowTags; tagEvent; propSmartTags }>((ctx) => {
  return (props) => {
    const classes = useStyles();
    const getTagStataus = (tagFeature) => {
      const lookingTag = getItemFromArray(props.propSmartTags, 'feature', tagFeature);
      console.log('tagFeature is ' + tagFeature + '====>' + (lookingTag?.selected == 1));
      return lookingTag?.selected == 1;
    };
    const setTagStatus = (tagFeature, selected) => {
      const lookingTag = getItemFromArray(props.propSmartTags, 'feature', tagFeature);
      if (lookingTag) {
        lookingTag.selected = selected;
      }
      console.log(lookingTag);
    };
    console.log('rerender tags....');
    console.log(props.propSmartTags);
    return (
      <div className={classes.tagsRowDiv}>
        {props.rowTags.map((loopTag, idx) => {
          return (
            <>
              {idx >= 1 ? <div style={{ marginLeft: 10 }}></div> : null}
              <TagButton
                tagClickEvent={setTagStatus}
                selected={getTagStataus(loopTag.feature)}
                tagProps={{
                  label: loopTag.label,
                  feature: loopTag.feature,
                  clickEvt: props.tagEvent,
                }}></TagButton>
            </>
          );
        })}
      </div>
    );
  };
});

const PropertyOutsideTag = rst.create<{ outsideTag: any; spaceOption?: any; tagEvt: any }>((ctx) => {
  const state = rst.state({
    tagSelectedValue: -1,
  });
  const tagClick = (tag, value) => {
    console.log(tag);
    state.tagSelectedValue = value;
    console.group('tag select value is ' + value);
    ctx.props.tagEvt(tag, null, value);
  };
  return (props) => {
    const classes = useStyles();
    const getSpaceOptions = (label) => {
      if (props.spaceOption) {
        console.log(label);
        label = label.replace('${min}', props.spaceOption.question_a);
        label = label.replace('${max}', props.spaceOption.question_c);
      }
      return label;
    };
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginTop: isMobileDevice() ? 15 : 25 }}>
        <div className={classes.rowTitle}>
          <TR name={props.outsideTag.label}></TR>
        </div>
        <div style={{ marginLeft: 16, display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
          {props.outsideTag.options.map((loopOption) => {
            return (
              <TagButton
                selected={state.tagSelectedValue == loopOption.value}
                tagProps={{
                  label: getSpaceOptions(loopOption.label),
                  value: loopOption.value,
                  feature: props.outsideTag.feature,

                  clickEvt: tagClick,

                  passEvtToParent: true,
                }}></TagButton>
            );
          })}
        </div>
      </div>
    );
  };
});

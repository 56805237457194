import React from 'react';
import { rst } from 'rt-state';
import { makeStyles } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TR } from '../../../../commons';

const useStyles = makeStyles({
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: '8px',
    },
    tips: {
        fontSize: 13,
        lineHeight: '18px',
        color: '#888888',
        marginTop: '5px',
    },
    value: {
        fontSize: '13px',
        color: '#000000',
    },
});

export const ApartmentStatistics = rst.create<{ pageData?: any }>((ctx) => {
    const pageState = rst.state({
        data: { ...ctx.props.pageData.prop_ql.street },
    });
    const classes = useStyles();
    return (props: any) => {
        return (
            <div>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>
                            <div style={{ fontSize: '16px', fontWeight: 600, color: '#333' }}>
                                <TR name="apartmentStatisticsTwoYears"></TR>
                            </div>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {pageState.data ? (
                                <div style={{ padding: '10px', color: '#888' }}>
                                    <div className={classes.tips}>
                                        <TR name="medianPrice"></TR>:{' '}
                                        <span className={classes.value}>
                                            {pageState.data.apartmentPriceRange
                                                ? '$' +
                                                  pageState.data.apartmentPriceRange.medianPrice.median / 1000000 +
                                                  'm'
                                                : 'N/A'}
                                        </span>
                                    </div>
                                    <div className={classes.tips}>
                                        <TR name="priceRangeInStatistics"></TR>:{' '}
                                        <span className={classes.value}>
                                            {pageState.data.apartmentPriceRange
                                                ? '$' +
                                                  pageState.data.apartmentPriceRange.soldPriceRange.minValue / 1000000 +
                                                  'm'
                                                : 'N/A'}
                                            {pageState.data.apartmentPriceRange
                                                ? ' ~ $' +
                                                  pageState.data.apartmentPriceRange.soldPriceRange.maxValue / 1000000 +
                                                  'm'
                                                : ''}
                                        </span>
                                    </div>
                                    <div className={classes.tips}>
                                        <TR name="soldTotal"></TR>：
                                        <span className={classes.value}>
                                            {pageState.data.apartmentPriceRange
                                                ? pageState.data.apartmentPriceRange.medianPrice.numberOfSold
                                                : 0}
                                        </span>
                                    </div>
                                </div>
                            ) : null}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };
});

import { rst } from 'rt-state';
import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { TR } from '../../commons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import { Arrays, SortFields } from '../../commons/array';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles((theme) => {
    return {
        sortButton: {
            height: 39,
            padding: '20px 0px',
            margin: '5px 0px 35px 0px',
            backgroundColor: 'white',
            '& span': {
                fontSize: 16,
                color: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
        dummy: {},
    };
});

export const SortMenu = rst.create<{ sortFieldsState: rst.StateS<SortFields> }>((ctx) => {
    const anchorState = rst.stateS(null);
    const handleClick = (event) => {
        anchorState.value = event.currentTarget;
    };

    const handleClose = () => {
        anchorState.value = null;
    };
    function sortCallback(fieldName: string) {
        return () => {
            anchorState.value = null;
            const { sortFieldsState } = ctx.props;
            const sortFields = sortFieldsState.value;
            if (fieldName != null) {
                const sortType = Arrays.getNewSortType(sortFields, fieldName);
                sortFields['entity.propBed'] = null;
                sortFields['entity.landSize'] = null;
                sortFields['entity.closestStationDistance'] = null;
                sortFields[fieldName] = sortType;
                sortFieldsState.value = { ...sortFields };
            }
        };
    }
    return (props) => {
        const classes = useStyles();
        const { sortFieldsState } = props;
        const { value: orderFields } = sortFieldsState;
        function getSortIcon(field: string) {
            const type = orderFields[field];
            switch (type) {
                case 'asc':
                    return <ArrowUpwardIcon />;
                case 'desc':
                    return <ArrowDownwardIcon />;
                default:
                    return <></>;
            }
        }

        return (
            <>
                <Button className={classes.sortButton} onClick={handleClick}>
                    <TR name={'SORT'} />
                    <ArrowDropDownIcon />
                </Button>
                <Menu anchorEl={anchorState.value} keepMounted open={Boolean(anchorState.value)} onClose={handleClose}>
                    <MenuItem onClick={sortCallback('entity.propBed')}>
                        {getSortIcon('entity.propBed')} <TR name={'Type'} />
                    </MenuItem>
                    <MenuItem onClick={sortCallback('entity.landSize')}>
                        {getSortIcon('entity.landSize')} <TR name={'Area'} />
                    </MenuItem>
                    <MenuItem onClick={sortCallback('entity.closestStationDistance')}>
                        {getSortIcon('entity.closestStationDistance')} <TR name={'Train Distance'} />
                    </MenuItem>
                </Menu>
            </>
        );
    };
});

import * as React from 'react';
import { rst } from 'rt-state';
import { TransitionProps } from '@material-ui/core/transitions';
import { Slide, Typography } from '@material-ui/core';
import MSnackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { ReactNode } from 'react';

export interface SnackbarController {
    open(msg: ReactNode, type?: 'success' | 'info' | 'warning' | 'error'): void;
    close(): void;
}

export const createXSnackbarController = (): SnackbarController => {
    const openState = rst.stateS(false);
    const props = { msg: '', severity: 'info' };
    const open = (msg: string, type?: 'success' | 'info' | 'warning' | 'error'): void => {
        props.msg = msg;
        props.severity = type;
        openState.value = true;
    };
    const close = () => {
        openState.value = false;
    };
    return { openState, props, open, close } as any;
};

function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction={'down'} />;
}

export const XSnackbar = rst.createS<{
    controller: SnackbarController;
}>(({ controller }) => {
    const _close = (event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        controller.close();
    };

    const { openState, props } = controller as any;
    const open = openState.value;

    return (
        <MSnackbar
            style={{ zIndex: 5000 }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={1000}
            onClose={_close}
            TransitionComponent={SlideTransition}>
            <Alert onClose={_close} severity={props.severity}>
                <Typography style={{ fontSize: 14 }}>{props.msg}</Typography>
            </Alert>
        </MSnackbar>
    );
});

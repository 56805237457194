import makeStyles from '@material-ui/core/styles/makeStyles';

export const usePageStyles = makeStyles((theme) => ({
    session: {
        position: 'relative',
        zIndex: 200,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    background: {
        backgroundColor: 'grey',
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1 0 auto',
        flexDirection: 'column',
        minHeight: '100%',
        textAlign: 'center',
    },
    wrapper: {
        flex: 'none',
        maxWidth: '400px',
        width: '100%',
        margin: '0 auto',
    },
}));

export const useStyles = makeStyles((theme) => ({
    card: {
        overflow: 'visible',
    },
    withGoogle: {
        padding: '6px',
        marginBottom: 12,
    },
    info: {
        '& p': {
            fontSize: 14,
            fontWeight: 400,
        },
    },
    button: {
        padding: '0.7rem 0rem',
    },
}));

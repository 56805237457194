import { API } from '../commons';
import { UserProfileDto, UpdateProfilePo } from '../models/backend';
import { SessionPo } from '../models/backend/session_po';
import { env } from '../environments';

export interface TwoTokens {
    refreshToken: string;
    accessToken: string;
}

export enum SignupType {
    EMAIL = 1,
    PHONE = 2,
}

function getFullUrl(path) {
    return `${env.USER_API_HOST}/user/api/auth${path}`;
}

export async function signup(
    language: string,
    type: SignupType,
    username: string,
    password: string,
): Promise<SessionPo> {
    const token = window.btoa(`${username}:${password}`);
    return await API.post(null, getFullUrl(`/signup?type=${type}&language=${language}`), null, {
        Authorization: `Basic ${token}`,
    });
}

export async function login(username: string, password: string) {
    const token = window.btoa(`${username}:${password}`);
    const twoTokens = (await API.get(null, getFullUrl('/login'), null, {
        Authorization: `Basic ${token}`,
    })) as TwoTokens;
    return twoTokens.accessToken;
}

export async function forgetPassword(username: string) {
    await API.get(null, getFullUrl('/forgetPassword'), { email: username });
}

export async function changePassword(password: string, newPassword: string) {
    await API.post(null, getFullUrl('/changePassword'), { password, newPassword });
}

export async function forgetPasswordSession(sessionPo: SessionPo, password: string) {
    const token = window.btoa(`anything:${password}`);
    const twoTokens = (await API.post(null, getFullUrl('/forgetPassword/session'), sessionPo, {
        Authorization: `Basic ${token}`,
    })) as TwoTokens;
    return twoTokens.accessToken;
}

export async function signupSession(sessionPo: SessionPo) {
    const twoTokens = (await API.post(null, getFullUrl('/signup/session'), sessionPo)) as TwoTokens;
    return twoTokens.accessToken;
}

export async function loginSession(sessionPo: SessionPo) {
    const twoTokens = (await API.post(null, getFullUrl('/login/session'), sessionPo)) as TwoTokens;
    return twoTokens.accessToken;
}

export async function getUserProfile() {
    return await API.get(UserProfileDto, getFullUrl('/profile'));
}

export async function updateUserProfile(po: UpdateProfilePo) {
    return await API.post(UserProfileDto, getFullUrl('/profile'), po);
}

export async function addTrial(): Promise<UserProfileDto> {
    return await API.post(UserProfileDto, getFullUrl('/trial'));
}

export async function changeLanguage(language: string): Promise<string> {
    return await API.post(null, getFullUrl(`/language/${language}`));
}

export async function verifyUserEmail(email, uuid): Promise<any> {
    return await API.post(null, getFullUrl('/signup/verifyEmail'), { email, uuid });
}

import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    ElementsConsumer,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { API, clsx, generateGUID, SecurityProvider, TR, TRHtml } from '../../../../commons';
import { AppProvider } from '../../../../commons/appProvider';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { Confirmation } from '../../../../components/Confirmation';
import { Margin } from '../../../../components/margin';
import { env } from '../../../../environments';
import { getUserProfile } from '../../../../services/auth';
import { getSuburbNameByIdFromLocalData } from '../../../../services/suburb';
import {
    becomeAdvanceMember,
    cancelAdvanceMember,
    changeMemberType,
    getUserSubInfo,
    updateAreaMemberCareSuburbs,
} from '../../../../services/userCenter';
import { mobileNavButton } from '../../consts/styleConsts';
import { AreaMemberSignup } from '../signUp/TipAfterSignup';
import { requestVerifyEmail } from '../../../../services/userCenter';
import { MemberIcon } from '../commonComponents/memberIcon';
import moment from 'moment';
import { InputSignUp } from '../commonComponents/input_signUp';
import { createXForm } from '../../../../components/form';
import { MAP_APIS_ENDPOINTS } from '../../../../services/EndPoints';

export const useStyles = makeStyles({
    closeIcon: {
        position: 'absolute',
        top: '15px',
        right: '20px',
        zIndex: 99999,
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '18px',
        fontWeight: 500,
        color: '#333333',
    },
    memberType: {
        display: 'flex',
        justifyContent: 'space-around',
        fontSize: '10px',
    },
    memberItem: {
        width: '126px',
        height: '121px',
        border: '1px solid #d8d8d8',
        borderRadius: '4px',
        paddingTop: '18px',
        position: 'relative',
    },
    annually: {
        background: '#ff2200',
        borderRadius: '10px',
        position: 'absolute',
        top: -10,
        left: '24%',
        padding: '2px 9px',
        color: 'white',
        fontSize: 12,
        fontWeight: 400,
    },
    selectedMemberType: {
        border: '1px solid #009e90',
    },
    cardTitle: {
        fontSize: 12,
        fontWeight: 400,
        color: '#333333',
        width: '100%',
        textAlign: 'center',
    },
    productPrice: {
        fontSize: '18px',
        fontWeight: 600,
        color: '#333333',
        width: '100%',
        textAlign: 'center',
    },
    addtionPrice: {
        fontSize: 12,
        fontWeight: 400,
        color: '#333333',
        width: '100%',
        textAlign: 'center',
    },
    cardElementDiv: {
        marginTop: 20,
    },
    paymentSummary: {
        fontSize: 14,
        color: '#666666',
        fontWeight: 400,
        display: 'flex',
    },
    paymentTotal: {
        fontWeight: 500,
        color: '#000000',
    },
    titleBorder: {
        position: 'relative',
        width: '100%',
    },
    borderTitle: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#333333',
        position: 'absolute',
        left: '40%',
        top: '-8px',
    },
    cardCollection: {
        display: 'flex',

        fontSize: '14px',
        fontWeight: 500,
        color: '#333333',
    },
    elementRow: {
        position: 'relative',
        background: '#eff1f1',
        padding: '11px',
        borderRadius: '4px',
        width: '95%',
    },
    cardElement: {
        marginLeft: 150,
    },
    cardElementLabel: {
        fontSize: 13,
        fontWeight: 500,
        position: 'absolute',
    },
    afterPayRow: {
        display: 'flex',
        fontSize: 14,
    },
    afterPayLabel: {
        flex: 1,

        fontWeight: 700,
    },
});
const initialValues = {
    selectedSuburbs: [],
};

export const MemberInfo = (props: any) => {
    const securityProvider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const userProfile = securityProvider.getUserProfile();

    const { onClose, show, onSuccessAreaMember, popType } = props;

    const stripePromise = loadStripe(env.stripeKey);
    const [changingSubType, setChangingSubType] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [emailSendResult, setEmailSendResult] = useState(null);

    const classes = useStyles();
    const [selectedMemberType, setSelectedMemberType] = useState(
        userProfile && userProfile['userStatus'] == '5' ? 1 : userProfile && userProfile['userStatus'] == '4' ? 2 : 1,
    );
    const [buyNow, setBuyNow] = useState(
        userProfile && (userProfile['userStatus'] == '5' || userProfile['userStatus'] == '4'),
    );
    const [showCancelConfirm, setShowCancelConfirm] = useState(false);
    const showCancelMemberConfirmation = () => {
        setShowCancelConfirm(true);
    };
    const [doingCancel, setDoingCancel] = useState(false);

    const cancelMember = async () => {
        try {
            const paymentIntentURL = env.apiHost + '/payment/subscription';

            const subscriptionResult: any = await API.delete(
                null,
                paymentIntentURL,
                JSON.stringify({ plan: selectedMemberType == 1 ? 'year' : 'month' }),
                {
                    'Content-Type': 'application/json',
                },
            );

            setShowCancelConfirm(false);
            await changeMemberType(6);

            EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
                open: true,
                title: 'Success',
                content: 'memberCanceld',
                cancelButtonText: 'GOT_IT',
                cancelEvt: () => {
                    refreshPageAfterCancel();
                },
            });
        } catch (e) {}
    };

    const changeSubType = async () => {
        setChangingSubType(true);
        const paymentIntentURL = env.apiHost + '/payment/subscription';
        console.log('intent url ==>', paymentIntentURL);
        console.log('token is ', securityProvider.tokenInfo);
        try {
            const subscriptionResult: any = await API.put(
                null,
                paymentIntentURL,
                JSON.stringify({ proration_date: proration_date, plan: 'year' }),
                {
                    'Content-Type': 'application/json',
                },
            );
            console.log(subscriptionResult);

            await changeMemberType(selectedMemberType);
            EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
                open: true,
                title: 'Success',
                content: 'changeSubTypeSucceed',
                cancelButtonText: 'GOT_IT',
                cancelEvt: () => {
                    onClose();
                },
            });

            console.log(subscriptionResult);
        } catch (e) {
            EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
                open: true,
                title: 'Error',
                content: 'error_change_sub_type',
                cancelButtonText: 'GOT_IT',
            });
        }
        setChangingSubType(false);
    };

    const refreshPageAfterCancel = () => {
        window.location.reload();
    };

    const [priceDiff, setPriceDiff] = useState(0);
    const [proration_date, setProration_date] = useState(0);
    const getPriceDifference = async () => {
        const subInfo = await getUserSubInfo(securityProvider.getUserProfile().id);
        if (subInfo && subInfo[0] && subInfo[0].invoice) {
            const { proration_date, subtotal } = subInfo[0].invoice;
            console.log(proration_date);
            console.log(subtotal);
            setPriceDiff(190000 - subtotal);
            setProration_date(proration_date);
        }
        console.log(subInfo);
    };

    const resendVerifyEmail = async () => {
        const result = await requestVerifyEmail(appProvider.getLanguage());
        setEmailSendResult(result);
    };
    return (
        <>
            <Dialog open={show}>
                <DialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={classes.closeIcon} onClick={onClose}>
                        <img src="assets/mobileIcons/close.svg"></img>
                    </div>
                </DialogTitle>

                <DialogContent>
                    {popType == 10 && (
                        <>
                            {!emailSendResult && (
                                <>
                                    <div className={classes.title}>
                                        <TR name="memberPermission"></TR>
                                    </div>

                                    <Margin tm={15}></Margin>
                                    <div>
                                        <TR name="oneStepLeftContent"></TR>
                                    </div>
                                    <Margin tm={15}></Margin>

                                    <div>
                                        <TR name="validationUtil"></TR>
                                    </div>
                                    <Margin tm={15}></Margin>
                                    <Button
                                        style={{
                                            ...mobileNavButton,
                                            background: '#009e90',
                                            height: '42px',
                                            color: 'white',
                                            width: '100%',
                                            border: '1px solid #979797',
                                            borderRadius: '4px',
                                        }}
                                        onClick={resendVerifyEmail}
                                        fullWidth
                                        variant="contained"
                                        disableElevation>
                                        <TR name={'reSendValidateEmail'} />
                                    </Button>
                                </>
                            )}
                            {emailSendResult && (
                                <>
                                    <div>
                                        <TR name="verifyEmailSendTo"></TR>
                                        {emailSendResult?.email}
                                    </div>

                                    <Margin tm={15}></Margin>
                                    <Button
                                        style={{
                                            ...mobileNavButton,
                                            background: '#009e90',
                                            height: '42px',
                                            color: 'white',
                                            width: '100%',
                                            border: '1px solid #979797',
                                            borderRadius: '4px',
                                        }}
                                        onClick={onClose}
                                        fullWidth
                                        variant="contained"
                                        disableElevation>
                                        <TR name={'GOT_IT'} />
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                    {popType !== 10 && (
                        <>
                            <div>
                                <MemberMessage popType={popType}></MemberMessage>
                            </div>

                            {(popType == 1 || popType == 10 || popType == 20) && (
                                <>
                                    <Margin tm={30}></Margin>
                                    <AreaMemberSignup
                                        incomingUser={{}}
                                        saveUserSuccessEvt={() => {
                                            onSuccessAreaMember();
                                        }}></AreaMemberSignup>
                                </>
                            )}
                            {popType != 6 && popType != 60 && (
                                <>
                                    <Margin tm={30}></Margin>
                                    <div className={classes.title}>
                                        <TR name="wholeAreaMember"></TR>
                                    </div>
                                    <Margin tm={10}></Margin>

                                    <div
                                        style={{
                                            fontSize: 13,
                                            fontWeight: 400,
                                            color: '#666666',
                                        }}>
                                        {userProfile &&
                                            (userProfile['userStatus'] == '3' ||
                                                userProfile['userStatus'] == '2' ||
                                                userProfile['userStatus'] == '1') && (
                                                <TR name="wholeAreaMemberBenefit"></TR>
                                            )}
                                        {userProfile && userProfile['userStatus'] == '4' && (
                                            <TR name="monthlyMemberInfo"></TR>
                                        )}
                                        {userProfile && userProfile['userStatus'] == '5' && (
                                            <TR name="yearlyMemberInfo"></TR>
                                        )}
                                    </div>

                                    <Margin tm={10}></Margin>

                                    {popType === 0 && (
                                        <>
                                            <div>
                                                <TRHtml html="nonRegiUserMessage"></TRHtml>
                                            </div>

                                            <Margin tm={15}></Margin>
                                            <div className={classes.memberType}>
                                                <MemberProductCard
                                                    memberType={1}
                                                    title={'AnnualFee'}
                                                    canSelect={true}
                                                    chooseMemberType={async () => {
                                                        if (userProfile['userStatus'] == '4') {
                                                            await getPriceDifference();
                                                        }
                                                        setSelectedMemberType(1);
                                                    }}
                                                    selected={selectedMemberType == 1}
                                                    price={'$390.00'}
                                                    addtionPrice={<TR name={'addtionPrice'}></TR>}></MemberProductCard>
                                                <MemberProductCard
                                                    memberType={2}
                                                    chooseMemberType={() => {
                                                        if (userProfile && userProfile['userStatus'] !== '5') {
                                                            setSelectedMemberType(2);
                                                        }
                                                    }}
                                                    canSelect={true}
                                                    selected={selectedMemberType == 2}
                                                    title={'MonthFee'}
                                                    price={'$39.00'}></MemberProductCard>
                                            </div>

                                            <Margin tm={15}></Margin>
                                            <Button
                                                style={{
                                                    ...mobileNavButton,
                                                    background: '#009e90',
                                                    height: '42px',
                                                    color: 'white',
                                                    width: '100%',
                                                    border: '1px solid #979797',
                                                    borderRadius: '4px',
                                                }}
                                                onClick={() => {
                                                    onClose();
                                                    appProvider.state.authDialog = 'login';
                                                }}
                                                fullWidth
                                                variant="contained"
                                                disableElevation>
                                                <TR name={'BuyNow'} />
                                            </Button>
                                        </>
                                    )}
                                    {!buyNow &&
                                        popType !== 0 &&
                                        userProfile['userStatus'] !== '4' &&
                                        userProfile['userStatus'] !== '5' &&
                                        userProfile['userStatus'] !== '7' &&
                                        userProfile['userStatus'] !== '6' && (
                                            <>
                                                <Button
                                                    style={{
                                                        ...mobileNavButton,
                                                        background: '#009e90',
                                                        height: '42px',
                                                        color: 'white',
                                                        width: '100%',
                                                        border: '1px solid #979797',
                                                        borderRadius: '4px',
                                                    }}
                                                    onClick={() => {
                                                        setBuyNow(true);
                                                    }}
                                                    fullWidth
                                                    variant="contained"
                                                    disableElevation>
                                                    <TR name={'BuyNow'} />
                                                    <Margin lm={5}></Margin>
                                                    {userProfile &&
                                                        (userProfile['userStatus'] == '3' ||
                                                            userProfile['userStatus'] == '1' ||
                                                            userProfile['userStatus'] == '2') && (
                                                            <img src="assets/mobileIcons/downArrowWhite.svg" />
                                                        )}
                                                </Button>{' '}
                                            </>
                                        )}

                                    {buyNow && (
                                        <>
                                            <Margin tm={15}></Margin>
                                            <div className={classes.memberType}>
                                                <MemberProductCard
                                                    memberType={1}
                                                    canSelect={true}
                                                    title={'AnnualFee'}
                                                    chooseMemberType={async () => {
                                                        if (userProfile['userStatus'] == '4') {
                                                            await getPriceDifference();
                                                        }
                                                        setSelectedMemberType(1);
                                                    }}
                                                    selected={selectedMemberType == 1}
                                                    price={'$390.00'}
                                                    addtionPrice={<TR name={'addtionPrice'}></TR>}></MemberProductCard>
                                                <MemberProductCard
                                                    memberType={2}
                                                    chooseMemberType={() => {
                                                        if (userProfile && userProfile['userStatus'] !== '5') {
                                                            setSelectedMemberType(2);
                                                        }
                                                    }}
                                                    canSelect={userProfile['userStatus'] !== '5'}
                                                    selected={selectedMemberType == 2}
                                                    title={'MonthFee'}
                                                    price={'$39.00'}></MemberProductCard>
                                            </div>

                                            <Margin tm={15}></Margin>

                                            {userProfile['userStatus'] !== '4' && userProfile['userStatus'] !== '5' && (
                                                <Elements stripe={stripePromise}>
                                                    <PaymentInfo
                                                        memberType={selectedMemberType}
                                                        onClose={onClose}></PaymentInfo>
                                                </Elements>
                                            )}
                                        </>
                                    )}
                                    {userProfile &&
                                        (userProfile['userStatus'] == '4' || userProfile['userStatus'] == '5') && (
                                            <>
                                                <Margin tm={15}></Margin>

                                                <Button
                                                    style={{
                                                        ...mobileNavButton,
                                                        background: '#009e90',
                                                        height: '42px',
                                                        color:
                                                            (userProfile['userStatus'] !== '4' &&
                                                                selectedMemberType == 2) ||
                                                            (userProfile['userStatus'] !== '5' &&
                                                                selectedMemberType == 1)
                                                                ? 'white'
                                                                : 'gray',
                                                        width: '100%',
                                                        border: '1px solid #979797',
                                                        borderRadius: '4px',
                                                    }}
                                                    onClick={changeSubType}
                                                    fullWidth
                                                    disabled={
                                                        !(
                                                            (userProfile['userStatus'] !== '4' &&
                                                                selectedMemberType == 2) ||
                                                            (userProfile['userStatus'] !== '5' &&
                                                                selectedMemberType == 1)
                                                        ) || changingSubType
                                                    }
                                                    variant="contained"
                                                    disableElevation>
                                                    <TR
                                                        name={
                                                            userProfile && userProfile['userStatus'] == '4'
                                                                ? 'ChangeToAnnually'
                                                                : 'ChangeToMonthly'
                                                        }
                                                    />
                                                    {changingSubType && (
                                                        <CircularProgress
                                                            size={16}
                                                            style={{ color: 'white' }}></CircularProgress>
                                                    )}
                                                </Button>

                                                <Margin tm={15}></Margin>
                                                <Button
                                                    style={{
                                                        ...mobileNavButton,
                                                        background: '#009e90',
                                                        height: '42px',
                                                        color: 'white',
                                                        width: '100%',
                                                        border: '1px solid #979797',
                                                        borderRadius: '4px',
                                                    }}
                                                    onClick={showCancelMemberConfirmation}
                                                    fullWidth
                                                    variant="contained"
                                                    disableElevation>
                                                    <TR name={'CancelMember'} />
                                                </Button>
                                            </>
                                        )}
                                </>
                            )}

                            {popType == 60 && (
                                <>
                                    <Margin tm={15}></Margin>
                                    <Button
                                        style={{
                                            ...mobileNavButton,
                                            background: '#009e90',
                                            height: '42px',
                                            color: 'white',
                                            width: '100%',
                                            border: '1px solid #979797',
                                            borderRadius: '4px',
                                        }}
                                        onClick={onClose}
                                        fullWidth
                                        variant="contained"
                                        disableElevation>
                                        <TR name={'GOT_IT'} />
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </DialogContent>
            </Dialog>

            <Confirmation
                confirmEvt={cancelMember}
                cancelEvt={() => {
                    setShowCancelConfirm(false);
                }}
                title="confirmation"
                content="cancelMemberConfirmation"
                open={showCancelConfirm}></Confirmation>

            <BuyMemberResult
                open={showResult}
                onClose={async () => {
                    setShowResult(false);
                    props.onClose && props.onClose();

                    const userProfile = await getUserProfile();
                    securityProvider.setUserProfile(userProfile);
                }}></BuyMemberResult>
        </>
    );
};

const MemberProductCard = (props: any) => {
    const { title, memberType, price, addtionPrice, info, selected, chooseMemberType, canSelect } = props;
    const classes = useStyles();
    return (
        <div
            style={{ background: !canSelect ? '#979797' : '' }}
            onClick={chooseMemberType}
            className={clsx(classes.memberItem, selected ? classes.selectedMemberType : '')}>
            <div className={classes.annually} style={{ background: '#ff2200' }}>
                <TR name={memberType == 1 ? 'Annual_saving' : 'Monthly_benefit'}></TR>
            </div>
            <div className={classes.cardTitle}>
                <TR name={title}></TR>
            </div>
            <div className={classes.productPrice}>{price}</div>
            <div className={classes.addtionPrice}>{addtionPrice}</div>
        </div>
    );
};

export const PaymentInfo = (props: any) => {
    const appProivder = AppProvider.use();
    const securityProvider = SecurityProvider.use();
    const { memberType } = props;

    const stripe = useStripe();
    const classes = useStyles();
    const elements = useElements();
    const [buyResult, setBuyResult] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [paying, setPaying] = useState(false);
    const confirmBuy = async () => {
        setPaying(true);
        const paymentIntentURL = env.apiHost + '/payment/subscription';
        console.log('intent url ==>', paymentIntentURL);
        console.log('token is ', securityProvider.tokenInfo);
        try {
            const subscriptionResult: any = await API.post(
                null,
                paymentIntentURL,
                JSON.stringify({ plan: memberType == 2 ? 'month' : 'year' }),
                {
                    'Content-Type': 'application/json',
                },
            );

            if (subscriptionResult?.clientSecret) {
                setClientSecret(subscriptionResult.clientSecret);

                const payload = await stripe.confirmCardPayment(subscriptionResult.clientSecret, {
                    receipt_email: securityProvider.getUserProfile().email,
                    payment_method: {
                        card: elements.getElement('cardNumber'),
                    },
                });

                console.log('after payment');
                console.log(payload);

                if (!payload.error) {
                    await becomeAdvanceMember(memberType);
                    setShowResult(true);
                } else {
                    EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
                        open: true,
                        title: 'Error',
                        content: 'paymentError',
                        contentParams: { errorMsg: payload.error.message },
                        cancelButtonText: 'GOT_IT',
                    });
                }
            } else {
                EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
                    open: true,
                    title: 'Error',
                    content: 'error_confirm_as_member',
                    cancelButtonText: 'GOT_IT',
                });
            }
        } catch (e) {
            EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
                open: true,
                title: 'Error',
                content: 'error_confirm_as_member',
                cancelButtonText: 'GOT_IT',
            });
            await API.post(
                null,
                MAP_APIS_ENDPOINTS.RECORD_USER_ERROR,
                JSON.stringify({ action: 'payment', errorMsg: e.toString() }),
                {
                    'Content-Type': 'application/json',
                },
            );
        }

        setPaying(false);
        //const buyResult = await becomeAdvanceMember();
    };

    return (
        <>
            <div>
                <Margin tm={15}></Margin>
                <div className={classes.title}>
                    <TR name="paymentInfo"></TR>
                </div>
                <Margin tm={15}></Margin>
                <div className={classes.paymentSummary}>
                    <div style={{ flex: 1 }}>
                        <TRHtml
                            html="paymentSummaryInfo"
                            params={{
                                type:
                                    memberType == 1
                                        ? appProivder.getLanguage() == 'zh'
                                            ? '年'
                                            : 'year'
                                        : appProivder.getLanguage() == 'zh'
                                        ? '月'
                                        : 'month',
                            }}></TRHtml>
                    </div>
                    <div className={classes.paymentTotal}>{memberType == 1 ? '$390.00' : '$39.00'}</div>
                </div>

                <Margin tm={15}></Margin>
                <div className={classes.cardCollection}>
                    <div style={{ flex: 1, fontSize: 14, color: '#333333' }}>
                        <TR name="cardInfoCollection"></TR>
                    </div>

                    <div>
                        <img src="assets/mobileIcons/creditCardsLogo.png" height={24} />
                    </div>
                </div>
                <Margin tm={15}></Margin>
                <ElementsConsumer>
                    {({ elements, stripe }) => (
                        <form id="paymentForm">
                            <div className={classes.elementRow}>
                                <div className={classes.cardElementLabel}>
                                    <TR name="CREDIT_CARD_NUM"></TR>
                                </div>
                                <div className={classes.cardElement}>
                                    <CardNumberElement></CardNumberElement>
                                </div>
                            </div>

                            <Margin tm={15}></Margin>

                            <div className={classes.elementRow}>
                                <div className={classes.cardElementLabel}>
                                    <TR name="CREDIT_CARD_EXP"></TR>
                                </div>

                                <div className={classes.cardElement}>
                                    <CardExpiryElement></CardExpiryElement>
                                </div>
                            </div>

                            <Margin tm={15}></Margin>
                            <div className={classes.elementRow}>
                                <div className={classes.cardElementLabel}>
                                    <TR name="CREDIT_CARD_CVC"></TR>
                                </div>

                                <div className={classes.cardElement}>
                                    <CardCvcElement></CardCvcElement>
                                </div>
                            </div>
                        </form>
                    )}
                </ElementsConsumer>

                <Margin tm={15}></Margin>
                <div className={classes.paymentSummary} style={{ color: '#000000' }}>
                    <div style={{ flex: 1 }}>
                        <TR name="total"></TR>
                    </div>
                    <div className={classes.paymentTotal}>{memberType == 1 ? '$390.00' : '$39.00'}</div>
                </div>
                <Margin tm={15}></Margin>
                <TRHtml html="agreeAutoPayment"></TRHtml>

                <Margin tm={15}></Margin>
                <Button
                    style={{
                        ...mobileNavButton,
                        background: '#009e90',
                        height: '42px',
                        color: 'white',
                        width: '100%',
                        border: '1px solid #979797',
                        borderRadius: '4px',
                    }}
                    onClick={() => {
                        confirmBuy();
                    }}
                    fullWidth
                    disabled={paying}
                    variant="contained"
                    disableElevation>
                    <TR name={'ConfirmBuy'} />

                    {paying && <CircularProgress style={{ color: 'white' }} size={16}></CircularProgress>}
                </Button>

                <BuyMemberResult
                    open={showResult}
                    totalAmount={memberType == 1 ? '$390.00' : '$39.00'}
                    email={securityProvider.getUserProfile().email}
                    onClose={async () => {
                        setShowResult(false);
                        props.onClose && props.onClose();

                        const userProfile = await getUserProfile();
                        securityProvider.setUserProfile(userProfile);
                    }}></BuyMemberResult>
            </div>
        </>
    );
};

const BuyMemberResult = (props: any) => {
    const { open, onClose, totalAmount, email } = props;
    const classes = useStyles();
    const todayDate = moment().format('DD/MM/YYYY');
    return (
        <Dialog open={open}>
            <DialogTitle>
                <Margin tm={15}></Margin>
                <div className={classes.title}>
                    <TR name="thanksBuy"></TR>
                </div>
                <div className={classes.closeIcon} onClick={onClose}>
                    <img src="assets/mobileIcons/close.svg"></img>
                </div>
            </DialogTitle>
            <DialogContent>
                <div>
                    <TR name="thanksBuying"></TR>
                    <Margin tm={15}></Margin>
                </div>
                <div>
                    <Margin tm={15}></Margin>
                    <div className={classes.afterPayRow}>
                        <div className={classes.afterPayLabel}>
                            <TR name="payDate"></TR>
                        </div>
                        <div>{todayDate}</div>
                    </div>
                </div>
                <div>
                    <Margin tm={15}></Margin>
                    <div className={classes.afterPayRow}>
                        <div className={classes.afterPayLabel}>
                            <TR name="payMethod"></TR>
                        </div>
                        <div>
                            <TR name="creditCard"></TR>
                        </div>
                    </div>
                </div>

                <div>
                    <Margin tm={15}></Margin>
                    <div className={classes.afterPayRow}>
                        <div className={classes.afterPayLabel}>
                            <TR name="total"></TR>
                        </div>
                        <div>{totalAmount}</div>
                    </div>
                </div>

                <Margin tm={15}></Margin>
                <TRHtml html={'invoiceSent'} params={{ email: email }}></TRHtml>

                <Margin tm={15}></Margin>

                <Button
                    style={{
                        ...mobileNavButton,
                        background: '#009e90',
                        height: '42px',
                        color: 'white',
                        width: '100%',
                        border: '1px solid #979797',
                        borderRadius: '4px',
                    }}
                    onClick={onClose}
                    fullWidth
                    variant="contained"
                    disableElevation>
                    <TR name={'OK'} />
                </Button>
            </DialogContent>
        </Dialog>
    );
};

const MemberMessage = (props: any) => {
    const [form, setForm] = useState(
        createXForm({
            initialValues,
            validate: null,
            onSubmit: () => {},
        }),
    );
    //popType = 10 --- unverify email user message
    const securityProvider = SecurityProvider.use();
    const classes = useStyles();
    const { popType } = props;
    const [suburbNames, setSuburbNames] = useState('');

    const statusMDate = moment(securityProvider.getUserProfile().whenAsCurrentStatus);
    const after3Months = statusMDate.add(3, 'month');
    const suburbChanged = securityProvider.getUserProfile().suburbChanged;
    console.log('after 3 months is ' + after3Months);
    const todayMDate = moment();
    const areaUserWithin3Month = todayMDate.valueOf() <= statusMDate.valueOf();
    useEffect(() => {
        const suburbIds = securityProvider.getUserProfile()?.selectedSuburbs;
        if (suburbIds) {
            setSuburbNames('');
            var tempNames = '';
            suburbIds.forEach(async (s) => {
                const suburbName = await getSuburbNameByIdFromLocalData(s);
                tempNames += '· ' + suburbName + '<br />';
            });
            setTimeout(() => {
                setSuburbNames(tempNames);
            }, 1000);
        }
    }, []);

    const saveCareSuburbs = async () => {
        const updateResult = await updateAreaMemberCareSuburbs(form.getFieldValue('selectedSuburbs'));
        console.log(updateResult);
    };

    const presentMessage = () => {
        if (popType == 1) {
            return (
                <>
                    <MemberIcon />
                    <Margin tm={15}></Margin>
                    <div style={{ fontSize: 13, color: '#333333', fontWeight: 400, marginTop: 5 }}>
                        <TR name="exceedUsage_notAreaMember"></TR>
                    </div>
                </>
            );
        }

        if (popType == 10) {
            return (
                <>
                    <MemberIcon />
                    <Margin tm={15}></Margin>
                    <div style={{ fontSize: 13, color: '#333333', fontWeight: 400, marginTop: 5 }}>
                        <TR name="unverified_email_user"></TR>
                    </div>
                </>
            );
        }

        if (popType == 2) {
            return (
                <>
                    <MemberIcon />
                    <Margin tm={15}></Margin>
                    <div style={{ fontSize: 13, color: '#333333', fontWeight: 400, marginTop: 5 }}>
                        <TR name="exceedUsage_areaMember"></TR>
                    </div>
                </>
            );
        }

        if (popType == 20) {
            return (
                <>
                    <MemberIcon />
                    <Margin tm={15}></Margin>

                    <div style={{ fontSize: 13, color: '#333333', fontWeight: 400, marginTop: 5 }}>
                        <TR name="exceedUsage_memberMessage"></TR>
                    </div>
                </>
            );
        }

        if (popType == 50) {
            return (
                <>
                    <MemberIcon />
                    <Margin tm={15}></Margin>
                    <div style={{ fontSize: 13, color: '#333333', fontWeight: 400, marginTop: 5 }}>
                        <TR name="wholeAreaMember_user"></TR>
                    </div>
                </>
            );
        }

        if (popType == 60) {
            return (
                <>
                    <MemberIcon />
                    <Margin tm={15}></Margin>
                    <div style={{ fontSize: 13, color: '#333333', fontWeight: 400, marginTop: 5 }}>
                        <TRHtml html="wholeAreaMember_cancelled"></TRHtml>
                    </div>
                </>
            );
        }

        if (popType == 4 || popType == 30) {
            return (
                <>
                    <MemberIcon />
                    <Margin tm={20}></Margin>
                    <div style={{ fontSize: 13, color: '#666666', fontWeight: 400, marginTop: 10 }}>
                        <TR name="exceedDaily_memberMessage"></TR>
                    </div>
                    {(areaUserWithin3Month || suburbChanged) && (
                        <>
                            <div
                                style={{
                                    background: '#eff1f1',
                                    borderRadius: '4px',
                                    marginTop: 6,
                                    color: '#333333',
                                    fontWeight: 400,
                                    fontSize: 14,
                                    padding: '12px 11px ',
                                }}>
                                <TR name="caringSuburbs"></TR> :
                                <div dangerouslySetInnerHTML={{ __html: suburbNames }} />
                            </div>

                            <div style={{ marginTop: 11, fontSize: 13, color: '#666666' }}>
                                <TRHtml
                                    html={suburbChanged ? 'suburbsForWholeAreaMember' : 'when_change_suburbs'}
                                    params={{ allow_date: after3Months.format('YYYY-MM-DD') }}></TRHtml>
                            </div>
                        </>
                    )}
                    {!areaUserWithin3Month && !suburbChanged && (
                        <>
                            <Margin tm={15}></Margin>
                            <InputSignUp
                                form={form}
                                name="careSuburbs"
                                suburbChoose
                                label=""
                                existingSuburbs={securityProvider.getUserProfile().selectedSuburbs}
                                placeholder="your_care_suburbs"></InputSignUp>
                            <Margin tm={15}></Margin>

                            <Button
                                style={{
                                    ...mobileNavButton,
                                    background: '#009e90',
                                    height: '42px',
                                    color: 'white',
                                    width: '100%',
                                    border: '1px solid #979797',
                                    borderRadius: '4px',
                                }}
                                onClick={saveCareSuburbs}
                                fullWidth
                                variant="contained"
                                disableElevation>
                                <TR name={'Save_Suburb_Modification'} />
                            </Button>
                        </>
                    )}
                </>
            );
        }
        if (popType == 6) {
            return <TR name="exceedDaily_wholeAreaMessage"></TR>;
        }
    };
    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div className={classes.titleBorder}>
                {popType == 6 && <TR name="wholeAreaMember"></TR>}
                {popType == 0 && (
                    <div className={classes.title}>
                        <TRHtml html="NON_MEMBER_PRICE_TITLE"></TRHtml>
                    </div>
                )}
                {popType != 6 && popType !== 0 && (
                    <div className={classes.title}>
                        <TR name="memeberPermission"></TR>
                    </div>
                )}
                <Margin tm={15}></Margin>
                {presentMessage()}
                {popType == 6 && (
                    <>
                        <Margin tm={15}></Margin>
                        <Button
                            style={{
                                ...mobileNavButton,
                                background: '#009e90',
                                height: '42px',
                                color: 'white',
                                width: '100%',
                                border: '1px solid #979797',
                                borderRadius: '4px',
                            }}
                            onClick={() => {}}
                            fullWidth
                            variant="contained"
                            disableElevation>
                            <TR name={'Contact Us'} />
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

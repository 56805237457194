import React, { useEffect, useState } from 'react';
import { generateGUID, SecurityProvider, TR } from '../../../../commons';
import { getSuburbDataInMapView } from '../../../../services/suburb';
import { StreetView } from '../../../PriceMap/Layers/PropertyInfo';
import { AutoAddressComplete } from '../autoAddressComplete';
import { StandardCard } from '../standardCard';
import { SuburbBasicInfo } from './basicInfo';
import { SuburbExplore } from './suburbExplore';
import { SuburbMedianInfo } from './suburbMedianInfo';
import { queryLocality_byGraphQL, queryProp_byGraphQL } from '../../../../commons/graphqlClient/graphAPI';
import { SuburbSchoolCard } from './suburbSchoolCard';
import { RecentSuburbSold } from './recentSuburbSold';
import { PriceInSuburb } from './priceInSuburb';
import { api_getSoldProperties } from '../../../../services/property';
import { MapGlobalVars } from '../../../PriceMap/support/PriceMapConsts';
import { AwesomeIcon } from '../../../../components/fontAwesomeIcon';
import { StreetInSuburb } from './streetsInSuburb';
import { Button, CircularProgress } from '@material-ui/core';
import { mobileNavButton } from '../../consts/styleConsts';
import { CardLine } from '../cardSeperate';
import { api_getPropertyFeatures } from '../../../../services/property';
import { constructPropObjFromFeature } from '../../../PriceMap/support/propertyFeatureHelper';
import { SuburbPriceTrend1 } from './suburbPriceTrend1';
import { SuburbPriceTrend2 } from './suburbPriceTrend2';
import { ReportMap } from '../../../PropertyReport/components/reportMap/ReportMap';
import { Margin } from '../../../../components/margin';
import { Permission } from '../../../../commons/security/authz';
import { AppProvider } from '../../../../commons/appProvider';
import { StreetRankCard } from './streetRankCard';
import { useHistory } from 'react-router';
import { NoVerifyEmailReceived } from '../../member/verifyEmail/NoVerifyEmailReceived';
import { exceedDailyLimit, saveSearchHistory, saveUserAccessLog } from '../../../../services/userCenter';
import { UserCenterEntityType } from '../../../../models/backend/userCenter';
import { MemberInfo } from '../../member/memberInfo';
import { VariousPopup } from '../../member/signUp/TipAfterSignup';

declare var window: any;

export const MobileSuburbDetail = (props: any) => {
    window.gtag('config', 'UA-148014445-2', {
        page_title: 'Suburb Details On Suburb',
        page_path: '/mobile_SuburbDetails',
    });

    const history = useHistory();
    const securityProvider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const { choosedItem, itemChoosedEvt, backEvt } = props;
    const [suburbData, setSuburbData] = useState(null);
    const [suburbData_ql, setSuburbData_ql] = useState(null);
    const [suburbSold, setSuburbSold] = useState(null);
    const [onePropertyPQL, setOnePropertyPQL] = useState(null);
    const [oneProperty, setOneProperty] = useState(null);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [suburbPoints, setSuburbPoints] = useState([]);
    const [showVerifyEmail, setShowVerifyEmail] = useState(false);
    const [showMember, setShowMember] = useState(false);
    const [showWelcomeAreaMember, setShowWelcomeAreaMember] = useState(false);
    const [popType, setPopType] = useState(-1);
    console.log(choosedItem);

    const [inDetails, setInDetails] = useState(false);

    const isMember = () => {
        return securityProvider.hasPermission(Permission.PROPERTY_BUYER);
    };
    const isTrial = () => {
        return securityProvider.hasPermission(Permission.TRIAL);
    };
    const userExpired = () => {
        return securityProvider.isExpired(
            securityProvider.hasPermission(Permission.PROPERTY_BUYER) ? Permission.PROPERTY_BUYER : Permission.TRIAL,
        );
    };
    const showSignup = () => {
        appProvider.state.authDialog = 'signup';
    };
    const toPayment = () => {
        window.open('/joinMember', '_self');
    };

    const showDetails = async () => {
        const doNext = await canDoNext(choosedItem?.value);
        if (doNext) {
            saveUserAccessLog('QUERY_SUBURB_DETAILS', securityProvider.getUserProfile()?.id, {
                suburbId: choosedItem?.value,
            });
            saveSearchHistory({
                entityId: choosedItem?.value,
                entityType: UserCenterEntityType.Suburb,
            });
            const currentURL = window.location.search;
            history.push(currentURL + '&showingDetails=true');
            setInDetails(true);
        }
    };

    const canDoNext = async (suburbId) => {
        try {
            await securityProvider.refreshProfile();
        } catch (e) {}
        if (!securityProvider.hasLogin()) {
            showSignup();
            return false;
        } else if (securityProvider.getUserProfile().userStatus == '1') {
            setShowVerifyEmail(true);
            return false;
        } else if (securityProvider.getUserProfile().userStatus == '3') {
            const limitedSuburbs = securityProvider.getUserProfile().selectedSuburbs;
            if (limitedSuburbs.indexOf(suburbId) > -1) {
                return true;
            } else {
                alert('Viewed area is out of range!!');
            }
        } else {
            const userUsage: any = await exceedDailyLimit(suburbId);
            if (userUsage.popType != -1) {
                setShowMember(true);
                setPopType(userUsage.popType);
                return false;
            }
        }
        return true;
    };

    const compId = generateGUID();

    const changeSuburb = async (choosedSuburb) => {
        if (inDetails) {
            saveUserAccessLog('QUERY_SUBURB_DETAILS', securityProvider.getUserProfile()?.id, {
                suburbId: choosedSuburb,
            });
            const doNext = await canDoNext(choosedSuburb);

            if (!doNext) {
                return;
            }
        } else {
            saveUserAccessLog('QUERY_PROP_STREET_SUBURB', securityProvider.getUserProfile()?.id, {
                queryType: '3',
                queryId: choosedSuburb,
            });
        }

        getSuburbData(choosedSuburb);
    };

    const getSuburbData = (suburbId: any) => {
        setLoading1(true);
        setLoading2(true);
        setLoading3(true);
        setLoading4(true);
        const querySuburbId = suburbId || choosedItem?.value;
        getSuburbDataInMapView([querySuburbId]).then((response: any) => {
            setSuburbData(response[0]);
            setLoading1(false);
        });
        queryLocality_byGraphQL(querySuburbId).then((response: any) => {
            console.log(response);
            setSuburbData_ql(response.data);
            MapGlobalVars.sectorMontlyData = response.data?.locality.bigSector;
            setLoading2(false);
        });

        const postData = {
            idList: [querySuburbId],
            period: 6,
        };
        api_getSoldProperties(postData).then((result: any) => {
            if (result && result.length > 0) {
                console.log(result);
                setSuburbSold(result[0]);
                setLoading3(false);
                try {
                    const onePropertyId = result[0]?.houseSold[0].prop_id;

                    queryProp_byGraphQL(onePropertyId).then((propertyResult: any) => {
                        if (propertyResult.errors) {
                        } else {
                            setOnePropertyPQL(propertyResult.data);
                            setLoading4(false);
                        }
                    });

                    api_getPropertyFeatures(onePropertyId).then((property: any) => {
                        if (property[0] == null) {
                            return;
                        }
                        setOneProperty(constructPropObjFromFeature(property));
                        console.log(property);
                        if (property[0].closest_station) {
                            const trainPoint = {
                                type: 'train',
                                lat: property[0].closest_station.y,
                                lon: property[0].closest_station.x,
                            };
                            const schoolPoints = [];
                            property[0].primary.forEach((school) => {
                                schoolPoints.push({
                                    type: 'school',
                                    lat: school.y,
                                    lon: school.x,
                                });
                            });
                            setSuburbPoints([...schoolPoints, trainPoint]);
                        }
                    });
                } catch (e) {}
            }
        });
    };

    const handleBack = () => {
        if (inDetails) {
            setInDetails(false);
        } else {
            backEvt();
        }
    };

    useEffect(() => {
        getSuburbData(choosedItem.value);

        // if (
        //     !securityProvider.getUserProfile().userStatus &&
        //     appProvider.state.authDialog !== 'login' &&
        //     appProvider.state.authDialog !== 'signup'
        // ) {
        //     setTimeout(() => {
        //         setInterval(() => {
        //             appProvider.state.authDialog = 'login';
        //         }, 1000);
        //     }, 30 * 1000);
        // }
    }, []);
    const loadingFinished = () => {
        return loading1 && loading2 && loading3 && loading4;
    };
    return (
        <>
            <div style={{ display: 'flex', marginTop: 10, alignItems: 'center', width: '100%', marginLeft: 5 }}>
                <div style={{ marginRight: 8, marginLeft: 8 }}>
                    <AwesomeIcon
                        icon={<img src="assets/mobileIcons/leftArrow.svg"></img>}
                        clickEvt={handleBack}
                        style={{ fontSize: 20, color: '#b2b2b2', display: 'flex', alignItems: 'center' }}></AwesomeIcon>
                </div>
                <div style={{ flex: 0.95 }}>
                    <AutoAddressComplete
                        init_choosedItem={props.choosedItem}
                        itemChoosedEvt={changeSuburb}
                        queryType={'3'}></AutoAddressComplete>
                </div>
            </div>
            {
                <div style={{ display: 'flex', padding: '10px', fontSize: '13px' }}>
                    {' '}
                    <TR name="fiveSuburbFound"></TR>
                    <TR name="colon"></TR>
                </div>
            }

            {suburbData ? (
                <>
                    <div
                        style={{
                            marginTop: '1rem',
                            width: '100%',
                        }}>
                        <ReportMap
                            notDrawCenter
                            propCenter={{
                                lat: suburbData.lat,
                                lon: suburbData.lon,
                            }}
                            drawingObject={'7'}
                            addtionData={suburbPoints}
                            drawingData={{
                                border: suburbData.polygon_encoded,
                                suburbName: suburbData.locality_name,
                                propType: 'apt',
                            }}></ReportMap>
                        {/* <StreetView
                            w={window.screen.width}
                            h={window.screen.width / 2}
                            lat={suburbData.lat}
                            imageBorderRadius={1}
                            lng={suburbData.lon}
                            onMap={false}
                            showFor="infopanel"
                            address={suburbData.locality_name}></StreetView> */}
                    </div>
                    <Margin tm="33px"></Margin>
                    {suburbData_ql && onePropertyPQL ? (
                        <SuburbBasicInfo
                            inDetails={inDetails}
                            suburbQL={onePropertyPQL}
                            suburbDataQL={suburbData_ql}
                            suburbData={suburbData}></SuburbBasicInfo>
                    ) : null}

                    {inDetails ? null : (
                        <>
                            <div style={{ marginTop: 20 }}></div>

                            <SuburbMedianInfo suburbData={suburbData}></SuburbMedianInfo>
                        </>
                    )}
                    {inDetails ? (
                        <>
                            <div style={{ marginTop: 10 }}></div>
                            <CardLine></CardLine>

                            {suburbData_ql ? (
                                <SuburbExplore
                                    property={oneProperty}
                                    propertyQL={onePropertyPQL}
                                    localityData={suburbData_ql}></SuburbExplore>
                            ) : null}
                            <div style={{ marginTop: 10 }}></div>
                            <CardLine></CardLine>
                            {onePropertyPQL ? (
                                <SuburbSchoolCard
                                    suburbData0={suburbData}
                                    suburbData={onePropertyPQL}></SuburbSchoolCard>
                            ) : null}
                            <CardLine></CardLine>
                            {suburbData && suburbSold ? (
                                <RecentSuburbSold
                                    suburbData={suburbData}
                                    polygon_encoded={suburbData.polygon_encoded}
                                    lat={suburbData.lat}
                                    lon={suburbData.lon}
                                    soldData={suburbSold}></RecentSuburbSold>
                            ) : null}

                            <CardLine></CardLine>

                            {suburbData_ql && <StreetRankCard suburbData={suburbData_ql}></StreetRankCard>}

                            <CardLine></CardLine>

                            {suburbSold && suburbData_ql ? (
                                <PriceInSuburb soldPropertiesInThisSuburb={suburbSold.houseSold}></PriceInSuburb>
                            ) : null}

                            <CardLine></CardLine>

                            <SuburbPriceTrend2 suburbData={suburbData}></SuburbPriceTrend2>

                            <CardLine></CardLine>

                            <SuburbPriceTrend1 suburbData={suburbData}></SuburbPriceTrend1>

                            <CardLine></CardLine>

                            {loading2 ? (
                                <CircularProgress></CircularProgress>
                            ) : (
                                <StreetInSuburb suburbData={suburbData_ql.locality}></StreetInSuburb>
                            )}
                        </>
                    ) : null}
                </>
            ) : null}
            {inDetails ? null : (
                <>
                    <div style={{ marginTop: 20 }}></div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', margin: '10px 0px' }}>
                        <Button
                            style={{
                                ...mobileNavButton,
                                width: '80%',
                                height: appProvider.getLanguage() == 'zh' ? '42px' : '60px',
                                background: '#009e90',
                                color: 'white',
                            }}
                            onClick={showDetails}
                            variant="outlined">
                            <TR name="MoreSuburbInfo"></TR>
                        </Button>
                    </div>
                </>
            )}
            <NoVerifyEmailReceived
                closeMessage={() => {
                    setShowVerifyEmail(false);
                }}
                showVerifyEmail={showVerifyEmail}></NoVerifyEmailReceived>

            <MemberInfo
                show={showMember}
                popType={popType}
                onSuccessAreaMember={() => {
                    setShowMember(false);
                    setShowWelcomeAreaMember(true);
                }}
                onClose={() => {
                    setShowMember(false);
                }}></MemberInfo>

            <VariousPopup
                popType={popType}
                open={showWelcomeAreaMember}
                messageTitleKey={'welcomeAsAreaMember'}
                messageKey={'areaMemberBenefit'}
                onClose={() => setShowWelcomeAreaMember(false)}></VariousPopup>
        </>
    );
};

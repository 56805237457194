import { getYear, isAfter, isBefore, parse } from 'date-fns';
import moment from 'moment';
import { MobileBreakpoint } from './constants';
import { getDaysBetweenTwoDate, parseDate } from './date';
import queryString from 'query-string';
import { Base64 } from 'base64-string';
import { API } from './axios';
import { MAP_APIS_ENDPOINTS } from '../services/EndPoints';

var polyline = require('google-polyline');
declare var L;

export const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
export const jsonHasKey = (jsonObj: any) => {
    let hasKey = false;
    Object.keys(jsonObj).forEach((key: any) => {
        hasKey = true;
    });
    return hasKey;
};

export function debounce(func: () => void, delayInMilliseconds: number, immediate?: boolean) {
    let timeout, result;

    const debounced = function () {
        // @ts-ignore
        const context = this;
        const args = arguments;
        if (timeout) clearTimeout(timeout);

        const later = function () {
            timeout = null;
            // @ts-ignore
            if (!immediate) result = func.apply(context, args);
        };

        const callNow = immediate && !timeout;
        timeout = setTimeout(later, delayInMilliseconds);
        // @ts-ignore
        if (callNow) result = func.apply(this, args);

        return result;
    };
    debounced.cancel = function () {
        clearTimeout(timeout);
        timeout = null;
    };
    return debounced;
}

export const compareStringsIgnoreCase = (str1, str2) => {
    return str1.trim().toLowerCase() == str2.trim().toLowerCase();
};

/***
 * Generate uuid
 */

export const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

/**
 *
 * @param jsonArray
 * @param searchConditionJson , like {keyA : 'valueA',keyB : 'valueB'}
 *
 */
export function getItemFromArrayByMulKeyPair(jsonArray: any, searchConditionJson: any) {
    var searchResult = [];

    if (jsonArray && jsonArray.length > 0) {
        for (var i = 0; i < jsonArray.length; i++) {
            const loopItem = jsonArray[i];

            var rightItem: boolean = true;
            Object.keys(searchConditionJson).forEach((loopKey) => {
                if (loopItem[loopKey] != searchConditionJson[loopKey]) {
                    rightItem = false;
                }
            });

            if (rightItem) {
                searchResult.push(loopItem);
            }
        }
    }

    return searchResult;
}

/** */
export function sortNumArray(sortingArray) {
    return sortingArray.sort((p1, p2) => {
        p1 = parseInt(p1);
        p2 = parseInt(p2);
        if (p1 - p2 > 0) {
            return 1;
        }
        if (p1 - p2 == 0) {
            return 0;
        }
        if (p1 - p2 < 0) {
            return -1;
        }
    });
}

export function sortDateArray(sortingArray) {
    return sortingArray.sort((p1, p2) => {
        p1 = parseDate(p1);
        p2 = parseDate(p2);
        if (isAfter(p1, p2)) {
            return 1;
        }
        if (getDaysBetweenTwoDate(p1, p2) == 0) {
            return 0;
        }
        if (isBefore(p1, p2)) {
            return -1;
        }
    });
}

export function sortJsonArrayByAttr(sortingArray, attr, dateField: boolean, dire) {
    if (!sortingArray) {
        return;
    }
    return sortingArray.sort((itemA, itemB) => {
        let convertedItemA = itemA[attr];
        let convertedItemB = itemB[attr];
        if (dateField) {
            convertedItemA = moment(itemA[attr]);
            convertedItemB = moment(itemB[attr]);
        }
        if (dire == 'ASC') {
            // Asc sort

            return convertedItemA - convertedItemB;
        } else {
            // Desc Sort
            return convertedItemB - convertedItemA;
        }
    });
}

export function getCurrentYear() {
    return moment().year();
}

export function getPriceRank(price, priceList) {
    let rank: any = 0;
    if (priceList) {
        let position = 0;
        for (let i = 0; i < priceList.length; i++) {
            if (price <= priceList[i]) {
                position = i;
                break;
            }
        }

        rank = ((position / priceList.length) * 100).toFixed(0);
    }
    return rank;
}
export function withinMonth(date1, date1Frt?) {
    if (!date1) {
        return false;
    }
    const date1Mom = moment(date1, date1Frt || 'YYYY-MM-DD');
    return date1Mom.add(31, 'days').isAfter(moment());
}
export function yearAgo(date1, date1Frt?) {
    if (!date1) {
        return true;
    }
    const date1Mom = moment(date1, date1Frt || 'YYYY-MM-DD');
    return date1Mom.add(365, 'days').isBefore(moment());
}
export function getYearsDifferenceFromNow(date1, date1Frt) {
    const date1Mome = moment(date1, date1Frt);
    const date1Year = date1Mome.year();

    const currentDateYear = moment().year();

    return currentDateYear - date1Year;
}

/*
/**
 * get item by keyvalue from json array, if not found return null
 */
export function getItemFromArray(jsonArray: any, lookingKey: string, keyValue: any) {
    if (jsonArray && jsonArray.length > 0) {
        for (let i = 0; i < jsonArray.length; i++) {
            const loopItem = jsonArray[i];

            if (loopItem && loopItem[lookingKey] == keyValue) {
                return loopItem;
                break;
            }
        }
    }

    return null;
}

/**
 *
 * remove items from json array accoding specified key anv its value
 */
export function removeElementsFromJson(jsonArray: any, key, value) {
    const newArray = jsonArray.filter((loopItem) => {
        return loopItem[key] != value;
    });
    return newArray;
}

/** remove given value from array not json array */
export function removeItemFromArray(array_var, value) {
    const newArray = [];
    for (let i = 0; i < array_var.length; i++) {
        if (array_var[i] != value) {
            newArray.push(array_var[i]);
        }
    }
    return newArray;
}

export function stickNumTo5_10(num) {
    const num_10 = Math.floor(num / 10) * 10;
    if (num - num_10 > 5) {
        return num_10 + 10;
    }
    if (num - num_10 < 5) {
        return num_10;
    }
    if (num - num_10 == 5) {
        return num_10 + 5;
    }
}

export function getYearMonthFromSoldDate(soldDate) {
    if (soldDate) {
        return soldDate.substr(0, 7);
    } else {
        return '';
    }
}

export const getPct = (value, digital?) => {
    return (parseFloat(value) * 100).toFixed(digital || 0) + '%';
};

/**
 *
 * @param 返回当前日期和指定日期查了多少年了。
 */
export function getYearDifferenceFromNow(date1, date1Frt) {
    const date1Mome = parse(date1, date1Frt, new Date());
    const date1Year = getYear(date1Mome);

    const currentDateYear = getYear(new Date());

    return currentDateYear - date1Year || 'n/a';
}

/**
 * convert encoded polygon string to latlng array
 */
export function getLatlngArrayFromEncodedPath(encodedPath) {
    const decodedPath = polyline.decode(encodedPath);
    const latLngArray = [];
    if (decodedPath) {
        decodedPath.forEach((loopArrayItem) => {
            latLngArray.push([loopArrayItem[1], loopArrayItem[0]]);
        });
    }

    return latLngArray;
}
export function pointInBounds(point: { lat: number; lon: number }, bounds: any) {
    var latLng = L.latLng(point.lat, point.lon);
    return bounds.contains(latLng);
}

/**
 *
 * @param dateString  the date format should be YYYY-MM-DD
 * @param part
 */
export function getDatePart(dateString: string, part) {
    if (part == 'Y') {
        return dateString.substr(0, 4);
    }
    if (part == 'M') {
        return dateString.substr(5, 2);
    }
    if (part == 'D') {
        return dateString.substr(8, 2);
    }
}

export function reverseToD(mPrice: any) {
    return parseFloat(mPrice) * 1000000;
}
export function convertToM(price: any): any {
    return (price / 1000000).toFixed(2);
}

//convert price to K
export function priceToK(price: number): string {
    return '$' + price / 1000 + 'K';
}
//convert numbers to $+xxx+m, such as 1000000 -> $1.00m
export function numToMillion(num: number): string {
    if (!num || num == 0) {
        return '-';
    }
    const millionNum = (num / 1000000).toFixed(2);
    const millionPrice = `\$${millionNum}m`;
    return millionPrice;
}

//convert decimals to percentage
export function decimalToPct(num: number): string {
    const pctNum = (num * 100).toFixed(1);
    const pct = `${pctNum}%`;
    return pct;
}

//convert number to $
export function numToMoney(num: number): string {
    const Price = `\$${num}`;
    return Price;
}

/***Detect the screen width to check is it mobile device */
export function isMobileDevice() {
    return window.screen.width <= MobileBreakpoint;
}

/*** convert to percentage */
export function toPct(num) {
    return (num * 100).toFixed(2) + '%';
}

/**** extract parameter value from url  */
export function getParamValueFromURL(urlString, paramKey, replaceChar = true) {
    console.log(queryString.parse(urlString));
    // there is bug for query-string, which parse '+' as space, so need to replace it back
    var keyValue: any = queryString.parse(urlString)[paramKey];
    if (keyValue && keyValue.indexOf(' ') > -1 && replaceChar) {
        console.log('has space in it');
        keyValue = keyValue.replace(' ', '+');
    }
    return keyValue;
}

// export async function getSuburbImage(suburb: string) {
//     const photoResponse = await API.post(null, MAP_APIS_ENDPOINTS.GET_PHOTO_REF_FOR_SUBURB, { suburbName: suburb });
//     return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${photoResponse}&key=AIzaSyC2AcKTS_-5hsVcvogRQr8flhpPAS65tjk`;
// }

export function getStreetImageForProp(address: string, zoom?) {
    zoom = zoom || 30;
    return `https://maps.googleapis.com/maps/api/streetview?location=${address}&size=500x500&fov=${zoom}&key=AIzaSyC2AcKTS_-5hsVcvogRQr8flhpPAS65tjk`;
}
export function openGoogleNav(address: string, lat, lon) {
    window.open(`https://www.google.com/maps/dir/${lat},${lon}/${address}`);
}

export function removeSpaceAndWords(name: string, removeSpaceAndWords: string) {
    const newName = name.replace(removeSpaceAndWords, '');
    return newName;
}

export function removeZeroFromArray(obj: { ranking: number[] }) {
    if (obj !== null) {
        const newArray = obj.ranking.map((value) => {
            // if (value >= 200 || value === 0 || value === null) {
            if (value === 0) {
                return 230;
            } else {
                return value;
            }
        });
        return newArray;
    } else {
        return [0, 0, 0, 0, 0];
    }
}

export function findMaxInArray(array: number[]) {
    if (array.length != 0) {
        const newArray = Math.max(...array);
        return newArray;
    } else {
        console.log('array is empty!');
    }
}

export function findMinInArray(array: number[]) {
    if (array.length != 0) {
        const newArray = Math.min(...array);
        return newArray;
    } else {
        console.log('array is empty!');
    }
}

export function kFormatter(num) {
    if (typeof num === 'number') {
        return Math.abs(num) > 999 ? (Math.abs(num) / 1000).toFixed(1) + 'k' : Math.abs(num);
    } else {
        return Math.abs(Number(num)) > 999 ? (Math.abs(Number(num)) / 1000).toFixed(1) + 'k' : Math.abs(Number(num));
    }
}

export function decodeB64Str(b64Str) {
    const enc = new Base64();
    return enc.decode(b64Str);
}

var pinyin = require('pinyin');

export function getPinyin(originChinese) {
    const pinyinStr = pinyin(originChinese);
    return pinyinStr;
}

export function changeToFirstCaptial(str) {
    console.log(str);
    if (!str) {
        return '';
    }
    var addressRemoveState = str.indexOf('NSW') != -1 ? str.substr(0, str.indexOf('NSW')) : str;
    console.log(addressRemoveState);
    const lastCharacter = addressRemoveState.trim().substr(addressRemoveState.trim().length - 1);

    if (lastCharacter == ',') {
        addressRemoveState = addressRemoveState.substr(0, addressRemoveState.trim().length - 1);
    }

    const strArray = addressRemoveState.split(' ');
    console.log(strArray);
    var returnStr = '';
    strArray.forEach((s) => {
        returnStr += s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase() + ' ';
    });

    returnStr =
        returnStr.substring(returnStr.length - 1) == ',' ? returnStr.substring(0, returnStr.length - 2) : returnStr;
    return returnStr;
}

// export function getArrayFromObjArray(data) {
//     var newStreetArray = [];
//     if (data && data > 0) {
//         data.map((house) => {
//             newStreetArray.push(Object.values(house));
//             console.log(newStreetArray);
//         });
//         console.log(newStreetArray);
//     }
//     return newStreetArray;
// }

import { request } from 'http';
import React from 'react';
import { API } from '../../../commons';
import { MAP_APIS_ENDPOINTS } from '../../../services/EndPoints';
import { getSuburbByPoint } from '../../../services/suburb';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import {
    getItemFromArray,
    getItemFromArrayByMulKeyPair,
    getLatlngArrayFromEncodedPath,
    getParamValueFromURL,
    isMobileDevice,
    removeElementsFromJson,
} from '../../../commons/utils';
import { MAP_LAYER_TYPES } from '../../Suburb/MapContainer/controller/MapConsts';

import { changeAddressURL, getPriceMapLevelByZoom, getSuburbColor } from '../support/common';
import { PriceMapFilter, PriceMapLayerItem } from '../support/DataModels';
import {
    FullPriceGrades,
    FullPriceGradesFor2BedsRent,
    MapGlobalVars,
    MapLevel,
    PriceMapLayerType,
    PRICE_MAP_STATUS,
} from '../support/PriceMapConsts';
import ReactDOM from 'react-dom/server';
import { PropertyMarker } from './PropertyMarker';
import { be } from 'date-fns/locale';
import { suburbDataList, SuburbPolygonController } from './SuburbPolygonController';
import { PropertyMarkerController } from './PropertyMarkerController';
import { string } from '../../../components/form';
import { SuburbNameController } from './SuburbNameController';
import { MapRouteController } from './MapRouteController';
import { MapOverlayController } from './MapOverlayController';
import { AllPropertiesLayerController } from './AllPropertiesLayerController';
import { getVisibleSuburbIds } from '../../../services/suburb';
import { FavMarkerController } from './FavMarkerController';
import { getFavourites } from '../../../services/userCenter';
import { p_icon } from './PropertyIcon';
import { queryLocality_byGraphQL } from '../../../commons/graphqlClient/graphAPI';
import { ROUTES } from '../../../routes';

const win: any = window;
const L = win.L;

export class PriceMapController {
    map: any;
    mapBounds: any;
    fromIndex: boolean;
    mapStateOnIndex: any;
    myLocationMarker: any;

    suburbPolygonController: SuburbPolygonController;
    propertyMarkerController: PropertyMarkerController;
    suburbNameController: SuburbNameController;
    mapRouteController: MapRouteController;
    mapOverlayController: MapOverlayController;
    allPropertiesLayerController: AllPropertiesLayerController;
    favMarkerController: FavMarkerController;

    topPageMapZoomHandler: any;
    suburbPriceLabelStatus = '1';
    specialCareFilter: any = {
        schoolRanks: [],
        secondarySchoolRanks: [],
        trans: [],
        chineseImmi: [],
        indianImmi: [],
        islamImmi: [],
        distanceOptions: [],
        medianPriceRange: { lower: 0, upper: 0 },
        RentPriceRange: { lower: 0, upper: 0 },
        PrivateSchoolMixBoy: [],
        PrivateSchoolMixGirl: [],
        TenantProportion: [],
        HouseApt: [],
        FamilyIncome: [],
        PublicSecurity: [],
        AreaBusiness: [],
        Waterscape: [],
        Trees: [],
        LandSize: [],
        PriceChange3M: [],
        PriceChange6M: [],
    };
    priceMapFilter: PriceMapFilter = {
        suburbId: '',
        suburbName: '',
        propertyType: 'House',
        bedOptions: ['total'],
    };

    securityProvider: any;

    constructor(mapInst, zoomHandler, fromIndex, mapState, securityProvider) {
        this.mapStateOnIndex = mapState;
        this.fromIndex = fromIndex;
        this.map = mapInst;
        this.securityProvider = securityProvider;
        this.suburbPolygonController = new SuburbPolygonController(this.map, this.securityProvider);
        this.propertyMarkerController = new PropertyMarkerController(this.map);
        this.suburbNameController = new SuburbNameController(this.map);
        this.topPageMapZoomHandler = zoomHandler;
        this.mapRouteController = new MapRouteController(this.map);
        this.mapOverlayController = new MapOverlayController(this.map);
        this.allPropertiesLayerController = new AllPropertiesLayerController(this.map);
        this.favMarkerController = new FavMarkerController(this.map);

        this.topPageMapZoomHandler(this.map.getZoom());

        this.setMapEvents();

        EventBus.on(EVENT_NAMES.EVENT_ZOOM_MAP, (zoomPara: any) => {
            if (zoomPara.zoomValue) {
                this.map.setZoom(zoomPara.zoomValue);
            } else {
                if (zoomPara.zoomDirect == 'in') {
                    this.map.setZoom(this.map.getZoom() + 1);
                } else {
                    this.map.setZoom(this.map.getZoom() - 1);
                }
            }
        });

        // if it is under property level, then remove all property markers first
        EventBus.on(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, (evt: any) => {
            if (evt.showAroundTrans) {
                this.propertyMarkerController.clearAllMarkerFromMap();
            }
        });

        EventBus.on(EVENT_NAMES.EVENT_SHOW_ALL_TRANS, () => {
            this.suburbPolygonController.clearPreviouseSuburbSelection();
            this.priceMapFilter.suburbId = '';
            this.priceMapFilter.suburbName = '';
            this.routeMap();
        });

        EventBus.on(EVENT_NAMES.EVENT_CHANGE_PERIOD, (evt) => {
            const period = evt.period;
            MapGlobalVars.priceMapFilter.period = period;
            this.propertyMarkerController.clearPreviousDataBeofreChangeP();
            this.routeMap(period);
        });

        EventBus.on(EVENT_NAMES.EVENT_CHANGE_FILTER, (newFilter: any) => {
            /** copy the selected suburb id & suburb name from current price map filter as filter panel doese not have these 2 proeprties */
            newFilter.suburbId = this.priceMapFilter.suburbId;
            newFilter.suburbName = this.priceMapFilter.suburbName;

            this.priceMapFilter = newFilter;

            this.applyFilterChange();
        });

        EventBus.on(EVENT_NAMES.EVENT_REQEUST_SEARCHED_PROP_SUBURB_DATA, async (evt) => {
            const suburbId = evt.suburbId;
            await this.suburbPolygonController.checkMapviewSuburbData([suburbId], this.priceMapFilter);

            MapGlobalVars.clickedSuburbData = getItemFromArray(suburbDataList, 'id', suburbId);
        });

        EventBus.on(EVENT_NAMES.EVENT_TOGGLE_PRICE_LABEL, () => {
            const currentMapLevel = getPriceMapLevelByZoom(this.map.getZoom());
            if (currentMapLevel == PRICE_MAP_STATUS.PROPERTYLEVEL) {
                this.propertyMarkerController.togglePropertyMarkerPL();
            } else {
                this.applySpecialFilter();
            }
        });

        EventBus.on(EVENT_NAMES.EVENT_SUBURB_POLYGON_CLICKED, (evt) => {
            this.priceMapFilter.suburbId = evt.suburbData.id;
            this.priceMapFilter.suburbName = evt.suburbData.locality_name;
        });

        EventBus.on(EVENT_NAMES.EVENT_TOGGLE_FAV_ON_MAP, async (evts) => {
            const myFavs = evts.allFavs;
            if (MapGlobalVars.showFavOnMap) {
                this.favMarkerController.drawAllFavOnMap(myFavs);
            } else {
                if (MapGlobalVars.showAroundTransCircle) {
                    EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, { showAroundTrans: false });
                }
                this.favMarkerController.removeAllFavOnMap();
            }
        });

        EventBus.on(EVENT_NAMES.EVENT_SHOW_CLICKED_SUBURB_TRANS, (evt) => {
            this.map.setZoom(MapLevel.propertyLevel.lower);
            this.suburbPolygonController.setSuburbBorder(this.map.getZoom());
            console.log(evt);
            const moveToPoint = evt.movedTo;
            const clickedSuburbId = evt.suburbId;
            this.priceMapFilter.suburbId = clickedSuburbId;
            this.priceMapFilter.suburbName = evt.suburbName;

            this.map.setView(moveToPoint);
        });

        EventBus.on(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, (evt) => {
            this.suburbNameController.clearAllCurrentSuburbName();
            // if showing around, should cancel it , then do rest
            if (MapGlobalVars.showAroundTransCircle) {
                EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, {
                    showAroundTrans: false,
                });
                MapGlobalVars.showAroundTransCircle = false;
            }

            this.priceMapFilter.suburbId = evt.suburbId;
            this.priceMapFilter.suburbName = evt.suburbName;

            // get big sector data
            queryLocality_byGraphQL(evt.suburbId).then((result: any) => {
                if (result && result.data) {
                    MapGlobalVars.sectorMontlyData = result.data?.locality.bigSector;
                }
            });
            // if click from home page,should open confirm to open in full screen
            if (fromIndex) {
                this.mapStateOnIndex.openDialog = true;
                return;
            }

            this.priceMapFilter.suburbId = evt.suburbId;
            this.priceMapFilter.suburbName = evt.suburbName;

            MapGlobalVars.showSuburbTransConfirm = true;

            this.suburbPolygonController.switchToSelectedSuburb(evt.suburbId, 1, this.priceMapFilter);
        });

        EventBus.on(EVENT_NAMES.EVENT_SWITCH_TO_ALL_PROPERTIES_LEVEL, () => {
            this.priceMapFilter.suburbId = '';
            this.priceMapFilter.suburbName = '';
            MapGlobalVars.showSuburbTransConfirm = false;
            MapGlobalVars.shouldStayOnPropertyLevel = false;
            this.map.setZoom(MapLevel.propertyLevel.upper - 1);
        });

        EventBus.on(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, (specialFilter) => {
            console.log(specialFilter);
            this.specialCareFilter = specialFilter;
            if (this.noSpecialCareCondition()) {
                this.suburbPriceLabelStatus = '1';
            } else if (this.suburbPriceLabelStatus == '1') {
                this.suburbPriceLabelStatus = '3';
            }
            console.log(this.suburbPriceLabelStatus);
            this.suburbPolygonController.togglePriceLabel(this.suburbPriceLabelStatus, this.specialCareFilter);
        });

        EventBus.on(EVENT_NAMES.EVENT_DRAW_MY_LOCATION, (evt) => {
            if (!this.myLocationMarker) {
                const iconHTML = ReactDOM.renderToString(
                    React.createElement(p_icon, {
                        clicked: true,
                        zoom: MapLevel.propertyLevel.mid,
                    }),
                );

                const locationIcon = new L.DivIcon({
                    className: '',
                    html: iconHTML,
                });

                this.myLocationMarker = L.marker([evt.lat, evt.lng], { icon: locationIcon }).addTo(this.map);
            }
        });

        const requestURL = window.location.search;
        if (requestURL.indexOf('suburbId') > -1) {
            const suburbId = getParamValueFromURL(requestURL, 'suburbId') as string;
            const suburbName = getParamValueFromURL(requestURL, 'suburbName') as string;
            const lat = getParamValueFromURL(requestURL, 'lat');
            const lng = getParamValueFromURL(requestURL, 'lng');

            this.priceMapFilter.suburbId = suburbId;
            this.priceMapFilter.suburbName = suburbName;
            setTimeout(() => {
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, {
                    suburbId: suburbId,
                    suburbName: suburbName,
                });
                this.suburbPolygonController.setSuburbBorder(this.map.getZoom());
                if (lat && lng) {
                    EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MY_LOCATION, {
                        lat: lat,
                        lng: lng,
                    });
                }
            }, 1500);
        }
        //
        if (requestURL.indexOf('mapType=1') > -1) {
            EventBus.dispatch(EVENT_NAMES.EVENT_FOLD_FILTER_PANEL, false);
            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_PRICE_MAP_TITLE, true);
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SUBURB_COLOR_BY, 'medianPrice');
        }

        if (requestURL.indexOf('showStreetRank') > -1) {
            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_LOADING_INDICATOR, {
                show: true,
                message: 'Loading',
            });
            setTimeout(() => {
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_LOADING_INDICATOR, {
                    show: false,
                    message: 'Loading',
                });
                EventBus.dispatch(EVENT_NAMES.EVENT_OPEN_STREET_DIALOG, {});
            }, 8000);
        }

        if (requestURL.indexOf('showSuburbSoldByBed') > -1) {
            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_LOADING_INDICATOR, {
                show: true,
                message: 'Loading',
            });
            setTimeout(() => {
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_LOADING_INDICATOR, {
                    show: false,
                });
                EventBus.dispatch(EVENT_NAMES.EVENT_OPEN_SOLD_BY_BED_DIALOG, {});
                changeAddressURL(ROUTES.HomePage.path);
            }, 8000);
        }

        if (requestURL.indexOf('showPropFav') > -1) {
            MapGlobalVars.showFavOnMap = true;

            setTimeout(async () => {
                const myFavs = await getFavourites();
                this.favMarkerController.drawAllFavOnMap(myFavs);
                EventBus.dispatch(EVENT_NAMES.EVENT_SET_FAV_BUTTON_STATUS, true);
            }, 1000);
        }

        this.routeMap();

        if (requestURL.indexOf('zoomFromIndex') > -1) {
            setTimeout(() => {
                this.map.setZoom(MapLevel.propertyLevel.lower + 1);
            }, 2000);
        }
    }

    previousZoom = 0;
    x_moving = 0;
    y_moving = 0;
    x_beforeMove = 0;
    y_beforeMove = 0;
    mapMoving = false;
    mapTapHoldTimeout;
    clicked = 0;
    setMapEvents() {
        this.map.on('click', (e) => {
            console.log('Lat, Lon : ' + e.latlng.lat + ', ' + e.latlng.lng);
            const clickedPoint = this.map.latLngToContainerPoint(e.latlng);
            console.log(clickedPoint);
            if (getPriceMapLevelByZoom(this.map.getZoom()) != PRICE_MAP_STATUS.SUBURBLEVEL) {
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_POP_MENU_ON_MAP, { point: clickedPoint, location: e.latlng });
            }
            EventBus.dispatch(EVENT_NAMES.EVENT_FOLD_SEARCH_INPUT, {});
        });
        this.map.on('zoomstart', () => {
            this.previousZoom = this.map.getZoom();
            //if user not login , then before enter the property level , disble the zoom function
            this.suburbNameController.clearAllCurrentSuburbName();
            if (MapGlobalVars.showingMapPopmenu) {
                EventBus.dispatch(EVENT_NAMES.EVENT_CLOSE_POP_MENU_ON_MAP, {});
            }
        });
        this.map.on('movestart', () => {
            this.mapMoving = true;
        });
        this.map.on('zoomend', () => {
            // if (MapGlobalVars.showingPropertyPop) {
            //     return;
            // }
            if (this.previousZoom != this.map.getZoom()) {
                //broadcast map zoomed
                EventBus.dispatch(EVENT_NAMES.EVENT_MAP_ZOOMED, this.map.getZoom());
            }
            MapGlobalVars.currentMapZoom = this.map.getZoom();

            if (
                this.previousZoom > this.map.getZoom() &&
                MapGlobalVars.shouldStayOnPropertyLevel &&
                this.map.getZoom() <= MapLevel.suburbLevel.upper - 1
            ) {
                setTimeout(() => {
                    MapGlobalVars.shouldStayOnPropertyLevel = false;
                }, 800);
            }

            this.suburbPolygonController.setSuburbBorder(this.map.getZoom());
        });

        this.map.on('moveend', async () => {
            // if on mobile and on ALL PROPERTY LEVEL , after map end movement, need to know which suburb is and show the suburb summary
            if (isMobileDevice && getPriceMapLevelByZoom(this.map.getZoom()) == PRICE_MAP_STATUS.ALLPROPERTYLEVEL) {
                const currentMapCenter = this.map.getCenter();
                console.log(currentMapCenter);
                const currentMapSuburb: any = await getSuburbByPoint(currentMapCenter);
                MapGlobalVars.clickedSuburbData = getItemFromArray(suburbDataList, 'id', currentMapSuburb.id);

                EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_MOBILE_TAB, { tabIdx: 1 });
            }
            this.topPageMapZoomHandler(this.map.getZoom());
            this.routeMap();
            this.mapMoving = false;
        });
    }

    async routeMap(period?) {
        if (MapGlobalVars.showAroundTransCircle || MapGlobalVars.showingPropertyPop) {
            return;
        }

        const suburbIdsWithinViewport: any = await this.getSuburbsInMapView();
        const previousMapLevel = getPriceMapLevelByZoom(this.previousZoom);
        const currentMapLevel = getPriceMapLevelByZoom(this.map.getZoom());

        if (currentMapLevel == PRICE_MAP_STATUS.SUBURBLEVEL) {
            console.log('start to clear property markers');

            this.priceMapFilter.suburbId = '';
            this.propertyMarkerController.clearAllMarkerFromMap();
            this.removeMyLocationMarker();
            this.allPropertiesLayerController.switchAllMarkersOnMap(false);
            MapGlobalVars.showSuburbTransConfirm = false;

            //this.suburbPolygonController.toggleSuburbPolygonsFromMap(true);

            await this.suburbPolygonController.checkMapviewSuburbData(suburbIdsWithinViewport, this.priceMapFilter);

            this.suburbNameController.drawSuburbNames();

            if (this.noSpecialCareCondition()) {
                this.suburbPolygonController.togglePriceLabel(this.suburbPriceLabelStatus);
            } else {
                this.suburbPolygonController.togglePriceLabel(this.suburbPriceLabelStatus, this.specialCareFilter);
            }
            MapGlobalVars.wholeMapDataLoaded = true;
            console.log('whole map data loaded: ' + MapGlobalVars.wholeMapDataLoaded);
        }

        if (currentMapLevel == PRICE_MAP_STATUS.PROPERTYLEVEL) {
            if (!MapGlobalVars.showFavOnMap) {
                if (!isMobileDevice()) {
                    // hide the sidebar for property level if there is
                    if (this.allPropertiesLayerController.clickedPropId) {
                        EventBus.dispatch(EVENT_NAMES.EVENT_PROP_SIDE_BAR, {
                            showSideBar: false,
                        });
                    }
                }

                if (this.propertyMarkerController.clickedPropId) {
                    const lastClickedPropertyMarker = this.propertyMarkerController.propertyMarkers[
                        this.propertyMarkerController.clickedPropId
                    ];
                    if (lastClickedPropertyMarker) {
                        if (!isMobileDevice()) {
                            EventBus.dispatch(EVENT_NAMES.EVENT_PROP_SIDE_BAR, {
                                showSideBar: true,
                                prop: lastClickedPropertyMarker.getMarkerProperty(),
                            });
                        } else {
                            // EventBus.dispatch(EVENT_NAMES.EVENT_PROP_MARKER_CLICKED, {
                            //     prop: lastClickedPropertyMarker.getMarkerProperty(),
                            // });
                        }
                    }
                }
            }

            // console.log('removing the current layer.');
            this.suburbPolygonController.toggleSuburbPolygonsFromMap(false);
            this.allPropertiesLayerController.switchAllMarkersOnMap(false);

            this.suburbPolygonController.togglePriceLabel(1);
            if (this.priceMapFilter.suburbId) {
                this.suburbPolygonController.switchToSelectedSuburb(
                    this.priceMapFilter.suburbId,
                    2,
                    this.priceMapFilter,
                );
            }
            await this.propertyMarkerController.getSoldPropertiesInMapView(suburbIdsWithinViewport, period);
            this.propertyMarkerController.createAndShowPropertyMarker(this.priceMapFilter);
        }

        if (currentMapLevel == PRICE_MAP_STATUS.ALLPROPERTYLEVEL) {
            if (!MapGlobalVars.showFavOnMap) {
                // show all property level sidebar if there is
                if (this.allPropertiesLayerController.clickedPropId) {
                    const lastClickedPropertyMarker = this.allPropertiesLayerController.allPropertyMarkers[
                        this.allPropertiesLayerController.clickedPropId
                    ];
                    if (lastClickedPropertyMarker) {
                        if (!isMobileDevice()) {
                            if (MapGlobalVars.showAroundTransCircle) {
                                EventBus.dispatch(EVENT_NAMES.EVENT_PROP_SIDE_BAR, {
                                    showSideBar: true,
                                    prop: lastClickedPropertyMarker.getMarkerProperty(),
                                });
                            }
                        } else {
                            EventBus.dispatch(EVENT_NAMES.EVENT_PROP_MARKER_CLICKED, {
                                prop: lastClickedPropertyMarker.getMarkerProperty(),
                            });
                        }
                    }
                }
            }

            MapGlobalVars.transShowingSuburbData = null;
            MapGlobalVars.showSuburbTransConfirm = false;
            this.propertyMarkerController.clearAllMarkerFromMap();
            this.allPropertiesLayerController.drawAllPropertiesOnMap();
        }

        this.previousZoom = this.map.getZoom();
    }

    removeMyLocationMarker() {
        if (this.myLocationMarker) {
            this.map.removeLayer(this.myLocationMarker);
            this.myLocationMarker = null;
        }
    }

    /**
     * When property filter change, need to redraw the current map layers
     */
    applyFilterChange() {
        const currentMapLevel = getPriceMapLevelByZoom(this.map.getZoom());
        if (currentMapLevel == PRICE_MAP_STATUS.SUBURBLEVEL) {
            this.suburbPolygonController.drawSuburbLayers(this.priceMapFilter);
            this.suburbPolygonController.changeSuburbChartType(this.suburbPolygonController.changeSuburbChartType, 1);
            this.suburbPolygonController.togglePriceLabel(this.suburbPriceLabelStatus, this.specialCareFilter);
        }
        if (currentMapLevel == PRICE_MAP_STATUS.PROPERTYLEVEL) {
            this.propertyMarkerController.createAndShowPropertyMarker(this.priceMapFilter);
        }
    }

    noSpecialCareCondition() {
        console.log(this.specialCareFilter);

        // console.log(this.specialCareFilter);
        const detectResult =
            this.specialCareFilter.schoolRanks.length == 0 &&
            this.specialCareFilter.secondarySchoolRanks.length == 0 &&
            this.specialCareFilter.trans.length == 0 &&
            this.specialCareFilter.chineseImmi.length == 0 &&
            this.specialCareFilter.indianImmi.length == 0 &&
            this.specialCareFilter.islamImmi.length == 0 &&
            this.specialCareFilter.distanceOptions.length == 0 &&
            (this.specialCareFilter.medianPriceRange.lower == 0 ||
                this.specialCareFilter.medianPriceRange.lower == FullPriceGrades[0]) &&
            (this.specialCareFilter.medianPriceRange.upper == FullPriceGrades[FullPriceGrades.length - 1] ||
                this.specialCareFilter.medianPriceRange.upper == 0) &&
            (this.specialCareFilter.RentPriceRange.lower == FullPriceGradesFor2BedsRent[0] ||
                this.specialCareFilter.RentPriceRange.lower == 0) &&
            (this.specialCareFilter.RentPriceRange.upper ==
                FullPriceGradesFor2BedsRent[FullPriceGradesFor2BedsRent.length - 1] ||
                this.specialCareFilter.RentPriceRange.upper == 0) &&
            this.specialCareFilter.PrivateSchoolMixBoy.length == 0 &&
            this.specialCareFilter.PrivateSchoolMixGirl.length == 0 &&
            this.specialCareFilter.TenantProportion.length == 0 &&
            this.specialCareFilter.HouseApt.length == 0 &&
            this.specialCareFilter.FamilyIncome.length == 0 &&
            this.specialCareFilter.PublicSecurity.length == 0 &&
            this.specialCareFilter.AreaBusiness.length == 0 &&
            this.specialCareFilter.Waterscape.length == 0 &&
            this.specialCareFilter.Trees.length == 0 &&
            this.specialCareFilter.LandSize.length == 0 &&
            this.specialCareFilter.PriceChange3M.length == 0 &&
            this.specialCareFilter.PriceChange6M.length == 0;

        return detectResult;
    }
    priceStatusLoopArray = ['1', '3', '2'];
    applySpecialFilter() {
        if (this.noSpecialCareCondition()) {
            if (this.suburbPriceLabelStatus == '1') {
                this.suburbPriceLabelStatus = '2';
            } else {
                this.suburbPriceLabelStatus = '1';
            }
            this.suburbPolygonController.togglePriceLabel(this.suburbPriceLabelStatus);
        } else {
            //toDo : draw special care marker
            let currentStatusIdx = this.priceStatusLoopArray.indexOf(this.suburbPriceLabelStatus);
            if (currentStatusIdx == this.priceStatusLoopArray.length - 1) {
                currentStatusIdx = 0;
            } else {
                currentStatusIdx = currentStatusIdx + 1;
            }
            this.suburbPriceLabelStatus = this.priceStatusLoopArray[currentStatusIdx];
            this.suburbPolygonController.togglePriceLabel(this.suburbPriceLabelStatus, this.specialCareFilter);
        }
    }

    getSuburbsInMapView() {
        console.log('load visible view suburb ids');
        return new Promise((resolve, reject) => {
            this.mapBounds = this.map.getBounds();

            const postData = {
                topRight: { lat: this.mapBounds._northEast.lat, lon: this.mapBounds._northEast.lng },
                bottomLeft: { lat: this.mapBounds._southWest.lat, lon: this.mapBounds._southWest.lng },
            };

            getVisibleSuburbIds(postData).then(
                async (result) => {
                    resolve(result);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }

    showSuburbPopup(suburbId) {
        alert(suburbId);
    }
}

import { Divider, makeStyles } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons/i18n/TR';
import { isMobileDevice } from '../../../commons/utils';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { ContentTitleData } from '../components/ContentTitleData';
import { Tips } from '../components/Tips';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px',
        },
        part1RowContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        part2: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        part2RowContent: {
            display: 'flex',
            flexFlow: 'row wrap',
        },
        part2RowContentItem: {
            padding: '5px 5px 5px 0px',
            margin: '0px',
        },
        part3: {
            margin: '10px auto',
            paddingTop: '5px',
        },
        part3Header: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px',
        },
        legendGroup: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        legend: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 7,
        },
        legendItem: {
            width: '10px',
            height: '10px',
            borderRadius: '5px',
        },
    };
});

declare var Highcharts;

const chartColor = {
    sydney: 'rgb(28 110 238)',
    suburb: 'rgb(255 143 41)',
};

export const Card34 = rst.create<{ property?: any; prop?: any; estimateInfo?: any }>((ctx) => {
    function loadChart() {
        Highcharts.chart('card34', {
            chart: {
                height: 180,
                marginBottom: isMobileDevice() ? 30 : 35,
            },
            title: {
                text: '',
            },

            subtitle: {
                text: '',
            },

            yAxis: {
                title: {
                    text: '',
                },
                labels: {
                    step: 1,
                },
            },

            xAxis: {
                type: 'category',
                categories: ctx.props.prop.sydneyData.year,
                crosshair: true,
            },

            legend: {
                enabled: false,
            },
            credits: { enabled: false },
            tooltip: {
                enabled: true,
                backgroundColor: 'white', // 背景颜色
                borderColor: 'black', // 边框颜色
                borderRadius: 10, // 边框圆角
                borderWidth: 1, // 边框宽度
                animation: true, // 是否启用动画效果
                //format the date and numbers
                formatter: function () {
                    const _this: any = this;
                    console.log(_this);
                    var d = new Date(_this.x);
                    var s = '<b>' + _this.x + '</b>';
                    s += '<br/><span>Price: ' + '$' + (_this.y / 1000000).toFixed(2) + 'm' + '</span>';
                    return s;
                },
            },
            plotOptions: {
                series: {
                    enableMouseTracking: true,
                    marker: {
                        enabled: false,
                    },
                },
                areaspline: {
                    fillOpacity: 0.25,
                },
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 2,
                    opacity: 1,

                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666',
                    },
                },
            },

            series: [
                {
                    name: 'Sydney',
                    data: ctx.props.prop.sydneyData.median,
                    color: chartColor.sydney,
                    fillOpacity: 0.3,
                    opacity: 1,
                    lineColor: chartColor.sydney,
                },
                {
                    name: 'Suburb',
                    data: ctx.props.prop.suburbData.yearly_median,
                    color: chartColor.suburb,
                    fillOpacity: 0.3,
                    opacity: 1,
                    lineColor: chartColor.suburb,
                },
            ],
        });
    }
    return (props) => {
        const classes = useStyles();
        setTimeout(() => {
            loadChart();
        }, 400);
        const projects = props.property.property.locality.significantProjects;
        // const showProjects = (value) => {
        //     if (value === null || value.length === 0) {
        //         return (
        //             <div className={classes.part2RowContentItem}>
        //                 <ContentData content="N/A" />
        //             </div>
        //         );
        //     } else {
        //         return value.map((project) => {
        //             if (project.completionTime === null) {
        //                 return (
        //                     <div className={classes.part2RowContentItem}>
        //                         <ContentData content={`${project.projectName}`} />
        //                     </div>
        //                 );
        //             } else {
        //                 return (
        //                     <div className={classes.part2RowContentItem}>
        //                         <ContentData content={`${project.projectName} ${project.completionTime}年`} />
        //                     </div>
        //                 );
        //             }
        //         });
        //     }
        // };
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader header="REPORT_SUBTITLE_34">
                    <Tips header="Card_Tips" content="CARD34_TIPS" isSelect={true} />
                    <div className={classes.part1}>
                        <div className={classes.part1RowContent}>
                            <ContentTitle hasArrow={false} header="Card34_prop_land" />
                            <ContentData
                                content={
                                    (
                                        ((props.prop.landValue || props.prop.land_value_reset) /
                                            props.estimateInfo.estimatePrice.middle) *
                                        100
                                    ).toFixed(0) + '%'
                                }
                            />
                        </div>
                        <div className={classes.part1RowContent}>
                            <ContentTitle hasArrow={false} header="Card34_sub_land" />
                            <ContentData
                                content={(props.property.property.locality.landvaluePctMedian * 100).toFixed(0) + '%'}
                            />
                        </div>
                    </div>
                    {projects === null || projects.length === 0 ? null : (
                        <>
                            <CardDivider />
                            <div className={classes.part2}>
                                <ContentTitle hasArrow={false} header="Card34_projects" />
                                <div className={classes.part2RowContent}>
                                    {projects.map((project) => {
                                        if (project.completionTime === null) {
                                            return (
                                                <div className={classes.part2RowContentItem}>
                                                    <ContentData content={`${project.projectName}`} />
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className={classes.part2RowContentItem}>
                                                    <ContentData
                                                        content={`${project.projectName} ${project.completionTime}年`}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </>
                    )}
                    <div className={classes.part3}>
                        <div className={classes.part3Header}>
                            <div style={{ fontSize: '14px' }}>
                                <span>
                                    <TR name={'Card34_trend'} />
                                </span>
                            </div>
                            <div className={classes.legendGroup}>
                                <div className={classes.legend}>
                                    <div className={classes.legendItem} style={{ background: chartColor.sydney }}></div>
                                    <div style={{ marginLeft: 3 }}>
                                        <span>Sydney</span>
                                    </div>
                                </div>
                                <div className={classes.legend}>
                                    <div className={classes.legendItem} style={{ background: chartColor.suburb }}></div>
                                    <div style={{ marginLeft: 3 }}>
                                        <span>{props.property.property.locality.localityName}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="card34"></div>
                    </div>
                </CardWithHeader>
            </div>
        );
    };
});

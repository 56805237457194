import ReactDOM from 'react-dom/server';
import React from 'react';
import { getLatlngArrayFromEncodedPath } from '../../../../commons/utils';
import { p_icon } from '../../../PriceMap/MapContainer/PropertyIcon';

const win: any = window;
const L = win.L;

export class AroundRiskMap {
    mapInstance = null;
    constructor(mapInstance) {
        this.mapInstance = mapInstance;
    }

    drawAround(propCenter, aroundRiskData) {
        L.circle([propCenter.lat, propCenter.lon], { radius: 1000, fill: false }).addTo(this.mapInstance);
        aroundRiskData.forEach((loopHazard) => {
            let icon = '';
            if (loopHazard.hazardType == 'Grave') {
                icon = 'cemetery-ico';
            }
            if (loopHazard.hazardType == 'Petro Station') {
                icon = 'gas station-ico';
            }
            if (loopHazard.hazardType == 'Carrier Sites') {
                icon = 'base statiom-ico';
            }

            const iconHTML = ReactDOM.renderToString(
                React.createElement(p_icon, {
                    reportMapCenter: true,
                    icon: icon,
                }),
            );

            const houseMarkerIcon = new L.DivIcon({
                className: '',
                html: iconHTML,
            });

            const houseMarker = L.marker([loopHazard.lat, loopHazard.lon]).addTo(this.mapInstance);
            houseMarker.setIcon(houseMarkerIcon);
        });
        console.log(aroundRiskData);
    }
}

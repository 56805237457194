import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    about: {
        display: 'flex',

        maxWidth: '1080px',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        '& img': {
            flex: '2',
            width: '50%',
            padding: '0',
            objectFit: 'contain',
            [theme.breakpoints.down('sm')]: {
                margin: '0 auto',
            },
        },
    },
    words: {
        flex: 3,
        margin: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            margin: '10px 20px',
        },

        '& h1': {
            color: 'rgb(0, 0, 232)',
            fontSize: '4rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem',
            },
        },
    },
    content: {
        fontSize: '1.4rem',
        textAlign: 'left',
        width: '90%',
        lineHeight: '2.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            lineHeight: '22px',
        },
    },
    button: {
        textAlign: 'center',
        margin: '0 0 30PX 0',
    },
}));

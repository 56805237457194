import React from 'react';
import { rst } from 'rt-state';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ROUTES } from '../../routes';
import { DrawerController, XDrawer } from './index';
import { TR } from '../../commons/i18n';
import { RRLink } from '../../commons';
import Popover from '@material-ui/core/Popover';
import { UserCenter } from '../../pages/UserCenter/UserCenter';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            marginTop: theme.spacing(2),
            width: 200,
        },
        headImage: {
            width: 150,
            margin: 'auto',
        },
        menuItem: {
            textAlign: 'center',
        },
    };
});
// const userCenterState = rst.stateS<{ anchorElUserCenter: HTMLElement | null }>({
//     anchorElUserCenter: null,
// });
// function handleClickUserCenter(event: React.MouseEvent<HTMLElement, MouseEvent>) {
//     userCenterState.value.anchorElUserCenter = event.currentTarget;
//     userCenterState.forceUpdate();
//     console.log(userCenterState.value.anchorElUserCenter);
// }
// function handleCloseUserCenter() {
//     userCenterState.value.anchorElUserCenter = null;
//     userCenterState.forceUpdate();
// }
export const AppDrawer = rst.createS<{ drawerCtrl: DrawerController }>((props) => {
    const classes = useStyles();
    // const openUserCenter = Boolean(userCenterState.value.anchorElUserCenter);
    // const idUserCenter = openUserCenter ? 'simple-popover' : undefined;
    // console.log(idUserCenter);
    return (
        <XDrawer controller={props.drawerCtrl}>
            <Grid
                className={classes.root}
                container
                onClick={props.drawerCtrl.close}
                direction={'column'}
                justify={'center'}>
                <img src={`${process.env.PUBLIC_URL}/static/images/logo.svg`} className={classes.headImage} />
                <List>
                    <ListItem component={RRLink} to={ROUTES.AboutUsPage.path}>
                        <ListItemText className={classes.menuItem} primary={<TR name={'About Us'} />} />
                    </ListItem>
                    <ListItem component={RRLink} to={ROUTES.ContactUsPage.path}>
                        <ListItemText className={classes.menuItem} primary={<TR name={'Contact Us'} />} />
                    </ListItem>
                    <ListItem component={RRLink} to={ROUTES.LoginPage.path}>
                        <ListItemText className={classes.menuItem} primary={<TR name={'Login'} />} />
                    </ListItem>
                    <ListItem component={RRLink} to={ROUTES.SignupPage.path}>
                        <ListItemText className={classes.menuItem} primary={<TR name={'Signup'} />} />
                    </ListItem>
                    {['Blogs', 'Settings', 'Help'].map((text, index) => (
                        <ListItem key={text}>
                            <ListItemText className={classes.menuItem} primary={<TR name={text} />} />
                        </ListItem>
                    ))}
                    <ListItem component={RRLink} to={ROUTES.UserCenter.path}>
                        <ListItemText className={classes.menuItem} primary={<TR name={'User Center'} />} />
                    </ListItem>
                    {/* <ListItem button onClick={handleClickUserCenter} aria-describedby={idUserCenter}>
                        <ListItemText className={classes.menuItem} primary={<TR name={'User Center'} />} />
                    </ListItem>
                    <Popover
                        id={idUserCenter}
                        open={openUserCenter}
                        anchorEl={userCenterState.value.anchorElUserCenter}
                        onClose={handleCloseUserCenter}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                        <UserCenter />
                    </Popover> */}
                </List>
            </Grid>
        </XDrawer>
    );
});

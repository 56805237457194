import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { rst } from 'rt-state';
import { useRunOnce } from '../../../commons/hooks';
import { SuburbPageState } from '../common';
import { MapController } from './controller/MapController';
import { MapLegend } from './MapLegend';
import { MapIconsController } from './MapIconsController';
import { API } from '../../../commons';
import { MapLevel } from '../../PriceMap/support/PriceMapConsts';

// read function from javascripts library which are defined in index.html
const win: any = window;
const L = win.L;
const LeafletToolbar = win.LeafletToolbar;

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 0,
        },
        dummy: {},
    };
});

interface MapContainerState {
    center: any;
    zoom: number;
    bounds: any;
}

export interface MapContainerProps {
    suburbPageState: SuburbPageState;
    tile?: {
        url?: string;
        attribution?: string;
    };
    mapOptions?: {
        center?: any;
        zoom?: number;
        controls?: {
            zoom?: boolean;
            fullscreen?: boolean;
            locate?: boolean;
            search?: boolean;
            drawtool?: boolean;
            gesture?: boolean;
        };
        size?: {
            width?: number;
            height?: number;
        };
    };
}

/**
 * 封装了leaflet map 容器的组件，
 * tile : Define the map theme , which can be found in the link : https://leaflet-extras.github.io/leaflet-providers/preview/
 * mapOptions :
 */

export const MapContainer = rst.create<MapContainerProps>((ctx) => {
    let mapController: MapController;
    let mapInstance;
    /**
     * Initialize the map using leaflet map library
     */

    const tile = {
        url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
        attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    };

    const bottomLeft = L.latLng(-34.28558793000448, 150.37536621093753);
    const topRight = L.latLng(-33.45665130188185, 151.69921875000003);

    const state = rst.state<MapContainerState>({
        center: [-33.8611, 151.203],
        zoom: 13,
        bounds: L.latLngBounds(bottomLeft, topRight),
    });

    if (ctx.props.tile) {
        if (ctx.props.tile.url) {
            tile.url = ctx.props.tile.url;
        }

        if (ctx.props.tile.attribution) {
            tile.attribution = ctx.props.tile.attribution;
        }
    }

    /** Monitor the suburbPage state change to redraw map  */
    rst.watch(
        (values) => {
            const [tabOption, filterOption] = values;
        },
        () => [ctx.w().suburbPageState.tabOption, ctx.w().suburbPageState.filterOption],
    );

    function initMap() {
        const { mapOptions } = ctx.props;
        mapInstance = L.map('mapid', {
            gestureHandling: mapOptions?.controls?.gesture ?? false,
            zoomControl: false,
        }).setView(state.center, state.zoom);

        new L.Control.Zoom({ position: 'bottomright' }).addTo(mapInstance);

        mapController = new MapController(mapInstance);
        mapController.initController();

        mapInstance.setMaxBounds(state.bounds);

        if (mapOptions?.controls?.drawtool) {
            new LeafletToolbar.DrawToolbar({
                position: 'topleft',
            }).addTo(mapInstance);
        }

        L.tileLayer(tile.url, {
            attribution: tile.attribution,
        }).addTo(mapInstance);

        refresh();
    }

    function resizeMap(mapSize) {
        /**
         * After load map, need to chagne map dimention to same with his parent container dimention
         */
        const container: any = document.getElementsByClassName('leaflet-container')[0];

        container.style.width = mapSize.width + 'px';
        container.style.height = mapSize.height + 'px';

        mapInstance.invalidateSize();
    }

    function refresh() {
        const { mapOptions } = ctx.props;
        if (mapOptions?.center) {
            state.center = mapOptions.center;
        }
        if (mapOptions?.zoom) {
            state.zoom = mapOptions.zoom;
        }
    }

    function showSpecialIcon(evt) {}

    return (props) => {
        useRunOnce(initMap);
        const classes = useStyles();

        return (
            <div className={classes.root}>
                <div id="mapid" />
                <MapIconsController iconEvents={{ specialIconEvents: showSpecialIcon }}></MapIconsController>
                <MapLegend suburbPageState={props.suburbPageState} />
            </div>
        );
    };
});

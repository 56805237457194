import { ENV } from './common';

export const localEnv: ENV = {
    apiHost: 'https://abstg.api.aihouse.io',
    USER_API_HOST: 'http://mynuc.com:8080',
    ql_token: '',
    stripeKey:
        'pk_test_51HVTK6AD12lyhQjmsarPl6u9tqzsUAyB8wtEQdTISMezCdlwpBmRtaV1p3TEfK97sal8owYaNtB2YsmmsSmDAaSj009CyrWYJt',
    signUpExpireHours: 24,
};

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { isMobileDevice } from '../../../commons/utils';

const useStyles = makeStyles((theme) => {
    return {
        pc: {
            fontSize: 14,
            fontWeight: 400,
            color: '#333333',
            display: 'flex',
            alignItems: 'center',
            width: 40,
        },
        mobile: {
            fontSize: 13,
            fontWeight: 400,
            color: '#333333',
            display: 'flex',
            alignItems: 'center',
            width: 40,
        },
    };
});

export const ItemLabel = rst.create((ctx) => {
    const selectedState = rst.stateS(false);
    return (props) => {
        const classes = useStyles();
        return (
            <div
                onClick={() => {
                    selectedState.value = !selectedState.value;
                }}
                className={isMobileDevice() ? classes.mobile : classes.pc}
                {...props}>
                {props.children}
            </div>
        );
    };
});

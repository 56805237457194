import { makeStyles, Theme } from '@material-ui/core';
import process from 'process';
import React from 'react';
import { clsx, SecurityProvider, TR } from '../../../../commons';
import { getPinyin } from '../../../../commons/utils';
import { Margin } from '../../../../components/margin';
const useStyles = makeStyles((theme: Theme) => {
    return {
        loginIcon: {
            width: '50px',
            [theme.breakpoints.down('sm')]: {
                width: '30px',
            },
        },
        circleName: {
            width: 24,
            height: 24,

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'yellow',
            padding: 5,
            borderRadius: '50%',
        },
        verified: {
            background: '#009e90',
        },
        unverified: {
            background: 'grey',
        },
        member: {
            background: 'url("assets/mobileIcons/member.svg")',
            borderRadius: '0',

            backgroundSize: '24px 24px',
        },
        memberName: {
            marginLeft: 10,

            display: 'flex',
            flexDirection: 'column',
        },
    };
});
export const MemberIcon = (props: any) => {
    const { onMenuBar } = props;
    const securityProvider = SecurityProvider.use();
    const userProfile = securityProvider.getUserProfile();
    const userStatus = userProfile?.userStatus;
    const firstNameLetter = getPinyin(userProfile.firstName);
    const lastNameLetter = getPinyin(userProfile.lastName);
    var nameInt = '';
    if (firstNameLetter && firstNameLetter.length > 0) {
        nameInt = firstNameLetter[0][0].substr(0, 1).toUpperCase();
    }
    // if (lastNameLetter && lastNameLetter.length > 0) {
    //     nameInt += lastNameLetter[0][0].substr(0, 1);
    // }
    if (nameInt == '' && userProfile.email) {
        nameInt = userProfile.email.substr(0, 1).toUpperCase();
    }

    const classes = useStyles();
    return (
        <div>
            {userProfile == null && (
                <img
                    style={{
                        // borderRadius: 80,
                        height: '21px',
                        width: '20px',
                    }}
                    className={classes.loginIcon}
                    src={`${process.env.PUBLIC_URL}/assets/icons/myselficon.svg`}
                />
            )}
            {(userStatus == '1' || userStatus == '2') && (
                <>
                    <div style={{ display: 'flex' }}>
                        <div className={clsx(classes.circleName, classes.unverified)}>{nameInt}</div>
                        {!onMenuBar && (
                            <div className={classes.memberName}>
                                <div> {userProfile.email}</div>
                                <Margin tm={8}></Margin>
                                <div>
                                    <TR name="reigsteredUser"></TR>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
            {userStatus == '3' && (
                <div style={{ display: 'flex', height: '24px', alignItems: 'center' }}>
                    <div className={clsx(classes.circleName, classes.verified)}>{nameInt}</div>
                    {!onMenuBar && (
                        <div className={classes.memberName}>
                            <div>
                                {' '}
                                {userProfile.firstName
                                    ? userProfile.firstName + ' ' + userProfile.lastName
                                    : userProfile.email}
                            </div>
                            <Margin tm={8}></Margin>
                            <div>
                                <TR name="areaMember"></TR>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {(userStatus == '4' || userStatus == '5' || userStatus == '6' || userStatus == '7') && (
                <div style={{ display: 'flex', height: '24px', alignItems: 'center' }}>
                    <div className={clsx(classes.circleName, classes.member)}>{nameInt}</div>
                    {!onMenuBar && (
                        <div className={classes.memberName}>
                            <div>
                                {userProfile.firstName
                                    ? userProfile.firstName + ' ' + userProfile.lastName
                                    : userProfile.email}
                            </div>
                            <Margin tm={8}></Margin>
                            <div>
                                <TR name="wholeAreaMember"></TR>
                                {(userStatus == '6' || userStatus == '7') && (
                                    <>
                                        (<TR name="cancelled"></TR>)
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import { rst, state } from 'rt-state';
import { generateGUID, SecurityProvider } from '../../commons';
import { getParamValueFromURL, isMobileDevice } from '../../commons/utils';
import { NewHomePageHead } from '../NewHome/components/01NewHomePageHead';
import { MobileAddressCard } from './component/addressCard';
import { domReady } from './supportUtils/htmlUtils';
import { EventBus, EVENT_NAMES } from '../../commons/EventBus';
import { MobileFunctionCard } from './component/index/functionCard';
import { MobileDescCard } from './component/index/descCard';
import { MobileVideoCard } from './component/index/videoCard';
import { MobileFooter } from './component/footer';
import { VideoPlayer } from '../NewHome/videoPlayer';
import { AppProvider } from '../../commons/appProvider';
import { LoginComp, LoginPage } from '../auth';
import { PageWrapper } from '../auth/common';
import { ComparableHouse } from './component/comparableHouse/index';
import { TipAfterSignup } from './member/signUp/TipAfterSignup';
import { Tip } from '../../components/Tip';
import { FloatTip } from '../../components/FloatingTip';
import { NewHomeTransactionCard } from '../NewHome/components/12NewHomeTransactionCard';
import { MobileTransactionCard } from './component/index/transactionCard';
import { PriceIncRankingM } from './component/PriceIncRankingM';
import { BlogAndLive } from '../NewHome/components/blogAndLive';
import { MobileQRCode } from './component/index/MobileQRCode';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: 'white',
    },
    mainContainer: {},
});
export const MobileHome = rst.create((ctx) => {
    console.log('Mobile Home');
    const state = rst.state({
        showLogin: false,
        showingAllComparable: false,
        property: {},
    });

    const appProvider = AppProvider.use();

    const requestURL = window.location.search;

    const pageState = rst.state({
        func: '',
        address: '',
        inDetailPage: getParamValueFromURL(requestURL, 'showingDetails') == 'true',
        addressSelected: false,
        showLoading: false,
        queryType: getParamValueFromURL(requestURL, 'queryType'),
        queryId: getParamValueFromURL(requestURL, 'queryId'),
        queryAddress: getParamValueFromURL(requestURL, 'queryAddress', false),
        propertyType: getParamValueFromURL(requestURL, 'propertyType'),

        // inDetailPage: true,
    });
    console.log('state is ' + JSON.stringify(state));
    console.log(requestURL);
    console.log('query type is ' + getParamValueFromURL(requestURL, 'queryType'));

    window.onpopstate = function (event) {
        console.log(event.target.location.search);
        const currentURL = window.location.search;

        const qType = getParamValueFromURL(currentURL, 'queryType');

        // pageState.queryId = getParamValueFromURL(currentURL, 'queryId');
        // pageState.queryAddress = getParamValueFromURL(currentURL, 'queryAddress');
        if (!qType) {
            EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_BACK_TO_HOME, {});
        } else {
            pageState.inDetailPage = true;

            if (qType == '1') {
                const showDetail = getParamValueFromURL(currentURL, 'showingDetails');
                console.log('show detail is ' + showDetail);
                EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_SHOW_PROPERTY, {
                    name: getParamValueFromURL(currentURL, 'queryAddress'),
                    propId: getParamValueFromURL(currentURL, 'queryId'),
                });
            }
            if (qType == '3') {
                EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_SHOW_SUBURB, {
                    name: getParamValueFromURL(currentURL, 'queryAddress'),
                    suburbId: getParamValueFromURL(currentURL, 'queryId'),
                });
            }
            if (qType == '2') {
                EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_SHOW_STREET, {
                    name: getParamValueFromURL(currentURL, 'queryAddress'),
                    streetId: getParamValueFromURL(currentURL, 'queryId'),
                });
            }
        }

        //alert(`location: ${document.location}, state: ${JSON.stringify(event.state)}`)
    };

    EventBus.on(EVENT_NAMES.EVENT_MOBILE_BACK_TO_HOME, () => {
        pageState.inDetailPage = false;
    });

    EventBus.on(EVENT_NAMES.EVENT_MOBILE_SHOW_ALL_COMPARABLE, () => {
        state.showingAllComparable = true;
    });

    EventBus.on(EVENT_NAMES.EVENT_MOBILE_ORIGINAL_PROPERTY_FOR_ALL_COMPARABLE, (evenData: any) => {
        state.property = evenData.property;
    });

    EventBus.on(EVENT_NAMES.EVENT_MOBILE_IN_DETAIL_PAGE, (eventData: any) => {
        pageState.inDetailPage = eventData.inDetail;
        pageState.addressSelected = true;
    });
    const UUID = generateGUID();
    domReady('header_' + UUID).then((headerRef: any) => {
        domReady('container_' + UUID).then((containerRef: any) => {
            // containerRef.style.height = window.screen.height - headerRef.offsetHeight + 'px';
        });
    });
    if (!isMobileDevice()) {
        window.location.href = 'home';
    }

    const openVideo = rst.stateS(false);
    const videoNo = rst.stateS(4);
    const closeVideoEvt = () => {
        openVideo.value = false;
        openVideo.forceUpdate();
    };

    EventBus.on(EVENT_NAMES.SHOW_MOBLIE_LOADING, (show) => {
        pageState.showLoading = show;
    });

    EventBus.on(EVENT_NAMES.EVENT_MOBILE_SHOW_FUNC, (evtData) => {
        pageState.inDetailPage = true;
    });

    EventBus.on(EVENT_NAMES.EVENT_SHOW_VIDEO_PLAYER, (no) => {
        videoNo.value = no;
        openVideo.value = true;
        openVideo.forceUpdate();
    });
    EventBus.on(EVENT_NAMES.EVENT_SHOW_LOGIN, () => {
        state.showLogin = true;
    });
    const backEvt = () => {
        pageState.inDetailPage = false;
    };

    return (props) => {
        const classes = useStyles();
        return (
            <>
                {state.showLogin ? (
                    <PageWrapper>
                        <LoginComp
                            onClose={() => {
                                state.showLogin = false;
                            }}></LoginComp>
                    </PageWrapper>
                ) : null}
                <div id="mobileIndexPage" className={classes.root}>
                    <div id={'header_' + UUID} style={{ zIndex: 100 }}>
                        <NewHomePageHead />
                    </div>

                    <div
                        style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}
                        id={'container_' + UUID}>
                        {!state.showingAllComparable ? (
                            <>
                                <MobileAddressCard
                                    queryType={pageState.queryType}
                                    queryId={pageState.queryId}
                                    queryAddress={pageState.queryAddress}
                                    showDetails={pageState.inDetailPage}
                                    propertyType={pageState.propertyType}
                                    backToMainPageEvt={backEvt}></MobileAddressCard>

                                {pageState.inDetailPage ? null : (
                                    <>
                                        <MobileQRCode />
                                        <PriceIncRankingM />
                                        <MobileFunctionCard></MobileFunctionCard>
                                        {/* <MobileUserEvaluationCard></MobileUserEvaluationCard> */}
                                        <MobileDescCard></MobileDescCard>

                                        <MobileTransactionCard />
                                        <BlogAndLive mobile></BlogAndLive>
                                        <MobileVideoCard></MobileVideoCard>
                                        {openVideo.value ? (
                                            <VideoPlayer
                                                open={openVideo.value}
                                                videoNo={appProvider.getLanguage() == 'zh' ? 4 : 5}
                                                closeVideoEvt={closeVideoEvt}></VideoPlayer>
                                        ) : null}
                                    </>
                                )}
                            </>
                        ) : (
                            <div>
                                <ComparableHouse
                                    property={state.property}
                                    showingAllComparable={state.showingAllComparable}
                                />
                            </div>
                        )}
                        <MobileFooter
                            addressSelected={pageState.addressSelected}
                            inDetails={pageState.inDetailPage}></MobileFooter>
                    </div>
                    {/* <Backdrop style={{ color: '#ffffff', zIndex: 999999 }} open={pageState.showLoading} onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop> */}
                </div>

                <TipAfterSignup />
                <Tip></Tip>
            </>
        );
    };
});

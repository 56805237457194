export const PriceMapTheme = {
    currentTheme: 'classic',
    classic: {
        UNSELECT_BUTTON_BACKGROUND: '#E7F1FD',
        MAP_BUTTON_BACKGROUND: '#D8D8D8',
        SELECTED_MAP_BORDER_COLOR: '#ff0000',

        HOVER_MAP_BORDER_COLOR: '#1875f0',
        MAIN_COLOR: '#1875f0',
        SPECIAL_COLOR: '#e02020',
        SPECAIL_CARE_MARKER_COLOR: '#ffff00',
        SPECICAL_CARE_LABEL_COLOR: '#000000',
        NORMAL_SUBURB_MARKER_COLOR: '#ffffff',
        NORMAL_SUBURB_LABEL_COLOR: '#000000',
        SELECT_ITEM_HOVER_COLOR: '#f7f7f7',
    },
};

export const getThemeValue = (item) => {
    return PriceMapTheme[PriceMapTheme.currentTheme][item];
};

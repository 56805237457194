import React from 'react';
import { rst } from 'rt-state';
import { XBreadcrumbs } from '../../../components/Breadcrumbs';
import { CategoryTabs } from '../../../components/Tabs';
import { Box, makeStyles } from '@material-ui/core';
import { SingleLineGridList } from '../../../components/Scroller';
import { XForm } from '../../../components/form';
import { ALL_FILTER_OPTIONS, ALL_TAB_OPTIONS, priceFilters } from '../common';
import { SelectOption } from '../../../components/common';
import { SearchTabContent } from './SearchTabContent';
import { XSliderField } from '../../../components/form/mui-form/SliderField';

const useStyles = makeStyles((theme) => {
    return {
        breadCrumbs: {
            fontSize: '16px',
            color: theme.palette.primary.main,
        },
    };
});

export const SuburbSearchContent = rst.create<{
    isComplex?: boolean;
    form: XForm;
}>((ctx) => {
    const state = rst.state({
        selectedTab: '1',
    });

    function onSelect(option: SelectOption) {
        state.selectedTab = option.value;
    }
    return (props) => {
        const { form } = props;
        const classes = useStyles(props);
        const firstScreenFilterOptions = [priceFilters[2], priceFilters[3]];
        const getTabContent = () => {
            if (state.selectedTab == '1') {
                return (
                    <>
                        <XSliderField
                            form={form}
                            name={'houseMedian'}
                            label={'近半年别墅中位价:'}
                            min={0}
                            max={300}
                            valueRender={(value) => `$${value}万`}
                            onChange={(values) => {
                                console.log('onChange:', values);
                            }}
                        />

                        <XSliderField
                            form={form}
                            name={'apartMedian'}
                            label={'近半年公寓中位价:'}
                            min={0}
                            max={300}
                            valueRender={(value) => `$${value}万`}
                            onChange={(values) => {
                                console.log('onChange:', values);
                            }}
                        />
                        <SearchTabContent form={form} listOptions={firstScreenFilterOptions}></SearchTabContent>
                    </>
                );
            } else {
                return (
                    <>
                        <SearchTabContent
                            form={form}
                            listOptions={ALL_FILTER_OPTIONS[state.selectedTab]}></SearchTabContent>
                    </>
                );
            }
        };
        return (
            <div>
                <XBreadcrumbs className={classes.breadCrumbs} breadcrumbs={['区域搜索', '多区域筛选']} />
                {/* <SingleLineGridList /> */}
                <Box pt={2} />
                <CategoryTabs value={ALL_TAB_OPTIONS[0]} options={ALL_TAB_OPTIONS} onSelect={onSelect} />
                <Box pt={2} />

                {getTabContent()}

                {/* <XTextField form={form} name="username" label="Email" />
                <XTextField form={form} name="password" label="Password" type="password" /> */}
            </div>
        );
    };
});

/* eslint-disable prettier/prettier */
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../../commons';
import { isMobileDevice, numToMillion } from '../../../../commons/utils';
import { PriceMapStatus } from '../../support/DataModels';
import { getPriceMapLevelByZoom } from '../../support/common';
import { PRICE_MAP_STATUS } from '../../support/PriceMapConsts';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { ROUTES } from '../../../../routes';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'absolute',
      top: isMobileDevice() ? '24px' : '20px',
      left: '10px',
      zIndex: 1255,
      display: 'flex',
    },
    titleRoot: {
      display: 'flex',
      opacity: 0.8,
      background: 'rgba(255,106,0,0.7)',
      borderRadius: '16px',
      paddingRight: isMobileDevice() ? '1px' : '12px',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
    },
    mobile_title: {
      fontSize: '12px',
    },
    pc_title: {
      fontSize: '16px',
    },
    mobile_sideIcon: {
      width: '32px',
      height: '32px',
    },
    pc_sideIcon: {
      width: '32px',
      height: '32px',
    },
    rowItem: {
      fontSize: isMobileDevice() ? 12 : 14,
    },
    transTitle: {
      padding: 10,
    },
    dummy: {},
  };
});

export const PriceMapTitle = rst.create<{ mapZoom: number; fromIndex: boolean }>((ctx) => {
  const showTitle = rst.stateS(false);

  const pageState = rst.state({
    mapCatagory: 'medianPrice',
    showTitleLayer: true,
  });

  const suburbData = rst.state({
    suburbId: '',
    suburbName: '',
    medianLansize: 0,
    medianPrice: 0,
    priceRange: { min: 0, max: 0 },
  });
  EventBus.on(EVENT_NAMES.EVENT_UPDATE_PRICE_MAP_TITLE, (evtData) => {
    suburbData.suburbId = evtData.suburbId;
    suburbData.suburbName = evtData.suburbName;
    suburbData.medianLansize = evtData.medianLandSize?.median;
    suburbData.medianPrice = evtData.medianPrice.median;
    suburbData.priceRange = evtData.medianPrice.range;
  });

  EventBus.on(EVENT_NAMES.EVENT_CHANGE_SUBURB_COLOR_BY, (catagory) => {
    pageState.mapCatagory = catagory;
    showTitle.value = false;
  });
  EventBus.on(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, () => {
    showTitle.value = true;
  });

  EventBus.on(EVENT_NAMES.EVENT_AVOID_OVERLAY, (evt) => {
    pageState.showTitleLayer = evt.showIcon;
  });

  const returnHomePage = () => {
    window.location.href = `${ROUTES.HomePage.path}`;
  };
  return (props) => {
    const classes = useStyles();

    const foldTitle = () => {
      showTitle.value = !showTitle.value;
      showTitle.forceUpdate();
      EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_PRICE_MAP_TITLE, showTitle.value);
    };

    const getMapTitle = () => {
      if (!showTitle.value && getPriceMapLevelByZoom(props.mapZoom) != PRICE_MAP_STATUS.SUBURBLEVEL) {
        return null;
      }

      if (getPriceMapLevelByZoom(props.mapZoom) == PRICE_MAP_STATUS.SUBURBLEVEL) {
        return (
          <div style={{ cursor: 'pointer' }} onClick={foldTitle}>
            <TR name={'PriceMapSuburbTitle_' + pageState.mapCatagory}></TR>
          </div>
        );
      }

      if (getPriceMapLevelByZoom(props.mapZoom) == PRICE_MAP_STATUS.PROPERTYLEVEL) {
        if (suburbData.suburbId == '') {
          return <TR name="PropertyMapTitle"></TR>;
        } else {
          return (
            <div className={classes.transTitle}>
              <div className={classes.rowItem}>{suburbData.suburbName}</div>
              {suburbData.medianLansize ? (
                <div className={classes.rowItem}>
                  <TR name="MEDIANLANDSIZE"></TR> :{suburbData.medianLansize.toFixed(0)}m<sup>2</sup>
                </div>
              ) : null}

              <div className={classes.rowItem}>
                <TR name="MedianPrice"></TR> : {numToMillion(suburbData.medianPrice)}
              </div>
              <div className={classes.rowItem}>
                <TR name="RANGE"></TR> :{numToMillion(suburbData.priceRange.min)} -{' '}
                {numToMillion(suburbData.priceRange.max)}
              </div>
            </div>
          );
        }
      }
      if (getPriceMapLevelByZoom(props.mapZoom) == PRICE_MAP_STATUS.ALLPROPERTYLEVEL) {
        return <TR name="ALLPROPERTIES_MAPTitle"></TR>;
      }
    };

    return (
      <div className={classes.root} style={{ display: pageState.showTitleLayer ? 'flex' : 'none' }}>
        {props.fromIndex ? null : (
          <>
            <img
              src="assets/icons/return-ico@1x.svg"
              style={{ cursor: 'pointer' }}
              onClick={returnHomePage}></img>
            <div style={{ marginLeft: 10 }}></div>
          </>
        )}

        <div
          className={classes.titleRoot}
          style={{
            paddingRight: isMobileDevice()
              ? 5
              : showTitle.value || getPriceMapLevelByZoom(props.mapZoom) == PRICE_MAP_STATUS.SUBURBLEVEL
                ? 12
                : 2,
          }}>
          <img
            src={
              getPriceMapLevelByZoom(props.mapZoom) == PRICE_MAP_STATUS.SUBURBLEVEL
                ? 'assets/icons/screen-ico@1x.svg'
                : 'assets/icons/title-ico.svg'
            }
            onClick={foldTitle}
            style={{ cursor: 'pointer' }}
          />
          <span className={isMobileDevice() ? classes.mobile_title : classes.pc_title}>{getMapTitle()}</span>
        </div>
      </div>
    );
  };
});

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { rst } from 'rt-state';
import { NewContentImg } from './NewContentImg';
import { NewNewContentText } from './NewNewContentText';
import { isMobileDevice } from '../../../commons/utils';
import { NewButton } from './NewButton';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { Tooltip, Button, Box } from '@material-ui/core';
import { TR, TRHtml } from '../../../commons';

export interface NewNewContentItemProps {
    img: string;
    onClick?: () => void;
    header?: string;
    subHeader?: string;
    hasBackText?: string;
    textContent?: string[];
    textLeft?: boolean;
    background?: string;
    hasButton?: boolean;
    video?: string;
}
const useStyles = makeStyles((theme) => ({
    root: (props: NewNewContentItemProps) => {
        const background = props.background;
        const flexDirection = isMobileDevice() ? 'column' : props.textLeft ? null : 'row-reverse';
        return {
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            background,
            flexDirection,
            padding: '54px 0px 66px 0px',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 0px 0px 0px',
            },
        };
    },
    text: {
        margin: '0px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    img: {
        margin: '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '20px',
        },
    },
}));

export const NewNewContentItem = rst.create<NewNewContentItemProps>((ctx) => {
    const openVideo = (videoNo) => {
        EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_VIDEO_PLAYER, videoNo);
    };

    return (props) => {
        const classes = useStyles(props);
        const { img, header, subHeader, hasBackText, textContent, background, textLeft, hasButton, video } = props;
        return (
            <div className={classes.root}>
                <div className={classes.text}>
                    <NewNewContentText
                        header={header}
                        // subHeader={subHeader}
                        // hasBackText={hasBackText}
                        textLeft={textLeft}
                        textContent={textContent}
                        background={background}
                        hasButton={hasButton}
                        video={video}
                    />
                </div>
                <div className={classes.img}>
                    <Tooltip arrow placement="right" title={<TRHtml html={'playIntroVideo'} />}>
                        <Box>
                            <NewContentImg
                                img={img}
                                onClick={() => {
                                    openVideo(video);
                                }}
                            />
                        </Box>
                    </Tooltip>
                    {/* <NewButton
                        backgroundColor="#FF4600"
                        padding={isMobileDevice() ? '10px 35px' : '10px 58px'}
                        color="white"
                        label="NewHome_Video"
                        onClick={() => {
                            openVideo(video);
                        }}
                        icon={1}
                    /> */}
                </div>
            </div>
        );
    };
});

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';

const useStyles = makeStyles({
    root: {
        display: 'flex',

        justifyContent: 'flex-start',
    },
    addressLine1: {
        display: 'flex',
        justifyContent: 'flex-start',

        fontWeight: 700,
        fontSize: 16,
    },
    addressLine2: {
        display: 'flex',
        justifyContent: 'flex-start',

        fontSize: 14,
    },
});

export const PropertyAddress = (props: any) => {
    const { address } = props;
    const addressArray = address.split(',');
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.addressLine1}> {address}</div>
        </div>
    );
};

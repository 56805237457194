import * as React from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { handleRedirect } from '../../commons';
import { rst } from 'rt-state';
import { XTextField } from '../../components/form';
import { TR } from '../../commons';
import { getUserProfile, forgetPasswordSession } from '../../services/auth';
import { getErrorData } from '../../commons/axios/axios';
import {
    getPassword2Validator,
    AuthDialogHeader,
    PageWrapper,
    PASSWORD_SCHEMA,
    setup,
    PASSWORD_HELPER_TEXT,
} from './common';
import { ValidateType } from '../../components/form';
import { AppProvider } from '../../commons/appProvider';

const initialValues = {
    password: '',
    password2: '',
};

const validationSchema: ValidateType<any> = {
    password: PASSWORD_SCHEMA,
    password2: getPassword2Validator('password'),
};

export const ForgetPasswordPage = () => (
    <PageWrapper>
        <ForgetPasswordComp />
    </PageWrapper>
);

export const ForgetPasswordComp = rst.create(() => {
    const appProvider = AppProvider.use();
    const { securityProvider, snackbarController, hooksRef, hasToken, form } = setup(
        initialValues,
        validationSchema,
        onSubmit,
    );

    async function onSubmit(data: any) {
        if (!hasToken()) {
            return;
        }
        const { sessionPo, history } = hooksRef.current;
        try {
            const { password } = data;
            const jwt = await forgetPasswordSession(sessionPo, password);
            await securityProvider.setToken(jwt);
            snackbarController.open(<TR name={'success'} />, 'success');
            const userProfile = await getUserProfile();
            securityProvider.setUserProfile(userProfile);
            await handleRedirect(history);
        } catch (e) {
            console.log(getErrorData(e));
        }
    }

    ////////////////////////////////////////////////////////////////////////
    return (props) => {
        if (!hasToken()) {
            return <div>you are not able to reset password</div>;
        }

        return (
            <Grid container justify={'center'} direction={'column'} alignItems={'center'}>
                <Container>
                    <AuthDialogHeader onClose={appProvider.onCloseAuthDialog} />
                    <Grid container justify={'flex-start'}>
                        <h2>
                            <TR name={'Reset your Password'} />
                        </h2>
                    </Grid>

                    <XTextField
                        form={form}
                        name="password"
                        type="password"
                        helperText={<TR name={PASSWORD_HELPER_TEXT} />}
                    />
                    <XTextField form={form} name="password2" label={'Password Again'} type="password" />
                    <Button
                        className={'mt-2 mb-2'}
                        onClick={form.handleSubmit}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!form.dirty}
                        disableElevation>
                        <TR name={'Confirm'} />
                    </Button>
                </Container>
            </Grid>
        );
    };
});

import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { ContentData } from './ContentData';
import { ContentTitle } from './ContentTitle';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            displayDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        ContentData: {
            paddingLeft: '15px',
            maxWidth: '490px',
        },
    };
});

export const ContentTitleData = rst.create<{ header?: string; content: any; mobile?: true }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const { header, content } = props;
        return (
            <div className={classes.root}>
                <ContentTitle hasArrow={true} header={header} />
                <div className={classes.ContentData}>
                    <ContentData content={content} />
                </div>
            </div>
        );
    };
});

import { withStyles } from '@material-ui/core/styles';
import { Tab, TabProps, Tabs, Theme } from '@material-ui/core';
import React from 'react';

export const XTabs = withStyles((theme) => {
    return {
        root: {},
        indicator: {
            backgroundColor: theme.palette.primary.main,
        },
    };
})(Tabs);

export const XTab = withStyles((theme: Theme) => ({
    root: {
        color: '#000000',
        textTransform: 'none',
        minWidth: 72,
        fontWeight: 'bold',
        fontSize: 16,
        marginRight: theme.spacing(4),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    selected: {},
}))((props: TabProps) => <Tab disableRipple {...props} />);

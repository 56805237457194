import React from 'react';
import { RankTable } from './RankTable';

export const StreetRanks = (props: any) => {
    const { expensiveStreets, chepestStreets, highestChangeStreets, lowestChangeStreets } = props;
    return (
        <>
            <RankTable
                title={'HIGHEST_PRICE_STREETS'}
                rowData={expensiveStreets}
                columns={[
                    { headerName: 'STREETNAME', columnWidth: '80%' },
                    { headerName: 'INCREASE_PCT', columnWidth: '20%' },
                ]}></RankTable>

            <div style={{ marginTop: 20 }}></div>
            <RankTable
                title={'LOWEST_PRICE_STREETS'}
                rowData={chepestStreets}
                columns={[
                    { headerName: 'STREETNAME', columnWidth: '80%' },
                    { headerName: 'INCREASE_PCT', columnWidth: '20%' },
                ]}></RankTable>

            <div style={{ marginTop: 20 }}></div>
            <RankTable
                title={'HIGHEST_SOLD_STREETS'}
                rowData={highestChangeStreets}
                columns={[
                    { headerName: 'STREETNAME', columnWidth: '80%' },
                    { headerName: 'TURNOVER_PCT', columnWidth: '20%' },
                ]}></RankTable>

            <div style={{ marginTop: 20 }}></div>
            <RankTable
                title={'LOWEST_SOLD_STREETS'}
                rowData={lowestChangeStreets}
                columns={[
                    { headerName: 'STREETNAME', columnWidth: '80%' },
                    { headerName: 'TURNOVER_PCT', columnWidth: '20%' },
                ]}></RankTable>
        </>
    );
};

import i18next from 'i18next';

export const zh = {
    Required: '必填',
    '{{field}} is not an array': '{{field}} 不是数组',
    // number
    'Minimum is {{min}}': '最小值是{{min}}',
    'Maximum is {{max}}': '最大值是{{max}}',
    // string
    'Too short, minimum is {{min}} characters': '太短了，至少{{min}}个',
    'Too long, maximum is {{max}} characters': '太长了，最多{{max}}个',
    'Invalid email': 'email无效',
};

export const en = {};

i18next.on('initialized', () => {
    i18next.addResources('zh', 'translation', zh);
});

import { createMuiTheme } from '@material-ui/core/styles';
import { rst } from 'rt-state';
import { PaletteType } from '@material-ui/core';
import { Localization, enUS } from '@material-ui/core/locale';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { themeOptions } from './theme';
import { createXSnackbarController, SnackbarController } from '../../components/SnackBar';
import { changeLocale, initLanguage } from '../i18n/commons';
import * as locales from '@material-ui/core/locale';
import { changeLanguage } from '../../services/auth';
import { SecurityProvider } from '../security';
import { saveUserAccessLog } from '../../services/userCenter';

export const getPrefersDarkMode = () => {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
};

interface AppState {
    themeMode: PaletteType;
    locale: Localization;
    authDialog: 'login' | 'signup' | null;
    authDialogCallback?: () => void;
    isLogOut?: boolean;
}

export interface AppProviderProps {
    state: AppState;
    onCloseAuthDialog(): void;
    openLoginDialog(cb: () => void): void;
    openSignupDialog(cb: () => void): void;
    toggleThemeMode(): void;
    getTheme(): Theme;
    getNextThemeMode(): string;
    setLocaleAndSave(locale: Localization): void;
    getLocale(): Localization;
    getLanguage(): string;
    snackbarController: SnackbarController;
}

export const AppProvider = rst.createProvider<AppProviderProps, {}>(() => {
    const securityProvider = SecurityProvider.use();
    // default locale is enUS
    const state = rst.state<AppState>({
        themeMode: 'light',
        locale: enUS,
        authDialog: null,
        authDialogCallback: null,
        isLogOut: null,
    });

    const snackbarController = createXSnackbarController();

    function onCloseAuthDialog() {
        state.authDialog = null;
        const { authDialogCallback } = state;
        state.authDialogCallback = null;
        if (authDialogCallback) {
            authDialogCallback();
        }
    }

    const openLoginDialog = (cb: () => void) => {
        state.authDialog = 'login';
        state.authDialogCallback = cb;
    };

    const openSignupDialog = (cb: () => void) => {
        state.authDialog = 'signup';
        state.authDialogCallback = cb;
    };

    const toggleThemeMode = () => {
        state.themeMode = getNextThemeMode();
    };

    const getNextThemeMode = () => {
        return state.themeMode === 'light' ? 'dark' : 'light';
    };

    const getTheme = () => {
        themeOptions.palette.type = state.themeMode;
        return createMuiTheme(themeOptions, state.locale);
    };

    const setLocale = async (locale: Localization) => {
        await changeLocale(locale);
        state.locale = locale;
    };

    const setLocaleAndSave = async (locale: Localization) => {
        if (securityProvider.getUserProfile() != null) {
            try {
                const newLan = locale === locales.enUS ? 'en' : 'zh';
                const oldLan = locale === locales.enUS ? 'zh' : 'en';
                saveUserAccessLog('CHANGE_LANGUAGE', securityProvider.getUserProfile().id, {
                    from: oldLan,
                    to: newLan,
                });
                await changeLanguage(locale === locales.enUS ? 'en' : 'zh');
            } catch (e) {
                console.log('failed to save user language settings');
            }
        }
        await setLocale(locale);
    };

    const getLanguage = (): string => {
        return state.locale === locales.enUS ? 'en' : 'zh';
    };

    const getLocale = () => {
        return state.locale;
    };

    rst.watch(
        async (values) => {
            const [profile] = values;
            const language = profile?.preferredLanguage;
            const locale = await initLanguage(language);
            await setLocale(locale);
        },
        () => [securityProvider.getUserProfile()],
    );

    return {
        state,
        onCloseAuthDialog,
        openLoginDialog,
        toggleThemeMode,
        openSignupDialog,
        getTheme,
        getNextThemeMode,
        setLocaleAndSave,
        getLocale,
        getLanguage,
        snackbarController,
    };
});

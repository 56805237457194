import { rst } from 'rt-state';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useStyles } from '../login.styles';
import { Button } from '@material-ui/core';
import * as React from 'react';
import { TR } from '../../../commons';

export const SignupInfo = rst.create<{}>((ctx) => {
    return (props) => {
        const { t } = useTranslation();
        const lan = i18next.language;
        const classes = useStyles(props);

        const chineseInfo = (
            <div style={{ fontWeight: 400, fontSize: 14 }}>
                <TR name="SignupNote1"></TR>
            </div>
        );

        return <div className="App">{chineseInfo}</div>;
    };
});

import { Divider, Hidden, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { isMobileDevice } from '../../../commons/utils';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { statsQuery_byGraphQL } from '../../../commons/graphqlClient/graphAPI';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '20px 10px 30px 10px',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: isMobileDevice() ? 'column' : 'row',
        justifyContent: 'center',
        fontSize: isMobileDevice() ? '13px' : '16px',
        [theme.breakpoints.down('sm')]: {
            padding: '28px 16px',
            //width: '100vw',
            // justifyContent: 'space-between',
        },
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        margin: '0 10px',
        // marginBottom: '30px',
        padding: '0px 0px 0px 0px',
        background: 'rgb(247 247 247)',
        '& img': {
            marginTop: '0px',
            width: '200px',
            height: '200px',
            [theme.breakpoints.down('sm')]: {
                width: '100px',
                margin: '0px 0px 0px 0px',
            },
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',

        //paddingLeft: '31px',
        padding: '24px 24px 24px 24px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0px',
        },
        '& p': {
            //fontSize: '14px',
            fontSize: isMobileDevice() ? '10px' : '14px',
            lineHeight: '22px',
            margin: '0px 0px 0px 0px',
            color: 'rgb(123 123 123)',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                margin: '0px',
                fontSize: '13px',
                lineHeight: '18px',
            },
        },
        '& hr': {
            width: '20%',
            heigh: '0px',
            borderTop: 'solid #96D9D2 1px',
            textAlign: 'center',
        },
    },
    title: {
        fontSize: '44px',
        fontWeight: 'bold',
        textAlign: 'center',
        display: 'inline-block',
        padding: '10px 10px 10px 10px',
        '& h3': {
            fontSize: '34px',
            display: 'inline-block',
            margin: '0px 0px 0px 0px',
            float: 'center',

            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '26px',
                margin: '0px 0px 5px 0px',
            },
        },
        '& h4': {
            fontSize: '22px',
            display: 'inline-block',
            fontWeight: 'normal',
            margin: '0px 0px 0px 0px',
            float: 'center',

            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '26px',
                margin: '0px 0px 5px 0px',
            },
        },
    },
    titleTop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '26px',
        margin: '45px 0px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '19px',
            margin: '0px 0px 10px 0px',
        },
    },
}));

export const NewHomeTransactionCard = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        const [updateTransactionData, setTransactionData] = useState('');
        const [updateNewComersData, setNewComersData] = useState('');

        const [ref, inView] = useInView({
            threshold: 0.3,
            triggerOnce: true,
        });

        useEffect(() => {
            getStatsInfo();
        }, []);

        const getStatsInfo = async () => {
            const statsInfoResult: any = await statsQuery_byGraphQL();
            setTransactionData(statsInfoResult.data.statsInfo.propertiesSoldInLast15Days);
            setNewComersData(statsInfoResult.data.statsInfo.propertiesTaggedInLast15Days);
        };
        return (
            <div style={{ background: 'rgb(255 255 255)' }}>
                <h2 className={classes.titleTop}>
                    <TR name={'NewHome_NewHomeDataHeader'} />
                </h2>
                <div className={classes.root}>
                    <div className={classes.item}>
                        {/* <Hidden smDown> */}
                        <img src={`${process.env.PUBLIC_URL}/static/images/pc-1.png`} alt="data-ico.png" />
                        {/* </Hidden> */}
                        <div className={classes.content}>
                            <p style={{ lineHeight: 1 }}>
                                <TR name={'NewHome_LatestTransactionCardNumbers1Up'} />
                            </p>
                            <div className={classes.title} ref={ref}>
                                <h3>+</h3>
                                <CountUp
                                    start={0}
                                    end={inView ? Number(updateTransactionData) : 0}
                                    duration={2}
                                    useEasing={true}
                                />
                                {'  '}
                                <h4>
                                    <TR name={'NewHome_Data'} />
                                </h4>
                            </div>
                            <hr></hr>
                            <p style={{ lineHeight: 1 }}>
                                <TR name={'NewHome_LatestTransactionCardNumbers1Down'} />
                            </p>
                        </div>
                    </div>
                    <Hidden mdUp>
                        <Divider />
                    </Hidden>
                    {/* <div className={classes.item}> */}
                    {/* <Hidden smDown> */}
                    {/* <img src={`${process.env.PUBLIC_URL}/static/images/pc-2.png`} alt="data-ico.png" /> */}
                    {/* </Hidden> */}
                    {/* <div className={classes.content}>
                            <p style={{ lineHeight: 1 }}>
                                <TR name={'NewHome_LatestTransactionCardNumbers2Up'} />
                            </p>
                            <div className={classes.title} ref={ref}>
                                <h3>+</h3>
                                <CountUp
                                    start={1}
                                    end={inView ? Number(updateNewComersData) : 0}
                                    duration={2}
                                    useEasing={true}
                                />
                                {'  '}
                                <h4>
                                    <TR name={'NewHome_Data'} />
                                </h4>
                            </div>
                            <hr></hr>
                            <p style={{ lineHeight: 1 }}>
                                <TR name={'NewHome_LatestTransactionCardNumbers2Down'} />
                            </p>
                        </div>
                    </div>
                    <Hidden mdUp>
                        <Divider />
                    </Hidden> */}
                </div>
            </div>
        );
    };
});

import { UserCenterEntityType } from './UserCenterEntityType';
import { FavouriteDto } from './FavouriteDto';

export class FavouritePo {
    entityType: UserCenterEntityType;
    entityId: string;
    static from(dto: FavouriteDto) {
        const po = new FavouritePo();
        po.entityType = dto.entityType;
        po.entityId = dto.entity.propId;
        return po;
    }
}

export class FavouriteUpdatePo {
    id: string;
    top: boolean;

    static from(dto: FavouriteDto) {
        const po = new FavouriteUpdatePo();
        po.id = dto.id;
        po.top = dto.top;
        return po;
    }
}

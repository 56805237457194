import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import * as colors from '@material-ui/core/colors';

export const themeOptions: ThemeOptions = {
    typography: {
        fontSize: 12,
        fontFamily: 'Noto Sans SC',
        fontWeightLight: 300,
        fontWeightMedium: 500,
        fontWeightBold: 900,
        button: {
            textTransform: 'none',
        },
    },
    direction: 'ltr',
    palette: {
        type: 'light',
        primary: {
            main: '#1875f0',
            light: '#999999',
        },
        secondary: colors.red,

        error: colors.red,
    },
    shape: {
        borderRadius: 4,
    },
    props: {
        MuiButton: {
            size: 'small',
        },
        MuiFilledInput: {
            margin: 'dense',
        },
        MuiFormControl: {
            margin: 'dense',
        },
        MuiFormHelperText: {
            margin: 'dense',
        },
        MuiIconButton: {
            size: 'small',
            color: 'primary',
        },
        MuiInputBase: {
            margin: 'dense',
        },
        MuiInputLabel: {
            margin: 'dense',
        },
        MuiListItem: {
            dense: true,
        },
        MuiOutlinedInput: {
            margin: 'dense',
        },
        MuiFab: {
            size: 'small',
        },
        MuiTable: {
            size: 'medium',
        },
        MuiTextField: {
            margin: 'dense',
        },
        MuiToolbar: {
            variant: 'dense',
        },
        MuiContainer: {
            maxWidth: false,
        },
        MuiPaper: {
            variant: 'elevation',
            elevation: 3,
        },
    },
    overrides: {},
};

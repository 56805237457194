import { Type } from 'class-transformer';

export class PropertyReportDto {
    id: string;
    @Type(() => Date)
    createTime: Date;
    entity: PropertyReportDtoEntity;
}

export interface PropertyReportDtoEntity {
    id: string;
    stdAddress?: string;
    propType?: string;
}

import { rst } from 'rt-state';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { XProps } from '../../commons';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            display: 'inline',
        },
        paper: {
            width: '80%',
            // maxHeight: 500,
        },
    }),
);

export interface XBaseDialogProps extends XProps {
    open: boolean;
    onClose?: () => void;
    dialogProps?: Omit<DialogProps, 'open'>;
}

export const XBaseDialog = rst.create<XBaseDialogProps>((ctx) => {
    function _onClose() {
        ctx.props.onClose?.(); // eslint-disable-line
    }
    return (props) => {
        const classes = useStyles(props);
        const { open, dialogProps } = props;

        return (
            <div className={classes.root}>
                <Dialog
                    onClose={_onClose}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={open}
                    {...dialogProps}>
                    {props.children}
                </Dialog>
            </div>
        );
    };
});

import React from 'react';
import { rst } from 'rt-state';
import { useTranslation } from 'react-i18next';

export const TR = rst.createS<{ name: string }>((props) => {
    const { t } = useTranslation();

    return t(props.name);
});

export const TROrNA = rst.createS<{ name: string }>((props) => {
    const { t } = useTranslation();
    if (props.name == null) {
        return t('N/A');
    }
    return t(props.name);
});

/**
 * params is the key-value pair need to pass dynamic value to the translated str
 * format : {key : value}...
 */
export const TRHtml = rst.createS<{ html: string; params?: any; style?: any }>((props) => {
    const { t } = useTranslation();
    var translated = t(props.html);
    if (props.params) {
        Object.keys(props.params).forEach((key) => {
            translated = translated.replace('${' + key + '}', props.params[key]);
        });
    }
    return (
        <div
            style={{ display: 'inline', ...props.style }}
            dangerouslySetInnerHTML={{
                __html: translated,
            }}
        />
    );
});

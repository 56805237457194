import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { rst } from 'rt-state';

import { SearchHouse } from './searchHouse';
import { SearchStreet } from './searchStreet';
import { SearchArea } from './searchArea';
import { TR } from '../../../../commons';

export const MobileFunctionCard = rst.create((ctx) => {
    // const classes = useStyles();
    return (props) => {
        return (
            <div style={{ padding: '1rem' }}>
                <h3 style={{ margin: 0, fontSize: '1.375rem' }}>
                    <TR name="mobile_FunctionCardDesc"></TR>
                </h3>
                <SearchHouse></SearchHouse>
                <SearchStreet></SearchStreet>
                <SearchArea></SearchArea>
            </div>
        );
    };
});

import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons/i18n/TR';
import {
    findMaxInArray,
    findMinInArray,
    isMobileDevice,
    removeSpaceAndWords,
    removeZeroFromArray,
} from '../../../commons/utils';
import { HelpComponent } from '../../PriceMap/components/HelpComponent';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { ContentTitleData } from '../components/ContentTitleData';
import { Glossary } from '../components/Glossary';
import { ReportMap } from '../components/reportMap/ReportMap';
import { Tips } from '../components/Tips';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            textAlign: 'left',
            padding: '5px 0',
        },
        part2: {
            textAlign: 'left',
            padding: '5px 0',
        },
        part2WithGlossary: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 0',
        },
        part3: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 0',
        },
        part4: {
            margin: '0 auto',
        },
        part4Header: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px',
            cursor: 'pointer',
        },
        HeaderArrow: {
            display: 'flex',
            justifyContent: 'flex-start',
            margin: '0px',
        },
        icon: {
            paddingTop: '5px',
            paddingLeft: '5px',
        },
        legend: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        legendItem: {
            width: '10px',
            height: '10px',
            borderRadius: '5px',
        },
        part5: {
            margin: '0 auto',
        },
        schooName: {
            fontSize: 14,
            paddingTop: '15px',
        },
        schoolRow: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
        },
        schoolRowItem: {
            width: '25%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        schoolRowLabel: {
            fontSize: '13px',
            color: '#666666',
        },
        schoolRowValue: {
            marginLeft: 10,
            background: '#f1f5ff',
            // fontSize: '13px',
            // paddingLeft: 5,
            padding: '0 3px',
            border: '1px solid #bdd8ff',
            borderRadius: '3px',
            minWidth: 40,
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                height: '19px',
                // minWidth: 35,
            },

            '& div': {
                textAlign: 'center',
            },
            '& span': {
                fontSize: '13px',
                position: 'relative',
                top: '-1px',
            },
        },
    };
});
declare var Highcharts;
const chartColor = {
    nineYears: '#ff9f40',
};
export const Card21 = rst.create<{ property: any; mobile?: boolean }>((ctx) => {
    // const isOpenTrend = rst.stateS(false);
    // const toggleTrendOptions = () => {
    //     isOpenTrend.value = !isOpenTrend.value;
    // };
    // const isOpenRange = rst.stateS(false);
    // const toggleRangeOptions = () => {
    //     isOpenRange.value = !isOpenRange.value;
    // };

    function loadChart() {
        try {
            Highcharts.chart('card21', {
                chart: {
                    type: 'spline',
                    height: 180,
                    marginBottom: isMobileDevice() ? 30 : 35,
                },
                title: {
                    text: '',
                },

                subtitle: {
                    text: '',
                },

                yAxis: {
                    title: {
                        text: '',
                    },
                    max:
                        ctx.props.property.primarySchools[0].yearlyRanking === null
                            ? 1
                            : findMaxInArray(removeZeroFromArray(ctx.props.property.primarySchools[0].yearlyRanking)) +
                              1,
                    min:
                        ctx.props.property.primarySchools[0].yearlyRanking === null
                            ? 1
                            : findMinInArray(removeZeroFromArray(ctx.props.property.primarySchools[0].yearlyRanking)),
                    // tickInterval: 50,
                    // startOnTick: false,
                    labels: {
                        step: 1,
                        formatter: function () {
                            const _this: any = this;
                            if (_this.value >= 230) {
                                return '';
                            } else {
                                return _this.value;
                            }
                        },
                    },
                    // type: 'category',
                    // categories: [1, 50, 100, 150, 200],
                    reversed: true,
                },

                xAxis: {
                    type: 'category',
                    categories:
                        ctx.props.property.primarySchools[0].yearlyRanking === null
                            ? [0, 0, 0, 0, 0]
                            : ctx.props.property.primarySchools[0].yearlyRanking.year,
                    crosshair: true,
                },

                legend: {
                    enabled: false,
                },
                credits: { enabled: false },
                tooltip: {
                    enabled: false,
                    // backgroundColor: 'white', // 背景颜色
                    // borderColor: 'black', // 边框颜色
                    // borderRadius: 10, // 边框圆角
                    // borderWidth: 1, // 边框宽度
                    // animation: true, // 是否启用动画效果
                    //format the date and numbers
                    // formatter: function () {
                    //     const _this: any = this;
                    //     console.log(_this);
                    //     var d = new Date(_this.x);
                    //     var s = '<b>' + _this.x + '</b>';
                    //     s += '<br/><span>Price: ' + '$' + (_this.y / 1000000).toFixed(2) + 'm' + '</span>';
                    //     return s;
                    // },
                },
                plotOptions: {
                    spline: {
                        dataLabels: {
                            enabled: true,
                            labels: {
                                step: 1,
                            },
                            formatter: function () {
                                const _this: any = this;
                                if (_this.y >= 230) {
                                    return 'N/A';
                                } else {
                                    return _this.y;
                                }
                            },
                        },
                        enableMouseTracking: false,
                    },
                    series: {
                        enableMouseTracking: true,
                        marker: {
                            enabled: false,
                        },
                    },
                    areaspline: {
                        fillOpacity: 0.25,
                    },
                    area: {
                        stacking: 'normal',
                        lineColor: '#666666',
                        lineWidth: 2,
                        opacity: 1,

                        marker: {
                            lineWidth: 1,
                            lineColor: '#666666',
                        },
                    },
                },

                series: [
                    {
                        data:
                            ctx.props.property.primarySchools[0].yearlyRanking === null
                                ? [0, 0, 0, 0, 0]
                                : removeZeroFromArray(ctx.props.property.primarySchools[0].yearlyRanking),
                        color: chartColor.nineYears,
                        fillOpacity: 0.3,
                        opacity: 1,
                        lineColor: chartColor.nineYears,
                    },
                ],
            });
        } catch (e) {}
    }
    return (props) => {
        console.log(props.property);
        const classes = useStyles();
        setTimeout(() => {
            loadChart();
        }, 400);
        const publicSchoolName = removeSpaceAndWords(props.property.primarySchools[0].schoolName, ' School');
        console.log(publicSchoolName);

        const presentPrimarySchoolList = () => {
            const returnNodes = [];
            props.property.locality.intersectedPrimary.forEach((loopSchool) => {
                returnNodes.push(
                    <>
                        <div className={classes.schooName}>{loopSchool.schoolName}</div>
                        <div className={classes.schoolRow}>
                            <div className={classes.schoolRowItem}>
                                <div className={classes.schoolRowLabel}>
                                    <div>
                                        <span>
                                            <TR name="Card21_tol_enrolments" />
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.schoolRowValue}>
                                    <div>
                                        <span style={{ color: 'rgb(81 147 247)' }}>{loopSchool.school.enrolments}</span>
                                    </div>
                                </div>
                            </div>

                            {/* <div style={{ marginLeft: 50 }}></div> */}
                            <div className={classes.schoolRowItem}>
                                <div className={classes.schoolRowLabel}>
                                    <div>
                                        <span>
                                            <TR name="primaryStarRating"></TR>
                                        </span>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        background: 'rgba(180,59,59,0.06)',
                                        color: '#b43b3b',
                                        border: '1px solid rgb(232 209 209)',
                                    }}
                                    className={classes.schoolRowValue}>
                                    <div>
                                        <span>{loopSchool.school.starRating ? loopSchool.school.starRating : 1}</span>
                                        <img
                                            src="assets/images/svgs/gold_star.svg"
                                            style={{ height: '12px', paddingLeft: '2px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.schoolRowItem}>
                                {/* <div style={{ marginLeft: 50 }}></div> */}
                                <div className={classes.schoolRowLabel}>
                                    <div>
                                        <span>
                                            <TR name="Card21_icsea"></TR>
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.schoolRowValue}>
                                    <div>
                                        <span style={{ color: 'rgb(81 147 247)' }}>{loopSchool.school.icsea}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>,
                );
            });
            console.log(returnNodes);
            return returnNodes;
        };
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader header={props.mobile ? '' : 'Card21_header'}>
                    {/* <Tips header="Card_Tips" content="CARD21_TIPS" /> */}
                    <div className={classes.part1}>
                        <ContentTitleData
                            header="Card21_school_name"
                            content={props.property.primarySchools[0].schoolName}
                        />
                    </div>
                    <CardDivider />
                    <div className={classes.part2}>
                        <ContentTitleData
                            header="Card21_tol_enrolments"
                            content={props.property.primarySchools[0].enrolments}
                        />
                    </div>
                    <CardDivider />
                    <div className={classes.part2}>
                        <ContentTitleData
                            header="Card21_lbotePct"
                            content={props.property.primarySchools[0].lbotePct + '%'}
                        />
                        {/* <HelpComponent content="HELP_ICSEA" maxWidth="xs" /> */}
                    </div>
                    <CardDivider />
                    <div className={classes.part2WithGlossary}>
                        <ContentTitleData header="Card21_icsea" content={props.property.primarySchools[0].icsea} />
                        <Glossary glossaryId="40" />
                    </div>

                    <div className={classes.part5}>
                        <div className={classes.part4Header}>
                            {/* <div className={classes.part4Header} onClick={toggleRangeOptions}> */}
                            <div className={classes.HeaderArrow}>
                                <div style={{ fontSize: '14px' }}>
                                    <span>
                                        <TR name={'Card21_rankingRange'} />
                                    </span>
                                </div>
                                {/* <div className={classes.icon}>
                                    <img
                                        src={
                                            'assets/icons/' +
                                            (!isOpenRange.value ? 'arrow-lower-ico' : 'arrow-lupper-ico') +
                                            '.svg'
                                        }></img>
                                </div> */}
                            </div>
                        </div>
                        {/* {isOpenRange.value ? (
                            <> */}
                        <ReportMap
                            propCenter={{ lat: props.property.propY, lon: props.property.propX }}
                            drawingObject={'1'}
                            drawingData={{
                                schoolData: props.property.locality.intersectedPrimary,
                                localityData: props.property.locality.polygonEncoded,
                            }}></ReportMap>

                        {presentPrimarySchoolList()}
                        {/* </>
                        ) : null} */}
                    </div>
                </CardWithHeader>
            </div>
        );
    };
});

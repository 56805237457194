import { rst } from 'rt-state';
import { XCircularProgress } from './CircularProgress';
import React from 'react';

export const LoadingIndicator = rst.createS<{
    isLoading: boolean;
    children: any;
}>((props) => {
    return (
        <div style={{ position: 'relative' }}>
            {props.isLoading && (
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 110,
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'rgba(255,255,255,0.8)',
                    }}>
                    <XCircularProgress size={24} />
                </div>
            )}

            {props.children}
        </div>
    );
});

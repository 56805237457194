import { Button, makeStyles } from '@material-ui/core';
import ReactDOM from 'react-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { rst, state } from 'rt-state';
import { TR, TRHtml } from '../../../../../commons';
import { convertToM, isMobileDevice, numToMillion, decimalToPct, generateUUID } from '../../../../../commons/utils';
import { MapGlobalVars, PropTypes } from '../../../support/PriceMapConsts';
import { getTypeByFeature, showGoogleResultByAddress } from '../../../support/propertyFeatureHelper';
import { StreetView } from '../../PropertyInfo';
import { PropBeds } from '../../PropertyInfo/PropBeds';
import { SchoolOrStationScroll } from '../SchoolOrTrainStation/SchoolOrStationScrolls';
import { SchoolOrStationSimple } from '../SchoolOrTrainStation/SchoolOrStationSimple';
import { CommonSchoolOrStation } from '../SchoolOrTrainStation';
import { ShareOrFavOrOther } from '../ShareOrFavOrOther';
import { PropertyPeriodChooser } from '../../PropertyPeriodChooser';
import { EventBus, EVENT_NAMES } from '../../../../../commons/EventBus';
import { Chip } from '../../../../../components/Chip';
import { HelpComponent } from '../../../components/HelpComponent';
import { mobileNavButton } from '../../../../mobile/consts/styleConsts';
const useStyles = makeStyles((theme) => {
    return {
        propAddr: {
            fontSize: isMobileDevice() ? 13 : 16,
            fontWeight: 400,
            color: '#333333',
            cursor: 'pointer',
        },
        basicInfoDiv: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            //height: !isMobileDevice() ? 124 : 86,
            width: '98%',
        },
        price: {
            fontSize: 16,
            fontWeight: 700,
            color: '#333333',
        },
        priceDesc: {
            fontSize: 12,
            color: '#999999',
            fontWeight: 400,
        },

        housePrice: {
            fontSize: isMobileDevice() ? 14 : 20,
            fontWeight: 700,
            color: '#e02020',
            flexGrow: 1,
        },
        soldDate: {},
        font: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                color: '#999999',
            },
        },
    };
});

export const RecentSoldHouseInfo = rst.create<{ prop: any; onMap?: boolean; onReport?: boolean }>((ctx) => {
    return (props) => {
        const { prop } = props;
        console.log(prop);
        const classes = useStyles();

        if (props.onMap) {
            setTimeout(() => {
                if (document.getElementById('houseAddressDiv')) {
                    document.getElementById('houseAddressDiv').addEventListener('click', () => {
                        showGoogleResultByAddress(prop.address);
                    });
                }
            }, 1000);
        }
        const handle = () => {
            console.log('www');
        };

        const openPropertyDetails = () => {
            const suburbURL = `/mobileHome?queryType=1&queryId=${props.prop.propId}&queryAddress=${props.prop.address}`;
            window.open(suburbURL, '_blank');
        };

        setTimeout(() => {
            const detailBtn = document.getElementById('propDetailOnMapBtn');
            if (detailBtn) {
                detailBtn.addEventListener('click', () => {
                    openPropertyDetails();
                });
            }
        }, 3000);
        return (
            <>
                <div>
                    <div style={{ display: 'flex', alignItems: 'flex-start', paddingRight: 10, paddingTop: 10 }}>
                        <StreetView
                            w={!isMobileDevice() ? 135 : 100}
                            h={!isMobileDevice() ? 124 : 100}
                            lat={props.prop.lat}
                            lng={props.prop.lon}
                            onMap={props.onMap}
                            showFor="infopanel"
                            address={props.prop.address}></StreetView>
                        <div style={{ marginLeft: 10 }}></div>
                        <div className={classes.basicInfoDiv}>
                            <div
                                style={{
                                    display: props.onReport ? 'none' : 'flex',
                                    paddingRight: 0,
                                    width: '100%',
                                }}>
                                <div
                                    className={classes.propAddr}
                                    id="houseAddressDiv"
                                    onClick={() => {
                                        showGoogleResultByAddress(prop.address);
                                    }}>
                                    {props.onReport ? null : prop.address}
                                </div>

                                {props.onReport ? null : <ShareOrFavOrOther prop={prop}></ShareOrFavOrOther>}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}>
                                <div>
                                    <div className={classes.housePrice}>{numToMillion(prop.price)}</div>
                                    <div className={classes.soldDate}>
                                        <TR name={'LAST_SOLD_TIME'}></TR>
                                    </div>
                                    <div className={classes.soldDate}>({prop.sold_contract_date})</div>
                                </div>
                                <div style={{ marginLeft: 10 }}></div>
                                <div
                                    style={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                    }}>
                                    <div className={classes.housePrice} style={{ color: '#000000' }}>
                                        {numToMillion(prop.allFeatures?.land_value_reset)}
                                    </div>
                                    <div className={classes.soldDate}>
                                        <TR name={'landValue'}></TR>
                                    </div>
                                    <div className={classes.soldDate}>
                                        ({prop?.allFeatures?.prop_area}m<sup>2</sup>,{' '}
                                        {prop?.allFeatures?.prop_zoning_string})
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: 10 }}></div>
                            <PropBeds
                                landSize={prop?.allFeatures?.prop_area}
                                smallSize={true}
                                beds={prop?.allFeatures?.prop_bed}
                                baths={prop?.allFeatures?.prop_bath}
                                cars={prop?.allFeatures?.prop_car}
                                propType={getTypeByFeature(prop.allFeatures)}></PropBeds>
                        </div>
                    </div>
                </div>
                {isMobileDevice() && !props.onReport ? (
                    <Button
                        id="propDetailOnMapBtn"
                        fullWidth
                        variant="outlined"
                        style={{ ...mobileNavButton, marginTop: 10 }}>
                        <TR name="Details"></TR>
                    </Button>
                ) : (
                    <>
                        <div style={{ marginTop: 10 }}></div>

                        <CommonSchoolOrStation
                            onMap={props.onMap}
                            onReport={props.onReport}
                            prop={prop}
                            recentSold={true}></CommonSchoolOrStation>
                        <div style={{ marginTop: 10 }}></div>
                        <div
                            style={{
                                display: props.onReport ? 'none' : 'flex',
                                marginTop: 15,

                                justifyContent: 'space-between',
                                alignItems: 'flex-end',
                            }}>
                            <div>
                                <div className={classes.font}>
                                    <TR name={'Zoning'}></TR>
                                </div>
                                <div>
                                    <Chip label={props.prop.zoningStr}></Chip>
                                </div>
                            </div>
                            <div>
                                <div className={classes.font}>
                                    {' '}
                                    <TR name={'PriceToStreet'}></TR>
                                </div>
                                <div>
                                    <Chip
                                        label={
                                            props.prop.streetPct == 0
                                                ? '-'
                                                : (props.prop.streetPct * 100).toFixed(1) + '%'
                                        }></Chip>
                                </div>
                            </div>

                            <div>
                                <div className={classes.font}>
                                    {/* <div className={classes.font} style={{ display: 'flex', alignItems: 'center' }}> */}
                                    <TR name={'StreetHouseChangeHand'}></TR>
                                    {/* <div style={{ width: 23 }}>
                                <HelpComponent content="StreetHouseChangeHand_help" maxWidth="xs" />
                            </div> */}
                                </div>
                                <div>
                                    <Chip
                                        label={
                                            (props.prop.streetChangeHandRate == 0
                                                ? '-'
                                                : (props.prop.streetChangeHandRate * 100).toFixed(1) + '%') +
                                            '(' +
                                            (props.prop.suburbChangeHandRate == 0
                                                ? '-'
                                                : (props.prop.suburbChangeHandRate * 100).toFixed(1) + '%') +
                                            ')'
                                        }></Chip>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };
});

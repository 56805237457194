import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons/i18n/TR';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { ReportMap } from '../components/reportMap/ReportMap';
const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
        partn: {
            background: 'rgb(250 252 255)',
            border: '1px solid rgb(236 239 246)',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px 0px',
            '& h2': {
                fontSize: '14px',
                margin: '0px 0px 8px 0px',
                color: '#B43B3B',
                background: 'linear-gradient(to bottom, rgb(250 252 255) 70%, rgb(249 235 237) 0%)',
                width: 'fit-content',
            },
            '& span': {
                fontSize: '13px',
                color: '#848383',
            },
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            margin: '0 auto',
        },
        part2: {
            margin: '0 auto',
        },
        schoolTable: {
            margin: '10px auto',
            borderCollapse: 'collapse',
            '& th': {
                background: 'rgb(221 242 255)',
                border: '1px solid rgb(167 222 255)',
                height: '30px',
                textAlign: 'center',
            },
            '& td': {
                border: '1px solid rgb(167 222 255)',
                height: '30px',
                textAlign: 'center',
            },
        },
    };
});

export const Card24 = rst.create<{ prop: any; mobile?: boolean }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const schoolFilter = (schools: any, gender: string) => {
            const newSchools = schools.filter(
                (school) => school.schoolSex === 'Combined' || school.schoolSex === gender,
            );
            return newSchools;
        };
        const getNum = (gender: string) => {
            if (schoolFilter(props.prop.property.privateSchools, gender).length >= 10) {
                return <TR name="CARD24_TIPS_10" />;
            }
            if (
                schoolFilter(props.prop.property.privateSchools, gender).length >= 5 &&
                schoolFilter(props.prop.property.privateSchools, gender).length <= 9
            ) {
                return <TR name="CARD24_TIPS_5-9" />;
            }
            if (
                schoolFilter(props.prop.property.privateSchools, gender).length >= 2 &&
                schoolFilter(props.prop.property.privateSchools, gender).length <= 4
            ) {
                return <TR name="CARD24_TIPS_2-4" />;
            }
            if (schoolFilter(props.prop.property.privateSchools, gender).length <= 1) {
                return <TR name="CARD24_TIPS_1" />;
            } else {
                return <TR name="CARD24_TIPS_1" />;
            }
        };
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader header={props.mobile ? '' : 'Card24_header'}>
                    <div className={classes.partn}>
                        <span>
                            <TR name="CARD24_TIPS1" />
                            <span style={{ color: 'red' }}>
                                {schoolFilter(props.prop.property.privateSchools, 'Boy').length}
                            </span>
                            <span>{getNum('Boy')}</span>
                            <TR name="CARD24_TIPS2" />
                            <span style={{ color: 'red' }}>
                                {schoolFilter(props.prop.property.privateSchools, 'Girl').length}
                            </span>
                            <span>{getNum('Girl')}</span>
                            <TR name="CARD24_TIPS3" />
                        </span>
                    </div>
                    <div className={classes.part1}>
                        <ReportMap
                            propCenter={{
                                lat: props.prop?.property.propY,
                                lon: props.prop?.property.propX,
                            }}
                            zoom={10}
                            drawingObject={'4'}
                            drawingData={props.prop.property.privateSchools}></ReportMap>
                    </div>

                    <div className={classes.part2}>
                        <table className={classes.schoolTable}>
                            <tr>
                                <th style={{ width: '30px' }}>#</th>
                                <th>
                                    <TR name={'SCHOOL_NAME'}></TR>
                                </th>
                                <th>
                                    <TR name={'SCHOOL_GENDER'}></TR>
                                </th>
                                <th>
                                    {' '}
                                    <TR name={'SCHOOL_STAR'}></TR>
                                </th>
                            </tr>

                            {props.prop.property.privateSchools.map((loopSchool, idx) => {
                                return (
                                    <tr>
                                        <td style={{ width: '30px' }}>{idx + 1}</td>
                                        <td style={{ width: '240px' }}>{loopSchool.schoolName}</td>
                                        <td>
                                            <TR name={loopSchool.schoolSex}></TR>
                                        </td>
                                        <td>
                                            {' '}
                                            {loopSchool.starRating ? loopSchool.starRating : 3}
                                            <img
                                                src="assets/images/svgs/gold_star.svg"
                                                style={{ height: '15px', paddingTop: '3px', paddingLeft: '2px' }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                </CardWithHeader>
            </div>
        );
    };
});

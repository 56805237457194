import { filter } from 'lodash';
import { API, generateGUID, storage } from '../../../commons';
import { MAP_APIS_ENDPOINTS } from '../../../services/EndPoints';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import {
    getItemFromArray,
    getYearDifferenceFromNow,
    isMobileDevice,
    pointInBounds,
    sortNumArray,
    yearAgo,
} from '../../../commons/utils';
import { api_getPropertyFeatures, api_getSoldProperties } from '../../../services/property';
import { calculateMedianPrice, getPriceMapLevelByZoom, getSuitablePriceArray } from '../support/common';
import { PriceMapFilter } from '../support/DataModels';
import {
    BedOptions,
    BuildAges,
    MapGlobalVars,
    MapLevel,
    PRICE_MAP_STATUS,
    ProjectScales,
    PropertyPriceLegendList,
} from '../support/PriceMapConsts';

import { T_MarkerProeprty, PropertyMarker } from './PropertyMarker';
import { getPropLastRecord, getSuburbSoldPriceList, getTypeByFeature } from '../support/propertyFeatureHelper';
import { suburbDataList } from './SuburbPolygonController';
import { queryLocality_byGraphQL } from '../../../commons/graphqlClient/graphAPI';
import { AppProvider } from '../../../commons/appProvider';

declare var L;

export var allSoldPropertiyData: any = [];
export class PropertyMarkerController {
    map;

    propertyMarkers: any = {};
    propertyMarkerGroup = new L.layerGroup();
    searchedPropertyMarkerGroup = new L.layerGroup();
    clickedPropId;

    constructor(map) {
        this.map = map;
        EventBus.on(EVENT_NAMES.EVENT_SEARCHED_PROP, (evt) => {
            this.markSearchedProperty(evt.propId);
        });

        EventBus.on(EVENT_NAMES.EVENT_RECENTER_CURRENT, () => {
            console.log(this.propertyMarkers[this.clickedPropId]);
        });

        EventBus.on(EVENT_NAMES.EVENT_SHOW_REFER_CURRENT_SOLD_PRICE, (params: any) => {
            storage.get('token').then(async (data) => {
                if (data) {
                    this.togglePropertyMarkerPL(true);
                }
            });
        });

        EventBus.on(EVENT_NAMES.EVENT_PROP_MARKER_CLICKED, (evt) => {
            console.log(evt);
            if (!evt.prop || getPriceMapLevelByZoom(this.map.getZoom()) != PRICE_MAP_STATUS.PROPERTYLEVEL) {
                return;
            }

            if (this.clickedPropId) {
                if (this.propertyMarkers[this.clickedPropId]) {
                    this.propertyMarkers[this.clickedPropId].setupMarkerIcon(
                        this.showingPriceLabel,
                        this.map.getZoom(),
                    );
                    this.propertyMarkers[this.clickedPropId].currentClicked = false;
                }
            }

            this.clickedPropId = evt.prop.propId;

            let clickedMarker = this.propertyMarkers[this.clickedPropId];
            if (!clickedMarker) {
                clickedMarker = evt.marker;
            }

            if (clickedMarker) {
                console.log(clickedMarker);
                clickedMarker.currentClicked = true;

                clickedMarker.setIconByProeprtyType(true, false, this.map.getZoom());
            }
        });
    }
    clearPreviousDataBeofreChangeP() {
        allSoldPropertiyData = [];
        this.clearAllMarkerFromMap();
    }

    getSoldPropertiesInMapView(suburbIdsInMapView, period?) {
        const requestSuburbIds: any = [];
        if (suburbIdsInMapView && suburbIdsInMapView.length > 0) {
            suburbIdsInMapView.forEach((loopSuburbId) => {
                if (getItemFromArray(allSoldPropertiyData, 'localityId', loopSuburbId) == null) {
                    requestSuburbIds.push(loopSuburbId);
                }
            });
        }

        return new Promise((resolve, reject) => {
            const postData = {
                idList: requestSuburbIds,
                period: period || 6,
            };
            api_getSoldProperties(postData).then(
                (result: any) => {
                    if (result && result.length > 0) {
                        allSoldPropertiyData = allSoldPropertiyData.concat(result);
                    }

                    resolve(true);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }

    createAndShowPropertyMarker(priceMapFilter: PriceMapFilter) {
        console.log(priceMapFilter);
        if (getPriceMapLevelByZoom(this.map.getZoom()) != PRICE_MAP_STATUS.PROPERTYLEVEL) {
            return;
        }
        this.clearAllMarkerFromMap();

        var priceRange = [];
        var landSizeRange = [];
        console.log(priceMapFilter.suburbId);
        for (let idx = 0; idx < allSoldPropertiyData.length; idx++) {
            const loopSuburb = allSoldPropertiyData[idx];

            if (loopSuburb == null || (priceMapFilter.suburbId && loopSuburb.localityId != priceMapFilter.suburbId)) {
                console.log('not selected suburb');
                continue;
            }
            const suburbSoldProps: any = loopSuburb[priceMapFilter.propertyType == 'House' ? 'houseSold' : 'aptSold'];

            if (suburbSoldProps && suburbSoldProps.length > 0) {
                for (let propIdx = 0; propIdx < suburbSoldProps.length; propIdx++) {
                    let propMarker: PropertyMarker = null;
                    var markerProperty: any = {};
                    const loopProp = suburbSoldProps[propIdx];

                    loopProp.propType = priceMapFilter.propertyType;

                    const propShouldShow = this.propShouldShow(loopProp, priceMapFilter);
                    if (!loopProp.uuid) {
                        // use property id as key, but waiting for backend return project id , so just now for house is prop id and for unit is uuid
                        if (priceMapFilter.propertyType == 'House') {
                            loopProp.uuid = loopProp.prop_id;
                        } else {
                            loopProp.uuid = loopProp.properties[0].prop_id; // for apt to get first property as key
                        }
                    }

                    propMarker = this.propertyMarkers[loopProp.uuid];
                    if (!propShouldShow) {
                        if (propMarker) {
                            this.propertyMarkerGroup.removeLayer(propMarker.getPropertyMarker());
                            propMarker.show = false;
                        }
                        continue;
                    } else {
                        /*** put showing property into array to calculate the median price and size */
                        landSizeRange.push(loopProp.land_size);
                        if (priceMapFilter.propertyType == 'House') {
                            priceRange.push(loopProp.sold_price);
                        } else {
                            const aptPropertyPriceList = loopProp.properties.map((loopItem) => {
                                return loopItem.sold_price;
                            });

                            priceRange.push(calculateMedianPrice(aptPropertyPriceList).median);
                        }

                        if (!propMarker) {
                            markerProperty.propType = priceMapFilter.propertyType;

                            if (priceMapFilter.propertyType == 'House') {
                                markerProperty.propId = loopProp.prop_id;
                                markerProperty.price = loopProp.sold_price;
                                markerProperty.landSize = loopProp.land_size;
                                markerProperty.landValue = loopProp.land_value;
                                markerProperty.address = loopProp.full_address;
                                markerProperty.sold_contract_date = loopProp.sold_contract_date;
                            } else {
                                const aptPropertyPriceList = loopProp.properties.map((loopItem) => {
                                    return loopItem.sold_price;
                                });
                                markerProperty.propId = loopProp.properties[0].prop_id; // for project, need to one of property prop_id to get project or other details
                                markerProperty.price = calculateMedianPrice(aptPropertyPriceList).median;
                            }

                            markerProperty.lat = loopProp.lat;
                            markerProperty.lon = loopProp.lon;
                            if (priceMapFilter.propertyType == 'Apt') {
                                markerProperty.units = loopProp.properties;
                                markerProperty.address = loopProp.project_address;
                                markerProperty.builtYear = loopProp.built_date;
                                markerProperty.unit_number = loopProp.unit_number;
                                markerProperty.sold_contract_date = loopProp.sold_contract_date;
                                markerProperty.lots = loopProp.new_number_of_lots;
                            }
                            propMarker = new PropertyMarker(markerProperty);

                            // propMarker.togglePriceLabel(this.showingPriceLabel);
                            this.propertyMarkers[loopProp.uuid] = propMarker;

                            propMarker.show = true;
                        } else {
                            markerProperty = propMarker.getMarkerProperty();
                        }
                        if (!this.propertyMarkerGroup.hasLayer(propMarker)) {
                            this.propertyMarkerGroup.addLayer(propMarker.getPropertyMarker());
                        }
                    }
                }
            }
        }

        var medianLandSize: any;

        if (priceMapFilter.propertyType == 'House') {
            medianLandSize = calculateMedianPrice(landSizeRange);
        }
        //get median price
        var medianPrice: any = calculateMedianPrice(priceRange);
        getSuitablePriceArray(medianPrice.median);

        //notice price legend update price legend panel with latest array
        EventBus.dispatch(EVENT_NAMES.EVENT_UPDATE_PRICE_LEGEND, {});

        Object.keys(this.propertyMarkers).map((propId) => {
            const loopMarker = this.propertyMarkers[propId];
            if (loopMarker.show) {
                if (loopMarker.currentClicked) {
                    loopMarker.setIconByProeprtyType(true, false, this.map.getZoom());
                } else {
                    loopMarker.setupMarkerIcon(this.showingPriceLabel, isMobileDevice() ? this.map.getZoom() : null);
                }
            }
        });

        if (!this.map.hasLayer(this.propertyMarkerGroup)) {
            this.map.addLayer(this.propertyMarkerGroup);
        }

        EventBus.dispatch(EVENT_NAMES.EVENT_UPDATE_PRICE_MAP_TITLE, {
            suburbId: priceMapFilter.suburbId,
            suburbName: priceMapFilter.suburbName,
            medianPrice: medianPrice,
            medianLandSize: medianLandSize,
        });

        console.log(this.propertyMarkers);
    }

    propShouldShow(property, priceMapFilter: PriceMapFilter) {
        if (!pointInBounds({ lat: property.lat, lon: property.lon }, this.map.getBounds())) {
            return;
        }

        var typeIsRight = true;
        var bedRight = true;
        var landSizeRight = true;
        var projScaleRight = true;
        var buildAgeRight = true;
        if (property.propType != priceMapFilter.propertyType) {
            typeIsRight = false;
        }

        /***
         * Check property bed in selected bed option start
         */
        const aptAllPropertyBeds = [];
        const selectedBedOption = getItemFromArray(BedOptions[property.propType], 'key', priceMapFilter.bedOptions[0]);

        if (selectedBedOption.value == 0) {
            bedRight = true;
        } else {
            if (property.propType != 'House') {
                if (property.properties && property.properties.length > 0) {
                    property.properties.forEach((loopProperty) => {
                        if (loopProperty.prop_bed) {
                            if (aptAllPropertyBeds.toString().indexOf(loopProperty.prop_bed) == -1) {
                                aptAllPropertyBeds.push(loopProperty.prop_bed);
                            }
                        }
                    });
                }
                if (aptAllPropertyBeds.toString().indexOf(selectedBedOption.value) == -1) {
                    bedRight = false;
                }
            } else {
                if (property.prop_bed && property.prop_bed != selectedBedOption.value) {
                    bedRight = false;
                }
            }
        }
        /***
         * Check property bed in selected bed option end
         */

        /**
         * Check project scales in selected option start
         */

        if (priceMapFilter.projectScale && priceMapFilter.projectScale != 0 && priceMapFilter.propertyType != 'House') {
            const projectScalOption = getItemFromArray(ProjectScales, 'value', priceMapFilter.projectScale);
            if (
                property.new_number_of_lots < projectScalOption.min ||
                property.new_number_of_lots > projectScalOption.max
            ) {
                projScaleRight = false;
            }
        }

        /**
         * Check project scales in selected option end
         */

        /**
         * Check building Age in selected option start
         */

        if (priceMapFilter.buildingAge && priceMapFilter.buildingAge != 0 && priceMapFilter.propertyType != 'House') {
            const buidingYears = getYearDifferenceFromNow(property.built_date, 'yyyy-MM-dd');
            console.log(buidingYears);

            const buildAgeOption = getItemFromArray(BuildAges, 'value', priceMapFilter.buildingAge);
            if (buidingYears < buildAgeOption.min || buidingYears > buildAgeOption.max) {
                buildAgeRight = false;
            }
        }

        /**
         * Check  building Age in selected option end
         */

        /**
         * Check property land size if have land size defined start.
         */
        if (priceMapFilter.landSize && (priceMapFilter.landSize.min !== 0 || priceMapFilter.landSize.max !== 0)) {
            if (!priceMapFilter.landSize) {
                landSizeRight = false;
            } else {
                if (priceMapFilter.landSize.max == 0) {
                    if (property.land_size < priceMapFilter.landSize.min) {
                        landSizeRight = false;
                    }
                } else {
                    if (priceMapFilter.landSize.min == 0) {
                        if (property.land_size > priceMapFilter.landSize.max) {
                            landSizeRight = false;
                        }
                    }
                }

                if (priceMapFilter.landSize.min != 0 && priceMapFilter.landSize.max != 0) {
                    if (
                        property.land_size < priceMapFilter.landSize.min ||
                        property.land_size > priceMapFilter.landSize.max
                    ) {
                        landSizeRight = false;
                    }
                }
            }
        }

        /**
         * Check property land size if have land size defined start.
         */

        return typeIsRight && bedRight && landSizeRight && projScaleRight && buildAgeRight;
    }
    clearAllMarkerFromMap() {
        console.log('clear group');
        if (this.map.hasLayer(this.propertyMarkerGroup)) {
            this.propertyMarkerGroup.clearLayers();
            this.map.removeLayer(this.propertyMarkerGroup);
        }
    }

    showingPriceLabel = false;

    async togglePropertyMarkerPL(switchPriceRef?) {
        // if show price REF , then need to find out big sector data

        console.log(this.propertyMarkers);
        if (switchPriceRef) {
            this.showingPriceLabel = true;
        } else {
            this.showingPriceLabel = !this.showingPriceLabel;
        }

        Object.keys(this.propertyMarkers).map((propId) => {
            const loopMarker: PropertyMarker = this.propertyMarkers[propId];

            loopMarker.togglePriceLabel(this.showingPriceLabel);
        });
    }

    /**
     *
     * mark the searched property via search inputbox
     */
    async markSearchedProperty(id) {
        var markerProperty: T_MarkerProeprty = {
            propId: id,
            propType: '',
        };
        const propFeatures: any = await api_getPropertyFeatures(id);

        console.log(propFeatures);
        //set map zoom as maximum, and center it
        if (propFeatures && propFeatures.length > 0 && propFeatures[0]) {
            markerProperty.allFeatures = propFeatures[0];

            // if current map is on suburb level , then set map level as property level
            if (getPriceMapLevelByZoom(this.map.getZoom()) == PRICE_MAP_STATUS.SUBURBLEVEL) {
                this.map.setZoom(MapLevel.propertyLevel.mid);
            }
            console.log('pan to ' + markerProperty.allFeatures.prop_y + ';' + markerProperty.allFeatures.prop_x);
            this.map.setView(new L.LatLng(markerProperty.allFeatures.prop_y, markerProperty.allFeatures.prop_x));
            console.log('pan to searched point');
            const propLastRecord = getPropLastRecord(markerProperty.allFeatures);

            let searchingMarker = this.propertyMarkers[markerProperty.allFeatures.prop_id];
            if (!searchingMarker) {
                markerProperty.price = propLastRecord.sold_price;
                markerProperty.sold_contract_date = propLastRecord.sold_contract_date;

                markerProperty.address =
                    markerProperty.allFeatures.prop_address || markerProperty.allFeatures.full_address;
                markerProperty.landSize = markerProperty.allFeatures.prop_area;
                markerProperty.landValue = markerProperty.allFeatures.land_value_reset;
                markerProperty.builtYear = markerProperty.allFeatures.built_date;
                markerProperty.lat = markerProperty.allFeatures.prop_y;
                markerProperty.lon = markerProperty.allFeatures.prop_x;

                searchingMarker = new PropertyMarker(markerProperty, true);
            }

            // check property suburb data , if not exists, then request it.
            //when running on mobile , need to get the property locality details to update global vars
            console.log(suburbDataList);
            MapGlobalVars.clickedSuburbData = getItemFromArray(
                suburbDataList,
                'id',
                markerProperty.allFeatures.locality_id,
            );
            console.log(MapGlobalVars.clickedSuburbData);

            if (MapGlobalVars.clickedSuburbData == null) {
                EventBus.dispatch(EVENT_NAMES.EVENT_REQEUST_SEARCHED_PROP_SUBURB_DATA, {
                    suburbId: markerProperty.allFeatures.locality_id,
                });
            }

            // if it is the house what need to calculate price, then need to get suburb price list
            if (
                markerProperty?.allFeatures?.prop_type == 0 &&
                yearAgo(markerProperty.sold_contract_date, 'YYYY-MM-DD')
            ) {
                const searchedSuburbSoldPriceList = getSuburbSoldPriceList(markerProperty.allFeatures.locality_id);
                if (searchedSuburbSoldPriceList) {
                    markerProperty['thisSuburbPriceList'] = searchedSuburbSoldPriceList;
                } else {
                    this.getSoldPropertiesInMapView(
                        [markerProperty.allFeatures.locality_id],
                        MapGlobalVars.priceMapFilter.period,
                    );
                }
            }

            searchingMarker.currerntSearching = true;
            searchingMarker.currentClicked = true;
            searchingMarker.setIconByProeprtyType(true);
            setTimeout(() => {
                searchingMarker.propMarkerClickEvent();
            }, 1000);

            console.log(searchingMarker);

            if (!this.searchedPropertyMarkerGroup.hasLayer(searchingMarker.getPropertyMarker())) {
                this.searchedPropertyMarkerGroup.addLayer(searchingMarker.getPropertyMarker());
            }
            if (!this.map.hasLayer(this.searchedPropertyMarkerGroup)) {
                this.searchedPropertyMarkerGroup.addTo(this.map);
            }
        } else {
            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                title: 'ERROR_ON_PROPERTY',
                content: 'CAN_NOT_FOUND_PROPERTY',
            });
            return;
        }
    }
}

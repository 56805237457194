import React from 'react';
import { rst } from 'rt-state';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { TR } from '../../../commons';

const useStyles = makeStyles((theme) => {
    return {
        button: {
            height: '46px',
            borderRadius: '10px',

            fontSize: '16px',
            fontFamily: theme.typography.fontFamily,
        },
        searchButton: {
            backgroundColor: '#1875f0',
            width: '110px',
            color: 'white',
        },
        modifyButton: {
            borderColor: theme.palette.primary.main,
            width: '170px',
            backgroundColor: 'white',
            color: theme.palette.primary.main,
        },

        searchDialogTitle: {
            fontSize: '24px',
        },
        searchDialogButton: {
            fontSize: '16px',
        },
        dummy: {},
    };
});
export const HelpComponentAuto = rst.create((ctx: any) => {
    return (props: {
        maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
        isHelpOpen: boolean;
        helpContent?: string;
        helpOpenHandler: (isOpen: boolean) => void;
    }) => {
        const classes = useStyles(props);
        console.log(props.isHelpOpen);
        return (
            <div>
                <Dialog
                    onClose={() => props.helpOpenHandler(false)}
                    aria-labelledby="customized-dialog-title"
                    open={props.isHelpOpen}
                    maxWidth={props.maxWidth}
                    fullWidth={true}>
                    <DialogTitle>
                        <TR name={'Tips'} />
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <TR name={props.helpContent} />
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => props.helpOpenHandler(false)} color="primary">
                            <TR name={'Get it'} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };
});

const win: any = window;
const L = win.L;

const markerIcons = {
    cementry: 'svg/grave.svg',
    fuelStation: 'fuel-station.svg',
    mosque: 'mosque.svg',
    telecomTower: 'tower.svg',
    church: 'church.svg',
};

export class MapMarker {
    markerInst: any;

    constructor(type, position) {
        let markerHTML = '<div>';
        markerHTML += "<img src='assets/imgs/svg/" + markerIcons[type] + '/>';

        markerHTML += '</div>';

        const markerIcon = new L.DivIcon({
            html: markerHTML,
        });

        this.markerInst = L.marker([position.lat, position.lon], {
            icon: markerIcon,
        });
    }

    addToMap(mapInst) {
        this.markerInst.addToMap(mapInst);
    }
    removeFromMap(mapInst) {
        mapInst.removeLayer(this.markerInst);
    }
}

import { makeStyles } from '@material-ui/core';

import React from 'react';
import { rst } from 'rt-state';
import { clsx, TR, TRHtml } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { isMobileDevice } from '../../../commons/utils';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            background: '#F6F7F9',
            border: '1px solid #F6F7F9',
            borderRadius: 4,
            paddingLeft: isMobileDevice() ? 4 : 17,
            paddingRight: isMobileDevice() ? 4 : 17,
            paddingTop: 6,
            paddingBottom: 6,
            fontSize: 14,
            cursor: 'pointer',
            position: 'relative',
            minWidth: isMobileDevice() ? 70 : 80,
            display: 'flex',
            justifyContent: 'center',
        },
        tagSelected: {
            background: 'rgba(142,189,251,0.20)',
            border: '1px solid #217af0',
        },
        tagLabel: {
            fontSize: isMobileDevice() ? '12px' : '12px',
            fontWeight: 400,
            color: '#999999',
        },
        tagSelectedLabel: {
            color: '#1875F0',
        },
    };
});
interface TagProp {
    label: string;
    feature: string;
    clickEvt: any;

    value?: string;
    passEvtToParent?: boolean;
}

export const TagButton = rst.create<{ selected: boolean; tagProps: TagProp; tagClickEvent?: any }>((ctx) => {
    const state = rst.state({
        tagSelected: ctx.props.selected,
    });

    EventBus.on(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE, () => {
        state.tagSelected = ctx.props.selected;
    });

    return (props) => {
        console.log('current status is ' + state.tagSelected + props.selected);
        const toggleTagStatus = () => {
            state.tagSelected = !state.tagSelected;
            console.log('parent state is ' + state.tagSelected);
            if (props.tagProps.clickEvt) {
                props.tagProps.clickEvt(props.tagProps.feature, state.tagSelected ? 1 : 0);
                EventBus.dispatch(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE_ISCLICKED, {});
            }
            if (props.tagClickEvent) {
                props.tagClickEvent(props.tagProps.feature, state.tagSelected ? 1 : 0);
                EventBus.dispatch(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE_ISCLICKED, {});
            }
        };
        const singleSelectTagEvent = () => {
            if (props.tagProps.clickEvt) {
                props.tagProps.clickEvt(props.tagProps.feature, props.tagProps.value);
            }
        };

        const classes = useStyles();

        return (
            <div
                className={clsx(
                    (props.tagProps.passEvtToParent ? props.selected : state.tagSelected) ? classes.tagSelected : '',
                    classes.root,
                )}
                onClick={props.tagProps.passEvtToParent ? singleSelectTagEvent : toggleTagStatus}>
                <div
                    className={clsx(
                        (props.tagProps.passEvtToParent ? props.selected : state.tagSelected)
                            ? classes.tagSelectedLabel
                            : '',
                        classes.tagLabel,
                    )}>
                    <TRHtml html={props.tagProps.label}></TRHtml>
                </div>
                <img
                    src={
                        'assets/icons/' +
                        ((props.tagProps.passEvtToParent ? props.selected : state.tagSelected) ? 'ls.svg' : 'hs.svg')
                    }
                    style={{ position: 'absolute', bottom: 0, right: 0 }}></img>
            </div>
        );
    };
});

import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { convertToM } from '../../../commons/utils';
import { MapGlobalVars } from '../../PriceMap/support/PriceMapConsts';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { ContentTitleData } from '../components/ContentTitleData';
import { Tips } from '../components/Tips';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            textAlign: 'left',
            padding: '5px 0',
        },

        part2: {
            textAlign: 'left',
            padding: '5px 0',
        },
        part3: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '5px 0',
            flexFlow: 'row wrap',
        },
        content: {
            padding: '5px 0px 5px 15px',
            margin: '0px',
        },
    };
});

export const Card30 = rst.create<{ estimateInfo: any }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader>
                    <Tips header="Card_Tips" content="CARD30_TIPS" isSelect={true} />
                    <div className={classes.part1}>
                        <ContentTitleData
                            header="Card30_AI_Range"
                            content={
                                props.estimateInfo.estimatePrice.inMonth ? (
                                    <TR name="SOLD_IN_MONTH_DESC"></TR>
                                ) : (
                                    convertToM(props.estimateInfo.estimatePrice.lower) +
                                    'm ~' +
                                    convertToM(props.estimateInfo.estimatePrice.upper) +
                                    'm'
                                )
                            }
                        />
                    </div>
                    <CardDivider />
                    <div className={classes.part2}>
                        <ContentTitleData
                            header="Card30_AI_Median"
                            content={
                                <>
                                    {/* <TR name="SOLD_PRICE"></TR> */}
                                    <div>{convertToM(props.estimateInfo.estimatePrice.middle)}m</div>
                                </>
                            }
                        />
                    </div>
                    <CardDivider />
                    <div
                        style={{
                            display:
                                props.estimateInfo.smartTags && props.estimateInfo.smartTags.length > 0 ? '' : 'none',
                        }}
                        className={classes.part3}>
                        <ContentTitle hasArrow={true} header="Card30_feature" />
                        {props.estimateInfo.smartTags
                            .filter((loopTag) => {
                                return loopTag.tag_name != 'Indoor space';
                            })
                            .map((loopTag) => {
                                return (
                                    <div className={classes.content}>
                                        <ContentData content={<TR name={loopTag.tag_name}>/</TR>} />
                                    </div>
                                );
                            })}
                    </div>
                </CardWithHeader>
            </div>
        );
    };
});

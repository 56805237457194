import { Theme } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';

const useStyles = makeStyles({
    root: {
        // border: 'solid 1px rgb(232 234 245)',
        borderRadius: '5px',
        padding: '5px',
        minWidth: '46px',
        display: 'inline-block',
        '& div': {
            textAlign: 'center',
        },
        '& span': {
            fontSize: '13px',
        },
    },
});

export const ContentData = rst.create<{ content: any }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const { content } = props;
        return (
            <div className={classes.root}>
                <div>
                    <span>{content}</span>
                </div>
            </div>
        );
    };
});

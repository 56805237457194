import { makeStyles } from '@material-ui/core';
import { BorderBottom, BorderTop } from '@material-ui/icons';
import React from 'react';
import { rst } from 'rt-state';

interface ArrowProps {
    cssStyle: any;
}
const Arrow = rst.create<ArrowProps>((ctx) => {
    const { cssStyle } = ctx.props;

    return (props) => {
        return (
            <div style={cssStyle.outterTriStyle}>
                <div style={cssStyle.innerTriStyle}></div>
            </div>
        );
    };
});

export const LeftArrow = rst.create<ArrowProps>((ctx) => {
    const { cssStyle } = ctx.props;
    const arrowStyle = {
        outterTriStyle: {
            borderTop: '10px dotted transparent',
            borderBottom: '10px dotted transparent',
            borderRight: '12px solid  ' + cssStyle?.borderColor || cssStyle?.background || '#999999',
            width: 0,
            height: 0,
        },
        innerTriStyle: {
            borderTop: '10px dotted transparent',
            borderBottom: '10px dotted transparent',
            borderRight: '12px solid ' + (cssStyle?.outline ? 'white' : cssStyle?.background || '#999999'),
            width: 0,
            height: 0,
            marginLeft: '1px',
            marginTop: '-10px',
        },
    };

    return (props) => {
        return <Arrow cssStyle={arrowStyle}></Arrow>;
    };
});

export const RightArrow = rst.create<ArrowProps>((ctx) => {
    const { cssStyle } = ctx.props;
    const arrowStyle = {
        outterTriStyle: {
            borderTop: '10px dotted transparent',
            borderBottom: '10px dotted transparent',
            borderLeft: '12px solid  ' + cssStyle?.borderColor || cssStyle?.background || '#999999',
            width: 0,
            height: 0,
        },
        innerTriStyle: {
            borderTop: '10px dotted transparent',
            borderBottom: '10px dotted transparent',
            borderLeft: '12px solid ' + (cssStyle?.outline ? 'white' : cssStyle?.background || '#999999'),
            width: 0,
            height: 0,
            marginLeft: '-13px',
            marginTop: '-10px',
        },
    };

    return (props) => {
        return <Arrow cssStyle={arrowStyle}></Arrow>;
    };
});

export const UpArrow = rst.create<ArrowProps>((ctx) => {
    const { cssStyle } = ctx.props;
    const arrowStyle = {
        outterTriStyle: {
            borderLeft: '10px dotted transparent',
            borderRight: '10px dotted transparent',
            borderBottom: '12px solid  ' + cssStyle?.borderColor || cssStyle?.background || '#999999',
            width: 0,
            height: 0,
        },
        innerTriStyle: {
            borderLeft: '10px dotted transparent',
            borderRight: '10px dotted transparent',
            borderBottom: '12px solid ' + (cssStyle?.outline ? 'white' : cssStyle?.background || '#999999'),
            width: 0,
            height: 0,
            marginLeft: '-10px',
            marginTop: '1px',
        },
    };

    return (props) => {
        return <Arrow cssStyle={arrowStyle}></Arrow>;
    };
});

export const DownArrow = rst.create<ArrowProps>((ctx) => {
    const { cssStyle } = ctx.props;
    const arrowStyle = {
        outterTriStyle: {
            borderLeft: '10px dotted transparent',
            borderRight: '10px dotted transparent',
            borderTop: '12px solid  ' + cssStyle?.borderColor || cssStyle?.background || '#999999',
            width: 0,
            height: 0,
        },
        innerTriStyle: {
            borderLeft: '10px dotted transparent',
            borderRight: '10px dotted transparent',
            borderTop: '12px solid ' + (cssStyle?.outline ? 'white' : cssStyle?.background || '#999999'),
            width: 0,
            height: 0,
            marginLeft: '-10px',
            marginTop: '-13px',
        },
    };

    return (props) => {
        return <Arrow cssStyle={arrowStyle}></Arrow>;
    };
});

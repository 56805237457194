import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { NavLink } from 'react-router-dom';
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useSidebarItemStyles } from './SideBarItem.styles';
import { rst } from 'rt-state';
import { capitalize } from '../../../commons';

export const SidebarItem = rst.create<{
    route: any;
    index: number;
    activeRoute: number;
    toggleMenu: any;
    isCurrentPath: boolean;
}>((ctx) => {
    const runOnce = () => {
        const { isCurrentPath, activeRoute, route, index, toggleMenu } = ctx.props;
        if (!isCurrentPath || activeRoute === index || route.path === '/') return;
        toggleMenu(index);
    };

    return (props) => {
        useEffect(runOnce, []);
        const { activeRoute, route, index, toggleMenu } = ctx.props;
        const classes = useSidebarItemStyles();
        const badge = (badge) => {
            if (!badge) return;
            const badgeClassName = classNames(classes.badge, {
                [classes[`${badge.type}`]]: badge.type !== 'default',
            });
            return (
                <Typography className={classNames(classes.badge, badgeClassName)} component="div">
                    {badge.value}
                </Typography>
            );
        };
        if (route.type === 'external') {
            return (
                <a href={route.path} target="_blank" rel="noopener noreferrer" key={index} className={classes.menuLink}>
                    <ListItem className={classes.menuItem} button>
                        <ListItemIcon>
                            <route.icon className={classes.menuIcon} />
                        </ListItemIcon>
                        <Typography variant="body1" className="flexSpacer">
                            {capitalize(route.name)}
                        </Typography>
                        {badge(route.badge)}
                    </ListItem>
                </a>
            );
        }

        if (route.type === 'submenu') {
            return (
                <div className={activeRoute === index ? classes.menuCollapsed : classes.menuClosed}>
                    <ListItem className={classes.menuItem} button key={index} onClick={() => toggleMenu(index)}>
                        <ListItemIcon>
                            <route.icon className={classes.menuIcon} />
                        </ListItemIcon>
                        <Typography variant="body1" className="flexSpacer">
                            {capitalize(route.name)}
                        </Typography>
                        {badge(route.badge)}
                        <ListItemIcon className={classes.caret}>
                            {activeRoute === index ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </ListItemIcon>
                    </ListItem>
                    <Collapse in={activeRoute === index} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            {route.children.map((subitem, index) => (
                                <NavLink
                                    to={`${route.path ? route.path : ''}${subitem.path ? subitem.path : ''}`}
                                    exact
                                    className={classes.menuLink}
                                    activeClassName={classes.menuActive}
                                    key={index}>
                                    <ListItem className={classes.menuSubItem} button>
                                        <Typography variant="body1" className="flexSpacer">
                                            {capitalize(subitem.name)}
                                        </Typography>
                                        {badge(subitem.badge)}
                                    </ListItem>
                                </NavLink>
                            ))}
                        </List>
                    </Collapse>
                </div>
            );
        }

        return (
            <NavLink
                to={route.path}
                exact
                className={classes.menuLink}
                activeClassName={classes.menuActive}
                key={index}>
                <ListItem className={classes.menuItem} button onClick={() => toggleMenu(index)}>
                    <ListItemIcon>
                        <route.icon className={classes.menuIcon} />
                    </ListItemIcon>
                    <Typography variant="body1" className="flexSpacer">
                        {capitalize(route.name)}
                    </Typography>
                    {badge(route.badge)}
                </ListItem>
            </NavLink>
        );
    };
});

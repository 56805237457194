import React from 'react';
import { rst, useOnce } from 'rt-state';
import { makeStyles } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Card11 } from '../../../PropertyReport/ReportCards/Card11';
import { Card12 } from '../../../PropertyReport/ReportCards/Card12';
// import { mockData } from './mock';
import mockData from './mock.json';
import { getParamValueFromURL, isMobileDevice } from '../../../../commons/utils';
import { queryProp_byGraphQL } from '../../../../commons/graphqlClient/graphAPI';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TR } from '../../../../commons';

const useStyles = makeStyles({
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: '8px',
    },
    tips: {
        fontSize: 13,
        lineHeight: '18px',
        color: '#888888',
        marginTop: '5px',
    },
    value: {
        fontSize: '13px',
        color: '#000000',
    },
});

export const HouseStatistics = rst.create<{ pageData?: any }>((ctx) => {
    return (props: any) => {
        const pageState = rst.state({
            data: { ...ctx.props.pageData.prop_ql.street },
            propQL_result: ctx.props.pageData.oneProp_ql,
        });

        const classes = useStyles();
        return (
            <div>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>
                            <div style={{ fontSize: '16px', fontWeight: 600, color: '#333' }}>
                                <TR name="houseStatisticsTwoYears"></TR>
                            </div>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {pageState.data ? (
                                <>
                                    <div style={{ padding: '10px', color: '#888' }}>
                                        <div className={classes.tips}>
                                            <TR name="medianPrice"></TR>：
                                            <span className={classes.value}>
                                                $
                                                {pageState.data.housePriceRange &&
                                                    pageState.data.housePriceRange.medianPrice.median / 1000000}
                                                m
                                            </span>
                                        </div>
                                        <div className={classes.tips}>
                                            <TR name="priceRangeInStatistics"></TR>：
                                            <span className={classes.value}>
                                                $
                                                {pageState.data.housePriceRange &&
                                                    pageState.data.housePriceRange.soldPriceRange.minValue / 1000000}
                                                ~
                                                {pageState.data.housePriceRange &&
                                                    pageState.data.housePriceRange.soldPriceRange.maxValue / 1000000}
                                                m
                                            </span>
                                        </div>
                                        <div className={classes.tips}>
                                            <TR name="soldTotal"></TR>：
                                            <span className={classes.value}>
                                                {pageState.data.housePriceRange &&
                                                    pageState.data.housePriceRange.medianPrice.numberOfSold}
                                            </span>
                                        </div>
                                        <div className={classes.tips}>
                                            <TR name="LANDSIZE"></TR>：
                                            <span className={classes.value}>
                                                {' '}
                                                {pageState.data.housePriceRange &&
                                                    pageState.data.housePriceRange.landSizeRange?.minValue}
                                                ~
                                                {pageState.data.housePriceRange &&
                                                    pageState.data.housePriceRange.landSizeRange?.maxValue}
                                                m²
                                            </span>
                                        </div>
                                        <div className={classes.tips}>
                                            <TR name="landValueInStatics"></TR>：
                                            <span className={classes.value}>
                                                {' '}
                                                $
                                                {pageState.data.housePriceRange &&
                                                    pageState.data.housePriceRange.landValueRange?.minValue / 1000000}
                                                ~
                                                {pageState.data.housePriceRange &&
                                                    pageState.data.housePriceRange.landValueRange?.maxValue / 1000000}
                                                m
                                            </span>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {pageState.propQL_result ? (
                                <div>
                                    <Card11 property={pageState.propQL_result} mobile={true} />
                                    <Card12 property={pageState.propQL_result} mobile={true} />
                                </div>
                            ) : null}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };
});

import { AppBar, makeStyles, Paper, Tab, Tabs, Theme } from '@material-ui/core';
import { TabPanel } from '@material-ui/lab';
import { createStyles, withStyles } from '@material-ui/styles';

import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { rst } from 'rt-state';
import { SecurityProvider, TR } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { getItemFromArray, isMobileDevice, yearAgo } from '../../../../commons/utils';
import { getPriceMapLevelByZoom } from '../../support/common';
import {
    MapGlobalVars,
    MapInitParam,
    MapLevel,
    PRICE_MAP_STATUS,
    SuburbPriceColorsLabel,
} from '../../support/PriceMapConsts';
import { PriceMapLegend } from '../PriceMapLegend';
import { PropertyInfo } from '../PropertyInfo';
import { PropertySideBar } from '../PropertySidebar';
import { SuburbSummaryPanel } from '../SuburbSummary/SuburbSummaryPanel';
import { PropertyFilterPanel } from './PropertyFilterPanel';
import { SpecialCare } from './SpecialCare';
import Gesture from 'rc-gesture';
import { suburbDataList } from '../../MapContainer/SuburbPolygonController';
import { Permission } from '../../../../commons/security/authz';

const FilterTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: '13px',
            padding: 0,
            fontWeight: 400,
            color: theme.palette.primary.light,

            '&:focus': {
                fontWeight: 700,
            },
        },
    }),
)((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme) => {
    return {
        tabsRoot: {
            height: '40px',
            minHeight: '40px',
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        tabContentDiv: {
            paddingTop: '10px',
            paddingLeft: isMobileDevice() ? '15px' : '25px',
            paddingRight: 0,
            background: 'white',
            width: '100%',
            paddingBottom: '20px',
        },
        mobileFilterTab: {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
        },
        // blockDiv: {
        //     position: 'absolute',
        //     top: 0,
        //     left: 0,
        //     width: '100%',
        //     height: '100%',

        //     zIndex: 100,
        //     opacity: 0.5,
        // },
    };
});
export const MobileFilterTabs = rst.create((ctx) => {
    const state = rst.state({
        mapZoom: MapInitParam.zoom,
        propertyType: MapInitParam.propertyType,
        tabValue: 2,
        mapStaus: PRICE_MAP_STATUS.SUBURBLEVEL,

        medianData: {},
        suburbData: {},
        showingLegendHandle: false,
        showBottomTabs: true,
        clickedProperty: null,
        showingTabContent: true,
    });
    const PropType = rst.stateS('House');

    EventBus.on(EVENT_NAMES.EVENT_PROP_MARKER_CLICKED, (evt) => {
        if (evt.prop?.allFeatures) {
            state.clickedProperty = evt.prop;
            if (evt.prop == null) {
                changeTab(evt, 0);
            } else {
                changeTab(evt, 2);
            }

            state.suburbData = MapGlobalVars.clickedSuburbData; // get suburb data from global when click property marker
        }
    });
    EventBus.on(EVENT_NAMES.EVENT_CHANGE_MOBILE_TAB, (evt) => {
        if (state.tabValue != 0) {
            return;
        }
        const tabIdx = evt.tabIdx;
        if ((state.tabValue! = tabIdx)) {
            changeTab(null, tabIdx);
        } else {
            if (tabIdx == 1) {
                state.suburbData = MapGlobalVars.clickedSuburbData;
            }
        }
    });

    EventBus.on(EVENT_NAMES.EVENT_MAP_ZOOMED, (zoomLevel) => {
        state.mapZoom = zoomLevel;
        state.mapStaus = getPriceMapLevelByZoom(zoomLevel);
    });

    EventBus.on(EVENT_NAMES.EVENT_SUBURB_POLYGON_CLICKED, (evt) => {
        state.suburbData = evt.suburbData;
        state.medianData = evt.medianData;

        changeTab(null, 1);
    });

    EventBus.on(EVENT_NAMES.EVENT_CHANGE_SUBURB_COLOR_BY, (colorBy) => {
        state.propertyType = SuburbPriceColorsLabel[colorBy].key;

        console.log(colorBy);
    });

    EventBus.on(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, (evt) => {
        refreshSuburbData(evt);
    });

    function refreshSuburbData(evt) {
        MapGlobalVars.clickedSuburbData = getItemFromArray(suburbDataList, 'id', evt.suburbId);
        if (MapGlobalVars.clickedSuburbData == null) {
            setTimeout(() => {
                refreshSuburbData(evt);
            }, 100);
        } else {
            state.suburbData = MapGlobalVars.clickedSuburbData;
        }
    }

    function changeTab(evt, idx) {
        if (idx == 0 && (MapGlobalVars.showAroundTransCircle || state.mapStaus == PRICE_MAP_STATUS.ALLPROPERTYLEVEL)) {
            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                show: true,
                title: 'ERROR',
                content: 'FILTER_DISABLED',
            });
            return;
        }
        console.log(MapGlobalVars.clickedSuburbData);
        if (
            MapGlobalVars.clickedSuburbData == null &&
            !MapGlobalVars.showingSuburbPop &&
            MapGlobalVars.priceMapFilter &&
            MapGlobalVars.priceMapFilter.suburbId == '' &&
            idx == 1
        ) {
            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                show: true,
                title: 'ERROR',
                content: 'CHOOSE_SUBURB_FROM_MAP',
            });
            return;
        } else {
            state.suburbData = MapGlobalVars.clickedSuburbData;
        }
        if (idx == 2 && state.mapStaus != PRICE_MAP_STATUS.SUBURBLEVEL && state.clickedProperty == null) {
            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                show: true,
                title: 'ERROR',
                content: 'CHOOSE_PROPERTY_FROM_MAP',
            });
            return;
        }
        state.tabValue = idx;
        console.log(state.tabValue);
    }

    function foldLegend() {
        state.showingLegendHandle = !state.showingLegendHandle;
    }

    function showDisableError() {
        EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
            show: true,
            title: 'ERROR',
            content: 'CAN_NOT_FILTER_WHEN_SHOWING_FAV',
        });
    }
    const handlePropType = (type) => {
        PropType.value = type;
    };

    return (props) => {
        const classes = useStyles();
        const securityProvider = SecurityProvider.use();
        const getTabContent = () => {
            return (
                <>
                    <div style={{ display: state.tabValue == 0 ? 'block' : 'none', position: 'relative' }}>
                        <PropertyFilterPanel handlePropType={handlePropType}></PropertyFilterPanel>
                    </div>
                    <div
                        style={{
                            display: state.tabValue == 1 ? 'block' : 'none',
                        }}>
                        <SuburbSummaryPanel
                            suburbData={state.suburbData}
                            medianData={state.medianData}
                            showFor="infoPanel"></SuburbSummaryPanel>
                    </div>

                    <div
                        style={{
                            display:
                                state.tabValue == 2 &&
                                (state.mapStaus != PRICE_MAP_STATUS.SUBURBLEVEL || MapGlobalVars.showFavOnMap)
                                    ? 'block'
                                    : 'none',
                        }}>
                        <PropertySideBar prop={state.clickedProperty || { allFeatures: {} }}></PropertySideBar>
                    </div>
                    <div
                        style={{
                            display:
                                state.tabValue == 2 &&
                                state.mapStaus == PRICE_MAP_STATUS.SUBURBLEVEL &&
                                !MapGlobalVars.showFavOnMap
                                    ? 'block'
                                    : 'none',
                        }}>
                        <SpecialCare PropType={PropType.value}></SpecialCare>
                    </div>
                </>
            );
        };
        const swipeHandler = useSwipeable({
            onSwipedDown: (eventData) => {
                toggleTab(false);
            },
            onSwipedUp: (eventData) => {
                toggleTab(true);
            },
        });

        const toggleTab = (show) => {
            state.showingTabContent = show;
        };

        const getThirdTab = () => {
            if (
                state.mapStaus == PRICE_MAP_STATUS.ALLPROPERTYLEVEL &&
                yearAgo(state.clickedProperty?.sold_contract_date, 'YYYY-MM-DD') &&
                state.clickedProperty?.propType == 'House'
            ) {
                return <FilterTab onClick={() => toggleTab(true)} label={<TR name="TAB_AI_CALCULATOR"></TR>} />;
            } else {
                if (state.mapStaus != PRICE_MAP_STATUS.SUBURBLEVEL || MapGlobalVars.showFavOnMap) {
                    return <FilterTab onClick={() => toggleTab(true)} label={<TR name="Property Info"></TR>} />;
                } else return <FilterTab onClick={() => toggleTab(true)} label={<TR name="Special Care"></TR>} />;
            }
        };

        return (
            <Gesture
                onPanMove={(gestureStatus) => {
                    console.log(gestureStatus);
                }}>
                <div className={classes.root} style={{ display: state.showBottomTabs ? 'flex' : 'none' }}>
                    {!state.showingLegendHandle && !MapGlobalVars.showFavOnMap ? (
                        <PriceMapLegend
                            mapZoom={state.mapZoom}
                            legendFoldEvent={foldLegend}
                            propertyType={state.propertyType}></PriceMapLegend>
                    ) : null}

                    <div style={{ marginTop: 5 }}></div>
                    {/* <AppBar elevation={0} position="static" color="default" className={classes.mobileFilterTab}>
                        {state.showingLegendHandle ? (
                            <div
                                onClick={foldLegend}
                                style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 6 }}>
                                <img src="assets/icons/hornLine.svg"></img>
                            </div>
                        ) : null}

                        <Tabs
                            classes={{
                                root: classes.tabsRoot,
                            }}
                            indicatorColor="primary"
                            value={state.tabValue}
                            onChange={changeTab}
                            textColor="primary"
                            variant="fullWidth"
                            centered>
                            <FilterTab onClick={() => toggleTab(true)} label={<TR name="FilterMap"></TR>} />

                            <FilterTab onClick={() => toggleTab(true)} label={<TR name="Suburb Info"></TR>} />

                            {getThirdTab()}
                        </Tabs>
                    </AppBar> */}
                    {state.showingTabContent ? (
                        <div
                            id="mobileTabDiv"
                            className={classes.tabContentDiv}
                            style={{
                                maxHeight: isMobileDevice()
                                    ? state.tabValue == 2 &&
                                      (state.mapStaus == PRICE_MAP_STATUS.ALLPROPERTYLEVEL ||
                                          MapGlobalVars.showAroundTransCircle ||
                                          MapGlobalVars.showFavOnMap)
                                        ? 220
                                        : 200
                                    : 999,
                                overflowY: 'auto',
                                overflowX: 'hidden',
                            }}>
                            {getTabContent()}
                        </div>
                    ) : null}
                </div>
            </Gesture>
        );
    };
});

import { IconButton, Theme } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import * as locales from '@material-ui/core/locale';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles({
    root: {
        '& div': {
            textAlign: 'center',
        },
        '& span': {
            fontSize: '13px',
            color: 'rgb(127 127 127)',
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
});

export const GlossaryHelp = rst.create<{ glossaryId?: any }>((ctx) => {
    const appProvider = AppProvider.use();

    return (props) => {
        const classes = useStyles();
        const { glossaryId } = props;
        const openGlossary = () => {
            window.open(
                appProvider.getLocale() === locales.zhCN
                    ? `${process.env.PUBLIC_URL}/assets/help/HELP-ZH.html#zh-${glossaryId}`
                    : `${process.env.PUBLIC_URL}/assets/help/HELP-EN.html#en-${glossaryId}`,
            );
        };
        return (
            <div className={classes.root}>
                {/* <div onClick={openGlossary}>
                    <span>
                        <TR name="glossary"></TR>
                    </span>
                </div> */}
                <IconButton onClick={openGlossary}>
                    <HelpOutlineIcon fontSize="small" style={{ color: 'rgb(166 166 166)' }} />
                </IconButton>
            </div>
        );
    };
});

export const sentenceCase = (str?: string) => {
    return str && str.replace(/^\w/, (c) => c.toUpperCase());
};

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function nameToLabel(field: string) {
    if (!field) {
        return '';
    }
    const t = sentenceCase(field);
    const arr = t.match(/[A-Z][a-z]*|[0-9]+/g) ?? [];
    return arr.join(' ');
}

export function generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function numberToString(num: number): string {
    if (num == null) {
        return null;
    }
    return `${num}`;
}

import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core';
const useStyles = makeStyles({
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
    },
    address: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#333333',
        marginTop: '18px',
        display: 'flex',
        alignItems: 'center',
    },
    link: { fontSize: 14, color: '#333333', marginLeft: 8 },
    searchImg: {
        width: '110px',
        height: '50px',
    },
    secondLine: {
        display: 'flex',
        padding: 10,
    },
    searchTitle: {
        fontSize: '13px',
        fontWeight: 400,
        color: '#009e90',
        marginLeft: '12px',
    },
    streetViewDiv: {
        position: 'absolute',
        left: 9,
        background: '#009e90',
        borderRadius: '15px',
        zIndex: 999,
        bottom: '20px',
        padding: '7px 16px',
        color: 'white',
        fontSize: '13',
        display: 'flex',
    },
});
import { TR } from '../../../../commons';
import { SearchResultMap } from './searchResultMap';
import { changeToFirstCaptial, openGoogleNav } from '../../../../commons/utils';
import { swtchToStreetView } from '../../../PriceMap/support/propertyFeatureHelper';
export const GoogleSearchDialog = (props: any) => {
    const { open, address, searchResult, closeDialogEvt, propCenter } = props;
    console.log(searchResult);
    const classes = useStyles();
    const openGoogleNavigate = () => {
        openGoogleNav(address, propCenter.lat, propCenter.lon);
    };
    const openStreetView = () => {
        swtchToStreetView(propCenter.lat, propCenter.lon);
    };
    return (
        <Dialog open={open}>
            <DialogTitle>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <TR name="GoogleSearchResult"></TR>
                </div>
                <div className={classes.closeButton} onClick={closeDialogEvt}>
                    <img src="assets/mobileIcons/close.svg"></img>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ position: 'relative' }}>
                    <SearchResultMap propCenter={propCenter}></SearchResultMap>
                    <div className={classes.streetViewDiv} onClick={openStreetView}>
                        <img src="assets/mobileIcons/streetView.svg"></img>
                        <div style={{ marginLeft: 4 }}>
                            <TR name="streetView"></TR>
                        </div>
                    </div>
                </div>
                <div className={classes.address}>
                    <div>{changeToFirstCaptial(address)}</div>
                    <img
                        style={{ marginLeft: 10 }}
                        onClick={openGoogleNavigate}
                        src="assets/mobileIcons/navigate.svg"></img>
                </div>
                {searchResult && searchResult.realestateInfo.length > 0 && (
                    <div>
                        <SearchItem searchItem={searchResult.realestateInfo[0]} type="real"></SearchItem>
                    </div>
                )}

                {searchResult && searchResult.domainInfo.length > 0 && (
                    <div>
                        <SearchItem searchItem={searchResult.domainInfo[0]} type="domain"></SearchItem>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};
const SearchItem = (props: any) => {
    const classes = useStyles();
    const { type } = props;
    const { link, imagelink, title } = props.searchItem;
    const openGoogleSearch = (link) => {
        window.open(link, '_blank');
    };
    return (
        <div
            style={{ marginTop: '12px' }}
            onClick={() => {
                openGoogleSearch(link);
            }}>
            <div style={{ display: 'flex' }}>
                <img
                    width="18"
                    height="18"
                    src={type == 'real' ? 'assets/mobileIcons/realestate.svg' : 'assets/mobileIcons/domain.svg'}></img>
                <div className={classes.link}>{link.substr(0, 45) + '...'}</div>
            </div>

            <div className={classes.secondLine}>
                <div className={classes.searchImg}>
                    <img width="110" height="56" src={imagelink} />
                </div>
                <div className={classes.searchTitle}>{title}</div>
            </div>
        </div>
    );
};

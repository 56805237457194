import { UserCenterEntityType } from './UserCenterEntityType';
import { Type } from 'class-transformer';

export class SearchHistoryDto {
    entityType: UserCenterEntityType;
    id: string;
    @Type(() => Date)
    createTime: Date;
    entity: SearchHistoryDtoEntity;
}

interface SearchHistoryDtoEntity {
    id: string;
    stdAddress?: string;
    propType?: string;
    suburbName?: string;
    suburbPost?: string;
}

import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { XBreadcrumbs } from '../../../components/Breadcrumbs';
import { XText } from '../../../components/Text';

const useStyles = makeStyles((theme) => {
    return {
        resultTitle: {
            fontSize: '25px',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
        },
        breadCrumbs: {
            fontSize: '16px',
            color: theme.palette.primary.main,
        },
    };
});

export const SurburbSearchResultPanel = rst.create<{}>((ctx) => {
    return (props) => {
        const classes = useStyles(props);
        return (
            <>
                {' '}
                <XText className={classes.resultTitle}>
                    <TR name="suburb search result" />
                </XText>
                <XBreadcrumbs className={classes.breadCrumbs} breadcrumbs={['首页', '区域搜索', '筛选结果']} />
            </>
        );
    };
});

import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { api_getPropertiesOnMapView } from '../../../services/property';
import {
    getPriceMapLevelByZoom,
    hasMarkerInSamePosition,
    isProperType,
    mapHasLayerOnThisPoint,
} from '../support/common';
import { MapGlobalVars, MapLevel, PRICE_MAP_STATUS } from '../support/PriceMapConsts';
import { allFavMarkers } from './FavMarkerController';
import { PropertyMarker } from './PropertyMarker';

const win: any = window;
const L = win.L;

export class AllPropertiesLayerController {
    map: any;
    allPropertyMarkers = {};
    allPropertiesMarkerGroup: any;
    clickedPropId;

    constructor(map) {
        this.map = map;
        this.allPropertiesMarkerGroup = new L.layerGroup();

        EventBus.on(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, (evt: any) => {
            if (evt.showAroundTrans) {
                this.switchAllMarkersOnMap(false);
            } else {
                this.switchAllMarkersOnMap(true);
            }
        });

        EventBus.on(EVENT_NAMES.EVENT_SHOW_TRANS_FROM_SIMPLE_CHART, (evt) => {
            const clickedProp = evt.prop;
            console.log(clickedProp);
            console.log(clickedProp.prop_id);
            this.map.panTo([clickedProp.lat, clickedProp.lon]);
            EventBus.dispatch(EVENT_NAMES.EVENT_PROP_MARKER_CLICKED, { prop: clickedProp, showInfo: true });
            console.log(this.allPropertyMarkers);
        });

        EventBus.on(EVENT_NAMES.EVENT_PROP_MARKER_CLICKED, (evt) => {
            console.log(evt);
            if (!evt.prop || getPriceMapLevelByZoom(this.map.getZoom()) != PRICE_MAP_STATUS.ALLPROPERTYLEVEL) {
                return;
            }
            console.log(evt);
            console.log(this.clickedPropId);
            if (this.clickedPropId) {
                if (this.allPropertyMarkers[this.clickedPropId]) {
                    this.allPropertyMarkers[this.clickedPropId].setIconByProeprtyType(false);
                    this.allPropertyMarkers[this.clickedPropId].currentClicked = false;
                }
            }

            this.clickedPropId = evt.prop.propId;

            try {
                if (this.clickedPropId && typeof this.clickedPropId != undefined) {
                    this.allPropertyMarkers[this.clickedPropId].currentClicked = true;
                    this.allPropertyMarkers[this.clickedPropId].setIconByProeprtyType(true);
                }
            } catch (e) {
                console.log(e);
            }

            if (evt.showInfo) {
                const clickedMarker = this.allPropertyMarkers[this.clickedPropId];
                if (clickedMarker) {
                    clickedMarker.setIconByProeprtyType(true);
                }
            }
        });

        EventBus.on(EVENT_NAMES.EVENT_HIDE_ALL_PROPERTIES, () => {
            this.switchAllMarkersOnMap(false);
        });
    }

    switchAllMarkersOnMap(showAllProperties) {
        Object.keys(this.allPropertyMarkers).forEach((propId) => {
            const propertyMarker = this.allPropertyMarkers[propId].getPropertyMarker();
            if (showAllProperties) {
                if (!this.map.hasLayer(propertyMarker)) {
                    propertyMarker.addTo(this.map);
                }
            } else {
                if (
                    (MapGlobalVars.showAroundTransCircle && propId != this.clickedPropId) ||
                    (!MapGlobalVars.showAroundTransCircle &&
                        getPriceMapLevelByZoom(this.map.getZoom()) != PRICE_MAP_STATUS.ALLPROPERTYLEVEL)
                ) {
                    this.map.removeLayer(propertyMarker);
                } else {
                }
            }
        });
    }

    async drawAllPropertiesOnMap() {
        // this.clearAllProperties();
        // this.map.addLayer(this.allPropertiesMarkerGroup);

        const topRight = { lat: this.map.getBounds()._northEast.lat, lon: this.map.getBounds()._northEast.lng };
        const bottomLeft = { lat: this.map.getBounds()._southWest.lat, lon: this.map.getBounds()._southWest.lng };
        var allProperties: any = await api_getPropertiesOnMapView(topRight, bottomLeft);
        if (getPriceMapLevelByZoom(this.map.getZoom()) != PRICE_MAP_STATUS.ALLPROPERTYLEVEL) {
            return;
        }
        for (let i = 0; i < allProperties.length; i++) {
            const loopProp = allProperties[i];
            if (!isProperType(loopProp.prop_type)) {
                continue;
            }
            if (this.allPropertyMarkers[loopProp.prop_id]) {
                if (!this.map.hasLayer(this.allPropertyMarkers[loopProp.prop_id].getPropertyMarker())) {
                    this.allPropertyMarkers[loopProp.prop_id].getPropertyMarker().addTo(this.map);
                }
                continue;
            }
            if (hasMarkerInSamePosition(this.allPropertyMarkers, { lat: loopProp.lat, lon: loopProp.lon })) {
                continue;
            }
            const markerProperty: any = {};
            markerProperty.lat = loopProp.lat;
            markerProperty.lon = loopProp.lon;
            markerProperty.propId = loopProp.prop_id;
            markerProperty.propType = loopProp.prop_type == 'AptLike' ? 'Apt' : loopProp.prop_type;
            markerProperty.sold_contract_date = loopProp.sold_contract_date;

            const propMarker = new PropertyMarker(markerProperty);
            propMarker.markerInMaxZoom = true;
            if (allFavMarkers && allFavMarkers[loopProp.prop_id]) {
                propMarker.setFavMarkerIcon();
            } else {
                propMarker.setIconByProeprtyType(false, true);
            }

            propMarker.getPropertyMarker().addTo(this.map);
            this.allPropertyMarkers[loopProp.prop_id] = propMarker;
            // this.allPropertiesMarkerGroup.addLayer(propMarker.getPropertyMarker());
        }
    }
}

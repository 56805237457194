import React, { ReactNode } from 'react';
import { rst } from 'rt-state';
import { XText } from '../Text';
import { Breadcrumbs } from '@material-ui/core';

export const XBreadcrumbs = rst.createS<{
    breadcrumbs: ReactNode[];
}>((props) => {
    return (
        <Breadcrumbs separator=">" aria-label="breadcrumb">
            {props.breadcrumbs?.map((d, idx) => {
                return (
                    <XText className={props.className} key={idx} color="inherit">
                        {d}
                    </XText>
                );
            })}
        </Breadcrumbs>
    );
});

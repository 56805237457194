import ReactDOM from 'react-dom/server';
import React from 'react';
import { favPropIcon, PropertyIcon, p_icon, RoundPropIcon } from './PropertyIcon';
import { getPriceLabelColor, calculateMedianPrice, getPriceMapLevelByZoom } from '../support/common';
import {
    convertToM,
    getItemFromArray,
    getYearMonthFromSoldDate,
    isMobileDevice,
    numToMillion,
    yearAgo,
} from '../../../commons/utils';
import { PropertyInfo } from '../Layers/PropertyInfo';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { AroundTransPropColor, MapGlobalVars, PRICE_MAP_STATUS, PropTypes } from '../support/PriceMapConsts';
import { api_getAroundTranscations, api_getPropertyFeatures } from '../../../services/property';
import {
    getAptSoldMedianPrice,
    getHouseSoldRank,
    getPropertyPriceIfNowSell,
    getPropLastRecord,
    getSuburbSoldPriceList,
    getTypeByFeature,
} from '../support/propertyFeatureHelper';
import { rst } from 'rt-state';
import { NonRecentSoldHouseInfo } from '../Layers/PropertySidebar/house/NonRecentSoldHouseInfo';
import { RecentSoldHouseInfo } from '../Layers/PropertySidebar/house/RecentSoldHouseInfo';
import { suburbDataList } from './SuburbPolygonController';
import { generateGUID } from '../../../commons';
import { getFavouriteDtoForProperty } from '../../../services/userCenter';
import { UnitInfoSidebarBasic } from '../Layers/PropertySidebar/apartment/UnitInfoSidebarBasic';
import { resetAllTags } from '../PropertyCalculator/CalculatorConst';
import { is } from 'date-fns/locale';

declare var L;
export class T_MarkerProeprty {
    propId: string;
    propType: string;
    price?: any;
    projectScale?: number;
    address?: string;
    units?: any;
    landSize?: number;
    landValue?: number;
    builtYear?: string;
    unit_number?: string;
    sold_contract_date?: string;
    lat?: number;
    lon?: number;
    allFeatures?: any;
    medianPrice?: any;
    prop_bed?: any;
    zoningStr?: any;
    streetChangeHandRate?: any;
    streetPct?: any;
    suburbChangeHandRate?: any;
}
export class PropertyMarker {
    marker?: any;
    show?: boolean;
    currerntSearching?: boolean; // this marker is current searching
    property?: T_MarkerProeprty;
    markerInMaxZoom?: boolean;
    currentClicked: boolean;

    constructor(property, alreadyGetPropData?) {
        this.property = property;
        this.marker = L.marker([property.lat, property.lon]);

        this.marker.on('click', async () => {
            this.currentClicked = true;
            this.propMarkerClickEvent();
        });
    }

    async propMarkerClickEvent() {
        console.log(this.property);

        MapGlobalVars.currentClickedPropId = this.property.propId;
        if (MapGlobalVars.nearByTransEventName == '') {
            MapGlobalVars.nearByTransEventName = generateGUID();
        }

        console.log('current Marker clicked?' + this.currentClicked);
        if (!this.property.allFeatures) {
            this.property.allFeatures = (await api_getPropertyFeatures(this.property.propId))[0];
        }

        // if there is feature return , then need to show error
        if (
            !this.property.allFeatures ||
            (this.property.allFeatures.prop_type != 1 &&
                this.property.allFeatures.prop_type != 0 &&
                this.property.allFeatures.prop_type != 3 &&
                this.property.allFeatures.prop_type != 4)
        ) {
            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                title: 'ERROR_ON_PROPERTY',
                content:
                    this.property.allFeatures?.prop_type != 0 && this.property.allFeatures?.prop_type != 3
                        ? 'WRONG PROPERTY TYPE'
                        : 'NOT_ENOUGH_INFORATION_INDB',
            });
            return;
        }

        await this.completePropertyIfNot();
        if (this.property?.allFeatures?.prop_type == 0 && !yearAgo(this.property.sold_contract_date, 'YYYY-MM-DD')) {
            this.property['soldRank'] = getHouseSoldRank(this.property.price, this.property.allFeatures.locality_id);
        }
        if (this.property?.allFeatures?.prop_type == 0 && yearAgo(this.property.sold_contract_date, 'YYYY-MM-DD')) {
            this.property['thisSuburbPriceList'] = getSuburbSoldPriceList(this.property.allFeatures.locality_id);
            console.log(this.property['thisSuburbPriceList']);
        }

        console.log(this.property);

        // check property is fav or not
        try {
            const favouriteDto = await getFavouriteDtoForProperty(this.property.propId);
            const isFav = !!favouriteDto;
            console.log(isFav);
            this.property['isFav'] = isFav;
        } catch (e) {
            console.log(e);
        }

        MapGlobalVars.clickedSuburbData = getItemFromArray(suburbDataList, 'id', this.property.allFeatures.locality_id);

        // if it is sold before a year ago, show property side bar information or it is apartment

        if (!isMobileDevice()) {
            if (MapGlobalVars.showAroundTransCircle && !yearAgo(this.property.sold_contract_date, 'YYYY-MM-DD')) {
                this.showPropertyMarkerPopup();
            } else {
                EventBus.dispatch(EVENT_NAMES.EVENT_PROP_SIDE_BAR, {
                    prop: this.getMarkerProperty(),
                    showSideBar: true,
                });
                EventBus.dispatch(EVENT_NAMES.EVENT_PROP_MARKER_CLICKED, {
                    prop: this.getMarkerProperty(),
                    showInfo: true,
                    marker: this,
                });
            }
        } else {
            if (
                this.property.propType == 'Apt' ||
                this.property.propType == 'House' ||
                MapGlobalVars.showAroundTransCircle
            ) {
                this.showPropertyMarkerPopup();
                EventBus.dispatch(EVENT_NAMES.EVENT_PROP_SIDE_BAR, {
                    prop: this.getMarkerProperty(),
                    forMobile: true,
                });
            }

            if (this.property.propType == 'Apt' && !MapGlobalVars.showAroundTransCircle) {
                EventBus.dispatch(EVENT_NAMES.EVENT_PROP_SIDE_BAR, {
                    prop: this.getMarkerProperty(),
                    retrieveAroundTrans: true,
                });
            }

            if (!MapGlobalVars.showAroundTransCircle) {
                EventBus.dispatch(EVENT_NAMES.EVENT_PROP_MARKER_CLICKED, {
                    prop: this.getMarkerProperty(),
                    showInfo: true,
                });
            }
        }

        if (!MapGlobalVars.showAroundTransCircle && this.property.propType == 'House') {
            // set 500ms delay to fire the event as need to wait for the sidebar shows
            setTimeout(() => {
                if (this.currerntSearching) {
                    EventBus.dispatch(MapGlobalVars.nearByTransEventName, { chart: -1, prop: this.property });
                } else {
                    // chart == 3 means only get around, but not draw the circle
                    EventBus.dispatch(MapGlobalVars.nearByTransEventName, { chart: 3, prop: this.property });
                }
            }, 100);
        }

        // if it is a year ago , need to get property price
        if (this.property.propType == 'House') {
            setTimeout(() => {
                EventBus.dispatch(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE, {});
            }, 300);
        }
    }

    /** when return property list from map view api, it only has property id , so need to set all necessary property values */
    async completePropertyIfNot() {
        const lastPropertySoldRecord: any = getPropLastRecord(this.property.allFeatures);
        this.property.propType = getTypeByFeature(this.property.allFeatures);
        if (this.property.propType == 'House') {
            this.property.address = this.property.allFeatures.prop_address;

            this.property.sold_contract_date = lastPropertySoldRecord.sold_contract_date;
            this.property.price = lastPropertySoldRecord.sold_price;
            this.property.landSize = this.property.allFeatures.prop_area;
            this.property.landValue = this.property.allFeatures.land_value_reset;
            this.property.zoningStr = this.property.allFeatures.prop_zoning_string;
            this.property.streetChangeHandRate = this.property.allFeatures.street_house_changing_hands;
            this.property.suburbChangeHandRate = this.property.allFeatures.suburb_house_changing_hands;
            this.property.streetPct = this.property.allFeatures.street_suburb_median_pct;
            if (this.property.allFeatures.smart_tags) {
                // this.property.allFeatures.smart_tags.forEach((loopItem) => {
                //     loopItem.selected = 0;
                // });
            }
        } else {
            this.property.address = this.property.allFeatures.full_address;
            const aptSoldMedianPrice = getAptSoldMedianPrice(this.property.propId);
            if (aptSoldMedianPrice) {
                this.property.medianPrice = aptSoldMedianPrice.medianPrice;
                this.property.units = aptSoldMedianPrice.soldProperties;
            }
        }

        console.log(JSON.stringify(this.property));
    }

    showPropertyMarkerPopup() {
        /**
         * On mobile,should hide all other layers to avoid overlay,includes title and side icons
         */
        if (isMobileDevice()) {
            EventBus.dispatch(EVENT_NAMES.EVENT_AVOID_OVERLAY, { showIcon: false });
        }
        const lastSoldRecord = getPropLastRecord(this.property.allFeatures);
        const yearAgoSold = yearAgo(lastSoldRecord.sold_contract_date, 'YYYY-MM-DD');
        this.marker
            .bindPopup(
                L.popup({
                    minWidth: isMobileDevice() ? 230 : 420,
                    offset: isMobileDevice() ? [-3, -10] : [-8, -25],
                    className: isMobileDevice() ? 'm_prop' : 'pc_prop',
                })
                    .setContent(
                        ReactDOM.renderToString(
                            React.createElement(
                                this.property.propType == 'House'
                                    ? yearAgoSold
                                        ? NonRecentSoldHouseInfo
                                        : RecentSoldHouseInfo
                                    : UnitInfoSidebarBasic,

                                {
                                    prop: this.getMarkerProperty(),
                                    onMap: true,
                                },
                            ),
                        ),
                    )
                    .on('remove', () => {
                        if (!MapGlobalVars.showAroundTransCircle) {
                            EventBus.dispatch(EVENT_NAMES.EVENT_PROP_POP_UP_CLOSED, {});
                        }
                        MapGlobalVars.showingPropertyPop = false;

                        this.marker.unbindPopup();

                        if (isMobileDevice()) {
                            EventBus.dispatch(EVENT_NAMES.EVENT_AVOID_OVERLAY, { showIcon: true });
                        }
                    }),
            )
            .openPopup();

        MapGlobalVars.showingPropertyPop = true;
    }

    getPropertyMarker() {
        return this.marker;
    }
    getMarkerProperty() {
        return this.property;
    }

    togglePriceLabel(showingLabel) {
        this.setupMarkerIcon(showingLabel);
    }

    setIconByProeprtyType(clicked?, notTrans?, zoom?) {
        const iconHTML = ReactDOM.renderToString(
            React.createElement(p_icon, {
                propType: this.property.propType,
                soldContractDate: this.property.sold_contract_date,
                clicked: clicked,
                notTrans: notTrans,
                zoom: zoom,
            }),
        );

        // const iconHTML = ReactDOM.renderToString(
        //     React.createElement(RoundPropIcon, {
        //         propType: this.property.propType,
        //     }),
        // );
        const propertyIcon = new L.DivIcon({
            className: '',
            html: iconHTML,
        });
        this.marker.setIcon(propertyIcon);
    }

    setFavMarkerIcon() {
        const iconHTML = ReactDOM.renderToString(React.createElement(favPropIcon, {}));
        const propertyIcon = new L.DivIcon({
            className: '',
            html: iconHTML,
        });
        this.marker.setIcon(propertyIcon);
    }

    setupMarkerByBeds() {
        let propertyIconColor = AroundTransPropColor['bed' + this.property.prop_bed];
        if (!propertyIconColor) {
            propertyIconColor = AroundTransPropColor['bed0'];
        }

        const iconHTML = ReactDOM.renderToString(
            React.createElement(PropertyIcon, {
                showLabel: true,
                color: propertyIconColor?.color,
                price: convertToM(this.property.price),
                currentSearching: this.currerntSearching,
                propId: this.property.propId,
            }),
        );

        const propertyIcon = new L.DivIcon({
            className: '',
            html: iconHTML,
        });
        // console.log(propertyIcon);
        this.marker.setIcon(propertyIcon);
    }

    // showCurrentPriceRef to show the sold price which how much should be today by big sector increase
    setupMarkerIcon(showingLabel?, zoom?, showCurrentPriceRef?) {
        //console.log(this.property);
        let usingPrice = 0;
        if (this.property.propType != 'House') {
            usingPrice = this.property.price;
        } else {
            usingPrice = MapGlobalVars.showPropertyPriceAsRef
                ? getPropertyPriceIfNowSell(
                      getYearMonthFromSoldDate(this.property.sold_contract_date),
                      this.property.price,
                  )
                : this.property.price;
        }
        var propertyColor = getPriceLabelColor(usingPrice);
        var propertyPrice: any;

        if (this.property.propType != 'House') {
            propertyPrice =
                convertToM(usingPrice) +
                (this.property.units && this.property.units.length > 1 ? '(' + this.property.units.length + ')' : '');
        } else {
            propertyPrice = convertToM(usingPrice);
        }

        const iconHTML = ReactDOM.renderToString(
            React.createElement(PropertyIcon, {
                showLabel: showingLabel,
                color: propertyColor,
                price: propertyPrice,
                currentSearching: this.currerntSearching,
                propId: this.property.propId,
                zoom: zoom,
            }),
        );

        const propertyIcon = new L.DivIcon({
            className: '',
            html: iconHTML,
        });
        // console.log(propertyIcon);
        this.marker.setIcon(propertyIcon);
    }
}

import { makeStyles } from '@material-ui/core';

import React from 'react';
import { rst } from 'rt-state';
import { clsx, TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { getItemFromArray, isMobileDevice } from '../../../commons/utils';
import { MapGlobalVars } from '../support/PriceMapConsts';
import { BBCLimited } from './CalculatorConst';

const useStyles = makeStyles((theme) => {
    return {
        root: { display: 'flex', justifyContent: 'space-around' },
        singleItem: { display: 'flex' },
        itemValue: {
            fontSize: 12,
            fontWeight: 400,
            color: '#666666',
        },
    };
});
export const PropertyBedsChange = rst.create<{ prop: any }>((ctx) => {
    console.log('-------------------');
    console.log(ctx.props.prop);
    const changingItems = [
        {
            idx: 1,
            feature: 'prop_bed',
            id: 'bed',
            value: ctx.props.prop.allFeatures.prop_bed,
            icon: 'bed.svg',
        },
        {
            idx: 2,
            feature: 'prop_bath',
            value: ctx.props.prop.allFeatures.prop_bath,
            id: 'bath',
            icon: 'bath.svg',
        },
        {
            idx: 3,
            feature: 'prop_car',
            id: 'car',
            value: ctx.props.prop.allFeatures.prop_car,
            icon: 'cars.svg',
        },
    ];
    return (props) => {
        setTimeout(() => {
            if (document.getElementById('prop_bed') != null)
                document.getElementById('prop_bed').innerHTML = ctx.props.prop.allFeatures.prop_bed;

            if (document.getElementById('prop_bath') != null)
                document.getElementById('prop_bath').innerHTML = ctx.props.prop.allFeatures.prop_bath;

            if (document.getElementById('prop_car') != null)
                document.getElementById('prop_car').innerHTML = ctx.props.prop.allFeatures.prop_car;
        }, 300);

        const classes = useStyles();
        const changePropBedEvt = (item, value) => {
            ctx.props.prop.allFeaturess[item] = value;
        };
        return (
            <>
                {changingItems.map((loopItem) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around', flexGrow: 1 }}>
                            <PropertyChangeItem changeEvt={changePropBedEvt} item={loopItem}></PropertyChangeItem>
                        </div>
                    );
                })}
            </>
        );
    };
});
const PropertyChangeItem = rst.create<{ item: any; changeEvt: any }>((ctx) => {
    const state = rst.state({
        value: ctx.props.item.value,
    });
    return (props) => {
        setTimeout(() => {
            if (document.getElementById(props.item.feature)) {
                state.value = parseInt(document.getElementById(props.item.feature).innerHTML);
            }
        }, 1000);

        const changeItemValue = (dire) => {
            if (dire == 'up') {
                if (state.value < BBCLimited[props.item.id].max) {
                    state.value = state.value + 1;
                    EventBus.dispatch(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE_ISCLICKED, {});
                }
            }
            if (dire == 'down') {
                if (state.value > BBCLimited[props.item.id].min) {
                    state.value = state.value - 1;
                    EventBus.dispatch(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE_ISCLICKED, {});
                }
            }
            //props.changeEvt(props.item.feature, state.value);

            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_PROP_BEDS, { tagId: props.item.feature, value: state.value });
        };
        const classes = useStyles();
        return (
            <div className={classes.singleItem}>
                <img
                    src={'assets/icons/minus-ico' + props.item.idx + '.svg'}
                    onClick={() => changeItemValue('down')}
                    style={{ cursor: 'pointer' }}></img>
                <div style={{ marginLeft: 5 }}></div>
                <img src={'assets/icons/' + props.item.icon}></img>
                <div style={{ marginLeft: 3 }}></div>
                <div id={props.item.feature} className={classes.itemValue}>
                    {state.value}
                </div>
                <div style={{ marginLeft: 5 }}></div>
                <img
                    style={{ cursor: 'pointer' }}
                    src={'assets/icons/plus-ico' + props.item.idx + '.svg'}
                    onClick={() => changeItemValue('up')}></img>
            </div>
        );
    };
});

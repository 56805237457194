import { ENV } from './common';

export const stgEnv: ENV = {
    // apiHost: 'https://ab.api.aihouse.io',
    // USER_API_HOST: 'https://abstg.api.aihouse.io',

    // update stg ts file to stg
    apiHost: 'https://abstg.api.aihouse.io',
    USER_API_HOST: 'https://abstg.api.aihouse.io',
    ql_token:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNjUwNDUwMDg0MTM4ODQ0MTYiLCJyb2xlcyI6eyI0IjoxNjcyNDkxNjAwMDAwfSwiaWF0IjoxNjQ0NzAxMzA5LCJleHAiOjE2NDk4ODUzMDl9.8__YQTNaoAWEx2xeRCAWVZ0E8NZdA1EErVKkPVzRp80',

    stripeKey:
        'pk_live_51HVTK6AD12lyhQjm2dyFYqkGyMXRhzShr7QXCdICrEs6t2p4EhdPsZbbQWlCDk6tOEGyM26Lkl6yBmFsZdP6MMKW00iOSVANUd',

    signUpExpireHours: 24,
};

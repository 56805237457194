import { rst } from 'rt-state';
import { FavouriteDto, ViewUserCenterProperty } from '../../../models/backend/userCenter';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles';
import { formatDate, TR, TROrNA } from '../../../commons';
import Box from '@material-ui/core/Box';
import React from 'react';
import { numToMillion } from '../../../commons/utils';

export const BasicInfo = rst.createS<{ dto: FavouriteDto }>((props) => {
    useTranslation();
    const classes = useStyles();
    const entity = props.dto?.entity ?? ({} as ViewUserCenterProperty);
    const { propType, soldPrice, landValue, soldSettlementDate, builtDate } = entity;

    let slot1;
    let slot2;

    if (propType === 'House') {
        slot1 = (
            <>
                <h2>{numToMillion(soldPrice) ?? 'N/A'}</h2>
                <span>
                    <TR name={'LAST_SOLD_TIME'} /> (<TROrNA name={formatDate(soldSettlementDate)} />)
                </span>
            </>
        );
        slot2 = (
            <>
                <h2>
                    <TROrNA name={numToMillion(landValue)} />
                </h2>
                <span>
                    <TR name={'RECENT_LAND_VALUE'} />
                </span>
            </>
        );
    } else {
        // apartment
        const { numberOfLots } = entity;
        let str = 'N/A';
        if (numberOfLots != null) {
            if (numberOfLots > 100) {
                str = 'Large';
            } else if (numberOfLots < 400) {
                str = 'Small';
            } else {
                str = 'Medium';
            }
        }
        slot1 = (
            <>
                <h2>
                    {numberOfLots}
                    <TROrNA name={'units'} />/<TR name={str} />
                </h2>
                <span>
                    <TR name={'ProjectScale'} />
                </span>
            </>
        );
        slot2 = (
            <>
                <h2>
                    <TROrNA name={formatDate(builtDate)} />
                </h2>
                <span>
                    <TR name={'ProjectBuiltDate'} />
                </span>
            </>
        );
    }

    return (
        <div className={classes.contentPrice}>
            <Box flexGrow={1}>{slot1}</Box>
            <Box flexGrow={1}>{slot2}</Box>
        </div>
    );
});

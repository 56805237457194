import React from 'react';
import { useStyles } from './ContactUs.styles';
import { Button } from '@material-ui/core';
import { rst } from 'rt-state';
import { XTextField, createXForm, string } from '../../components/form';

const initialValues = {
    name: '',
    email: '',
    message: '',
};

export const ContactUsPage = rst.create((ctx) => {
    const form = createXForm({
        initialValues,
        validate: {
            name: string().required('Name can not be empty').min(2, 'Please enter your name'),
            email: string().required('Email can not be empty').email('Email does not appear to be valid'),
            message: string().required('Message can not be empty').min(10, 'Message must be 10 or more characters'),
        },
        onSubmit,
        fieldProps: {
            message: {
                label: 'Please enter your message',
                multiline: true,
                rows: 4,
            },
        },
    });

    function onSubmit(values) {
        console.log(values);
    }

    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.wrapper}>
                <h2>Contact Us</h2>
                <form onSubmit={form.handleSubmit} className={classes.form}>
                    <div>
                        <XTextField form={form} name="name" />
                    </div>
                    <div>
                        <XTextField form={form} name="email" />
                    </div>
                    <div>
                        <XTextField form={form} name="message" />
                    </div>
                    <Button variant="contained" size="large" color="primary" type="submit" fullWidth>
                        Contact Us
                    </Button>
                </form>
            </div>
        );
    };
});

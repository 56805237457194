export const domReady = async (domId: any) => {
    return new Promise((resolve: any, reject: any) => {
        //  console.log('detect dom Ref===>' + domId);

        const domRef = document.getElementById(domId);
        if (!domRef) {
            setTimeout(() => {
                resolve(domReady(domId));
            }, 200);
        } else {
            resolve(domRef);
        }
    });
};

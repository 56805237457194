import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { rst } from 'rt-state';

const useStyles = makeStyles((theme) => ({
    wechat: {
        margin: '0 auto',
        textAlign: 'center',
        // display: 'flex',
        [theme.breakpoints.down('sm')]: {
            margin: '27px 30px 15px 30px',
        },
        '& img': {
            width: '200px',
            height: '200px',
            margin: '0 auto',
        },
        '& p': {
            padding: '0 20px',
            fontSize: '16px',
            fontWeight: '600',
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                // textAlign: 'center',
                // fontWeight: '500',
                // padding: '0 10px',
            },
        },
    },
}));

export const WeChatGroup = rst.createS((props) => {
    const classes = useStyles();
    return (
        <div className={classes.wechat}>
            <p>欢迎入群，扫码联系小澳，拉你入群</p>
            <p>一起查出澳洲房产真相，一起说说买房人的心里话</p>
            <img src={`${process.env.PUBLIC_URL}/static/images/weChatGroup.jpeg`} alt="weChatGroup.jpeg" />
            <p>在这里大咖云集，你可以看到最真实的讨论，每周六更有拍卖及看房现场分享 等待您的加入，赶快联系小澳吧！</p>
        </div>
    );
});

// Don't edit existing one, just add new one.
export interface TimedRoles {
    // role: Role enum
    [role: number]: number;
}

export enum Permission {
    DEFAULT = 0,
    ADMIN = 1,
    TRIAL = 1 << 1,
    PROPERTY_BUYER = 1 << 2,
    PROPERTY_OWNER = 1 << 3,
    PROFESSIONAL = 1 << 4,
}

// Don't edit existing one, just add new one.
export enum Role {
    DEFAULT = 0,
    ADMIN = 1,
    TRIAL = 1 << 1,
    PROPERTY_BUYER = 1 << 2,
    PROPERTY_OWNER = 1 << 3,
    PROFESSIONAL = 1 << 4,
}

export namespace Role {
    export function hasRole(timedRoles: TimedRoles, role: Role, now: number) {
        const expireTime = timedRoles?.[role];
        return expireTime && expireTime > now;
    }

    function getPermission(timedRoles: TimedRoles, role: Role, now: number): Permission {
        if (!hasRole(timedRoles, role, now)) {
            return 0;
        }
        switch (role) {
            case Role.ADMIN:
                return (
                    Permission.ADMIN |
                    Permission.TRIAL |
                    Permission.PROPERTY_BUYER |
                    Permission.PROPERTY_OWNER |
                    Permission.PROFESSIONAL
                );
            case Role.TRIAL:
                return Permission.TRIAL;
            case Role.PROPERTY_BUYER:
                return Permission.PROPERTY_BUYER;
            case Role.PROPERTY_OWNER:
                return Permission.PROPERTY_OWNER;
            case Role.PROFESSIONAL:
                return Permission.PROFESSIONAL;
            default:
                return Permission.DEFAULT;
        }
    }
    //////////////////////////////////////////////////
    export function addRoles(timedRoles: TimedRoles, toAdd: TimedRoles): TimedRoles {
        timedRoles = timedRoles ?? {};
        toAdd = toAdd ?? {};
        Object.keys(toAdd).forEach((role) => {
            const expireTime = toAdd[role];
            if (expireTime == null) {
                delete timedRoles[role];
            } else {
                timedRoles[role] = expireTime;
            }
        });
        return timedRoles;
    }

    export function getMergedPerm(timedRoles: TimedRoles): number {
        if (timedRoles == null) {
            return 0;
        }
        let perm = 0;
        const now = new Date().getTime();
        Object.keys(timedRoles).forEach((role) => {
            const r = parseInt(role);
            perm = perm | getPermission(timedRoles, r, now); // WARNING: only safe when it's not long int
        });
        return perm;
    }
}

export namespace Permission {
    export function hasPermission(timedRoles: TimedRoles, permission: Permission): boolean {
        return (Role.getMergedPerm(timedRoles) & permission) !== 0; // WARNING: only safe when it's not long int
    }
}

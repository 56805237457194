import { rst } from 'rt-state';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TR } from '../../../commons';
import { isMobileDevice } from '../../../commons/utils';

const useStyles = makeStyles((theme) => {
    return {
        table: {
            border: '1px solid #999999',
            marginTop: 10,
            minWidth: isMobileDevice() ? null : 400,
        },
        tableTitle: {
            fontSize: 14,
            fontWeight: 700,
        },
        header: {
            display: 'flex',
            width: '100%',
        },
        columnHead: {
            fontWeight: 700,
            padding: '5px 10px',
        },
        row: {
            display: 'flex',
            width: '100%',
        },
        rowColumn: {
            padding: '5px 10px',
        },
    };
});

interface TableColumn {
    headerName: string;
    columnWidth: string;
}
interface RowData {
    columnValues: string[];
    soldProperties: [];
}

export const RankTable = rst.create<{ title: string; columns: TableColumn[]; rowData: any }>((ctx) => {
    console.log('row data is======= ');
    console.log(ctx.props.rowData);
    return (props) => {
        const classes = useStyles();
        const presentRow = (rowData: RowData) => {
            const row = [];
            rowData?.columnValues?.forEach((loopColumn, idx) => {
                row.push(
                    <div className={classes.rowColumn} style={{ width: props.columns[idx].columnWidth }}>
                        <TR name={loopColumn}></TR>
                    </div>,
                );
            });

            return row;
        };
        return (
            <>
                <div className={classes.tableTitle}>
                    <TR name={props.title}></TR>
                </div>
                <div className={classes.table}>
                    <div className={classes.header}>
                        {props.columns.map((loopColumn) => {
                            return (
                                <div className={classes.columnHead} style={{ width: loopColumn.columnWidth }}>
                                    <TR name={loopColumn.headerName}></TR>
                                </div>
                            );
                        })}
                    </div>

                    <div style={{ display: 'block' }}>
                        {props.rowData.map((loopRow: any) => {
                            return <div className={classes.row}>{presentRow(loopRow)}</div>;
                        })}
                    </div>
                </div>
            </>
        );
    };
});

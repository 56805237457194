import React from 'react';
import { rst } from 'rt-state';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SecurityProvider, TR } from '../../commons';
import { isMobileDevice } from '../../commons/utils';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { SuburbSearchAutoComplete } from '../PriceMap/Layers/SuburbSearchInput';
import { Permission } from '../../commons/security/authz';
import { triggrMemberWindowForVisitor } from '../PriceMap/support/common';
import { AppProvider } from '../../commons/appProvider';
import { ROUTES } from '../../routes';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '1200px',
        margin: '0 auto',
        paddingBottom: 20,
    },
    title: {
        fontWeight: 'bold',
        fontSize: isMobileDevice() ? '16px' : '26px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    subTitle: {
        // fontWeight: 'bold',
        fontSize: isMobileDevice() ? '10px' : '20px',
        width: '100%',
        // display: 'flex',
        // justifyContent: 'center',
    },
    functionBody: {
        marginTop: 20,
        display: 'flex',
        flexDirection: isMobileDevice() ? 'column' : 'row',
        justifyContent: 'space-around',
        fontSize: isMobileDevice() ? '13px' : '16px',
    },
    functionItem: {
        width: isMobileDevice() ? '100%' : '300px',
        padding: 20,
        cursor: 'pointer',
    },
}));
export const FunctionChooser = rst.create((ctx) => {
    const securityProvider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const state = rst.state({
        showSearchDialog: false,
        searchType: 1,
        clickedItem: 0,
    });
    const functionItemClick = (searchType: any, item: any) => {
        if ((searchType == 1 && (item == 3 || item == 4)) || (searchType == 2 && (item == 4 || item == 5))) {
            if (
                securityProvider.hasPermission(Permission.PROPERTY_BUYER) ||
                securityProvider.hasPermission(Permission.TRIAL)
            ) {
                showSearchBox(searchType, item);
            } else {
                triggrMemberWindowForVisitor(appProvider);
            }
        } else {
            showSearchBox(searchType, item);
        }
    };
    const suburbSearchInputStyles = {
        background: 'white',
        padding: !isMobileDevice() ? '10px 17px 10px 17px' : '10px',
        borderRadius: '8px 8px 8px 8px',

        inputStyles: {
            background: '#f7f7f7',
            height: isMobileDevice() ? 30 : 40,
            fontSize: isMobileDevice() ? 12 : 14,
            placholder: 'Search',
            borderRadius: 19,
            paddingLeft: 14,
            paddingRight: 14,
            width: 50,
        },
    };

    const chooseSuburb = (suburbId: string, suburbName) => {
        // window.open("/priceMap?")
        if (state.searchType == 1) {
            if (state.clickedItem == 1 || state.clickedItem == 2) {
                window.location.href = `${ROUTES.PriceMap.path}?suburbId=${suburbId}&suburbName=${suburbName}`;
            }
            if (state.clickedItem == 3) {
                window.location.href = `${ROUTES.PriceMap.path}?suburbId=${suburbId}&suburbName=${suburbName}&showStreetRank`;
            }
            if (state.clickedItem == 4) {
                window.location.href = `${ROUTES.PriceMap.path}?suburbId=${suburbId}&suburbName=${suburbName}&showSuburbSoldByBed`;
            }
        }
        if (state.searchType == 2) {
            if (state.clickedItem == 1 || state.clickedItem == 2 || state.clickedItem == 4) {
                window.location.href = `${ROUTES.PriceMap.path}?propId=${suburbId}`;
            }
            if (state.clickedItem == 3) {
                window.location.href = `${ROUTES.PriceMap.path}?propId=${suburbId}&showSoldTimeline`;
            }

            if (state.clickedItem == 4) {
                window.location.href = `${ROUTES.PriceMap.path}?propId=${suburbId}&showAICalcu`;
            }

            if (state.clickedItem == 5) {
                window.location.href = `${ROUTES.PriceMap.path}?propId=${suburbId}&showReport`;
            }
        }
    };

    const showSearchBox = (searchType: any, clickedItem: any) => {
        state.searchType = searchType;
        state.clickedItem = clickedItem;
        state.showSearchDialog = true;
    };

    return (props) => {
        const classes = useStyles();
        return (
            <>
                <div className={classes.root}>
                    <div className={classes.title}>
                        <TR name="FunctionChoose"></TR>
                    </div>

                    <div className={classes.functionBody}>
                        <div className={classes.functionItem} style={{ background: '#E2F0D9' }}>
                            <div className={classes.subTitle}>
                                <TR name="F_SuburbChoose"></TR>
                            </div>
                            <ul>
                                <li
                                    onClick={() => {
                                        window.open('priceMap?mapType=1', '_self');
                                    }}>
                                    <TR name="F_areaMap"></TR>
                                </li>
                                <li
                                    onClick={() => {
                                        window.open('priceMap', '_self');
                                    }}>
                                    <TR name="F_filterSuburb"></TR>
                                </li>
                            </ul>
                        </div>

                        <div className={classes.functionItem} style={{ background: '#DEEBF7' }}>
                            <div className={classes.subTitle}>
                                <TR name="F_SuburbInternal"></TR>
                            </div>
                            <ul>
                                <li
                                    onClick={() => {
                                        functionItemClick(1, 1);
                                    }}>
                                    <TR name="F_SuburbProfile"></TR>
                                </li>
                                <li
                                    onClick={() => {
                                        functionItemClick(1, 2);
                                    }}>
                                    <TR name="F_SuburbTrans"></TR>
                                </li>
                                <li
                                    onClick={() => {
                                        functionItemClick(1, 3);
                                    }}>
                                    <TR name="F_SuburbStreet"></TR>
                                </li>
                                <li
                                    onClick={() => {
                                        functionItemClick(1, 4);
                                    }}>
                                    <TR name="F_SuburbByBed"></TR>
                                </li>
                            </ul>
                        </div>

                        <div className={classes.functionItem} style={{ background: '#FFF2CC' }}>
                            <div className={classes.subTitle}>
                                <TR name="F_PropertyAnalysis"></TR>
                            </div>
                            <ul>
                                <li
                                    onClick={() => {
                                        functionItemClick(2, 1);
                                    }}>
                                    <TR name="F_BasicInfo"></TR>
                                </li>
                                <li
                                    onClick={() => {
                                        functionItemClick(2, 3);
                                    }}>
                                    <TR name="F_priceSwitch"></TR>
                                </li>
                                <li
                                    onClick={() => {
                                        functionItemClick(2, 4);
                                    }}>
                                    <TR name="F_AI_Calc"></TR>
                                </li>
                                <li
                                    onClick={() => {
                                        functionItemClick(2, 5);
                                    }}>
                                    <TR name="F_propertyReport"></TR>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <Dialog open={state.showSearchDialog}>
                    <div
                        style={{
                            position: 'absolute',
                            right: 20,
                            top: 10,
                            width: isMobileDevice() ? 16 : 20,
                            height: isMobileDevice() ? 16 : 20,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: isMobileDevice() ? 14 : 18,
                            borderRadius: '50%',
                            border: '1px solid black',
                            cursor: 'pointer',
                            padding: 10,
                        }}
                        onClick={() => {
                            state.showSearchDialog = false;
                        }}>
                        X
                    </div>
                    <DialogTitle style={{ fontSize: isMobileDevice() ? 14 : 16 }}>
                        {state.searchType == 1 ? (
                            <TR name="SearchSuburbTitle"></TR>
                        ) : (
                            <TR name="SearchAddressTitle"></TR>
                        )}
                    </DialogTitle>
                    <DialogContent style={{ padding: 10 }}>
                        <div style={{ height: 200, width: isMobileDevice() ? 260 : 500, position: 'relative' }}>
                            <SuburbSearchAutoComplete
                                cssStyle={suburbSearchInputStyles}
                                searchAddress={state.searchType !== 1}
                                searchSuburb={state.searchType === 1}
                                propertySelectedEvent={chooseSuburb}
                                caller="homePop"
                                foldEvent={chooseSuburb}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );
    };
});

import { CardContent } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { TR } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { convertToM, getYearMonthFromSoldDate } from '../../../../commons/utils';
import { RankTable } from '../../../PriceMap/suburbSoldRank/RankTable';
import { SuburbSoldByBedBar } from '../../../PriceMap/suburbSoldRank/SuburbSoldByBedBar';
import { calculateMedianPrice } from '../../../PriceMap/support/common';
import { getPropertyPriceIfNowSell } from '../../../PriceMap/support/propertyFeatureHelper';
import { CardPermission } from '../cardPermission';
import { CardTitle } from '../propertyDetails/cardTitle';

export const PriceInSuburb = (props: any) => {
    const { soldPropertiesInThisSuburb } = props;
    const [soldDataRow, setSoldDataRow] = useState([]);
    const [state_soldPriceRange, setState_soldPriceRange] = useState({});
    const [state_soldNowPriceRange, setState_soldNowPriceRange] = useState({});
    console.log(soldPropertiesInThisSuburb);
    const [closeContent, setCloseContent] = useState(false);
    const closeContentEvt = () => {
        setCloseContent(!closeContent);
    };

    const [hasPermission, setHasPermission] = useState(true);
    EventBus.on(EVENT_NAMES.EVENT_MOBILE_CARD_NOPERMISSION, (evtData: any) => {
        setHasPermission(false);
    });

    var soldPriceRange = {
        min: 0,
        max: 0,
    };

    var soldNowPriceRange = {
        min: 0,
        max: 0,
    };

    useEffect(() => {
        const rowData = [
            {
                columnValues: ['3Beds', 0, '12', 'abcdefg', '12m', '12'],
                soldProperties: [],
                soldPriceList: [],
                nowSoldPriceList: [],
                soldLandSize: [],
                soldLandValue: [],
            },
            {
                columnValues: ['4Beds', 0, '12', 'abcdefg', '12m', '12'],
                soldProperties: [],
                soldPriceList: [],
                nowSoldPriceList: [],
                soldLandSize: [],
                soldLandValue: [],
            },
            {
                columnValues: ['5Beds', 0, '12', 'abcdefg', '12m', '12'],
                soldProperties: [],
                soldPriceList: [],
                nowSoldPriceList: [],
                soldLandSize: [],
                soldLandValue: [],
            },
        ];
        if (soldPropertiesInThisSuburb && soldPropertiesInThisSuburb.length > 0) {
            soldPropertiesInThisSuburb.forEach((loopProperty) => {
                console.log(loopProperty);
                if (soldPriceRange.max == 0 && soldPriceRange.min == 0) {
                    soldPriceRange = {
                        min: loopProperty.sold_price,
                        max: loopProperty.sold_price,
                    };
                }

                const nowSoldPrice = getPropertyPriceIfNowSell(
                    getYearMonthFromSoldDate(loopProperty.sold_contract_date),
                    loopProperty.sold_price,
                );
                console.log('===========');
                console.log(nowSoldPrice);

                if (soldNowPriceRange.max == 0 && soldNowPriceRange.min == 0) {
                    soldNowPriceRange = {
                        min: nowSoldPrice,
                        max: nowSoldPrice,
                    };
                }

                if (loopProperty.sold_price > soldPriceRange.max) {
                    soldPriceRange.max = loopProperty.sold_price;
                }
                if (loopProperty.sold_price < soldPriceRange.min) {
                    soldPriceRange.min = loopProperty.sold_price;
                }

                if (nowSoldPrice > soldNowPriceRange.max) {
                    soldNowPriceRange.max = nowSoldPrice;
                }
                if (nowSoldPrice < soldNowPriceRange.min) {
                    soldNowPriceRange.min = nowSoldPrice;
                }

                if (loopProperty.prop_bed <= 3) {
                    console.log('3 bedrooms');
                    rowData[0].columnValues[1] = Number(rowData[0].columnValues[1]) + 1;
                    console.log(rowData[0].columnValues[1]);
                    rowData[0].soldProperties.push(loopProperty);
                    rowData[0].soldPriceList.push(loopProperty.sold_price);
                    rowData[0].nowSoldPriceList.push(nowSoldPrice);
                    rowData[0].soldLandSize.push(loopProperty.land_size);
                    rowData[0].soldLandValue.push(loopProperty.land_value);
                }
                if (loopProperty.prop_bed == 4) {
                    console.log('4 bedrooms');
                    rowData[1].columnValues[1] = Number(rowData[1].columnValues[1]) + 1;
                    rowData[1].soldProperties.push(loopProperty);
                    rowData[1].soldPriceList.push(loopProperty.sold_price);
                    rowData[1].nowSoldPriceList.push(nowSoldPrice);
                    rowData[1].soldLandSize.push(loopProperty.land_size);
                    rowData[1].soldLandValue.push(loopProperty.land_value);
                }
                if (loopProperty.prop_bed >= 5) {
                    rowData[2].columnValues[1] = Number(rowData[2].columnValues[1]) + 1;
                    rowData[2].soldProperties.push(loopProperty);
                    rowData[2].soldPriceList.push(loopProperty.sold_price);
                    rowData[2].nowSoldPriceList.push(nowSoldPrice);
                    rowData[2].soldLandSize.push(loopProperty.land_size);
                    rowData[2].soldLandValue.push(loopProperty.land_value);
                }
            });

            rowData.forEach((loopRow) => {
                const medianPrice = calculateMedianPrice(loopRow.soldPriceList);
                const medianLandSize = calculateMedianPrice(loopRow.soldLandSize);
                const medianLandValue = calculateMedianPrice(loopRow.soldLandValue);
                if (medianPrice && medianPrice.median) {
                    loopRow.columnValues[2] = convertToM(medianPrice.median);
                    loopRow.columnValues[3] = convertToM(calculateMedianPrice(loopRow.nowSoldPriceList)?.median);
                    loopRow.columnValues[4] = medianLandSize.median.toFixed(2);
                    loopRow.columnValues[5] = convertToM(medianLandValue.median);
                }
            });

            console.log('=====================');
            soldPriceRange = {
                min: Math.floor(convertToM(soldPriceRange.min)),
                max: Math.ceil(convertToM(soldPriceRange.max)),
            };

            soldNowPriceRange = {
                min: Math.floor(convertToM(soldNowPriceRange.min)),
                max: Math.ceil(convertToM(soldNowPriceRange.max)),
            };

            console.log(rowData);
            setSoldDataRow(rowData);
            console.log(soldPriceRange);
            setState_soldPriceRange(soldPriceRange);
            setState_soldNowPriceRange(soldNowPriceRange);
        }
    }, []);
    return (
        <div style={{ width: '100%', padding: '10px' }}>
            <CardTitle closeContent={!closeContent} closeEvt={closeContentEvt} title={'Price In Suburb'}></CardTitle>
            <CardContent style={{ padding: 0 }}>
                {closeContent ? (
                    <div style={{ position: 'relative', minHeight: 150 }}>
                        <>
                            <RankTable
                                title={'SOLD_BY_BED_REPORT'}
                                rowData={soldDataRow}
                                columns={[
                                    { headerName: 'BEDS', columnWidth: '16%' },
                                    { headerName: 'TOTAL_SOLD', columnWidth: '16%' },
                                    { headerName: 'SOLD_MEDIAN_PRICE', columnWidth: '16%' },
                                    { headerName: 'SOLD_CONVERT_MEDIAN_PRICE', columnWidth: '16%' },
                                    { headerName: 'MEDIAN_LAND_SIZE', columnWidth: '16%' },
                                    { headerName: 'MEDIAN_LAND_VALUE', columnWidth: '16%' },
                                ]}></RankTable>

                            <SuburbSoldByBedBar
                                soldPriceRange={state_soldPriceRange}
                                soldData={soldDataRow}
                                title="SOLD_BY_BED_PRICE_DIST"></SuburbSoldByBedBar>

                            <SuburbSoldByBedBar
                                soldPriceRange={state_soldNowPriceRange}
                                soldData={soldDataRow}
                                title="SOLD_BY_BED_NOW_PRICE_DIST"
                                showNorPrice={true}></SuburbSoldByBedBar>
                        </>
                    </div>
                ) : (
                    <TR name="summary_priceInSuburb"></TR>
                )}
            </CardContent>
        </div>
    );
};

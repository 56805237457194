/* eslint-disable prettier/prettier */
import { Theme } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { kFormatter } from '../../../commons/utils';

const useStyles = makeStyles({
  root: {
    margin: '10px auto',
    borderCollapse: 'collapse',
    border: '1px solid rgb(167 222 255)',
    // borderRadius: '10px',
    width: '100%',
    '& th': {
      background: 'rgb(221 242 255)',
      border: '1px solid rgb(167 222 255)',
      height: '30px',
      textAlign: 'center',
    },
    '& td': {
      border: '1px solid rgb(167 222 255)',
      height: '30px',
      textAlign: 'center',
    },
  },
});

export const BusinessTable = rst.create<{ BusinessParks?: any }>((ctx) => {
  return (props) => {
    console.log(props.BusinessParks);
    const classes = useStyles();
    const { BusinessParks } = props;
    return (
      <table className={classes.root}>
        <tr>
          <th>#</th>
          <th>
            <TR name="Card25_businessPark" />
          </th>
          <th>
            <TR name="Card25_2016" />
          </th>
          <th>
            <TR name="Card25_2036" />
          </th>
          <th>
            <TR name="Card25_industry" />
          </th>
        </tr>
        {props?.BusinessParks?.map((loopPark, idx) => {
          console.log(typeof(loopPark.numberOfJobs?.employees[0]));
          return (
            <tr>
              <td>{idx + 1}</td>
              <td>{loopPark.name}</td>
              <td>{kFormatter(loopPark.numberOfJobs?.employees[0])}</td>
              <td>{kFormatter(loopPark.numberOfJobs?.employees[1])}</td>
              <td>{loopPark.mainIndustries.join(', ')}</td>
              {/* <td>{loopPark.mainIndustries.map(loop => {
                                return (
                                    <>
                                        <TR name={loop}></TR>, 
                                    </>
                                )
                            })}</td> */}
            </tr>
          );
        })}

        {/* use below when having data */}
        {/* {BusinessParks.map((BusinessPark) => (
                    <tr>
                        <td>{BusinessPark.name}</td>
                        <td>{BusinessPark.Num2016}</td>
                        <td>{BusinessPark.Num2036}</td>
                        <td>{BusinessPark.occ}</td>
                    </tr>
                ))} */}
      </table>
    );
  };
});

import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TR, TRHtml } from '../../../commons';
import { ROUTES } from '../../../routes';
import { Tooltip, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '376px',
        height: '189px',
        margin: '11px 5px',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'left',
        cursor: 'pointer',
        background: '#065e85',
        borderRadius: '10px',
    },
    title: {
        margin: '23px 0 23px 29px',
        fontSize: '26px',
        fontFamily: 'PingFangSC, PingFangSC-Semibold',
        fontWeight: 600,
        textAlign: 'left',
        color: '#ffffff',
    },
    contentCon: {
        width: '248px',
        height: '76px',
        margin: '11px 0px 19px 29px ',
        background: 'rgba(255,255,255,0.80)',
        borderRadius: '4px',
    },
    content: {
        paddingLeft: '12px',
        fontSize: '22px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        textAlign: 'left',
        color: '#333333',
    },
}));

export const DisplaySubInfo = (props) => {
    const classes = useStyles();

    const { ranking, suburb, changePct, middlePrice, suburbId } = props;
    const handleOpenPriceMap = () => {
        window.open(`${ROUTES.PriceMap.path}?suburbId=${suburbId}&suburbName=${suburb}`, '_self');
    };
    const [suburbImg, setSuburbImg] = useState('');
    // useEffect(() => {
    //     getSuburbImage(suburb).then((suburbSrc) => {
    //         setSuburbImg(suburbSrc);
    //     });
    // }, [suburb]);
    const suburbName = suburb.replace(/ /g, '');
    console.log('~~~~~~', suburbImg, suburb);
    return (
        <>
            <Tooltip arrow placement="right" title={<TRHtml html={'checkSubData'} />}>
                <Button>
                    <div
                        className={classes.root}
                        onClick={handleOpenPriceMap}
                        style={{
                            backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.50), rgba(0,0,0,0.00) 40%), url(${process.env.PUBLIC_URL}/static/images/suburbImg/${suburbName}.jpg)`,
                        }}>
                        <div className={classes.title}>
                            {ranking}. {suburb}
                        </div>

                        <div className={classes.contentCon}>
                            {Number.isInteger(changePct) ? (
                                changePct > 0 ? (
                                    <div className={classes.content}>
                                        <img
                                            src="/assets/icons/rise-ico.svg"
                                            alt="rise arrow"
                                            style={{ paddingRight: '2px' }}
                                        />{' '}
                                        {'$' + Intl.NumberFormat('en-US').format(changePct)}
                                    </div>
                                ) : (
                                    <div className={classes.content}>
                                        <img
                                            src="/assets/icons/decline-ico.svg"
                                            alt="decline arrow"
                                            style={{ paddingRight: '2px', fill: 'red' }}
                                        />{' '}
                                        {'$' + Intl.NumberFormat('en-US').format(Math.abs(changePct))}
                                    </div>
                                )
                            ) : changePct > 0 ? (
                                <div className={classes.content}>
                                    <img
                                        src="/assets/icons/rise-ico.svg"
                                        alt="rise arrow"
                                        style={{ paddingRight: '2px' }}
                                    />{' '}
                                    {(changePct * 100).toFixed(0) + '%'}
                                </div>
                            ) : (
                                <div className={classes.content}>
                                    <img
                                        src="/assets/icons/decline-ico.svg"
                                        alt="decline arrow"
                                        style={{ paddingRight: '2px' }}
                                    />{' '}
                                    {Math.abs(changePct * 100).toFixed(0) + '%'}
                                </div>
                            )}

                            <div className={classes.content}>
                                <TR name="MiddlePrice"></TR>: ${Intl.NumberFormat('en-US').format(middlePrice)}
                                <img src="/assets/icons/arrow-ico.svg" alt="arrow" style={{ paddingLeft: '2px' }} />
                            </div>
                        </div>
                    </div>
                </Button>
            </Tooltip>
        </>
    );
};

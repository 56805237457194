import ReactGA from 'react-ga';
ReactGA.initialize('UA-148014445-2');

/***
 * Record the page view and page event to GA
 */
export const recordPageView = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
};
export const recordUserActionsToGA = (category, actionName) => {
    console.log('change action type....');
    ReactGA.event({
        category: category,
        action: actionName,
    });
};

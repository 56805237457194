import { CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { TR } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { Card12 } from '../../../PropertyReport/ReportCards/Card12';
import { Card21 } from '../../../PropertyReport/ReportCards/Card21';
import { Card22 } from '../../../PropertyReport/ReportCards/Card22';
import { Card23 } from '../../../PropertyReport/ReportCards/Card23';
import { Card24 } from '../../../PropertyReport/ReportCards/Card24';
import { CardPermission } from '../cardPermission';
import { CardTitle } from '../propertyDetails/cardTitle';
import { SuburbBasicInfo } from './basicInfo';

const useStyles = makeStyles({
    tab: {
        fontSize: '8px',
        padding: '8px 10px !important',
    },
});
export const SuburbSchoolCard = (props: any) => {
    const [closeContent, setCloseContent] = useState(true);

    const closeContentEvt = () => {
        setCloseContent(!closeContent);
    };

    const [hasPermission, setHasPermission] = useState(false);
    EventBus.on(EVENT_NAMES.EVENT_MOBILE_CARD_NOPERMISSION, (evtData: any) => {
        console.log(evtData);
        setHasPermission(evtData.permission);
    });
    const [tab, setTab] = useState(1);
    const { suburbData, suburbData0 } = props;
    console.log(suburbData);
    const classes = useStyles();
    const changeTab = (evt, tab) => {
        setTab(tab);
    };
    return (
        <>
            <div style={{ width: '100%', padding: 10 }}>
                <CardTitle closeContent={closeContent} closeEvt={closeContentEvt} title={'Education'}></CardTitle>
                <CardContent style={{ padding: 0 }}>
                    <SuburbBasicInfo part="school" suburbQL={suburbData} suburbData={suburbData0}></SuburbBasicInfo>
                    {!closeContent ? (
                        <div style={{ position: 'relative', minHeight: 150 }}>
                            <>
                                <Tabs
                                    activeTab="1"
                                    className="mt-5"
                                    ulClassName=""
                                    activityClassName="bg-success"
                                    onClick={changeTab}>
                                    <Tab title={<TR name="primary_school"></TR>} className={classes.tab}></Tab>
                                    <Tab title={<TR name="secondary_school"></TR>} className={classes.tab}></Tab>

                                    <Tab title={<TR name="selective_school"></TR>} className={classes.tab}></Tab>

                                    <Tab title={<TR name="private_school"></TR>} className={classes.tab}></Tab>
                                </Tabs>
                                {tab == 1 ? <Card21 property={suburbData.property} mobile={true} /> : null}
                                {tab == 2 ? (
                                    <div style={{ position: 'relative', minHeight: '150px' }}>
                                        {hasPermission ? (
                                            <Card22 property={suburbData.property} mobile={true} />
                                        ) : (
                                            <CardPermission></CardPermission>
                                        )}
                                    </div>
                                ) : null}
                                {tab == 3 ? (
                                    <div style={{ position: 'relative', minHeight: '150px' }}>
                                        {hasPermission ? (
                                            <Card23 prop={suburbData} mobile={true} />
                                        ) : (
                                            <CardPermission></CardPermission>
                                        )}
                                    </div>
                                ) : null}
                                {tab == 4 ? (
                                    <div style={{ position: 'relative', minHeight: '150px' }}>
                                        {hasPermission ? (
                                            <Card24 prop={suburbData} mobile={true} />
                                        ) : (
                                            <CardPermission></CardPermission>
                                        )}
                                    </div>
                                ) : null}
                            </>
                        </div>
                    ) : null}
                </CardContent>
            </div>
        </>
    );
};

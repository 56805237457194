import React from 'react';
import { makeStyles, styled, Switch } from '@material-ui/core';
import { TR } from '../../commons';
const useStyles = makeStyles({
    label: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#333333',

        fontFamily: 'PingFangSC, PingFangSC-Regular',
    },
});

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
    ({ theme }) => ({
        width: '44px',
        height: '24px',
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#009e90',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: '22px',
            height: '20px',
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#d8d8d8',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }),
);

export const HomeOnToggleButton = (props) => {
    const { label, switchEvt } = props;
    const classes = useStyles();
    return (
        <div style={{ display: 'flex' }}>
            <span className={classes.label}>
                <TR name={label}></TR>
            </span>
            <div style={{ marginLeft: '8px' }}></div>
            <IOSSwitch onChange={switchEvt} />
        </div>
    );
};

export const HomeOnToggleButtonDefaultChecked = (props) => {
    const { label, switchEvt } = props;
    const classes = useStyles();
    return (
        <div style={{ display: 'flex' }}>
            <span className={classes.label}>
                <TR name={label}></TR>
            </span>
            <div style={{ marginLeft: '8px' }}></div>
            <IOSSwitch onChange={switchEvt} defaultChecked />
        </div>
    );
};

import { Button, Card, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { generateGUID, SecurityProvider, TR } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { queryProp_byGraphQL, query_byGraphQL, streetQuery } from '../../../commons/graphqlClient/graphAPI';
import { Permission } from '../../../commons/security/authz';
import { isMobileDevice } from '../../../commons/utils';
import { AwesomeIcon } from '../../../components/fontAwesomeIcon';
import { Margin } from '../../../components/margin';
import { api_getPropertyFeatures, api_getSoldProperties } from '../../../services/property';
import { StreetView } from '../../PriceMap/Layers/PropertyInfo';
import { PropBeds } from '../../PriceMap/Layers/PropertyInfo/PropBeds';
import { SuburbSearchAutoComplete } from '../../PriceMap/Layers/SuburbSearchInput';
import { allSoldPropertiyData } from '../../PriceMap/MapContainer/PropertyMarkerController';
import {
    getPrimarySchoolDesc,
    getPrimarySchoolDescByScore,
    getSecondarySchoolDesc,
} from '../../PriceMap/support/common';
import { constructPropObjFromFeature, getTypeByFeature } from '../../PriceMap/support/propertyFeatureHelper';
import { mobileNavButton } from '../consts/styleConsts';
import { domReady } from '../supportUtils/htmlUtils';
import { AutoAddressComplete } from './autoAddressComplete';
import { Back } from './Back';
import { CardLine } from './cardSeperate';
import { ApartmentProjectInfo } from './propertyDetails/apartmentProject';
import { AptHalfYearSold } from './propertyDetails/aptSold';
import { AroundPriceCard } from './propertyDetails/aroundPriceCard';
import { LandZoning } from './propertyDetails/landZoning';
import { PropertyMobileCaculatorCard } from './propertyDetails/mobileCaculator';
import { ProjectSolds } from './propertyDetails/ProjectSolds';
import { PropertyAddress } from './propertyDetails/propertyAddress';

import { SchoolCard } from './propertyDetails/schoolCard';
import { SimilarHouseCard } from './propertyDetails/similarHouseCard';
import { SoldHistoryCard } from './propertyDetails/soldHistoryCard';
import { Station } from './propertyDetails/station';
import { InfoValue } from './standard/infoValue';
import { InfoLabel } from './standard/label';
import { AroundPriceMap } from '../component/aroundPriceMap';
import { exceedDailyLimit, saveSearchHistory } from '../../../services/userCenter';
import { UserCenterEntityType } from '../../../models/backend/userCenter';
import { NoVerifyEmailReceived } from '../member/verifyEmail/NoVerifyEmailReceived';
import { MemberInfo } from '../member/memberInfo';
import { VariousPopup } from '../member/signUp/TipAfterSignup';
const suburbSearchInputStyles = {
    padding: '5px 15px',
    borderRadius: '10px',
    inputStyles: {
        background: 'white',
        height: 30,

        fontSize: 12,
        //fontSize: 18,
        placholder: isMobileDevice()
            ? 'Enter a Sydney address or suburb'
            : 'Enter a Sydney address or suburb to reveal the data insights you’ve never seen',
        borderRadius: 19,
        paddingLeft: isMobileDevice() ? 0 : 0,
        paddingRight: isMobileDevice() ? 0 : 0,
    },
};

declare var window: any;

export const PropertyCard = (props: any) => {
    const history = useHistory();
    const { propId, choosedItem, itemChoosedEvt, backEvt, inDetails, embedded } = props;
    const securityProvider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const compId = generateGUID();
    const [showVerifyEmail, setShowVerifyEmail] = useState(false);
    const [popType, setPopType] = useState(-1);
    const [showMember, setShowMember] = useState(false);
    const [showWelcomeAreaMember, setShowWelcomeAreaMember] = useState(false);
    const [state, setState] = useState({
        property: {} as any,
        prop_ql: {} as any,
        showingDetails: inDetails,
        address: '',
        propType: '',
        mixedPropObj: {} as any,
        loading: false,
        streetQL: null as any,
        suburbSolds: null,
        showSimilarTrans: false,
        exceedLimit: true,
    });

    window.gtag('config', 'UA-148014445-2', {
        page_title: 'Property Details',
        page_path: '/mobile_PropertyDetails',
    });

    const isMember = () => {
        return securityProvider.hasPermission(Permission.PROPERTY_BUYER);
    };
    const isTrial = () => {
        return securityProvider.hasPermission(Permission.TRIAL);
    };
    const userExpired = () => {
        return securityProvider.isExpired(
            securityProvider.hasPermission(Permission.PROPERTY_BUYER) ? Permission.PROPERTY_BUYER : Permission.TRIAL,
        );
    };
    const showSignup = () => {
        appProvider.state.authDialog = 'signup';
    };
    const toPayment = () => {
        window.open('/joinMember', '_self');
    };

    useEffect(() => {
        if (!state.showingDetails) {
            setState({
                ...state,
                loading: true,
            });

            api_getPropertyFeatures(propId).then((property: any) => {
                queryProp_byGraphQL(propId).then((prop_ql: any) => {
                    query_byGraphQL(streetQuery, property[0]?.street_id).then(
                        (street_ql: any) => {
                            console.log(street_ql);

                            const postData = {
                                idList: [property[0].locality_id],
                                period: 6,
                            };
                            api_getSoldProperties(postData).then((result: any) => {
                                console.log(result);

                                setState({
                                    ...state,
                                    loading: false,
                                    prop_ql: prop_ql.data,
                                    property: property[0],
                                    mixedPropObj: constructPropObjFromFeature(property),
                                    address: property[0].prop_address || property[0].full_address,
                                    propType: property[0].prop_type,
                                    streetQL: street_ql.data.street,
                                    suburbSolds: result,
                                });
                            });
                        },
                        (err) => {
                            setState({
                                ...state,
                                loading: false,
                            });
                        },
                    );
                });
            });
        } else {
            setState({
                ...state,
                loading: true,
            });

            api_getPropertyFeatures(propId).then((property: any) => {
                queryProp_byGraphQL(propId).then((prop_ql: any) => {
                    query_byGraphQL(streetQuery, property[0]?.street_id).then(
                        (street_ql: any) => {
                            console.log(street_ql);

                            const postData = {
                                idList: [property[0].locality_id],
                                period: 6,
                            };
                            api_getSoldProperties(postData).then((result: any) => {
                                console.log(result);

                                setState({
                                    ...state,
                                    loading: false,
                                    prop_ql: prop_ql.data,
                                    property: property[0],
                                    mixedPropObj: constructPropObjFromFeature(property),
                                    address: property[0].prop_address || property[0].full_address,
                                    propType: property[0].prop_type,
                                    streetQL: street_ql.data.street,
                                    suburbSolds: result,
                                });
                            });
                        },
                        (err) => {
                            setState({
                                ...state,
                                loading: false,
                            });
                        },
                    );
                });
            });
        }
    }, [securityProvider, propId]);

    const logged = () => {
        return securityProvider.hasLogin();
    };
    const getUserStatus = () => {
        return securityProvider.getUserProfile().userStatus;
    };

    const showDetails = async () => {
        const doNext = await canDoNext();
        if (doNext) {
            saveSearchHistory({
                entityId: propId + '',
                entityType: UserCenterEntityType.Property,
            });
            if (embedded) {
                const newURL = `/mobileHome?queryType=1&queryId=${propId}&queryAddress=${state.address}`;

                console.log(newURL);
                window.open(newURL, '_blank');
            } else {
                history.push(
                    `/mobileHome?queryType=1&queryId=${propId}&queryAddress=${state.address}&showingDetails=true`,
                );
                setState({
                    ...state,
                    showingDetails: true,
                });
            }
        }
    };
    const canDoNext = async (canDoThisPropId?) => {
        // try {
        //     await securityProvider.refreshProfile();
        // } catch (e) {}

        if (!logged()) {
            showSignup();
            return false;
        } else if (getUserStatus() == '1') {
            setShowVerifyEmail(true);
            return false;
        } else {
            const userUsage: any = await exceedDailyLimit(canDoThisPropId || propId);

            // if execeed the user usage level , should pop up window
            if (userUsage.popType != -1) {
                setShowMember(true);
                setPopType(userUsage.popType);
                return false;
            }
        }
        return true;
    };

    const openSimilarTrans = (open) => {
        setState({
            ...state,
            showSimilarTrans: open,
        });
    };

    const showSuburbDetails = () => {
        history.push(
            `/mobileHome?queryType=3&queryId=${state.property.locality_id}&queryAddress=${state.property.prop_locality}`,
        );

        EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_SHOW_SUBURB, {
            suburbId: state.property.locality_id,
            name: state.property.prop_locality,
        });

        console.log(state.property);

        // const suburbURL = `/mobileHome?queryType=3&queryId=${state.property.locality_id}&queryAddress=${state.property.prop_locality}`;
        // window.open(suburbURL, '_self');
    };

    const showStreetDetail = () => {
        const streetData = state.prop_ql.property.street;
        const streetAddress = `${streetData.streetName},${streetData.localityName} ${streetData.state} ${streetData.post}`;
        history.push(`/mobileHome?queryType=2&queryId=${streetData.streetId}&queryAddress=${streetAddress}`);

        EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_SHOW_STREET, {
            streetId: streetData.streetId,
            name: streetAddress,
        });

        // const suburbURL = `/mobileHome?queryType=2&queryId=${streetData.streetId}&queryAddress=${streetAddress}`;
        // window.open(suburbURL, '_blank');
    };

    const { prop_bed, prop_car, prop_bath, prop_area } = state.property;
    const propType = getTypeByFeature(state.property);
    const handleBack = () => {
        if (state.showingDetails) {
            setState({
                ...state,
                showingDetails: false,
            });
        } else {
            backEvt();
        }
    };
    const changeProperty = async (newPropId: string, choosedAddress) => {
        if (state.showingDetails) {
            const doNext = await canDoNext(newPropId);
            if (doNext) {
                saveSearchHistory({
                    entityId: newPropId + '',
                    entityType: UserCenterEntityType.Property,
                });
                props.itemChoosedEvt(newPropId, choosedAddress);
            }
        } else {
            props.itemChoosedEvt(newPropId, choosedAddress);
        }
    };

    return (
        <>
            {!state.loading ? (
                <>
                    {embedded ? null : (
                        <>
                            <div style={{ display: 'flex', marginTop: 10, alignItems: 'center', marginLeft: 5 }}>
                                <div style={{ marginLeft: 8, marginRight: 8 }}>
                                    <AwesomeIcon
                                        icon={<img src="assets/mobileIcons/leftArrow.svg"></img>}
                                        clickEvt={handleBack}
                                        style={{
                                            fontSize: 20,
                                            color: '#b2b2b2',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}></AwesomeIcon>
                                </div>
                                <div style={{ flex: 0.95 }}>
                                    <AutoAddressComplete
                                        init_choosedItem={props.choosedItem}
                                        itemChoosedEvt={changeProperty}
                                        queryType={'1'}></AutoAddressComplete>
                                </div>
                            </div>
                            {AppProvider.use().getLanguage() == 'zh' ? (
                                <div style={{ display: 'flex', padding: '10px', fontSize: '13px' }}>
                                    {' '}
                                    <TR name="foundAtAddressDesc"></TR>
                                    <TR name={getTypeByFeature(state.property)}></TR>
                                    <TR name="colon"></TR>
                                </div>
                            ) : (
                                <div style={{ display: 'flex', padding: '10px', fontSize: '13px' }}>
                                    {' '}
                                    <TR name="foundAtAddressDesc"></TR>
                                    <TR name="colon"></TR>
                                </div>
                            )}
                        </>
                    )}

                    {state.property && state.address && state.prop_ql ? (
                        <div
                            style={{
                                marginTop: '1rem',
                                width: '100%',
                            }}>
                            <StreetView
                                w={window.screen.width}
                                h={window.screen.width / 2}
                                lat={state.property.prop_y}
                                lng={state.property.prop_x}
                                onMap={false}
                                showFor="infopanel"
                                imageBorderRadius={1}
                                address={state.address}></StreetView>

                            <div style={{ marginTop: 23 }}></div>
                            <div style={{ padding: '10px' }}>
                                <PropertyAddress address={state.address}></PropertyAddress>

                                <div style={{ marginTop: '6px' }}></div>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div style={{ flex: 2 }}>
                                        <PropBeds
                                            style={{ display: 'flex', justifyContent: 'flex-start', width: '70%' }}
                                            propType={propType}
                                            beds={prop_bed}
                                            cars={prop_car ? prop_car : 'N/A'}
                                            baths={prop_bath}
                                            landSize={prop_area}></PropBeds>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <TR name={getTypeByFeature(state.property)}></TR>
                                    </div>
                                </div>
                                <div style={{ marginTop: 23 }}></div>
                                {state.showingDetails ? null : (
                                    <SoldHistoryCard
                                        inSummary={true}
                                        soldHistory={state.property.sold_record}></SoldHistoryCard>
                                )}
                                {state.showingDetails || embedded ? null : (
                                    <>
                                        <div style={{ marginTop: 16 }}></div>
                                        <AroundPriceCard
                                            propId={propId}
                                            isSummary={true}
                                            property={state.mixedPropObj}
                                            propType={getTypeByFeature(state.property)}
                                            lat={state.property.prop_y}
                                            propBed={prop_bed}
                                            lon={state.property.prop_x}></AroundPriceCard>
                                    </>
                                )}
                                <div style={{ marginTop: 16 }}></div>
                                {!state.showingDetails && getTypeByFeature(state.property) == 'House' ? (
                                    <>
                                        <LandZoning
                                            landValue={state.property.land_value_reset}
                                            zoning={state.property.prop_zoning}></LandZoning>
                                        <div style={{ marginTop: '6px' }}></div>
                                    </>
                                ) : null}

                                {state.showingDetails || getTypeByFeature(state.property) == 'House' ? null : (
                                    <>
                                        {embedded ? null : (
                                            <ApartmentProjectInfo property={state.property}></ApartmentProjectInfo>
                                        )}
                                    </>
                                )}
                                {state.showingDetails || embedded ? null : (
                                    <>
                                        <div style={{ marginTop: 20 }}></div>
                                        <Station station={state.property.closest_station}></Station>
                                    </>
                                )}
                                {!state.showingDetails &&
                                (getTypeByFeature(state.property) != 'Apt' ||
                                    (getTypeByFeature(state.property) == 'Apt' && !embedded)) &&
                                state.prop_ql ? (
                                    <div style={{ fontWeight: 400, fontSize: '13px', marginTop: 20, color: '#888888' }}>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <div style={{ flex: 4, display: 'flex' }}>
                                                <InfoLabel>cathmentPrimary</InfoLabel>
                                                <InfoValue> {state.property.primary[0].school_name}</InfoValue>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <InfoValue>
                                                    <TR
                                                        name={getPrimarySchoolDescByScore(
                                                            state.property.primary[0].score,
                                                        )}></TR>
                                                </InfoValue>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                marginTop: 5,
                                                display: 'flex',
                                                width: '100%',
                                            }}>
                                            <div style={{ flex: 4, display: 'flex' }}>
                                                <InfoLabel>cathmentSecondary</InfoLabel>
                                                <div>
                                                    {state.property.secondary.map((school) => {
                                                        return <InfoValue> {school.school_name}</InfoValue>;
                                                    })}
                                                </div>
                                            </div>

                                            <div style={{ flex: 1 }}>
                                                <div>
                                                    {state.property.secondary.map((school) => {
                                                        return (
                                                            <InfoValue>
                                                                <TR
                                                                    name={getPrimarySchoolDescByScore(
                                                                        school.score,
                                                                    )}></TR>
                                                            </InfoValue>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}

                                {!state.showingDetails &&
                                state.streetQL &&
                                getTypeByFeature(state.property) == 'House' ? (
                                    <div style={{ marginTop: '20px' }}>
                                        <InfoLabel noColon>priceLevelToSuburb1</InfoLabel>
                                        <div style={{ display: 'flex', marginTop: 5 }}>
                                            <InfoLabel>housePrice</InfoLabel>
                                            <InfoValue>
                                                {state.streetQL.suburbMedianPct > 1 ? (
                                                    <TR name="higher"></TR>
                                                ) : (
                                                    <TR name="lower"></TR>
                                                )}
                                            </InfoValue>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: 5 }}>
                                            <InfoLabel>houseHoldingPeriod</InfoLabel>
                                            <InfoValue>
                                                {state.streetQL.turnoverRate > 1 ? (
                                                    <TR name="long"></TR>
                                                ) : (
                                                    <TR name="short"></TR>
                                                )}
                                            </InfoValue>
                                        </div>
                                    </div>
                                ) : null}
                            </div>

                            <Margin tm={10}></Margin>

                            {state.showingDetails ? (
                                <>
                                    <CardLine></CardLine>
                                    <SoldHistoryCard soldHistory={state.property.sold_record}></SoldHistoryCard>

                                    <CardLine></CardLine>

                                    {getTypeByFeature(state.property) == 'House' ? (
                                        <LandZoning
                                            inDetails
                                            landValue={state.property.land_value_reset}
                                            closest_station={state.property.closest_station}
                                            zoning={state.property.prop_zoning}></LandZoning>
                                    ) : null}

                                    {getTypeByFeature(state.property) == 'Apt' ? (
                                        <ApartmentProjectInfo
                                            inDetails
                                            closest_station={state.property.closest_station}
                                            property={state.property}></ApartmentProjectInfo>
                                    ) : null}

                                    <CardLine></CardLine>
                                    {state.prop_ql.property && (
                                        <SchoolCard
                                            property_ql={{
                                                ...state.prop_ql.property,
                                                propX: state.property.prop_x,
                                                propY: state.property.prop_y,
                                            }}></SchoolCard>
                                    )}
                                    <Margin tm={10}></Margin>
                                    <CardLine></CardLine>
                                    <Margin tm={20}></Margin>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            width: '100%',
                                            alignItems: 'center',
                                        }}>
                                        <Button
                                            style={{ ...mobileNavButton, width: '80%', height: 40 }}
                                            onClick={showStreetDetail}
                                            variant="outlined">
                                            <TR name="getStreetProfile"></TR>
                                        </Button>
                                        <Margin tm={20}></Margin>
                                        <Button
                                            style={{ ...mobileNavButton, width: '80%', height: 40 }}
                                            onClick={showSuburbDetails}
                                            variant="outlined">
                                            <TR name="getSuburbProfile"></TR>
                                        </Button>
                                    </div>
                                    <div style={{ marginTop: 20 }}></div>
                                    <CardLine></CardLine>
                                    <AroundPriceCard
                                        isSummary={false}
                                        propId={propId}
                                        property={state.mixedPropObj}
                                        propType={getTypeByFeature(state.property)}
                                        lat={state.property.prop_y}
                                        lon={state.property.prop_x}></AroundPriceCard>
                                    <div style={{ marginTop: 20 }}></div>
                                    <CardLine></CardLine>

                                    {/* <AptHalfYearSold propId={propId} solds={state.suburbSolds}></AptHalfYearSold>
                                    <div style={{ marginTop: 20 }}></div>
                                    <CardLine></CardLine> */}

                                    {getTypeByFeature(state.property) == 'House' ? (
                                        <PropertyMobileCaculatorCard
                                            showSimilarTrans={openSimilarTrans}
                                            property={state.property}></PropertyMobileCaculatorCard>
                                    ) : null}

                                    {getTypeByFeature(state.property) == 'House' &&
                                    state.property &&
                                    state.showSimilarTrans ? (
                                        <>
                                            <SimilarHouseCard
                                                prop={state.mixedPropObj}
                                                property_ql={state.prop_ql}></SimilarHouseCard>
                                        </>
                                    ) : null}

                                    {getTypeByFeature(state.property) == 'Apt' ? (
                                        <>
                                            <ProjectSolds projectId={state.property.project_id}></ProjectSolds>
                                        </>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'center',
                                            margin: '10px 0px',
                                        }}>
                                        <Button
                                            style={{
                                                ...mobileNavButton,
                                                background: '#009e90',
                                                height: appProvider.getLanguage() == 'zh' ? '42px' : '60px',
                                                color: 'white',
                                                width: '80%',
                                            }}
                                            onClick={showDetails}
                                            variant="outlined">
                                            {state.loading ? <CircularProgress /> : null}
                                            {getTypeByFeature(state.property) == 'House' ? (
                                                <TR name="getPriceAndMoreData"></TR>
                                            ) : (
                                                <TR name="getMoreData"></TR>
                                            )}
                                        </Button>
                                    </div>

                                    {embedded ? null : (
                                        <div
                                            style={{
                                                marginTop: '20px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                width: '100%',
                                                alignItems: 'center',
                                                paddingBottom: 20,
                                            }}>
                                            <Button
                                                style={{ ...mobileNavButton, width: '80%', height: 40 }}
                                                onClick={showStreetDetail}
                                                variant="outlined">
                                                <TR name="getStreetProfile"></TR>
                                            </Button>
                                            <Margin tm={20}></Margin>
                                            <Button
                                                style={{ ...mobileNavButton, width: '80%', height: 40 }}
                                                onClick={showSuburbDetails}
                                                variant="outlined">
                                                <TR name="getSuburbProfile"></TR>
                                            </Button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    ) : null}
                </>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        minHeight: embedded ? 200 : 500,
                        alignItems: 'center',
                    }}>
                    <CircularProgress size={48}></CircularProgress>
                </div>
            )}

            <NoVerifyEmailReceived
                closeMessage={() => {
                    setShowVerifyEmail(false);
                }}
                showVerifyEmail={showVerifyEmail}></NoVerifyEmailReceived>

            <MemberInfo
                show={showMember}
                popType={popType}
                onSuccessAreaMember={() => {
                    setShowMember(false);
                    setShowWelcomeAreaMember(true);
                }}
                onClose={() => {
                    if (state.exceedLimit) {
                        history.push('/mobileHome');
                        window.open('/mobileHome', '_self');
                    } else {
                    }
                    setShowMember(false);
                }}></MemberInfo>

            <VariousPopup
                open={showWelcomeAreaMember}
                messageTitleKey={'welcomeAsAreaMember'}
                messageKey={'areaMemberBenefit'}
                onClose={() => setShowWelcomeAreaMember(false)}></VariousPopup>
        </>
    );
};

import { makeStyles } from '@material-ui/styles';
import { CircularProgress, CircularProgressProps, createStyles, Theme } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';

const useStylesFacebook = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            color: '#1a90ff',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }),
);

export const XCircularProgress = rst.createS<CircularProgressProps>((props) => {
    const classes = useStylesFacebook();

    return (
        <div className={classes.root}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={40}
                thickness={4}
                {...props}
            />
        </div>
    );
});

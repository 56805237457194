import ReactDOM from 'react-dom/server';
import React from 'react';
import { getLatlngArrayFromEncodedPath } from '../../../../commons/utils';
import { PriceLabel, SimilarTransMarker } from '../../../PriceMap/components/PriceLabel';
import { PropertyMarker } from '../../../PriceMap/MapContainer/PropertyMarker';

const win: any = window;
const L = win.L;

export class AroundSoldMap {
    mapInstance = null;
    constructor(mapInstance) {
        this.mapInstance = mapInstance;
    }

    addAroundTransMarkers(propCenter, trans, propType) {
        console.log(trans);
        L.circle([propCenter.lat, propCenter.lon], { radius: 1000, fill: false }).addTo(this.mapInstance);

        if (!trans || trans.length == 0) {
            return;
        }
        for (let idx = 0; idx < trans.length; idx++) {
            const loopProp = trans[idx];

            const markerProperty: any = {};
            markerProperty.lat = loopProp.lat;
            markerProperty.lon = loopProp.lon;
            markerProperty.propId = propType == 'House' ? loopProp.prop_id : loopProp.properties[0].prop_id;
            markerProperty.propType = propType;
            markerProperty.sold_contract_date = loopProp.sold_contract_date;
            markerProperty.prop_bed = loopProp.prop_bed;
            markerProperty.price = loopProp.sold_price;

            const propMarker = new PropertyMarker(markerProperty);
            propMarker.markerInMaxZoom = true;
            if (propType == 'House') {
                propMarker.setupMarkerByBeds();
            } else {
                propMarker.setIconByProeprtyType(false, true);
            }

            propMarker.getPropertyMarker().addTo(this.mapInstance);
        }
    }

    drawAround(propCenter, aroundSoldProperties) {
        L.circle([propCenter.lat, propCenter.lon], { radius: 1000, fill: false }).addTo(this.mapInstance);

        aroundSoldProperties.forEach((loopProperty, idx) => {
            const marker = L.marker([loopProperty.prop_y || loopProperty.lat, loopProperty.prop_x || loopProperty.lon]);

            const iconHTML = ReactDOM.renderToString(React.createElement(SimilarTransMarker, {}, idx + 1));

            const propertyIcon = new L.DivIcon({
                className: '',
                html: iconHTML,
            });

            marker.setIcon(propertyIcon).addTo(this.mapInstance);
        });
    }
}

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { isMobileDevice } from '../../../commons/utils';
import { XPanel } from '../../../components/Layout/XPanel';
import ReactTooltip from 'react-tooltip';

const useStyles = makeStyles((theme) => {
    return {
        pc: {
            width: 50,
            height: 50,
        },
        pc_hasArrow: {
            width: 57,
            height: 50,
        },
        mobile: {
            width: 40,
            height: 40,
        },
    };
});

export const MapControlButtonBase = rst.create<{
    icon: string;
    type?: string;
    marginLeft?: number;
    arrowPosition?: string;
    clickEvent?: any;
    width?: any;
    height?: any;
    tooltip?: string;
}>((ctx) => {
    return (props) => {
        const { arrowPosition } = props;

        const classes = useStyles();
        return (
            <div
                data-tip={props.tooltip}
                className={isMobileDevice() ? classes.mobile : arrowPosition ? classes.pc_hasArrow : classes.pc}
                style={{ marginLeft: props.marginLeft || 0 }}>
                <XPanel
                    cssStyle={{
                        background: 'white',
                        arrowPosition: arrowPosition,
                        borderColor: 'white',
                        radius: 6,
                    }}
                    clickEvent={props.clickEvent}>
                    <img
                        src={`assets/icons/${props.icon}.${props.type || 'svg'}`}
                        width={isMobileDevice() ? props.width || 12 : 18}
                    />
                </XPanel>
                <ReactTooltip />
            </div>
        );
    };
});

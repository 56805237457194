import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { ContentTitleData } from '../components/ContentTitleData';
import { Tips } from '../components/Tips';
import * as locales from '@material-ui/core/locale';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
            '& li': {
                fontSize: '13px',
                color: '#848383',
            },
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '5px 0',
        },
        partn: {
            textAlign: 'left',
            padding: '5px 0',
        },

        part2: {
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between',
            alignItems: 'flex-start',
            '& span': {
                fontSize: '14px',
            },
            '& p': {
                margin: '5px 0',
            },
        },
        part3: {
            //以下是横着两个
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'space-around',
            // alignItems: 'center',
            // padding: '5px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: '5px 0',
        },
        part3Content: {
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between',
            alignItems: 'center',
        },
        content: {
            padding: '5px 0px 5px 15px',
            margin: '0px',
        },
    };
});

export const Card10 = rst.create<{ property: any; mobile? }>((ctx) => {
    const appProvider = AppProvider.use();
    return (props) => {
        const classes = useStyles();
        const getShoppingRange = (value: number) => {
            if (value === 4 || value === 5) {
                return <TR name={'Business Planty'} />;
            }
            if (value === 3) {
                return <TR name={'Business Supermarket'} />;
            }
            if (value === 2) {
                return <TR name={'Business Shops'} />;
            }
            if (value === 1) {
                return <TR name={'Business Few'} />;
            }
        };
        const keyWords = props.property.property.locality.chineseKeyword;
        console.log(keyWords);
        const mapKeyWords = (value) => {
            if (value === null) {
                return (
                    <div className={classes.partn}>
                        <ContentTitleData header="Card10_Words" content="N/A" />
                    </div>
                );
            } else {
                return (
                    <div className={classes.part1}>
                        <ContentTitle hasArrow={true} header="Card10_Words" />
                        {value.map((keyWord) => {
                            return (
                                <div className={classes.content}>
                                    <ContentData content={keyWord} />
                                </div>
                            );
                        })}
                    </div>
                );
            }
        };
        const comments = props.property.property.locality.chineseComment;
        console.log(comments);
        const showComments = (value) => {
            if (value === null || value.length === 0) {
                return (
                    <div style={{ margin: '0 auto' }}>
                        <span>N/A</span>
                    </div>
                );
            } else {
                return value.map((comment) => {
                    return (
                        <p>
                            <span>{comment}</span>
                        </p>
                    );
                });
            }
        };
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader>
                    {appProvider.getLocale() === locales.zhCN ? (
                        <Tips header="Card_Tips" content="CARD10_TIPS" isSelect={true}></Tips>
                    ) : (
                        <Tips header="Card_Tips" isSelect={true}>
                            <li>
                                <TR name="CARD10_TIPS_P1" />
                            </li>
                            <li>
                                <TR name="CARD10_TIPS_P2" />
                            </li>
                            <li>
                                <TR name="CARD10_TIPS_P3" />
                            </li>
                        </Tips>
                    )}
                    {appProvider.getLocale() === locales.zhCN ? (
                        <>
                            {keyWords === null ? null : (
                                <>
                                    <div className={classes.part1}>
                                        <ContentTitle hasArrow={true} header="Card10_Words" />
                                        {keyWords.map((keyWord) => {
                                            return (
                                                <div className={classes.content}>
                                                    <ContentData content={keyWord} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <CardDivider />
                                </>
                            )}
                            {/* 以下是区域点评 请不要删 */}
                            {/* {comments === null || comments.length === 0 ? null : (
                                <>
                                    <div className={classes.part2}>
                                        <ContentTitle hasArrow={false} header="Card10_commits" />
                                        {comments.map((comment) => {
                                            return (
                                                <p>
                                                    <span>{comment}</span>
                                                </p>
                                            );
                                        })}
                                    </div>
                                    <CardDivider />
                                </>
                            )} */}
                        </>
                    ) : null}
                    <div className={classes.part3}>
                        <ContentTitleData
                            header="Card10_shop"
                            content={getShoppingRange(props.property.property.locality.rateShopping)}
                        />
                    </div>
                    <CardDivider />
                    <div className={classes.part3}>
                        {props.property.property.locality.shoppingCentre.distance.toFixed(1) === '0.0' ? (
                            <ContentTitleData
                                header="Card10_shoppingCenter"
                                content={`${props.property.property.locality.shoppingCentre.centreName}`}
                            />
                        ) : (
                            <ContentTitleData
                                header="Card10_shoppingCenter"
                                content={`${
                                    props.property.property.locality.shoppingCentre.centreName
                                } ${props.property.property.locality.shoppingCentre.distance.toFixed(1)}km`}
                            />
                        )}
                    </div>
                    {/* <div className={classes.part3}>
                        <div className={classes.part3Content}>
                            <ContentTitle hasArrow={false} header="Card10_shop" />
                            <ContentData content={getShoppingRange(props.property.property.locality.rateShopping)} />
                        </div>
                        <div className={classes.part3Content}>
                            <ContentTitle hasArrow={false} header="Card10_shoppingCenter" />
                            {props.property.property.locality.shoppingCentre.distance.toFixed(1) === '0.0' ? (
                                <ContentData
                                    content={`${props.property.property.locality.shoppingCentre.centreName}`}
                                />
                            ) : (
                                <ContentData
                                    content={`${
                                        props.property.property.locality.shoppingCentre.centreName
                                    } ${props.property.property.locality.shoppingCentre.distance.toFixed(1)}km`}
                                />
                            )}
                        </div>
                        <div className={classes.part3Content}>
                            <ContentTitle hasArrow={false} header="Card10_religion" />
                            <ContentData content="清真寺" />
                        </div>
                    </div> */}
                </CardWithHeader>
            </div>
        );
    };
});

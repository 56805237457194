import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TR } from '../../../commons';
import { ROUTES } from '../../../routes';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '91%',
        // height: '189px',
        margin: '11px 5px',
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'apace-between',
        alignItems: 'left',
        cursor: 'pointer',
        background: '#e8e8e8',
        borderRadius: '6px',
    },
    pictureCon: {
        width: '13%',
        maxWidth: '60px',
        position: 'relative',
        margin: '4.2% 0 0 4.2%',
        // borderRadius: '6px',
        // '&::before': { content: '""', display: 'block', paddingTop: '100%' },
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
    },
    picture: {
        // width: '13%',
        // margin: '2.7% 4.2% 4.2% 4.2%',
        borderRadius: '6px',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#065e85',
        aspectRatio: '1',
    },
    contentCon: {
        width: '67%',
        // height: '76px',
        margin: '2.7% 4.1% 3.7% 4.2%',
        // background: 'rgba(255,255,255,0.80)',
        // borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left',
    },
    title: {
        // width: '45%',
        // height: '60px',
        // margin: '2.7% 4.1% 3.7% 4.2%',
        fontSize: '16px',
        fontFamily: 'PingFangSC, PingFangSC-Semibold',
        fontWeight: 500,
        textAlign: 'center',
        color: '#333333',
        // background: '#065e85',
        // borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    content: {
        paddingTop: '2px',
        fontSize: '16px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        textAlign: 'left',
        color: '#333333',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'left',
    },
    arrow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export const DisplaySubInfoM = (props) => {
    const classes = useStyles();
    const { ranking, suburb, changePct, middlePrice, suburbId } = props;
    const handleOpenPriceMap = () => {
        window.open(`${ROUTES.PriceMap.path}?suburbId=${suburbId}&suburbName=${suburb}`, '_self');
    };
    const [suburbImg, setSuburbImg] = useState('');
    // useEffect(() => {
    //     getSuburbImage(suburb).then((suburbSrc) => {
    //         setSuburbImg(suburbSrc);
    //     });
    // }, [suburb]);
    const suburbName = suburb.replace(/ /g, '');
    return (
        <div className={classes.root} onClick={handleOpenPriceMap}>
            <div className={classes.pictureCon}>
                <div
                    className={classes.picture}
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/suburbImg/${suburbName}.jpg)`,
                    }}></div>
            </div>

            <div className={classes.contentCon}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={classes.title}>
                        {ranking}. {suburb}
                    </div>
                    <div className={classes.arrow}>
                        <img src="/assets/icons/arrow-ico.svg" alt="arrow" style={{ paddingLeft: '2px' }} />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {Number.isInteger(changePct) ? (
                        changePct > 0 ? (
                            <div className={classes.content}>
                                <img
                                    src="/assets/icons/rise-ico.svg"
                                    alt="rise arrow"
                                    style={{ paddingRight: '5px' }}
                                />
                                {'$' + Intl.NumberFormat('en-US').format(changePct)}
                            </div>
                        ) : (
                            <div className={classes.content}>
                                <img
                                    src="/assets/icons/decline-ico.svg"
                                    alt="decline arrow"
                                    style={{ paddingRight: '5px' }}
                                />
                                {'$' + Intl.NumberFormat('en-US').format(Math.abs(changePct))}
                            </div>
                        )
                    ) : changePct > 0 ? (
                        <div className={classes.content}>
                            <img src="/assets/icons/rise-ico.svg" alt="rise arrow" style={{ paddingRight: '5px' }} />
                            <span>{(changePct * 100).toFixed(0) + '%'}</span>
                        </div>
                    ) : (
                        <div className={classes.content}>
                            <img
                                src="/assets/icons/decline-ico.svg"
                                alt="decline arrow"
                                style={{ paddingRight: '5px' }}
                            />
                            {Math.abs(changePct * 100).toFixed(0) + '%'}
                        </div>
                    )}
                </div>

                <div className={classes.content}>
                    <TR name="MiddlePrice"></TR>: ${Intl.NumberFormat('en-US').format(middlePrice)}
                </div>
            </div>
        </div>
    );
};

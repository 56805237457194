import * as React from 'react';
import { Route, BrowserRouter, useHistory, Switch, Redirect } from 'react-router-dom';
import { SecurityProvider, storage } from './commons';
import { rst } from 'rt-state';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { CssBaseline } from '@material-ui/core';
import { env } from './environments';
import { XSnackbar } from './components/SnackBar';
import { AppProvider } from './commons/appProvider';
import { ROUTES } from './routes';
import { getParamValueFromURL, isMobileDevice } from './commons/utils';

// WARNING: don't delete noop
const noop = env;

const AuthComponent = rst.create<{ component?: any }>((ctx) => {
    const { isLoading, hasLogin, getUserProfile } = SecurityProvider.use();

    return (props) => {
        const history = useHistory();

        if (isLoading()) {
            return <div>loading</div>;
        }

        // TODO: uncomment it.
        if (!hasLogin()) {
            // const location = history.location;
            // const redirect = `${location.pathname}${location.search}${location.hash}`;
            // storage.save('redirect', redirect);
            return <Redirect to={ROUTES.HomePage.path} />;
        }

        return <props.component />;
    };
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App = rst.createS(
    () => {
        const appProvider = AppProvider.use();
        const securityProvider = SecurityProvider.use();
        const search = window.location.search;

        var addtionalURL = '';
        // if this is for veryify email link , then parsing the email and uuid
        if (search.indexOf('verifyEmail') > -1) {
            addtionalURL = search;
        }
        return (
            <ThemeProvider theme={appProvider.getTheme()}>
                <CssBaseline />
                <StylesProvider jss={jss}>
                    <BrowserRouter>
                        <Switch>
                            {Object.keys(ROUTES).map((k) => {
                                const { auth, path, component } = ROUTES[k];
                                let comp = component;
                                if (auth) {
                                    comp = () => <AuthComponent key={k} component={component} />;
                                }
                                return <Route key={k} path={path} exact component={comp} />;
                            })}
                            <Route path={'/'}>
                                {isMobileDevice() ? (
                                    <Redirect to={`${ROUTES.MobilePage.path}${addtionalURL}`} />
                                ) : (
                                    <Redirect to={`${ROUTES.HomePage.path}${addtionalURL}`} />
                                )}
                            </Route>
                        </Switch>
                    </BrowserRouter>
                    <XSnackbar controller={appProvider.snackbarController} />
                </StylesProvider>
            </ThemeProvider>
        );
    },
    { providers: [AppProvider, SecurityProvider] },
);

export default App;

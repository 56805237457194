import { rst } from 'rt-state';
import { TR } from '../../../commons/i18n';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import { XDialog, XDialogClass } from '../../../components/Dialog';
import { SuburbSearchContent } from './SuburbSearchContent';
import { PASSWORD_SCHEMA, USERNAME_SCHEMA } from '../../auth/common';
import { createXForm } from '../../../components/form';
import { SimpleSuburbSearch } from './SimpleSuburbSearch';
import { ALL_FILTER_OPTIONS } from '../common';
import { SearchState } from '.';
import { clsx } from '../../../commons';
import { TuneOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => {
    return {
        button: {
            height: '46px',
            borderRadius: '10px',

            fontSize: '16px',
            fontFamily: theme.typography.fontFamily,
        },
        searchButton: {
            backgroundColor: '#1875f0',
            width: '110px',
            color: 'white',
        },
        modifyButton: {
            borderColor: theme.palette.primary.main,
            width: '170px',
            backgroundColor: 'white',
            color: theme.palette.primary.main,
        },

        searchDialogTitle: {
            fontSize: '24px',
        },
        searchDialogButton: {
            fontSize: '16px',
        },
        dummy: {},
    };
});

// const initialValues = {
//     percentageChinese: 1,
//     publicSchoolScore: null,
//     hasTrainStation: false,
//     distanceCBD: null,
// };
//
// const validationSchema = yup.object().shape({
//     percentageChinese: yup.number().required('required'),
//     publicSchoolScore: yup.number().required('required'),
//     hasTrainStation: yup.boolean().required('required'),
//     distanceCBD: yup.number().required('required'),
// });

const initialValues = {};

const simpleSearchInitialValues = {
    chinesePercentage: '2',
    primarySchoolRate: '1',
};
const validationSchema = {
    // price: number(true).min(10).max(50),
    // username: USERNAME_SCHEMA,
    // password: PASSWORD_SCHEMA,
};

export const SuburbSearch = rst.create<{ onSearchButtonClick: any }>((ctx) => {
    const formInitValues: any = {};
    Object.keys(ALL_FILTER_OPTIONS).forEach((loopFilterKey) => {
        ALL_FILTER_OPTIONS[loopFilterKey].forEach((loopOption) => {
            if (loopOption['fieldName']) {
                if (loopOption.defaultValue) {
                    formInitValues[loopOption['fieldName']] = loopOption.defaultValue;
                } else {
                    formInitValues[loopOption['fieldName']] = '1';
                }
            }
        });
    });
    const state = rst.state<SearchState>({
        simpleSearch: false,
        complexSearch: false,
        searching: false,
        showSearchResult: false,
        lastSearchType: 'S',
    });

    const form = createXForm({
        initialValues: formInitValues,
        validate: validationSchema,
        onSubmit,
    });
    const simpleForm = createXForm({
        initialValues: simpleSearchInitialValues,
        validate: validationSchema,
        onSubmit,
    });
    function onSubmit(data: any) {
        ctx.props.onSearchButtonClick(data);
        toggleSimpleSearch(false);
        toggleComplex(false);
        state.showSearchResult = true;
    }

    function toggleComplex(isOpen: boolean) {
        state.complexSearch = isOpen;
    }

    function toggleSimpleSearch(isOpen: boolean) {
        state.simpleSearch = isOpen;
    }

    function switchToComplex() {
        toggleSimpleSearch(false);
        toggleComplex(true);
        state.lastSearchType = 'C';
    }

    function modifyFilter() {
        if (state.lastSearchType == 'C') {
            toggleComplex(true);
        } else {
            toggleComplex(true);
        }
    }

    return (props) => {
        const classes = useStyles(props);
        const dialogClasses: XDialogClass = {
            title: classes.searchDialogTitle,
            button: classes.searchDialogButton,
        };

        const getHeadContent = () => {
            if (state.showSearchResult) {
                return (
                    <>
                        <Button
                            variant="outlined"
                            size="medium"
                            startIcon={<TuneOutlined />}
                            className={clsx(classes.button, classes.modifyButton)}
                            onClick={() => modifyFilter()}>
                            <TR name={'modify filter'} />
                        </Button>
                    </>
                );
            } else {
                return (
                    <>
                        <Button
                            variant="contained"
                            size="medium"
                            color={'primary'}
                            className={clsx(classes.button, classes.searchButton)}
                            onClick={() => toggleSimpleSearch(true)}>
                            <TR name={'Suburb Search'} />
                        </Button>
                    </>
                );
            }
        };

        return (
            <>
                {getHeadContent()}

                <XDialog
                    form={simpleForm}
                    title={'SIMPLE SEARCH TITLE'}
                    open={state.simpleSearch}
                    dialogClasses={dialogClasses}
                    onCancel={() => toggleSimpleSearch(false)}>
                    <SimpleSuburbSearch switchToComplex={switchToComplex} form={simpleForm} />
                </XDialog>

                <XDialog
                    form={form}
                    title={'COMPLEX SEARCH TITLE'}
                    open={state.complexSearch}
                    dialogClasses={dialogClasses}
                    onCancel={() => toggleComplex(false)}>
                    <SuburbSearchContent form={form} />
                </XDialog>
            </>
        );
    };
});

import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { useRunOnce } from '../../../commons/hooks';
import { isMobileDevice } from '../../../commons/utils';
const useStyles = makeStyles((theme) => {
    return {
        chartTitleDiv: {
            display: 'flex',
        },
        chartTitle: {
            fontSize: 14,
            color: '#333333',
            fontWeight: 500,
        },
        legend: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        legendItem: {
            width: '11px',
            height: 8,
        },
    };
});
/*"locality_trends": {"year": [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019], "median": [360000, 422000, 460000, 515000, 610000, 625000, 713500, 721000, 730000, 710000]}, "city_trends": {"year": [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019], "median": [480000, 485000, 520000, 555000, 625000, 698000, 720000, 730000, 725000, 700000]}*/

declare var Highcharts;

const chartColor = {
    sydney: '#ff9f40',
    council: '#5ad8a6',
    local: '#5b8ff9',
};

export const SuburbTenYearTrend = rst.create<{
    sydneyData?: any;
    councilData?: any;
    localData?: any;
    suburbName?: string;
    councilName?: string;
    mobile?: boolean;
}>((ctx) => {
    console.log(ctx.props.localData);
    const sampleData = {
        sydneyData: {
            yearly_median: [515000, 610000, 580000, 620000, 660000, 739000, 870000, 878000, 931000, 980000],
            year: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019],
        },
    };
    const x_catagories = [];

    ctx.props.localData.year.forEach((value, idx) => {
        if (idx == 0) {
            x_catagories.push(value);
        } else {
            x_catagories.push((value + '').substr(2, 2));
        }
    });
    function loadChart() {
        Highcharts.chart('suburbTrendContainer', {
            chart: {
                height: 120,

                marginBottom: isMobileDevice() ? 30 : 20,
            },
            title: {
                text: '',
            },

            subtitle: {
                text: '',
            },

            yAxis: {
                title: {
                    text: '',
                },
                min: 0,
                max: Math.max(...ctx.props.sydneyData.yearly_median.concat(ctx.props.localData.yearly_median)) * 1.2,
            },

            xAxis: {
                accessibility: {
                    rangeDescription: '',
                },
                type: 'category',
                categories: x_catagories,
                crosshair: true,
                endOnTick: false,
                startOnTick: false,
            },

            legend: {
                enabled: false,
            },
            credits: { enabled: false },
            tooltip: {
                enabled: true,
                backgroundColor: 'white', // 背景颜色
                borderColor: 'black', // 边框颜色
                borderRadius: 10, // 边框圆角
                borderWidth: 1, // 边框宽度
                animation: true, // 是否启用动画效果
                //format the date and numbers
                formatter: function () {
                    const _this: any = this;
                    console.log(_this);
                    var d = new Date(_this.x);

                    var s = '<b>' + (_this.x.length == 2 ? '20' + _this.x : _this.x) + '</b>';
                    s += '<br/><span>Price: ' + '$' + (_this.y / 1000000).toFixed(2) + 'm' + '</span>';
                    return s;
                },
            },
            plotOptions: {
                series: {
                    enableMouseTracking: true,
                    marker: {
                        enabled: false,
                    },
                },
                areaspline: {
                    fillOpacity: 0.25,
                },
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 2,
                    opacity: 1,

                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666',
                    },
                },
            },

            series: [
                {
                    name: 'Sydney',
                    data: ctx.props.sydneyData.yearly_median,
                    color: chartColor.sydney,
                    fillOpacity: 0.3,
                    opacity: 1,
                    lineColor: chartColor.sydney,
                },
                {
                    name: 'Local',
                    color: chartColor.local,
                    fillOpacity: 0.3,
                    data: ctx.props.localData.yearly_median,
                    opacity: 1,
                    lineColor: chartColor.local,
                },
            ],
        });
    }
    const processChartData = () => {
        console.log('Will process chart data');
    };

    return (props) => {
        setTimeout(() => {
            processChartData();
            loadChart();
        }, 400);
        const classes = useStyles();
        return (
            // the reason set class name is to hide before showing the right chart
            <div className="HOMEON_Chart" style={{ minHeight: '160px' }} id="tenYearsTrendChart">
                <div className={classes.chartTitleDiv}>
                    <div className={classes.chartTitle}>
                        {props.mobile ? (
                            <TR name="TEN_YEAR_TREND_CHART_Mobile"></TR>
                        ) : (
                            <TR name="TEN_YEAR_TREND_CHART"></TR>
                        )}
                    </div>
                    <div className={classes.legend}>
                        <div className={classes.legendItem} style={{ background: chartColor.sydney }}></div>
                        <div style={{ marginLeft: 7 }}>
                            <TR name="SYDNEY_OVERALL"></TR>
                        </div>

                        <div style={{ marginLeft: 17 }}></div>

                        <div className={classes.legendItem} style={{ background: chartColor.local }}></div>
                        <div style={{ marginLeft: 7 }}>{props.suburbName}</div>
                    </div>
                </div>

                <div style={{ marginTop: 15 }}></div>
                <div id="suburbTrendContainer"> </div>
            </div>
        );
    };
});

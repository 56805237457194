import { storage } from './storage';

export var global_screenSize = { width: 0, height: 0 };
export var global_styles = {} as any;

export const initApp = async () => {
    /**
     * 取得屏幕的尺寸
     */
    global_screenSize = {
        width: window.screen.width,
        height: window.screen.height,
    };

    /**
     * 设置全局样式
     */

    global_styles = {
        mainColor: '#2C7BF6',
        unselectedMenuItemColor: '#000',
        reverseColor: '#fff',
    };
};

import React from 'react';
import { rst } from 'rt-state';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { TR } from '../../../commons';
import { ROUTES } from '../../../routes';

const useStyles = makeStyles((theme) => {
    return {
        button: {
            height: '46px',
            borderRadius: '10px',

            fontSize: '16px',
            fontFamily: theme.typography.fontFamily,
        },
        searchButton: {
            backgroundColor: '#1875f0',
            width: '110px',
            color: 'white',
        },
        modifyButton: {
            borderColor: theme.palette.primary.main,
            width: '170px',
            backgroundColor: 'white',
            color: theme.palette.primary.main,
        },

        searchDialogTitle: {
            fontSize: '24px',
        },
        searchDialogButton: {
            fontSize: '16px',
        },
        dummy: {},
    };
});
export const HelpComponent = rst.create((ctx: any) => {
    const state = rst.state({
        help: false,
    });
    function handleClick(isOpen: boolean) {
        state.help = isOpen;
        console.log('wo!!!!!');
    }
    function handleOpenMember(isOpen: boolean) {
        state.help = isOpen;
        window.open(`${ROUTES.JoinMember.path}`);
    }
    return (props: { content: string; isText?: boolean; maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' }) => {
        const classes = useStyles(props);
        return (
            <div>
                {props.isText ? (
                    <div
                        style={{
                            marginLeft: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 20,
                            height: 20,
                            border: '1px solid grey',
                            borderRadius: '50%',
                            fontSize: 14,
                            cursor: 'hand',
                        }}
                        onClick={() => handleClick(true)}>
                        ?
                    </div>
                ) : (
                    <IconButton onClick={() => handleClick(true)}>
                        <HelpOutlineIcon fontSize="small" style={{ color: 'rgb(166 166 166)' }} />
                    </IconButton>
                )}
                <Dialog
                    onClose={() => handleClick(false)}
                    aria-labelledby="customized-dialog-title"
                    open={state.help}
                    maxWidth={props.maxWidth}
                    fullWidth={true}>
                    <DialogTitle>
                        <TR name={'Tips'} />
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <TR name={props.content} />
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        {props.content === 'Expired_membership' ? (
                            <Button autoFocus onClick={() => handleOpenMember(false)} color="primary">
                                <TR name={'Pls_renew'} />
                            </Button>
                        ) : (
                            <Button autoFocus onClick={() => handleClick(false)} color="primary">
                                <TR name={'Get it'} />
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </div>
        );
    };
});

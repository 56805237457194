import { getLatlngArrayFromEncodedPath } from '../../../../commons/utils';
import ReactDOM from 'react-dom/server';
import React from 'react';
import { schoolMapInfo } from './SchooMapInfo';

const win: any = window;
const L = win.L;

const schoolColors = [
    {
        rank: '#ded71c',
    },
    {
        rank: '#1cdec5',
    },
    {
        rank: '#f77171',
    },
    {
        rank: '#3cde1c',
    },
    {
        rank: '#e6297d',
    },
    {
        rank: '#f8d800',
    },
    {
        rank: '#0086f8',
    },
    {
        rank: '#3dd8cf',
    },
];

export class SchoolCatchmentMap {
    mapInstance = null;
    constructor(mapInstance) {
        this.mapInstance = mapInstance;
    }

    drawCatchment(drawingData) {
        const overAllBounds = [];
        console.log(drawingData);
        const schoolCatchmentData = drawingData.schoolData;
        const localityPolygonData = drawingData.localityData;
        localityPolygonData.forEach((loopSuburb) => {
            console.log(loopSuburb);

            const loopSuburbPolygonPath = getLatlngArrayFromEncodedPath(loopSuburb);

            const suburbPolygon = L.polygon(loopSuburbPolygonPath, {
                //fillColor: polygonFillColor,
                //color: suburbColor,
                color: '#FF0303',
                stoke: true,
                weight: 2,
                opacity: 1,
            }).addTo(this.mapInstance);

            overAllBounds.push(suburbPolygon._bounds._northEast);
            overAllBounds.push(suburbPolygon._bounds._southWest);
        });

        schoolCatchmentData.forEach((loopSchool, idx) => {
            console.log(idx);
            var catchmentPolygon: any = null;
            loopSchool.polyEncoded.forEach((loopCatchment, idx1) => {
                const catchmentPath = getLatlngArrayFromEncodedPath(loopCatchment);

                catchmentPolygon = L.polygon(catchmentPath, {
                    fillColor: schoolColors[idx].rank,
                    fillOpacity: 0.4,
                    //color: suburbColor,

                    stoke: false,
                    weight: 0,
                    opacity: 0,
                }).addTo(this.mapInstance);
            });

            const marker = L.marker(catchmentPolygon.getBounds().getCenter());

            const iconHTML = ReactDOM.renderToString(
                React.createElement(schoolMapInfo, {
                    schoolName: loopSchool.schoolName,
                    background: schoolColors[idx].rank,
                    rank: loopSchool.school.ranking,
                    starRating: loopSchool.school.starRating ? loopSchool.school.starRating : 1,
                }),
            );

            const propertyIcon = new L.DivIcon({
                className: '',
                html: iconHTML,
            });
            // console.log(propertyIcon);
            marker.setIcon(propertyIcon).addTo(this.mapInstance);
        });

        console.log(overAllBounds);
        this.mapInstance.fitBounds(overAllBounds);
        console.log(schoolCatchmentData);
    }
}

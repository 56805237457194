import * as jwtDecode from 'jwt-decode';
import { TimedRoles } from './authz';

export interface TokenInfo {
    token?: string;
    valid?: boolean;
    sub?: string;
    roles?: TimedRoles; // not used in the frontend

    iat?: number;
    exp?: number;
}

export const decodeToken = (token: string): TokenInfo => {
    try {
        const jwtObject = jwtDecode.default(token);
        const tokenInfo = {
            token,
            valid: true,
            sub: jwtObject.sub,
            displayName: jwtObject.displayName,
            roles: jwtObject.roles,

            iat: jwtObject.iat,
            exp: jwtObject.exp,
        };
        const expTime = new Date(jwtObject.exp * 1000);
        if (new Date() < expTime) {
            return tokenInfo;
        }
    } catch (e) {
        // console.log(e);
    }

    return { token, valid: false };
};

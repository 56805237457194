import React from 'react';
import { rst } from 'rt-state';
import { AppBar, Box, Button, Fab, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useStyles } from './SuburbHead.styles';
import Hidden from '@material-ui/core/Hidden';
import { SuburbSearch } from '../SuburbSearch';
import Grid from '@material-ui/core/Grid';
import { CategoryTabs, CategoryTabsProps } from '../../../components/Tabs';
import { ALL_TAB_OPTIONS } from '../common/allTabOptions';

export const SuburbHead = rst.create<{
    onMenuButtonClick: () => void;
    categoryTabsProps: Pick<CategoryTabsProps, 'value' | 'onSelect'>;
    onSearchButtonClick: () => void;
}>((ctx) => {
    const state = rst.state({ appStatus: '1' });

    /**
     * When search dialog the 'search'button clicked, the header should show the selected filter
     */
    function suburbSearchButtonClick(searchedConditions) {
        console.log(searchedConditions);
        state.appStatus = '2';
        ctx.props.onSearchButtonClick();
    }

    return (props) => {
        const classes: any = useStyles(props);
        const { categoryTabsProps } = props;
        const switchAppStatus = () => {
            if (state.appStatus == '1') {
                return (
                    <>
                        <CategoryTabs
                            value={categoryTabsProps.value}
                            options={ALL_TAB_OPTIONS}
                            isPrimary={true}
                            onSelect={categoryTabsProps.onSelect}
                        />
                    </>
                );
            }
        };

        return (
            <>
                <AppBar position={'static'} className={classes.appBar}>
                    <Toolbar>
                        <Grid container alignItems={'center'}>
                            <Grid container item alignItems={'center'} md={4} xs={12}>
                                <div>
                                    <IconButton color="inherit" onClick={props.onMenuButtonClick}>
                                        <MenuIcon className={classes.menuIcon} />
                                    </IconButton>
                                </div>
                                <Box className={classes.logo}>
                                    <img src={`${process.env.PUBLIC_URL}/static/images/logo.svg`} />
                                </Box>
                            </Grid>
                            <Grid container item alignItems={'center'} justify={'flex-end'} md={8} xs={12}>
                                {switchAppStatus()}

                                <Hidden xsDown implementation={'css'}>
                                    <SuburbSearch onSearchButtonClick={suburbSearchButtonClick} />
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </>
        );
    };
});

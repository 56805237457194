import { Chip, makeStyles } from '@material-ui/core';
import ReactDOM from 'react-dom';
import React from 'react';
import { rst } from 'rt-state';
import { StreetView } from '../../PropertyInfo';
import { getDatePart, getItemFromArray, isMobileDevice, numToMillion, priceToK } from '../../../../../commons/utils';
import { TR, TRHtml } from '../../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../../commons/EventBus';
import { BedOptions, MapGlobalVars } from '../../../support/PriceMapConsts';
import { SchoolOrStationScroll } from '../SchoolOrTrainStation/SchoolOrStationScrolls';
import { showGoogleResultByAddress } from '../../../support/propertyFeatureHelper';
import { UnitCards } from './UnitCards';
import { SchoolOrStationSimple } from '../SchoolOrTrainStation/SchoolOrStationSimple';
import { CommonSchoolOrStation } from '../SchoolOrTrainStation';
import { ShareOrFavOrOther } from '../ShareOrFavOrOther';
import { suburbDataList } from '../../../MapContainer/SuburbPolygonController';
import { useRunOnce } from '../../../../../commons/hooks';
import { AppProvider } from '../../../../../commons/appProvider';
import * as locales from '@material-ui/core/locale';

const useStyles = makeStyles((theme) => {
    return {
        unitAddr: {
            fontSize: isMobileDevice() ? 13 : 16,
            fontWeight: 500,
            color: '#333333',
            display: 'flex',
            alignItems: 'center',
            paddingRight: 20,
        },
        unitMedian: {
            fontSize: isMobileDevice() ? 12 : 14,
            fontWeight: 700,
            color: '#333333',
            flexGrow: 1,
            display: 'flex',
        },
        unitPrice: {
            fontSize: 16,
            color: '#e02020',
            fontWeight: 400,
        },
        soldUnitTitle: {
            fontSize: isMobileDevice() ? 13 : 14,
        },
    };
});

export const UnitInfoSidebarBasic = rst.create<{ prop: any; onMap?: boolean }>((ctx) => {
    // received by propertymarkercontroller
    const appProvider = AppProvider.use();

    return (props) => {
        const { prop } = props;
        const classes = useStyles();

        if (props.onMap) {
            setTimeout(() => {
                document.getElementById('unitAddressDiv').addEventListener('click', () => {
                    showGoogleResultByAddress(prop.address);
                });
            }, 1000);
        }

        const getFilteredBedDesc = () => {
            const filteredBedOption = getItemFromArray(
                BedOptions?.Apt,
                'value',
                MapGlobalVars.priceMapFilter?.bedOptions || 0,
            );
            if (filteredBedOption && filteredBedOption.value != 0) {
                return <TR name={filteredBedOption.label}></TR>;
            } else {
                return <></>;
            }
        };

        const googleSearchEvt = () => {
            showGoogleResultByAddress(prop.address);
        };

        const getSuburbRental = () => {
            const suburbData = getItemFromArray(suburbDataList, 'id', props.prop?.allFeatures.locality_id);

            return <>${suburbData?.half_year_2br_apt_rent}</>;
        };
        return (
            <>
                <div style={{ paddingRight: isMobileDevice() ? 10 : 0 }}>
                    <div style={{ display: 'flex' }}>
                        <StreetView
                            lat={props.prop?.lat}
                            lng={props.prop?.lon}
                            w={!isMobileDevice() ? 135 : 100}
                            h={!isMobileDevice() ? 124 : 100}
                            showFor="infopanel"
                            address={prop?.address}></StreetView>
                        <div style={{ marginLeft: 10 }}></div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div className={classes.unitAddr} style={{ paddingRight: props.onMap ? 20 : 0 }}>
                                <div id="unitAddressDiv">
                                    {prop?.address}
                                    {prop?.builtYear ? '(' : ''}
                                    {appProvider?.getLocale() === locales.zhCN ? (
                                        <>
                                            {prop?.builtYear ? getDatePart(prop.builtYear, 'Y') : ''}
                                            {prop?.builtYear ? <TR name={'MOVEIN'}></TR> : ''}
                                        </>
                                    ) : (
                                        <>
                                            {prop?.builtYear ? <TR name={'MOVEIN'}></TR> : ''}
                                            {prop?.builtYear ? getDatePart(prop.builtYear, 'Y') : ''}
                                        </>
                                    )}
                                    {prop?.builtYear ? ')' : ''}
                                </div>
                                {prop ? <ShareOrFavOrOther prop={prop}></ShareOrFavOrOther> : null}
                            </div>
                            <div style={{ marginTop: isMobileDevice() ? 3 : 10 }}></div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    alignItems: 'center',
                                }}>
                                <div className={classes.unitMedian}>
                                    <TRHtml
                                        html="APT_SIDEBAR_SOLD_MEDIAN1"
                                        params={{ period: MapGlobalVars.priceMapFilter?.period || 6 }}></TRHtml>

                                    <TRHtml html="APT_SIDEBAR_SOLD_MEDIAN2"></TRHtml>
                                </div>

                                <div className={classes.unitPrice}>
                                    {prop?.medianPrice == 0 || !prop?.medianPrice ? (
                                        <TR name="projectNoSold"></TR>
                                    ) : (
                                        <span style={{ fontWeight: 900 }}> {priceToK(prop?.medianPrice)}</span>
                                    )}
                                </div>
                            </div>

                            <div style={{ marginTop: isMobileDevice() ? 3 : 10 }}></div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}>
                                <div>
                                    <div>
                                        <TR name="ProjectScale"></TR>
                                    </div>
                                    <div>{prop?.allFeatures?.number_of_lots || '-'}</div>
                                </div>
                                <div>
                                    <div>
                                        <TR name="builder"></TR>
                                    </div>
                                    <div>
                                        {!prop?.allFeatures?.developer || prop?.allFeatures?.developer == '0'
                                            ? '-'
                                            : prop.allFeatures?.developer}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <TR name="2BedsRental"></TR>
                                    </div>
                                    <div>{getSuburbRental()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {prop ? <CommonSchoolOrStation prop={prop}></CommonSchoolOrStation> : null}
                </div>

                {prop?.units && prop?.units.length > 0 ? (
                    <>
                        <hr style={{ backgroundColor: '#d8d8d8', height: 1, border: 'none' }}></hr>
                        <div style={{ marginLeft: isMobileDevice() ? 5 : 10 }}></div>
                        <div className={classes.soldUnitTitle}>
                            <TRHtml
                                html="soldUnitsTitle"
                                params={{ period: MapGlobalVars.priceMapFilter.period || 6 }}></TRHtml>
                        </div>
                        <div style={{ display: 'flex', overflowX: 'auto' }}>
                            <UnitCards googleSearchEvt={googleSearchEvt} units={prop.units}></UnitCards>
                        </div>
                    </>
                ) : null}
            </>
        );
    };
});

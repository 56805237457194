import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { rst } from 'rt-state';
import { NewNewContentItem } from './NewNewContentItem';
import { isMobileDevice } from '../../../commons/utils';
import { AppProvider } from '../../../commons/appProvider';
import * as locales from '@material-ui/core/locale';

const useStyles = makeStyles((theme) => ({
    content: {
        margin: '0 auto',
        width: '1200px',
        [theme.breakpoints.down('sm')]: {
            padding: '28px 16px',
            width: '100vw',
        },
    },
}));
const NewHome_NewNewContentTextTextContent1 = [
    'NewHome_NewNewContentTextTextContent1_1',
    'NewHome_NewNewContentTextTextContent1_1b',
    'NewHome_NewNewContentTextTextContent1_2',
    'NewHome_NewNewContentTextTextContent1_3',
    // 'NewHome_NewNewContentTextTextContent1_4',
    // 'NewHome_NewNewContentTextTextContent1_5',
];
export const NewNewHomeContent = rst.create((ctx) => {
    const appProvider = AppProvider.use();

    return (props) => {
        const classes = useStyles();
        return (
            <>
                <div style={{ background: 'rgb(249 249 249)' }}>
                    <div className={classes.content}>
                        <NewNewContentItem
                            img={appProvider.getLanguage() == 'zh' ? 'tp-4' : 'tp-4en'}
                            header="NewHome_NewNewContentTextHeader1"
                            // subHeader="NewHome_NewContentTextSubHeader1"
                            // hasBackText="NewHome_NewContentTextHasBackText1"
                            textContent={NewHome_NewNewContentTextTextContent1}
                            textLeft={isMobileDevice() ? false : true}
                            background="rgb(249 249 249)"
                            hasButton={false}
                            video={appProvider.getLocale() === locales.zhCN ? '4' : '5'}
                        />
                    </div>
                </div>
            </>
        );
    };
});

import { ENV } from './common';

export const devEnv: ENV = {
    // update dev ts file to dev
    apiHost: 'https://abstg.api.aihouse.io',
    USER_API_HOST: 'https://abstg.api.aihouse.io',

    // apiHost: 'http://192.168.1.101:8000',
    // USER_API_HOST: 'http://localhost:8080',
    // USER_API_HOST: 'http://192.168.1.109:8080',

    ql_token:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyODc4NDEzMzQ5NDgwMTIwMzMiLCJyb2xlcyI6eyI0IjoxNjcyNDkxNjAwMDAwfSwiaWF0IjoxNjUwODg0MDEyLCJleHAiOjE2NTYwNjgwMTJ9.bPd7q2AwDybR6ei5Wfiji0F_pvTE8T-wpEjHnIPCxrw',
    // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyODAzMDYwMTUzNDAwNzI5NjEiLCJyb2xlcyI6eyIyIjoxNjcyNDA1MjAwMDAwfSwiaWF0IjoxNjQ5OTA3OTA3LCJleHAiOjE2NTUwOTE5MDd9.Z5y0_Ih3RdL_WUIYq_Ei1hAuZ7zQpR8qMg7Wkeskxyg',

    stripeKey:
        //     'pk_test_51HVTK6AD12lyhQjmsarPl6u9tqzsUAyB8wtEQdTISMezCdlwpBmRtaV1p3TEfK97sal8owYaNtB2YsmmsSmDAaSj009CyrWYJt',

        'pk_live_51HVTK6AD12lyhQjm2dyFYqkGyMXRhzShr7QXCdICrEs6t2p4EhdPsZbbQWlCDk6tOEGyM26Lkl6yBmFsZdP6MMKW00iOSVANUd',
    signUpExpireHours: 24,
};

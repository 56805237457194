import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, Container, DialogTitle, Dialog, Paper } from '@material-ui/core';
import CheckoutForm from './CheckoutForm';
import SubscriptionList from './SubscriptionList';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import './formStyle.css';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { API } from '../../commons';
import { env } from 'process';

const promise = loadStripe(
    'pk_test_51HVTK6AD12lyhQjmsarPl6u9tqzsUAyB8wtEQdTISMezCdlwpBmRtaV1p3TEfK97sal8owYaNtB2YsmmsSmDAaSj009CyrWYJt',
);

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 550,
        fontSize: 17,
    },
    botton: {
        margin: theme.spacing(3),
    },
    input: {
        width: 350,
    },
    'button-parent': {
        'text-align': 'right',
    },
    'payment-method': {
        padding: 30,
        fontSize: 16,
    },
    'whole-page': {
        marginTop: 20,
    },
}));

function createData(name, period, price) {
    return { name, period, price };
}

const rows = [createData('VIP Membership', 90, 99)];
const subscriptionRows = [createData('Monthly', 'Per Month', 29), createData('Yearly', 'Per Year', 269)];
const cardRows = [
    createData('Payment succeeds', '4242 4242 4242 4242', null),
    createData('Payment requires authentication', '4000 0025 0000 3155', null),
    createData('Payment is declined', '4000 0000 0000 9995', null),
];

export default function MemberShipDemo() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [showSubscriptions, setShowSubscriptions] = useState(false);
    const [plan, setPlan] = useState('');

    const cancelSub = () => {
        API.delete(null, env.apiHost + '/payment/subscription', JSON.stringify({ subuscriptionId: '' }), {
            'Content-Type': 'application/json',
            Authorization:
                '11eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyOTMzNjUzODIxNDI1MjU0NDAiLCJyb2xlcyI6eyIyIjoxNjQ4NjQ0NTU3NTY3fSwiaWF0IjoxNjQ3NzgwNTU3LCJleHAiOjE2NTI5NjQ1NTd9.eA4uBI8vkiNchmP4sVgtN0_DtA4Wp6FIZBPBhN__rwg',
        }).then((data: any) => {});
    };

    return (
        <Container maxWidth="md" className={classes['whole-page']}>
            <Paper>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Membership Name</TableCell>
                                <TableCell align="right">Period</TableCell>
                                <TableCell align="right">Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.period} days</TableCell>
                                    <TableCell align="right">${row.price}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <div className={classes['button-parent']}>
                <Button
                    onClick={() => setOpen(true)}
                    className={classes.botton}
                    size="large"
                    color="primary"
                    variant="contained"
                    startIcon={<ShoppingCartIcon />}>
                    Buy Now
                </Button>
            </div>
            <Paper>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Subscription Name</TableCell>
                                <TableCell align="right">Period</TableCell>
                                <TableCell align="right">Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {subscriptionRows.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.period}</TableCell>
                                    <TableCell align="right">${row.price}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <div className={classes['button-parent']}>
                <Button
                    onClick={() => setPlan('month')}
                    className={classes.botton}
                    size="large"
                    color="primary"
                    variant="contained"
                    startIcon={<ShoppingCartIcon />}>
                    Monthly Subscription
                </Button>
                <Button
                    onClick={() => setPlan('year')}
                    className={classes.botton}
                    size="large"
                    color="primary"
                    variant="contained"
                    startIcon={<ShoppingCartIcon />}>
                    Yearly Subscription
                </Button>

                <Button
                    onClick={() => setShowSubscriptions(true)}
                    className={classes.botton}
                    size="large"
                    color="primary"
                    variant="contained"
                    startIcon={<ShoppingCartIcon />}>
                    Show Subscriptions
                </Button>
            </div>
            <Paper className={classes['payment-method']}>
                <h2>Make a test payment</h2>
                <p>
                    Use a test card number to try your integration. These card numbers work in test mode with any CVC,
                    postal code, and future expiry date.
                </p>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            {cardRows.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.period}</TableCell>
                                    <TableCell align="right">{row.price}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Dialog onClose={() => setOpen(false)} aria-labelledby="payment-dialog-title" open={open}>
                <DialogTitle id="payment-dialog-title">Make a payment</DialogTitle>
                <Container className={classes.input}>
                    <Elements stripe={promise}>
                        <CheckoutForm
                            products={{ productId: 10000001 }}
                            paymentStatus={(status) => {
                                console.log(status);
                            }}
                        />
                    </Elements>
                </Container>
            </Dialog>
            <Dialog onClose={() => setPlan('')} aria-labelledby="payment-dialog-title" open={plan.length > 0}>
                <DialogTitle id="payment-dialog-title">Make a payment</DialogTitle>
                <Container className={classes.input}>
                    <Elements stripe={promise}>
                        <CheckoutForm
                            plan={plan}
                            paymentStatus={(status) => {
                                console.log(status);
                            }}
                        />
                    </Elements>
                </Container>
            </Dialog>

            <Dialog
                onClose={() => setShowSubscriptions(false)}
                aria-labelledby="payment-dialog-title"
                open={showSubscriptions}>
                <DialogTitle id="payment-dialog-title">Subscriptions</DialogTitle>
                <Container className={classes.input}>
                    <Elements stripe={promise}>
                        <SubscriptionList />
                    </Elements>
                </Container>
            </Dialog>

            <Button
                onClick={() => cancelSub()}
                className={classes.botton}
                size="large"
                color="primary"
                variant="contained"
                startIcon={<ShoppingCartIcon />}>
                Cancel Subscription
            </Button>
        </Container>
    );
}

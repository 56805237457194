import React from 'react';
import { rst } from 'rt-state';
import { makeStyles } from '@material-ui/core/styles';
import { FirstPageHead } from '../Home/components/FirstPageHead';
import { SearchHistoryDto, UserCenterEntityType } from '../../models/backend/userCenter';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { formatDate, TR } from '../../commons';
import { Container, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isSameDay } from 'date-fns';
import { getSearchHistory } from '../../services/userCenter';
import { Arrays } from '../../commons/array';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';
import { NewHomeFooter } from '../NewHome/components/11NewHomeFooter';
import { NewHomePageHead } from '../NewHome/components/01NewHomePageHead';
import { NewHomeFooterForMobile } from '../NewHome/components/11NewHomeFooterForMobile';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
        maxWidth: 780,
        minHeight: '70vh',
        backgroundColor: 'white',
        margin: '0 auto',
    },
    dummy: {},
}));

export const SearchHistoryPage = rst.create((ctx) => {
    const historyState = rst.stateS<SearchHistoryDto[]>(null);
    getSearchHistory().then((data) => {
        historyState.value = data;
    });

    return (props) => {
        const classes = useStyles();
        let previousDate = null;
        let currDate = null;

        let historyNode;
        if (Arrays.hasItems(historyState.value)) {
            historyNode = historyState.value?.map((dto) => {
                previousDate = currDate;
                currDate = dto.createTime;
                return <HistoryItem previousDate={previousDate} key={dto.id} dto={dto} />;
            });
        } else {
            historyNode = (
                <Grid container justify={'center'}>
                    <TR name={'No search history'} />
                </Grid>
            );
        }
        return (
            // <div className={classes.zIndex}>
            <div>
                <NewHomePageHead />
                <Container className={classes.container}>{historyNode}</Container>
                <Hidden smDown>
                    <NewHomeFooter />
                </Hidden>
                <Hidden mdUp>
                    <NewHomeFooterForMobile />
                </Hidden>
            </div>
        );
    };
});

const HistoryItem = rst.create<{ previousDate: Date; dto: SearchHistoryDto }>((ctx) => {
    function getSearchHistoryType(dto: SearchHistoryDto) {
        const { entityType, entity } = dto;
        switch (entityType) {
            case UserCenterEntityType.Property:
                return entity?.propType ?? 'Property';
            case UserCenterEntityType.Suburb:
                return 'Suburb';
            default:
                return '';
        }
    }

    function getAddress(dto: SearchHistoryDto) {
        const { entity, entityType } = dto;
        switch (entityType) {
            case UserCenterEntityType.Property:
                return entity?.stdAddress;
            case UserCenterEntityType.Suburb:
                return `${entity?.suburbName} NSW ${entity?.suburbPost}`;
            default:
                return '';
        }
    }

    return (props) => {
        useTranslation();
        const lan = i18next.language;
        const { dto, previousDate } = props;
        const type = getSearchHistoryType(dto);
        const address = getAddress(dto);
        const hide = isSameDay(previousDate, dto.createTime);

        return (
            <>
                {hide || (
                    <Box mr={1} mt={1}>
                        <Typography>{formatDate(dto.createTime, 'yyyy.MM.dd HH:mm eeee')}</Typography>
                    </Box>
                )}

                <Box px={{ xs: 1, sm: 2, md: 4 }} pt={1} display={'flex'} flexDirection={'row'}>
                    <Box mr={1} width={lan === 'en' ? 95 : 65} display={'flex'} justifyContent={'flex-start'}>
                        <b>
                            <TR name={type} />
                        </b>
                    </Box>
                    <Box flexGrow={1} display={'flex'} justifyContent={'flex-start'}>
                        <Typography align={'left'}>{address}</Typography>
                    </Box>
                </Box>
            </>
        );
    };
});

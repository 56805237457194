import React, { useState, useEffect } from 'react';
import { rst } from 'rt-state';
import { makeStyles } from '@material-ui/core';
import { convertToM } from '../../../../commons/utils';
import { TR } from '../../../../commons';
import { Card11 } from '../../../PropertyReport/ReportCards/Card11_GeneralPage';

const useStyles = makeStyles({
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: '7px',
    },
    tips: {
        fontSize: 13,
        lineHeight: '18px',
        color: '#888888',
        marginTop: '5px',
    },
    num: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '28px',
        marginTop: '20px',
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    value: {
        fontSize: '13px',
        color: '#000000',
    },
});

declare var window: any;
export const GeneralSituation = rst.create<{ pageData?: any }>((ctx) => {
    window.gtag('config', 'UA-148014445-2', {
        page_title: 'Street Details',
        page_path: '/mobile_StreetDetails',
    });
    return () => {
        const classes = useStyles();
        const pageState = rst.state({
            data: { ...ctx.props.pageData.prop_ql.street },
            propQL_result: ctx.props.pageData.oneProp_ql,
        });
        return (
            <div style={{ padding: '10px', marginTop: '10px', background: '#fff' }}>
                <div className={classes.title}>
                    <span>
                        {pageState.data.streetName} {pageState.data.streetTypeAbbr},
                    </span>{' '}
                    <span>{pageState.data.localityName} </span>
                    <span>{pageState.data.state} </span>
                    <span>{pageState.data.post}</span>
                </div>
                <div className={classes.flex}>
                    <div className={classes.tips} style={{ flex: 2 }}>
                        <TR name="totalHouse"></TR>:{'  '}
                        <span className={classes.value}>{pageState.data.numberOfHouse}</span>
                    </div>
                    {pageState.data.numberOfApartment && (
                        <div className={classes.tips} style={{ flex: 1 }}>
                            <TR name="apartmentNumber"></TR>:
                            <span className={classes.value}>{pageState.data.numberOfApartment}</span>
                        </div>
                    )}
                </div>
                <div className={classes.tips} style={{ marginTop: '20px' }}>
                    <TR name="priceLevelToSuburb"></TR>
                </div>
                <div className={classes.tips} style={{ marginTop: '5px' }}>
                    <TR name="housePrice"></TR>:{'  '}
                    <span className={classes.value}>
                        {pageState.data.suburbMedianPct > 1 ? <TR name="higher"></TR> : <TR name="lower"></TR>}
                    </span>
                </div>
                <div className={classes.tips}>
                    <TR name="houseHoldingPeriod"></TR>:{'  '}
                    <span className={classes.value}>
                        {pageState.data.turnoverRate > 1 ? <TR name="short"></TR> : <TR name="long"></TR>}
                    </span>
                </div>
                {!ctx.props.pageData.showAll ? (
                    <>
                        <div className={classes.flex}>
                            <div style={{ flex: 2 }}>
                                <div className={classes.num}>
                                    {convertToM(pageState.data.housePriceRange?.medianPrice.median) == 'NaN'
                                        ? 'N/A'
                                        : '$' + convertToM(pageState.data.housePriceRange?.medianPrice.median) + 'm'}
                                </div>
                                <div className={classes.tips}>
                                    <TR name="houseMedianPriceTwoYears"></TR>
                                </div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <div className={classes.num}>
                                    {pageState.data.housePriceRange &&
                                    pageState.data.housePriceRange.medianPrice.numberOfSold
                                        ? pageState.data.housePriceRange.medianPrice.numberOfSold
                                        : 0}
                                </div>
                                <div className={classes.tips}>
                                    <TR name="soldTotal"></TR>
                                </div>
                            </div>
                        </div>

                        <div style={{ fontSize: 13, color: '#888888' }}>
                            <TR name="housePriceRangeTwoYears"></TR>：
                            <span className={classes.value}>
                                {convertToM(pageState.data.housePriceRange?.soldPriceRange?.minValue) == 'NaN'
                                    ? 'N/A'
                                    : '$' +
                                      convertToM(pageState.data.housePriceRange?.soldPriceRange?.minValue) +
                                      'm ~ $' +
                                      convertToM(pageState.data.housePriceRange?.soldPriceRange?.maxValue) +
                                      'm'}
                            </span>
                        </div>

                        {pageState.data.apartmentPriceRange ? (
                            <>
                                <div className={classes.flex}>
                                    <div style={{ flex: 2 }}>
                                        <div className={classes.num}>
                                            {convertToM(pageState.data.apartmentPriceRange.medianPrice.median) == 'NaN'
                                                ? 'N/A'
                                                : '$' +
                                                  convertToM(pageState.data.apartmentPriceRange.medianPrice.median) +
                                                  'm'}
                                        </div>
                                        <div className={classes.tips}>
                                            <TR name="apartmentPriceTwoYears"></TR>
                                        </div>
                                        {/* <div className={classes.tips}>
                                            <TR name="soldTotal"></TR>：
                                            {pageState.data.apartmentPriceRange &&
                                                pageState.data.apartmentPriceRange.numberOfYears}
                                        </div> */}
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className={classes.num}>
                                            {pageState.data.apartmentPriceRange &&
                                            pageState.data.apartmentPriceRange.medianPrice.numberOfSold
                                                ? pageState.data.apartmentPriceRange.medianPrice.numberOfSold
                                                : 0}
                                        </div>
                                        <div className={classes.tips}>
                                            <TR name="soldTotal"></TR>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ fontSize: 13, color: '#888888' }}>
                                    <TR name="apartmentPriceRangeTwoYears"></TR>:{'  '}$
                                    <span className={classes.value}>
                                        {convertToM(pageState.data.apartmentPriceRange?.soldPriceRange?.minValue)}m ~ $
                                        {convertToM(pageState.data.apartmentPriceRange?.soldPriceRange?.maxValue)}m
                                    </span>
                                </div>
                            </>
                        ) : null}

                        {pageState.propQL_result ? (
                            <div>
                                <Card11 property={pageState.propQL_result} mobile={true} />
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    };
});

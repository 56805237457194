import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { TR } from '../../../../commons';
const useStyles = makeStyles({
    root: {
        fontSize: 13,
        color: '#333333',
        fontWeight: 400,
        marginLeft: 5,
    },
});
export const InfoValue = (props: any) => {
    const { children } = props;
    const classes = useStyles();
    return <div className={classes.root}>{children}</div>;
};

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { XForm, XRadioGroup } from '../../../components/form';

const useStyles = makeStyles((theme) => {
    return {
        radioLabel: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
    };
});

export const SearchTabContent = rst.create<{ listOptions: any; form: XForm }>((ctx) => {
    return (props) => {
        const classes = useStyles(props);
        const { form, listOptions } = props;
        function createSearchContent() {
            const searchContent = [];
            listOptions.forEach((loopItem) => {
                const itemOptions = [];
                if (loopItem.grades) {
                    loopItem.grades.forEach((loopOption) => {
                        itemOptions.push({ label: loopOption.label, value: loopOption.value });
                    });
                }

                console.log(loopItem.fieldName);
                searchContent.push(
                    <XRadioGroup
                        form={form}
                        name={loopItem.fieldName}
                        classes={{ formLabel: classes.radioLabel }}
                        label={loopItem.label}
                        options={itemOptions}
                    />,
                );
            });

            return searchContent;
        }

        return <>{createSearchContent()}</>;
    };
});

import { Card, CardContent, makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';

const useStyles = makeStyles({
    root: {
        // marginTop: 10,
        padding: '8px',
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

export const StandardCard = rst.create((ctx: any) => {
    return (props: any) => {
        const classes = useStyles();
        return (
            <div style={{ ...props.style }} className={classes.root} id={props.id}>
                <Card
                    style={{
                        background: 'linear-gradient(359deg,#65bdb5 0%, #009e90 50%)',
                        padding: '0px',
                    }}>
                    <CardContent className={classes.content}>{ctx.props.children}</CardContent>
                </Card>
            </div>
        );
    };
});

import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { TR } from '../../../commons';
import StarIcon from '@material-ui/icons/Star';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { create, stateS } from 'rt-state';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { ROUTES } from '../../../routes';
import { RRLink } from '../../../commons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            padding: '2rem',
            margin: '2rem',
        },
        nested: {
            paddingLeft: theme.spacing(3),
            padding: '0.8rem 0rem',
        },
        nestedNested: {
            paddingLeft: theme.spacing(5),
            padding: '0.3rem 1rem',
        },
        toHome: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }),
);

export const UserCenterPage = create<{ openUserInfo: () => void; openPasswordSetting: () => void }>((ctx) => {
    const openFavorite = stateS(false);
    const handleClickFavorite = () => {
        openFavorite.value = !openFavorite.value;
        openFavorite.forceUpdate();
    };

    const openSetting = stateS(false);
    const handleClickSetting = () => {
        openSetting.value = !openSetting.value;
        openSetting.forceUpdate();
    };

    return (props) => {
        const classes = useStyles();
        const { openUserInfo, openPasswordSetting } = props;
        return (
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="userCenter_header">
                        <h2>
                            <TR name={'User Center'} />
                        </h2>
                    </ListSubheader>
                }
                className={classes.root}>
                <Divider />
                <ListItem button dense={true} className={classes.nested} onClick={handleClickFavorite}>
                    <ListItemIcon>
                        <StarIcon />
                    </ListItemIcon>
                    <ListItemText primary={<TR name={'My Favorite'} />} />
                    {openFavorite.value ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openFavorite.value} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button dense={true} className={classes.nestedNested}>
                            <ListItemIcon>
                                <HomeOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={<TR name={'Favorite House'} />} />
                        </ListItem>
                        <ListItem button dense={true} className={classes.nestedNested}>
                            <ListItemIcon>
                                <PinDropOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={<TR name={'Favorite Area'} />} />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider />
                <ListItem button dense={true} className={classes.nested}>
                    <ListItemIcon>
                        <HistoryOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={<TR name={'Browsing History'} />} />
                </ListItem>
                <Divider />
                <ListItem button dense={true} className={classes.nested}>
                    <ListItemIcon>
                        <FindInPageOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={<TR name={'Search History'} />} />
                </ListItem>
                <Divider />
                <ListItem button dense={true} className={classes.nested}>
                    <ListItemIcon>
                        <MessageOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={<TR name={'Comment History'} />} />
                </ListItem>
                <Divider />
                <ListItem button dense={true} className={classes.nested} onClick={handleClickSetting}>
                    <ListItemIcon>
                        <SettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={<TR name={'Setting'} />} />
                    {openSetting.value ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSetting.value} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button dense={true} className={classes.nestedNested} onClick={openUserInfo}>
                            <ListItemIcon>
                                <AccountCircleOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={<TR name={'User Information Setting1'} />} />
                        </ListItem>
                        <ListItem button dense={true} className={classes.nestedNested} onClick={openPasswordSetting}>
                            <ListItemIcon>
                                <LockOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={<TR name={'Password Setting'} />} />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider />
                <ListItem button dense={true} className={classes.nested}>
                    <ListItemIcon>
                        <MonetizationOnOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={<TR name={'Payment Report'} />} />
                </ListItem>
                <Link component={RRLink} to={ROUTES.SuburbsPage.path} className={classes.toHome}>
                    <TR name={'Back to Home'} />
                </Link>
            </List>
        );
    };
});

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { getStreetImageForProp } from '../../../commons/utils';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'absolute',
            zIndex: 99999,

            borderRadius: '20px',
            width: 500,
            height: 500,
            left: (window.screen.width - 500) / 2,
            top: (window.screen.height - 500) / 2,
            boxShadow: '5px 10px',
            background: 'white',
        },
    };
});

export const EnlargeStreetView = rst.create((ctx) => {
    const compState = rst.state({
        show: false,
        address: '',
        zoom: 35,
    });
    EventBus.on(EVENT_NAMES.EVENT_SWITCH_ENLARGE_STREETVIEW, (evt) => {
        if (evt.show != null) {
            compState.show = evt.show;
            compState.address = evt.address;
        }
        if (evt.zoomIn) {
            compState.zoom = compState.zoom + 2;
        }
        if (evt.zoomOut) {
            compState.zoom = compState.zoom - 2;
        }
    });
    return (props) => {
        const classes = useStyles();
        return (
            <>
                {compState.show ? (
                    <div className={classes.root}>
                        <img
                            style={{ borderRadius: 10 }}
                            src={getStreetImageForProp(compState.address, compState.zoom)}></img>
                    </div>
                ) : null}
            </>
        );
    };
});

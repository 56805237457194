import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { generateGUID, TR } from '../../../../commons';
import { queryAdjacent_byGraphQL } from '../../../../commons/graphqlClient/graphAPI';
import { changeToFirstCaptial } from '../../../../commons/utils';
import { HomeOnToggleButton, HomeOnToggleButtonDefaultChecked } from '../../../../components/ToggleButton';
import { calculateMedianPrice, getSuitablePriceArray } from '../../../PriceMap/support/common';
import { PropertyPriceLegendList } from '../../../PriceMap/support/PriceMapConsts';
import { ReportMap } from '../../../PropertyReport/components/reportMap/ReportMap';
import { AdjacentResult } from './adjacentResult';
import { LeafletAdjacentMap } from './LeafletAjacentMap';
import { MapLegendForPropertyType } from './mapLegendForProeprtyType';
import { PriceMapCondition } from './priceMapCondition';
import { PopupComponentForLabel } from './../../../PriceMap/components/popupComponentForLabel';
import { getAptSoldMedianPrice, getTotalAroundTrans } from '../../../PriceMap/support/propertyFeatureHelper';

import {
    api_estimatePropertyValue,
    api_getAroundTranscations,
    api_getPropertyFeatures,
} from '../../../../services/property';

const useStyles = makeStyles({
    root: {
        padding: '16px',
    },
    title: {
        fontSize: '16px',
        fontWeight: 500,
    },
    toggleButtons: {
        display: 'flex',
        width: '100%',
        paddingTop: '16px',
        justifyContent: 'space-between',
    },
});
export const AroundPriceMap = (props) => {
    const mapRef = useRef();
    const [redrawMapVar, setRedrawMapVar] = useState('');
    var mapComponentKey = '';
    const { propCenter, propId, schools, locality, polygonEncoded, propAddress, apt } = props;
    console.log('polygonEncoded', polygonEncoded);

    const [loading, setLoading] = useState(false);
    const [conditions, setConditions] = useState({
        distance: 500,
        interval: 6,
        bedrooms: 0 as any,
    });
    const [showPrice, setShowPrice] = useState(true);
    const [showSchool, setShowSchool] = useState(false);

    console.log(props);
    const [adjacentResult, setAdjacentResult] = useState(null);

    const classes = useStyles();

    const getAdjacentSold = (type, value) => {
        //setLoading(true);
        if (type) {
            const tempConditions = conditions;
            tempConditions[type] = value;

            if (tempConditions.bedrooms == 0) {
                tempConditions.bedrooms = [];
            }

            setConditions(tempConditions);
        }

        apt
            ? queryAdjacent_byGraphQL(
                  propId,
                  conditions.interval,
                  conditions.distance,
                  conditions.bedrooms,
                  'Apt',
              ).then((result: any) => {
                  console.log(result);
                  calculateMedianPriceArray(result.data.property.adjacentSold);
                  setAdjacentResult(result.data.property);
                  // setLoading(false);
                  mapComponentKey = generateGUID();
              })
            : queryAdjacent_byGraphQL(
                  propId,
                  conditions.interval,
                  conditions.distance,
                  conditions.bedrooms,
                  'House',
              ).then((result: any) => {
                  console.log(result);
                  calculateMedianPriceArray(result.data.property.adjacentSold);
                  setAdjacentResult(result.data.property);
                  // setLoading(false);
                  mapComponentKey = generateGUID();
              });
    };
    //     apt
    //         ? api_getAroundTranscations(
    //               'Apt',
    //               propCenter.lat,
    //               propCenter.lon,
    //               conditions.interval,
    //               propId,
    //               conditions.distance / 1000,
    //               '0',
    //               conditions.bedrooms,
    //               '',
    //               '',
    //               '',
    //           ).then((result: any) => {
    //               console.log(result);
    //               calculateMedianPriceArray(result);

    //               setAdjacentResult(getAptAroundTrans(result));
    //               // setLoading(false);
    //               mapComponentKey = generateGUID();
    //           })
    //         : queryAdjacent_byGraphQL(propId, conditions.interval, conditions.distance, conditions.bedrooms).then(
    //               (result: any) => {
    //                   console.log(result);
    //                   calculateMedianPriceArray(result.data.property.adjacentSold);
    //                   setAdjacentResult(result.data.property);
    //                   // setLoading(false);
    //                   mapComponentKey = generateGUID();
    //               },
    //           );
    // };

    console.log('adjacentResult', adjacentResult);

    const calculateMedianPriceArray = (allSolds) => {
        const soldPriceArray =
            allSolds &&
            allSolds.map((sold) => {
                return sold.soldPrice;
            });

        var medianPrice: any = calculateMedianPrice(soldPriceArray);
        getSuitablePriceArray(medianPrice.median);
    };
    useEffect(() => {
        getAdjacentSold('bedrooms', 0);
    }, [conditions, propId]);
    const switchShowPrice = (evt) => {
        console.log(evt.target.checked);
        setShowPrice(evt.target.checked);
        mapComponentKey = generateGUID();
        setRedrawMapVar(generateGUID());
    };
    const switchShowSchool = (evt) => {
        setShowSchool(evt.target.checked);
        mapComponentKey = generateGUID();
        setRedrawMapVar(generateGUID());
    };
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <TR name="aroundHouseMapTitle"></TR>
            </div>
            <PriceMapCondition conditions={conditions} changeConditionEvt={getAdjacentSold}></PriceMapCondition>
            {adjacentResult && (
                <LeafletAdjacentMap
                    redrawVar={redrawMapVar}
                    mapId="1"
                    ref={mapRef}
                    key={mapComponentKey}
                    propCenter={propCenter}
                    drawingData={{
                        distance: conditions.distance,
                        bedrooms: conditions.bedrooms,
                        solds: adjacentResult?.adjacentSold,
                        suburbBorder: adjacentResult?.locality?.polygonEncoded,
                        // bedrooms: conditions.bedrooms,
                        // solds: apt ? adjacentResult : adjacentResult?.adjacentSold,
                        // suburbBorder: apt ? polygonEncoded : adjacentResult?.locality?.polygonEncoded,
                        showPrice: showPrice,
                        showSchool: showSchool,
                        schools: schools,
                    }}></LeafletAdjacentMap>
            )}

            <div className={classes.toggleButtons}>
                <HomeOnToggleButtonDefaultChecked
                    label="label_showPrice"
                    checked={showPrice}
                    switchEvt={switchShowPrice}></HomeOnToggleButtonDefaultChecked>
                <HomeOnToggleButton label="label_school" switchEvt={switchShowSchool}></HomeOnToggleButton>
            </div>
            <div style={{ marginTop: 16 }}>
                {adjacentResult && (
                    <AdjacentResult
                        locality={locality}
                        showPrice={showPrice}
                        conditions={conditions}
                        propAddress={changeToFirstCaptial(adjacentResult.stdAddress)}
                        solds={adjacentResult.adjacentSold}
                        // propAddress={changeToFirstCaptial(apt ? propAddress : adjacentResult.stdAddress)}
                        // solds={apt ? adjacentResult : adjacentResult.adjacentSold}
                        apt={apt}></AdjacentResult>
                )}
            </div>
            <div style={{ marginTop: 16 }}>
                <div style={{ display: 'flex' }}>
                    <img src="/assets/mobileIcons/help-ico.svg"></img>
                    <PopupComponentForLabel
                        link={
                            <div style={{ marginLeft: '3px' }}>
                                <TR name="howHomeOnGetData"></TR>
                            </div>
                        }
                        content={
                            <div>
                                <p>
                                    <TR name={'howHomeOnGetDataDesp'}></TR>
                                </p>
                            </div>
                        }
                        title={<TR name={'howHomeOnGetDataTitle'}></TR>}
                        table=""
                        maxWidth="xl"
                    />
                </div>
            </div>
        </div>
    );
};

// export const getAptAroundTrans = (trans) => {
//     const stats = [];

//     trans.forEach((proj) => {
//         proj.properties.forEach((apt) => {
//             apt['propY'] = proj.lat;
//             apt['propX'] = proj.lon;
//             apt['fullAddress'] = apt.full_address;
//             apt['propBath'] = apt.prop_bath;
//             apt['propBed'] = apt.prop_bed;
//             apt['propCarpark'] = apt.prop_carpark;
//             apt['propId'] = apt.prop_id;
//             apt['soldContractDate'] = apt.sold_contract_date;
//             apt['soldPrice'] = apt.sold_price;
//             stats.push(apt);
//         });
//     });

//     return stats;
// };

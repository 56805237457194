import { Dialog } from '@material-ui/core';
import React from 'react';
import { PageWrapper } from '../../../auth/common';

export const VerifyEmailPage = (props) => {
    return (
        <PageWrapper>
            <Dialog open={true}> this is verify email page</Dialog>
        </PageWrapper>
    );
};

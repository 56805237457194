import { CardContent } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TRHtml } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { Card32 } from '../../../PropertyReport/ReportCards/Card32';
import { CardPermission } from '../cardPermission';
import { CardTitle } from './cardTitle';

export const SimilarHouseCard = rst.create((props: any) => {
    const state = rst.state({
        closeContent: false,
        estimateResult: 0,
        hasPermission: true,
    });
    EventBus.on(EVENT_NAMES.EVENT_MOBILE_CARD_NOPERMISSION, (evtData: any) => {
        state.hasPermission = evtData.permission;
    });
    const closeContentEvt = () => {
        state.closeContent = !state.closeContent;
    };
    EventBus.on(EVENT_NAMES.EVENT_ESTIMAGE_VALUE_CHANGE, (evt) => {
        console.log(evt.middle);
        state.estimateResult = evt.middle;
    });
    return (props: any) => {
        const { property_ql, prop } = props;
        console.log(prop);
        return (
            <div style={{ width: '100%', padding: '10px' }}>
                {/* <CardTitle
                    closeContent={state.closeContent}
                    closeEvt={closeContentEvt}
                    title={'comparableProperties'}></CardTitle> */}
                <div style={{ position: 'relative', minHeight: state.closeContent ? 'auto' : '150px' }}>
                    {!state.closeContent ? <CardPermission cardType="similarHouse"></CardPermission> : null}
                    {state.hasPermission ? (
                        <CardContent style={{ padding: 0 }}>
                            {
                                !state.closeContent && state.estimateResult != 0 ? (
                                    <Card32
                                        mobile={true}
                                        prop={prop}
                                        pql={property_ql}
                                        estimateMiddle={state.estimateResult}></Card32>
                                ) : null
                                // <div style={{ color: '#888888' }}>
                                //     {' '}
                                //     <TRHtml html={'similarDesc'} params={{ address: prop.address }}>
                                //         {' '}
                                //     </TRHtml>
                                // </div>
                            }
                        </CardContent>
                    ) : null}
                </div>
            </div>
        );
    };
});

import React from 'react';
import { rst } from 'rt-state';
import { makeStyles } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PropertyCard } from '../propertyCard';
import { TR } from '../../../../commons';

const useStyles = makeStyles({
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: '8px',
    },
    tips: {
        fontSize: 13,
        lineHeight: '18px',
        color: '#888888',
        marginTop: '5px',
    },
});

export const HouseSale = rst.create<{ pageData?: any }>((ctx) => {
    return (props: any) => {
        const pageState = rst.state({
            data: { ...ctx.props.pageData.prop_ql.street },
        });
        const classes = useStyles();
        const houseList = pageState.data.streetSold
            ? pageState.data.streetSold.length > 3
                ? pageState.data.streetSold.splice(0, 3)
                : pageState.data.streetSold
            : [];
        return (
            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>
                            <div style={{ fontSize: '16px', fontWeight: 600, color: '#333' }}>
                                <TR name="recentlySoldInStreet"></TR>
                            </div>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {houseList.length > 0
                                ? houseList.map((item) => {
                                      return (
                                          <>
                                              <PropertyCard embedded propId={item.propId}></PropertyCard>
                                          </>
                                      );
                                  })
                                : null}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };
});

import { rst, stateS } from 'rt-state';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FilterButton } from '../../../components/Button';
import Grid from '@material-ui/core/Grid';
import { FilterOptionProps } from '../../../models/filterOption';
import { XProps } from '../../../commons';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            maxWidth: 500,
            //
            margin: 'auto',
            marginTop: theme.spacing(3),
        },
        dummy: {},
    };
});

export const FilterGroup = rst.create<FilterOptionProps & XProps>((ctx) => {
    function onClick(idx: number) {
        ctx.props.onSelect(ctx.props.options?.[idx]);
    }
    return (props) => {
        const classes = useStyles(props);
        const { value } = props;

        return (
            <Grid className={classes.root} container justify={'space-around'}>
                {props.options.map((filter, idx) => {
                    return (
                        <Grid container item xs key={filter.value} justify={'center'}>
                            <FilterButton
                                tooltip={filter.tooltip}
                                label={filter.label ?? filter.value}
                                selected={value?.value === filter.value}
                                onClick={() => onClick(idx)}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    };
});

import React, { useState } from 'react';
import { rst, setState } from 'rt-state';
import { CardTitle } from './cardTitle';
import { api_getAroundTranscations } from '../../../../services/property';
import { getAptSoldMedianPrice, getTotalAroundTrans } from '../../../PriceMap/support/propertyFeatureHelper';
import { makeStyles } from '@material-ui/styles';
import { TR } from '../../../../commons';
import { convertToM, jsonHasKey } from '../../../../commons/utils';
import { CardPermission } from '../cardPermission';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { AppProvider } from '../../../../commons/appProvider';
import { ReportMap } from '../../../PropertyReport/components/reportMap/ReportMap';
import { NearByTranscationChart } from '../../../PriceMap/Charts/NearByTranscationsChart';

const useStyles = makeStyles({
    aroundLine: {
        display: 'flex',
        marginTop: '10px',
    },
    medianPriceTitle: {
        fontSize: '16px',
        fontWeight: 500,
    },
    aroundBed: {
        display: 'flex',
        marginLeft: 10,
        fontWeight: 500,
        fontSize: '14px',
    },
    noTrans: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        fontWeight: 700,
    },
    aroundMedian: {
        marginLeft: '20px',
    },
    medianSummary: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    landValue: {
        fontWeight: 700,
        fontSize: 18,
    },
    landLabel: {
        color: '#888888',
        fontSize: 13,
    },
});
export const AroundPriceCard = rst.create<{ property; propId; propType; lat; lon; isSummary; propBed? }>((ctx: any) => {
    const state = rst.state({
        closeContent: false,
        aptAround: {},
        houseAround: {},
        hasPermission: true,
        currentBed: ctx.props.propBed,
        currentBedMedian: 0,
        currentBedSold: 0,
        aroundTrans: [],
        medianPrice: 0,
    });
    EventBus.on(EVENT_NAMES.EVENT_MOBILE_CARD_NOPERMISSION, (evtData: any) => {
        state.hasPermission = evtData.permission;
    });
    const closeContentEvt = () => {
        state.closeContent = !state.closeContent;
    };

    const getAroundTrans = (propType) => {
        api_getAroundTranscations(propType, ctx.props.lat, ctx.props.lon, 6, '').then((trans: any) => {
            state.aroundTrans = trans;
            if (propType == 'Apt') {
                state.aptAround = getTotalAroundTrans(trans, propType);
            }
            if (propType == 'House') {
                state.houseAround = getTotalAroundTrans(trans, propType);
            }
        });
    };

    getAroundTrans(ctx.props.propType);

    return (props: any) => {
        const classes = useStyles();
        const presentAroundData = () => {
            const content = [];
            if (props.propType == 'House') {
                if (jsonHasKey(state.houseAround)) {
                    content.push(
                        <div className={classes.medianPriceTitle}>
                            <TR name="house500mMedianPriceHalfYear"></TR>
                        </div>,
                    );
                    Object.keys(state.houseAround).forEach((bed) => {
                        if ((props.isSummary && bed == props.propBed) || !props.isSummary) {
                            content.push(
                                <div className={classes.aroundLine}>
                                    <span className={classes.aroundBed}>
                                        <img
                                            src={`assets/icons/bed${bed == null || bed == 'null' ? 0 : bed}.svg`}></img>
                                        <div style={{ marginLeft: 5 }}>
                                            {bed != 'null' ? bed : <TR name={'unknown'}></TR>}
                                        </div>
                                    </span>
                                    <div className={classes.aroundMedian}>
                                        {state.houseAround[bed].medianPrice
                                            ? `$${convertToM(state.houseAround[bed].medianPrice.median)}m`
                                            : 'N/A'}
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <TR name={'Units'}></TR>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>{state.houseAround[bed].length}</div>
                                </div>,
                            );
                        }
                    });
                } else {
                    content.push(
                        <div className={classes.noTrans}>
                            <TR name="noAroundTrans"></TR>
                        </div>,
                    );
                }
            }
            if (props.propType == 'Apt') {
                if (jsonHasKey(state.aptAround)) {
                    content.push(
                        <div className={classes.medianPriceTitle}>
                            <TR name="apartment500mMedianPriceHalfYear"></TR>
                        </div>,
                    );

                    Object.keys(state.aptAround).forEach((bed) => {
                        content.push(
                            <div className={classes.aroundLine}>
                                <span className={classes.aroundBed}>
                                    <img src="assets/icons/bed.svg"></img>
                                    {bed}
                                </span>
                                <div className={classes.aroundMedian}>
                                    ${convertToM(state.aptAround[bed].medianPrice.median)}m
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <TR name={'Units'}></TR>
                                </div>
                                <div style={{ marginLeft: 10 }}>{state.aptAround[bed].length}</div>
                            </div>,
                        );
                    });
                } else {
                    content.push(
                        <div className={classes.noTrans}>
                            <TR name="noAroundTrans"></TR>
                        </div>,
                    );
                }
            }

            return content;
        };

        const showAroundMap = () => {
            return (
                <ReportMap
                    propCenter={{ lat: props.lat, lon: props.lon }}
                    drawingData={state.aroundTrans}
                    addtionData={{ propType: ctx.props.propType }}
                    draggable
                    drawingObject="8"></ReportMap>
            );
        };

        const changeCurrentBed = (evt) => {
            console.log(state.houseAround);
            state.currentBed = evt.target.value;
        };

        const presentSummary = () => {
            return (
                <>
                    <div style={{ width: '100%', display: 'flex' }}>
                        <div style={{ flex: 2 }}>
                            <div className={classes.landValue}>
                                {' '}
                                {state.houseAround[state.currentBed]?.medianPrice
                                    ? `$${convertToM(state.houseAround[state.currentBed]?.medianPrice.median)}m`
                                    : 'N/A'}
                            </div>
                            {AppProvider.use().getLanguage() == 'zh' ? (
                                <div className={classes.landLabel}>
                                    <TR name="recentYearSoldDesc"></TR>
                                    <select value={state.currentBed} onChange={changeCurrentBed}>
                                        {Object.keys(state.houseAround).map((bed) => {
                                            return <option>{bed == 'null' ? '未知' : bed}</option>;
                                        })}
                                    </select>
                                    <TR name="bedRoom"></TR>
                                    <TR name="mobilePropertyMedianPrice"></TR>
                                </div>
                            ) : (
                                <div className={classes.landLabel}>
                                    <TR name="mobilePropertyMedianPrice"></TR>
                                    <select value={state.currentBed} onChange={changeCurrentBed}>
                                        {Object.keys(state.houseAround).map((bed) => {
                                            return <option>{bed == 'null' ? 'unknown' : bed}</option>;
                                        })}
                                    </select>
                                    <TR name="bedRoom"></TR>
                                    <div>
                                        <TR name="recentYearSoldDesc"></TR>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div style={{ flex: 1 }}>
                            <div className={classes.landValue}>
                                {state.houseAround[state.currentBed] ? state.houseAround[state.currentBed]?.length : 0}
                            </div>
                            <div className={classes.landLabel}>
                                <TR name="soldTotal"></TR>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}></div>
                </>
            );
        };
        return (
            <div style={{ width: '100%', padding: !props.isSummary ? '10px' : 0 }}>
                {props.isSummary ? null : (
                    <CardTitle
                        closeContent={state.closeContent}
                        closeEvt={closeContentEvt}
                        title={'Around Property Data'}></CardTitle>
                )}
                {state.closeContent ? (
                    <>
                        {props.propType == 'House' ? (
                            <TR name={'aroundSummary'}></TR>
                        ) : (
                            <TR name={'aroundSummary1'}></TR>
                        )}
                    </>
                ) : (
                    <>
                        <div>
                            {/* <TR name={'suburbHouseMedianPriceHalfYear'}></TR> */}
                            {/* <div>
                                ${convertToM(suburbData.median_data.House.total.median)}m
                            </div> */}
                        </div>
                        {props.isSummary ? (
                            <>{presentSummary()}</>
                        ) : (
                            <div style={{ position: 'relative', minHeight: 150 }}>
                                {presentAroundData()}
                                <div style={{ marginTop: 10 }}></div>
                                {showAroundMap()}
                                <div style={{ marginTop: 10 }}></div>
                                <NearByTranscationChart
                                    mobileVersion
                                    chartChangeEvt={() => {}}
                                    changeIntervalEvt={() => {}}
                                    property={props.property}
                                    aroundTrans={state.aroundTrans}></NearByTranscationChart>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };
});

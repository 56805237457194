import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { TR } from '../../../../commons';
import { AppProvider } from '../../../../commons/appProvider';
import { requestVerifyEmail } from '../../../../services/userCenter';

export const NoVerifyEmailReceived = (props: any) => {
    const appProvider = AppProvider.use();
    const { showVerifyEmail, closeMessage } = props;
    const [sendResult, setSendResult] = useState(null);
    const resendVerifyEmail = async () => {
        const result = await requestVerifyEmail(appProvider.getLanguage());
        setSendResult(result);
    };
    return (
        <Dialog open={showVerifyEmail}>
            <DialogTitle>
                <TR name="emailVerifyRequired"></TR>
            </DialogTitle>
            <DialogContent>
                {sendResult == null && (
                    <>
                        <TR name="emailVerifyRequiredInfo"></TR>
                        <div
                            onClick={resendVerifyEmail}
                            style={{
                                marginTop: 15,
                                color: 'red',
                                width: '100%',
                                justifyContent: 'flex-end',
                                display: 'flex',
                            }}>
                            <TR name="noEmailReceived"></TR>
                        </div>
                    </>
                )}

                {sendResult?.email && (
                    <>
                        <TR name="verifyEmailSendTo"></TR>
                        {sendResult.email}
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={closeMessage}>
                    <TR name="GOT_IT"></TR>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

import ReactDOM from 'react-dom/server';
import React from 'react';
import { PropertyIcon } from './PropertyIcon';
import { getThemeValue } from '../support/PriceMapTheme';
import { convertToM, numToMillion } from '../../../commons/utils';

declare var L;
export class SuburbMapMarker {
    marker: any;
    medianPrice: string;
    constructor(suburbCenter: { lat: number; lon: number }, medianPrice: string) {
        this.marker = L.marker([suburbCenter.lat, suburbCenter.lon]);
        this.medianPrice = medianPrice;
    }

    // markerStatus : 1 not show
    //                2 normal
    //                3 show special care
    show(markerStatus: any, mapInst) {
        const convertedPrice = convertToM(this.medianPrice);

        if (markerStatus == '1' || convertedPrice == 0) {
            mapInst.removeLayer(this.marker);
            return;
        }
        let markerColor = '';
        let labelColor = getThemeValue('NORMAL_SUBURB_LABEL_COLOR');
        if (markerStatus == '2') {
            mapInst.addLayer(this.marker);
            markerColor = getThemeValue('NORMAL_SUBURB_MARKER_COLOR');
        }
        if (markerStatus == '3') {
            mapInst.addLayer(this.marker);
            markerColor = getThemeValue('SPECAIL_CARE_MARKER_COLOR');
            labelColor = getThemeValue('SPECICAL_CARE_LABEL_COLOR');
        }

        const iconHTML = ReactDOM.renderToString(
            React.createElement(PropertyIcon, {
                showLabel: true,
                color: markerColor,
                price: convertToM(this.medianPrice),
                labelColor: labelColor,
                notShowMarkPoint: true,
                propId: '',
            }),
        );

        const propertyIcon = new L.DivIcon({
            className: '',
            html: iconHTML,
            iconAnchor: [0, 35], // move the price maplabel to top offset 30 , avoid overlapping with suburb name
        });
        // console.log(propertyIcon);
        this.marker.setIcon(propertyIcon);
    }
}

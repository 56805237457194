import { SelectOption } from '../../../components/common';

export const ALL_TAB_OPTIONS: SelectOption[] = [
    {
        label: '价格',
        value: '1',
    },
    {
        label: '学校',
        value: '2',
    },
    {
        label: '居民',
        value: '3',
    },
    {
        label: '交通',
        value: '4',
    },
    {
        label: '生活',
        value: '5',
    },
];

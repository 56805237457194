import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR, TRHtml } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { decimalToPct, isMobileDevice, numToMillion } from '../../../../commons/utils';

import { MapGlobalVars } from '../../support/PriceMapConsts';
import { getThemeValue } from '../../support/PriceMapTheme';
import { PropBeds } from './PropBeds';

const useStyles = makeStyles((theme) => {
    return {
        propInfoDiv: { paddingLeft: isMobileDevice() ? 5 : 9, fontSize: isMobileDevice() ? 11 : 14 },
        housePrice: {
            fontSize: isMobileDevice() ? 12 : 20,
            fontWeight: 700,
            color: '#e02020',
            flexGrow: 1,
        },
        soldDate: {},
        landDiv: {
            fontSize: isMobileDevice() ? 11 : 14,
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        propAddr: {
            fontSize: isMobileDevice() ? 11 : 14,
            fontWeight: 400,
            color: '#333333',
        },
        schoolStation: {
            display: 'flex',
        },
    };
});

export const HouseBasicInfo = rst.create<{ prop: any; showFor: string }>((ctx) => {
    return (props) => {
        setTimeout(() => {
            if (!isMobileDevice()) {
                if (document.getElementById('stationRoute')) {
                    document.getElementById('stationRoute').addEventListener('click', () => {
                        const propertyPoint = { lat: props.prop.lat, lng: props.prop.lon };
                        const stationPoint = {
                            lat: prop.allFeatures.closest_station.y,
                            lng: prop.allFeatures.closest_station.x,
                        };
                        EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_ROUTE, {
                            points: { start: propertyPoint, end: stationPoint },
                        });
                    });
                }
            }
        }, 1000);
        const { prop, showFor } = props;
        const classes = useStyles();
        return (
            <div className={classes.propInfoDiv}>
                <div style={{ display: 'flex' }}>
                    <div className={classes.housePrice}>{numToMillion(prop.price)}</div>
                    <div className={classes.soldDate}>{prop.sold_contract_date}</div>
                </div>
                {isMobileDevice() && showFor != 'infoPanel' ? null : (
                    <>
                        <div style={{ marginTop: isMobileDevice() ? 2 : 9 }}></div>
                        <div className={classes.propAddr}>{prop.address}</div>
                    </>
                )}

                <div style={{ marginTop: isMobileDevice() ? 2 : 9 }}></div>
                <div className={classes.landDiv}>
                    <TR name={isMobileDevice() && showFor != 'infoPanel' ? 'LAND_SIZE' : 'LAND_SIZE_VALUE'}></TR>
                    <div style={{ marginLeft: isMobileDevice() ? 5 : 20 }}></div>
                    <span style={{ color: getThemeValue('MAIN_COLOR') }}>
                        {prop.landSize || '-'}m<sup>2</sup>
                    </span>
                    {isMobileDevice() && props.showFor == 'map' ? null : (
                        <>
                            <div style={{ marginLeft: isMobileDevice() ? 5 : 20 }}></div>
                            <span style={{ color: getThemeValue('MAIN_COLOR') }}>
                                {numToMillion(prop.landValue) || '-'}
                            </span>
                        </>
                    )}
                </div>
                {prop.landValue > 0 ? (
                    <>
                        <div style={{ marginTop: isMobileDevice() ? 2 : 9 }}></div>
                        <div style={{ display: 'flex' }}>
                            <TRHtml html="landValuePct"></TRHtml> : {decimalToPct(prop.landValue / prop.price)}
                        </div>
                    </>
                ) : null}

                <div style={{ marginTop: isMobileDevice() ? 2 : 9 }}></div>
                <PropBeds
                    propType="House"
                    beds={prop.prop_bed || prop.allFeatures.prop_bed}
                    baths={prop.prop_bath || prop.allFeatures.prop_bath}
                    cars={prop.prop_carpark || prop.allFeatures.prop_car}></PropBeds>

                {!isMobileDevice() ? (
                    <div className={classes.schoolStation}>
                        <div style={{ cursor: 'pointer' }}>
                            <div>最近火车站</div>
                            <div id="stationRoute">{prop.allFeatures.closest_station.name}</div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };
});

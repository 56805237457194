import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { EventBus, EVENT_NAMES } from '../../../../../commons/EventBus';
import { isMobileDevice, priceToK } from '../../../../../commons/utils';
import { PropBeds } from '../../PropertyInfo/PropBeds';

const useStyles = makeStyles((theme) => {
    return {
        unitInfo: {
            background: 'rgba(24,117,240,.04)',
            padding: '3px 2px',
            minWidth: 140,
            marginLeft: 3,
            cursor: 'pointer',
            // '&:nth-child(even)': {
            //     marginLeft: 3,
            // },
            marginTop: 3,
            paddingRight: 10,
        },
        unitPrice: {
            fontSize: isMobileDevice() ? 12 : 13,
            color: '#e02020',
            fontWeight: 400,
        },
        unitNo: {
            display: 'flex',
            width: '100%',
        },
    };
});
export const UnitCards = rst.create<{ units: any; googleSearchEvt: any }>((ctx) => {
    const state = rst.state({
        units: null,
    });

    return (props) => {
        const getUnitList = () => {
            var unitList = [];
            if (props.units) {
                props.units.map((loopUnit) => {
                    unitList.push(
                        <UnitCard
                            soldPrice={loopUnit.sold_price}
                            googleSearchEvt={props.googleSearchEvt}
                            unitNo={loopUnit.unit_number}
                            soldDate={loopUnit.sold_contract_date}
                            propBeds={{
                                beds: loopUnit.prop_bed,
                                baths: loopUnit.prop_bath,
                                cars: loopUnit.prop_carpark,
                            }}></UnitCard>,
                    );
                });
            }

            return unitList;
        };

        const { units } = props;

        return <>{getUnitList()}</>;
    };
});

export const UnitCard = rst.create<{
    soldPrice: number;
    unitNo: string;
    soldDate: string;
    googleSearchEvt: any;
    propBeds: { beds: number; baths: number; cars: number };
}>((ctx) => {
    return (props) => {
        const { propBeds } = props;
        const classes = useStyles();
        return (
            <div className={classes.unitInfo} onClick={props.googleSearchEvt}>
                <div className={classes.unitPrice}>{priceToK(props.soldPrice)}</div>
                <div className={classes.unitNo}>
                    Unit {props.unitNo} <div style={{ marginLeft: 5 }}>{props.soldDate}</div>
                </div>
                <div style={{ marginTop: isMobileDevice() ? 5 : 10 }}></div>
                <PropBeds propType="Apt" baths={propBeds.baths} beds={propBeds.beds} cars={propBeds.cars}></PropBeds>
            </div>
        );
    };
});

import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { Tips } from '../components/Tips';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
    };
});

export const Card20 = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader>
                    <Tips header="Card_Tips" content="CARD20_TIPS" />
                </CardWithHeader>
            </div>
        );
    };
});

import 'reflect-metadata';
import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'regenerator-runtime/runtime';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initApp } from './commons/globalVars';
import { initI18n, storage } from './commons';
import { isMobileDevice } from './commons/utils';

async function init() {
    // if user is using on mobile device, record user frenquency and prepare to prompt install
    if (isMobileDevice()) {
        const installedAsApp = await storage.get('installedAsApp');
        if (!installedAsApp) {
            const usedTime: any = await storage.get('usedTime');
            // if used time more than 5 times, then prompt user to install on phone as convience.
            if (parseInt(usedTime) > 5) {
                let deferredPrompt;

                window.addEventListener('beforeinstallprompt', (e) => {
                    // Prevent the mini-infobar from appearing on mobile
                    e.preventDefault();
                    // Stash the event so it can be triggered later.
                    deferredPrompt = e;
                    // Update UI notify the user they can install the PWA
                    //showInstallPromotion();

                    document.getElementById('installAsPWAPrompt').style.display = 'flex';

                    setTimeout(() => {
                        document.getElementById('installAsApp').addEventListener('click', (e) => {
                            // Hide the app provided install promotion
                            document.getElementById('installAsPWAPrompt').style.display = 'none';
                            // Show the install prompt
                            deferredPrompt.prompt();
                            // Wait for the user to respond to the prompt
                            deferredPrompt.userChoice.then((choiceResult) => {
                                if (choiceResult.outcome === 'accepted') {
                                    console.log('User accepted the install prompt');
                                } else {
                                    console.log('User dismissed the install prompt');
                                }
                            });
                        });

                        document.getElementById('cancelInstall').addEventListener('click', () => {
                            document.getElementById('installAsPWAPrompt').style.display = 'none';
                        });
                    });
                });

                window.addEventListener('appinstalled', (evt) => {
                    // Log install to analytics
                    alert('Homeon has been installed!');
                    document.getElementById('installAsPWAPrompt').style.display = 'none';
                });

                window.addEventListener('appinstalled', (evt) => {
                    // Log install to analytics
                    console.log('INSTALL: Success');
                });
            }
            storage.save('usedTime', parseInt(usedTime || 0) + 1);
        }
    }

    await initI18n();
    /**
     * 在呈现整个网站之前， 需要取得一些系统信息并保存在全局变量中，
     * 包括： 屏幕尺寸，
     *       用户浏览器语言，先要查看是否在本地存储中已经保存了语言，如果没有， 则从系统设备设置中读取
     *
     */
    await initApp();
    ReactDOM.render(<App />, document.getElementById('root'));
}
init().then();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

import { Box, CircularProgress, makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { API, clsx, SecurityProvider, storage, TR } from '../../../../commons';
import { changeToFirstCaptial, getItemFromArray } from '../../../../commons/utils';
import { MAP_APIS_ENDPOINTS } from '../../../../services/EndPoints';
import { api_querySuburbs } from '../../../../services/property';
import { saveUserAccessLog } from '../../../../services/userCenter';
import { detectInputSearchStr, getMobileSearchedHistory } from '../../../PriceMap/support/common';

const useStyles = makeStyles({
    root: {
        padding: '16px',
        position: 'relative',
    },
    searchBox: {
        display: 'flex',
        width: '100%',
        padding: '7px 9px',
        background: '#f1f1f2',
        bordeRadius: '4px',
        height: '44px',
        boxShadow: '0px 2px 5px 0px rgba(229,236,237,0.37)',
        alignItems: 'center',
    },
    searchInput: {
        width: '100%',
        background: '#f1f1f2',
        border: 0,
        height: '30px',
        fontSize: '14px',
        fontWeight: 400,
        '&:focus': {
            outline: 'none',
        },
    },
    selection: {
        position: 'absolute',

        marginTop: '1px',
        width: '94%',
        background: 'white',
        padding: '3px 10px',
        paddingLeft: '0px',
        fontSize: '14px',
        fontWeight: 400,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',

        boxShadow: '0px 0px 12px 0px rgba(95,103,115,0.10)',
        zIndex: 500,
        maxHeight: '300px',
        overflowY: 'auto',
    },
    subTitle: {
        fontWeight: 700,
    },
    selectItem: {
        padding: 5,
        fontWeight: 400,
        fontSize: '13px',
        color: '#666666',
        marginTop: '5px',
        cursor: 'pointer',
    },
    selecting: {},
    searchTab: {
        display: 'flex',
        alignItems: 'center',
    },
    staticAddressBar: {
        display: 'flex',
        alignItems: 'center',
    },
    staticAddress: {
        fontSize: 16,
        fontWeight: 500,
        marginLeft: 5,
        color: '#333333',
        flex: 1,
    },
    searchIcon: {
        width: 30,
        height: 30,
        background: '#009e90',
        borderRadius: '5px',
        marginLeft: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchHistoryLabel: {
        color: '#ff0000',
        fontWeight: 700,
        fontSize: 13,
        paddingLeft: 10,
        paddingTop: 10,
    },
});
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const CombinedAddressSearchBar = (props) => {
    const [loading, setLoading] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(false);
    const { currentAddress, changePropertyEvt } = props;
    const [showHistory, setShowHistory] = useState(false);
    const securityProvider = SecurityProvider.use();
    const [searchResult, setSearchResult] = useState({
        properties: [],
        streets: [],
        suburbs: [],
    });
    const [selected, setSelected] = useState({
        type: 0,
        value: '',
        label: currentAddress,
    });
    const classes = useStyles();

    const KEY_HISTORY_PROPERTY_ADDRESS = 'KEY_HISTORY_PROPERTY_ADDRESS';
    const KEY_HISTORY_STREET_ADDRESS = 'KEY_HISTORY_STREET_ADDRESS';
    const KEY_HISTORY_SUBURB_ADDRESS = 'KEY_HISTORY_SUBURB_ADDRESS';

    const clearList = () => {
        setSearchResult({
            properties: [],
            streets: [],
            suburbs: [],
        });
    };
    var handleInputInterval = 0;
    const handleInput = async (evt) => {
        setLoading(true);
        console.log(evt.target.value);

        const inputStr = evt.target.value;

        if (inputStr == '') {
            clearList();
        } else {
            setShowHistory(false);
        }
        setSelected({
            ...selected,
            label: inputStr,
        });

        console.log('start with number==>' + detectInputSearchStr(inputStr));
        const searchType = detectInputSearchStr(inputStr);
        if (searchType == 'N') {
            setSelected({
                ...selected,
                label: inputStr,
                type: 0,
            });
            const propertyList: any = await API.get(
                null,
                MAP_APIS_ENDPOINTS.SEARCH_ADDRESS_BY_KEY + '?address_strings=' + evt.target.value,
                '',
                {},
            );

            const searchedProperties = [];
            propertyList.forEach((history: any) => {
                searchedProperties.push({
                    label: history.address,
                    value: history.propId,
                });
            });
            const searchedSuburbs: any = await searchSuburbs(inputStr);
            setSearchResult({
                streets: [],
                suburbs: searchedSuburbs,
                properties: searchedProperties,
            });
        }

        if (searchType == 'S') {
            setSelected({
                ...selected,
                label: inputStr,
                type: 1,
            });
            const returnList: any = await API.get(
                null,
                MAP_APIS_ENDPOINTS.SEARCH_STREET_BY_KEY + '?street_strings=' + inputStr,
                '',
                {},
            );
            const searchedStreets = [];
            returnList.forEach((history: any) => {
                searchedStreets.push({
                    label: history.streetName,
                    value: history.streetId,
                });
            });
            const searchedSuburbs: any = await searchSuburbs(inputStr);
            setSearchResult({
                properties: [],
                suburbs: searchedSuburbs,
                streets: searchedStreets,
            });
        }
        setLoading(false);
    };
    const searchSuburbs = async (keyword) => {
        const searchedList: any = await api_querySuburbs(keyword);
        console.log(searchedList);
        const searchedSuburbs = [];
        if (searchedList && searchedList.length > 0) {
            searchedList.forEach((suburb: any) => {
                searchedSuburbs.push({
                    label: `${suburb.localityName} ${suburb.localityPost}`,
                    value: suburb.localityId,
                });
            });
        }

        return searchedSuburbs;
    };

    var currentPropertySearchedHistoryList = [];
    const cacheSelectedItem = async (choosedType, choosed) => {
        try {
            const cachedAddress: any = await storage.get(
                choosedType == '1'
                    ? KEY_HISTORY_PROPERTY_ADDRESS
                    : choosedType == '2'
                    ? KEY_HISTORY_STREET_ADDRESS
                    : KEY_HISTORY_SUBURB_ADDRESS,
            );
            currentPropertySearchedHistoryList = cachedAddress;
        } catch (e) {}

        console.log(currentPropertySearchedHistoryList);
        const inHistory = getItemFromArray(currentPropertySearchedHistoryList, 'value', choosed.value);

        if (!inHistory) {
            if (!currentPropertySearchedHistoryList) {
                currentPropertySearchedHistoryList = [];
            }
            if (currentPropertySearchedHistoryList.length >= 4) {
                currentPropertySearchedHistoryList = currentPropertySearchedHistoryList.slice(0, 3);
            }
            currentPropertySearchedHistoryList.unshift(choosed);

            await storage.save(
                choosedType == '1'
                    ? KEY_HISTORY_PROPERTY_ADDRESS
                    : choosedType == '2'
                    ? KEY_HISTORY_STREET_ADDRESS
                    : KEY_HISTORY_SUBURB_ADDRESS,
                currentPropertySearchedHistoryList,
            );
        }
    };
    const chooseProperty = (selectedProp) => {
        setSelected({
            type: 0,
            value: selectedProp.value,
            label: selectedProp.label,
        });
        cacheSelectedItem('1', { label: selectedProp.label, value: selectedProp.value });
        clearList();
        saveUserAccessLog('QUERY_PROP_STREET_SUBURB', securityProvider.getUserProfile()?.id, {
            queryType: '1',
            queryId: selectedProp.value,
        });
        changePropertyEvt(selectedProp);
    };

    const chooseStreet = (selectStreet) => {
        saveUserAccessLog('QUERY_PROP_STREET_SUBURB', securityProvider.getUserProfile()?.id, {
            queryType: '2',
            queryId: selectStreet.value,
        });
        window.open(
            `/mobileHome?queryType=2&queryId=${selectStreet.value}&queryAddress=${selectStreet.label}`,
            '_self',
        );

        cacheSelectedItem('2', selectStreet);
    };
    const chooseSuburb = (selectSuburb) => {
        console.log('query suburb is ');
        saveUserAccessLog('QUERY_PROP_STREET_SUBURB', securityProvider.getUserProfile()?.id, {
            queryType: '3',
            queryId: selectSuburb.value,
        });
        window.open(
            `/mobileHome?queryType=3&queryId=${selectSuburb.value}&queryAddress=${selectSuburb.label}`,
            '_self',
        );
        console.log(selectSuburb);
        cacheSelectedItem('3', selectSuburb);
    };

    const cancelSearch = () => {
        setSelected({
            ...selected,
            label: currentAddress,
        });
        setShowSearchInput(false);
        clearList();
    };
    const switchToSearch = async () => {
        setShowSearchInput(true);
        setSelected({
            ...selected,
            label: '',
        });
        const propertyHistories = await getMobileSearchedHistory(KEY_HISTORY_PROPERTY_ADDRESS);
        const streetHisotries = await getMobileSearchedHistory(KEY_HISTORY_STREET_ADDRESS);
        const suburbHistories = await getMobileSearchedHistory(KEY_HISTORY_SUBURB_ADDRESS);
        if (propertyHistories.length > 0 || streetHisotries.length > 0 || suburbHistories.length > 0) {
            setShowHistory(true);
        }
        setSearchResult({
            properties: propertyHistories,
            streets: streetHisotries,
            suburbs: suburbHistories,
        });
        setTimeout(() => {
            document.getElementById('searchInput').focus();
        }, 500);
    };
    const handleTabChange = (evt, newValue) => {
        console.log(newValue);
        setSelected({
            ...selected,
            type: newValue,
        });
    };
    return (
        <div className={classes.root}>
            {showSearchInput && (
                <div className={classes.searchBox}>
                    <img src="assets/mobileIcons/search_bar.svg"></img>
                    <div style={{ marginLeft: '4px', flexGrow: 1 }}>
                        <input
                            id="searchInput"
                            type="text"
                            value={selected.label}
                            onChange={handleInput}
                            className={classes.searchInput}></input>
                    </div>
                    {!loading && (
                        <span onClick={cancelSearch}>
                            <TR name="CANCEL" />
                        </span>
                    )}
                    {loading && <CircularProgress></CircularProgress>}
                </div>
            )}
            {!showSearchInput && (
                <div className={classes.staticAddressBar}>
                    <img style={{ width: 17, height: 13 }} src="assets/mobileIcons/house_grey.svg"></img>
                    <div className={classes.staticAddress}>{changeToFirstCaptial(selected.label)}</div>
                    <div className={classes.searchIcon} onClick={switchToSearch}>
                        <img src="assets/mobileIcons/searchIcon.svg" />
                    </div>
                </div>
            )}
            {(searchResult.properties.length > 0 ||
                searchResult.streets.length > 0 ||
                searchResult.suburbs.length > 0) && (
                <div className={classes.selection}>
                    {showHistory && (
                        <div className={classes.searchHistoryLabel}>
                            <TR name="searchHistoryTitle"></TR>
                        </div>
                    )}
                    {searchResult.properties.length > 0 && (
                        <div style={{ padding: 20, paddingBottom: 0 }}>
                            <div className={classes.searchTab}>
                                <img src="assets/mobileIcons/houses.png"></img>
                                <div style={{ marginLeft: '4px' }}></div>
                                <TR name="properties"></TR>
                            </div>

                            <div className={classes.subTitle}>
                                {searchResult.properties.slice(0, 5).map((property) => {
                                    return (
                                        <AddressSelectItem
                                            selectedEvt={chooseProperty}
                                            property={property}></AddressSelectItem>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {searchResult.streets.length > 0 && (
                        <div style={{ padding: 20, paddingBottom: 0 }}>
                            <div className={classes.searchTab}>
                                <img src="assets/mobileIcons/streets.svg"></img>
                                <div style={{ marginLeft: '4px' }}></div>
                                <TR name="streets"></TR>
                            </div>

                            <div className={classes.subTitle}>
                                {searchResult.streets.slice(0, 5).map((property) => {
                                    return (
                                        <AddressSelectItem
                                            selectedEvt={chooseStreet}
                                            property={property}></AddressSelectItem>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {searchResult.suburbs.length > 0 && (
                        <div style={{ padding: 20 }}>
                            <div className={classes.searchTab}>
                                <img src="assets/mobileIcons/suburbs.svg"></img>
                                <div style={{ marginLeft: '4px' }}></div>
                                <TR name="suburbs"></TR>
                            </div>

                            <div className={classes.subTitle}>
                                {searchResult.suburbs.slice(0, 5).map((property) => {
                                    return (
                                        <AddressSelectItem
                                            selectedEvt={chooseSuburb}
                                            property={property}></AddressSelectItem>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const AddressSelectItem = (props) => {
    const { property, selectedEvt } = props;
    const [over, setOver] = useState(false);
    const mouseOver = () => {
        setOver(true);
    };
    const mouseOut = () => {
        setOver(false);
    };

    const classes = useStyles();
    return (
        <div
            className={clsx(classes.selectItem, over ? classes.selecting : '')}
            onMouseOver={mouseOver}
            onMouseOut={mouseOut}
            onClick={() => {
                selectedEvt(property);
            }}>
            {property.label}
        </div>
    );
};

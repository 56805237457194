import * as React from 'react';
import { AppProvider } from '../../../commons/appProvider';
import { rst } from 'rt-state';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    qrCodeImg: {
        width: '75%',
    },
}));
export const QRCode = rst.create(() => {
    return () => {
        const classes = useStyles();
        const appProvider = AppProvider.use();

        if (appProvider.getLanguage() == 'zh') {
            return (
                <img
                    className={classes.qrCodeImg}
                    src={`${process.env.PUBLIC_URL}/static/images/loanQRcode.png`}
                    alt="data-ico.png"
                />
            );
        }
    };
});

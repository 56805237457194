import { rst } from 'rt-state';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SuburbPageState } from '../common';
import { OrderType, XTable, XTableColumn, XTableRow } from '../../../components/Table';
import { allTitleAndColumns } from './columns';
import { XText } from '../../../components/Text';
import { FilterOption } from '../../../models/filterOption';
import { Container } from '@material-ui/core';
import { SelectOption } from '../../../components/common';
import { getRankingList } from '../../../services/suburb';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            margin: theme.spacing(2),
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
        },
    };
});
// export interface ResultArr {
//     [key: string]: string;
// }

export const FilterGroupResult = rst.create<{
    suburbPageState: SuburbPageState;
}>((ctx) => {
    const tableState = rst.state<{
        // rows: XTableRow[];
        rows: any;
        title: string;
        columns?: XTableColumn[];
    }>({
        rows: [],
        title: null,
        columns: undefined,
    });

    const params: {
        tabOption: SelectOption;
        filterOption: FilterOption;
        page: number;
        rowsPerPage: number;
        order: OrderType;
        orderBy: string;
    } = {
        tabOption: null,
        filterOption: null,
        page: 0,
        rowsPerPage: 10,
        order: null,
        orderBy: null,
    };

    rst.watch(
        (values) => {
            const [tabOption, filterOption] = values;
            params.tabOption = tabOption;
            params.filterOption = filterOption;
            const titleAndColumns = allTitleAndColumns[tabOption.value][filterOption.value];
            tableState.title = titleAndColumns?.title;
            tableState.columns = titleAndColumns?.columns;
            getRows();
        },
        () => [ctx.w().suburbPageState.tabOption, ctx.w().suburbPageState.filterOption],
    );
    function onPaginationChange(page: number, rowsPerPage: number) {
        params.page = page;
        params.rowsPerPage = rowsPerPage;
        getRows();
    }
    function onSortChange(orderBy: string, order: OrderType) {
        params.order = order;
        params.orderBy = orderBy;
        getRows();
    }
    // TODO: get rows
    async function getRows() {
        // console.log('getRows:', params);
        // console.log('tableState.columns:', tableState.columns);
        const result = (await getRankingList(params.filterOption.value)) as { [key: string]: string }[];

        // const result = await getRankingList(params.filterOption.value);

        console.log('result:', result);
        tableState.rows = result;
    }
    return (props) => {
        const classes = useStyles(props);
        const { columns } = tableState;
        return (
            <div className={classes.root}>
                <Container>
                    <XText className={classes.title}> {columns ? tableState.title : '无排行榜'} </XText>
                    {columns && (
                        <XTable
                            rows={tableState.rows}
                            columns={columns}
                            initValues={{
                                page: params.page,
                                rowsPerPage: params.rowsPerPage,
                                order: params.order,
                                orderBy: params.orderBy,
                            }}
                            onPaginationChange={onPaginationChange}
                            onSortChange={onSortChange}
                        />
                    )}
                </Container>
            </div>
        );
    };
});

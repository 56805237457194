import { makeStyles, CircularProgress, Backdrop } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
// import { TR } from '../../../../commons';
import { PopupComponentForLabel } from './../../../PriceMap/components/popupComponentForLabel';
import { divide, property, filter } from 'lodash';
import { LandSize } from './../../../PriceMap/support/PriceMapConsts';
import { Landscape } from '@material-ui/icons';
import { convertToM } from '../../../../commons/utils';
import {
    Button,
    Grow,
    Paper,
    Popper,
    MenuItem,
    Menu,
    MenuList,
    ClickAwayListener,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { rst, setState } from 'rt-state';
import { clsx, formatDate, formatDateForEn, RRLink, SecurityProvider, TR } from '../../../../commons';
import { TrialButton } from '../../../auth/UserAuthButton/TrialButton';
import { LoginOrSignupButton } from '../../../auth/UserAuthButton/LoginOrSignupButton';
import { AppProvider } from '../../../../commons/appProvider';
import { ComparableHouseLabel } from './comparabelHouseLabel';
import { ComparableHouseLabelApt } from './comparabelHouseLabel_Apt';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
// import List from '@material-ui/core';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import { mobileNavButton } from '../../consts/styleConsts';
import {
    api_estimatePropertyValue,
    api_getAroundTranscations,
    api_getPropertyFeatures,
} from '../../../../services/property';
import { PriceMapCondition } from '../aroundPriceMap/priceMapCondition';
import { api_searchGoogleResult } from '../../../../commons/graphqlClient/graphAPI';
import { GoogleSearchDialog } from '../googleSearchDialog';
import { HomeOnToggleButton } from '../../../../components/ToggleButton';

const useStyles = makeStyles({
    root: {
        // padding: '16px',
        // display: 'flex',
        // justifyContent: 'space-between',
    },
    title: {
        paddingLeft: '16px',
        fontSize: '16px',
        fontWeight: 500,
        display: 'flex',
        // width: 161px;
        minHeight: '22px',
        fontFamily: 'PingFangSC, PingFangSC-Medium',
        textAlign: 'left',
        color: '#333333',
        lineHeight: '22px',
    },
    sorting: {
        padding: '8px 0px',
        fontSize: '15px',
        fontWeight: 600,
        display: 'flex',
        // width: 161px;
        minHeight: '22px',
        fontFamily: ' PingFangSC, PingFangSC-Semibold',
        textAlign: 'left',
        color: 'rgba(0,0,0,0.85)',
        lineHeight: '22px',
    },
    label: {
        dispaly: 'flex',
        alighContent: 'flex-start',
        padding: '16px',
    },
    link: {
        display: 'flex',
        maxwidth: '50px',
        borderBottom: '1px dashed',
    },
    linkShowing: {
        padding: '1.5px',
    },
    favoriteIcon: {
        padding: '',
    },
    line: {
        background: 'grey',
        width: '0.6px',
        height: '',
        positioni: 'relative',
        // left: '10px',
        float: 'left',
        margin: '0 4px',
    },
    labelLine1: {
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '1px 0',
    },
    labelLine3: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        border: 'none',
        background: 'none',
        padding: 'none',
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '50px',
    },
});

const options = ['Similarities', 'SoldDate', 'Distance', 'priceHighToLow', 'priceLowToHigh'];
const sortBy = ['similarity', 'date', 'distance', '-price', 'price'];

export const ComparableHouse = (props) => {
    const appProvider = AppProvider.use();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [googleSearchResult, setGooglSearchResult] = useState(null);
    const [showGoogleSearch, setShowGoogleSearch] = useState(false);
    const { property, showingAllComparable } = props;
    const {
        propId,
        propX,
        propY,
        __typename,
        PropBeds,
        propBath,
        propCarpark,
        propType,
        stdAddress,
        landAddress,
    } = property;
    console.log('tellme', property);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [onlySameProject, setOnlySameProject] = useState(false);
    const [onlyPassIn, setOnlyPassIn] = useState(false);
    const [apt, setApt] = useState(propType);

    const [similarTrans, setSimilarTrans] = useState([]);
    const [similarTransAll, setSimilarTransAll] = useState([]);
    const [displaySortmethod, setDisplaySortmethod] = useState('Similarities');

    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [conditions, setConditions] = useState({
        interval: 6,
        bedrooms: 0 as any,
    });

    const getAroundTrans = (sortByValue) => {
        api_getAroundTranscations(
            apt == 'Apt' ? 'Apt' : 'House',
            propY,
            propX,
            conditions.interval,
            propId,
            '1',
            '0',
            PropBeds,
            propCarpark,
            propBath,
            sortByValue,
        ).then((trans: any) => {
            setSimilarTrans(
                trans
                    .filter((tran: any) => {
                        return onlyPassIn ? tran.sold_info == 'Withdrawn' || tran.sold_info == 'Passed in' : true;
                    })
                    .filter((tran: any) => {
                        return onlySameProject ? tran.land_address == landAddress : true;
                    })
                    .slice(0, 5),
            );
        });
    };
    const getAroundTransAll = (sortByValue) => {
        setLoading(true);
        api_getAroundTranscations(
            apt == 'Apt' ? 'Apt' : 'House',
            propY,
            propX,
            conditions.interval,
            propId,
            '1',
            '0',
            PropBeds,
            propCarpark,
            propBath,
            sortByValue,
        ).then((trans: any) => {
            setSimilarTransAll(trans);
            setLoading(false);
        });
    };

    useEffect(() => {
        showingAllComparable ? getAroundTransAll('similarity') : getAroundTrans('similarity');
    }, []);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        console.log('index', index);
        const sortByValue = sortBy[index];
        setDisplaySortmethod(options[index]);
        console.log(index, sortByValue);
        showingAllComparable ? getAroundTransAll(sortByValue) : getAroundTrans(sortByValue);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleShowAllComparable = () => {
        EventBus.dispatch(EVENT_NAMES.EVENT_TRANSFER_SHOW_ALL_COMPARABLE, {
            showingAllComparable: showingAllComparable,
            property: property,
        });
    };

    const changeCondition = (type, value) => {
        if (type) {
            const tempConditions = conditions;
            tempConditions[type] = value;

            if (tempConditions.bedrooms == 0) {
                tempConditions.bedrooms = [];
            }

            setConditions(tempConditions);
        }

        getAroundTransAll('similarity');
    };

    const handleShowingAllComparable = () => {
        // EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_SHOW_LESS_COMPARABLE, { detail: showingAllComparable });
        // console.log(showingAllComparable);
        window.open(
            `/mobileHome?queryType=1&queryId=${propId}&queryAddress=${stdAddress}&showingDetails=false`,
            '_self',
        );
    };

    var inRequest = false;
    const searchGoogle = async (propId) => {
        if (!inRequest) {
            inRequest = true;
            const searchResult: any = await api_searchGoogleResult(propId);
            console.log(searchResult);
            inRequest = false;
            setGooglSearchResult(searchResult.data.property);
            setShowGoogleSearch(true);
        }
    };

    const switchToPassIn = (evt) => {
        setOnlyPassIn(evt.target.checked);
    };
    const switchToSameProject = (evt) => {
        setOnlySameProject(evt.target.checked);
    };

    console.log('tellyourself', onlySameProject);
    return (
        <>
            {/* {loading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100vh',
                        alignItems: 'center',
                    }}>
                    <CircularProgress size={128}></CircularProgress>
                </div>
            )} */}

            <>
                {showingAllComparable ? (
                    <div
                        style={{
                            marginTop: '20px',
                            marginLeft: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'flex-start',
                            paddingBottom: 20,
                        }}>
                        <Button
                            style={{
                                background: '#f5f5f5',
                                borderRadius: '16px',
                                // width: '80%',
                                height: 32,
                            }}
                            onClick={handleShowingAllComparable}>
                            <img
                                src="assets/mobileIcons/return_icon.svg"
                                style={{ width: '19px', height: '14px' }}></img>

                            <div>
                                <TR
                                    name="return"
                                    style={{
                                        marginLeft: '3px',
                                        width: '32px',
                                        height: 22,
                                        color: '#333333',
                                        fontSize: 16,
                                        fontFamily: 'PingFangSC, PingFangSC-Regular',
                                        fontWeight: 400,
                                        textAlign: 'left',
                                        lineHeight: 22,
                                    }}></TR>
                            </div>
                        </Button>
                    </div>
                ) : null}

                <div className={classes.top}>
                    <div className={classes.title}>
                        {showingAllComparable ? (
                            <PriceMapCondition
                                changeConditionEvt={changeCondition}
                                conditions={conditions}
                                comparableAll={true}
                            />
                        ) : (
                            <TR name="viewComparableProperties"></TR>
                        )}
                    </div>
                    {/* <div className={classes.title}>Top {similarTrans.length}</div> */}

                    <div className={classes.sorting}>
                        {/* Selected menu module from MUI */}{' '}
                        <List>
                            <ListItem
                                button
                                id="lock-button"
                                aria-haspopup="listbox"
                                aria-controls="lock-menu"
                                aria-label="when device is locked"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClickListItem}>
                                <ListItemText>
                                    <div className={classes.sorting}>
                                        <img src="assets/mobileIcons/px-ico.svg"></img>
                                        <div>
                                            {appProvider.getLanguage() == 'zh' && <TR name={displaySortmethod} />}
                                            <TR name="SORT"></TR>
                                        </div>
                                    </div>
                                </ListItemText>
                            </ListItem>
                        </List>
                        <Menu
                            id="lock-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'lock-button',
                                role: 'listbox',
                            }}>
                            {options.map((option, index) => (
                                <MenuItem
                                    key={option}
                                    selected={index === selectedIndex}
                                    onClick={(event) => handleMenuItemClick(event, index)}>
                                    <TR name={option}></TR>
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                </div>

                <div>
                    {apt == 'Apt' ? (
                        <ComparableHouseLabelApt
                            googleSearchEvt={searchGoogle}
                            similarTrans={(showingAllComparable
                                ? conditions.bedrooms[0]
                                    ? similarTransAll.filter((element) => element.prop_bed === conditions.bedrooms[0])
                                    : similarTransAll
                                : similarTrans
                            )
                                .filter((tran: any) => {
                                    return onlyPassIn
                                        ? tran.sold_info == 'Withdrawn' || tran.sold_info == 'Passed in'
                                        : true;
                                })
                                .filter((tran: any) => {
                                    return onlySameProject ? tran.land_address == landAddress : true;
                                })}
                        />
                    ) : (
                        <ComparableHouseLabel
                            googleSearchEvt={searchGoogle}
                            similarTrans={(showingAllComparable
                                ? conditions.bedrooms[0]
                                    ? similarTransAll.filter((element) => element.prop_bed === conditions.bedrooms[0])
                                    : similarTransAll
                                : similarTrans
                            ).filter((tran: any) => {
                                return onlyPassIn
                                    ? tran.sold_info == 'Withdrawn' || tran.sold_info == 'Passed in'
                                    : true;
                            })}
                        />
                    )}

                    <div style={{ paddingLeft: 21, paddingTop: 19 }}>
                        <HomeOnToggleButton
                            label="onlyPassIn"
                            checked={onlyPassIn}
                            switchEvt={switchToPassIn}></HomeOnToggleButton>
                    </div>
                    {apt == 'Apt' && (
                        <div style={{ paddingLeft: 21, paddingTop: 19 }}>
                            <HomeOnToggleButton
                                label="onlySameProject"
                                checked={onlySameProject}
                                switchEvt={switchToSameProject}></HomeOnToggleButton>
                        </div>
                    )}
                </div>

                {showingAllComparable ? null : (
                    <div
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'center',
                            paddingBottom: 20,
                        }}>
                        <Button
                            style={{
                                ...mobileNavButton,
                                background: '#009E90',
                                borderRadius: '4px',
                                width: '80%',
                                minHeight: 40,
                                color: '#FFFFFF',
                            }}
                            onClick={handleShowAllComparable}>
                            <TR name="moreComparableProp"></TR>
                        </Button>
                    </div>
                )}
            </>

            {
                <Backdrop style={{ color: '#ffffff', zIndex: 999999 }} open={loading} onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }

            {googleSearchResult && showGoogleSearch && (
                <GoogleSearchDialog
                    propCenter={{
                        lat: googleSearchResult.propY,
                        lon: googleSearchResult.propX,
                    }}
                    closeDialogEvt={() => {
                        setShowGoogleSearch(false);
                    }}
                    address={googleSearchResult.stdAddress}
                    searchResult={googleSearchResult.moreInfoGoogle}
                    open={showGoogleSearch}></GoogleSearchDialog>
            )}
        </>
    );
};

import { CardContent } from '@material-ui/core';
import React, { useState } from 'react';
import { setState } from 'rt-state';
import { Card14 } from '../../../PropertyReport/ReportCards/Card14';
import { CardTitle } from '../propertyDetails/cardTitle';
import Tabs, { Tab } from 'react-best-tabs';
import { makeStyles } from '@material-ui/styles';
import { TR } from '../../../../commons';
import { Card25 } from '../../../PropertyReport/ReportCards/Card25';
import { Card10 } from '../../../PropertyReport/ReportCards/Card10';
import { Card13 } from '../../../PropertyReport/ReportCards/Card13';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { CardPermission } from '../cardPermission';

const useStyles = makeStyles({
    tab: {
        fontSize: '8px',
        padding: '8px 10px !important',
    },
    tabs: {
        width: '100%',
    },
});
export const SuburbExplore = (props: any) => {
    const { localityData, property, propertyQL } = props;
    const [tab, setTab] = useState(1);
    console.log(localityData);
    const [closeContent, setCloseContent] = useState(false);
    const closeContentEvt = () => {
        setCloseContent(!closeContent);
    };

    const [hasPermission, setHasPermission] = useState(false);
    EventBus.on(EVENT_NAMES.EVENT_MOBILE_CARD_NOPERMISSION, (evtData: any) => {
        setHasPermission(evtData.permission);
    });
    const classes = useStyles();
    const changeTab = (evt, tab) => {
        setCloseContent(false);
        setTab(tab);
    };
    return (
        <div style={{ width: '100%', padding: '10px' }}>
            <CardTitle closeContent={closeContent} closeEvt={closeContentEvt} title={'Explore Suburb'}></CardTitle>
            <CardContent style={{ padding: 0 }}>
                <>
                    <Tabs
                        activeTab="1"
                        className={classes.tabs}
                        ulClassName=""
                        activityClassName="bg-success"
                        onClick={changeTab}>
                        <Tab title={<TR name="PeopleCharacters"></TR>} className={classes.tab}></Tab>
                        <Tab title={<TR name="WorkConv"></TR>} className={classes.tab}></Tab>
                        <Tab title={<TR name="BUSINESS"></TR>} className={classes.tab}></Tab>
                        <Tab title={<TR name="TIPS"></TR>} className={classes.tab}></Tab>
                    </Tabs>
                    {!closeContent ? (
                        <>
                            {tab == 1 ? <Card14 mobile={true} localityData={localityData.locality}></Card14> : null}
                            {tab == 2 ? (
                                <div style={{ position: 'relative', minHeight: 150 }}>
                                    {hasPermission ? (
                                        <Card25 mobile={true} prop={property} property={propertyQL.property}></Card25>
                                    ) : (
                                        <CardPermission></CardPermission>
                                    )}
                                </div>
                            ) : null}
                            {tab == 3 ? (
                                <div style={{ position: 'relative', minHeight: 150 }}>
                                    {hasPermission ? (
                                        <Card10 property={propertyQL}></Card10>
                                    ) : (
                                        <CardPermission></CardPermission>
                                    )}
                                </div>
                            ) : null}
                            {tab == 4 ? (
                                <div style={{ position: 'relative', minHeight: 150 }}>
                                    {hasPermission ? (
                                        <Card13 mobile propQL={propertyQL} prop={property}></Card13>
                                    ) : (
                                        <CardPermission></CardPermission>
                                    )}
                                </div>
                            ) : null}
                        </>
                    ) : null}
                </>
            </CardContent>
        </div>
    );
};

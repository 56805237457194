import { getLatlngArrayFromEncodedPath } from '../../../../commons/utils';
import { PropertyMarkerController } from '../../../PriceMap/MapContainer/PropertyMarkerController';
import ReactDOM from 'react-dom/server';
import React from 'react';
import { p_icon } from '../../../PriceMap/MapContainer/PropertyIcon';
import { RecentSoldHouseInfo } from '../../../PriceMap/Layers/PropertySidebar/house/RecentSoldHouseInfo';
import { UnitInfoSidebarBasic } from '../../../PriceMap/Layers/PropertySidebar/apartment/UnitInfoSidebarBasic';
import { api_getPropertyFeatures } from '../../../../services/property';
import { constructPropObjFromFeature } from '../../../PriceMap/support/propertyFeatureHelper';
const win: any = window;
const L = win.L;

export class SuburbSold {
    mapInstance = null;
    constructor(mapInstance) {
        this.mapInstance = mapInstance;
    }

    drawSuburbName(suburbName, suburbCenter) {
        const suburbNameDivIcon = new L.DivIcon({
            className: '',
            html: suburbName,
            iconAnchor: [20, 0],
        });

        const suburbNameMarker = L.marker([suburbCenter.lat, suburbCenter.lon], {
            icon: suburbNameDivIcon,
        }).addTo(this.mapInstance);
    }

    drawPoints(points) {
        console.log(points);
        points.forEach((point) => {
            let icon = '';
            if (point.type == 'train') {
                icon = 'train';
            }
            if (point.type == 'school') {
                icon = 'school';
            }

            const iconHTML = ReactDOM.renderToString(
                React.createElement(p_icon, {
                    reportMapCenter: true,
                    icon: icon,
                }),
            );

            const houseMarkerIcon = new L.DivIcon({
                className: '',
                html: iconHTML,
            });

            console.log(iconHTML);

            const houseMarker = L.marker([point.lat, point.lon]).addTo(this.mapInstance);
            houseMarker.setIcon(houseMarkerIcon);
        });
    }

    drawBorder(border) {
        const overAllBounds = [];

        border.forEach((encodedBorder: any) => {
            console.log(encodedBorder);
            const suburbBorderPath = getLatlngArrayFromEncodedPath(encodedBorder);

            const suburbPolygon = L.polygon(suburbBorderPath, {
                fillColor: 'white',
                //color: suburbColor,
                color: 'red',
                stoke: true,
                weight: 2,
                opacity: 1,
            }).addTo(this.mapInstance);

            overAllBounds.push(suburbPolygon._bounds._northEast);
            overAllBounds.push(suburbPolygon._bounds._southWest);
        });

        this.mapInstance.fitBounds(overAllBounds);
    }
    drawRecentSold(soldData, propType) {
        soldData[propType + 'Sold'].forEach((loopProperty, idx) => {
            const marker = L.marker([loopProperty.lat, loopProperty.lon]);

            const iconHTML = ReactDOM.renderToString(
                React.createElement(p_icon, {
                    propType: loopProperty.propType,
                    soldContractDate: loopProperty.sold_contract_date,
                    clicked: false,
                    notTrans: true,
                }),
            );

            const propertyIcon = new L.DivIcon({
                className: '',
                html: iconHTML,
            });

            marker.setIcon(propertyIcon).addTo(this.mapInstance);
            marker.on('click', () => {
                let p_id = '';
                if (propType == 'house') {
                    p_id = loopProperty.prop_id;
                } else {
                    p_id = loopProperty.properties[0].prop_id;
                }
                api_getPropertyFeatures(p_id).then((property: any) => {
                    console.log(property);
                    const propObj = constructPropObjFromFeature(property);
                    marker
                        .bindPopup(
                            L.popup({
                                minWidth: 230,
                                offset: [-3, -10],
                                className: 'm_prop',
                            })
                                .setContent(
                                    ReactDOM.renderToString(
                                        React.createElement(
                                            propType == 'house' ? RecentSoldHouseInfo : UnitInfoSidebarBasic,

                                            {
                                                prop: propObj,
                                                onMap: true,
                                            },
                                        ),
                                    ),
                                )
                                .on('remove', () => {
                                    marker.unbindPopup();
                                }),
                        )
                        .openPopup();
                });
            });
        });
    }
}

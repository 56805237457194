import axios from 'axios';
import './mock_adapter';
import { recordUserLog } from '../../services/userCenter';

const TOKEN_MIN_LEN = 6;

const axiosContext = {
    auth: null,
};

export const injectToken = (token: string) => {
    if (!!token && token.length >= TOKEN_MIN_LEN) {
        axiosContext.auth = `Bearer ${token}`;
    } else {
        axiosContext.auth = null;
    }
};

export function getErrorData(e) {
    return e.response.data;
}

axios.interceptors.request.use(
    (config) => {
        const auth = config.headers.Authorization as string;
        if (auth == null || !auth.startsWith('Basic')) {
            if (axiosContext.auth) {
                config.headers.Authorization = axiosContext.auth;
                recordUserLog().then();
            }
        }
        // console.log({ axiosContext });
        // console.log({ headers: config.headers });
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export { axios };

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { rst } from 'rt-state';
import { TR } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: '1.25rem 0 1.5rem',
            borderBottom: '1px solid #ccc',
        },
        title: {
            fontSize: '14px',
            lineHeight: '1.375rem',
            color: '#333',
            marginBottom: '12px',

            display: 'flex',
            alignItems: 'center',
        },
        content: {
            // width: '80vw',
            fontSize: '0.8125rem',
            lineHeight: '1.125rem',
            color: '#888',
            margin: '0 auto 10px',
        },
        img: {
            width: 17,
            verticalAlign: 'baseline',
            margin: '0 5px 0px 0',
        },
        line: {
            position: 'relative',
            width: '100%',
            borderBottom: '1px solid #333',
            marginTop: '7px',
        },
        arr: {
            width: '7px',
            height: '7px',
            position: 'absolute',
            left: '50%',
            bottom: '-2px',
            borderLeft: '1px solid #333',
            borderBottom: '1px solid #333',
            transform: 'rotate(-45deg) translateX(-50%)',
            background: '#fff',
        },
        button: {
            width: '7.5rem',
            height: '2rem',
            lineHeight: '2.375rem',
            fontSize: '1rem',
            backgroundColor: '#029F96',
            color: '#fff',
            border: 'none',
        },
    };
});
export const SearchStreet = rst.create<{ label?: string }>((ctx) => {
    const showFunc = () => {
        EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_SHOW_FUNC, { queryType: 2 });
    };
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                <div>
                    <div className={classes.title} style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            width="13"
                            height="16"
                            src={`assets/mobileIcons/streets.svg`}
                            alt=""
                            className={classes.img}
                        />
                        <div style={{ flex: 1 }}>
                            <TR name="mobile_SearchStreet"></TR>
                        </div>

                        <div onClick={showFunc} style={{ display: 'flex', alignItems: 'center' }}>
                            <TR name="mobile_CardBtn"></TR>
                            <img style={{ marginLeft: 3 }} src="assets/mobileIcons/rightArrow.svg"></img>
                        </div>
                    </div>
                </div>
                <div className={classes.content}>
                    <TR name="mobile_SearchStreetDesc"></TR>
                </div>
            </div>
        );
    };
});

import { API } from '../../../commons';
import { MAP_APIS_ENDPOINTS, setEndpointParams } from '../../../services/EndPoints';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { getLatlngArrayFromEncodedPath } from '../../../commons/utils';

const win: any = window;
const L = win.L;

export class MapRouteController {
    map: any;

    mapRouteGroup: any;
    constructor(map) {
        this.map = map;
        EventBus.on(EVENT_NAMES.EVENT_DRAW_MAP_ROUTE, async (evt) => {
            console.log(evt.points);

            const retrieveRouteURL = setEndpointParams(MAP_APIS_ENDPOINTS.GET_ROUTE_FROM_GOOGLE, {
                start_latlng: evt.points.start.lat + ',' + evt.points.start.lng,
                end_latlng: evt.points.end.lat + ',' + evt.points.end.lng,
            });
            const googleRoutes: any = await API.get(null, retrieveRouteURL);
            console.log(googleRoutes);
            if (googleRoutes) {
                if (!this.mapRouteGroup) {
                    this.mapRouteGroup = L.layerGroup();
                } else {
                    this.mapRouteGroup.clearLayers();
                }

                const route_overview = googleRoutes.routes[0].overview_polyline;
                const routePolylinePoints = getLatlngArrayFromEncodedPath(route_overview.points);
                // as google route put lng as first element, so need to reverse all element
                const reversedRoutePoint = [];
                routePolylinePoints.forEach((loopItem) => {
                    const lat = loopItem[1];
                    const lng = loopItem[0];
                    loopItem = [lat, lng];
                    reversedRoutePoint.push([lat, lng]);
                });
                console.log(reversedRoutePoint);
                console.log(route_overview);

                const mapRoute = L.polyline(reversedRoutePoint, { color: 'red' });
                const startMarker = L.marker([evt.points.start.lat, evt.points.start.lng]);
                const endMarker = L.marker([evt.points.end.lat, evt.points.end.lng]);
                this.mapRouteGroup.addLayer(mapRoute);
                this.mapRouteGroup.addLayer(startMarker);
                this.mapRouteGroup.addLayer(endMarker);

                this.mapRouteGroup.addTo(this.map);
            }
        });
    }
}

import { Theme } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';

const useStyles = makeStyles({
    root: {
        // border: 'rgb(176 209 255)',
        background: 'rgb(222 235 250)',
        borderRadius: '5px',
        padding: '3px 10px',
        minWidth: '46px',
        display: 'inline-block',
        margin: '5px 5px',
        '& div': {
            textAlign: 'center',
        },
        '& span': {
            fontSize: '13px',
            color: 'rgb(31 109 229)',
        },
    },
});

export const BlContentData = rst.create<{ content: any }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const { content } = props;
        return (
            <div className={classes.root}>
                <div>
                    <span>
                        <TR name={content}></TR>
                    </span>
                </div>
            </div>
        );
    };
});

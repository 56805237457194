import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { NewQuestionButton } from './NewQuestionButton';

const useStyles = makeStyles((theme) => ({
    outRoot: {
        background: 'rgb(249 249 249)',
        paddingBottom: '63px',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '0px',
        },
    },

    root: {
        margin: '0 auto',
        width: '1200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& h2': {
            fontSize: '26px',
            margin: '57px 0px 0px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '19px',
                margin: '0px 0px 0px 0px',
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: '28px 16px',
            width: '100vw',
        },
    },
    questionContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    questions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px 10px 10px 25px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            margin: '0px 0px 0px 0px',
        },
    },
    answers: {
        background: 'rgb(255 255 255)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '980px',
        padding: '36px 96px 49px 86px',
        [theme.breakpoints.down('sm')]: {
            padding: '22px 5px 21px 58px',
            width: '100%',
        },

        '& h3': {
            fontSize: '20px',
            marginBottom: '11px',
            marginTop: '0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '16px',
            },
        },
        // '& h4': {
        //     fontSize: '14px',
        //     margin: '0px 0px 28px 0px',
        //     color: 'rgb(132 132 132)',
        //     fontWeight: 400,
        // },
        '& p': {
            fontSize: '16px',
            margin: '0px 0px 0px 0px',
            color: 'rgb(83 83 83)',
            fontWeight: 400,
            lineHeight: '22px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px',
                lineHeight: '18px',
            },
        },
    },
}));
export const NewHomeAnswer = rst.create((ctx) => {
    const buttonState = rst.state({
        NewHome_AnswerQuestion: {
            NewHome_AnswerQuestion1: true,
            NewHome_AnswerQuestion2: false,
            NewHome_AnswerQuestion3: false,
            NewHome_AnswerQuestion4: false,
        },
        NewHome_AnswerSubHeader: 'NewHome_AnswerSubHeader1',
        NewHome_AnswerRole: 'NewHome_AnswerRole1',
        NewHome_AnswerContent: 'NewHome_AnswerContent1',
    });
    // below should be optimized by map with using [i];
    const handleClick1 = () => {
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion1 = true;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion2 = false;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion3 = false;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion4 = false;
        buttonState.NewHome_AnswerSubHeader = 'NewHome_AnswerSubHeader1';
        buttonState.NewHome_AnswerRole = 'NewHome_AnswerRole1';
        buttonState.NewHome_AnswerContent = 'NewHome_AnswerContent1';
    };
    const handleClick2 = () => {
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion1 = false;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion2 = true;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion3 = false;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion4 = false;
        buttonState.NewHome_AnswerSubHeader = 'NewHome_AnswerSubHeader2';
        buttonState.NewHome_AnswerRole = 'NewHome_AnswerRole2';
        buttonState.NewHome_AnswerContent = 'NewHome_AnswerContent2';
    };
    const handleClick3 = () => {
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion1 = false;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion2 = false;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion3 = true;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion4 = false;
        buttonState.NewHome_AnswerSubHeader = 'NewHome_AnswerSubHeader3';
        buttonState.NewHome_AnswerRole = 'NewHome_AnswerRole3';
        buttonState.NewHome_AnswerContent = 'NewHome_AnswerContent3';
    };
    const handleClick4 = () => {
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion1 = false;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion2 = false;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion3 = false;
        buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion4 = true;
        buttonState.NewHome_AnswerSubHeader = 'NewHome_AnswerSubHeader4';
        buttonState.NewHome_AnswerRole = 'NewHome_AnswerRole4';
        buttonState.NewHome_AnswerContent = 'NewHome_AnswerContent4';
    };
    const list = [handleClick1, handleClick2, handleClick3, handleClick4];
    let i = 0;
    let timer;
    const loopClick = () => {
        timer = setInterval(function () {
            list[i]();
            // console.log(i);
            i++;
            i %= list.length;
        }, 4000);
    };
    const stopLoop = () => {
        clearInterval(timer);
        // console.log('??');
        setTimeout(() => {
            // console.log('!!');
            loopClick();
        }, 6000);
    };
    loopClick();
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.outRoot}>
                <div className={classes.root}>
                    <h2>
                        <TR name="NewHome_AnswerHeader" />
                    </h2>
                    <div className={classes.questionContent} onClick={stopLoop}>
                        <div className={classes.questions}>
                            <NewQuestionButton
                                // isClicked={true}
                                isClicked={buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion1}
                                label="NewHome_AnswerQuestion1"
                                onClick={handleClick1}
                            />
                            <NewQuestionButton
                                isClicked={buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion2}
                                label="NewHome_AnswerQuestion2"
                                onClick={handleClick2}
                            />
                            <NewQuestionButton
                                isClicked={buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion3}
                                label="NewHome_AnswerQuestion3"
                                onClick={handleClick3}
                            />
                            <NewQuestionButton
                                isClicked={buttonState.NewHome_AnswerQuestion.NewHome_AnswerQuestion4}
                                label="NewHome_AnswerQuestion4"
                                onClick={handleClick4}
                            />
                        </div>
                        <div className={classes.answers}>
                            <h3>
                                <TR name={buttonState.NewHome_AnswerSubHeader} />
                            </h3>
                            {/* <h4>
                                <TR name={buttonState.NewHome_AnswerRole} />
                            </h4> */}
                            <p>
                                <TR name={buttonState.NewHome_AnswerContent} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
});

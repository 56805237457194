import { CardContent } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { api_getProjectSolds } from '../../../../services/property';
import { CardTitle } from './cardTitle';
import { convertToM } from '../../../../commons/utils';
import { InfoLabel } from '../standard/label';
import { UnitCard } from '../../../PriceMap/Layers/PropertySidebar/apartment/UnitCards';
import { PropertyCard } from '../propertyCard';
import { CardLine } from '../cardSeperate';
import { TR } from '../../../../commons';

export const ProjectSolds = (props: any) => {
    const { projectId } = props;
    const [projectData, setProjectData] = useState(null);
    useEffect(() => {
        api_getProjectSolds(projectId, 6).then((result: any) => {
            setProjectData(result);
            console.log(result.project_sold);
        });
    }, []);
    const [closeContent, setCloseContent] = useState(true);
    const closeContentEvt = () => {
        setCloseContent(!closeContent);
    };
    return (
        <div style={{ width: '100%', padding: 10 }}>
            <CardTitle title="projectSoldHalfYear" closeContent={closeContent} closeEvt={closeContentEvt}></CardTitle>
            <CardContent>
                {projectData && (!projectData.project_sold || projectData.project_sold.length == 0) ? (
                    <>{<TR name="projectNoSoldHalfYear"></TR>}</>
                ) : (
                    <>
                        {closeContent ? (
                            <>
                                {projectData && (
                                    <div>
                                        <TR name="projectMedianPriceHalfYear"></TR>：$
                                        {convertToM(projectData.project_median)}m
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {projectData &&
                                    projectData.project_sold.map((unit) => {
                                        return (
                                            <>
                                                <PropertyCard embedded propId={unit.prop_id}></PropertyCard>
                                                <CardLine forCard></CardLine>
                                            </>
                                        );
                                    })}
                            </>
                        )}
                    </>
                )}
            </CardContent>
        </div>
    );
};

import * as React from 'react';
import { Box, Button } from '@material-ui/core';
import { rst } from 'rt-state';
import * as locales from '@material-ui/core/locale';
import { TR } from '../../commons';
import { AppProvider } from '../../commons/appProvider';

export const LanguageDemo = rst.create(() => {
    const appProvider = AppProvider.use();
    function changeChinese() {
        appProvider.setLocaleAndSave(locales.zhCN);
    }
    function changeEnglish() {
        appProvider.setLocaleAndSave(locales.enUS);
    }
    function changeJapanese() {
        appProvider.setLocaleAndSave(locales.jaJP);
    }
    const currentLanguage = () => {
        switch (appProvider.getLocale()) {
            case locales.enUS:
                return 'English';
            case locales.zhCN:
                return '中文';
            case locales.jaJP:
                return 'Japanese';
            default:
                return 'Unknown';
        }
    };

    return (props) => {
        return (
            <>
                <Box pt={6} />
                <div>
                    <div>current language: {currentLanguage()}</div>
                    (<TR name={'Test'} />)
                </div>
                <Button onClick={changeChinese}>中文</Button>
                <Button onClick={changeEnglish}>English</Button>
                <Button onClick={changeJapanese}>Japanese</Button>
            </>
        );
    };
});

import { CardContent } from '@material-ui/core';
import React, { useState } from 'react';
import { TR, TRHtml } from '../../../../commons';
import { CardLine } from '../cardSeperate';
import { CardTitle } from '../propertyDetails/cardTitle';
import { StreetCard } from '../streetCard';

export const StreetInSuburb = (props: any) => {
    const { suburbData } = props;
    console.log(suburbData);
    const [closeContent, setCloseContent] = useState(true);
    const closeContentEvt = () => {
        setCloseContent(!closeContent);
    };

    const presentStreets = () => {
        const content = [];
        suburbData.streetCards?.map((street) => {
            content.push(
                <div>
                    <StreetCard propId={street.streetId} embedded></StreetCard>

                    <div style={{ marginTop: 15 }}></div>
                    <CardLine></CardLine>
                </div>,
            );
        });
        return content;
    };
    return (
        <div style={{ width: '100%', padding: '10px' }}>
            <CardTitle closeContent={closeContent} closeEvt={closeContentEvt} title={'StreetSuburbTitle'}></CardTitle>
            <CardContent style={{ padding: 0 }}>
                {closeContent ? <TR name="summary_streetSuburb"></TR> : <>{presentStreets()}</>}
            </CardContent>
        </div>
    );
};

import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { TR } from '../../../../commons';
import { PropertyPriceLegendList } from '../../../PriceMap/support/PriceMapConsts';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        zIndex: 499,
        right: '11px',
        bottom: '15px',
        background: '#f5f5f5',
        borderRadius: '4px',
        boxShadow: '0px 2px 6px 0px rgba(1,2,2,0.16)',
        padding: '10px 6px',
    },
    legendCircel: {
        width: '8px',
        height: '8px',
        borderRadius: '50%',
    },
    legendLabel: {
        fontSize: '12px',
        color: '#333333',
    },
});
export const MapLegendForPrice = (props: any) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {PropertyPriceLegendList.map((legend) => {
                return (
                    <>
                        <div style={{ background: legend.color }} className={classes.legendCircel}></div>
                        <div style={{ marginLeft: 3 }}></div>
                        {!legend.min && legend.max ? '<' + legend.max + 'm' : ''}
                        {legend.min && legend.max ? legend.min + '~' + legend.max + 'm' : ''}
                        {legend.min && !legend.max ? '>' + legend.min + 'm' : ''}
                        <div style={{ marginLeft: 7 }}></div>
                    </>
                );
            })}
        </div>
    );
};

import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { TR } from '../../../../commons';
import {
    getIncomeDesc,
    getPrimarySchoolDesc,
    getSecondarySchoolDesc,
    getShoppingCenterDesc,
    getTranDesc,
} from '../../../PriceMap/support/common';
import { InfoValue } from '../standard/infoValue';
import { InfoLabel } from '../standard/label';
const useStyles = makeStyles({
    root: {
        display: 'flex',
        padding: '10px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
    },
    font1: {
        fontWeight: 700,
        fontSize: 16,
    },
    font2: {
        fontSize: '13px',
        color: '#888888',
    },
    value: {
        fontSize: '13px',
        color: '#000000',
    },
});
export const SuburbBasicInfo = (props: any) => {
    const classes = useStyles();
    const { suburbData, suburbQL, part } = props;

    console.log(suburbQL);

    return (
        <div className={classes.root}>
            {!part ? (
                <>
                    <div className={classes.font1}>{suburbData.locality_name}</div>

                    <div className={classes.font2} style={{ display: 'flex', marginTop: '6px', width: '100%' }}>
                        <div style={{ flex: 1, display: 'flex' }}>
                            <InfoLabel>distanceToCBD</InfoLabel>
                            <InfoValue>{suburbData.cbd_distance}km</InfoValue>
                        </div>
                        <div style={{ flex: 1, display: 'flex' }}>
                            <InfoLabel>TRAIN_STATION</InfoLabel>
                            <InfoValue>
                                {' '}
                                <TR name={getTranDesc(suburbData.train_station)}></TR>
                            </InfoValue>
                        </div>
                    </div>

                    <div className={classes.font2} style={{ display: 'flex', marginTop: '6px', width: '100%' }}>
                        <div style={{ flex: 1, display: 'flex' }}>
                            <InfoLabel>totalHouse</InfoLabel>
                            <InfoValue>{suburbQL.property.locality.houseTotal}</InfoValue>
                        </div>
                        <div style={{ flex: 1, display: 'flex' }}>
                            <InfoLabel>TotalNumberOfApartment</InfoLabel>
                            <InfoValue>{suburbQL.property.locality.flatApartmentTotal}</InfoValue>
                        </div>
                    </div>
                </>
            ) : null}
            {props.inDetails ? (
                <>
                    <div style={{ flex: 1, marginTop: 20, display: 'flex' }}>
                        <InfoLabel>Card10_shop</InfoLabel>
                        <InfoValue>
                            <TR name={getShoppingCenterDesc(suburbData.rate_shopping)}></TR>
                        </InfoValue>
                    </div>
                </>
            ) : null}

            {props.inDetails ? null : (
                <>
                    {!part ? (
                        <>
                            <div
                                className={classes.font2}
                                style={{ marginTop: 20, display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ flex: 1, display: 'flex' }}>
                                    <InfoLabel>FAMILY_INCOME</InfoLabel>
                                    <InfoValue>
                                        <TR name={getIncomeDesc(suburbData.family_income)}></TR>
                                    </InfoValue>
                                </div>
                                <div style={{ flex: 1, display: 'flex' }}>
                                    <InfoLabel>Rent_pct</InfoLabel>
                                    <InfoValue>{(suburbData.rental_pct * 100).toFixed(1)}%</InfoValue>
                                </div>
                                <div style={{ flex: 1, display: 'flex' }}>
                                    <InfoLabel>medianAge</InfoLabel>
                                    <InfoValue>{suburbQL.property.locality.medianAge}</InfoValue>
                                </div>
                            </div>

                            <div className={classes.font2} style={{ marginTop: 20, display: 'flex' }}>
                                <InfoLabel>religions</InfoLabel>

                                <div className={classes.value} style={{ display: 'flex' }}>
                                    {suburbQL.property.locality?.religionRank?.map((religion, idx) => {
                                        return idx < 3 ? (
                                            <div style={{ marginLeft: 5 }}>
                                                <InfoValue>
                                                    <TR name={religion.religionName}></TR>:{' '}
                                                    {(religion.religionPercent * 100).toFixed(0)}%
                                                </InfoValue>
                                            </div>
                                        ) : null;
                                    })}
                                </div>
                            </div>

                            <div className={classes.font2} style={{ marginTop: 5, display: 'flex' }}>
                                <InfoLabel>nationalities1</InfoLabel>

                                <div className={classes.value} style={{ display: 'flex' }}>
                                    {suburbQL.property.locality?.ancestryRank?.map((ancestry, idx) => {
                                        return idx < 3 ? (
                                            <div style={{ marginLeft: 5 }}>
                                                <InfoValue>
                                                    <TR name={ancestry.topAncestryName}></TR>:{' '}
                                                    {(ancestry.topAncestryPct * 100).toFixed(0)}%
                                                </InfoValue>
                                            </div>
                                        ) : null;
                                    })}
                                </div>
                            </div>
                        </>
                    ) : null}

                    {!part || part == 'school' ? (
                        <>
                            <div
                                className={classes.font2}
                                style={{ marginTop: part == 'school' ? 0 : 20, display: 'flex' }}>
                                <InfoLabel>primarySchoolCatchment</InfoLabel>
                                <div className={classes.value} style={{ marginLeft: 5, display: 'flex' }}>
                                    {suburbQL.property.primarySchools.length}
                                    {suburbQL.property.primarySchools.length > 1 ? (
                                        <TR name="schoolUnit"></TR>
                                    ) : (
                                        <TR name="schoolUnit1"></TR>
                                    )}
                                    <div style={{ marginLeft: 15 }}>
                                        <InfoValue>
                                            <TR name="rank"></TR> :{' '}
                                            {suburbQL.property.primarySchools.map((school, idx) => {
                                                const yearlyRanking = school.yearlyRanking?.ranking;
                                                console.log(yearlyRanking);
                                                if (yearlyRanking) {
                                                    return (
                                                        yearlyRanking[yearlyRanking?.length - 1] +
                                                        (idx == suburbQL.property.primarySchools.length - 1 ? '' : ',')
                                                    );
                                                } else {
                                                    return <>N/A</>;
                                                }
                                            })}
                                        </InfoValue>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.font2} style={{ marginTop: 5, display: 'flex' }}>
                                <InfoLabel>secondarySchoolCatchment</InfoLabel>
                                <InfoValue>
                                    {' '}
                                    <TR name={getSecondarySchoolDesc(suburbData.score_secondary)}></TR>
                                </InfoValue>
                            </div>
                        </>
                    ) : null}
                    {!part ? (
                        <div style={{ flex: 1, marginTop: 20, display: 'flex' }}>
                            <InfoLabel>Card10_shop</InfoLabel>
                            <InfoValue>
                                <TR name={getShoppingCenterDesc(suburbData.rate_shopping)}></TR>
                            </InfoValue>
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};

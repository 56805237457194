import { makeStyles } from '@material-ui/core';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { TR } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { changeToFirstCaptial, convertToM } from '../../../../commons/utils';

import { PrimarySchoolInfo } from '../../../PriceMap/components/popupComponentForLabel';
import { MobileAroundpricemap } from '../../../PropertyReport/components/reportMap/MobileAroundpricemap';
import { MapLegendForPrice } from './mapLegendForPrice';
import { MapLegendForPropertyType } from './mapLegendForProeprtyType';
import { api_searchGoogleResult } from '../../../../commons/graphqlClient/graphAPI';
import { GoogleSearchDialog } from '../googleSearchDialog';
import { FullScreenAdjacentMap } from './fullscreenAdjacentMap';
import { Fullscreen } from '@material-ui/icons';

const win: any = window;
const L = win.L;
const useStyles = makeStyles({
    link: {
        display: 'flex',
        maxwidth: '50px',
        borderBottom: '1px dashed',
    },
    linkShowing: {
        padding: '1.5px',
    },
    title: {
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'PingFangSC, PingFangSC-Medium',
        color: '#333333',
    },
    tableName: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#333333',
        marginTop: '3pt',
        marginBottom: '3pt',
        textAlign: 'left',
        fontFamily: 'PingFangSC, PingFangSC-Medium',
    },
    tableRow: {
        display: 'flex',
        justifyContent: 'flex-start',
    },

    tableCell: {
        fontSize: '13px',
        fontWeight: 400,
        color: '#333333',
        padding: '3px 11px 3px 10px',
        margin: '6px 9px 6px 0px',
        backgroundColor: '#F1F1F2',
        borderRadius: '4px',
        width: 'fit-content',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        textSlign: 'left',
    },
    content: {
        fontSize: '14px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        textSlign: 'left',
        color: '#666666',
    },
});

export const LeafletAdjacentMap = (props) => {
    const { propCenter, drawingData, redrawVar, mapId, full } = props;
    const [showDialog, setShowDialog] = useState(false);
    const [primarySchool, setPrimarySchool] = useState(null);
    const [schoolType, setSchoolType] = useState('');
    const [googleSearchResult, setGooglSearchResult] = useState(null);
    const [showGoogleSearch, setShowGoogleSearch] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    var mapInstance = null;
    var mobileAroundSoldMap = null;

    const noLableLayer = L.tileLayer(
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png',
        {
            attribution: false,
        },
    );

    const clearMap = () => {
        for (const i in mapInstance._layers) {
            if (mapInstance._layers[i]._path != undefined) {
                try {
                    mapInstance.removeLayer(mapInstance._layers[i]);
                } catch (e) {
                    console.log('problem with ' + e + mapInstance._layers[i]);
                }
            }
        }
    };

    useEffect(() => {
        drawData();
    }, [drawingData.solds, redrawVar]);

    const initMap = () => {
        var container = L.DomUtil.get('adjacentMap_' + mapId);
        if (container != null) {
            container._leaflet_id = null;
        }

        if (mapInstance == null && container != null) {
            try {
                mapInstance = L.map('adjacentMap_' + mapId, {
                    zoomControl: false,
                    doubleClickZoom: false,

                    scrollWheelZoom: full,
                    attribution: false,
                    touchZoom: full,
                    dragging: false,
                }).setView([propCenter.lat, propCenter.lon], 14);
                mapInstance.addLayer(noLableLayer);
            } catch (e) {
                console.log(e);
            }
        }
    };
    useEffect(() => {
        setTimeout(() => {
            initMap();

            const copyrightElements = document.getElementsByClassName('leaflet-control-attribution') as any;
            console.log('copy right layer is ' + copyrightElements.length);
            for (let i = 0; i < copyrightElements.length; i++) {
                const copyrightElement = copyrightElements[i];
                if (copyrightElement) {
                    copyrightElement.style.display = 'none';
                }
            }

            if (mobileAroundSoldMap == null) {
                mobileAroundSoldMap = new MobileAroundpricemap(mapInstance, mapId);
            }
        }, 300);

        setTimeout(() => {
            drawData();
        }, 500);

        return () => {
            console.log('clean up the map');
        };
    }, []);

    const drawData = () => {
        console.log(drawingData.solds);
        if (!drawingData.solds) {
            return;
        }
        const houseMarkerIcon = new L.DivIcon({
            className: '',
            html:
                '<div style="margin-left:-10px;margin-top:-10px"><img src="/assets/mobileIcons/houseSelf.svg" /></div>',
        });
        console.log('map instance is ' + mapInstance);
        if (mapInstance == null) {
            initMap();

            const copyrightElements = document.getElementsByClassName('leaflet-control-attribution') as any;
            console.log('copy right layer is ' + copyrightElements.length);
            for (let i = 0; i < copyrightElements.length; i++) {
                const copyrightElement = copyrightElements[i];
                if (copyrightElement) {
                    copyrightElement.style.display = 'none';
                }
            }

            if (mobileAroundSoldMap == null) {
                mobileAroundSoldMap = new MobileAroundpricemap(mapInstance, mapId);
            }
        }
        if (mapInstance != null) {
            console.log(mapInstance);
            const houseMarker = L.marker([propCenter.lat, propCenter.lon]).addTo(mapInstance);
            houseMarker.setIcon(houseMarkerIcon);

            if (drawingData.distance == 500) {
                mapInstance.setZoom(15);
            }
            if (drawingData.distance == 1000) {
                mapInstance.setZoom(14);
            }
            if (drawingData.distance > 0) {
                mapInstance.setView([propCenter.lat, propCenter.lon]);
                mobileAroundSoldMap.drawCircle(drawingData.distance, propCenter);
            } else {
                mobileAroundSoldMap.drawSuburbBorder(drawingData.suburbBorder);
            }
            mobileAroundSoldMap.drawSolds(drawingData.solds, drawingData.showPrice, drawingData.distance);
            mobileAroundSoldMap.drawSchools(drawingData.schools, drawingData.showSchool);
        }
    };
    EventBus.on(EVENT_NAMES.SHOW_PRIMARY_INFO_FOR_MAP + mapId, (evt) => {
        console.log(evt.school);
        setPrimarySchool(evt.school);
        setSchoolType(evt.type);
        setShowDialog(true);
    });
    console.log('primaryschoolmap', primarySchool);
    const classes = useStyles();

    var inRequest = false;
    const searchGoogle = async (propId) => {
        if (!inRequest) {
            inRequest = true;
            const searchResult: any = await api_searchGoogleResult(propId);
            console.log(searchResult);
            inRequest = false;
            setGooglSearchResult(searchResult.data.property);
            setShowGoogleSearch(true);
        }
    };
    const showFullMap = () => {
        setFullScreen(true);
    };

    return (
        <div id={'adjacentMap_' + mapId} style={{ width: '100%', height: full ? '91vh' : 343 }}>
            {drawingData.bedrooms == 0 && drawingData.distance != 0 && (
                <MapLegendForPropertyType></MapLegendForPropertyType>
            )}
            {drawingData.distance == 0 && <MapLegendForPrice></MapLegendForPrice>}
            {!fullScreen && !full && (
                <div onClick={showFullMap} style={{ position: 'absolute', left: '10px', top: '10px', zIndex: 999 }}>
                    <img src="/assets/icons/fullScreen.svg"></img>
                </div>
            )}

            <PrimarySchoolInfo
                closeDialogEvt={() => {
                    setShowDialog(false);
                }}
                link={
                    <div className={classes.link}>
                        <div className={classes.linkShowing}>
                            <TR name={'schoolStarRating'}></TR>
                        </div>
                        <div className={classes.linkShowing}>{primarySchool?.starRating}</div>
                    </div>
                }
                content={
                    <div className={classes.content}>
                        {schoolType == 'pri' ? (
                            <div>
                                <p>
                                    <TR name={'primarySchStarRatingSource'}></TR>
                                </p>
                                <p>
                                    <TR name={'icseaIntrol'}></TR>
                                </p>
                            </div>
                        ) : null}
                    </div>
                }
                title={
                    <>
                        <div className={classes.title}>
                            <TR
                                name={
                                    schoolType == 'pri'
                                        ? 'primaryRanking'
                                        : schoolType == 'sec'
                                        ? 'SecondarySchool'
                                        : schoolType == 'sel'
                                        ? 'SelectiveSchool'
                                        : schoolType == 'mapHouse'
                                        ? 'SoldPrice'
                                        : 'PrivateSchool'
                                }></TR>
                            {schoolType == 'mapHouse' ? '$' + convertToM(primarySchool.soldPrice) + 'm' : ''}
                        </div>
                    </>
                }
                table={
                    <div>
                        <div>
                            {primarySchool?.schoolName ? (
                                <p className={classes.tableName}>{primarySchool?.schoolName}</p>
                            ) : (
                                <p className={classes.tableName}>{primarySchool?.fullAddress}</p>
                            )}
                        </div>

                        <div className={classes.tableRow}>
                            {primarySchool?.soldContractDate ? (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignContent: 'space-around',
                                            gap: '10px',
                                            paddingBottom: 10,
                                            flexWrap: 'wrap',
                                        }}>
                                        <div className={classes.tableCell}>
                                            <TR name="SoldContractDate"></TR>: {primarySchool.soldContractDate}
                                        </div>
                                        <div className={classes.tableCell}>
                                            <TR name="Price"></TR> : ${convertToM(primarySchool.soldPrice)}m
                                        </div>
                                        <div className={classes.tableCell} style={{ display: 'flex' }}>
                                            <img src="/assets/mobileIcons/ws-ico@1x.svg"></img>
                                            <div style={{ marginLeft: 5 }}> {primarySchool.propBed}</div>
                                            <div style={{ marginLeft: 10 }}></div>
                                            <img src="/assets/mobileIcons/my-ico@1x.svg"></img>
                                            <div style={{ marginLeft: 5 }}> {primarySchool.propBath}</div>

                                            <div style={{ marginLeft: 10 }}></div>
                                            <img src="/assets/mobileIcons/cz-ico@1x.svg"></img>
                                            <div style={{ marginLeft: 5 }}> {primarySchool.propCarpark}</div>
                                        </div>
                                        <div
                                            onClick={() => {
                                                searchGoogle(primarySchool.propId);
                                            }}
                                            className={classes.tableCell}
                                            style={{
                                                height: '22px',
                                                fontSize: '14px',
                                                fontFamily: 'PingFangSC, PingFangSC-Regular',
                                                fontWeight: 400,
                                                textAlign: 'left',
                                                color: '#333330',
                                                lineHeight: '22px',

                                                backgroundColor: '#ffffff',
                                            }}>
                                            <TR name="googleSearch" />
                                            <img
                                                style={{
                                                    width: '14px',
                                                    height: '14px',
                                                    color: '#3d3d3d',
                                                    paddingLeft: '3px',
                                                }}
                                                src="/assets/mobileIcons/rightArrow.svg"></img>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {primarySchool?.schoolName ? (
                                <p className={classes.tableCell}>
                                    {' '}
                                    {primarySchool?.starRating ? (
                                        <>
                                            <TR name={'schoolStarRating'}></TR> {'   '} {primarySchool?.starRating}{' '}
                                            <img
                                                src="/assets/images/svgs/gold_star.svg"
                                                style={{ height: '15px', paddingTop: '3px', paddingLeft: '2px' }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <TR name={'schoolStarRating'}></TR> {'   '}1{' '}
                                            <img
                                                src="/assets/images/svgs/gold_star.svg"
                                                style={{ height: '15px', paddingTop: '3px', paddingLeft: '2px' }}
                                            />
                                        </>
                                    )}
                                </p>
                            ) : null}
                            {primarySchool?.icsea ? (
                                <p className={classes.tableCell}>
                                    <TR name={'Card21_icsea'}></TR>
                                    {'   '} {primarySchool?.icsea}
                                </p>
                            ) : null}
                        </div>
                    </div>
                }
                maxWidth="xl"
                openDialog={showDialog}></PrimarySchoolInfo>
            {googleSearchResult && showGoogleSearch && (
                <GoogleSearchDialog
                    propCenter={{
                        lat: googleSearchResult.propY,
                        lon: googleSearchResult.propX,
                    }}
                    closeDialogEvt={() => {
                        setShowGoogleSearch(false);
                    }}
                    address={googleSearchResult.stdAddress}
                    searchResult={googleSearchResult.moreInfoGoogle}
                    open={showGoogleSearch}></GoogleSearchDialog>
            )}

            <FullScreenAdjacentMap
                closeFull={() => {
                    setFullScreen(false);
                }}
                mapProps={props}
                open={fullScreen}
                propCenter={propCenter}></FullScreenAdjacentMap>
        </div>
    );
};

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { rst } from 'rt-state';
import { clsx, SecurityProvider, TR } from '../../../../commons';
import { AppProvider } from '../../../../commons/appProvider';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { Permission } from '../../../../commons/security/authz';
import { getPct, isMobileDevice, numToMillion, toPct } from '../../../../commons/utils';
import { Chip } from '../../../../components/Chip';
import { string } from '../../../../components/form';
import { getUserProfile } from '../../../../services/auth';
import { checkQuota, getAvailableCountOfSuburbSearch } from '../../../../services/userCenter';
import { ChartTypes } from '../../Charts/ChartTypes';
import { SuburbHalfYearAuction } from '../../Charts/SuburbHalfYearAuction';
import { SuburbTenYearTrend } from '../../Charts/SuburbTenYearsTrend';
import { MapButton } from '../../components/MapButton';
import {
    getIncomeDesc,
    getPrimarySchoolDesc,
    getSecondarySchoolDesc,
    getShoppingCenterDesc,
    getTranDesc,
    triggrMemberWindowForVisitor,
} from '../../support/common';
import { AreaBusiness, MapGlobalVars, PublicSecurity, SuburbPriceColors, Trees } from '../../support/PriceMapConsts';
import { getThemeValue } from '../../support/PriceMapTheme';

const useStyles = makeStyles((theme) => {
    return {
        suburbTitle: {
            display: 'flex',
            justifyContent: !isMobileDevice() ? 'flex-start' : 'center',
            alignItems: 'center',
            width: '100%',
        },
        pop_suburbName: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: !isMobileDevice() ? 'flex-start' : 'center',
            fontSize: isMobileDevice() ? 12 : 20,
            fontWeight: isMobileDevice() ? 500 : 700,
            alignItems: 'center',
        },
        pc_transCount: {
            fontSize: 16,
            fontWeight: 400,
        },
        infoPanel: {
            paddingTop: 9,

            display: 'flex',
            flex: '1 1',
            width: '100%',
            justifyContent: 'space-between',
        },
        englishInfoItem: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            minWidth: '120px',
        },
        infoItem: {
            '&>:first-child': {
                marginTop: 0,
            },
            marginTop: 6,
            display: 'flex',
        },
        itemLabel: {
            fontSize: isMobileDevice() ? 13 : 14,
            color: '#666666',
            fontWeight: 500,
        },
        itemValue: {
            marginLeft: 12,
            fontSize: 13,
            color: '#333333',
            fontWeight: 500,
        },
        englishInfoRow: {
            display: 'flex',

            width: '100%',
        },
        buttons: {
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            marginTop: isMobileDevice() ? 7 : 13,
        },
        panelColumn: { fontSize: isMobileDevice() ? '10px' : '12px' },
        panelColumnLabel: {
            fontWeight: 400,
            color: '#999999',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        },
        panelColumnValue: {
            fontWeight: 400,
            color: '#666666',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        },
        noticeablePanelColumnValue: {
            fontWeight: 400,
            color: '#ff0000',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        },
        infoValue: {
            fontSize: '16px',
            color: getThemeValue('SPECIAL_COLOR'),
            fontWeight: 500,
        },
        chartChooseButton: {
            width: 28,
            height: 28,
            borderRadius: '50%',
            background: '#999999',
            fontSize: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#666666',
            cursor: 'pointer',
        },
        selectedChartButton: {
            background: '#ff5540',
            color: '#ffffff',
        },
        mobileNavDiv: {
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
        },
        roundNav: {
            width: 8,
            height: 8,
            background: '#DEDADA',
            borderRadius: '50%',
            cursor: 'pointer',
        },
        selectedRoundNav: {
            background: '#999999',
        },
    };
});
/*
showFor : map or infoPanel
*/

export const SuburbSummaryPanel = rst.create<{
    suburbData: any;
    medianData: any;
    showFor?: string;
    changeSuburbChartTypeEvt?: any;
    chartType?: any;
    sideBar?: any;
    userRegister?: boolean;
}>((ctx) => {
    const chartShowing = rst.stateS(1);

    function changeSuburbTrend(choosedChart) {
        chartShowing.value = choosedChart;
        chartShowing.forceUpdate();
    }
    function switchToSuburbTrans() {
        EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, { suburbId: ctx.props.suburbData.id });
    }
    return (props) => {
        const { suburbData, showFor, medianData } = props;
        console.log(suburbData);
        const classes = useStyles();
        const getContent = () => {
            const content: any = [];
            if (showFor == 'map') {
                content.push(
                    <SummaryTitle suburbData={suburbData} suburbName={suburbData.locality_name}></SummaryTitle>,
                );
            }
            if (!isMobileDevice() || showFor == 'infoPanel' || props.sideBar) {
                if (!isMobileDevice() && !props.sideBar) {
                    content.push(
                        <PCSuburbInfoPanel chartType={props.chartType} suburbData={suburbData}></PCSuburbInfoPanel>,
                    );
                } else {
                    content.push(<MobileInfoPanel suburbData={suburbData}></MobileInfoPanel>);
                }
            } else {
                content.push(
                    <MobileSuburbInfoPanel suburbData={suburbData} medianData={medianData}></MobileSuburbInfoPanel>,
                );
            }
            if (showFor == 'map') {
                content.push(
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                        {isMobileDevice() ? null : (
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <ChartRoundButton
                                    showingChartID="tenYearsTrendChart"
                                    changeSuburbChartTypeEvt={() =>
                                        props.changeSuburbChartTypeEvt(
                                            props.changeSuburbChartTypeEvt,
                                            ChartTypes.SUBURB_HALF_YEAR_AUCTION_TREND,
                                        )
                                    }
                                    id="chart1"
                                    selected={props.chartType == ChartTypes.SUBURB_HALF_YEAR_AUCTION_TREND}>
                                    1
                                </ChartRoundButton>
                                <div style={{ marginLeft: 16 }}></div>
                                <ChartRoundButton
                                    showingChartID="halfYearAuction"
                                    changeSuburbChartTypeEvt={() =>
                                        props.changeSuburbChartTypeEvt(
                                            props.changeSuburbChartTypeEvt,
                                            ChartTypes.SUBURB_TEN_YEARS_TREND,
                                        )
                                    }
                                    id="chart2"
                                    selected={props.chartType == ChartTypes.SUBURB_TEN_YEARS_TREND}>
                                    2
                                </ChartRoundButton>
                            </div>
                        )}

                        <SuburbInfoButton
                            disable={!props.userRegister}
                            clickEvent={switchToSuburbTrans}></SuburbInfoButton>
                    </div>,
                );
            }

            return content;
        };
        return getContent();
    };
});

const PCSuburbInfoPanel = rst.create<{ suburbData: any; whichChartShowing?: string; chartType: any }>((ctx) => {
    return (props) => {
        const medianData =
            ctx.props.suburbData.median_data[MapGlobalVars.priceMapFilter.propertyType][
                MapGlobalVars.priceMapFilter.bedOptions[0]
            ];
        console.log(medianData);
        const classes = useStyles();
        const { suburbData } = props;

        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 4, alignItems: 'flex-end' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            {MapGlobalVars.priceMapFilter.propertyType == 'House' ? (
                                <TR name="ALL_HOUSE"></TR>
                            ) : (
                                <TR name="ALL_APT"></TR>
                            )}
                            <TR name="SIX_MONTHS"></TR>
                            <TR name="MedianPrice1"></TR>
                        </div>
                        <div style={{ marginLeft: 5 }}></div>
                        <div className={classes.infoValue}>{numToMillion(medianData.median)}</div>
                    </div>
                    <div style={{ marginLeft: 5 }}></div>
                    {MapGlobalVars.priceMapFilter.propertyType == 'House' ? (
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <div>
                                <TR name="MEDIAN_LAND_SIZE"></TR>
                            </div>
                            <div style={{ marginLeft: 5 }}></div>
                            <div className={classes.infoValue}>
                                {suburbData.median_lz}
                                <span style={{ fontSize: 12 }}>
                                    m<sup>2</sup>
                                </span>
                            </div>
                        </div>
                    ) : null}
                    <div style={{ marginLeft: 5 }}></div>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <div>
                            <TR name="TOTAL_SOLD"></TR>
                        </div>
                        <div style={{ marginLeft: 5 }}></div>
                        <div className={classes.infoValue}>{medianData.numberOfSold}</div>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 13 }}>
                    <div className={classes.panelColumn}>
                        <div className={classes.panelColumnLabel}>
                            <TR name={'TRAIN_STATION'}></TR>
                        </div>
                        <div className={classes.panelColumnValue}>
                            <TR name={getTranDesc(suburbData.train_station)}></TR>
                        </div>
                    </div>

                    <div style={{ marginLeft: 7 }}></div>

                    <div className={classes.panelColumn}>
                        <div className={classes.panelColumnLabel}>
                            <TR name={'BUSINESS'}></TR>
                        </div>
                        <div
                            className={
                                suburbData.rate_shopping >= 4
                                    ? classes.noticeablePanelColumnValue
                                    : classes.panelColumnValue
                            }>
                            <TR name={getShoppingCenterDesc(suburbData.rate_shopping)}></TR>
                        </div>
                    </div>

                    <div style={{ marginLeft: 7 }}></div>

                    <div className={classes.panelColumn}>
                        <div className={classes.panelColumnLabel}>
                            <TR name={'PRIMARY_CATCH'}></TR>
                        </div>
                        <div
                            className={
                                suburbData.rate_primary == 5
                                    ? classes.noticeablePanelColumnValue
                                    : classes.panelColumnValue
                            }>
                            <TR name={getPrimarySchoolDesc(suburbData.rate_primary)}></TR>
                        </div>
                    </div>

                    <div style={{ marginLeft: 7 }}></div>

                    <div className={classes.panelColumn}>
                        <div className={classes.panelColumnLabel}>
                            <TR name={'SECONDARY_CATCH'}></TR>
                        </div>
                        <div
                            className={
                                suburbData.score_secondary >= 96
                                    ? classes.noticeablePanelColumnValue
                                    : classes.panelColumnValue
                            }>
                            <TR name={getSecondarySchoolDesc(suburbData.score_secondary)}></TR>
                        </div>
                    </div>

                    <div style={{ marginLeft: 7 }}></div>
                    <div style={{ borderLeft: '1px solid #F1F1F1', height: '31px', width: 1 }}></div>
                    <div style={{ marginLeft: 7 }}></div>
                    <div className={classes.panelColumn}>
                        <div className={classes.panelColumnLabel}>
                            <TR name={'FAMILY_INCOME'}></TR>
                        </div>
                        <div
                            className={
                                suburbData.family_income > 3000
                                    ? classes.noticeablePanelColumnValue
                                    : classes.panelColumnValue
                            }>
                            <TR name={getIncomeDesc(suburbData.family_income)}></TR>
                        </div>
                    </div>

                    <div style={{ marginLeft: 7 }}></div>

                    <div className={classes.panelColumn}>
                        <div className={classes.panelColumnLabel}>
                            <TR name={'CHINESE_MIGRATION'}></TR>
                        </div>
                        <div className={classes.panelColumnValue}>
                            <TR name={toPct(suburbData.chinese)}></TR>
                        </div>
                    </div>

                    <div style={{ marginLeft: 7 }}></div>

                    <div className={classes.panelColumn}>
                        <div className={classes.panelColumnLabel}>
                            <TR name={'INDIAN_MIGRATION'}></TR>
                        </div>
                        <div className={classes.panelColumnValue}>
                            <TR name={toPct(suburbData.hinduism_p_pct)}></TR>
                        </div>
                    </div>

                    <div style={{ marginLeft: 7 }}></div>

                    <div className={classes.panelColumn}>
                        <div className={classes.panelColumnLabel}>
                            <TR name={'ISLAM_MIGRATION'}></TR>
                        </div>
                        <div className={classes.panelColumnValue}>
                            <TR name={toPct(suburbData.islam_p_pct)}></TR>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: 11 }}></div>
                <div style={{ height: 1, width: '100%', background: '#D8D8D8' }}></div>

                <div style={{ marginTop: 22 }}></div>
                {props.chartType == ChartTypes.SUBURB_TEN_YEARS_TREND ? (
                    <SuburbTenYearTrend
                        suburbName={props.suburbData.locality_name}
                        sydneyData={
                            MapGlobalVars.priceMapFilter.propertyType == 'House'
                                ? suburbData.syd_price_trend_house
                                : suburbData.syd_price_trend_apt
                        }
                        localData={
                            MapGlobalVars.priceMapFilter.propertyType == 'House'
                                ? suburbData.price_trend_house
                                : suburbData.price_trend_apt
                        }></SuburbTenYearTrend>
                ) : (
                    <SuburbHalfYearAuction
                        suburbName={props.suburbData.locality_name}
                        sydneyData={
                            MapGlobalVars.priceMapFilter.propertyType == 'House'
                                ? suburbData.syd_price_trend_house
                                : suburbData.syd_price_trend_apt
                        }
                        localData={
                            MapGlobalVars.priceMapFilter.propertyType == 'House'
                                ? suburbData.price_trend_house
                                : suburbData.price_trend_apt
                        }></SuburbHalfYearAuction>
                )}
            </div>
        );
    };
});

export const MobileSuburbInfoPanel = rst.create<{ medianData: any; suburbData }>((ctx) => {
    return (props) => {
        const classes = useStyles();

        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 4 }}>
                <div className={classes.panelColumn}>
                    <div className={classes.panelColumnLabel}>
                        {MapGlobalVars.priceMapFilter.propertyType == 'House' ? (
                            <TR name={'ALL_HOUSE'}></TR>
                        ) : (
                            <TR name={'ALL_APT'}></TR>
                        )}
                        <TR name={'SIX_MONTHS'}></TR>
                    </div>
                </div>

                <div
                    className={classes.panelColumnValue}
                    style={{ color: getThemeValue('SPECIAL_COLOR'), alignItems: 'center' }}>
                    <span style={{ fontSize: 18, fontWeight: 700 }}>{numToMillion(props.medianData.median)}</span>
                    <span style={{ fontSize: 10, color: '#6dd400', fontWeight: 400, marginLeft: 5 }}>
                        <TR name={'TOTAL_SOLD'}></TR> {props.medianData.numberOfSold}
                    </span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <SuburbCatagoryValueInfo suburbData={props.suburbData}></SuburbCatagoryValueInfo>
                </div>
            </div>
        );
    };
});

export const SuburbInfoButton = rst.create<{ clickEvent: any; disable?: boolean }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.buttons}>
                <MapButton
                    bg="#34A4FF"
                    id={'showSuburbAllTrans'}
                    w={isMobileDevice() ? 140 : 281}
                    clickEvent={props.clickEvent}
                    label={'THIS_SUBURB_ALL_TRANS'}></MapButton>
            </div>
        );
    };
});

const MobileInfoPanel = rst.create<{ suburbData: any }>((ctx) => {
    const securityProvider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const whichButton = rst.stateS(1);

    const checkSuburbQueryPermission = (whichButton) => {
        if (
            securityProvider.hasPermission(Permission.PROPERTY_BUYER) ||
            securityProvider.hasPermission(Permission.TRIAL)
        ) {
            return new Promise((resolve, reject) => {
                getUserProfile().then((userProfile) => {
                    // if this user is trial user
                    if (userProfile.roles['2']) {
                        getAvailableCountOfSuburbSearch().then((howManyLeft) => {
                            if (howManyLeft == 0) {
                                EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
                                    open: true,
                                    title: 'Tips',
                                    content: 'RUN_OUT_SUBURB_QUERY',
                                });
                                resolve('-1');
                            }
                            if (howManyLeft > 0) {
                                EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
                                    open: true,
                                    title: 'Tips',
                                    content: 'TIMES_QUERY_SUBURB_QUERY',
                                    contentParams: { haveChecked: 10 - howManyLeft, tryTimes: howManyLeft },
                                    confirmButtonText: 'YES',
                                    confirmEvt: () => {
                                        if (whichButton == 1) {
                                            EventBus.dispatch(EVENT_NAMES.EVENT_OPEN_STREET_DIALOG, {});
                                        }
                                        if (whichButton == 2) {
                                            EventBus.dispatch(EVENT_NAMES.EVENT_OPEN_SOLD_BY_BED_DIALOG, {});
                                        }
                                        checkQuota(ctx.props.suburbData.id);
                                    },
                                });
                                resolve('-1');
                            }
                        });
                    } else {
                        if (whichButton == 1) {
                            EventBus.dispatch(EVENT_NAMES.EVENT_OPEN_STREET_DIALOG, {});
                        }
                        if (whichButton == 2) {
                            EventBus.dispatch(EVENT_NAMES.EVENT_OPEN_SOLD_BY_BED_DIALOG, {});
                        }
                        checkQuota(ctx.props.suburbData.id);
                    }
                });
            });
        } else {
            triggrMemberWindowForVisitor(appProvider);
        }
    };

    return (props) => {
        const handlers = useSwipeable({
            onSwipedLeft: (eventData) => {
                if (whichButton.value < 3) {
                    whichButton.value++;
                }
            },
            onSwipedRight: (eventData) => {
                if (whichButton.value > 1) {
                    whichButton.value--;
                }
            },
        });
        const changeSuburbInfoContent = (clickedButton) => {
            whichButton.value = clickedButton;
        };

        const classes = useStyles();
        const { suburbData } = props;
        const getContent = () => {
            console.log(suburbData);
            if (whichButton.value == 1) {
                if (MapGlobalVars.currentUserLan == 'zh') {
                    return (
                        <div className={classes.infoPanel}>
                            <div style={{ flexGrow: 1 }}>
                                <InfoItem
                                    item={{
                                        label: 'TRAIN_STATION',
                                        value: getTranDesc(suburbData?.train_station),
                                    }}></InfoItem>
                                <InfoItem
                                    item={{ label: 'CHINESE_MIGRATION', value: toPct(suburbData?.chinese) }}></InfoItem>
                                <InfoItem
                                    item={{
                                        label: 'INDIAN_MIGRATION',
                                        value: toPct(suburbData?.hinduism_p_pct),
                                    }}></InfoItem>
                                <InfoItem
                                    item={{
                                        label: 'ISLAM_MIGRATION',
                                        value: toPct(suburbData?.islam_p_pct),
                                    }}></InfoItem>
                            </div>

                            <div style={{ flexGrow: 1 }}>
                                <InfoItem
                                    item={{
                                        label: 'PRIMARY_CATCH',
                                        value: getPrimarySchoolDesc(suburbData?.rate_primary),
                                    }}></InfoItem>
                                <InfoItem
                                    item={{
                                        label: 'SECONDARY_CATCH',
                                        value: getSecondarySchoolDesc(suburbData?.score_secondary),
                                    }}></InfoItem>
                                <InfoItem
                                    item={{
                                        label: 'BUSINESS',
                                        value: getShoppingCenterDesc(suburbData?.rate_shopping),
                                    }}></InfoItem>
                                <InfoItem
                                    item={{
                                        label: 'FAMILY_INCOME',
                                        value: getIncomeDesc(suburbData?.family_income),
                                    }}></InfoItem>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <>
                            {' '}
                            <div className={classes.englishInfoRow}>
                                <EnglishInfoItem
                                    item={{
                                        label: 'TRAIN_STATION',
                                        value: getTranDesc(suburbData?.train_station),
                                    }}></EnglishInfoItem>

                                <EnglishInfoItem
                                    item={{
                                        label: 'BUSINESS',
                                        value: getShoppingCenterDesc(suburbData?.rate_shopping),
                                    }}></EnglishInfoItem>

                                <EnglishInfoItem
                                    item={{
                                        label: 'FAMILY_INCOME',
                                        value: getIncomeDesc(suburbData?.family_income),
                                    }}></EnglishInfoItem>
                            </div>
                            <div className={classes.englishInfoRow}>
                                <EnglishInfoItem
                                    item={{
                                        label: 'CHINESE_MIGRATION',
                                        value: toPct(suburbData?.chinese),
                                    }}></EnglishInfoItem>
                                <EnglishInfoItem
                                    item={{
                                        label: 'INDIAN_MIGRATION',
                                        value: toPct(suburbData?.hinduism_p_pct),
                                    }}></EnglishInfoItem>
                                <EnglishInfoItem
                                    item={{
                                        label: 'ISLAM_MIGRATION',
                                        value: toPct(suburbData?.islam_p_pct),
                                    }}></EnglishInfoItem>
                            </div>
                            <div className={classes.englishInfoRow}>
                                <EnglishInfoItem
                                    item={{
                                        label: 'PRIMARY_CATCH',
                                        value: getPrimarySchoolDesc(suburbData?.rate_primary),
                                    }}></EnglishInfoItem>
                                <EnglishInfoItem
                                    item={{
                                        label: 'SECONDARY_CATCH',
                                        value: getSecondarySchoolDesc(suburbData?.score_secondary),
                                    }}></EnglishInfoItem>
                            </div>
                        </>
                    );
                }
            } else if (whichButton.value == 2) {
                return (
                    <SuburbHalfYearAuction
                        sydneyData={
                            MapGlobalVars.priceMapFilter.propertyType == 'House'
                                ? suburbData.syd_price_trend_house
                                : suburbData.syd_price_trend_apt
                        }
                        localData={
                            MapGlobalVars.priceMapFilter.propertyType == 'House'
                                ? suburbData.price_trend_house
                                : suburbData.price_trend_apt
                        }></SuburbHalfYearAuction>
                );
            } else {
                return (
                    <SuburbTenYearTrend
                        sydneyData={
                            MapGlobalVars.priceMapFilter.propertyType == 'House'
                                ? suburbData.syd_price_trend_house
                                : suburbData.syd_price_trend_apt
                        }
                        localData={
                            MapGlobalVars.priceMapFilter.propertyType == 'House'
                                ? suburbData.price_trend_house
                                : suburbData.price_trend_apt
                        }></SuburbTenYearTrend>
                );
            }
        };
        return (
            <div {...handlers}>
                {getContent()}
                <div style={{ marginTop: 10 }}></div>
                <div className={classes.mobileNavDiv}>
                    {isMobileDevice() ? (
                        <div
                            style={{ position: 'absolute', left: 10 }}
                            onClick={() => {
                                // checkSuburbQueryPermission(1);
                                EventBus.dispatch(EVENT_NAMES.EVENT_OPEN_STREET_DIALOG, {});
                                checkQuota(ctx.props.suburbData.id);
                            }}>
                            {' '}
                            <Button variant="outlined">
                                <TR name={'STREET_RANK_BUTTON'}></TR>
                            </Button>
                        </div>
                    ) : null}

                    <div
                        onClick={() => {
                            changeSuburbInfoContent(1);
                        }}
                        className={clsx(
                            classes.roundNav,
                            whichButton.value == 1 ? classes.selectedRoundNav : '',
                        )}></div>
                    <div style={{ marginLeft: 5 }}></div>
                    <div
                        onClick={() => {
                            changeSuburbInfoContent(2);
                        }}
                        className={clsx(
                            classes.roundNav,
                            whichButton.value == 2 ? classes.selectedRoundNav : '',
                        )}></div>
                    <div style={{ marginLeft: 5 }}></div>
                    <div
                        onClick={() => {
                            changeSuburbInfoContent(3);
                        }}
                        className={clsx(
                            classes.roundNav,
                            whichButton.value == 3 ? classes.selectedRoundNav : '',
                        )}></div>
                    {isMobileDevice() ? (
                        <div
                            style={{ position: 'absolute', right: 10 }}
                            onClick={() => {
                                // checkSuburbQueryPermission(2);
                                EventBus.dispatch(EVENT_NAMES.EVENT_OPEN_SOLD_BY_BED_DIALOG, {});
                                checkQuota(ctx.props.suburbData.id);
                            }}>
                            <Button variant="outlined">
                                <TR name={'PRICE_BY_BED_BUTTON'}></TR>
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };
});
const SummaryTitle = rst.create<{ suburbName: any; transCount?: any; suburbData }>((ctx) => {
    return (props) => {
        const { suburbName, transCount } = props;
        const classes = useStyles();
        return (
            <div className={classes.suburbTitle}>
                <div className={classes.pop_suburbName}>
                    {' '}
                    {suburbName}
                    {!isMobileDevice() ? (
                        <div style={{ marginLeft: 10 }}>
                            {' '}
                            <SuburbCatagoryValueInfo suburbData={props.suburbData}></SuburbCatagoryValueInfo>
                        </div>
                    ) : null}
                </div>

                {!transCount ? null : (
                    <div className={classes.pc_transCount}>
                        <TR name="TOTAL_TRANS"></TR>:{transCount}
                    </div>
                )}
            </div>
        );
    };
});
const InfoItem = rst.create<{ item: { label: string; value: string } }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const { item } = props;
        return (
            <div className={classes.infoItem}>
                <div className={classes.itemLabel}>
                    <TR name={item.label}></TR>:
                </div>
                <div className={classes.itemValue}>
                    <TR name={item.value}></TR>
                </div>
            </div>
        );
    };
});

const EnglishInfoItem = rst.create<{ item: { label: string; value: string } }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const { item } = props;
        return (
            <div className={classes.englishInfoItem}>
                <div className={classes.itemLabel}>
                    <TR name={item.label}></TR>
                </div>
                <div style={{ marginTop: 5 }}></div>
                <Chip label={item.value}></Chip>
            </div>
        );
    };
});

const ChartRoundButton = rst.create<{
    selected: boolean;
    id: string;
    showingChartID: string;
    changeSuburbChartTypeEvt: any;
}>((ctx) => {
    return (props) => {
        const classes = useStyles();
        setTimeout(() => {
            document.getElementById(props.id).addEventListener('click', () => {
                props.changeSuburbChartTypeEvt();
            });
        }, 500);

        return (
            <div
                id={props.id}
                className={clsx(
                    'HOMEON_ChartChooseButton',
                    classes.chartChooseButton,
                    props.selected ? classes.selectedChartButton : '',
                )}>
                {props.children}
            </div>
        );
    };
});

const SuburbCatagoryValueInfo = rst.create<{ suburbData }>((ctx) => {
    const { suburbData } = ctx.props;
    return (props) => {
        console.log(props.suburbData);
        const catagoryInfo = () => {
            switch (MapGlobalVars.currentMapCatagory) {
                case 'PriceChange3M':
                    return (
                        <>
                            <TR name="House price changes (last 3 months)"></TR> :
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {getPct(suburbData['median_house_3m_change'], 1)}
                            </span>
                        </>
                    );
                case 'PriceChange6M':
                    return (
                        <>
                            <TR name="House price changes (last 6 months)"></TR> :
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {getPct(suburbData['median_house_6m_change'], 1)}
                            </span>
                        </>
                    );
                case 'rate_primary':
                    return (
                        <>
                            <TR name="BestPrimarySchoolScore"></TR> :
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {SuburbPriceColors.rate_primary[suburbData[MapGlobalVars.currentMapCatagory]].max}
                            </span>
                        </>
                    );
                case 'score_secondary':
                    return (
                        <>
                            <TR name="BestSecondarySchoolScore"></TR>:{' '}
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {suburbData[MapGlobalVars.currentMapCatagory]}
                            </span>
                        </>
                    );
                case 'rate_private_boys':
                    return (
                        <>
                            <TR name="HowManyPrivateBoySchool"></TR>:{' '}
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {suburbData[MapGlobalVars.currentMapCatagory]}
                            </span>
                        </>
                    );

                case 'rate_private_girls':
                    return (
                        <>
                            <TR name="HowManyPrivateGirlSchool"></TR>:{' '}
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {suburbData[MapGlobalVars.currentMapCatagory]}
                            </span>
                        </>
                    );

                case 'rental_pct':
                    return (
                        <>
                            <TR name="Tenant Proportion"></TR>:{' '}
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {getPct(suburbData[MapGlobalVars.currentMapCatagory])}
                            </span>
                        </>
                    );

                case 'chinese':
                    return (
                        <>
                            <TR name="Chinese Immi Pct Pop"></TR>:{' '}
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {getPct(suburbData[MapGlobalVars.currentMapCatagory])}
                            </span>
                        </>
                    );

                case 'hinduism_p_pct':
                    return (
                        <>
                            <TR name="Indian Immi Pct Pop"></TR>:{' '}
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {getPct(suburbData[MapGlobalVars.currentMapCatagory])}
                            </span>
                        </>
                    );

                case 'islam_p_pct':
                    return (
                        <>
                            <TR name="Islam Immi Pct Pop"></TR>:{' '}
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {getPct(suburbData[MapGlobalVars.currentMapCatagory])}
                            </span>
                        </>
                    );

                case 'family_income':
                    return (
                        <>
                            <TR name="Family Income"></TR>:{' '}
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {suburbData[MapGlobalVars.currentMapCatagory]}
                            </span>
                        </>
                    );

                case 'house_pct':
                    return (
                        <>
                            <TR name="House/Apartment"></TR>:{' '}
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                {getPct(suburbData[MapGlobalVars.currentMapCatagory])}
                            </span>
                        </>
                    );

                case 'rate_public_security':
                    return (
                        <>
                            <TR name="Public Security"></TR>:
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                <TR name={PublicSecurity[5 - suburbData[MapGlobalVars.currentMapCatagory]].label}></TR>
                            </span>
                        </>
                    );

                case 'rate_tree_cover':
                    return (
                        <>
                            <TR name="Trees"></TR>:
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                <TR name={Trees[5 - suburbData[MapGlobalVars.currentMapCatagory]].label}></TR>
                            </span>
                        </>
                    );

                case 'rate_shopping':
                    return (
                        <>
                            <TR name="BUSINESS"></TR>:
                            <span style={{ color: 'red', fontWeight: 500, fontSize: '16px' }}>
                                <TR name={AreaBusiness[5 - suburbData[MapGlobalVars.currentMapCatagory]].label}></TR>
                            </span>
                        </>
                    );
                default:
                    return '';
            }
        };
        return (
            <span
                style={{
                    fontFamily: 'Noto Sans SC !important',
                    fontWeight: 400,
                    fontSize: isMobileDevice() ? 10 : 12,
                }}>
                {catagoryInfo()}
            </span>
        );
    };
});

import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { useSidebarStyles } from './SiderBar.styles';
import { SidebarItem } from './SideBarItem';
import { rst } from 'rt-state';

const iOS = (process as any).browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

export const Sidebar = rst.create<{
    opened: any;
    toggleDrawer: any;
    closeDrawer: any;
    openDrawer: any;
    routes: any;
}>((ctx) => {
    const activeRouteV = rst.stateS(undefined);
    const toggleMenu = (index) => {
        activeRouteV.value = activeRouteV.value === index ? undefined : index;
    };

    return ({ routes, opened, toggleDrawer, closeDrawer }) => {
        const classes = useSidebarStyles();

        const menu = (
            <List component="div">
                {routes.map((route, index) => {
                    const isCurrentPath = window.location.pathname.indexOf(route.path) > -1;
                    return (
                        <SidebarItem
                            key={index}
                            index={index}
                            route={route}
                            activeRoute={activeRouteV.value}
                            toggleMenu={toggleMenu}
                            isCurrentPath={isCurrentPath}
                        />
                    );
                })}
            </List>
        );

        return (
            <>
                <Hidden smDown>
                    <Drawer
                        variant="persistent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        open={opened}
                        ModalProps={{
                            keepMounted: false,
                            className: classes.modal,
                            BackdropProps: {
                                className: classes.backdrop,
                            },
                            onBackdropClick: toggleDrawer,
                        }}>
                        {menu}
                    </Drawer>
                </Hidden>
                <Hidden mdUp>
                    <SwipeableDrawer
                        variant="temporary"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        open={opened}
                        onClose={toggleDrawer}
                        onOpen={toggleDrawer}
                        disableBackdropTransition={!iOS}
                        ModalProps={{
                            keepMounted: false,
                            className: classes.modal,
                            BackdropProps: {
                                className: classes.backdrop,
                            },
                            onBackdropClick: toggleDrawer,
                        }}>
                        {menu}
                    </SwipeableDrawer>
                </Hidden>
            </>
        );
    };
});

import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { rst } from 'rt-state';
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { TR } from '../../commons';
import { withStyles } from '@material-ui/styles';

export interface FilterButtonProps {
    label: React.ReactNode;
    tooltip?: ReactNode;
    selected: boolean;
    onClick: () => void;
}

const useStyles = makeStyles((theme) => {
    return {
        root: (props: FilterButtonProps) => {
            const backgroundColor = props.selected ? theme.palette.primary.main : 'white';
            return {
                backgroundColor,
                width: '90px',
                height: '36px',
                fontSize: '14px',
                whiteSpace: 'nowrap',
                color: props.selected ? 'white' : 'black',
                '&:hover': {
                    backgroundColor,
                },
            };
        },
        dummy: {},
    };
});

const FilterButtonTooltip = withStyles({
    tooltip: {
        color: '#495b6b',
        backgroundColor: 'white',
        fontSize: '10px',
    },
})(Tooltip);

export const FilterButton = rst.createS<FilterButtonProps>((props) => {
    const { label, selected, onClick } = props;
    const classes = useStyles(props);

    const btn = (
        <Button variant={'contained'} color={'primary'} disableRipple onClick={onClick} className={classes.root}>
            <TR name={label as string} />
        </Button>
    );
    if (props.tooltip) {
        return <FilterButtonTooltip title={<TR name={props.tooltip as string} />}>{btn}</FilterButtonTooltip>;
    }
    return btn;
});

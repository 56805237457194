import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: 'white',
    },
    menuIcon: {
        color: theme.palette.primary.main,
        fontSize: 30,
        fontWeight: 'bold',
    },
    logo: {
        [theme.breakpoints.down('sm')]: {
            width: 160,
        },
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
}));

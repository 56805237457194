import React from 'react';
import { useStyles } from './TermOfUse.styles';
import { rst } from 'rt-state';
import { FirstPageHead } from '../Home/components/FirstPageHead';
import { HomeFooter } from '../Home/components/HomeFooter';
import { NewHomeFooter } from '../NewHome/components/11NewHomeFooter';
import { NewHomePageHead } from '../NewHome/components/01NewHomePageHead';
import { Hidden } from '@material-ui/core';
import { NewHomeFooterForMobile } from '../NewHome/components/11NewHomeFooterForMobile';

export const TermOfUsePage = rst.createS(() => {
    const classes = useStyles();
    return (
        <>
            <NewHomePageHead />
            <div className={classes.root}>
                <div className={classes.content}>
                    <h1>Terms and Conditions</h1>
                    <p>
                        Thank you for using our website. By accessing and/or using this website and related services,
                        you agree to these Terms and Conditions, which includes our{' '}
                        <u>
                            <span>Privacy Policy</span>
                        </u>
                        . You should review our Privacy Policy and these Terms carefully and immediately cease using our
                        website if you do not agree to these Terms.{' '}
                    </p>
                    <p>
                        We respect and protect the privacy of our users.We are committed to complying with the Privacy
                        Act 1988 (Cth) and other applicable data protection laws.{' '}
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ABOUT THESE TERMS AND CONDITIONS &nbsp;</strong>
                    </p>
                    <p>
                        The term "Website" means web applications and web pages owned and operated by AIHOUSE PTY LTD
                        (ACN: 627 431 805) ("The company"). For the avoidance of doubt, Website includes any web
                        applications and web pages under domain name "homeon.com.au".
                    </p>
                    <p>
                        The terms "you" and "your" refer to the person that accesses or uses the Website and/or any
                        Content. The "we" and "our" means The company.&nbsp;
                    </p>
                    <p>
                        These Terms and Conditions govern your use of the Website and any material, communications,
                        calculators, links or information contained in the Website ("Content"), and comprise these terms
                        and conditions and service specific terms and conditions, notices and disclaimers available in
                        the Website or otherwise provided through the Website.&nbsp;
                    </p>
                    <p>
                        By accessing and/or using the Website and/or Content, you acknowledge that you have read and
                        agree to be bound by these Terms and Conditions (including any variations The company makes from
                        time to time). If do not agree to these Terms and Conditions, you must immediately cease
                        accessing and using the Website and the Content.&nbsp;
                    </p>
                    <p>
                        You warrant that you are at least 18 years old or are accessing or using the Website and Content
                        under the supervision of a parent or guardian who agrees to these Terms and Conditions.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; MEMBERSHIP &nbsp;</strong>
                    </p>
                    <p>
                        To access or use the Website, you shall register as a member of the Website. You may register as
                        a member of the Website by using your existing email account to log-in to the Website.
                    </p>
                    <p>
                        When registering as a member, you must provide us with accurate, complete and up-to-date
                        registration information, as requested. It is your responsibility to inform us of any changes to
                        your registration information. We will treat your personal information strictly in accordance
                        with our Privacy Policy.
                    </p>
                    <p>You must not register as a member multiple times.</p>
                    <p>You must not impersonate or create a membership for any person other than yourself.</p>
                    <p>We may at any time request a form of identification to verify your identity.</p>
                    <p>
                        You must ensure the security and confidentiality of your membership details, including any
                        username and/or password assigned to you. You are wholly responsible for all activities which
                        occur under your details (including unauthorized use of your credit card). You must notify us
                        immediately if you become aware of any unauthorized use of your membership details. You must not
                        permit your membership details to be used by or transferred to any other person.
                    </p>
                    <p>
                        We reserve the right to, in our sole discretion, suspend or terminate your membership or access
                        to the Website, including if we believe you are abusing the services in any way, have breached
                        the Conditions no longer an active member.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PAID MEMBERSHIP &nbsp;</strong>
                    </p>
                    <p>
                        In consideration for enjoying certain benefits notified on the Website, you agree to pay the
                        fees notified on the Website
                    </p>
                    <p>
                        Under this clause, you enter into a fixed term agreement with us to enjoy the benefits over a
                        specified period notified on the Website when you pay the membership fees in full.
                    </p>
                    <p>
                        Any membership fees, once paid, are non-refundable if you wish to cancel your paid membership
                        for your convenience during the fixed term.
                    </p>
                    <p>
                        You may cancel your paid membership during the fixed term after paying the fees for any services
                        or products already supplied by us in the following ways:
                    </p>
                    <p>
                        (a) we break an essential term of the agreement incorporating these Terms and Conditions and
                        have not fixed this in a reasonable time of you asking us in writing. If there is a disagreement
                        as to whether a term is an essential term, the matter will be reasonably determined by us;
                    </p>
                    <p>(b) you become entitled to cancel under consumer laws.</p>
                    <p>
                        We may cancel your paid membership if you breach any obligation under the agreement
                        incorporating these Terms and Conditions that cannot be fixed or if you breach an obligation
                        that can be fixed but you do not fix it in a reasonable time.
                    </p>
                    <p>We may at our discretion adjust the membership fees from time to time.</p>
                    <p>Unless otherwise stated, all fees are GST inclusive.</p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; FREE TRIAL&nbsp;</strong>
                    </p>
                    <p>
                        After you register as a member of the Website, we may make certain benefits of paid membership
                        available to you on a trial basis free of charge until the end of the free trial period notified
                        on the Website. Additional trial terms and conditions may appear on the Website and are
                        incorporated into these Terms and Conditions and are legally binding.
                    </p>
                    <p>
                        At the end of the free trial period notified on the Website, you may choose to join our paid
                        membership to continue to enjoy the benefits. Otherwise, the free trial will end.
                    </p>
                    <p>
                        We may at our discretion adjust the free trial period and benefits and terminate any free trial
                        from time to time.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PROHIBITED ACTIVITIES&nbsp;</strong>
                    </p>
                    <p>You must not:&nbsp;</p>
                    <p>(a) use the Website in breach of any applicable laws or regulations;</p>
                    <p>(b) use the Website (or Content from the Website):</p>
                    <ul>
                        <li>for any purpose other than as expressly permitted in these Terms and Conditions;</li>
                        <li>
                            to transmit (or authorise the transmission of) &ldquo;junk mail,&rdquo; &ldquo;chain
                            letters,&rdquo; unsolicited emails, instant messaging, &ldquo;spimming,&rdquo; or
                            &ldquo;spamming&rdquo;;
                        </li>
                        <li>to impersonate any person or entity;</li>
                        <li>to solicit money, passwords or personal information from any person;</li>
                        <li>to harm, abuse, harass, stalk, threaten or otherwise offend others; or</li>
                        <li>for any unlawful purpose;</li>
                    </ul>
                    <p>
                        (c) use the Website to upload, post, transmit or otherwise make available (or attempt to upload,
                        post, transmit or otherwise make available) any Material that:
                    </p>
                    <ul>
                        <li>
                            infringes the rights (including intellectual property rights and person; contains, promotes,
                            or provides information about unlawful activities or conduct;
                        </li>
                        <li>
                            is, or could reasonably be expected to be, defamatory, obscene, offensive, threatening,
                            abusive, pornographic, vulgar, profane, indecent or otherwise unlawful, vilifies, incites
                            violence or hatred, or is likely to offend, insult or humiliate or humiliate others based on
                            race, religion, ethnicity, gender, age, sexual orientation or orientation or any physical or
                            mental disability;
                        </li>
                        <li>exploits another person in any manner;</li>
                        <li>contain sexual acts, nudity, pornography and is not of a violent nature;</li>
                        <li>
                            includes an image or personal information of another person or persons unless you have their
                            consent;
                        </li>
                        <li>poses or creates a privacy or security risk to any person;</li>
                        <li>
                            you know or suspect (or ought reasonably to have known or suspected) to be false, misleading
                            or deceptive;
                        </li>
                        <li>contains large amounts of untargeted, unwanted or repetitive content;</li>
                        <li>contains restricted or password only access pages, or hidden content;</li>
                        <li>
                            contains viruses, or other computer codes, les or programs designed to interrupt, limit or
                            destroy the functionality of other computer software or hardware;
                        </li>
                        <li>
                            advertises, promotes or solicits any goods or services or commercial activities (except
                            where expressly permitted or authorised by us); or
                        </li>
                        <li>contains financial, legal, medical or other professional advice.</li>
                    </ul>
                    <p>(d) interfere with, disrupt, or create an undue burden on the Website;</p>
                    <p>
                        (e) use any robot, spider, or other device or process to retrieve, index, or in any way
                        reproduce, modify or circumvent the navigational structure, security or presentation of the
                        Website;
                    </p>
                    <p>(f) use the Website with the assistance of any automated scripting tool or software; </p>
                    <p>(g) frame or mirror any part of the Website without our prior written authorisation; </p>
                    <p>
                        (h) use code or other devices containing any reference to the Website to direct other persons to
                        any other web page;
                    </p>
                    <p>
                        (i) except to the extent permitted by law, modify, adapt, sublicense, translate, sell, reverse
                        engineer, decipher, decompile or otherwise disassemble any portion of the Website or cause any
                        other person to so; or
                    </p>
                    <p>
                        (j) delete any attributions or legal or proprietary notices in the Website facilitate or
                        encourage any breaches of these Terms and Conditions, or policies (including our Privacy
                        Policy).
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; USER MATERIAL</strong>
                    </p>
                    <p>
                        The Website may enable you to upload or otherwise submit information, content or data (including
                        illustrations and photographs) (User Material). You are solely responsible for the User
                        Material.&nbsp;
                    </p>
                    <p>You must ensure that the User Material:&nbsp;</p>
                    <p>
                        (a) is accurate and complete at the time it is uploaded or submitted to via the Website, and
                        promptly advise The company or add or change the User Material (as appropriate) if you become
                        aware that the User Material is not accurate or complete;&nbsp;
                    </p>
                    <p>(b) does not breach these Terms and Conditions.&nbsp;</p>
                    <p>By uploading, transmitting, posting or otherwise making available User Material, you:</p>
                    <p>
                        (a) grant us a non-exclusive, worldwide, royalty-free, perpetual, licence to use, reproduce,
                        edit and exploit the Material in any form and for any purpose;
                    </p>
                    <p>
                        (a) except where expressly stated otherwise, also grant each user of the Website a nonexclusive,
                        worldwide, royalty free, perpetual, licence to use, reproduce, edit and exploit the Material in
                        any form for any purpose, subject to these Terms and Conditions;
                    </p>
                    <p>(b) warrant that you have the right to grant the abovementioned licences;</p>
                    <p>
                        (c) warrant that The company’s use of the User Material will not infringe the rights of a third
                        party or otherwise breach any obligations of confidence or any law relating to the User
                        Material.
                    </p>
                    <p>
                        (d) unconditionally waive all moral rights (as de ned by the Copyright Act 1968) which you may
                        have in respect of the Material.
                    </p>
                    <p>
                        The company reserves the right to remove or amend (with or without notice and at The
                        company&rsquo;s sole discretion) any User Material that The company considers breaches these
                        Terms and Conditions. The company is under no obligation to monitor the User Material for any
                        purpose, including for the purposes of ensuring compliance with these Terms and
                        Conditions.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CONTENT &nbsp;</strong>
                    </p>
                    <p>
                        All Content is provided as general information only and does not take into account your personal
                        financial circumstances. It is not intended as advice and must not be relied upon as such. You
                        should obtain your own financial and legal advice before making any investment decisions based
                        on any Content obtained from the Website.&nbsp;
                    </p>
                    <p>
                        To the extent permitted by law, we make no warranty regarding Content in the Website. If you are
                        aware of a data error in the Website, please contact us.
                    </p>
                    <p>
                        The company does not review all User materials. The company excludes all liability in respect of
                        the Content. If you have a complaint regarding any Content displayed in the Website or otherwise
                        communicated through the Website, The company&rsquo;s sole obligation will be to review any
                        written written complaint notified to it and, at its sole discretion, modify or remove the
                        particular Content.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; THIRD PARTY LINKS AND CONTENT &nbsp;</strong>
                    </p>
                    <p>
                        The Website may contain links to third party websites. Such websites do not form part of the
                        Website and are not under our control. We do not accept any responsibility in connection with
                        any such website. Websites Websites which are linked to the Website are not covered by these
                        Terms and Conditions, and may their own terms and conditions and privacy policy by which they
                        operate. If you choose to access these sites, you do so at your own risk.&nbsp;
                    </p>
                    <p>The company is not responsible for and will not be liable in respect of: </p>
                    <p>
                        (a) the content or operation of those websites or any of the goods and services that they
                        describe; or
                    </p>
                    <p>
                        (b) for any loss or damage company’s provision of (including your access and use of) any such
                        links (including any incorrect links to an external website).
                    </p>
                    <p>
                        You must not link to or frame (or otherwise permit a person to so link to or frame) the Website
                        without The company&rsquo;s prior written permission.&nbsp;
                    </p>
                    <p>
                        The Website may display or feature third party advertising, which does not mean The company
                        endorses or otherwise recommends the relevant advertisement, advertiser, or its products and
                        services in so displaying or featuring the advertisement.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PROPERTY REPORTS&nbsp;</strong>
                    </p>
                    <p>
                        If you request a property report via our Website, you acknowledge and agree that the property
                        reports are of a general nature only, based on the historical data we collect and will not take
                        into account the potential impact of external factors (such as changes in the economy, future
                        planned infrastructure, regulation, environment or the property market) which may affect the
                        value of the property.
                    </p>
                    <p>
                        In providing a property report, we attempt to ensure that the content is current but we do not
                        guarantee its currency. Property reports should not be regarded as advice or relied upon by you
                        or any other person and we recommend that you seek professional advice before making any
                        property decisions.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PRIVACY&nbsp;</strong>
                    </p>
                    <p>
                        We collect personal information about you in order to process your registration, give you access
                        to our Website, provide services to you and for the purposes otherwise set out in our Privacy
                        Policy.&nbsp;
                    </p>
                    <p>
                        We may disclose that information to third parties that help us deliver our services (including
                        information technology suppliers, communication suppliers) or as required by law.&nbsp;
                    </p>
                    <p>
                        By providing your personal information to us, you consent to the collection, use, storage and
                        disclosure of that information as described in the Privacy Policy and these Terms. For more
                        details, please see Privacy policy.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; INTELLECTUAL PROPERTY &nbsp;</strong>
                    </p>
                    <p>
                        By uploading, transmitting, posting or otherwise making available User Material, you grant The
                        company a world-wide, royalty-free, perpetual, irrevocable, transferrable and nonexclusive
                        licence to:
                    </p>
                    <p>
                        (a) use, reproduce, modify, adapt, communicate, display, perform, store and distribute the User
                        Material;
                    </p>
                    <p>
                        (b) without limiting (a), create statistics, databases and compilations for use by The company
                        and third parties;
                    </p>
                    <p>(c) permit any person to assist The company to do any of the things referred to in (a); and</p>
                    <p>(d) sub-licence any of the rights granted to The company under (a) to (c) to any person.</p>
                    <p>
                        This license continues even if you stop using the Website and our Services. And you
                        unconditionally waive all moral rights (as defined by the Copyright Act 1968) which you may have
                        in respect of User Material.
                    </p>
                    <p>
                        Nothing in this Agreement operates to assign or otherwise transfer any right, interest or title
                        in or to any intellectual property rights (including moral rights) of a party, except as
                        expressly stated.&nbsp;
                    </p>
                    <p>Subject to our license conditions, you:</p>
                    <p>
                        (a) may reproduce and display Content from the Website for your own personal, noncommercial use
                        only;
                    </p>
                    <p>
                        (b) could share Content from the Website with proper attributions and without changes to any
                        original data and photo. You cannot remove image watermarks;
                    </p>
                    <p>
                        (c)may not use Content from the Website to establish, maintain or provide, or assist in
                        establishing, maintaining or providing your own publications, Internet site or other means of
                        distribution;
                    </p>
                    <p>
                        (d)may not collect Content or other information using automated means (such as harvesting bots,
                        robots, spiders, or scrapers);
                    </p>
                    <p>
                        (e) may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license,
                        commercially exploit, create derivative works from, transfer, or sell any Content, software,
                        products or services contained within the Website.
                    </p>
                    <p>
                        (f)may be prohibited from accessing or using or sharing Content if you are on behalf of a
                        corporate entity or you want to use the Website for commercial purposes.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; BRAND FEATURES</strong>
                    </p>
                    <p>
                        Brand features is defined as the trade names, trademarks, service marks, logos, domain names,
                        and other distinctive brand features of each party. Except where expressly stated, these Terms
                        and Conditions do not grant you any right, title, or interest in or to the Company’s Brand
                        Features.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>13.&nbsp; DISCLAIMER &nbsp;</strong>
                    </p>
                    <p>You use the Website at your sole risk.</p>
                    <p>
                        Except where expressly stated otherwise, all Content is provided as a guide only and does not
                        take into account your personal financial circumstances. It is not intended as advice and must
                        not be relied upon as such. You should obtain your own financial and legal advice before making
                        any investment decisions based on any Content obtained from the Website.&nbsp; We do not make
                        any representation or warranty that Content from Website will be reliable, accurate or complete,
                        nor do we accept any responsibility arising in any way from errors or omissions.
                    </p>
                    <p>
                        We will not be liable for loss resulting from any action or decision by you in reliance on the
                        Content in the Website, nor any interruption, delay in operation or transmission, virus,
                        communications failure, Internet access difficulties, or malfunction in equipment or software.
                        We do not take any responsibility and we are not liable for any damage caused by your sharing of
                        the Website or Contents or User Materials the Website.
                    </p>
                    <p>
                        To the extent permitted by law, we exclude all conditions and warranties relating to your use of
                        the Website that are not expressly set out in the Conditions.
                    </p>
                    <p>
                        To the extent that our liability for breach of any implied warranty or condition cannot be
                        excluded by law, our liability will be limited, at our option, to:
                    </p>
                    <p>(a) in the case of services supplied or offered by us:</p>
                    <ul>
                        <li>the re-supply of those services; or</li>
                        <li>the payment of the cost of having those services re-supplied; and</li>
                    </ul>

                    <p>(b) in the case of goods supplied or offered by us:</p>
                    <ul>
                        <li>the replacement of the goods or the supply of equivalent goods;</li>
                        <li>the repair of the goods;</li>
                        <li>the payment of the cost of having the goods replaced; or</li>
                        <li>the payment of the cost of having the goods repaired.</li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                        <strong>14.&nbsp; LIMITED WARRANTY &nbsp;</strong>
                    </p>
                    <p>
                        To the fullest extent permitted by the law, all warranties are hereby excluded, and we hereby
                        expressly disclaims all warranties:
                    </p>
                    <p>(a) that the Website and/or the Company will meet your requirements;</p>
                    <p>
                        (b) that the Website and/or the Company are fit for your purpose or are otherwise suitable for
                        your use;
                    </p>
                    <p>
                        (c) that the Website and/or the Company will be compatible with, or suitable for use with, your
                        software;
                    </p>
                    <p>
                        (d) that the Website and/or the Company or the property reports generated will be error-free,
                        correct, accurate, complete, reliable, secure, current, up-to-date or otherwise;
                    </p>
                    <p>
                        (e) that the statistical methods on which any of the Website and/or the Company are based use
                        appropriate or accurate assumptions;
                    </p>
                    <p>
                        (f) that the performance of the Website and/or the Company will not be affected by data entry
                        errors, including incorrect entries, double entries or delayed entries, or incorrect or untimely
                        data supplied by any third party suppliers;
                    </p>
                    <p>
                        (g) that the Website and/or the Company will function in an uninterrupted manner, be available
                        24 hours a day, 7 days a week, or be fully secure.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>15.&nbsp; CUSTOMER INDEMNITY &nbsp;</strong>
                    </p>
                    <p>
                        You shall indemnify The company, its officers, directors, employees and sub-contractors in
                        respect of all loss, damage, costs, expenses (including legal fees on a full indemnity basis),
                        fines, penalties, claims, demands and proceedings howsoever arising, whether in contract, tort
                        (including negligence) or under statute, in connection with any of the following:&nbsp;
                    </p>
                    <p>(a) any unlawful, negligent or willful act or omission by you; and</p>
                    <p>
                        (b) without limiting the foregoing, any claim that your use of the Website or any User Material
                        infringes the rights of any person (including intellectual property rights, rights in respect of
                        defamation, confidentiality, breach of contract and other personal and proprietary rights).
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>16.&nbsp; SEVERABILITY &nbsp;</strong>
                    </p>
                    <p>
                        If any provision of the Conditions is deemed invalid by a court of competent jurisdiction, the
                        invalidity of such provision shall not affect the validity of the remaining provisions of the
                        Conditions, which shall remain in full force and effect.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>17.&nbsp; NO WAIVER &nbsp;</strong>
                    </p>
                    <p>
                        No waiver of any term of the Conditions shall be deemed a further or continuing waiver of such
                        term or any other term. Any failure to assert any right under the Conditions shall not
                        constitute a waiver of right.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>18.&nbsp; APPLICABLE LAW &nbsp;</strong>
                    </p>
                    <p>
                        These Conditions shall be construed in accordance with and governed by the laws of New South
                        Wales, Australia. You consent to the exclusive jurisdiction of the courts in New South Wales
                        Courts to matter or dispute which arises under the Conditions.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>19.&nbsp; VARIATION&nbsp;</strong>
                    </p>
                    <p>
                        The company may update or change these Terms and Conditions at any time with or without notice.
                        If you continue to access or use the Website after The company updates or changes these Terms
                        and Conditions, then you will be taken to have agreed to these Terms and Conditions as updated
                        or changed. Since you are bound by these Terms and Conditions, you should periodically check
                        these Terms and Conditions.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>20.&nbsp; CONTACTING US</strong>
                    </p>
                    <p>
                        If you think that the Website has been accessed or used by another user in breach of the
                        Conditions, please email us at{' '}
                        <a href="mailto:feedback@aihouse.com.au">feedback@aihouse.com.au</a> .. We&rsquo;ll consider
                        whether there are grounds for taking any action, but you won&rsquo;t necessarily be contacted as
                        to our decision.
                    </p>
                    <p>
                        If you wish to send us a copyright infringement notification, you will need to identify the
                        Material(s) that you believe infringe(s) your copyright, identify each copyright protected work
                        in which you own the rights and which you believe has been infringed, identify how each
                        copyright protected work has been or is being infringed and include your contact information.
                        You will need to sign the notice and send it to{' '}
                        <a href="mailto:feedback@aihouse.com.au">feedback@aihouse.com.au</a> .
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>21.&nbsp; LANGUAGE DISCREPANCY</strong>
                    </p>
                    <p>
                        The English text of the Terms and Conditions shall be deemed authentic. In the event of any
                        dispute or misunderstanding as to the interpretation of the language or terms of the Terms and
                        Conditions, the English language version shall prevail.
                    </p>
                    <p>&nbsp;</p>
                    <p>These terms and conditions of use were last updated in June 2021.</p>
                    <p>&nbsp;</p>
                </div>
            </div>
            <Hidden smDown>
                <NewHomeFooter />
            </Hidden>
            <Hidden mdUp>
                <NewHomeFooterForMobile />
            </Hidden>
        </>
    );
});

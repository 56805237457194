import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { clsx, TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { isMobileDevice } from '../../../commons/utils';
import { delay } from '../../../commons/utils';
import { XPanel } from '../../../components/Layout/XPanel';
import { MapGlobalVars } from '../support/PriceMapConsts';
const useStyles = makeStyles((theme) => {
    return {
        mapTopLayer: {
            background: 'white',
            position: 'absolute',
            top: 100,
            left: 100,
            zIndex: 999999,
            borderRadius: 10,
            boxShadow:
                '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        },
        closeIcon: {
            position: 'absolute',
            top: 5,
            right: 5,
            cursor: 'pointer',
            fontWeight: 'bold',
        },
        popContent: {
            paddingTop: 10,
            paddingBottom: 10,
        },
        popItem: {
            marginTop: 10,
            width: '100%',
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: isMobileDevice() ? 12 : 14,
            cursor: 'pointer',
        },
        popItemOver: {
            background: 'lightgrey',
        },
    };
});

interface MapPopProps {
    open: boolean;
    position: { x: number; y: number };
    location: any;
}

export const MapPopup = rst.create<{ popProps: MapPopProps; closeEvent: any }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const { popProps } = props;
        const showSuburbTrans = async () => {
            if (!MapGlobalVars.clickedSuburbData) {
                await delay(200);
            }
            console.log(MapGlobalVars.clickedSuburbData);
            if (
                !MapGlobalVars.showSuburbTransConfirm ||
                MapGlobalVars.clickedSuburbData?.id != MapGlobalVars.transShowingSuburbData?.id
            ) {
                MapGlobalVars.clickedSuburbData = MapGlobalVars.transShowingSuburbData;
                MapGlobalVars.showSuburbTransConfirm = true;
                EventBus.dispatch(EVENT_NAMES.EVENT_HIDE_ALL_PROPERTIES, {});
                // EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_CLICKED_SUBURB_TRANS, {
                //     suburbId: MapGlobalVars.transShowingSuburbData?.id,
                //     suburbName: MapGlobalVars.clickedSuburbData.locality_name,
                //     movedTo: props.popProps?.location,
                // });
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, {
                    suburbId: MapGlobalVars.transShowingSuburbData?.id,
                    suburbName: MapGlobalVars.clickedSuburbData.locality_name,
                });
            } else {
                MapGlobalVars.showSuburbTransConfirm = false;
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_ALL_TRANS, {});
            }

            props.closeEvent();
        };
        const showAllProperties = () => {
            if (MapGlobalVars.showAroundTransCircle) {
                EventBus.dispatch(MapGlobalVars.nearByTransEventName, {
                    removeAroundTrans: true,
                });
            }
            EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_TO_ALL_PROPERTIES_LEVEL, {});
            props.closeEvent();
        };
        return popProps.open ? (
            <div
                className={classes.mapTopLayer}
                style={{ top: popProps.position.y - 100, left: popProps.position.x - 130 }}
                {...props}>
                <div className={classes.closeIcon} onClick={props.closeEvent}>
                    ×
                </div>
                <div className={classes.popContent}>
                    <PopItem itemEvt={showAllProperties}>
                        <span style={{ fontWeight: 'bold' }}>
                            {' '}
                            <TR name="VIEW_ALL_NEARBY"></TR>
                        </span>
                    </PopItem>

                    <PopItem itemEvt={showSuburbTrans}>
                        <TR
                            name={
                                !MapGlobalVars.showSuburbTransConfirm ||
                                MapGlobalVars.transShowingSuburbData == null ||
                                MapGlobalVars.transShowingSuburbData?.id != MapGlobalVars.priceMapFilter.suburbId
                                    ? 'SHOW_ALL_TRANS_THIS_SUBURB'
                                    : 'SHOW_ALL_TRANS_ON_MAP'
                            }></TR>
                    </PopItem>
                </div>
            </div>
        ) : null;
    };
});

const PopItem = rst.create<{ itemEvt: any }>((ctx) => {
    const state = rst.state({
        mouseOver: false,
    });
    return (props) => {
        const classes = useStyles();
        return (
            <div
                onClick={props.itemEvt}
                className={clsx(classes.popItem, state.mouseOver ? classes.popItemOver : '')}
                onMouseOver={() => {
                    state.mouseOver = true;
                }}
                onMouseOut={() => {
                    state.mouseOver = false;
                }}>
                {props.children}
            </div>
        );
    };
});

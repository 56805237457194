import { Permission, TimedRoles } from '../../commons/security/authz';

export class UserProfileDto {
    id: string;
    nickname: string;
    email: string;
    // phoneNumber: string;
    // fullName: string;
    // firstName: string;
    // lastName: string;
    password: string;
    newPassword: string;

    preferredLanguage: 'zh' | 'en';
    reportCountProperty: number;
    // address: string;
    roles?: TimedRoles;
    // subscribe: boolean;
    userStatus?: string;

    get hasTried() {
        return this.roles?.[Permission.TRIAL] != null;
    }

    // get fullName() {
    //     return `${this.firstName} ${this.lastName}`;
    // }
}

export interface UpdateProfilePo {
    nickname?: string;
    // phoneNumber: string;
    firstName: string;
    lastName: string;
    // fullName: string;
    email: string;
    password: string;
    newPassword: string;
    // address: string;
    // subscribe: boolean;
}

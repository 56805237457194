import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../../../commons';
import { isMobileDevice, numToMillion } from '../../../../../commons/utils';
import { getTypeByFeature, showGoogleResultByAddress } from '../../../support/propertyFeatureHelper';
import { StreetView } from '../../PropertyInfo';
import { PropBeds } from '../../PropertyInfo/PropBeds';
import { CommonSchoolOrStation } from '../SchoolOrTrainStation';
import { Chip } from '../../../../../components/Chip';

import { ShareOrFavOrOther } from '../ShareOrFavOrOther';
import { HelpComponent } from '../../../components/HelpComponent';
const useStyles = makeStyles((theme) => {
    return {
        propAddr: {
            fontSize: isMobileDevice() ? 11 : 14,
            fontWeight: 400,
            color: '#333333',
            cursor: 'pointer',
            height: isMobileDevice() ? 30 : null,
            overflow: 'hidden',
        },
        basicInfoDiv: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            height: '100%',
            // height: !isMobileDevice() ? 124 : 86,
        },
        price: {
            fontSize: isMobileDevice() ? 14 : 16,
            fontWeight: 700,
            color: '#333333',
        },
        priceDesc: {
            fontSize: 12,
            color: '#999999',
            fontWeight: 400,
        },
        icon: {
            width: 13,
            height: 13,
            cursor: 'pointer',
        },
        font: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                color: '#999999',
            },
        },
    };
});

export const NonRecentSoldHouseInfo = rst.create<{ prop: any; onMap?: boolean; onReport?: boolean }>((ctx) => {
    return (props) => {
        const { prop } = props;
        if (props.onMap) {
            setTimeout(() => {
                document.getElementById('houseAddressDiv').addEventListener('click', () => {
                    showGoogleResultByAddress(prop.address);
                });
            }, 1000);
        }

        console.log(prop);
        const classes = useStyles();
        const handle = () => {
            console.log('www');
        };

        return (
            <div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <StreetView
                            w={!isMobileDevice() ? 135 : 100}
                            h={!isMobileDevice() ? 124 : 100}
                            lat={props.prop.lat}
                            lng={props.prop.lon}
                            showFor="infopanel"
                            address={props.prop.address}></StreetView>
                        <div style={{ marginLeft: 10 }}></div>
                        <div className={classes.basicInfoDiv} style={{ height: isMobileDevice() ? 100 : 124 }}>
                            <div
                                style={{
                                    display: props.onReport ? 'none' : 'flex',
                                    paddingRight: 15,
                                    width: '100%',
                                }}>
                                <div
                                    className={classes.propAddr}
                                    id="houseAddressDiv"
                                    onClick={() => {
                                        showGoogleResultByAddress(prop.address);
                                    }}>
                                    {prop.address}
                                </div>
                                {props.onReport ? null : <ShareOrFavOrOther prop={prop}></ShareOrFavOrOther>}
                            </div>

                            <div style={{ marginTop: 2 }}></div>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <div className={classes.price}>{numToMillion(prop.price)}</div>
                                    <div className={classes.priceDesc}>
                                        <TR name="LAST_SOLD_TIME"></TR>(
                                        <span style={{ fontSize: 10 }}>{prop.sold_contract_date}</span>)
                                    </div>
                                </div>
                                <div style={{ marginLeft: 10 }}></div>
                                <div>
                                    <div className={classes.price}>
                                        {numToMillion(prop.landValue || prop.land_value_reset)}
                                    </div>
                                    <div className={classes.priceDesc}>
                                        <TR name="RECENT_LAND_VALUE"></TR>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: 10 }}></div>
                            <PropBeds
                                landSize={prop.allFeatures?.prop_area}
                                smallSize={true}
                                beds={prop.allFeatures?.prop_bed}
                                baths={prop.allFeatures?.prop_bath}
                                cars={prop.allFeatures?.prop_car}
                                propType={getTypeByFeature(prop.allFeatures)}></PropBeds>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 10 }}></div>
                <CommonSchoolOrStation
                    onReport={props.onReport}
                    onMap={props.onMap}
                    prop={prop}></CommonSchoolOrStation>

                <div style={{ marginTop: 10 }}></div>
                <div
                    style={{
                        display: props.onReport ? 'none' : 'flex',
                        marginTop: 15,

                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                    onClick={handle}>
                    <div>
                        <div className={classes.font}>
                            <TR name={'Zoning'}></TR>
                        </div>
                        <div>
                            <Chip label={props.prop.zoningStr}></Chip>
                        </div>
                    </div>
                    <div>
                        <div className={classes.font}>
                            {' '}
                            <TR name={'PriceToStreet'}></TR>
                        </div>
                        <div>
                            <Chip
                                label={
                                    props.prop.streetPct == 0 ? '-' : (props.prop.streetPct * 100).toFixed(1) + '%'
                                }></Chip>
                        </div>
                    </div>

                    <div>
                        <div className={classes.font} style={{ display: 'flex', alignItems: 'center' }}>
                            <TR name={'StreetHouseChangeHand'}></TR>
                            <div style={{ width: 23 }}>
                                <HelpComponent isText={true} content="StreetHouseChangeHand_help" maxWidth="xs" />
                            </div>
                        </div>
                        <div>
                            <Chip
                                label={
                                    (props.prop.streetChangeHandRate == 0
                                        ? '-'
                                        : (props.prop.streetChangeHandRate * 100).toFixed(1) + '%') +
                                    '(' +
                                    (props.prop.suburbChangeHandRate == 0
                                        ? '-'
                                        : (props.prop.suburbChangeHandRate * 100).toFixed(1) + '%') +
                                    ')'
                                }></Chip>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
});

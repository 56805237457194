import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { queryAdjacent_byGraphQL, queryPropEnhance_byGraphQL } from '../../commons/graphqlClient/graphAPI';
import { MobileAroundPriceBar } from '../mobile/component/aroundPriceMap/mobileAroundPriceBar';
import { AdjacentResult } from '../mobile/component/aroundPriceMap/adjacentResult';
import { changeToFirstCaptial } from '../../commons/utils';
import { AroundPriceMap } from '../mobile/component/aroundPriceMap';

export default function AdjacentSoldDisplay(props: any) {
    const params = useParams() as any;
    console.log(params);
    const propId = params.propId;
    // const interval = parseInt(params.interval);
    // const distance = parseInt(params.distance);
    // const bedrooms = [params.bedRooms];

    const [property, setProperty] = useState(null as any);
    const [aroundSolds, setAroundSolds] = useState([] as any);
    useEffect(() => {
        if (propId) {
            console.log('prop id ,', propId);
            const loadPropertyInfo = async () => {
                const propertyResult: any = await queryPropEnhance_byGraphQL(propId);
                if (!propertyResult.errors) {
                    setProperty(propertyResult.data.property);
                }
            };
            loadPropertyInfo();
        }
    }, [propId]);
    const requestFull = () => {
        window.parent.postMessage('TestData', 'http://localhost:3005');
    };

    return (
        <div>
            {property && (
                <AroundPriceMap
                    propId={property.propId}
                    locality={property.locality?.localityName}
                    schools={{
                        primary: property.primarySchools,
                        secondary: property.secondarySchools,
                        selective: property.selectiveSchools,
                        private: property.privateSchools,
                    }}
                    propCenter={{
                        lat: property.propY,
                        lon: property.propX,
                    }}></AroundPriceMap>
            )}
            {/* For External
            <Button onClick={requestFull}>Full</Button> */}
        </div>
    );
}

import React from 'react';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles({
    line: {
        borderBottom: '1px #e8ecf0 solid',
    },
});

export const CardLine = (props: any) => {
    const { forCard } = props;
    const classes = useStyles();
    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div
                style={forCard ? { width: '100%', boxShadow: '0 1px 3px 0 rgb(32 33 36 / 8%)' } : { width: '96%' }}
                className={classes.line}></div>
        </div>
    );
};

import React from 'react';
import { rst } from 'rt-state';
import { MapContainer } from './MapContainer';
import { SuburbHead } from './SuburbHead';
import { FilterGroup } from './FilterGroup';
import { AppDrawer, createXDrawerController } from '../../components/Drawer';
import { RightDrawer } from './RightDrawer';
import { FilterOption } from '../../models/filterOption';
import { useStyles } from './Suburb.styles';
import { SuburbPageState, ALL_TAB_OPTIONS, ALL_FILTER_OPTIONS } from './common';
import { FilterGroupResult } from './DrawerContent';
import { SelectOption } from '../../components/common';
import { SurburbSearchResultPanel } from './SearchResult/SuburbSearchResultPanel';

export const SuburbPage = rst.create((ctx) => {
    const appDrawerCtrl = createXDrawerController();
    const tabOption = ALL_TAB_OPTIONS[0];
    const filterOptions = ALL_FILTER_OPTIONS[tabOption.value];
    const filterOption = filterOptions[0];
    const state = rst.state<SuburbPageState>({
        tabOption,
        filterOptions,
        filterOption,
        searchButtonClicked: false,
    });

    const onMenuButtonClick = () => {
        appDrawerCtrl.open();
    };
    function onTabSelect(option: SelectOption) {
        state.tabOption = option;
        state.filterOptions = ALL_FILTER_OPTIONS[option.value];
        state.filterOption = state.filterOptions[0];
    }
    function onFilterSelect(filter: FilterOption) {
        state.filterOption = filter;
        console.log(filter);
    }

    /**
     * When search dialog 'search' button clicked, should open the right drawer
     */
    function onSearchButtonClick() {
        ctrl.open();
        state.searchButtonClicked = true;
    }
    const ctrl = createXDrawerController();

    return (props) => {
        const classes = useStyles(props);

        return (
            <div className={classes.root}>
                {rst.view(() => (
                    <SuburbHead
                        categoryTabsProps={{
                            value: state.tabOption,
                            onSelect: onTabSelect,
                        }}
                        onMenuButtonClick={onMenuButtonClick}
                        onSearchButtonClick={onSearchButtonClick}
                    />
                ))}

                <div className={classes.mapContainer}>
                    <MapContainer suburbPageState={state} />
                    <div style={{ flexGrow: 1 }}>
                        {rst.view(() => (
                            <FilterGroup
                                value={state.filterOption}
                                options={state.filterOptions}
                                onSelect={onFilterSelect}
                            />
                        ))}
                    </div>
                    <RightDrawer ctrl={ctrl}>
                        <div className={classes.drawerContent}>
                            {state.searchButtonClicked ? (
                                <SurburbSearchResultPanel></SurburbSearchResultPanel>
                            ) : (
                                <FilterGroupResult suburbPageState={state} />
                            )}
                        </div>
                    </RightDrawer>
                </div>

                <AppDrawer drawerCtrl={appDrawerCtrl} />
            </div>
        );
    };
});

import { Card, Dialog, DialogContent, IconButton, makeStyles } from '@material-ui/core';
import { Height } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            background: 'rgb(249 249 249)',
            //width: '396px',
            padding: '9px 0px 9px 0px',
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
        },
        card: {
            position: 'relative',
            //maxWidth: '357px',
            padding: '20px 20px',
            background: 'rgb(255 255 255)',
            margin: '0 auto',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            // height: '300px',
            // boxShadow: '0px 0px 10px rgb(210 210 210)',
        },
        cardHeader: {
            position: 'absolute',
            left: '-6px',
            top: '15px',
        },
        cardHeaderBigPart: {
            backgroundColor: '#00A3FF',
            color: 'white',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            minWidth: '116px',
            display: 'inline-block',
            height: '31px',
            '& h2': {
                fontSize: '15px',
                margin: '0 auto',
                padding: '5px 10px',
                position: 'relative',
                top: '-1px',
            },
        },
        cardHeaderSmallPart: {
            // backgroundColor: '#0054EB',
            background: 'linear-gradient(45deg,rgb(255 255 255) 50%, #0054EB 50%)',
            width: '6px',
            height: '6px',
        },
        cardContentWithHeader: {
            padding: '45px 0px 0px 0px',
        },
        cardContentWithoutHeader: {
            padding: '0px',
        },
    };
});

export const CardWithHeader = rst.create<{ header?: string; mobile?: any }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const { header } = props;
        return (
            <div className={classes.root}>
                <div className={classes.card}>
                    {header ? (
                        <div className={classes.cardHeader}>
                            <div
                                className={classes.cardHeaderBigPart}
                                style={
                                    props.mobile
                                        ? { background: 'white', color: '#333333', marginLeft: 8, fontSize: 16 }
                                        : {}
                                }>
                                <h2>
                                    <TR name={header}></TR>
                                </h2>
                            </div>
                            <div className={classes.cardHeaderSmallPart}></div>
                        </div>
                    ) : null}
                    <div className={header ? classes.cardContentWithHeader : classes.cardContentWithoutHeader}>
                        {props.children}
                    </div>
                </div>
            </div>
        );
    };
});

import { CardContent, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { CardTitle } from '../propertyDetails/cardTitle';
import Tabs, { Tab } from 'react-best-tabs';
import { TR } from '../../../../commons';
import { SuburbTenYearTrend } from '../../../PriceMap/Charts/SuburbTenYearsTrend';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { CardPermission } from '../cardPermission';
import { SuburbHalfYearAuction } from '../../../PriceMap/Charts/SuburbHalfYearAuction';
const useStyles = makeStyles({
    tab: {
        fontSize: '8px',
    },
});
export const SuburbPriceTrend2 = (props: any) => {
    const { suburbData } = props;
    const [closeContent, setCloseContent] = useState(true);

    const closeContentEvt = () => {
        setCloseContent(!closeContent);
    };
    const [tab, setTab] = useState(1);
    const changeTab = (evt, tab) => {
        setTab(tab);
    };

    const [hasPermission, setHasPermission] = useState(false);
    EventBus.on(EVENT_NAMES.EVENT_MOBILE_CARD_NOPERMISSION, (evtData: any) => {
        setHasPermission(evtData.permission);
    });
    const classes = useStyles();
    return (
        <div style={{ width: '100%', padding: '10px' }}>
            <CardTitle
                closeContent={closeContent}
                closeEvt={closeContentEvt}
                title={'Price Trend Last 12 months'}></CardTitle>
            <CardContent style={{ padding: 0 }}>
                {closeContent ? null : (
                    <>
                        <div style={{ width: '50%' }}>
                            <Tabs
                                activeTab="1"
                                className="mt-5"
                                ulClassName=""
                                activityClassName="bg-success"
                                onClick={changeTab}>
                                <Tab title={<TR name="House"></TR>} className={classes.tab}></Tab>
                                <Tab title={<TR name="Apt"></TR>} className={classes.tab}></Tab>
                            </Tabs>
                        </div>
                        <div style={{ marginTop: '20px' }}></div>
                        {tab == 1 ? (
                            <div style={{ position: 'relative', minHeight: 150 }}>
                                {hasPermission ? (
                                    <SuburbHalfYearAuction
                                        suburbName={props.suburbData.locality_name}
                                        sydneyData={suburbData.syd_price_trend_house}
                                        localData={suburbData.price_trend_house}></SuburbHalfYearAuction>
                                ) : (
                                    <CardPermission></CardPermission>
                                )}
                            </div>
                        ) : null}
                        {tab == 2 ? (
                            <div style={{ position: 'relative', minHeight: 150 }}>
                                {hasPermission ? (
                                    <SuburbHalfYearAuction
                                        suburbName={props.suburbData.locality_name}
                                        sydneyData={suburbData.syd_price_trend_apt}
                                        localData={suburbData.price_trend_apt}></SuburbHalfYearAuction>
                                ) : (
                                    <CardPermission></CardPermission>
                                )}
                            </div>
                        ) : null}{' '}
                    </>
                )}
            </CardContent>
        </div>
    );
};

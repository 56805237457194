import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { API, TR } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '1200px',
        // padding: '10px 10px 30px 10px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '28px 16px',
            width: '100vw',
            // justifyContent: 'space-between',
        },
        '& h2': {
            fontSize: '26px',
            margin: '45px 0px 0px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '19px',
                margin: '0px 0px 10px 0px',
            },
        },
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '30px',
        '& img': {
            marginTop: '5px',
            [theme.breakpoints.down('sm')]: {
                width: '30px',
                margin: '5px 5px 0px 0px',
            },
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingLeft: '31px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0px',
        },

        '& h3': {
            fontSize: '20px',
            margin: '0px 0px 13px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '26px',
                margin: '0px 0px 5px 0px',
            },
        },
        '& p': {
            fontSize: '16px',
            lineHeight: '22px',
            margin: '0px 0px 0px 0px',
            // textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                margin: '0px',
                fontSize: '13px',
                lineHeight: '18px',
            },
        },
    },
    button: {
        margin: '40px 0px 20px 0px',
        [theme.breakpoints.down('sm')]: {
            margin: '20px 0px 0px 0px',
        },
    },
    blogs: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    blogCard: {
        borderRadius: 10,
        width: 330,
        height: 190,

        position: 'relative',
    },
    blogTitle: {
        position: 'absolute',
        left: 19,
        top: 21,
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
        color: 'white',
    },
    blogDes: {
        position: 'absolute',
        left: 25,
        top: 50,
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
        color: 'white',
    },
}));

export const BlogAndLive = (props: any) => {
    const appProvider = AppProvider.use();

    const { mobile } = props;
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        API.get(null, appProvider.getLanguage() == 'zh' ? 'blogs.json' : 'blogsE.json').then((b: any) => {
            setBlogs(b);
            console.log('blogs is ', b);
        });
    }, [appProvider.getLanguage()]);

    const showBlogs = () => {
        const blogsContent: any = [];
        blogs.forEach((blog: any) => {
            blogsContent.push(<BlogCard blog={{ ...blog }}></BlogCard>);
        });

        return blogsContent;
    };

    const classes = useStyles();
    return (
        <div style={{ background: 'rgb(255 255 255)' }}>
            {mobile ? (
                <div style={{ padding: '1.875rem 1rem 1.4rem' }}>
                    <h3 style={{ margin: 0, fontSize: '1.375rem' }}>
                        <TR name="BlogAndLive"></TR>
                    </h3>
                </div>
            ) : (
                <div className={classes.root}>
                    <h2>
                        <TR name={'BlogAndLive'} />
                    </h2>
                </div>
            )}
            <div className={classes.blogs}>{showBlogs()}</div>
        </div>
    );
};

const BlogCard = (props: any) => {
    const { blog } = props;
    const classes = useStyles();
    return (
        <div style={{ padding: 20, cursor: 'pointer' }}>
            <div
                onClick={() => {
                    window.open(blog.link, '_blank');
                }}
                className={classes.blogCard}
                style={{ backgroundImage: `url(${blog.bgImg})`, backgroundSize: 'cover' }}>
                <div className={classes.blogTitle}>{blog.title}</div>
                <div className={classes.blogDes}>{blog.des}</div>
            </div>
        </div>
    );
};

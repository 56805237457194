import { storage } from '../storage';
import { injectToken } from '../axios';
import { rst } from 'rt-state';
import { TokenInfo, decodeToken } from './authn';
import { UserProfileDto } from '../../models/backend';
import { getUserProfile as getUserProfileService } from '../../services/auth';
import { delay } from '../utils';
import { Permission } from './authz';
import { compare2Date } from '../date';

function createSecurityProvider() {
    const tokenInfo = rst.stateS<TokenInfo>({ token: '', valid: false });
    const userProfile = rst.stateS<any>(null);

    const applyToken = (token: string) => {
        tokenInfo.value = decodeToken(token);
        injectToken(token);
    };

    const loading = rst.stateS(true);

    const setToken = async (token: string, expireTime?) => {
        while (loading.value) {
            console.log('waiting for init');
            await delay(100);
        }
        applyToken(token);
        await storage.save('token', { value: token, expire: expireTime || new Date('2099-12-31') });
    };

    const isLoading = () => {
        return loading.value;
    };

    const hasPermission = (permission: Permission) => {
        console.log('check permission-=');
        console.log(userProfile.value?.roles);
        console.log('checking===>' + permission);
        return Permission.hasPermission(userProfile.value?.roles, permission);
    };

    const getExpireTime = (permission: Permission) => {
        const date = userProfile.value?.roles?.[permission] ?? null;
        if (date != null) {
            return new Date(date);
        }
        return null;
    };
    const isExpired = (permission: Permission) => {
        const expireTime = getExpireTime(permission);
        const now = new Date();
        return expireTime < now;
    };

    function hasLogin() {
        return tokenInfo.value?.valid;
    }

    const getUserProfile = () => {
        return userProfile.value || {};
    };

    const setUserProfile = (profile: UserProfileDto) => {
        userProfile.value = profile;
    };

    async function init() {
        try {
            const tokenObj: any = await storage.get('token');
            const token = tokenObj.value;
            const tokenExpireDate = tokenObj.expire;
            console.log('expire date is ', tokenExpireDate);
            if (!compare2Date(new Date(), tokenExpireDate)) {
                applyToken(token);
            }

            if (tokenInfo.value.valid) {
                try {
                    userProfile.value = await getUserProfileService();
                } catch (e) {
                    //logout();
                }
            } else {
                console.log('token is invalid:', tokenInfo.value);
                if (token) {
                    await storage.remove('token');
                }
            }
        } catch (e) {
        } finally {
            loading.value = false;
        }
    }

    async function refreshProfile() {
        userProfile.value = await getUserProfileService();
    }

    function logout() {
        storage.save('token', null).then();
        userProfile.value = null;
        tokenInfo.value = { token: '', valid: false };
    }

    init().then();

    return {
        tokenInfo,
        setToken,
        isLoading,
        hasPermission,
        getExpireTime,
        setUserProfile,
        getUserProfile,
        hasLogin,
        logout,
        isExpired,
        refreshProfile,
    };
}

export const SecurityProvider = rst.createProvider(createSecurityProvider);

import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { SecurityProvider, storage } from '../../commons';
import { getParamValueFromURL, isMobileDevice } from '../../commons/utils';
import { api_getPropertyFeatures } from '../../services/property';
import { CommonLogin } from '../PriceMap/components/Login';
import { CardWithHeader } from './components/CardWithHeader';
import { RptHeader } from './components/RptHeader';
import {
    constructPropObjFromFeature,
    getAptSoldMedianPrice,
    getHouseSoldRank,
    getPropLastRecord,
    getSuburbSoldPriceList,
    getTypeByFeature,
} from '../PriceMap/support/propertyFeatureHelper';
import { Card10 } from './ReportCards/Card10';
import { Card11 } from './ReportCards/Card11';
import { Card12 } from './ReportCards/Card12';
import { Card21 } from './ReportCards/Card21';
import { Card22 } from './ReportCards/Card22';
import { Card23 } from './ReportCards/Card23';
import { Card24 } from './ReportCards/Card24';
import { Card25 } from './ReportCards/Card25';
import { Card30 } from './ReportCards/Card30';
import { Card31 } from './ReportCards/Card31';
import { Card32 } from './ReportCards/Card32';
import { Card33 } from './ReportCards/Card33';
import { Card34 } from './ReportCards/Card34';
import { OverViewCard } from './ReportCards/OverviewCard';
import { queryProp_byGraphQL } from '../../commons/graphqlClient/graphAPI';
import { EventBus, EVENT_NAMES } from '../../commons/EventBus';
import { CardAbout } from './ReportCards/CardAbout';
import { CardFrontpage } from './ReportCards/CardFrontpage';
import { Card13 } from './ReportCards/Card13';
import { Card14 } from './ReportCards/Card14';
import { Card20 } from './ReportCards/Card20';
import { Card40 } from './ReportCards/Card40';
import { CardDisclaimer } from './ReportCards/CardDisclaimer';
import { Fab } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { AppProvider } from '../../commons/appProvider';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
            padding: '20px 0',
        },
        reportTopDiv: {
            zoom: isMobileDevice() ? 1 : 1.2,
            // position: 'absolute',
            // top: 0,
            // left: '0px',
            zIndex: 99999,
            width: 447,
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '24px',
            background: 'white',
        },
    };
});

export const HouseReport = rst.create<{ propId?: string }>((ctx) => {
    var estimateInfo: any = {};
    storage.get('CURRENT_PROPERTY_ESTIMATE').then((savedPropertyEstimate) => {
        console.log(savedPropertyEstimate);
    });

    const pageState = rst.state({
        loadedFromAPI: false,
        loadedFromQL: false,
        propId: ctx.props.propId,
        prop: null,
        propQL_result: null,
    });
    const requestURL = window.location.search;
    if (requestURL.indexOf('reportPropId') > -1 || ctx.props.propId) {
        pageState.propId = getParamValueFromURL(requestURL, 'reportPropId') as string;
        if (!pageState.propId) {
            pageState.propId = ctx.props.propId;
        }

        queryProp_byGraphQL(pageState.propId).then((propertyResult: any) => {
            if (propertyResult.errors) {
            } else {
                pageState.propQL_result = propertyResult.data;
                console.log(pageState.propQL_result);
                pageState.loadedFromQL = true;
            }
        });
    }

    if (requestURL.indexOf('estimateInfo') > -1) {
        estimateInfo = JSON.parse(getParamValueFromURL(requestURL, 'estimateInfo') as string);
        console.log(estimateInfo);
    }

    storage.get('CURRENT_REPORT_PROPERTY_FEATURE').then((propFeatureStr: any) => {
        console.log(propFeatureStr);
        const propertyFeatures = JSON.parse(propFeatureStr);
        pageState.prop = constructPropObjFromFeature([propertyFeatures]);

        pageState.loadedFromAPI = true;
    });

    setTimeout(() => {
        EventBus.dispatch(EVENT_NAMES.EVENT_ESTIMAGE_VALUE_CHANGE, estimateInfo?.estimatePrice);
    }, 3000);

    return (props) => {
        const classes = useStyles();
        const getReportContent = () => {
            if (!pageState.loadedFromAPI || !pageState.loadedFromQL) {
                return (
                    <Backdrop className={classes.backdrop} open={true}>
                        <CircularProgress size={78} color="inherit" />
                        <div style={{ marginTop: 20 }}></div>
                        Generating Report
                    </Backdrop>
                );
            } else {
                return (
                    <div id="reportRoot" className={classes.reportTopDiv}>
                        <div
                            style={{ position: 'sticky', right: 0, top: 0, zIndex: 99999 }}
                            onClick={() => {
                                window.print();
                            }}>
                            <Fab color="primary" aria-label="add">
                                <PrintIcon />
                            </Fab>
                        </div>

                        {pageState.prop && pageState.propQL_result ? (
                            <div className={classes.root}>
                                <RptHeader zh="RptHeader1" />
                                {/* <RptHeaderWithDivider en="Overview" zh="此房概况" /> */}
                                <CardFrontpage />
                                <RptHeader zh="RptHeader2" />
                                <OverViewCard
                                    property={pageState.prop}
                                    prop={pageState.propQL_result}
                                    estimateInfo={estimateInfo}
                                />
                                <div style={{ pageBreakBefore: 'always' }}>
                                    <RptHeader zh="RptHeader3" />
                                </div>
                                <Card10 property={pageState.propQL_result} />
                                <Card11 property={pageState.propQL_result} />
                                <Card12 property={pageState.propQL_result} />
                                <Card13 prop={pageState.prop} propQL={pageState.propQL_result} />
                                <Card14 localityData={pageState.propQL_result.property.locality} />
                                <div style={{ pageBreakBefore: 'always' }}>
                                    <RptHeader zh="RptHeader4" />
                                </div>
                                <Card20 />
                                <Card21 property={pageState.propQL_result.property} />
                                <Card22 property={pageState.propQL_result.property} />
                                <div style={{ pageBreakBefore: 'always' }}>
                                    <Card23 prop={pageState.propQL_result} />
                                </div>
                                <div style={{ pageBreakBefore: 'always' }}>
                                    <Card24 prop={pageState.propQL_result} />
                                </div>
                                <Card25 prop={pageState.prop} property={pageState.propQL_result.property} />
                                <RptHeader zh="RptHeader5" />
                                <Card30 estimateInfo={estimateInfo} />
                                <Card31
                                    refreshEstimateEvt={() => {
                                        EventBus.dispatch(
                                            EVENT_NAMES.EVENT_ESTIMAGE_VALUE_CHANGE,
                                            estimateInfo?.estimatePrice,
                                        );
                                    }}
                                    prop={pageState.prop}
                                />

                                <div style={{ pageBreakBefore: 'always' }}>
                                    <Card32
                                        prop={pageState.prop}
                                        pql={pageState.propQL_result}
                                        estimateMiddle={estimateInfo.estimatePrice.middle}
                                    />
                                </div>
                                <Card34
                                    prop={pageState.prop}
                                    property={pageState.propQL_result}
                                    estimateInfo={estimateInfo}
                                />
                                <Card40 />
                                <RptHeader zh="RptHeader6" />
                                <CardAbout />
                                <RptHeader zh="Disclaimer" />
                                <CardDisclaimer />
                            </div>
                        ) : null}
                    </div>
                );
            }
        };
        return <>{getReportContent()}</>;
    };
});

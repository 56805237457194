import { makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';

import { setState } from 'rt-state';
import { API, storage, TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { getItemFromArray } from '../../../commons/utils';
import { MAP_APIS_ENDPOINTS } from '../../../services/EndPoints';
import { getMobileSearchedHistory, getSearchedHistory } from '../../PriceMap/support/common';

/***
 *   <Select menuPortalTarget={document.body} menuPosition={'fixed'} options={options}></Select>;
 */

const useStyles = makeStyles({
    searchIcon: {
        width: 30,
        height: 30,
        background: '#009e90',
        borderRadius: '5px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export const AutoAddressComplete = (props: any) => {
    const { queryType, itemChoosedEvt, init_choosedItem, focusEvt, blurEvt } = props;
    console.log('query TYPE IS ' + (queryType == '3'));
    const [inputValue, setInputValue] = useState('');
    const [choosedItem, setChoosedItem] = useState(init_choosedItem);

    const [searchedProperties, setSearchedProperties] = useState([]);
    const [searchedStreets, setSearchedStreets] = useState([]);
    const [allSuburbs, setAllSuburbs] = useState([]);

    const KEY_HISTORY_PROPERTY_ADDRESS = 'KEY_HISTORY_PROPERTY_ADDRESS';
    const KEY_HISTORY_STREET_ADDRESS = 'KEY_HISTORY_STREET_ADDRESS';

    EventBus.on(EVENT_NAMES.EVENT_MOBILE_SHOW_FUNC, () => {
        document.getElementById('addressInput').focus();
    });

    useEffect(() => {
        getMobileSearchedHistory(KEY_HISTORY_PROPERTY_ADDRESS).then((histories: any) => {
            console.log(histories);
            setSearchedProperties(histories);
        });

        getMobileSearchedHistory(KEY_HISTORY_STREET_ADDRESS).then((histories: any) => {
            setSearchedStreets(histories);
        });

        API.get(null, 'static/data/suburbList.json').then((suburbs: any) => {
            const suburbList = [];
            suburbs.forEach((suburb: any) => {
                suburbList.push({
                    label: suburb.locality_name,
                    value: suburb.id,
                });
            });

            setAllSuburbs(suburbList);
        });
    }, []);

    const loadList = async (inputValue: string) => {
        const selectList = [];

        if (queryType == '1') {
            const returnList: any = await API.get(
                null,
                MAP_APIS_ENDPOINTS.SEARCH_ADDRESS_BY_KEY + '?address_strings=' + inputValue,
                '',
                {},
            );
            returnList.forEach((history: any) => {
                selectList.push({
                    label: history.address,
                    value: history.propId,
                });
            });
        } else if (queryType == '2') {
            const returnList: any = await API.get(
                null,
                MAP_APIS_ENDPOINTS.SEARCH_STREET_BY_KEY + '?street_strings=' + inputValue,
                '',
                {},
            );
            returnList.forEach((history: any) => {
                selectList.push({
                    label: history.streetName,
                    value: history.streetId,
                });
            });
        } else {
            const allSuburbs: any = await API.get(null, 'static/data/suburbList.json');

            allSuburbs
                .filter((suburb: any) => {
                    return (
                        suburb.locality_name &&
                        suburb.locality_name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                    );
                })
                .forEach((suburb: any) => {
                    selectList.push({
                        label: suburb.locality_name,
                        value: suburb.id,
                    });
                });
        }

        return selectList;
    };

    const handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/^\s*|\s*$/g, '');
        setInputValue(inputValue);
        return inputValue;
    };
    const chooseItemEvt = async (choosed: any) => {
        setChoosedItem(choosed);
        itemChoosedEvt(choosed.value, choosed);
        let currentPropertySearchedHistoryList = [];
        if (queryType != 3) {
            try {
                const cachedAddress: any = await storage.get(
                    queryType == '1' ? KEY_HISTORY_PROPERTY_ADDRESS : KEY_HISTORY_STREET_ADDRESS,
                );
                currentPropertySearchedHistoryList = cachedAddress;
            } catch (e) {}
            console.log(currentPropertySearchedHistoryList);
            const inHistory = getItemFromArray(currentPropertySearchedHistoryList, 'value', choosed.value);

            if (!inHistory) {
                if (!currentPropertySearchedHistoryList) {
                    currentPropertySearchedHistoryList = [];
                }
                if (currentPropertySearchedHistoryList.length >= 4) {
                    currentPropertySearchedHistoryList = currentPropertySearchedHistoryList.slice(0, 3);
                }
                currentPropertySearchedHistoryList.push(choosed);

                storage.save(
                    queryType == '1' ? KEY_HISTORY_PROPERTY_ADDRESS : KEY_HISTORY_STREET_ADDRESS,
                    currentPropertySearchedHistoryList,
                );
            }
        }
    };

    const holderKey = queryType == '1' ? 'addressHolder' : queryType == '2' ? 'streetHolder' : 'suburbHolder';
    const classes = useStyles();
    return (
        <div style={{ position: 'relative' }}>
            <AsyncSelect
                styles={{ menu: (styles) => Object.assign(styles, { zIndex: 1000 }) }}
                inputId="addressInput"
                menuPlacement={'auto'}
                onFocus={focusEvt}
                onBlur={blurEvt}
                cacheOptions
                menuShouldScrollIntoView
                menuPosition={'absolute'}
                placeholder={<TR name={holderKey}></TR>}
                loadOptions={loadList}
                defaultOptions={queryType == '1' ? searchedProperties : queryType == '2' ? searchedStreets : allSuburbs}
                value={choosedItem}
                onChange={chooseItemEvt}
                onInputChange={handleInputChange}
            />

            <div style={{ position: 'absolute', right: 5, top: 4 }} className={classes.searchIcon}>
                <img src="assets/mobileIcons/searchIcon.svg" />
            </div>
        </div>
    );
};

import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { API } from '../../commons';
import { env } from '../../environments';
import { SecurityProvider } from '../../commons/security/provider';
import { storage } from '../../commons/storage';

export interface CheckoutFormProps {
    paymentStatus: any;
    products?: any;
    plan?: string;
}

enum IconStyleType {
    solid = 'solid',
    default = 'default',
}

export default function CheckoutForm(CheckoutFormProps: any) {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [token, setToken] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [email, setEmail] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    useEffect(() => {
        storage.get('token').then((data: any) => {
            setToken(data);
            console.log(data);
        });

        // Create PaymentIntent as soon as the page loads
        CheckoutFormProps.paymentStatus('Waiting input');
        if (CheckoutFormProps.plan) {
            API.post(null, env.apiHost + '/payment/subscription', JSON.stringify({ plan: CheckoutFormProps.plan }), {
                'Content-Type': 'application/json',
                Authorization:
                    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyOTU0NzMxNTk2MjMxNTE2MTYiLCJyb2xlcyI6eyIyIjoxNjQ5MTExMDkwODU3fSwiaWF0IjoxNjQ4NjM2NjczLCJleHAiOjE2NTM4MjA2NzN9.q40jsoLzJQVbkNH0KzpSf0l1ogav6l0MCW8Dwj8YiXI',
            }).then((data: any) => {
                setClientSecret(data.clientSecret);
            });
        } else {
            API.post(null, env.apiHost + '/payment/create-payment-intent', JSON.stringify(CheckoutFormProps.products), {
                'Content-Type': 'application/json',
                Authorization: token,
            }).then((data: any) => {
                setClientSecret(data.clientSecret);
            });
        }
    }, []);
    const cardStyle = {
        hidePostalCode: true,
        iconStyle: IconStyleType.solid,
        style: {
            base: {
                color: '#32325d',
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                display: 'flex',
                '::placeholder': {
                    color: '#32325d',
                },
                '-webkit-font-smoothing': 'antialiased',
                'justify-content': 'center',
                width: '550px',
                resultMessage: {
                    'line-height': '32px',
                    'font-size': '16px',
                },
            },
            success: {
                padding: '40px',
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };
    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : '');
    };
    const handleSubmit = async (ev) => {
        alert('submitings');
        ev.preventDefault();
        setProcessing(true);
        //CheckoutFormProps.paymentStatus('Processing');
        console.log('start to send request');
        console.log('client secret is ', clientSecret);
        const payload = await stripe.confirmCardPayment(clientSecret, {
            receipt_email: email,
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });
        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
            CheckoutFormProps.paymentStatus('Failed');
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            CheckoutFormProps.paymentStatus('Succeeded');
        }
    };
    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <CardElement id="payment-card-element" options={cardStyle} onChange={handleChange} />
            <button className="payment-button" disabled={processing || disabled || succeeded} id="submit">
                <span id="button-text">
                    {processing ? <div className="payment-spinner" id="payment-spinner"></div> : 'Pay'}
                </span>
            </button>
            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="payment-card-error" role="alert">
                    {error}
                </div>
            )}
            {/* Show a success message upon completion */}
            <p className={succeeded ? 'payment-result-message' : 'payment-result-message-hidden'}>
                Payment succeeded, see the result in your
                <a href={'/dashboard'}> User Centre.</a> Refresh the page to pay again.
            </p>
        </form>
    );
}

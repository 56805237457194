import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStyles } from './Dashboard.styles';
import { rst } from 'rt-state';
import { routes } from './routes';
import { Workspace } from './Workspace';
import { Header } from './Header';
import { Sidebar } from './SideBar';
import { clsx, XProps, MobileBreakpoint, TR, TRHtml } from '../../commons';

export const Dashboard = rst.create<XProps>((ctx) => {
    const openedV = rst.stateS(true);
    const notificationsOpenV = rst.stateS(false);

    const mediaMatcher = matchMedia(`(max-width: ${MobileBreakpoint}px)`);

    const handleDrawerToggle = () => {
        openedV.value = !openedV.value;
    };

    const handleNotificationToggle = () => {
        notificationsOpenV.value = !notificationsOpenV.value;
    };

    const getRoutes = (
        <Switch>
            {routes.items.map((item, index) =>
                item.type === 'external' ? (
                    <Route exact path={item.path} component={item.component} name={item.name} key={index} />
                ) : item.type === 'submenu' ? (
                    item.children.map((subItem) => (
                        <Route
                            exact
                            path={`${item.path}${subItem.path}`}
                            component={subItem.component}
                            name={subItem.name}
                        />
                    ))
                ) : (
                    <Route exact path={item.path} component={item.component} name={item.name} key={index} />
                ),
            )}
            {/*<Redirect to="/404" />*/}
        </Switch>
    );

    const runOnce = () => {
        if (mediaMatcher.matches) {
            openedV.value = false;
        }
        mediaMatcher.addEventListener('change', (match) => {
            setTimeout(() => {
                if (match.matches) {
                    openedV.value = false;
                } else {
                    openedV.value = true;
                }
            }, 300);
        });

        const unlisten = ctx.props.history.listen(() => {
            if (mediaMatcher.matches) {
                openedV.value = false;
            }
            const elm = document.querySelector('#root > div > main');
            if (elm) {
                elm.scrollTop = 0;
            }
        });

        return () => {
            unlisten();
            mediaMatcher.removeListener((match) => {
                setTimeout(() => {
                    if (match.matches) {
                        openedV.value = false;
                    } else {
                        openedV.value = true;
                    }
                }, 300);
            });
        };
    };

    const releaseCB = runOnce();

    ctx.onDispose(releaseCB);

    return (props) => {
        const classes = useStyles();

        // @ts-ignore
        const sidebar = <Sidebar routes={routes.items} opened={openedV.value} toggleDrawer={handleDrawerToggle} />;
        return (
            <>
                <Header
                    logoAltText="UI"
                    logo={`${process.env.PUBLIC_URL}/static/images/logo.svg`}
                    toggleDrawer={handleDrawerToggle}
                    toogleNotifications={handleNotificationToggle}
                />
                <div className={clsx(classes.panel, 'theme-dark')}>
                    {sidebar}
                    <Workspace opened={openedV.value}>
                        {getRoutes}
                        <TR name={'Test'} />
                        <br />
                        <TRHtml html={"Welcome, <div>User!</div>, here's your <strong>bread</strong>"} />
                    </Workspace>
                </div>
            </>
        );
    };
});

/* eslint-disable prettier/prettier */
import { API, SecurityProvider } from '../../../commons';
import { MAP_APIS_ENDPOINTS } from '../../../services/EndPoints';
import ReactDOM from 'react-dom/server';
import React from 'react';
import {
  getItemFromArray,
  getItemFromArrayByMulKeyPair,
  getLatlngArrayFromEncodedPath,
  isMobileDevice,
  reverseToD,
} from '../../../commons/utils';
import {
  getChineseImmiValue,
  getIndianImmiValue,
  getIslamImmiValue,
  getDistanceGrade,
  getPriceMapLevelByZoom,
  getSecondarySchoolRankByScore,
  getSuburbColor,
  getTranValue,
  getPrivateSchoolMixBoy,
  getPrivateSchoolMixGirl,
  getTenantProportion,
  getHouseApt,
  getFamilyIncome,
  getPublicSecurity,
  getAreaBusiness,
  getWaterscape,
  getTrees,
  getLandSize,
  findSuburbValueIndex,
  getPriceChange3M,
  getPriceChange6M,
} from '../support/common';
import { PriceMapFilter } from '../support/DataModels';
import { getThemeValue } from '../support/PriceMapTheme';

import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { PropertyMarker } from './PropertyMarker';
import { SuburbMapMarker } from './SuburbMapMarker';
import {
  FullPriceGrades,
  FullPriceGradesFor2BedsRent,
  HoverSuburbPolygonStyle,
  mapCatagoryColors,
  MapGlobalVars,
  MapLevel,
  PRICE_MAP_STATUS,
} from '../support/PriceMapConsts';
import { SuburbSummary } from '../Layers/SuburbSummary';
import { ChartTypes } from '../Charts/ChartTypes';
import { getItem } from 'localforage';
import { getSuburbDataInMapView } from '../../../services/suburb';
import { Permission } from '../../../commons/security/authz';
const win: any = window;
const L = win.L;

export var suburbDataList: any = [];

export class SuburbPolygonController {
    map: any;
    suburbPolygons: any = [];
    securityProvider: any;

    priceMapFilter: PriceMapFilter;
    selectedSuburb;
    suburbMarkers: any = {};

    constructor(map, securityProvider) {
      this.securityProvider = securityProvider;
      this.map = map;
      EventBus.on(EVENT_NAMES.EVENT_SUBURB_NAME_CLICK, (evt) => {
        const suburbId = evt.suburbId;

        const clickedSuburbData = getItemFromArray(suburbDataList, 'id', suburbId);
        const clickedPolygonObj = getItemFromArray(this.suburbPolygons, 'suburbId', suburbId).polygon;

        this.clickSuburbPolygon(clickedSuburbData, clickedPolygonObj, 3);
      });

      EventBus.on(EVENT_NAMES.EVENT_CHANGE_SUBURB_COLOR_BY, (colorBy) => {
        MapGlobalVars.currentMapCatagory = colorBy;
        this.changeSuburbColor(colorBy);
        console.log(colorBy);
      });
    }

    toggleSuburbPolygonsFromMap(show) {
      if (this.suburbPolygons) {
        this.suburbPolygons.forEach((loopItem) => {
          // this.map.removeLayer(loopItem.polygon);
          loopItem.polygon.setStyle({
            fillOpacity: show ? 1 : 0,
          });
        });
      }
    }

    changeSuburbColor(colorBy) {
      console.log('start changing color');
      this.suburbPolygons.map((loopSuburb) => {
        const loopSuburbData = getItemFromArray(suburbDataList, 'id', loopSuburb.suburbId);
        console.log(loopSuburbData);
        const PriceChange3M = loopSuburbData.median_house_3m_change;
        const PriceChange6M = loopSuburbData.median_house_6m_change;


        const medianData =
                loopSuburbData.median_data[MapGlobalVars.priceMapFilter.propertyType][
                  MapGlobalVars.priceMapFilter.bedOptions[0]
                ];

        let fillingColor = '';
        let fillOpacity = 1;
        if (colorBy != 'medianPrice') {
          if (colorBy === 'PriceChange3M') {
            fillingColor = mapCatagoryColors[findSuburbValueIndex(PriceChange3M, colorBy)];
            console.log(fillingColor);
          } else if (colorBy === 'PriceChange6M') {
            fillingColor = mapCatagoryColors[findSuburbValueIndex(PriceChange6M, colorBy)];
          } else {
            fillingColor = mapCatagoryColors[findSuburbValueIndex(loopSuburbData[colorBy], colorBy)];
          }

          // console.log(loopSuburbData[colorBy]+"=====>"+fillingColor);
        } else {
          fillingColor = getSuburbColor(MapGlobalVars.priceMapFilter.propertyType, medianData.median);
          if (medianData.numberOfSold < 5) {
            fillOpacity = 0;
          }
        }

        loopSuburb.polygon.setStyle({
          fillColor: fillingColor,
          fillOpacity: fillOpacity,
        });
      });
    }

    drawSuburbLayers(priceMapFilter: any) {
      if (getPriceMapLevelByZoom(this.map.getZoom()) != PRICE_MAP_STATUS.SUBURBLEVEL) {
        return;
      }
      MapGlobalVars.priceMapFilter = priceMapFilter;
      this.priceMapFilter = priceMapFilter;

      suburbDataList.forEach((loopSuburbData: any) => {
        const medianData = loopSuburbData.median_data[priceMapFilter.propertyType][priceMapFilter.bedOptions[0]];

        let suburbMarker;
        //make new specail care marker
        if (!this.suburbMarkers[loopSuburbData.id] && medianData && medianData.median && medianData.median > 0) {
          suburbMarker = new SuburbMapMarker(
            { lat: loopSuburbData.lat, lon: loopSuburbData.lon },
            medianData.median,
          );
          this.suburbMarkers[loopSuburbData.id] = suburbMarker;
        }
        if (this.suburbMarkers[loopSuburbData.id]) {
          this.suburbMarkers[loopSuburbData.id].medianPrice = medianData.median;
        }

        let suburbPolygon: any;
        loopSuburbData.polygon_encoded.forEach((loopBorderData, idx) => {
          const retrievedSuburbFromMemory: any = getItemFromArrayByMulKeyPair(this.suburbPolygons, {
            suburbId: loopSuburbData.id,
            idx: idx,
          });

          const polygonFillColor = getSuburbColor(priceMapFilter.propertyType, medianData.median);

          // check is this suburb has been created and in array , if it is ,
          // then retrieve it and change its color accoding median price

          if (retrievedSuburbFromMemory.length == 0) {
            const polygonPath = getLatlngArrayFromEncodedPath(loopBorderData);

            let fillOpacity = 0;
            if (!medianData || !medianData.median || medianData.median == 0 || medianData.numberOfSold < 5) {
            } else {
              fillOpacity = 1;
            }
            suburbPolygon = L.polygon(polygonPath, {
              fillColor: polygonFillColor,
              //color: suburbColor,
              color: 'white',
              stoke: true,
              weight: 2,
              opacity: 1,
              fillOpacity: fillOpacity,
            }); //.bindPopup(ReactDOM.renderToString(React.createElement(SuburbInfoPopup)));
            suburbPolygon.addTo(this.map);

            suburbPolygon.on('dblclick contextmenu', (evt) => {
              if (getPriceMapLevelByZoom(this.map.getZoom()) != PRICE_MAP_STATUS.SUBURBLEVEL) {
                suburbPolygon.setStyle(HoverSuburbPolygonStyle);
                this.clickSuburbPolygon(loopSuburbData, suburbPolygon, 2);
              }
            });

            suburbPolygon.on('click', (evt) => {
              MapGlobalVars.transShowingSuburbData = loopSuburbData;
              console.log('clicked suburb id is ' + JSON.stringify(loopSuburbData));
              if (getPriceMapLevelByZoom(this.map.getZoom()) != PRICE_MAP_STATUS.SUBURBLEVEL) {
                return false;
              }
              this.clickSuburbPolygon(loopSuburbData, suburbPolygon, 2);
            });

            if (suburbMarker) {
              suburbMarker.marker.on('click', () => {
                this.clickSuburbPolygon(loopSuburbData, suburbPolygon, 1);
              });
            }

            const suburbPolygonObj = { suburbId: loopSuburbData.id, idx: idx, polygon: suburbPolygon };

            this.suburbPolygons.push(suburbPolygonObj);
          } else {
            let fillOpacity = 0;
            if (!medianData || !medianData.median || medianData.median == 0 || medianData.numberOfSold < 5) {
              fillOpacity = 0;
            } else {
              fillOpacity = 1;
            }

            suburbPolygon = retrievedSuburbFromMemory[0].polygon;
            suburbPolygon.setStyle({
              color: 'white',
              opacity: 0.3,
              fillColor: polygonFillColor,
              fillOpacity: fillOpacity,
            });
          }
        });
      });

      if (MapGlobalVars.currentMapCatagory != 'medianPrice') {
        this.changeSuburbColor(MapGlobalVars.currentMapCatagory);
      }
    }

    setSuburbBorder(mapStatus) {
      if (mapStatus == PRICE_MAP_STATUS.SUBURBLEVEL) {
        this.suburbPolygons.forEach((loopSuburbPolygon) => {
          loopSuburbPolygon.polygon.setStyle({
            color: 'white',
          });
        });
      } else {
        this.suburbPolygons.forEach((loopSuburbPolygon) => {
          loopSuburbPolygon.polygon.setStyle({
            color: 'transparent',
          });
        });
      }
    }

    changeSuburbChartType(changeTypeEvt, chartType) {
      if (!MapGlobalVars.clickedSuburbData || !MapGlobalVars.clickedSuburbPolygon) {
        return;
      }
      const medianData =
            MapGlobalVars.clickedSuburbData.median_data[MapGlobalVars.priceMapFilter.propertyType][
              MapGlobalVars.priceMapFilter.bedOptions[0]
            ];

      MapGlobalVars.clickedSuburbPolygon.getPopup().setContent(
        ReactDOM.renderToString(
          React.createElement(SuburbSummary, {
            suburbData: MapGlobalVars.clickedSuburbData,
            medianData: medianData,
            showFor: 'map',

            changeSuburbChartTypeEvt: changeTypeEvt,
            chartType: chartType,
          }),
        ),
      );
    }

    // caller is 1 for suburb price marker ; 2 for suburb polygon ; 3 for suburb name
    clickSuburbPolygon(suburbData, polygonObj, caller) {
      const nonRegisterUser =
            !this.securityProvider.hasPermission(Permission.PROPERTY_BUYER) &&
            !this.securityProvider.hasPermission(Permission.TRIAL);

      const suburbPopup = L.popup({
        maxWidth: 999,
        className: 'suburbPopup',
      }).on('remove', () => {
        polygonObj.unbindPopup();
        MapGlobalVars.clickedSuburbPolygon = null;
        MapGlobalVars.clickedSuburbData = null;

        if (suburbData.id != MapGlobalVars.priceMapFilter.suburbId) {
          polygonObj.setStyle({ color: 'white' });
        }
        if (getPriceMapLevelByZoom(this.map.getZoom()) == PRICE_MAP_STATUS.PROPERTYLEVEL) {
          polygonObj.setStyle({ fillOpacity: 0 });
        }
        if (isMobileDevice()) {
          MapGlobalVars.showingSuburbPop = false;
          console.log('setshowing suburb prop as false');
        }
        console.log(MapGlobalVars);
      });
      polygonObj.bindPopup(suburbPopup);

      try {
        MapGlobalVars.clickedSuburbPolygon = polygonObj;
        MapGlobalVars.clickedSuburbData = suburbData;
        if (isMobileDevice()) {
          MapGlobalVars.showingSuburbPop = true;
        }
        const medianData =
                suburbData.median_data[this.priceMapFilter.propertyType][this.priceMapFilter.bedOptions[0]];

        /** If it is under suburb level, need to show the correct suburb info tab information */
        if (getPriceMapLevelByZoom(this.map.getZoom()) == PRICE_MAP_STATUS.SUBURBLEVEL) {
          EventBus.dispatch(EVENT_NAMES.EVENT_SUBURB_POLYGON_CLICKED, {
            suburbData: suburbData,
            medianData: medianData,
          });
        }

        polygonObj.getPopup().setContent(
          ReactDOM.renderToString(
            React.createElement(SuburbSummary, {
              suburbData: suburbData,
              medianData: medianData,
              showFor: 'map',
              userRegister: !nonRegisterUser,
              changeSuburbChartTypeEvt: this.changeSuburbChartType,
              chartType: ChartTypes.SUBURB_HALF_YEAR_AUCTION_TREND,
            }),
          ),
        );
        polygonObj.openPopup();
        setTimeout(() => {
          document.getElementById('showSuburbAllTrans').addEventListener('click', () => {

            MapGlobalVars.showSuburbTransConfirm = true;
            // clear previous suburb border if have
            this.clearPreviouseSuburbSelection();

            polygonObj.closePopup();

            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, {
              suburbId: suburbData.id,
              suburbName: suburbData.locality_name,
            });

            EventBus.dispatch(EVENT_NAMES.EVENT_SUBURB_POLYGON_CLICKED, {
              suburbData: suburbData,
              medianData: medianData,
            });
            if (caller == 2) {
              polygonObj.setStyle({
                color: getThemeValue('SELECTED_MAP_BORDER_COLOR'),
              });
            }
          });
        }, 200);
      } catch (e) {
        console.log(e);
      }
    }

    clearPreviouseSuburbSelection() {
      const previousSuburbPolygons: any = getItemFromArrayByMulKeyPair(this.suburbPolygons, {
        suburbId: this.priceMapFilter.suburbId,
      });
      console.log(previousSuburbPolygons);
      if (previousSuburbPolygons && previousSuburbPolygons.length > 0) {
        previousSuburbPolygons.forEach((loopItem) => {
          console.log('set border color');
          loopItem.polygon.setStyle({
            color: 'white',
          });
        });
      }
    }

    checkMapviewSuburbData(suburbIdsInMapView, priceMapFilter: PriceMapFilter) {
      if (this.suburbPolygons) {
        this.suburbPolygons.forEach((loopItem) => {
          if (!this.map.hasLayer(loopItem.polygon)) {
            this.map.addLayer(loopItem.polygon);
          }
        });
      }
      return new Promise((resolve, reject) => {
        const requestSuburbIds: any = [];
        if (suburbIdsInMapView && suburbIdsInMapView.length > 0) {
          suburbIdsInMapView.forEach((loopSuburbId) => {
            if (getItemFromArray(suburbDataList, 'id', loopSuburbId) == null) {
              requestSuburbIds.push(loopSuburbId);
            }
          });
        }
        getSuburbDataInMapView(requestSuburbIds).then(
          (result: any) => {
            if (result && result.length > 0) {
              suburbDataList = suburbDataList.concat(result);
            }
            if (getPriceMapLevelByZoom(this.map.getZoom()) == PRICE_MAP_STATUS.SUBURBLEVEL) {
              this.drawSuburbLayers(priceMapFilter);
            }

            resolve(true);
          },
          (err) => {
            reject({
              err: err,
            });
            // todo : error handler
          },
        );
      });
    }

    // task =1 is to fit the suburb into this map
    // task =2 is to draw the selected suburb border

    async switchToSelectedSuburb(suburbId, task, priceMapFilter) {
      if (this.selectedSuburb != suburbId) {
        const previousSelected: any = getItemFromArrayByMulKeyPair(this.suburbPolygons, {
          suburbId: this.selectedSuburb,
        });

        if (previousSelected && previousSelected.length > 0) {
          for (let idx = 0; idx < previousSelected.length; idx++) {
            const loopSelectedSuburb = previousSelected[idx];

            loopSelectedSuburb.polygon.setStyle({
              color: 'transparent',
            });
          }
        }
      }

      const selectedSuburbData: any = getItemFromArray(suburbDataList, 'id', suburbId);
      MapGlobalVars.clickedSuburbData = selectedSuburbData;
      console.log(selectedSuburbData);

      let selectedSuburbPolygons: any = getItemFromArrayByMulKeyPair(this.suburbPolygons, { suburbId: suburbId });

      if (selectedSuburbPolygons.length == 0) {
        await this.checkMapviewSuburbData([suburbId], priceMapFilter);
        selectedSuburbPolygons = getItemFromArrayByMulKeyPair(this.suburbPolygons, { suburbId: suburbId });
      }

      const overAllBounds = [];
      if (selectedSuburbPolygons && selectedSuburbPolygons.length > 0) {
        selectedSuburbPolygons.forEach((loopSuburb) => {
          overAllBounds.push(loopSuburb.polygon._bounds._northEast);
          overAllBounds.push(loopSuburb.polygon._bounds._southWest);
          if (task == 2) {
            loopSuburb.polygon.setStyle({
              color: getThemeValue('SELECTED_MAP_BORDER_COLOR'),
              fillOpacity: 0,
              opacity: 1,
            });
            loopSuburb.polygon.addTo(this.map);
          }
        });
      }

      if (task == 1) {
        // need to tell other component , don't do map level change as it fits the suburb bounary
        MapGlobalVars.shouldStayOnPropertyLevel = true;
        // this.map.setView([selectedSuburbData.lat, selectedSuburbData.lon], MapLevel.propertyLevel.lower);

        this.map.fitBounds(overAllBounds);
      }

      this.selectedSuburb = suburbId;
    }

    togglePriceLabel(priceLabelStatus, speicalCareFilter?) {

      Object.keys(this.suburbMarkers).forEach((suburbId) => {
        const loopMarker: SuburbMapMarker = this.suburbMarkers[suburbId];
        if (!speicalCareFilter) {
          loopMarker.show(priceLabelStatus, this.map);
        } else {
          if (priceLabelStatus == 1) {
            loopMarker.show(priceLabelStatus, this.map);
          }
          if (priceLabelStatus == 2) {
            if (this.suburbInSepcialCare(suburbId, speicalCareFilter)) {
              loopMarker.show(3, this.map);
            } else {
              loopMarker.show(2, this.map);
            }
          }
          if (priceLabelStatus == 3) {
            if (this.suburbInSepcialCare(suburbId, speicalCareFilter)) {
              loopMarker.show(3, this.map);
            } else {
              loopMarker.show(1, this.map);
            }
          }
        }
      });
    }

    suburbInSepcialCare(suburbId, specialFilter) {
      let schoolRateTrue = false;
      let transTrue = false;
      let chineseTrue = false;
      let indianImmiTrue = false;
      let islamImmiTrue = false;
      let secondaryRateTrue = false;
      let distanceToCBDTrue = false;
      let medianPriceTrue = false;
      let FullPriceGradesFor2BedsRentTrue = false;
      let PrivateSchoolMixBoyTrue = false;
      let PrivateSchoolMixGirlTrue = false;
      let TenantProportionTrue = false;
      let HouseAptTrue = false;
      let FamilyIncomeTrue = false;
      let PublicSecurityTrue = false;
      let AreaBusinessTrue = false;
      let WaterscapeTrue = false;
      let TreesTrue = false;
      let LandSizeTrue = false;
      let PriceChange3MTrue = false;
      let PriceChange6MTrue = false;

      const suburbData = getItemFromArray(suburbDataList, 'id', suburbId);
      console.log(suburbData);
      const PriceChange3M = suburbData.median_house_3m_change;
      const PriceChange6M = suburbData.median_house_6m_change;

      if (
        (specialFilter.schoolRanks.length > 0 &&
                specialFilter.schoolRanks.toString().indexOf(suburbData.rate_primary) > -1) ||
            specialFilter.schoolRanks.length == 0
      ) {
        schoolRateTrue = true;
      }

      if (
        (specialFilter.secondarySchoolRanks.length > 0 &&
                specialFilter.secondarySchoolRanks
                  .toString()
                  .indexOf(getSecondarySchoolRankByScore(suburbData.score_secondary)) > -1) ||
            specialFilter.secondarySchoolRanks.length == 0
      ) {
        secondaryRateTrue = true;
      }

      if (
        (specialFilter.trans.length > 0 &&
                specialFilter.trans.toString().indexOf(getTranValue(suburbData.train_station)) > -1) ||
            specialFilter.trans.length == 0
      ) {
        transTrue = true;
      }

      if (
        (specialFilter.distanceOptions.length > 0 &&
                specialFilter.distanceOptions.toString().indexOf(getDistanceGrade(suburbData.cbd_distance)) > -1) ||
            specialFilter.distanceOptions.length == 0
      ) {
        distanceToCBDTrue = true;
      }

      if (
        (specialFilter.chineseImmi.length > 0 &&
                specialFilter.chineseImmi.toString().indexOf(getChineseImmiValue(suburbData.chinese)) > -1) ||
            specialFilter.chineseImmi.length == 0
      ) {
        chineseTrue = true;
      }

      if (
        (specialFilter.indianImmi.length > 0 &&
                specialFilter.indianImmi.toString().indexOf(getIndianImmiValue(suburbData.hinduism_p_pct)) > -1) ||
            specialFilter.indianImmi.length == 0
      ) {
        indianImmiTrue = true;
      }

      if (
        (specialFilter.islamImmi.length > 0 &&
                specialFilter.islamImmi.toString().indexOf(getIslamImmiValue(suburbData.islam_p_pct)) > -1) ||
            specialFilter.islamImmi.length == 0
      ) {
        islamImmiTrue = true;
      }

      if (specialFilter.medianPriceRange.lower == 0 || specialFilter.medianPriceRange.upper == 0) {
        medianPriceTrue = true;
      }

      if (
        specialFilter.medianPriceRange.lower == FullPriceGrades[0] &&
            specialFilter.medianPriceRange.upper == FullPriceGrades[FullPriceGrades.length - 1]
      ) {
        medianPriceTrue = true;
      }

      if (
        specialFilter.medianPriceRange.lower > FullPriceGrades[0] ||
            specialFilter.medianPriceRange.upper < FullPriceGrades[FullPriceGrades.length - 1]
      ) {
        const medianData =
                suburbData.median_data[MapGlobalVars.priceMapFilter.propertyType][
                  MapGlobalVars.priceMapFilter.bedOptions[0]
                ];

        if (
          medianData.median > reverseToD(specialFilter.medianPriceRange.lower) &&
                medianData.median < reverseToD(specialFilter.medianPriceRange.upper)
        ) {
          medianPriceTrue = true;
          console.log('median price is in ');
        }
      }
      if (specialFilter.RentPriceRange.lower == 0 || specialFilter.RentPriceRange.upper == 0) {
        FullPriceGradesFor2BedsRentTrue = true;
      }

      if (
        specialFilter.RentPriceRange.lower == FullPriceGradesFor2BedsRent[0] &&
            specialFilter.RentPriceRange.upper == FullPriceGradesFor2BedsRent[FullPriceGradesFor2BedsRent.length - 1]
      ) {
        FullPriceGradesFor2BedsRentTrue = true;
      }

      if (
        specialFilter.RentPriceRange.lower > FullPriceGradesFor2BedsRent[0] ||
            specialFilter.RentPriceRange.upper < FullPriceGradesFor2BedsRent[FullPriceGradesFor2BedsRent.length - 1]
      ) {
        const rentData = suburbData.half_year_2br_apt_rent;
        if (rentData > specialFilter.RentPriceRange.lower && rentData < specialFilter.RentPriceRange.upper) {
          FullPriceGradesFor2BedsRentTrue = true;
        }
      }

      if (
        (specialFilter.PrivateSchoolMixBoy.length > 0 &&
                specialFilter.PrivateSchoolMixBoy.toString().indexOf(
                  getPrivateSchoolMixBoy(suburbData.rate_private_boys),
                ) > -1) ||
            specialFilter.PrivateSchoolMixBoy.length == 0
      ) {
        PrivateSchoolMixBoyTrue = true;
      }
      if (
        (specialFilter.PrivateSchoolMixGirl.length > 0 &&
                specialFilter.PrivateSchoolMixGirl.toString().indexOf(
                  getPrivateSchoolMixGirl(suburbData.rate_private_girls),
                ) > -1) ||
            specialFilter.PrivateSchoolMixGirl.length == 0
      ) {
        PrivateSchoolMixGirlTrue = true;
      }
      if (
        (specialFilter.TenantProportion.length > 0 &&
                specialFilter.TenantProportion.toString().indexOf(getTenantProportion(suburbData.rental_pct)) > -1) ||
            specialFilter.TenantProportion.length == 0
      ) {
        TenantProportionTrue = true;
      }
      if (
        (specialFilter.HouseApt.length > 0 &&
                specialFilter.HouseApt.toString().indexOf(getHouseApt(suburbData.house_pct)) > -1) ||
            specialFilter.HouseApt.length == 0
      ) {
        HouseAptTrue = true;
      }
      if (
        (specialFilter.FamilyIncome.length > 0 &&
                specialFilter.FamilyIncome.toString().indexOf(getFamilyIncome(suburbData.family_income)) > -1) ||
            specialFilter.FamilyIncome.length == 0
      ) {
        FamilyIncomeTrue = true;
      }
      if (
        (specialFilter.PublicSecurity.length > 0 &&
                specialFilter.PublicSecurity.toString().indexOf(getPublicSecurity(suburbData.rate_public_security)) >
                -1) ||
            specialFilter.PublicSecurity.length == 0
      ) {
        PublicSecurityTrue = true;
      }
      if (
        (specialFilter.AreaBusiness.length > 0 &&
                specialFilter.AreaBusiness.toString().indexOf(getAreaBusiness(suburbData.rate_shopping)) > -1) ||
            specialFilter.AreaBusiness.length == 0
      ) {
        AreaBusinessTrue = true;
      }
      if (
        (specialFilter.Waterscape.length > 0 &&
                specialFilter.Waterscape.toString().indexOf(getWaterscape(suburbData.rate_waterfront)) > -1) ||
            specialFilter.Waterscape.length == 0
      ) {
        WaterscapeTrue = true;
      }
      if (
        (specialFilter.Trees.length > 0 &&
                specialFilter.Trees.toString().indexOf(getTrees(suburbData.rate_tree_cover)) > -1) ||
            specialFilter.Trees.length == 0
      ) {
        TreesTrue = true;
      }
      if (
        (specialFilter.LandSize.length > 0 &&
                specialFilter.LandSize.toString().indexOf(getLandSize(suburbData.median_lz)) > -1) ||
            specialFilter.LandSize.length == 0
      ) {
        LandSizeTrue = true;
      }

      if (
        (specialFilter.PriceChange3M.length > 0 &&
                specialFilter.PriceChange3M.toString().indexOf(getPriceChange3M(PriceChange3M)) > -1) ||
            specialFilter.PriceChange3M.length == 0
      ) {
        PriceChange3MTrue = true;
      }

      if (
        (specialFilter.PriceChange6M.length > 0 &&
                specialFilter.PriceChange6M.toString().indexOf(getPriceChange6M(PriceChange6M)) > -1) ||
            specialFilter.PriceChange6M.length == 0
      ) {
        PriceChange6MTrue = true;
      }

      return (
        schoolRateTrue &&
            transTrue &&
            chineseTrue &&
            islamImmiTrue &&
            indianImmiTrue &&
            secondaryRateTrue &&
            distanceToCBDTrue &&
            medianPriceTrue &&
            FullPriceGradesFor2BedsRentTrue &&
            PrivateSchoolMixBoyTrue &&
            PrivateSchoolMixGirlTrue &&
            TenantProportionTrue &&
            HouseAptTrue &&
            FamilyIncomeTrue &&
            PublicSecurityTrue &&
            AreaBusinessTrue &&
            WaterscapeTrue &&
            TreesTrue &&
            LandSizeTrue &&
            PriceChange3MTrue &&
            PriceChange6MTrue
      );
    }
}

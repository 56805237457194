import { Button, Link, ListItem, makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { RRLink, TR, TRHtml } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { XAuthDialog } from '../../../components/Dialog/XAuthDialog';
import { ROUTES } from '../../../routes';
import { ContactUsPage } from '../../ContactUs';
import { WeChatGroup } from '../../Home/components/WeChatGroup';
import { NewButton } from './NewButton';
import * as locales from '@material-ui/core/locale';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '1200px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '29px 0px 28px 0px',
        '& h1': {
            fontSize: '20px',
            color: '#FFFFFF',
            padding: '0px 0px 0px 0px',
            margin: '0px 0px 0px 0px',
        },
        '& h2': {
            fontSize: '16px',
            color: '#FFFFFF',
            padding: '0px 0px 0px 0px',
            margin: '0px 0px 0px 0px',
            position: 'relative',
            left: '5px',
        },
        '& span': {
            fontSize: '14px',
            color: '#FFFFFF',
            padding: '0px 0px 0px 0px',
            margin: '0px 0px 0px 0px',
        },
    },
    homeOn: {
        margin: '0px',
        padding: '0px',
    },
    aboutFocus: {
        margin: '0px',
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    about: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '20px',
    },
    aboutContent: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    contact: {
        margin: '0px',
        padding: '0px',
    },
}));
export const NewHomeFooter = rst.create((ctx) => {
    const handleOpenLive = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://www.youtube.com/channel/UCPbp_YpynVEVpJrr_UzMqYg';
    };
    const handleOpenXiaohongshu = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://www.xiaohongshu.com/user/profile/5f84ba2900000000010034c8';
    };
    const handleOpenTwitter = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://twitter.com/SydneyHomeOn';
    };
    const handleOpenFacebook = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://www.facebook.com/homeon.com.au';
    };
    const isOpen = rst.stateS<'shop' | 'wechat' | 'live' | 'Feedback'>(null);
    function handleForumClose(choice: 'shop' | 'wechat' | 'live' | 'Feedback') {
        switch (choice) {
            case 'shop':
            case 'wechat':
                isOpen.value = choice;
                break;
            case 'Feedback':
                isOpen.value = choice;
                break;
            case 'live':
        }
    }
    function onDialogClose() {
        isOpen.value = null;
    }
    const appProvider = AppProvider.use();

    return (props) => {
        const classes = useStyles();

        return (
            <div style={{ background: '#012E2A', height: '198px' }}>
                <div className={classes.root}>
                    <div className={classes.homeOn}>
                        {/* <h1>HomeOn</h1> */}
                        <img
                            src={`${process.env.PUBLIC_URL}/static/images/footerLogo.png`}
                            alt="logo"
                            style={{ width: '110px' }}
                        />
                    </div>
                    <div className={classes.aboutFocus}>
                        <div className={classes.about}>
                            {/* <h2>
                                <TR name={'NewHome_Footer_AboutUs'} />
                            </h2> */}
                            <div className={classes.aboutContent}>
                                {/* <Button style={{ marginRight: '50px' }}>
                                    <Link
                                        target="_blank"
                                        component={RRLink}
                                        to={ROUTES.AboutUsPage.path}
                                        color="initial"
                                        style={{ textDecoration: 'none' }}>
                                        <span>
                                            <TR name={'NewHome_Footer_compPro'} />
                                        </span>
                                    </Link>
                                </Button> */}
                                <Button style={{ marginRight: '50px' }}>
                                    <Link
                                        target="_blank"
                                        component={RRLink}
                                        to={ROUTES.TermOfUsePage.path}
                                        color="initial"
                                        style={{ textDecoration: 'none' }}>
                                        <span>
                                            <TR name={'NewHome_Footer_use'} />
                                        </span>
                                    </Link>
                                </Button>
                                <Button style={{ marginRight: '50px' }}>
                                    <Link
                                        target="_blank"
                                        component={RRLink}
                                        to={ROUTES.PrivacyPolicyPage.path}
                                        color="initial"
                                        style={{ textDecoration: 'none' }}>
                                        <span>
                                            <TR name={'NewHome_Footer_priv'} />
                                        </span>
                                    </Link>
                                </Button>
                                <span style={{ width: '80px' }}>
                                    <a href="mailto:support@homeon.com.au">
                                        <TR name={'NewHome_Footer_Contact'} />
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className={classes.about}>
                            <h2>
                                <TR name={'NewHome_Footer_Focus'} />
                            </h2>
                            <div className={classes.aboutContent}>
                                <Button style={{ marginRight: '50px' }} onClick={handleOpenTwitter}>
                                    <img src={`${process.env.PUBLIC_URL}/static/images/Twitter.svg`} alt="Twitter" />
                                    <span style={{ marginLeft: '5px' }}>
                                        <TR name={'Twitter'} />
                                    </span>
                                </Button>
                                <Button style={{ marginRight: '50px' }} onClick={handleOpenFacebook}>
                                    <img src={`${process.env.PUBLIC_URL}/static/images/facebook.svg`} alt="facebook" />
                                    <span style={{ marginLeft: '5px' }}>
                                        <TR name={'Facebook'} />
                                    </span>
                                </Button>
                                {appProvider.getLocale() === locales.zhCN ? (
                                    <Button style={{ marginRight: '50px' }} onClick={() => handleForumClose('wechat')}>
                                        <img src={`${process.env.PUBLIC_URL}/static/images/wechat.svg`} alt="Wechat" />
                                        <span style={{ marginLeft: '5px' }}>
                                            <TR name={'Wechat'} />
                                        </span>
                                    </Button>
                                ) : null}
                                <Button style={{ marginRight: '50px' }} onClick={handleOpenLive}>
                                    <img src={`${process.env.PUBLIC_URL}/static/images/youtube.svg`} alt="youTube" />
                                    <span style={{ marginLeft: '5px' }}>
                                        <TR name={'YouTube'} />
                                    </span>
                                </Button>
                                {/* {appProvider.getLocale() === locales.zhCN ? (
                                    <Button style={{ marginRight: '50px' }} onClick={handleOpenXiaohongshu}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/static/images/xiaohongshu.png`}
                                            alt="Xiaohongshu"
                                            style={{ width: 20, height: 20 }}
                                        />
                                        <span style={{ marginLeft: '5px' }}>
                                            <TR name={'Xiaohongshu'} />
                                        </span>
                                    </Button>
                                ) : null} */}
                            </div>
                        </div>
                        <div>
                            <p style={{ color: '#FFFFFF' }}>
                                <TRHtml html={'copyright'} />
                            </p>
                        </div>
                    </div>

                    <div className={classes.contact}>
                        <div className={classes.about}>
                            {/* <h2>
                                <TR name={'NewHome_Footer_Contact'} />
                            </h2>
                            <div className={classes.aboutContent}>
                                <ListItem button dense={true} style={{ marginRight: '50px', padding: '5px' }}>
                                    <span style={{ width: '80px' }}>
                                        <a href="mailto:support@homeon.com.au">
                                            <TR name={'NewHome_Footer_mail'} />
                                        </a>
                                    </span>
                                </ListItem>
                                <ListItem button dense={true}>
                                    <span onClick={() => handleForumClose('Feedback')} style={{ maxWidth: '23vw' }}>
                                        <TR name={'NewHome_Footer_feedb'} />
                                    </span>
                                </ListItem>
                            </div> */}
                            {/* {rst.view(() => {
                                return (
                                    <XAuthDialog open={isOpen.value === 'Feedback'} onClose={onDialogClose}>
                                        <ContactUsPage />
                                    </XAuthDialog>
                                );
                            })} */}
                            {rst.view(() => {
                                return (
                                    <XAuthDialog open={isOpen.value === 'wechat'} onClose={onDialogClose}>
                                        <WeChatGroup />
                                    </XAuthDialog>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
});

import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { AppProvider } from '../../../commons/appProvider';
import { TR } from '../../../commons/i18n/TR';
import { isMobileDevice } from '../../../commons/utils';
import { HelpComponent } from '../../PriceMap/components/HelpComponent';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import * as locales from '@material-ui/core/locale';
import { Glossary } from '../components/Glossary';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px',
        },
        legendGroup: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        legend: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 7,
        },
        legendItem: {
            width: '10px',
            height: '10px',
            borderRadius: '5px',
        },
        part2: {
            background: 'rgb(250 252 255)',
            border: '1px solid rgb(236 239 246)',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px 0px',
            '& h2': {
                fontSize: '14px',
                margin: '0px 0px 8px 0px',
                color: '#B43B3B',
                background: 'linear-gradient(to bottom, rgb(250 252 255) 70%, rgb(249 235 237) 0%)',
                width: 'fit-content',
            },
            '& span': {
                fontSize: '13px',
                color: '#848383',
            },
        },
    };
});
declare var Highcharts;
const chartColor = {
    sydney: 'rgb(15 134 243)',
    suburb: 'rgb(255 152 47)',
    street: 'rgb(32 204 104)',
};

export const Card12 = rst.create<{ property: any; mobile?: boolean }>((ctx) => {
    const appProvider = AppProvider.use();
    console.log((ctx.props.property.property.locality.houseTurnOverRate * 100).toFixed(2));
    const sampleData = {
        sydney: ctx.props.property.property.locality.city?.houseTurnOverRate * 100,
        suburb: ctx.props.property.property.locality.houseTurnOverRate * 100,
        street: ctx.props.property.property.street.turnoverRate * 100,
    };
    console.log(ctx.props.property.property.locality.houseTurnOverRate);
    function loadChart() {
        Highcharts.chart('card12', {
            chart: {
                height: 180,
                type: 'column',
                marginBottom: isMobileDevice() ? 30 : 35,
            },
            title: {
                text: '',
            },

            subtitle: {
                text: '',
            },
            // tooltip: {
            //     formatter: function () {
            //         return this.series.name + this.point.x;
            //     }
            // },
            yAxis: {
                title: {
                    text: '',
                },
                labels: {
                    enabled: false,
                    // formatter: function () {
                    //     return `${this.value * 100}%`;
                    // },
                },
                // min: 0,
            },
            xAxis: {
                type: 'category',
                categories: [''],
                crosshair: true,
                labels: {
                    enabled: false,
                },

                tickWidth: 0, //设置刻度标签宽度
                gridLineWidth: 0,
            },
            legend: {
                enabled: false,
            },
            borderRadiusTopLeft: 10,
            plotOptions: {
                column: {
                    borderWidth: 0,
                    borderRadius: 10,
                    pointPadding: 0.25,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}%',
                        // formatter: function () {
                        //     return Highcharts.numberFormat(this.percentage, 2) + '%';
                        // },
                    },
                },
                // series: {
                //     groupPadding: 0.3,
                // },
            },
            credits: { enabled: false },
            tooltip: {
                enabled: false,
                // pointFormat: `{point.y}%`,
            },
            series: [
                {
                    name: 'sydney',
                    data: [parseFloat(sampleData.sydney.toFixed(2))],
                    color: 'rgb(15 134 243)',
                },
                {
                    name: 'suburb',
                    data: [parseFloat(sampleData.suburb.toFixed(2))],
                    color: 'rgb(255 152 47)',
                },
                {
                    name: 'street',
                    data: [parseFloat(sampleData.street.toFixed(2))],
                    color: 'rgb(32 204 104)',
                },
            ],
        });
    }

    return (props) => {
        const classes = useStyles();
        setTimeout(() => {
            loadChart();
        }, 400);
        const compareTurnover = () => {
            if (props.property.property.street.turnoverRate - props.property.property.locality.houseTurnOverRate >= 0) {
                return <TR name={'CARD12_TIPS_HIGHER'} />;
            } else {
                return <TR name={'CARD12_TIPS_LOWER'} />;
            }
        };
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader mobile={props.mobile} header={props.mobile ? 'Card12_header_mobile' : 'Card12_header'}>
                    <div className={classes.part1}>
                        <div style={{ fontSize: '14px', display: 'flex' }}>
                            <span>
                                <TR name={'Card12_turnOver'} />
                            </span>
                            {/* <HelpComponent content="HELP_TURNOVER_Rate" maxWidth="xs" /> */}
                        </div>
                        <div className={classes.legendGroup}>
                            <div className={classes.legend}>
                                <div className={classes.legendItem} style={{ background: chartColor.sydney }}></div>
                                <div style={{ marginLeft: 3 }}>
                                    <span>Sydney</span>
                                </div>
                            </div>
                            <div className={classes.legend}>
                                <div className={classes.legendItem} style={{ background: chartColor.suburb }}></div>
                                <div style={{ marginLeft: 3 }}>
                                    <span>Suburb</span>
                                </div>
                            </div>
                            <div className={classes.legend}>
                                <div className={classes.legendItem} style={{ background: chartColor.street }}></div>
                                <div style={{ marginLeft: 3 }}>
                                    <span>Street</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="card12"></div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Glossary glossaryId="19" />
                    </div>
                    <div className={classes.part2}>
                        <h2>
                            <TR name="Card_Description" />
                        </h2>
                        <p>
                            <span>
                                <TR name="CARD12_TIPS1" />
                                <span style={{ color: 'rgba(193 32 26)' }}>
                                    {(props.property.property.street.turnoverRate * 100).toFixed(2) + '%'}
                                </span>
                                <TR name="CARD12_TIPS2" />
                                {appProvider.getLocale() === locales.zhCN ? (
                                    <>
                                        <span style={{ color: 'rgba(193 32 26)' }}>{compareTurnover()}</span>
                                        <span style={{ color: 'rgba(193 32 26)' }}>
                                            {(
                                                Math.abs(
                                                    props.property.property.street.turnoverRate -
                                                        props.property.property.locality.houseTurnOverRate,
                                                ) * 100
                                            ).toFixed(2) + '%'}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span style={{ color: 'rgba(193 32 26)' }}>
                                            {(
                                                Math.abs(
                                                    props.property.property.street.turnoverRate -
                                                        props.property.property.locality.houseTurnOverRate,
                                                ) * 500
                                            ).toFixed(2) + '%'}
                                        </span>
                                        <span> </span>
                                        <span style={{ color: 'rgba(193 32 26)' }}>{compareTurnover()}</span>
                                    </>
                                )}
                                <TR name="CARD12_TIPS3" />
                            </span>
                        </p>
                        <p>
                            <span>
                                <TR name="CARD12_TIPS4" />
                            </span>
                        </p>
                    </div>
                </CardWithHeader>
            </div>
        );
    };
});

import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { TR } from '../../../../commons';
import { InfoValue } from '../standard/infoValue';
import { InfoLabel } from '../standard/label';
const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: '13px',
        fontWeight: 400,
        color: '#888888',
    },
});
export const Station = (props: any) => {
    const { station } = props;
    console.log(station);
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <TR name="distance"></TR>
            <InfoValue>
                {station.name} : {station.distance.toFixed(2)}km
            </InfoValue>
        </div>
    );
};

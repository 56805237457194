import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { clsx } from '../../../commons';
import { isMobileDevice } from '../../../commons/utils';
const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        mobile: {
            fontSize: '10px',
            height: '24px',
            width: '36px',
            padding: '2px 5px',
        },
        pc: {
            fontSize: '16px',
            width: '50px',
            height: '30px',
        },
        report: {
            fontSize: '8px',
            height: '12px',
            width: '16px',
            padding: '1px 3px',
        },
        eclipse: {
            fontFamily: 'Noto Sans SC',
            paddingLef: '3px',
            paddingRight: '1px',
            borderRadius: '50%',
            backgroundColor: 'white',

            color: 'white',
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        pc_arrow: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',

            borderTop: '10px solid red',
            marginTop: '-1px',
        },
        mobile_arrow: {
            width: 0,
            height: 0,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',

            borderTop: '6px solid #fff',
            marginTop: '-1px',
        },
        similarTransMarker: {
            width: 24,
            height: 28,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: 'url("assets/icons/businessIcon.svg")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 24,
            opacity: 0.7,
            fontSize: 10,
        },

        businessParkMarker: {
            width: 24,
            height: 28,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: 'url("assets/icons/businessIcon.svg")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 24,
            opacity: 1,
            fontSize: 10,
        },
    };
});

export const PriceLabel = rst.create<{ color: string; labelColor?: string; report?: boolean }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                <div
                    style={{ background: props.color, color: props.labelColor || '' }}
                    className={clsx(
                        classes.eclipse,
                        props.report ? classes.report : isMobileDevice() ? classes.mobile : classes.pc,
                    )}>
                    {props.children}
                </div>
                <div
                    style={{ borderTopColor: props.color }}
                    className={isMobileDevice() ? classes.mobile_arrow : classes.pc_arrow}></div>
            </div>
        );
    };
});

export const SimilarTransMarker = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        return <div className={classes.similarTransMarker}>{props.children}</div>;
    };
});

export const BusinessParkMarker = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        return <div className={classes.businessParkMarker}>{props.children}</div>;
    };
});

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { CardWithHeader } from '../components/CardWithHeader';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
            paddingBottom: '0px',
        },
    };
});

export const CardDisclaimer = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader>
                    <p>
                        <TR name="Card_Disclaimer" />
                    </p>
                </CardWithHeader>
            </div>
        );
    };
});

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tab,
    TabProps,
    Tabs,
    Theme,
} from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR, TRHtml } from '../../commons';
import { EventBus, EVENT_NAMES } from '../../commons/EventBus';

const useStyles = makeStyles((theme) => {
    return {};
});
export const Tip = rst.create((ctx) => {
    const tipState = rst.state({
        open: false,
        cancelEvt: null, //optional
        confirmEvt: null, //optional
        title: '',
        content: '',
        contentParams: null,
        cancelButtonText: 'NO', // optional
        confirmButtonText: 'YES', //optional
        thirdButton: null, // optional
        thirdButtonEvt: null, // optional
    });
    EventBus.on(EVENT_NAMES.EVENT_SWITCH_DIALOG, (evt: any) => {
        tipState.open = evt.open;
        tipState.cancelEvt = evt.cancelEvt;
        tipState.confirmEvt = evt.confirmEvt;
        tipState.title = evt.title;
        tipState.content = evt.content;
        tipState.contentParams = evt.contentParams;

        if (evt.confirmButtonText) {
            tipState.confirmButtonText = evt.confirmButtonText;
        }
        if (evt.cancelButtonText) {
            tipState.cancelButtonText = evt.cancelButtonText;
        }
        if (evt.thirdButton) {
            tipState.thirdButton = evt.thirdButton;
            tipState.thirdButtonEvt = evt.thirdButtonEvt;
        }
    });

    const handleCancel = () => {
        tipState.open = false;
        if (tipState.cancelEvt) {
            tipState.cancelEvt();
        }
    };

    const handleConfirm = () => {
        tipState.open = false;
        if (tipState.confirmEvt) {
            tipState.confirmEvt();
        }
    };

    return (props) => {
        return (
            <Dialog
                open={tipState.open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <TR name={tipState.title}></TR>
                    <div
                        onClick={() => {
                            tipState.open = false;
                        }}
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            width: 28,
                            height: 28,
                            borderRadius: '50%',
                            border: '1px solid #333333',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}>
                        X
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TRHtml html={tipState.content} params={tipState.contentParams}></TRHtml>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {tipState.thirdButtonEvt ? (
                        <Button onClick={tipState.thirdButtonEvt} color="primary" autoFocus>
                            <TR name={tipState.thirdButton}></TR>
                        </Button>
                    ) : null}
                    {tipState.confirmEvt ? (
                        <Button onClick={handleConfirm} color="primary" autoFocus>
                            <TR name={tipState.confirmButtonText}></TR>
                        </Button>
                    ) : null}
                    {/* {!tipState.confirmEvt ? null : (
                        <Button onClick={handleConfirm} color="primary" autoFocus>
                            <a href="mailto:support@homeon.com.au">
                                <TR name={'EmailToUs'} />
                            </a>
                        </Button>
                    )} */}
                    <Button onClick={handleCancel} color="primary">
                        <TR name={tipState.cancelButtonText}></TR>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
});

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TRHtml } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { decimalToPct, isMobileDevice, numToMillion } from '../../../../commons/utils';
import { BBCLimited } from '../../PropertyCalculator/CalculatorConst';

import { MapGlobalVars } from '../../support/PriceMapConsts';
import { getThemeValue } from '../../support/PriceMapTheme';

export const PropBeds = rst.create<{
    beds?: number;
    baths?: number;
    cars?: number;
    propType: string;
    smallSize?: boolean;
    landSize?: number;
}>((ctx) => {
    const useStyles = makeStyles((theme) => {
        return {
            part: {
                display: 'flex',
                alignItems: 'center',
            },
            propBedDiv: {
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
            },

            icon: {
                width: isMobileDevice() || ctx.props.smallSize ? 13 : 29,
                height: isMobileDevice() || ctx.props.smallSize ? 13 : 29,
            },
            unit_icon: {
                width: 13,
            },
            actionIcon: {
                cursor: 'pointer',
            },
            propItemValue: {},
        };
    });
    const state = rst.state({
        changingBed: false,
        whichItem: '',
    });
    const changedBeds = rst.state({
        bed: ctx.props.beds,

        bath: ctx.props.baths,
        car: ctx.props.cars,
        landSize: ctx.props.landSize,
    });
    return (props) => {
        const propItems = [
            {
                item: 'bed',
                value: ctx.props.beds,
                icon: 'bed.svg',
                tagId: 'prop_bed',
            },
            {
                item: 'bath',
                value: ctx.props.baths,
                icon: 'bath.svg',
                tagId: 'prop_bath',
            },
            {
                item: 'car',
                value: ctx.props.cars,
                icon: 'cars.svg',
                tagId: 'prop_car',
            },
            {
                item: 'landSize',
                value: ctx.props.landSize,
                icon: 'square-ico.svg',
                typeId: 'landSize',
                suffix: 'm<sup>2</m>',
            },
        ];
        console.log(propItems);
        const classes = useStyles();
        const { propType } = props;
        const changeBedEvent = (which, newValue) => {
            for (let i = 0; i < propItems.length; i++) {
                const loopItem = propItems[i];
                if (loopItem.item == which) {
                    changedBeds[which] = newValue;
                    EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_PROP_BEDS, {
                        tagId: loopItem.tagId,
                        value: newValue,
                    });
                }
            }
        };

        const getContent: any = () => {
            const content = [];
            propItems.map((loopItem, idx) => {
                if ((propType == 'Apt' && idx < 3) || propType == 'House') {
                    content.push(
                        <>
                            {idx >= 1 ? (
                                <span
                                    style={{
                                        marginLeft: isMobileDevice() || props.smallSize || propType == 'Apt' ? 10 : 58,
                                    }}></span>
                            ) : null}

                            <div className={classes.part}>
                                <img
                                    className={propType == 'Apt' ? classes.unit_icon : classes.icon}
                                    src={'assets/icons/' + loopItem.icon}></img>
                                <div style={{ marginLeft: isMobileDevice() ? 5 : 10 }}></div>

                                <span
                                    style={{
                                        fontSize: isMobileDevice() ? 10 : 12,
                                    }}>
                                    {loopItem.value || '-'}
                                    {loopItem.suffix ? <TRHtml html={loopItem.suffix}></TRHtml> : null}
                                </span>
                            </div>
                        </>,
                    );

                    console.log(content);
                }
            });

            return content;
        };
        return (
            <div className={classes.propBedDiv} style={props.style}>
                {getContent()}
            </div>
        );
    };
});

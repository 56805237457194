import { api_getPropertyFeatures } from '../../../services/property';
import { getFavourites } from '../../../services/userCenter';
import { MapLevel } from '../support/PriceMapConsts';
import { PropertyMarker } from './PropertyMarker';

export var allFavMarkers = {};
const win: any = window;
const L = win.L;

export class FavMarkerController {
    map: any;
    constructor(map) {
        this.map = map;
    }

    async drawAllFavOnMap(allMyFavs) {
        const favMarkersBounds = [];
        //this.map.setZoom(MapLevel.suburbLevel.lower + 1);
        const myFavs = allMyFavs;
        console.log(myFavs);
        if (myFavs && myFavs.length > 0) {
            myFavs.forEach(async (loopFav) => {
                console.log(loopFav);
                if (!allFavMarkers[loopFav.entity.propId]) {
                    const markerProperty: any = {};
                    /*** temporary code as there is geo  */
                    // const propertyFeatures: any = await api_getPropertyFeatures(loopFav.entity.propId);

                    markerProperty.lat = loopFav.entity.propY;
                    markerProperty.lon = loopFav.entity.propX;
                    markerProperty.propId = loopFav.entity.propId;
                    markerProperty.propType = loopFav.entity.propType;
                    markerProperty.sold_contract_date = loopFav.entity.soldSettlementDate;
                    const propMarker = new PropertyMarker(markerProperty);
                    propMarker.setFavMarkerIcon();
                    allFavMarkers[loopFav.entity.propId] = propMarker;

                    favMarkersBounds.push([markerProperty.lat, markerProperty.lon]);
                    propMarker.getPropertyMarker().addTo(this.map);
                }
            });
        }

        console.log(favMarkersBounds);
        if (favMarkersBounds) {
            if (favMarkersBounds.length > 1) {
                this.map.fitBounds(favMarkersBounds);
            } else {
                this.map.panTo(new L.LatLng(favMarkersBounds[0][0], favMarkersBounds[0][1]));
            }
        }
    }
    removeAllFavOnMap() {
        Object.keys(allFavMarkers).forEach((propId) => {
            const propMarker = allFavMarkers[propId];
            this.map.removeLayer(propMarker.getPropertyMarker());
        });
        allFavMarkers = {};
        // this.map.setZoom(MapLevel.suburbLevel.lower);
    }
}

import { getLatlngArrayFromEncodedPath } from '../../../../commons/utils';
import ReactDOM from 'react-dom/server';
import React from 'react';
import { schoolMapInfo } from './SchooMapInfo';
import { SimilarTransMarker } from '../../../PriceMap/components/PriceLabel';

const win: any = window;
const L = win.L;

const schoolColors = [
    {
        rank: '#E70D14',
    },
    {
        rank: '#F86E00',
    },
    {
        rank: '#00B5F8',
    },
    {
        rank: '#0069F8',
    },
    {
        rank: '#af00f8',
    },
    {
        rank: '#f8d800',
    },
    {
        rank: '#0086f8',
    },
    {
        rank: '#3dd8cf',
    },
    {
        rank: '#3dd8cf',
    },
];

export class AroundPrivateSeletive {
    mapInstance = null;
    constructor(mapInstance) {
        this.mapInstance = mapInstance;
    }

    drawAround(propCenter, aroundRiskData) {
        L.circle([propCenter.lat, propCenter.lon], { radius: 11000, fill: false }).addTo(this.mapInstance);
    }

    drawSchoolMarkers(schools) {
        console.log(schools);
        schools.forEach((loopSchool, idx) => {
            const marker = L.marker([loopSchool.lat, loopSchool.lon]);
            const iconHTML = ReactDOM.renderToString(React.createElement(SimilarTransMarker, {}, idx + 1));

            const propertyIcon = new L.DivIcon({
                className: '',
                html: iconHTML,
            });

            marker.setIcon(propertyIcon).addTo(this.mapInstance);
        });
    }
}

import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TR } from '../../../../commons';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { toLower } from 'lodash';
import reactSelect from 'react-select/async';
import IconButton from '@material-ui/core/IconButton';
import { Collapse } from '@material-ui/core';
import { convertToM } from '../../../../commons/utils';
import { AppProvider } from '../../../../commons/appProvider';

const useStyles = makeStyles({
    root: {
        padding: '16px',
    },
    title: {
        fontSize: '16px',
        fontWeight: 500,
    },
    toggleButtons: {
        display: 'flex',
        width: '100%',
        paddingTop: '16px',
        justifyContent: 'space-between',
    },
    container: {
        maxHeight: '50rem',
    },
    tableHead: {
        fontWeight: 400,
    },
    tableCell: {
        fontSize: '13px',
        padding: '10px 0px 11px 2px',
    },
});

export const SalePriceHistory = (props) => {
    const classes = useStyles();
    const { soldRecords, landSize, localData, apt } = props;
    const soldHistoryRecord = (obj) => {
        if (obj) {
            obj.forEach((value) => {
                value.unitPrice = value.soldPrice / landSize;
            });
        }

        return obj;
    };

    // console.log('localData', localData);
    // console.log('soldRecords', soldRecords);

    const soldRecordAll = soldHistoryRecord(soldRecords);

    const soldRecordSorted =
        soldRecords &&
        soldHistoryRecord(soldRecords).sort((a, b) => (a.soldContractDate > b.soldContractDate ? -1 : 1));

    const soldRecordLatest = soldRecordSorted && soldRecordSorted.slice(0, 1);

    const n = soldRecordSorted && soldRecordSorted.length;

    const soldRecordOld = soldRecordSorted && soldRecordSorted.slice(1, n);

    const Row = (props) => {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow>
                    <TableCell className={classes.tableCell} style={{ width: '40%' }}>
                        {AppProvider.use().getLanguage() == 'zh'
                            ? new Date(row.soldContractDate).toLocaleDateString('zh-ZH', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                              })
                            : new Date(row.soldContractDate).toLocaleDateString('au-AU', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                              })}
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{ color: '#e84133', width: '25%' }}>
                        ${convertToM(row.soldPrice)}m
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{ width: '25%' }}>
                        {/* ${new Intl.NumberFormat().format(row.unitPrice.toFixed(0))}/&#x33A1; */}
                        {apt ? (
                            <>
                                {localData.priceApt[
                                    localData.year.findIndex(
                                        (element) => element === new Date(row.soldContractDate).getFullYear(),
                                    )
                                ] ? (
                                    '$' +
                                    convertToM(
                                        localData.priceApt[
                                            localData.year.findIndex(
                                                (element) => element === new Date(row.soldContractDate).getFullYear(),
                                            )
                                        ],
                                    ) +
                                    'm'
                                ) : (
                                    <TR name="noData" />
                                )}
                            </>
                        ) : (
                            '$' +
                            convertToM(
                                localData.priceHouse[
                                    localData.year.findIndex(
                                        (element) => element === new Date(row.soldContractDate).getFullYear(),
                                    )
                                ],
                            ) +
                            'm'
                        )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            style={{ padding: '0px' }}
                            onClick={() => setOpen(!open)}>
                            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Table>
                                <TableBody>
                                    {soldRecordOld.map((row) => (
                                        <TableRow key={row.soldContractDate}>
                                            <TableCell className={classes.tableCell} style={{ width: '40%' }}>
                                                {AppProvider.use().getLanguage() == 'zh'
                                                    ? new Date(row.soldContractDate).toLocaleDateString('zh-ZH', {
                                                          year: 'numeric',
                                                          month: 'long',
                                                          day: 'numeric',
                                                      })
                                                    : new Date(row.soldContractDate).toLocaleDateString('au-AU', {
                                                          year: 'numeric',
                                                          month: 'long',
                                                          day: 'numeric',
                                                      })}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                style={{ color: '#e84133', width: '25%' }}>
                                                ${convertToM(row.soldPrice)}m
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{ width: '25%' }}>
                                                {/* ${new Intl.NumberFormat().format(row.unitPrice.toFixed(0))}/&#x33A1; */}
                                                {/* $
                                                {apt
                                                    ? convertToM(
                                                          localData.priceApt[
                                                              localData.year.findIndex(
                                                                  (element) =>
                                                                      element ===
                                                                      new Date(row.soldContractDate).getFullYear(),
                                                              )
                                                          ],
                                                      )
                                                    : convertToM(
                                                          localData.priceHouse[
                                                              localData.year.findIndex(
                                                                  (element) =>
                                                                      element ===
                                                                      new Date(row.soldContractDate).getFullYear(),
                                                              )
                                                          ],
                                                      )}
                                                m */}
                                                {apt ? (
                                                    <>
                                                        {localData.priceApt[
                                                            localData.year.findIndex(
                                                                (element) =>
                                                                    element ===
                                                                    new Date(row.soldContractDate).getFullYear(),
                                                            )
                                                        ] ? (
                                                            '$' +
                                                            convertToM(
                                                                localData.priceApt[
                                                                    localData.year.findIndex(
                                                                        (element) =>
                                                                            element ===
                                                                            new Date(
                                                                                row.soldContractDate,
                                                                            ).getFullYear(),
                                                                    )
                                                                ],
                                                            ) +
                                                            'm'
                                                        ) : (
                                                            <TR name="noData" />
                                                        )}
                                                    </>
                                                ) : (
                                                    '$' +
                                                    convertToM(
                                                        localData.priceHouse[
                                                            localData.year.findIndex(
                                                                (element) =>
                                                                    element ===
                                                                    new Date(row.soldContractDate).getFullYear(),
                                                            )
                                                        ],
                                                    ) +
                                                    'm'
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <div style={{ padding: '12px' }}></div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <TR name="checkPriceHistory"></TR>
            </div>

            {/* {console.log(soldHistoryRecord(soldRecords))}
            {console.log(soldRecordLatest)}
            {console.log(soldRecordOld)} */}

            <TableContainer className={classes.container}>
                <Table stickyHeader size="medium" aria-label="sticky table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell
                                align="left"
                                className={classes.tableCell}
                                style={{ backgroundColor: 'white', padding: '13px 0px 7px 2px' }}>
                                <TR name="time"></TR>
                            </TableCell>
                            <TableCell
                                align="left"
                                className={classes.tableCell}
                                style={{ backgroundColor: 'white', padding: '13px 0px 7px 2px' }}>
                                <TR name="Price"></TR>
                            </TableCell>
                            <TableCell
                                align="left"
                                className={classes.tableCell}
                                style={{ backgroundColor: 'white', padding: '13px 0px 7px 2px' }}>
                                {apt ? <TR name="projectMedianPrice"></TR> : <TR name="medianPriceWhenSold"></TR>}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{ backgroundColor: 'white' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    {soldRecords && soldHistoryRecord(soldRecords).length === 0 ? (
                        <TableBody>
                            {
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <TR name="noSoldRecord"></TR>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    ) : soldRecords && soldHistoryRecord(soldRecords).length === 1 ? (
                        <TableBody>
                            {
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        {AppProvider.use().getLanguage() == 'zh'
                                            ? new Date(soldRecordAll[0].soldContractDate).toLocaleDateString('zh-ZH', {
                                                  year: 'numeric',
                                                  month: 'long',
                                                  day: 'numeric',
                                              })
                                            : new Date(soldRecordAll[0].soldContractDate).toLocaleDateString('au-AU', {
                                                  year: 'numeric',
                                                  month: 'long',
                                                  day: 'numeric',
                                              })}
                                    </TableCell>

                                    <TableCell className={classes.tableCell} style={{ color: '#e84133' }}>
                                        ${convertToM(soldRecordAll[0].soldPrice)}m
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableCell}
                                        // style={{ paddingTop: '10px', paddingBottom: '11px' }}
                                    >
                                        {/* ${new Intl.NumberFormat().format(soldRecordAll[0].unitPrice.toFixed(0))}
                                        /&#x33A1; */}
                                        {/* $
                                        {apt
                                            ? convertToM(
                                                  isNaN(
                                                      localData.priceApt[
                                                          localData.year.findIndex(
                                                              (element) =>
                                                                  element ===
                                                                  new Date(
                                                                      soldRecordAll[0].soldContractDate,
                                                                  ).getFullYear(),
                                                          )
                                                      ],
                                                  )
                                                      ? 'no record'
                                                      : localData.priceApt[
                                                            localData.year.findIndex(
                                                                (element) =>
                                                                    element ===
                                                                    new Date(
                                                                        soldRecordAll[0].soldContractDate,
                                                                    ).getFullYear(),
                                                            )
                                                        ],
                                              )
                                            : convertToM(
                                                  localData.priceHouse[
                                                      localData.year.findIndex(
                                                          (element) =>
                                                              element ===
                                                              new Date(soldRecordAll[0].soldContractDate).getFullYear(),
                                                      )
                                                  ],
                                              )}
                                        m */}
                                        {apt ? (
                                            <>
                                                {localData.priceApt[
                                                    localData.year.findIndex(
                                                        (element) =>
                                                            element ===
                                                            new Date(soldRecordAll[0].soldContractDate).getFullYear(),
                                                    )
                                                ] ? (
                                                    '$' +
                                                    convertToM(
                                                        localData.priceApt[
                                                            localData.year.findIndex(
                                                                (element) =>
                                                                    element ===
                                                                    new Date(
                                                                        soldRecordAll[0].soldContractDate,
                                                                    ).getFullYear(),
                                                            )
                                                        ],
                                                    ) +
                                                    'm'
                                                ) : (
                                                    <TR name="noData" />
                                                )}
                                            </>
                                        ) : (
                                            '$' +
                                            convertToM(
                                                localData.priceHouse[
                                                    localData.year.findIndex(
                                                        (element) =>
                                                            element ===
                                                            new Date(soldRecordAll[0].soldContractDate).getFullYear(),
                                                    )
                                                ],
                                            ) +
                                            'm'
                                        )}
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableCell}
                                        style={{ backgroundColor: 'white' }}></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    ) : (
                        <TableBody>
                            {soldRecordLatest?.map((row) => (
                                <Row key={row.soldContractDate} row={row} />
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </div>
    );
};

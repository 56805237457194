import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { clsx } from '../../../commons';
import { isMobileDevice } from '../../../commons/utils';
const useStyles = makeStyles((theme) => {
    return {
        root: {
            border: '1px solid #ffffff',
            boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.1)',
            borderRadius: '50%',
            cursor: 'pointer',
        },
        mobile: {
            width: 8,
            height: 8,
        },
        pc: {
            width: 12,
            height: 12,
        },
        mobile_14: {
            width: 10,
            height: 10,
        },
        mobile_15: {
            width: 12,
            height: 12,
        },
        mobile_16: {
            width: 14,
            height: 14,
        },
    };
});

export const PriceLegend = rst.create<{ background: string; zoom?: number }>((ctx) => {
    return (props) => {
        const classes = useStyles();

        return (
            <>
                <div
                    className={clsx(
                        classes.root,
                        isMobileDevice()
                            ? props.zoom < 14 || !props.zoom
                                ? classes.mobile
                                : classes['mobile_' + props.zoom]
                            : classes.pc,
                    )}
                    style={{ background: props.background }}></div>
            </>
        );
    };
});

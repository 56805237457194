import React, { useEffect, useState } from 'react';
import { rst } from 'rt-state';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { storage, TR, TRHtml } from '../../../commons';
import { ROUTES } from '../../../routes';
import { mobileNavButton } from '../../mobile/consts/styleConsts';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';

const useStyles = makeStyles((theme) => {
    return {
        button: {
            height: '46px',

            borderRadius: '10px',

            fontSize: '16px',
            fontFamily: theme.typography.fontFamily,
        },
        searchButton: {
            backgroundColor: '#1875f0',
            width: '110px',
            color: 'white',
        },
        modifyButton: {
            borderColor: theme.palette.primary.main,
            width: '170px',
            backgroundColor: 'white',
            color: theme.palette.primary.main,
        },

        searchDialogTitle: {
            fontSize: '24px',
        },
        searchDialogButton: {
            fontSize: '16px',
        },
        dummy: {},
        dialogTitle: {
            fontSize: '16px',
            minHeight: '22px',
            fontWeight: 500,
            textAlign: 'center',
            lineHeight: '22px',
            color: '#333333',
            width: '70%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            padding: '0',
        },
        dialogDesc: {
            fontSize: '14px',
            fontWeight: 400,
            color: '#333333',
            padding: '0 24px',
        },

        dialogTop: {
            display: 'inline',
        },
        bedRoot: { padding: '0px 23px 14px 23px' },
        bedTitle: {
            fontSize: '16px',
            fontWeight: 500,
            color: '#333333',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        closeButton: {
            position: 'absolute',
            right: '24px',
            top: '18px',
        },
        bedDesc: {
            fontSize: '14px',
            fontWeight: 400,
            color: '#333333',
            marginTop: '17PX',
        },
        bedButtons: {
            display: 'flex',
            marginTop: '11px',
            justifyContent: 'space-between',
            fontSize: '14px',
            fontWeight: 400,
        },
        bedButton: {
            width: '87px',
            height: '32px',
            background: '#eeeeee',
            border: '0.82px solid #d8d8d8',
            borderRadius: '2px',
            display: 'flex',
            alignItems: 'center',
            padding: 8,
        },
        bedImg: {
            width: '14px',
            height: '14px',
        },
        dialogWhole: {
            width: '100%',
            padding: '1pt 1pt',
        },
        labelDiv: {
            // height: '30px',
            // background: '#f7f7f7',
            // border: '1px solid rgba(151,151,151,0.2)',
            // borderRadius: '4px',
            // padding: '8px',
            // display: 'flex',
            // alignItems: 'center',
        },
    };
});
export const PopupComponentForLabel = rst.create((ctx: any) => {
    const state = rst.state({
        help: false,
    });
    function handleClick(isOpen: boolean) {
        state.help = isOpen;
        console.log('wo!!!!!');
    }
    function handleOpenMember(isOpen: boolean) {
        state.help = isOpen;
        window.open(`${ROUTES.JoinMember.path}`);
    }
    return (props: {
        content: any;
        link: any;
        title: any;
        table: any;
        tagType?: string;
        addtionData?: any;
        maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    }) => {
        const classes = useStyles(props);
        return (
            <div className={classes.labelDiv}>
                {<div onClick={() => handleClick(true)}>{props.link}</div>}
                <Dialog
                    onClose={() => handleClick(false)}
                    aria-labelledby="customized-dialog-title"
                    open={state.help}
                    maxWidth={props.maxWidth}
                    fullWidth={true}>
                    <DialogContent className={classes.dialogWhole}>
                        {props.tagType != 'beds' ? (
                            <>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <DialogTitle className={classes.dialogTitle}>
                                        {props.title}{' '}
                                        <div className={classes.closeButton} onClick={() => handleClick(false)}>
                                            <img src="assets/mobileIcons/close.svg"></img>
                                        </div>
                                    </DialogTitle>
                                </div>
                                <DialogContent className={classes.dialogDesc}>{props.table}</DialogContent>
                                <DialogContent className={classes.dialogDesc}>{props.content}</DialogContent>
                            </>
                        ) : (
                            <PropertyBedAdjust
                                addtionData={props.addtionData}
                                closeAdjustEvt={() => {
                                    state.help = false;
                                }}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            </div>
        );
    };
});

export const PrimarySchoolInfo = (props: any) => {
    const { openDialog, closeDialogEvt } = props;
    const classes = useStyles();
    return (
        <Dialog aria-labelledby="customized-dialog-title" open={openDialog} maxWidth={props.maxWidth} fullWidth={true}>
            <DialogContent className={classes.dialogWhole}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <DialogTitle className={classes.dialogTitle}>
                        {props.title}{' '}
                        <div className={classes.closeButton} onClick={closeDialogEvt}>
                            <img src="assets/mobileIcons/close.svg"></img>
                        </div>
                    </DialogTitle>
                </div>
                <DialogContent className={classes.dialogDesc}>{props.table}</DialogContent>
                <DialogContent className={classes.dialogDesc}>{props.content}</DialogContent>
            </DialogContent>
        </Dialog>
    );
};
const PropertyBedAdjust = (props: any) => {
    const { bed, bath, carpark, propId } = props.addtionData;
    const { closeAdjustEvt } = props;

    useEffect(() => {
        storage.get('PROPERTY_BED_ADJUST').then((result: any) => {
            console.log(result);
            if (result) {
                setBed_S(result.bed);
                setBath_S(result.bath);
                setCarpark_S(result.carpark);
            }
        });
    }, []);
    const [bed_S, setBed_S] = useState(bed || 0);
    const [bath_S, setBath_S] = useState(bath || 0);
    const [carpark_S, setCarpark_S] = useState(carpark || 0);

    const changeBeds = (which, direc) => {
        if (direc == '+') {
            if (which == 'bed') {
                setBed_S(bed_S + 1);
            }
            if (which == 'bath') {
                setBath_S(bath_S + 1);
            }
            if (which == 'carpark') {
                setCarpark_S(carpark_S + 1);
            }
        }
        if (direc == '-') {
            if (which == 'bed') {
                if (bed_S >= 1) {
                    setBed_S(bed_S - 1);
                }
            }
            if (which == 'bath') {
                if (bath_S >= 1) {
                    setBath_S(bath_S - 1);
                }
            }
            if (which == 'carpark') {
                if (carpark_S >= 1) {
                    setCarpark_S(carpark_S - 1);
                }
            }
        }
    };
    const saveAdjust = async () => {
        await storage.save(`PROPERTY_BED_ADJUST_${propId}`, {
            bed: bed_S,
            bath: bath_S,
            carpark: carpark_S,
        });
        EventBus.dispatch(EVENT_NAMES.EVENT_RETRIEVE_PROPERTY_ADJUST, {});
        closeAdjustEvt();
    };
    const classes = useStyles();
    return (
        <div className={classes.bedRoot}>
            {/* <div className={classes.bedTitle}>
                <TR name="bedType"></TR>
            </div> */}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <DialogTitle className={classes.dialogTitle}>
                    <TR name="bedType"></TR>
                    <div className={classes.closeButton} onClick={closeAdjustEvt}>
                        <img src="assets/mobileIcons/close.svg"></img>
                    </div>
                </DialogTitle>
            </div>

            <div className={classes.bedDesc}>
                <TR name="bedDesc"></TR>
            </div>

            <div className={classes.bedButtons}>
                <div className={classes.bedButton}>
                    <img className={classes.bedImg} src="assets/mobileIcons/ws-ico@1x.svg"></img>
                    <div
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            changeBeds('bed', '-');
                        }}>
                        <img className={classes.bedImg} src="assets/mobileIcons/minus.svg"></img>
                    </div>
                    <div style={{ marginLeft: 5 }}>{bed_S}</div>
                    <div
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                            changeBeds('bed', '+');
                        }}>
                        <img className={classes.bedImg} src="assets/mobileIcons/plus.svg"></img>
                    </div>
                </div>

                <div className={classes.bedButton}>
                    <img className={classes.bedImg} src="assets/mobileIcons/my-ico@1x.svg"></img>

                    <div
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            changeBeds('bath', '-');
                        }}>
                        <img className={classes.bedImg} src="assets/mobileIcons/minus.svg"></img>
                    </div>
                    <div style={{ marginLeft: 5 }}>{bath_S || '0'}</div>
                    <div
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                            changeBeds('bath', '+');
                        }}>
                        <img className={classes.bedImg} src="assets/mobileIcons/plus.svg"></img>
                    </div>
                </div>

                <div className={classes.bedButton}>
                    <img className={classes.bedImg} src="assets/mobileIcons/cz-ico@1x.svg"></img>

                    <div
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            changeBeds('carpark', '-');
                        }}>
                        <img className={classes.bedImg} src="assets/mobileIcons/minus.svg"></img>
                    </div>
                    <div style={{ marginLeft: 5 }}>{carpark_S || '0'}</div>
                    <div
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                            changeBeds('carpark', '+');
                        }}>
                        <img className={classes.bedImg} src="assets/mobileIcons/plus.svg"></img>
                    </div>
                </div>
            </div>

            <div style={{ marginTop: '36px' }}>
                <Button
                    onClick={saveAdjust}
                    style={{
                        ...mobileNavButton,
                        background: '#009e90',

                        color: 'white',
                        width: '100%',
                    }}>
                    <TR name="bedConfirm"></TR>
                </Button>
            </div>
        </div>
    );
};

import React from 'react';
import { useStyles } from './AboutUs.styles';
import { TR } from '../../commons/i18n';
import { Button, Hidden, Link } from '@material-ui/core';
import { rst } from 'rt-state';
import { HomeFooter } from '../Home/components/HomeFooter';
import { FirstPageHead } from '../Home/components/FirstPageHead';
import { RRLink } from '../../commons';
import { ROUTES } from '../../routes';
import { NewHomePageHead } from '../NewHome/components/01NewHomePageHead';
import { NewHomeFooter } from '../NewHome/components/11NewHomeFooter';
import { NewHomeFooterForMobile } from '../NewHome/components/11NewHomeFooterForMobile';

export const AboutUsPage = rst.createS(() => {
    const classes = useStyles();
    return (
        <>
            <NewHomePageHead />
            <div className={classes.about}>
                <div className={classes.words}>
                    <h1>
                        <TR name={'About HomeOn'} />
                    </h1>
                    <div className={classes.content}>
                        <TR name={'AboutUsContent'} />
                    </div>
                </div>

                <img src={`${process.env.PUBLIC_URL}/static/images/about.png`} alt="About_Us" />
            </div>

            <div className={classes.button}>
                <Link
                    // target="_blank"
                    component={RRLink}
                    to={ROUTES.FirstPage.path}
                    color="initial"
                    style={{ textDecoration: 'none' }}>
                    <Button variant="contained" size="large" color="primary">
                        <TR name={'Go To HomeOn'} />
                    </Button>
                </Link>
            </div>
            <Hidden smDown>
                <NewHomeFooter />
            </Hidden>
            <Hidden mdUp>
                <NewHomeFooterForMobile />
            </Hidden>
        </>
    );
});

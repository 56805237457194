import { rst } from 'rt-state';
import { FavouriteDto, ViewUserCenterProperty } from '../../../models/backend/userCenter';
import { useStyles } from '../styles';
import { Grid, Tooltip, withStyles } from '@material-ui/core';
import React from 'react';
import { TR, TROrNA } from '../../../commons';
import { getSecondarySchoolDesc } from '../../PriceMap/support/common';

export const SchoolsAndTrainInfo = rst.createS<{
    dto: FavouriteDto;
}>((props) => {
    const classes = useStyles();
    const { primarySchool, secondarySchool, closestStationDistance } =
        props.dto.entity ?? ({} as ViewUserCenterProperty);
    return (
        <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            xs={12}
            className={classes.contentSchool}>
            <Grid item xs className={classes.schoolType}>
                <CustomTooltip title={primarySchool[0]?.name} arrow>
                    <span>
                        {primarySchool[0]?.name.substr(0, 11)}
                        {primarySchool[0]?.name.trim().length > 11 ? '...' : ''}
                    </span>
                </CustomTooltip>
                <div className={classes.schoolScore}>
                    <span>{primarySchool[0]?.rank_disp}</span>
                    {primarySchool[0]?.score === 0 ? null : <span>{primarySchool[0]?.score}</span>}
                </div>
            </Grid>
            <Grid item xs className={classes.schoolType}>
                <CustomTooltip title={secondarySchool[0]?.name} arrow>
                    <span>
                        {secondarySchool[0]?.name.substr(0, 11)}
                        {secondarySchool[0]?.name.trim().length > 11 ? '...' : ''}
                    </span>
                </CustomTooltip>
                <div className={classes.schoolScore}>
                    {secondarySchool[0]?.score != 0 ? <span>{secondarySchool[0]?.score}</span> : ''}
                    <span>
                        <TR name={getSecondarySchoolDesc(secondarySchool[0].score)}></TR>
                    </span>
                </div>
            </Grid>
            <Grid item xs className={classes.schoolType}>
                <span>
                    <TR name={'Train Distance'} />
                </span>
                <div className={classes.schoolScore}>
                    <span>
                        <TROrNA name={closestStationDistance?.toFixed(2)} />
                        km
                    </span>
                </div>
            </Grid>
        </Grid>
    );
});
const CustomTooltip = withStyles({
    tooltip: {
        color: '#495b6b',
        backgroundColor: 'white',
        fontSize: '12px',
    },
})(Tooltip);

import React, { useState, useEffect } from 'react';

import { CardTitle } from '../propertyDetails/cardTitle';
import { CardContent } from '@material-ui/core';

import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { MapGlobalVars } from '../../../PriceMap/support/PriceMapConsts';
import { SuburbSoldRank } from '../../../PriceMap/suburbSoldRank/SuburbSoldRank';
import { StreetRanks } from '../../../PriceMap/suburbSoldRank/streetRanks';
import { stickNumTo5_10 } from '../../../../commons/utils';

export const StreetRankCard = (props: any) => {
    const { suburbData } = props;
    console.log(suburbData);
    console.log(suburbData.locality.streetRanking.expensiveStreets);

    const expensiveStreets = [];
    suburbData.locality.streetRanking.mostExpensive.map((loopStreet) => {
        expensiveStreets.push({
            columnValues: [loopStreet.streetName, stickNumTo5_10(loopStreet.suburbMedianPct * 100).toFixed(0) + '%'],
        });
    });
    const chepestStreets = [];

    suburbData.locality.streetRanking.mostEconomical.map((loopStreet) => {
        chepestStreets.push({
            columnValues: [loopStreet.streetName, stickNumTo5_10(loopStreet.suburbMedianPct * 100).toFixed(0) + '%'],
        });
    });
    const lowestChangeStreets = [];
    const highestChangeStreets = [];

    suburbData.locality.streetRanking.lowestChanginghands.map((loopStreet) => {
        lowestChangeStreets.push({
            columnValues: [loopStreet.streetName, (loopStreet.turnoverRate * 100).toFixed(0) + '%'],
        });
    });

    suburbData.locality.streetRanking.highestChanginghands.map((loopStreet) => {
        highestChangeStreets.push({
            columnValues: [loopStreet.streetName, (loopStreet.turnoverRate * 100).toFixed(0) + '%'],
        });
    });

    const [closeContent, setCloseContent] = useState(true);
    const closeEvt = () => {
        setCloseContent(!closeContent);
    };
    return (
        <div style={{ width: '100%', padding: '10px' }}>
            <CardTitle closeContent={closeContent} closeEvt={closeEvt} title="streetRankTitle"></CardTitle>

            <div>
                {closeContent ? null : (
                    <CardContent>
                        <StreetRanks
                            expensiveStreets={expensiveStreets}
                            chepestStreets={chepestStreets}
                            highestChangeStreets={highestChangeStreets}
                            lowestChangeStreets={lowestChangeStreets}></StreetRanks>
                    </CardContent>
                )}
            </div>
        </div>
    );
};

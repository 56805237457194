import { Hidden, makeStyles, Select, MenuItem, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { TR, TRHtml } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { ROUTES } from '../../../routes';
import { DisplaySubInfoM } from './DisplaySubInfoM';
import { statsQuery_byGraphQL } from '../../../commons/graphqlClient/graphAPI';
import Typography from '@material-ui/core/Typography';
import { PopupComponentForLabel } from '../../PriceMap/components/popupComponentForLabel';

const useStyles = makeStyles((theme) => ({
    root: {
        // width: '1200px',
        margin: '0 auto',
        // '& h2': {
        //     fontSize: '26px',
        //     lineHeight: '36px',
        //     textAlign: 'center',
        //     margin: '0px',
        //     padding: '10px 0px 0px 0px',
        //     [theme.breakpoints.down('sm')]: {
        //         fontSize: '16px',
        //         lineHeight: '25px',
        //         padding: '5px 0px 0px 0px',
        //         textAlign: 'left',
        //     },
        // },
        // [theme.breakpoints.down('sm')]: {
        //     padding: '28px 16px',
        //     width: '100vw',
        // },
    },

    selectGroup: {
        paddingTop: '25px',

        display: 'flex',
        justifyContent: 'center',
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
    },
    icon: {
        fill: '#009E90',
    },
    selectTitle: {
        // margin: '3px',
        // padding: '3px',
        color: '#009E90',
        fontSize: '16px',
        fontFamily: 'PingFangSC, PingFangSC-Semibold',
        fontWeight: 400,
        textAlign: 'left',
    },

    dataDateCon: {
        marginTop: '1.4rem',
        display: 'flex',
        justifyContent: 'center',
    },
    dataDate: {
        fontSize: '13px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        textAlign: 'center',
        color: '#888888',
        display: 'flex',
        justifyContent: 'center',
    },
    ranking: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
}));

export const PriceIncRankingM = (props) => {
    // const appProvider = AppProvider.use();
    const classes = useStyles();

    // const [statsInfo, setStatsInfo] = useState({ localityHouseMedian12MTopChangeRate: [] });
    const [localityHouseMedian6MTopChangeRate, setLocalityHouseMedian6MTopChangeRate] = useState([]);
    const [localityHouseMedian12MTopChangeRate, setLocalityHouseMedian12MTopChangeRate] = useState([]);
    const [localityAptMedian6MTopChangeRate, setLocalityAptMedian6MTopChangeRate] = useState([]);
    const [localityAptMedian12MTopChangeRate, setLocalityAptMedian12MTopChangeRate] = useState([]);
    const [localityHouseMedian6MBottomChangeRate, setLocalityHouseMedian6MBottomChangeRate] = useState([]);
    const [localityHouseMedian12MBottomChangeRate, setLocalityHouseMedian12MBottomChangeRate] = useState([]);
    const [localityAptMedian6MBottomChangeRate, setLocalityAptMedian6MBottomChangeRate] = useState([]);
    const [localityAptMedian12MBottomChangeRate, setLocalityAptMedian12MBottomChangeRate] = useState([]);
    const [localityHouseMedian6MTopChange, setLocalityHouseMedian6MTopChange] = useState([]);
    const [localityHouseMedian6MBottomChange, setLocalityHouseMedian6MBottomChange] = useState([]);
    const [localityHouseMedian12MTopChange, setLocalityHouseMedian12MTopChange] = useState([]);
    const [localityHouseMedian12MBottomChange, setLocalityHouseMedian12MBottomChange] = useState([]);
    const [localityAptMedian6MTopChange, setLocalityAptMedian6MTopChange] = useState([]);
    const [localityAptMedian6MBottomChange, setLocalityAptMedian6MBottomChange] = useState([]);
    const [localityAptMedian12MTopChange, setLocalityApteMedian12MTopChange] = useState([]);
    const [localityAptMedian12MBottomChange, setLocalityAptMedian12MBottomChange] = useState([]);

    const [updateTime, setUpdateTime] = useState('');
    const [period, setPeriod] = useState(1);
    const [propertyType, setPropertyType] = useState(1);
    const [priceChange, setPriceChange] = useState(1);

    const [showMore, setShowMore] = useState(false);
    useEffect(() => {
        getStatsInfo();
    }, []);

    const getStatsInfo = async () => {
        const statsInfoResult: any = await statsQuery_byGraphQL();

        setLocalityHouseMedian6MTopChangeRate(
            statsInfoResult.data.statsInfo.localityHouseMedian.localityMedian6MChange.localityMedianTopChangeRate,
        );
        setLocalityHouseMedian12MTopChangeRate(
            statsInfoResult.data.statsInfo.localityHouseMedian.localityMedian12MChange.localityMedianTopChangeRate,
        );
        setLocalityAptMedian6MTopChangeRate(
            statsInfoResult.data.statsInfo.localityAptMedian.localityMedian6MChange.localityMedianTopChangeRate,
        );
        setLocalityAptMedian12MTopChangeRate(
            statsInfoResult.data.statsInfo.localityAptMedian.localityMedian12MChange.localityMedianTopChangeRate,
        );
        setLocalityHouseMedian6MBottomChangeRate(
            statsInfoResult.data.statsInfo.localityHouseMedian.localityMedian6MChange.localityMedianBottomChangeRate,
        );
        setLocalityHouseMedian12MBottomChangeRate(
            statsInfoResult.data.statsInfo.localityHouseMedian.localityMedian12MChange.localityMedianBottomChangeRate,
        );
        setLocalityAptMedian6MBottomChangeRate(
            statsInfoResult.data.statsInfo.localityAptMedian.localityMedian6MChange.localityMedianBottomChangeRate,
        );
        setLocalityAptMedian12MBottomChangeRate(
            statsInfoResult.data.statsInfo.localityAptMedian.localityMedian12MChange.localityMedianBottomChangeRate,
        );
        setLocalityHouseMedian6MTopChange(
            statsInfoResult.data.statsInfo.localityHouseMedian.localityMedian6MChange.localityMedianTopChange,
        );
        setLocalityHouseMedian6MBottomChange(
            statsInfoResult.data.statsInfo.localityHouseMedian.localityMedian6MChange.localityMedianBottomChange,
        );
        setLocalityHouseMedian12MTopChange(
            statsInfoResult.data.statsInfo.localityHouseMedian.localityMedian12MChange.localityMedianTopChange,
        );
        setLocalityHouseMedian12MBottomChange(
            statsInfoResult.data.statsInfo.localityHouseMedian.localityMedian12MChange.localityMedianBottomChange,
        );
        setLocalityAptMedian6MTopChange(
            statsInfoResult.data.statsInfo.localityAptMedian.localityMedian6MChange.localityMedianTopChange,
        );
        setLocalityAptMedian6MBottomChange(
            statsInfoResult.data.statsInfo.localityAptMedian.localityMedian6MChange.localityMedianBottomChange,
        );
        setLocalityApteMedian12MTopChange(
            statsInfoResult.data.statsInfo.localityAptMedian.localityMedian12MChange.localityMedianTopChange,
        );
        setLocalityAptMedian12MBottomChange(
            statsInfoResult.data.statsInfo.localityAptMedian.localityMedian12MChange.localityMedianBottomChange,
        );
        setUpdateTime(statsInfoResult.data.statsInfo.localityHouseMedian.updateTime);
    };

    // console.log('123321', statsInfo);
    // console.log('123456', statsInfo.localityHouseMedian12MTopChangeRate);

    const handlePeriodOnchange = (event) => {
        setPeriod(event.target.value);
    };
    const handlepropertyTypeOnchange = (event) => {
        setPropertyType(event.target.value);
    };
    const handlepriceChangeOnchange = (event) => {
        setPriceChange(event.target.value);
    };

    const handleClick = (event) => {
        setShowMore(!showMore);
    };

    return (
        <div style={{ background: 'rgb(255 255 255)' }}>
            <div className={classes.root}>
                <div className={classes.selectGroup}>
                    <Select
                        value={period}
                        onChange={handlePeriodOnchange}
                        displayEmpty
                        disableUnderline
                        className={classes.selectEmpty}
                        inputProps={{
                            'aria-label': 'Without label',
                            classes: {
                                icon: classes.icon,
                            },
                        }}>
                        <MenuItem value={1}>
                            <span className={classes.selectTitle}>
                                <TR name={'Recent_6_months'} />{' '}
                            </span>
                        </MenuItem>
                        <MenuItem value={2}>
                            <span className={classes.selectTitle}>
                                <TR name={'Recent_12_months'} />
                            </span>
                        </MenuItem>
                    </Select>
                    <Select
                        value={propertyType}
                        onChange={handlepropertyTypeOnchange}
                        displayEmpty
                        disableUnderline
                        className={classes.selectEmpty}
                        inputProps={{
                            'aria-label': '/assets/icons/statistic.svg',
                            classes: {
                                icon: classes.icon,
                            },
                        }}>
                        <MenuItem value={1}>
                            <span className={classes.selectTitle}>
                                <TR name={'house'} />{' '}
                            </span>
                        </MenuItem>
                        <MenuItem value={2}>
                            <span className={classes.selectTitle}>
                                <TR name={'Apartment'} />
                            </span>
                        </MenuItem>
                    </Select>
                    <Select
                        value={priceChange}
                        onChange={handlepriceChangeOnchange}
                        displayEmpty
                        disableUnderline
                        className={classes.selectEmpty}
                        inputProps={{
                            'aria-label': 'Without label',
                            classes: {
                                icon: classes.icon,
                            },
                        }}>
                        <MenuItem value={1}>
                            <span className={classes.selectTitle}>
                                <TR name={'priceIncFastest'} />{' '}
                            </span>
                        </MenuItem>
                        <MenuItem value={2}>
                            <span className={classes.selectTitle}>
                                <TR name={'priceDecFastest'} />
                            </span>
                        </MenuItem>
                        <MenuItem value={3}>
                            <span className={classes.selectTitle}>
                                <TR name={'PriceChangeLargest'} />
                            </span>
                        </MenuItem>
                    </Select>
                </div>

                <div className={classes.dataDateCon}>
                    <img
                        src="/assets/icons/statistic.svg"
                        alt="icon"
                        style={{ width: '20px', height: '20px', padding: '0px 4px 0 0' }}
                    />
                    <div className={classes.dataDate}>
                        <PopupComponentForLabel
                            link={
                                <div style={{ marginLeft: '3px' }}>
                                    <TR name={'rankingDataDate'} />
                                </div>
                            }
                            content={
                                <div>
                                    <p>
                                        <TRHtml html={'rankingDes'} />
                                    </p>
                                </div>
                            }
                            title={<TR name={'rankingPopupTitle'}></TR>}
                            table=""
                            maxWidth="xl"
                        />
                        <div style={{ paddingLeft: 5 }}></div>
                        {AppProvider.use().getLanguage() == 'zh'
                            ? new Date(updateTime).toLocaleDateString('zh-ZH', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                              })
                            : new Date(updateTime).toLocaleDateString('au-AU', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                              })}
                    </div>
                </div>

                <div className={classes.ranking}>
                    <>
                        {!showMore &&
                            (period === 1 && propertyType === 1 && priceChange === 1
                                ? localityHouseMedian6MTopChangeRate.slice(0, 5)
                                : period === 2 && propertyType === 1 && priceChange === 1
                                ? localityHouseMedian12MTopChangeRate.slice(0, 5)
                                : period === 1 && propertyType === 2 && priceChange === 1
                                ? localityAptMedian6MTopChangeRate.slice(0, 5)
                                : period === 2 && propertyType === 2 && priceChange === 1
                                ? localityAptMedian12MTopChangeRate.slice(0, 5)
                                : period === 1 && propertyType === 1 && priceChange === 2
                                ? localityHouseMedian6MBottomChangeRate.slice(0, 5)
                                : period === 2 && propertyType === 1 && priceChange === 2
                                ? localityHouseMedian12MBottomChangeRate.slice(0, 5)
                                : period === 1 && propertyType === 2 && priceChange === 2
                                ? localityAptMedian6MBottomChangeRate.slice(0, 5)
                                : period === 2 && propertyType === 2 && priceChange === 2
                                ? localityAptMedian12MBottomChangeRate.slice(0, 5)
                                : period === 1 && propertyType === 1 && priceChange === 3
                                ? localityHouseMedian6MTopChange
                                      .concat(localityHouseMedian6MBottomChange)
                                      .sort(function (a, b) {
                                          return Math.abs(b.medianChange) - Math.abs(a.medianChange);
                                      })
                                      .slice(0, 5)
                                : period === 2 && propertyType === 1 && priceChange === 3
                                ? localityHouseMedian12MTopChange
                                      .concat(localityHouseMedian12MBottomChange)
                                      .sort(function (a, b) {
                                          return Math.abs(b.medianChange) - Math.abs(a.medianChange);
                                      })
                                      .slice(0, 5)
                                : period === 1 && propertyType === 2 && priceChange === 3
                                ? localityAptMedian6MTopChange
                                      .concat(localityAptMedian6MBottomChange)
                                      .sort(function (a, b) {
                                          return Math.abs(b.medianChange) - Math.abs(a.medianChange);
                                      })
                                      .slice(0, 5)
                                : period === 2 && propertyType === 2 && priceChange === 3
                                ? localityAptMedian12MTopChange
                                      .concat(localityAptMedian12MBottomChange)
                                      .sort(function (a, b) {
                                          return Math.abs(b.medianChange) - Math.abs(a.medianChange);
                                      })
                                      .slice(0, 5)
                                : []
                            )?.map((suburb, id) => (
                                <DisplaySubInfoM
                                    key={id}
                                    ranking={id + 1}
                                    suburb={suburb.name}
                                    changePct={suburb.medianChange}
                                    middlePrice={suburb.median}
                                    suburbId={suburb.id}></DisplaySubInfoM>
                            ))}
                    </>
                    <>
                        {showMore &&
                            (period === 1 && propertyType === 1 && priceChange === 1
                                ? localityHouseMedian6MTopChangeRate.slice(0, 9)
                                : period === 2 && propertyType === 1 && priceChange === 1
                                ? localityHouseMedian12MTopChangeRate.slice(0, 9)
                                : period === 1 && propertyType === 2 && priceChange === 1
                                ? localityAptMedian6MTopChangeRate.slice(0, 9)
                                : period === 2 && propertyType === 2 && priceChange === 1
                                ? localityAptMedian12MTopChangeRate.slice(0, 9)
                                : period === 1 && propertyType === 1 && priceChange === 2
                                ? localityHouseMedian6MBottomChangeRate.slice(0, 9)
                                : period === 2 && propertyType === 1 && priceChange === 2
                                ? localityHouseMedian12MBottomChangeRate.slice(0, 9)
                                : period === 1 && propertyType === 2 && priceChange === 2
                                ? localityAptMedian6MBottomChangeRate.slice(0, 9)
                                : period === 2 && propertyType === 2 && priceChange === 2
                                ? localityAptMedian12MBottomChangeRate.slice(0, 9)
                                : period === 1 && propertyType === 1 && priceChange === 3
                                ? localityHouseMedian6MTopChange
                                      .concat(localityHouseMedian6MBottomChange)
                                      .sort(function (a, b) {
                                          return Math.abs(b.medianChange) - Math.abs(a.medianChange);
                                      })
                                      .slice(0, 9)
                                : period === 2 && propertyType === 1 && priceChange === 3
                                ? localityHouseMedian12MTopChange
                                      .concat(localityHouseMedian12MBottomChange)
                                      .sort(function (a, b) {
                                          return Math.abs(b.medianChange) - Math.abs(a.medianChange);
                                      })
                                      .slice(0, 9)
                                : period === 1 && propertyType === 2 && priceChange === 3
                                ? localityAptMedian6MTopChange
                                      .concat(localityAptMedian6MBottomChange)
                                      .sort(function (a, b) {
                                          return Math.abs(b.medianChange) - Math.abs(a.medianChange);
                                      })
                                      .slice(0, 9)
                                : period === 2 && propertyType === 2 && priceChange === 3
                                ? localityAptMedian12MTopChange
                                      .concat(localityAptMedian12MBottomChange)
                                      .sort(function (a, b) {
                                          return Math.abs(b.medianChange) - Math.abs(a.medianChange);
                                      })
                                      .slice(0, 9)
                                : []
                            )?.map((suburb, id) => (
                                <DisplaySubInfoM
                                    key={id}
                                    ranking={id + 1}
                                    suburb={suburb.name}
                                    changePct={suburb.medianChange}
                                    middlePrice={suburb.median}
                                    suburbId={suburb.id}></DisplaySubInfoM>
                            ))}
                    </>
                </div>

                <div
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        paddingBottom: 20,
                    }}>
                    {showMore ? (
                        <Button
                            style={{
                                background: '#009E90',
                                borderRadius: '4px',
                                width: '90%',
                                minHeight: 40,
                                color: '#FFFFFF',
                            }}
                            onClick={handleClick}>
                            <Typography style={{ fontSize: '14px' }}>
                                <TR name="viewLess"></TR>
                            </Typography>
                        </Button>
                    ) : (
                        <Button
                            style={{
                                background: '#009E90',
                                borderRadius: '4px',
                                width: '90%',
                                minHeight: 40,
                                color: '#FFFFFF',
                            }}
                            onClick={handleClick}>
                            <Typography style={{ fontSize: '14px' }}>
                                <TR name="viewMore"></TR>{' '}
                            </Typography>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

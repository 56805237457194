import { Button, Grid, Hidden, makeStyles } from '@material-ui/core';
import { is } from 'date-fns/locale';
import moment from 'moment';
import React from 'react';
import { rst } from 'rt-state';
import { SecurityProvider, storage, TR } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { isMobileDevice } from '../../../commons/utils';
import { XAuthDialog } from '../../../components/Dialog/XAuthDialog';
import { ROUTES } from '../../../routes';
import { getUserProfile } from '../../../services/auth';
import { getSuburbByPoint } from '../../../services/suburb';
import { whichDayForTrial } from '../../../services/userCenter';
import { LoginComp, SignupComp } from '../../auth';
import { HelpComponent } from '../../PriceMap/components/HelpComponent';
import { SuburbSearchAutoComplete } from '../../PriceMap/Layers/SuburbSearchInput';
import { NewButton } from './NewButton';
import * as locales from '@material-ui/core/locale';

const useStyles = makeStyles((theme) => ({
    homeImg: {
        background: `url(${process.env.PUBLIC_URL}/static/images/banner-mwz.png) center center no-repeat`,
        // background: 'rgb(48 120 191)',
        maxWidth: '1920px',
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: 'px',
        // },
        height: '450px',
        margin: '0 auto',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            backgroundSize: 'cover',
            height: '240px',
            background: 'linear-gradient(rgb(74 165 154), rgb(112 201 193))',
        },
    },
    homeImgContent: {
        width: '1200px',
        margin: '0 auto',
        paddingTop: '100px',
        // paddingLeft: '50px',
        [theme.breakpoints.down('sm')]: {
            padding: '24px 16px',
            width: '100vw',
        },
        '& p': {
            fontSize: '20px',
            lineHeight: '28px',
            margin: '20px 0px 0px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                lineHeight: '16px',
                margin: '20px 0px 0px 0px',
            },
        },
    },
    homeImgText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: 'white',
        // width: '757px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },

        '& h1': {
            fontSize: '36px',
            margin: '10px 0px 10px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                // lineHeight: '36px',
                margin: '5px 0px 10px 0px',
            },
        },
        '& p': {
            fontSize: '20px',
            lineHeight: '28px',
            margin: '0px 0px 20px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                lineHeight: '16px',
            },
        },
        '& span': {
            fontSize: '24px',
            color: 'red',
            fontWeight: 500,
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
            },
        },
    },
    homeSearch: {
        // background: '#009E90',
        background: 'rgb(255 255 255)',
        // maxWidth: {appProvider.getLocale() === locales.zhCN ? '763px' : '500'},
        height: '50px',
        borderRadius: '4px',
        margin: '60px 0px 0px 0px',
        padding: '0px 0px',
        position: 'relative',
        // top: '-32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // boxShadow: '0px 5px 10px rgb(166 166 166)',

        // zIndex: '99',
        [theme.breakpoints.down('sm')]: {
            // background: 'rgb(255 255 255)',
            borderRadius: '4px',
            height: '30px',
            maxWidth: '90vw',
            boxShadow: 'none',
            padding: '0px',
            display: 'flex',
            alignItems: 'center',
            margin: '30px 0px 0px 0px',
        },
    },
    homeSearchBar: {
        padding: '0',
        // position: 'absolute',
        height: '100%',
        // top: '0px',
        // [theme.breakpoints.down('sm')]: {
        //     maxWidth: '100%',
        //     padding: '0px 10px',
        //     margin: '0 auto',
        //     background: 'rgb(255 255 255)',
        //     height: '32px',
        //     borderRadius: '16px',
        // },
    },
    searchBarWidth: {
        width: '100%',
        borderRadius: '4px',
        // borderTopLeftRadius: '4px',
        // borderBottomLeftRadius: '4px',
        padding: '0 10px',
        background: 'rgb(255 255 255)',

        [theme.breakpoints.down('sm')]: {
            width: '170px',
            padding: '0',
            height: '30px',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            // fontSize: '16px',
        },
    },
    homeSearchButton: {
        color: 'rgb(0 0 0)',
        display: 'flex',
        flexDirection: 'row',
        // flexDirection: 'column',
        alignItems: 'center',
        padding: '7px 15px',
        margin: '0px 10px',
        backgroundColor: 'rgb(74 165 154)',
        boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.7)',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 5px',
            margin: '0px 8px',
            // color: 'rgb(110 110 110)',
            flexDirection: 'row',
            textAlign: 'center',
        },
        '& img': {
            // color: 'red',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '3px',
                width: 12,
            },
        },
        // '& img': {
        //     [theme.breakpoints.down('sm')]: {
        //         overflow: 'hidden',
        //         position: 'relative',
        //         left: '-5px',
        //         borderRight: '20px solid transparent',
        //         filter: 'drop-shadow(20px 0 rgb(110 110 110))',
        //     },
        // },
        '& span': {
            fontSize: '16px',
            lineHeight: '19px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
            },
        },
    },

    homeImgButton: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '20px',

        [theme.breakpoints.down('sm')]: {
            marginTop: '10px',
        },
    },
}));
export const NewHomeImg = rst.create((ctx) => {
    function openPriceMapSearchEvt(propId?, suburbId?, suburbName?) {
        if (propId) {
            window.location.href = `${ROUTES.PriceMap.path}?propId=${propId}`;
        }
        if (suburbId) {
            window.location.href = `${ROUTES.PriceMap.path}?suburbId=${suburbId}&suburbName=${suburbName}`;
        }
    }
    function foldSearch(choosedSuburbId, choosedSuburbName) {
        openPriceMapSearchEvt(null, choosedSuburbId, choosedSuburbName);
    }
    const handleMySuburb = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    console.log(position);
                    const userSuburb: any = await getSuburbByPoint({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    console.log(userSuburb);
                    if (!userSuburb || !userSuburb.id) {
                        EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                            show: true,
                            title: 'ERROR',
                            content: 'FAILED_GET_USER_POSITION',
                        });
                    } else {
                        window.location.href = `${ROUTES.PriceMap.path}?suburbId=${userSuburb.id}&suburbName=${userSuburb.locality_name}`;
                    }
                },
                (err) => {
                    EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                        show: true,
                        title: 'ERROR',
                        content: 'FAILED_GET_USER_POSITION',
                    });
                    openPriceMap();
                },
            );
        } else {
            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                show: true,
                title: 'ERROR',
                content: 'FAILED_GET_USER_POSITION',
            });
            openPriceMap();
        }
    };
    const openPriceMap = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = ROUTES.PriceMap.path;
    };
    function handleOpenMember() {
        window.open(`${ROUTES.JoinMember.path}`);
    }

    const searchState = rst.stateS(false);
    const trialState = rst.stateS(false);
    const memberExpire = rst.stateS(false);
    const appProvider = AppProvider.use();
    const { state } = appProvider;
    const securityProvider = SecurityProvider.use();

    return (props) => {
        const classes = useStyles();
        const suburbSearchInputStyles = {
            padding: '0px',
            borderRadius: '2px',
            inputStyles: {
                background: 'white',
                height: isMobileDevice() ? 20 : 50,
                fontSize: isMobileDevice() ? 12 : 16,
                //fontSize: 18,
                placholder: isMobileDevice()
                    ? 'Enter a Sydney address or suburb'
                    : 'Enter a Sydney address or suburb to reveal the data insights you’ve never seen',
                borderRadius: 19,
                paddingLeft: isMobileDevice() ? 0 : 0,
                paddingRight: isMobileDevice() ? 0 : 0,
            },
        };
        const isShow = () => {
            storage.get('token').then((data) => {
                if (data) {
                    getUserProfile().then(
                        (userProfile: any) => {
                            console.log(userProfile);
                            const expireTime = userProfile.roles['2'] || userProfile.roles['4'];
                            const daysExprire = Math.floor((expireTime - moment().valueOf()) / (24 * 60 * 60 * 1000));
                            console.log('dayExpire is ' + daysExprire);
                            // if user has expired
                            if (expireTime < moment().valueOf()) {
                                searchState.value = false;
                                trialState.value = false;
                                memberExpire.value = true;
                            } else {
                                searchState.value = true;
                                trialState.value = false;
                                memberExpire.value = false;
                            }
                        },
                        (error) => {
                            searchState.value = false;
                            trialState.value = true;
                        },
                    );
                } else {
                    searchState.value = false;
                    trialState.value = true;
                }
            });
        };
        // setTimeout(() => {
        isShow();
        // }, 100);

        return (
            <div className={classes.homeImg}>
                <div className={classes.homeImgContent}>
                    <div className={classes.homeImgText}>
                        <h1>
                            <TR name={'NewHome_homeImgTextHeader'} />
                        </h1>
                        <p>
                            <TR name={'NewHome_homeImgTextP1'} />
                            {/* <span>$99</span>
                            <TR name={'NewHome_homeImgTextP1_!'} /> */}
                        </p>
                    </div>
                    {/* {searchState.value ? ( */}
                    <div
                        className={classes.homeSearch}
                        style={{
                            maxWidth: appProvider.getLocale() === locales.zhCN ? '50%' : '60%',
                            margin: appProvider.getLocale() === locales.zhCN ? '60px 25% 0 25%' : '60px 20% 0 20%',
                        }}
                        id="scrollToTop">
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            className={classes.homeSearchBar}>
                            <Grid item className={classes.searchBarWidth} style={{ zIndex: 99 }} xs>
                                <SuburbSearchAutoComplete
                                    cssStyle={suburbSearchInputStyles}
                                    searchAddress={true}
                                    searchSuburb={true}
                                    propertySelectedEvent={openPriceMapSearchEvt}
                                    caller="home"
                                    foldEvent={foldSearch}
                                />
                            </Grid>
                            {/* <Hidden smDown> */}
                            {/* <Grid item> */}
                            {/* <Button onClick={openPriceMap} style={{ padding: '0px', height: '100%' }}> */}
                            {/* <Button onClick={handleMySuburb} style={{ padding: '0px', height: '100%' }}>
                                    <div className={classes.homeSearchButton}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/position-ico@1x.png`} />
                                        <span>
                                            <TR name={'NewHome_priceMap'} />
                                        </span>
                                    </div>
                                </Button> */}
                            {/* </Grid> */}
                            {/* </Hidden> */}
                        </Grid>
                    </div>
                    {/* ) : null} */}
                    {/* {trialState.value && (state.isLogOut || !state.isLogOut) ? (
                        <div className={classes.homeImgButton}>
                            <div style={{ marginLeft: '24px' }}>
                                <NewButton
                                    backgroundColor="rgb(191 204 209)"
                                    padding={isMobileDevice() ? '10px 13px' : '10px 19px'}
                                    color="#43818C"
                                    label="NewHome_Register now Free trial"
                                    onClick={() => (state.authDialog = 'signup')}
                                />
                            </div>
                        </div>
                    ) : null} */}
                    {false ? (
                        <div style={{ display: 'flex' }}>
                            <p style={{ marginBottom: '0px', color: 'rgb(105 97 98)' }}>
                                <TR name={'NewHome_homeImgTextP2_1'} />
                                <span
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={() => (state.authDialog = 'signup')}>
                                    <TR name={'NewHome_homeImgTextP2_2'} />
                                </span>
                                <TR name={'NewHome_homeImgTextP2_3'} />
                            </p>
                            {/* <p>
                                <HelpComponent content="NewHome_homeImgHelp" maxWidth="xs" />
                            </p> */}
                        </div>
                    ) : null}
                </div>
                <XAuthDialog
                    open={state.authDialog === 'login' || state.authDialog === 'signup'}
                    onClose={appProvider.onCloseAuthDialog}>
                    {state.authDialog === 'login' && (
                        <LoginComp
                            onClose={appProvider.onCloseAuthDialog}
                            goToSignup={() => (state.authDialog = 'signup')}
                        />
                    )}
                    {state.authDialog === 'signup' && (
                        <SignupComp
                            onClose={appProvider.onCloseAuthDialog}
                            goToLogin={() => (state.authDialog = 'login')}
                        />
                    )}
                </XAuthDialog>
            </div>
        );
    };
});

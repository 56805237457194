import { SecurityProvider, TR } from '../../commons';
import { hooks, rst } from 'rt-state';
import { useHistory, withRouter } from 'react-router-dom';
import { clsx, getQueryParams, XProps } from '../../commons';
import { SessionPo } from '../../models/backend/session_po';
import { AppProvider } from '../../commons/appProvider';
import { createXForm, XTextFieldProps, string } from '../../components/form';
import { usePageStyles } from './login.styles';
import * as React from 'react';
import { Card, IconButton, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import { forgetPassword } from '../../services/auth';

const useStyles = makeStyles({
    loginTitle: {
        paddingTop: '14px',
        paddingBottom: '22px',
        fontSize: 16,
        fontWeight: 500,
    },
});

export const USERNAME_SCHEMA = string().required('Required').email('Invalid email address');
export const PASSWORD_HELPER_TEXT = 'Use 6 or more characters with a mix of letters, numbers & symbols';
export const PASSWORD_SCHEMA = string()
    .required('Required')
    .check((value) => {
        if (!value || value.length < 6 || !/\d/.test(value) || !/[a-zA-Z]/.test(value)) {
            return PASSWORD_HELPER_TEXT;
        }

        return undefined;
    });

export const getPassword2Validator = (fieldMatched: string) => {
    return async (value, field, values) => {
        if (value === values[fieldMatched]) {
            return true;
        }
        return "Password doesn't match";
    };
};

export function setup(initialValues, validate, onSubmit) {
    const securityProvider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const { snackbarController } = appProvider;
    const hooksRef = hooks(() => {
        const history = useHistory();
        const sessionPo = getQueryParams(history) as SessionPo;
        return { history, sessionPo };
    });
    const form = createXForm({
        initialValues,
        validate,
        onSubmit,
        fieldProps: {
            // password: {
            //     onChange: async function (value, field, form) {
            //         await form.validateField('password2');
            //     },
            // } as XTextFieldProps,
        },
    });

    function hasToken() {
        return !!hooksRef.current.sessionPo.token;
    }

    return { securityProvider, appProvider, snackbarController, hooksRef, hasToken, form };
}

export const PageWrapper = withRouter(
    rst.createS<XProps>((props) => {
        const classes = usePageStyles();
        return (
            <div className={clsx(classes.session, classes.background)}>
                <div className={classes.content}>
                    <div className={classes.wrapper}>
                        <Card>{props.children}</Card>
                    </div>
                </div>
            </div>
        );
    }),
);

export const AuthDialogHeader = rst.createS<{
    onClose?: () => void;
    forgetPass?: boolean;
    signUp?: boolean;
}>((props) => {
    const classes = useStyles();
    return (
        <Box display={'flex'} justifyContent={'center'}>
            <Box flexGrow={1} display={'flex'} justifyContent={'center'}>
                {/* <img
                    src={`${process.env.PUBLIC_URL}/static/images/logo.svg`}
                    alt=""
                    width={100}
                    // height={80}
                    className="block"
                /> */}
                <div className={classes.loginTitle}>
                    {props.signUp && <TR name={'signupTitle'}></TR>}
                    {!props.signUp && !props.forgetPass && <TR name={'loginTitle'}></TR>}
                    {props.forgetPass && <TR name={'resetPassTitle'}></TR>}
                    {/* {!props.signUp && <img src="/static/images/logo.svg" />} */}
                </div>
            </Box>
            <Box pt={2}>
                <IconButton onClick={() => props.onClose?.()}>
                    <ClearIcon />
                </IconButton>
            </Box>
        </Box>
    );
});

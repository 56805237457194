import React from 'react';
import { rst } from 'rt-state';
import { generateGUID } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { NearByTranscationsSimpleChart } from '../../../PriceMap/Charts/NearByTranscationsSimpleChart';
import { PropertyCalculator } from '../../../PriceMap/PropertyCalculator';
import { MapGlobalVars } from '../../../PriceMap/support/PriceMapConsts';
import { CardPermission } from '../cardPermission';
import { CardTitle } from './cardTitle';
import { TR } from '../../../../commons';
import { Button } from '@material-ui/core';
import { mobileNavButton } from '../../consts/styleConsts';
import { AppProvider } from '../../../../commons/appProvider';
import { red } from '@material-ui/core/colors';

export const PropertyMobileCaculatorCard = rst.create<{ property: any; showSimilarTrans: any }>((ctx: any) => {
    const state = rst.state({
        closeContent: true,
        hasPermission: true,
    });
    EventBus.on(EVENT_NAMES.EVENT_MOBILE_CARD_NOPERMISSION, (evtData: any) => {
        state.hasPermission = evtData.permission;
    });
    const closeContentEvt = () => {
        state.closeContent = !state.closeContent;
        ctx.props.showSimilarTrans(false);
    };

    return (props: any) => {
        setTimeout(() => {
            EventBus.dispatch(MapGlobalVars.nearByTransEventName, { chart: 3, prop: props.property });

            MapGlobalVars.currentClickedPropId = props.property.prop_id;
            EventBus.dispatch(EVENT_NAMES.EVENT_RECALCULATE_PROPERTY_PRICE, {});
        });
        const p_property = {
            propId: props.property.prop_id,
            lat: props.property.prop_y,
            lon: props.property.prop_x,
            allFeatures: props.property,
        };

        return (
            <div style={{ width: '100%', padding: '10px' }}>
                <CardTitle
                    closeContent={state.closeContent}
                    closeEvt={closeContentEvt}
                    title={'priveEstimateAnalysis'}></CardTitle>
                {state.closeContent ? (
                    <TR name="aiEnabledFunction"></TR>
                ) : (
                    <div>
                        <div style={{ fontSize: 14, fontWeight: 500, color: 'red', marginBottom: 5 }}>
                            <TR name="AIestimatePrice"></TR>
                        </div>
                        <TR name="propertyFeaturesConfirm"></TR>
                    </div>
                )}
                <div
                    style={{
                        minHeight: state.closeContent ? 'auto' : '150px',
                        position: 'relative',
                        visibility: state.closeContent ? 'hidden' : 'visible',
                        height: state.closeContent ? 0 : 'auto',
                    }}>
                    <CardPermission cardType="aroundPrice"></CardPermission>
                    {state.hasPermission ? (
                        <>
                            <NearByTranscationsSimpleChart
                                showForReport={true}
                                prop={p_property}></NearByTranscationsSimpleChart>
                            <PropertyCalculator showAllParams property={p_property}></PropertyCalculator>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {AppProvider.use().getLanguage() == 'zh' ? (
                                    <Button
                                        onClick={() => props.showSimilarTrans(true)}
                                        style={{
                                            ...mobileNavButton,
                                            width: '85%',
                                            height: 40,
                                        }}
                                        variant="outlined">
                                        <TR name="comparableProperties"></TR>
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => props.showSimilarTrans(true)}
                                        style={{
                                            ...mobileNavButton,
                                            width: '85%',
                                            height: 80,
                                        }}
                                        variant="outlined">
                                        <TR name="comparableProperties"></TR>
                                    </Button>
                                )}
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        );
    };
});

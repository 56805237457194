import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { generateGUID, SecurityProvider, TR } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { Permission } from '../../../commons/security/authz';
import { domReady } from '../supportUtils/htmlUtils';

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        background: 'white',
        opacity: 1,
        width: '10px',
        height: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px #888888 solid',
        borderRadius: '5px',
        zIndex: 998,
    },
    upgradeButton: {
        fontSize: 16,
        background: '#ff2200',
        opacity: '0.4',
        color: 'white',
        padding: '10px 20px',
    },
});
export const CardPermission = (props: any) => {
    const appProvider = AppProvider.use();
    const { cardType } = props;

    const compId = (cardType || '') + generateGUID();
    const securityProvider = SecurityProvider.use();

    const trialUser = securityProvider.hasPermission(Permission.TRIAL);
    const memberUser = securityProvider.hasPermission(Permission.PROPERTY_BUYER);
    const userEpxired = securityProvider.isExpired(trialUser ? Permission.TRIAL : Permission.PROPERTY_BUYER);
    if ((!trialUser && !memberUser) || ((trialUser || memberUser) && userEpxired)) {
        EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_CARD_NOPERMISSION, { permission: false });

        setTimeout(() => {
            setPermissionCardSize();
        });
    } else {
        EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_CARD_NOPERMISSION, { permission: true });
    }
    console.log('trial user ==>' + trialUser + ';memberUser===>' + memberUser + ';expired===>' + userEpxired);

    const classes = useStyles();
    const setPermissionCardSize = () => {
        domReady(compId).then((ref: any) => {
            const parentElement = ref.parentElement;
            ref.style.width = parentElement.offsetWidth + 'px';
            ref.style.height = (parentElement.offsetHeight >= 150 ? 150 : parentElement.offsetHeight) + 'px';

            console.log(ref.style.width, ref.style.height);
        });
    };

    const showLogin = () => {
        appProvider.state.authDialog = 'login';
    };

    return (
        <>
            {(trialUser || memberUser) && !userEpxired ? null : (
                <div id={compId} className={classes.root}>
                    <Button className={classes.upgradeButton} variant="contained" onClick={showLogin}>
                        <TR name="upgradeMembership"></TR>
                    </Button>
                </div>
            )}
        </>
    );
};

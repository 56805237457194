import React from 'react';
import { rst } from 'rt-state';
// import { HomeButton } from './HomeButton';
import { RRLink } from '../../../commons';
import { ROUTES } from '../../../routes';
import { TR } from '../../../commons';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Box,
    Toolbar,
    Grid,
    Tabs,
    Tab,
    Button,
    Link,
    Hidden,
    List,
    ListItem,
    ListItemText,
    Select,
    MenuItem,
} from '@material-ui/core';
import { UserAuthButton } from '../../auth/UserAuthButton';
import { AppProvider } from '../../../commons/appProvider';
import * as locales from '@material-ui/core/locale';
import MenuIcon from '@material-ui/icons/Menu';
import { createXDrawerController, XDrawer } from '../../../components/Drawer';
import { XAuthDialog } from '../../../components/Dialog/XAuthDialog';
import { ContactUsPage } from '../../ContactUs';
import { is } from 'date-fns/locale';
import { isMobileDevice } from '../../../commons/utils';
import { MemberInfo } from '../../mobile/member/memberInfo';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';

const useStyles = makeStyles((theme) => ({
    root: {
        // position: 'fixed',
        // display: 'flex',
        // justifyContent: 'center',
    },
    labelContainer: {
        padding: 0,
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            left: '0px',
        },
    },
    logo: {
        width: '110px',
        position: 'relative',

        // top: '5px',
        [theme.breakpoints.down('sm')]: {
            margin: '0',
            left: '10%',
            // width: '96px',
            width: '110px',
        },
        '& img': {
            [theme.breakpoints.down('sm')]: {
                // width: '80px',
                width: '110px',
            },
        },
    },
    icon: {
        fill: '#009E90',
        paddingTop: '2px',
    },
    indicator: {
        display: 'none',
    },
    listForMobile: {
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
            width: '45vw',
            '& span': {
                fontSize: '12px',
            },
        },
    },
    ListItem: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'left',
            fontSize: '16px',
            padding: '10px 0',
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
        paddingTop: '2px',
    },
    newAppBar: {
        // display: 'flex',
        // justifyContent: 'center',
        backgroundColor: 'white',
        height: '80px',
        width: '100vw',
        [theme.breakpoints.down('sm')]: {
            height: '62px',
        },
    },
    newCenter: {
        maxWidth: '1200px',
        margin: '0px auto',
        fontSize: '25px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // alignSelf: 'center',
        position: 'relative',
        top: '18px',
        '& span': {
            cursor: 'pointer',
        },
        '& span:hover': {
            color: '#009E90',
        },

        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            padding: '0px 16px',
            top: '10px',
        },
    },
    newMenu: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    newMenuItem: {
        padding: '0px 15px',
        fontSize: '14px',
    },
    langAuth: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        zIndex: 99,
        marginRight: '15px',
    },
}));

export const NewHomePageHead = rst.create((ctx) => {
    const openMemberInfo = rst.stateS(false);
    const appProvider = AppProvider.use();
    const ctrl = createXDrawerController();
    const toggleMobileMenu = (open: boolean) => (e) => {
        if (open) {
            ctrl.open();
        } else {
            if (isOpen.value) {
                // to keep the contact us showing when the side draw showing
                ctrl.open();
            } else {
                ctrl.close();
            }
        }
    };

    const languageState = rst.stateS(0);
    rst.watch(
        (values) => {
            const [locale] = values;
            switch (locale) {
                case locales.zhCN:
                    languageState.value = 1;
                    break;
                default:
                    languageState.value = 2;
            }
        },
        () => [appProvider.getLocale()],
    );
    const handleSelectLanguage = (newValue: number) => {
        switch (newValue) {
            case 1:
                appProvider.setLocaleAndSave(locales.zhCN);
                return;
            case 2:
                appProvider.setLocaleAndSave(locales.enUS);
                return;
            // default:
            //     appProvider.setLocaleAndSave(locales.enUS);
        }
    };
    const selectLanguage = (event: React.ChangeEvent<{ value: number }>) => {
        languageState.value = event.target.value;
        // console.log(`languageState.value=${languageState.value}`);
        handleSelectLanguage(languageState.value);
    };
    const isOpen = rst.stateS<'shop' | 'wechat' | 'live' | 'Feedback'>(null);
    function handleForumClose(choice: 'shop' | 'wechat' | 'live' | 'Feedback') {
        switch (choice) {
            case 'shop':
            case 'wechat':
                isOpen.value = choice;
                break;
            case 'Feedback':
                isOpen.value = choice;
                break;
            case 'live':
        }
        // toggleMobileMenu(true);
    }
    function onDialogClose() {
        isOpen.value = null;
    }
    const handleOpenBlue = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://www.homeon.com.au/reports/index.html';
    };

    const openShop = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://store.homeon.com.au/collections/all';
    };
    const handleOpenGlossary = () => {
        window.open(
            appProvider.getLocale() === locales.zhCN
                ? 'https://www.cnblog.homeon.com.au/blog/8n7c5ly7e7hx2kc8zfcw7tzr69gmfz'
                : `${process.env.PUBLIC_URL}/assets/help/HELP-EN.html`,
        );
    };

    // const handleClick = () => {
    //     console.log('clicked!!');
    // };
    return (props) => {
        const classes = useStyles();
        const handleOpenBlogs = () => {
            const newPage = window.open('about:blank');
            newPage.location.href =
                appProvider.getLocale() === locales.zhCN
                    ? 'https://www.cnblog.homeon.com.au/'
                    : 'https://blog.homeon.com.au/';
        };
        const handleOpenShop = () => {
            const newPage = window.open('about:blank');
            newPage.location.href = 'https://store.homeon.com.au/collections/all';
        };
        const mobileMenuList = () => (
            <div onClick={toggleMobileMenu(false)} onKeyDown={toggleMobileMenu(false)}>
                <List className={classes.listForMobile}>
                    <Link
                        target="_blank"
                        component={RRLink}
                        to={ROUTES.PriceMap.path}
                        color="initial"
                        style={{ textDecoration: 'none' }}>
                        <ListItem button className={classes.ListItem}>
                            <span>
                                <TR name={'NewHome_priceMap'} />
                            </span>
                        </ListItem>
                    </Link>

                    <ListItem button onClick={handleOpenBlogs} className={classes.ListItem}>
                        <span>
                            <TR name={'NewHome_originalArticle'} />
                        </span>
                    </ListItem>

                    <div
                        color="initial"
                        onClick={() => {
                            openMemberInfo.value = true;
                            openMemberInfo.forceUpdate();
                        }}
                        style={{ textDecoration: 'none' }}
                        className={classes.ListItem}>
                        <span>
                            <TR name={'NewHome_memberPricing'} />
                        </span>
                    </div>

                    <ListItem button onClick={handleOpenGlossary} className={classes.ListItem}>
                        <span>
                            <TR name={'Glossary'} />
                        </span>
                    </ListItem>

                    <span className={classes.ListItem}>
                        <a href="mailto:support@homeon.com.au">
                            <TR name={'NewHome_customerService'} />
                        </a>
                    </span>
                </List>
            </div>
        );
        return (
            <div className={classes.root}>
                <div className={classes.newAppBar}>
                    <div className={classes.newCenter}>
                        <Hidden mdUp>
                            <Grid item className={classes.button}>
                                <Button onClick={toggleMobileMenu(true)}>
                                    <MenuIcon fontSize="large" style={{ color: 'black' }} />
                                </Button>
                                <XDrawer controller={ctrl}>{mobileMenuList()}</XDrawer>
                            </Grid>
                        </Hidden>
                        <Box className={classes.logo}>
                            <a href={isMobileDevice() ? ROUTES.MobilePage.path : ROUTES.HomePage.path}>
                                <img
                                    style={{ marginTop: '10px' }}
                                    src={`${process.env.PUBLIC_URL}/static/images/logo.svg`}
                                />
                            </a>
                            {/* <Link
                                target="_blank"
                                component={RRLink}
                                to={ROUTES.HomePage.path}
                                color="initial"
                                style={{ textDecoration: 'none' }}>
                                <img src={`${process.env.PUBLIC_URL}/static/images/logo.svg`} />
                            </Link> */}
                        </Box>
                        <Hidden smDown>
                            <div className={classes.newMenu}>
                                <span className={classes.newMenuItem}>
                                    <a href={ROUTES.PriceMap.path}>
                                        <TR name={'NewHome_priceMap'} />
                                    </a>
                                </span>

                                <span className={classes.newMenuItem} onClick={handleOpenBlogs}>
                                    <TR name={'NewHome_originalArticle'} />
                                </span>

                                <div
                                    onClick={() => {
                                        openMemberInfo.value = true;
                                        openMemberInfo.forceUpdate();
                                    }}
                                    color="initial"
                                    style={{ textDecoration: 'none' }}
                                    className={classes.newMenuItem}>
                                    <span>
                                        <TR name={'NewHome_memberPricing'} />
                                    </span>
                                </div>
                                <span className={classes.newMenuItem} onClick={handleOpenGlossary}>
                                    <TR name={'Glossary'} />
                                </span>

                                <span className={classes.newMenuItem}>
                                    <a href="mailto:support@homeon.com.au">
                                        <TR name={'NewHome_customerService'} />
                                    </a>
                                </span>
                            </div>
                        </Hidden>
                        <div className={classes.langAuth}>
                            <Select
                                value={languageState.value}
                                onChange={selectLanguage}
                                displayEmpty
                                disableUnderline
                                className={classes.selectEmpty}
                                inputProps={{
                                    'aria-label': 'Without label',
                                    classes: {
                                        icon: classes.icon,
                                    },
                                }}>
                                <MenuItem value={2}>
                                    <span style={{ textAlign: 'center', color: '#009E90' }}>
                                        <TR name={'EN'} />
                                    </span>
                                    {/* <TR name={'EN'} /> */}
                                </MenuItem>
                                <MenuItem value={1}>
                                    <span style={{ color: '#009E90' }}>
                                        <TR name={'ZH'} />{' '}
                                    </span>
                                    {/* <TR name={'ZH'} /> */}
                                </MenuItem>
                            </Select>
                            <div style={{ marginLeft: 20 }}></div>
                            {isMobileDevice() ? (
                                <Box pl={0}>
                                    <UserAuthButton />
                                </Box>
                            ) : (
                                <Box pl={1} style={{ marginBottom: '3px' }}>
                                    <UserAuthButton />
                                </Box>
                            )}
                        </div>
                    </div>
                </div>
                <MemberInfo
                    show={openMemberInfo.value}
                    popType={0}
                    onClose={() => {
                        openMemberInfo.value = false;
                        openMemberInfo.forceUpdate();
                    }}></MemberInfo>
            </div>
        );
    };
});

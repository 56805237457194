import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { TR } from '../../../../commons';
const useStyles = makeStyles({
    root: {
        fontSize: 13,
        color: '#888888',
        fontWeight: 400,
        minWidth: '50px',
    },
});
export const InfoLabel = (props: any) => {
    const { children, style, noColon } = props;
    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <TR name={children}></TR>
                {!noColon ? <>:</> : ''}
            </div>
        </>
    );
};

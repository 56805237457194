import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: 328,
        // height: 370,
        margin: 6,
        [theme.breakpoints.down('sm')]: {
            width: '343px',
            height: '200px',
            padding: '14px 8px 17px 8px',
            margin: '9px auto',
        },
    },
    pic: {
        width: 328,
        height: 208,
        backgroundImage: (props: any) => {
            return `url('${props.imgSrc}')`;
        },
        backgroundSize: 'cover',
        [theme.breakpoints.down('sm')]: {
            width: '116px',
            height: '106px',
            padding: '0',
            borderRadius: '5px',
            margin: '0',
            // backgroundSize: 'cover',
        },
    },
    picContent: {
        width: 328,
        height: 51,
        background: 'rgba(0, 0, 0, 0.5)',
        position: 'relative',
        top: '157px',
        padding: '0px 14px',
        '& h3': {
            fontSize: '16px',
            fontWeight: 400,
            color: 'white',
            margin: '0',
            padding: '2px 0',
        },
        '& span': {
            fontSize: '14px',
            fontWeight: 400,
            color: 'white',
            margin: '0',
            padding: '0',
        },
    },
    button: {
        position: 'relative',
        '& span': {
            padding: '0',
        },
    },
    content: {
        padding: '10px 17px',
    },
    contentPrice: {
        display: 'flex',
        flexDirection: 'row',
        '& h2': {
            fontSize: 18,
            fontWeight: 700,
            color: '#333333',
            margin: '0',
            padding: '2px 0',
        },
        '& span': {
            fontSize: 12,
            fontWeight: 400,
            color: '#333333',
            margin: 0,
            padding: 0,
        },
    },
    contentRoom: {
        padding: '9px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 0 0 0',
        },
        '& span': {
            fontSize: 14,
            color: '#666666',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
            },
        },
    },
    contentSchool: {
        padding: '3px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '13px 0',
        },
    },
    schoolType: {
        '& >span': {
            fontSize: 12,
            color: '#999999',
            cursor: 'pointer',
        },
    },
    schoolScore: {
        margin: '10px 0px 0px 0px',
        '& >span': {
            fontSize: 12,
            color: '#1875F0',
            backgroundColor: 'rgba(24, 117, 240, 0.10)',
            padding: '3px 6px',
            margin: '4px 4px 4px 0px',
            borderRadius: 4,
        },
    },
    buttonForMobile: {
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            border: 'none',
            background: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '2px 0 16px 0',
            padding: '5px 6px',
            '& span': {
                fontSize: '13px',
                color: '#FFFFFF',
            },
        },
    },
    address: {
        '& h3': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px',
                margin: '0',
                padding: '0 0 4px 0',
                lineHeight: '15px',
                fontWeight: 500,
                color: '#333333',
            },
        },
        '& span': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '11px',
                margin: '0',
                padding: '0',
                lineHeight: '0px',
                color: '#666666',
            },
        },
    },
    priceForMobile: {
        '& h2': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                margin: '0',
                padding: '8px 0 0px 0',
                fontWeight: 600,
                color: '#333333',
            },
        },
        '& span': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                margin: '0',
                padding: '0',
                lineHeight: '0px',
                color: '#666666',
            },
        },
    },
    roomForMobile: {
        padding: '5px 0 0 0',
        '& span': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                color: '#666666',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
            },
        },
    },
}));

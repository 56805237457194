import { Hidden, makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR, TRHtml } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { isMobileDevice } from '../../../commons/utils';
import { NewButton } from './NewButton';
import { NewHomeSSPTPart } from './NewHomeSSPTPart';
import { NewHomeThreePicItem } from './NewHomeThreePicItem';
import * as locales from '@material-ui/core/locale';
import { ROUTES } from '../../../routes';
import { Tooltip, Button, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '1200px',
        margin: '0 auto',
        '& h2': {
            fontSize: '26px',
            lineHeight: '36px',
            textAlign: 'center',
            margin: '0px',
            padding: '10px 0px 0px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '25px',
                padding: '5px 0px 0px 0px',
                textAlign: 'left',
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: '28px 16px',
            width: '100vw',
        },
    },
    divider: {
        borderTop: 'solid 4px rgb(189 226 223)',
        width: '56px',
        margin: '10px auto',
        [theme.breakpoints.down('sm')]: {
            borderTop: 'solid 2px rgb(189 226 223)',
            width: '26px',
            margin: '6px 0px',
        },
    },
    content: {
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '22px',
        width: '851px',
        color: '48 48 48',
        margin: '0 auto',
        paddingBottom: '25px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'left',
            fontSize: '12px',
            lineHeight: '16px',
            padding: '10px 0px',
        },
    },
    threePartsForMobile: {
        margin: '0 auto',
    },
    threeParts: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    part: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '365px',
        margin: '0px',
        padding: '0px',
        fontSize: '16px',
        lineHeight: '22px',
        color: '48 48 48',
    },
    part1: {
        marginTop: '30px',
        width: '365px',
    },
    part2: {
        marginTop: '140px',
        marginBottom: '20px',
        width: '365px',
    },
    img: {
        margin: '0px',
        padding: '0px',
        width: '430px',
    },
}));
export const NewHomeThreePic = rst.create((ctx) => {
    const appProvider = AppProvider.use();
    const handleOpenPriceMap = () => {
        window.open(`${ROUTES.PriceMap.path}`, '_self');
    };

    function openRptDemo(num: string) {
        window.open(
            appProvider.getLocale() === locales.zhCN
                ? `${process.env.PUBLIC_URL}/assets/RptDemo/ReportDemo${num}-zh.pdf`
                : `${process.env.PUBLIC_URL}/assets/RptDemo/ReportDemo${num}-en.pdf`,
        );
    }
    function openWeekDemo(num: string) {
        window.open(
            appProvider.getLocale() === locales.zhCN
                ? `${process.env.PUBLIC_URL}/assets/WeekDemo/WeekReportDemo${num}-zh.pdf`
                : `${process.env.PUBLIC_URL}/assets/WeekDemo/WeekReportDemo${num}-en.pdf`,
        );
    }
    return (props) => {
        const classes = useStyles();

        return (
            <div style={{ background: 'rgb(255 255 255)' }}>
                <div className={classes.root}>
                    <div style={isMobileDevice() ? null : { paddingTop: '47px' }}>
                        <h2>
                            <TR name={'NewHome_ThreePicHeader'} />
                        </h2>
                    </div>
                    <Hidden mdUp>
                        <div className={classes.threePartsForMobile}>
                            <NewHomeSSPTPart
                                header="NewHome_ThreePicSubHeader1"
                                content="NewHome_ThreePicContent1"
                                color1="white"
                                color2="white"
                                // color1="#6E7EF2"
                                // color2="#5F95F2"
                                helpContent="NewHome_homeImgHelp"
                                onClick={handleOpenPriceMap}
                                img={appProvider.getLanguage() == 'zh' ? 'tp-1' : 'tp-1en'}
                            />
                            <NewHomeSSPTPart
                                header="NewHome_ThreePicSubHeader2"
                                content="NewHome_ThreePicContent2"
                                color2="white"
                                color1="white"
                                // color2="#86CCFF"
                                // color1="#6FA4FC"
                                onClick={() => openRptDemo('1')}
                                img={appProvider.getLanguage() == 'zh' ? 'tp-2' : 'tp-2en'}>
                                {/* <div
                                    style={{
                                        fontSize: '13px',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                    onClick={openRptDemo}>
                                    <TR name="RptDemo" />
                                </div> */}
                            </NewHomeSSPTPart>
                            <NewHomeSSPTPart
                                header="NewHome_ThreePicSubHeader3"
                                content="NewHome_ThreePicContent3"
                                color1="white"
                                color2="white"
                                // color1="#F86C62"
                                // color2="#FA8360"
                                img={appProvider.getLanguage() == 'zh' ? 'tp-3' : 'tp-3en'}
                                onClick={() => openWeekDemo('1')}
                            />
                        </div>
                    </Hidden>
                    <Hidden smDown>
                        <div className={classes.threeParts}>
                            <Tooltip arrow placement="right" title={<TRHtml html={'checkHousePriceMap'} />}>
                                <Box>
                                    <NewHomeThreePicItem
                                        img={appProvider.getLanguage() == 'zh' ? 'tp-1' : 'tp-1en'}
                                        header="NewHome_ThreePicSubHeader1"
                                        content="NewHome_ThreePicContent1"
                                        helpContent="NewHome_homeImgHelp"
                                        onClick={handleOpenPriceMap}
                                    />
                                </Box>
                            </Tooltip>
                            <Tooltip arrow placement="right" title={<TRHtml html={'viewReportSample'} />}>
                                <Box>
                                    <NewHomeThreePicItem
                                        img={appProvider.getLanguage() == 'zh' ? 'tp-2' : 'tp-2en'}
                                        onClick={() => openRptDemo('1')}
                                        header="NewHome_ThreePicSubHeader2"
                                        content="NewHome_ThreePicContent2">
                                        {/* <div
                                    style={{ fontSize: '15px', textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={openRptDemo}>
                                    <TR name="RptDemo" />
                                </div> */}
                                    </NewHomeThreePicItem>
                                </Box>
                            </Tooltip>
                            <NewHomeThreePicItem
                                img={appProvider.getLanguage() == 'zh' ? 'tp-3' : 'tp-3en'}
                                header="NewHome_ThreePicSubHeader3b"
                                content="NewHome_ThreePicContent3b"
                                cursorSet="auto"
                                // header="NewHome_ThreePicSubHeader3"
                                // content="NewHome_ThreePicContent3"
                                // onClick={() => openWeekDemo('1')}
                            />
                        </div>
                    </Hidden>
                </div>
            </div>
        );
    };
});

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { HelpComponent } from '../../PriceMap/components/HelpComponent';

export interface NewHomeSSPTPartProps {
    header: string;
    content: string;
    color1: string;
    color2: string;
    helpContent?: string;
    onClick?: () => void;
    img?: string;
}
const useStyles = makeStyles((theme) => ({
    root: (props: NewHomeSSPTPartProps) => {
        // const background = `${props.color2}`;
        const background = `linear-gradient(to right, ${props.color1}, ${props.color2})`;
        return {
            padding: '13px 15px',
            background,
            borderRadius: '4px',
            margin: '12px 0px',
            '& h3': {
                margin: '0px 0px 5px 0px',
                padding: '0xp',
                fontSize: '14px',
                lineHeight: '16px',
                // color: 'white',
            },
            '& p': {
                margin: '0px',
                padding: '0xp',
                fontSize: '13px',
                lineHeight: '18px',
                // color: 'rgb(212 210 246)',
                // color: 'rgb(212 210 246)',
            },
            '& img': {
                width: '100%',
                padding: '10px 0px 0px 0px',
            },
        };
    },
}));

export const NewHomeSSPTPart = rst.create<NewHomeSSPTPartProps>((ctx) => {
    return (props) => {
        const classes = useStyles(props);
        const { header, content, helpContent, onClick, img } = props;
        return (
            <div className={classes.root} onClick={onClick}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h3>
                        <TR name={header} />
                    </h3>
                    {helpContent ? <HelpComponent content={helpContent} maxWidth="xs" /> : null}
                </div>
                <p>
                    <TR name={content} />
                </p>
                {props.children}
                <div style={{ cursor: 'pointer' }}>
                    <img src={`${process.env.PUBLIC_URL}/static/images/${img}.png`} alt={img} />
                </div>
            </div>
        );
    };
});

import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import { TR } from '../../../../commons';
import { PopupComponentForLabel } from './../../../PriceMap/components/popupComponentForLabel';
import { divide, property } from 'lodash';
import { LandSize } from './../../../PriceMap/support/PriceMapConsts';
import { Landscape } from '@material-ui/icons';
import { changeToFirstCaptial, convertToM } from '../../../../commons/utils';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { rst } from 'rt-state';
import { clsx, formatDate, formatDateForEn, RRLink, SecurityProvider, storage, TR } from '../../../../commons';
import { TrialButton } from '../../../auth/UserAuthButton/TrialButton';
import { LoginOrSignupButton } from '../../../auth/UserAuthButton/LoginOrSignupButton';
import { AppProvider } from '../../../../commons/appProvider';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { MedianPrice } from './../../../Home/MedianPrice';
import { stgEnv } from './../../../../environments/stg';
import {
    api_estimatePropertyValue,
    api_getAroundTranscations,
    api_getPropertyFeatures,
} from '../../../../services/property';
import {
    constructPropObjFromFeature,
    getPropLastRecord,
    showGoogleResultByAddress,
} from '../../../PriceMap/support/propertyFeatureHelper';
import { queryProp_byGraphQL } from '../../../../commons/graphqlClient/graphAPI';
import { gridColumnsSelector } from '@material-ui/data-grid';
import { CardLine } from '../cardSeperate';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        // padding: '16px',
        // display: 'flex',
        // justifyContent: 'space-between',
    },
    price: {
        color: '#FC7909',
        fontSize: '18px',
        fontFamily: 'Roboto, Roboto-Bold',
        fontWeight: 700,
        minHeight: '25px',
        lineHeight: '28px',
        textAlign: 'left',
    },
    moreInfo: {
        fontSize: '14px',
        color: '#333330',
        // width: 62px;
        minHeight: '22px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        textAlign: 'left',
        lineHeight: '22px',
    },
    auctionContainer: {
        display: 'flex',
        color: '#E88633',
        minHeight: '24px',
        alignItems: 'center',
        border: '1px solid #e88633',
        borderRadius: '2px',
    },
    auction: {
        // width: 105px;
        minHeight: '18px',
        fontSize: '13px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        textAlign: 'left',
        color: '#e88633',
        lineHeight: '18px',
        paddingLeft: '10px',
        paddingRight: '11px',
    },
    address: {
        // color: '#000000',
        // fontSize: '14px',
        // fontSize: '6.5pt',
        // fontWeight: 'Regular',
        // width: '215px',
        minHeight: '18px',
        fontSize: '15px',
        fontFamily: 'Roboto, Roboto-Medium',
        fontWeight: 400,
        textAlign: 'left',
        color: 'rgba(0,0,0,0.85)',
        lineHeight: '18px',
    },
    title: {
        fontSize: '16px',
        fontWeight: 500,
    },
    label: {
        dispaly: 'flex',
        alighContent: 'flex-start',
        padding: '16px',
    },
    link: {
        display: 'flex',
        // maxwidth: '50px',
        // borderBottom: '1px dashed',
        // width: 81px;
        borderBottom: '1px solid #000000',
        minHeight: '18px',
        fontSize: '13px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        // textDecoration: 'underline',
        textAlign: 'left',
        color: '#666666',
        lineHeight: '18px',
        marginTop: '4px',
        marginBottom: '4px',
    },
    nonLink: {
        display: 'flex',
        alignItems: 'center',
        // maxwidth: '50px',
        // width: '170px',
        minHeight: '30px',
        border: '1px solid rgba(151,151,151,0.20)',
        borderRadius: '4px',
        padding: '5px',
        marginTop: '4px',
        marginBottom: '4px',
    },
    image: {
        width: '14px',
        minHeight: '14px',
    },

    linkShowing: {
        padding: '1.5px 3px',
        // color: '#333333',
        // width: 42px;
        minHeight: '18px',
        fontSize: '13px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        textAlign: 'left',
        color: '#333333',
        lineHeight: '18px',
    },

    smartTags: {
        padding: '1.5px 3px',
        minHeight: '18px',
        fontSize: '13px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        textAlign: 'left',
        color: '#666666',
        lineHeight: '18px',
        // width: 81px
    },

    labelLine1: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '4px 0',
    },
    labelLine3: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },

    button: {
        border: 'none',
        background: 'none',
        padding: 'none',
    },

    tableName: {
        fontSize: '11pt',
        fontWeight: 500,
        color: '#333333',
        marginTop: '3pt',
        marginBottom: '3pt',
    },
    tableRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    tableCell: {
        fontSize: '10pt',
        fontWeight: 400,
        color: '#333333',
        padding: '3pt 11pt 3pt 10pt',
        marginRight: '3pt',
        backgroundColor: '#F1F1F2',
        borderRadius: '5pt',
        width: 'fit-content',
    },
});

export const ComparableHouseLabel = (props) => {
    const { similarTrans, googleSearchEvt } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const trunckSmartTag = (key) => {
        return t(key).substring(0, 5) + '/';
    };
    return (
        <>
            {props.similarTrans.length == 0 ? (
                <div style={{ paddingLeft: 21, paddingTop: 18 }}>
                    <TR name="nothingtoshow"></TR>
                </div>
            ) : (
                <>
                    {props.similarTrans.map((loopEach) => (
                        <>
                            <div className={classes.label} key={loopEach.prop_id}>
                                <div className={classes.labelLine1}>
                                    <div className={classes.price}>
                                        {loopEach.sold_info == 'Passed in' ? (
                                            <div>
                                                <TR name="passedIn"></TR>
                                            </div>
                                        ) : loopEach.sold_info == 'Withdrawn' ? (
                                            <div>
                                                <TR name="withdraw"></TR>
                                            </div>
                                        ) : (
                                            <div>${convertToM(loopEach.sold_price)}M</div>
                                        )}
                                    </div>
                                    <div className={classes.moreInfo}>
                                        <button
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                            }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}>
                                                <div
                                                    onClick={() => {
                                                        props.googleSearchEvt(loopEach.prop_id);
                                                    }}
                                                    style={{
                                                        height: '22px',
                                                        fontSize: '14px',
                                                        fontFamily: 'PingFangSC, PingFangSC-Regular',
                                                        fontWeight: 400,
                                                        textAlign: 'left',
                                                        color: '#333330',
                                                        lineHeight: '22px',
                                                        paddingRight: '2px',
                                                    }}>
                                                    <TR name="googleSearch" />
                                                </div>
                                                <img
                                                    style={{ width: '14px', height: '14px', color: '#3d3d3d' }}
                                                    src="assets/mobileIcons/rightArrow.svg"></img>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <div className={classes.labelLine1}>
                                    <div className={classes.auctionContainer}>
                                        <div className={classes.auction}>
                                            {AppProvider.use().getLanguage() == 'zh'
                                                ? new Date(loopEach.sold_contract_date).toLocaleDateString('zh-ZH', {
                                                      year: 'numeric',
                                                      month: 'long',
                                                      day: 'numeric',
                                                  })
                                                : new Date(loopEach.sold_contract_date).toLocaleDateString('au-AU', {
                                                      year: 'numeric',
                                                      month: 'long',
                                                      day: 'numeric',
                                                  })}
                                            {'  '}
                                            {loopEach.sold_info === 'Sold' ? null : <TR name={loopEach.sold_info}></TR>}
                                        </div>
                                    </div>

                                    {/* <div className={classes.auction}></div> */}

                                    {/* <div className={classes.auction}></div> */}
                                </div>

                                <div className={classes.labelLine1}>
                                    <div className={classes.address}>{changeToFirstCaptial(loopEach.full_address)}</div>
                                    {loopEach.smart_tags && (
                                        <PopupComponentForLabel
                                            link={
                                                <div className={classes.link}>
                                                    {!loopEach.smart_tags
                                                        ? ''
                                                        : loopEach.smart_tags
                                                              .filter((smart_tag) => smart_tag.selected === 1)
                                                              .slice(0, 3)
                                                              .map((stFeat) => {
                                                                  return <>{trunckSmartTag(stFeat.tag_name)}</>;
                                                              })}
                                                    {'...'}
                                                </div>
                                            }
                                            content=""
                                            title={<TR name={'smartTags'}></TR>}
                                            table={
                                                <div>
                                                    <p className={classes.tableName}>{}</p>
                                                    <div className={classes.tableRow}>
                                                        {!loopEach.smart_tags
                                                            ? ''
                                                            : loopEach.smart_tags
                                                                  .filter((smart_tag) => smart_tag.selected === 1)
                                                                  .map((stFeat) => (
                                                                      <>
                                                                          <p
                                                                              className={classes.tableCell}
                                                                              key={stFeat.id}>
                                                                              <TR name={stFeat.tag_name} />
                                                                          </p>
                                                                      </>
                                                                  ))}
                                                    </div>
                                                </div>
                                            }
                                            maxWidth="xl"
                                        />
                                    )}
                                </div>

                                <div className={classes.labelLine3}>
                                    <div className={classes.nonLink}>
                                        <div className={classes.image}>
                                            <img src="assets/mobileIcons/ws-ico@1x.svg"></img>
                                        </div>
                                        <div className={classes.linkShowing}>{loopEach.prop_bed || '-'}</div>
                                        <div className={classes.image}>
                                            <img src="assets/mobileIcons/my-ico@1x.svg"></img>
                                        </div>
                                        <div className={classes.linkShowing}>{loopEach.prop_bath || '-'}</div>
                                        <div className={classes.image}>
                                            <img src="assets/mobileIcons/cz-ico@1x.svg"></img>
                                        </div>
                                        <div className={classes.linkShowing}>{loopEach.prop_carpark || '-'}</div>
                                        <div className={classes.image}>
                                            <img src="assets/mobileIcons/mj-ico.svg"></img>
                                        </div>
                                        <div className={classes.linkShowing}>
                                            {new Intl.NumberFormat().format(loopEach.land_size.toFixed(0))}&#x33A1;{' '}
                                        </div>
                                    </div>

                                    <div style={{ marginLeft: 8 }}></div>
                                    <div className={classes.nonLink}>
                                        <div className={classes.linkShowing}>
                                            <TR name="land/zoning" />
                                        </div>
                                        <div className={classes.linkShowing}>
                                            ${convertToM(loopEach.land_value)}m/{loopEach.prop_zoning}
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 8 }}></div>
                                    <div className={classes.nonLink}>
                                        <div className={classes.linkShowing}>
                                            <TR name="distanceToProperty" />
                                        </div>
                                        <div className={classes.linkShowing}>
                                            {new Intl.NumberFormat().format(loopEach.distance?.toFixed(2))}km
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CardLine></CardLine>
                        </>
                    ))}
                </>
            )}
        </>
    );
};

import { env } from '../environments';

const custEnv: any = env;

const APIHOST = custEnv.apiHost + '/';
const USER_API_HOST = custEnv.USER_API_HOST + '/';
export const MAP_APIS_ENDPOINTS = {
    GET_SUBURBIDS_IN_MAPVIEW: APIHOST + 'LocalityIntersection',
    GET_MAPBORDERS: APIHOST + 'localityfilter/map',
    GET_MARKERS: APIHOST + 'getMarkerPositions',
    GET_SUBURBDATA_IN_MAPVIEW: APIHOST + 'LocalityMedian',
    GET_SOLDPROPERTIES_IN_MAPVIEW: APIHOST + 'LocalitySold',
    SEARCH_ADDRESS_BY_KEY: APIHOST + 'AutoCompleteAddress',
    GET_PROPERTY_DETAILS: APIHOST + 'EstimationFeatures?prop_id=', // return specified property features
    GET_AROUND_SOLD_PROPERTIES:
        APIHOST +
        'Adjacent?distance=${distance}km&lon=${lon}&lat=${lat}&interval=${interval}m&prop_type=${prop_type}&prop_id=${prop_id}&report_house_value=${reportHouseValue}&prop_bed=${prop_bed}&prop_carpark=${prop_car}&prop_bath=${prop_bath}&sort_by=${sort_by}&start=${start}&end=${end}',
    GET_ROUTE_FROM_GOOGLE: APIHOST + 'map-api-wrapper?origin=${start_latlng}&destination=${end_latlng}&mode=walking',
    ESTIMATE_PROPERTY_VALUE: APIHOST + 'price/predict?${allFeatureParams}',
    GET_ALL_PROPERTIES_MAP_VIEW: APIHOST + 'property-on-map',
    GET_SUBURB_BY_POINT: APIHOST + 'point-to-locality?lat=${lat}&lon=${lng}',
    GET_STREET_RANK_BY_SUBURB: APIHOST + 'street-ranking?localityId=${localityId}',

    PROPERTY_GRAPHQL: APIHOST + 'graphql',
    SEARCH_STREET_BY_KEY: APIHOST + 'AutoCompleteStreet',
    GET_PROJECT_SOLDS: APIHOST + 'project/recentSold?project_id=${projectId}&interval=${interval}m',
    SEARCH_LOCALITY_BY_STRING: APIHOST + 'AutoCompleteLocality?locality_strings=${queryKey}',
    LOG_USER_ACTIVITY: USER_API_HOST + 'user/api/user/log',
    GET_SELECTION_LISTS: USER_API_HOST + 'user/api/selections/list?code=${list_code}',
    SAVE_USER_DETAILS: USER_API_HOST + 'user/api/auth/signup/saveUserDetails',
    REQUEST_PHONE_VERIFYCODE: USER_API_HOST + 'user/api/auth/signup/verifyPhone?phone=${phone}',
    CONFIRMASMEMBER: USER_API_HOST + 'user/api/auth/confirmAsMember',
    CANCELMEMBER: USER_API_HOST + 'user/api/auth/cancelMember',
    RESEND_VERIFY_EMAIL: USER_API_HOST + 'user/api/auth/resendVerifyEmail',
    COUNT_DAILY_SEARCH: USER_API_HOST + 'user/api/userCenter/searchHistory/userExceedLimit',
    CHANGE_MEMBER_SUB_TYPE: USER_API_HOST + 'user/api/auth/changeMemberSubType',
    UPDATE_AREA_MEMBER_CARE_SUBURBS: USER_API_HOST + 'user/api/auth/updateAreaMemberCareSuburbs',
    RECORD_USER_ERROR: USER_API_HOST + 'user/api/auth/recordUserError',
    GET_PHOTO_REF_FOR_SUBURB: USER_API_HOST + 'user/api/googleApi/suburbPhoto',
};
export const setEndpointParams = (endPoint: string, params: any) => {
    Object.keys(params).map((loopKey) => {
        const replacingStr = '${' + loopKey + '}';

        if (!params[loopKey]) {
            endPoint = endPoint.replace('&' + loopKey + '=${' + loopKey + '}', '');
        }
        endPoint = endPoint.replace(replacingStr, params[loopKey]);
    });
    return endPoint;
};

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons/i18n/TR';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            background: 'rgb(250 252 255)',
            border: '1px solid rgb(236 239 246)',
            borderRadius: '5px',
            padding: '10px',
            margin: '5px 0px',
            '& h2': {
                fontSize: '14px',
                margin: '0px 0px 8px 0px',
                color: '#B43B3B',
                background: 'linear-gradient(to bottom, rgb(250 252 255) 70%, rgb(249 235 237) 0%)',
                width: 'fit-content',
            },
            '& span': {
                fontSize: '13px',
                color: '#848383',
            },
        },
        clickHeader: {
            display: 'flex',
            justifyContent: 'flex-start',
            cursor: 'pointer',
            '& img': {
                paddingLeft: '10px',
            },
        },
    };
});

export const Tips = rst.create<{
    content?: string;
    header?: string;
    isSelect?: boolean;
    selectState?: boolean;
    onClick?: () => void;
}>((ctx) => {
    const selectState = rst.stateS(false);
    const handleClick = () => {
        selectState.value = !selectState.value;
    };
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                {props.header ? (
                    <div className={classes.clickHeader} onClick={handleClick}>
                        <h2>
                            <TR name={props.header} />
                        </h2>
                        {props.isSelect ? (
                            <img
                                src={
                                    !selectState.value
                                        ? 'assets/icons/arrow-lower-ico.svg'
                                        : 'assets/icons/arrow-lupper-ico.svg'
                                }
                            />
                        ) : null}
                    </div>
                ) : null}
                {props.isSelect ? (
                    selectState.value ? (
                        <>
                            <span>
                                <TR name={props.content} />
                            </span>
                            {props.children ?? null}
                        </>
                    ) : null
                ) : (
                    <>
                        <span>
                            <TR name={props.content} />
                        </span>
                        {props.children ?? null}
                    </>
                )}
            </div>
        );
    };
});

import * as React from 'react';
import { withRouter } from 'react-router';
import { StyleDemo } from './StyleDemo';
import { MixedFieldDemo } from './MixedfieldDemo';
import { CascadingSelectDemo } from './CascadingSelectDemo';
import { DrawerDemo } from './DrawerDemo';
import { LanguageDemo } from './LanguageDemo';
import { FormSearchCard } from './SearchCardDemo';
import { TabDemo } from './TabDemo';
import { XTableDemo } from '../../components/Table/demo/TableDemo';

// export const DemoPage = withRouter(Demo1);
// export const DemoPage = FormSearchCard;
// export const DemoPage = DrawerDemo;
// export const DemoPage = StyleDemo;
export const DemoPage = LanguageDemo;
// export const DemoPage = FormSearchCard;
// export const DemoPage = FormDemo;

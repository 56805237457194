import React from 'react';

export const AwesomeIcon = (props: any) => {
    const { icon, clickEvt, style } = props;
    return (
        <i
            onClick={clickEvt}
            // className={icon}
            style={{
                clear: 'both',
                ...style,
            }}>
            {icon}
        </i>
    );
};

import { Box, Checkbox, Container, Icon, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { FloatLayer } from '../../../components/FloatLayer';
import { TR } from '../../../commons/i18n';
import { ArrowForwardIos } from '@material-ui/icons';
import { Animated } from 'react-animated-css';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { MapController } from './controller/MapController';

const iconControllerStyle = {
    top: '100px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row' as 'row',
    background: 'transparent',
};
const testDivStyle = {
    color: 'red',
};

const useStyles = makeStyles((theme) => {
    return {
        leftSide: {
            width: '131px',
            borderRadius: '10px',
            boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.16)',
            backgroundColor: '#ffffff',
            zIndex: 1,
            padding: '20px 0px 0px 20px',
        },
        rightSide: {
            width: '131px',

            borderRadius: '10px',
            boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.16)',
            marginLeft: '10px',
            background: 'white',
            padding: '10px',
            zIndex: -1,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        arrow: {
            marginLeft: '10px',
            color: 'blue',
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        iconItem: {
            fontSize: '12px',
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#495b6b',
            marginTop: '5px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        iconCheck: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            height: '15px',
        },
    };
});

const IconControlItem = rst.create<{ iconItem: IconItem; changeEvent: any }>((props) => {
    return (props) => {
        const classes = useStyles();
        const { iconItem: item, changeEvent } = props;
        return (
            <div className={classes.iconItem}>
                <Typography>
                    <TR name={item.label} />
                </Typography>
                <Checkbox
                    className={classes.iconCheck}
                    onChange={changeEvent}
                    id={'iconType_' + item.type}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                />
            </div>
        );
    };
});

export interface MapIconEvents {
    specialIconEvents?: any;
    favEvent?: any;
    addCustomeAddress?: any;
}
interface IconControllerProps {
    iconEvents: MapIconEvents;
}

export const MarkerTypes = {
    cementry: {
        label: 'CEMENTRY',
        type: 1,
        icon: 'grave.svg',
    },
    fuelStation: {
        label: 'PENTROL STATION',
        type: 2,
        icon: 'fuel-station.svg',
    },
    mosque: {
        label: 'TEMPLATE',
        type: 3,
        icon: 'mosque.svg',
    },
    telecomTower: {
        label: 'TELECOM',
        type: 4,
        icon: 'tower.svg',
    },
    church: {
        label: 'CHURCH',
        type: 2,
        icon: 'church.svg',
    },
};
export const MapIconsController = rst.create<IconControllerProps>((ctx) => {
    const showingMoreIcons = rst.stateS(false);

    const toggleMoreIcons = () => {
        showingMoreIcons.value = !showingMoreIcons.value;
    };
    return (props) => {
        const classes = useStyles();
        const { iconEvents } = props;
        const getIconControls = () => {
            const returnElements = [];
            Object.keys(MarkerTypes).forEach((key) => {
                returnElements.push(
                    <IconControlItem
                        changeEvent={iconEvents.specialIconEvents}
                        iconItem={MarkerTypes[key]}></IconControlItem>,
                );
            });
            return returnElements;
        };
        return (
            <FloatLayer style={iconControllerStyle}>
                <div className={classes.leftSide}>
                    <div onClick={toggleMoreIcons} className={classes.iconItem}>
                        <Typography>
                            <TR name={'SPECIAL ICON'} />
                        </Typography>
                        <div className={classes.arrow}>
                            <ArrowForwardIos></ArrowForwardIos>
                        </div>
                    </div>

                    <div onClick={toggleMoreIcons} className={classes.iconItem}>
                        <Typography>
                            <TR name={'FAVORATE HOUSE'} />
                        </Typography>
                    </div>

                    <div onClick={toggleMoreIcons} className={classes.iconItem}>
                        <Typography>
                            <TR name={'ADD ADDRESS'} />
                        </Typography>
                    </div>
                </div>
                <Animated
                    animationIn="slideInLeft"
                    animationOut="slideOutLeft"
                    animationInDuration={1000}
                    animationOutDuration={1000}
                    isVisible={showingMoreIcons.value}>
                    <div className={classes.rightSide}>{getIconControls()}</div>
                </Animated>
            </FloatLayer>
        );
    };
});

interface IconItem {
    label: string;
    type: number;
    icon: string;
    selected?: boolean;
}

import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { rst } from 'rt-state';
import { XDialogTitle } from './XDialogTitle';
import { XBaseDialog, XBaseDialogProps } from './XBaseDialog';
import { XForm } from '../form';
import Dialog from '@material-ui/core/Dialog';

export interface XDialogClass {
    title?: string;
    button?: string;
}

export interface XDialogProps extends XBaseDialogProps {
    title?: string;
    form?: XForm;
    dialogClasses?: XDialogClass;
    onCancel?: () => void;
    onConfirm?: () => void;
    onReset?: () => void;
}

export const XDialog = rst.create<XDialogProps>((ctx) => {
    const handleCancel = () => {
        ctx.props.onCancel?.();// eslint-disable-line
    };

    const handleConfirm = async () => {
        const { onConfirm, form } = ctx.props;
        const valid = await form.handleSubmit?.(); //eslint-disable-line
        if (valid) {
            onConfirm?.(); //eslint-disable-line
        }
    };

    const handleReset = () => {
        const { onReset, form } = ctx.props;
        form?.resetForm(); //eslint-disable-line
        onReset?.(); //eslint-disable-line
    };

    return (props) => {
        const { open, dialogClasses } = props;

        return (
            <XBaseDialog
                open={open}
                dialogProps={{ disableBackdropClick: true, disableEscapeKeyDown: true, keepMounted: true }}>
                {props.title && (
                    <XDialogTitle
                        onReset={handleReset}
                        onCancel={handleCancel}
                        className={dialogClasses.title}
                        title={props.title}
                    />
                )}
                <DialogContent>{props.children}</DialogContent>

                <DialogActions>
                    <Button
                        variant={'contained'}
                        className={dialogClasses.button}
                        onClick={handleConfirm}
                        color="primary">
                        确定
                    </Button>
                </DialogActions>
            </XBaseDialog>
        );
    };
});

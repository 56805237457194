import { FilterOption } from '../../../models/filterOption';

export const priceFilters: FilterOption[] = [
    {
        label: 'houseMedian',
        tooltip: 'Recent half year median house price tooltip',
        value: 'localityHouseMedian',
        fieldName: 'houseMedian',
        defaultValue: [50, 80],
        // Todo : need to get definition from business
        grades: [{}],
    },
    {
        label: 'apartMedian',
        tooltip: 'Recent half year median apartment price tooltip',
        value: 'localityAptMedian',
        fieldName: 'apartMedian',
        defaultValue: [20, 50],
        // Todo : need to get definition from business
        grades: [{}],
    },
    {
        label: 'apartRental',
        tooltip: 'Recent half year median apartment rental tooltip',
        value: 'localityAptWeeklyRent',
        fieldName: 'apartmentRental',
        defaultValue: '1',
        // Todo : need to get definition from business
        grades: [
            { value: '1', label: '小于400' },
            { value: '2', label: '400-100' },
            { value: '3', label: '1000-2000' },
            { value: '4', label: '2000以上' },
        ],
    },
    {
        label: 'rentalProfit',
        tooltip: 'Recent half year rental profilt tooltip',
        value: 'localityAptReturn',
        fieldName: 'rentalProfilt',
        // Todo : need to get definition from business
        grades: [
            { value: '1', label: '小于4%' },
            { value: '2', label: '4%-10%' },
            { value: '3', label: '大于10%' },
        ],
    },
];

export const schoolFilters: FilterOption[] = [
    {
        label: 'PrimarySchool',
        tooltip: 'primary school tooltip',
        value: '1',
        fieldName: 'primarySchool',
        defaultValue: '1',
        grades: [
            {
                min: 0,
                max: 89,
                label: '90分以下',
                color: 'red',
                value: '1',
            },
            {
                min: 90,
                max: 95,
                label: '90-95分',
                color: 'green',
                value: '2',
            },
            {
                min: 96,
                max: 97,
                label: '96-97分',
                color: 'black',
                value: '3',
            },
            {
                min: 98,
                max: 99,
                label: '98-99分',
                color: '',
                value: '4',
            },
            {
                min: 100,
                max: 100,
                label: '100分',
                color: '',
                value: '5',
            },
        ],
    },
    {
        label: 'Primary English Family',
        tooltip: null,
        value: '2',
        defaultValue: '2',
        fieldName: 'primaryEnglish',
        grades: [
            {
                min: 0,
                max: 9,
                color: '',
                label: '小于10%',
                value: '1',
            },
            {
                min: 10,
                max: 19,
                color: '',
                label: '10-20%',
                value: '2',
            },
            {
                min: 20,
                max: 39,
                color: '',
                label: ' 20-40%',
                value: '3',
            },
            {
                min: 40,
                max: 100,
                color: '',
                label: '40%以上',
                value: '4',
            },
        ],
    },
    {
        label: '中学排名',
        tooltip:
            '中学的星级评定是HSC分数和Better Education的评估相结合，展示悉尼155所划片招生的公立中学最近3年的综合分数',
        value: '3',
        defaultValue: '2',
        fieldName: 'middleSchoolRank',
        grades: [
            {
                label: '很好（前100名）',
                value: '1',
            },
            {
                label: '较好（101-180名）',
                value: '2',
            },
            {
                label: '一般（181-235名）',
                value: '3',
            },
            {
                label: '无排名',
                value: '4',
            },
        ],
    },
    {
        label: '私立(混+男)',
        tooltip: 'Suburb及周边5公里内私立学校数量',
        value: '4',
        fieldName: 'privateSchoolBoy',
        grades: [
            {
                label: '多个',
                value: '1',
            },
            {
                label: '1个',
                value: '2',
            },
            {
                label: '无',
                value: '3',
            },
        ],
    },
    {
        label: '私立(混+女)',
        tooltip: null,
        value: '5',
        fieldName: 'privateSchooGirl',
        grades: [
            {
                label: '多个',
                value: '1',
            },
            {
                label: '1个',
                value: '1',
            },
            {
                label: '无',
                value: '1',
            },
        ],
    },
];

export const residentFilters: FilterOption[] = [
    {
        label: '治安',
        tooltip: null,
        value: '1',
        fieldName: 'safeRank',
        grades: [
            {
                label: '1星',
                value: '1',
            },
            {
                label: '2星',
                value: '2',
            },
            {
                label: '3星',
                value: '3',
            },
            {
                label: '4星',
                value: '4',
            },
            {
                label: '5星',
                value: '5',
            },
        ],
    },
    {
        label: '华人占比',
        tooltip: '在中国出生的居民占比',
        value: 'localityChinese',
        fieldName: 'chineseImmPct',
        grades: [
            { min: 11.76, max: 100, label: '很多', value: '1' },

            { min: 8.82, max: 11.76, label: '较多', value: '2' },
            { min: 5.88, max: 8.82, label: '一般', value: '3' },
            { min: 2.94, max: 5.88, label: '较少', value: '4' },
            { min: 0, max: 2.94, label: '很少', value: '5' },
        ],
    },
    {
        label: '印度教占比',
        tooltip: null,
        value: 'localityHinduism',
        fieldName: 'IndianImmPct',
        grades: [
            { min: 11.76, max: 100, label: '很多', value: '1' },

            { min: 8.82, max: 11.76, label: '较多', value: '2' },
            { min: 5.88, max: 8.82, label: '一般', value: '3' },
            { min: 2.94, max: 5.88, label: '较少', value: '4' },
            { min: 0, max: 2.94, label: '很少', value: '5' },
        ],
    },
    {
        label: '伊斯兰教占比',
        tooltip: null,
        value: 'localityIslam',
        fieldName: 'islamImmPct',
        grades: [
            { min: 11.76, max: 100, label: '很多', value: '1' },

            { min: 8.82, max: 11.76, label: '较多', value: '2' },
            { min: 5.88, max: 8.82, label: '一般', value: '3' },
            { min: 2.94, max: 5.88, label: '较少', value: '4' },
            { min: 0, max: 2.94, label: '很少', value: '5' },
        ],
    },
    {
        label: '租客占比',
        tooltip: null,
        value: 'localityRenter',
        fieldName: 'rentPct',
        grades: [
            { min: 11.76, max: 100, label: '小于20%', value: '1' },

            { min: 8.82, max: 11.76, label: '20-40%', value: '2' },
            { min: 5.88, max: 8.82, label: '40-60%', value: '3' },
            { min: 2.94, max: 5.88, label: '大于60%', value: '4' },
        ],
    },
];

export const transportFilters: FilterOption[] = [
    {
        label: '火车站',
        tooltip: null,
        value: '1',
        fieldName: 'traffic',
        grades: [
            {
                label: '本区有火车站',
                value: '1',
            },
            {
                label: '有轻轨',
                value: '2',
            },
            {
                label: '无火车站和轻轨',
                value: '3',
            },
        ],
    },
    {
        label: 'CBD距离',
        tooltip: null,
        value: '2',
        fieldName: 'distanceToCBD',
        grades: [
            {
                label: '小于5公里',
                value: '1',
            },
            {
                label: '小于10公里',
                value: '2',
            },
            {
                label: '小于15公里',
                value: '3',
            },
            {
                label: '小于25公里',
                value: '4',
            },
            {
                label: '大于25公里',
                value: '5',
            },
        ],
    },
    {
        label: 'CBD公交时间',
        tooltip: '高峰时段，公共交通到CBD的时间',
        value: '3',
        fieldName: 'publicTransportTOCBD',
        grades: [
            {
                label: '30分钟以内',
                value: '1',
            },
            {
                label: '30-45分钟',
                value: '2',
            },
            {
                label: '45-60分钟',
                value: '3',
            },
            {
                label: '1小时以上',
                value: '4',
            },
        ],
    },
    {
        label: '自定义距离',
        tooltip: null,
        value: '4',
        fieldName: 'customizeDis',
        grades: [
            {
                label: '小于5公里',
                value: '1',
            },
            {
                label: '5-10公里',
                value: '2',
            },
            {
                label: '10-15公里',
                value: '3',
            },
            {
                label: '大于15公里',
                value: '4',
            },
        ],
    },
];

export const lifeFilters: FilterOption[] = [
    {
        label: '购物',
        tooltip: null,
        value: '1',
        fieldName: 'shoppingRank',
        grades: [
            {
                label: '1星',
                value: '1',
            },
            {
                label: '2星',
                value: '2',
            },
            {
                label: '3星',
                value: '3',
            },
            {
                label: '4星',
                value: '4',
            },
            {
                label: '5星',
                value: '5',
            },
        ],
    },
    {
        label: '树木覆盖',
        tooltip: null,
        value: '2',
        fieldName: 'forestRank',
        grades: [
            {
                label: '1星',
                value: '1',
            },
            {
                label: '2星',
                value: '2',
            },
            {
                label: '3星',
                value: '3',
            },
            {
                label: '4星',
                value: '4',
            },
            {
                label: '5星',
                value: '5',
            },
        ],
    },
    {
        label: '亲水得分',
        tooltip: null,
        value: '3',
        fieldName: 'waterRank',
        grades: [
            {
                label: '1星',
                value: '1',
            },
            {
                label: '2星',
                value: '2',
            },
            {
                label: '3星',
                value: '3',
            },
            {
                label: '4星',
                value: '4',
            },
            {
                label: '5星',
                value: '5',
            },
        ],
    },
];

export const ALL_FILTER_OPTIONS = {
    1: priceFilters,
    2: schoolFilters,
    3: residentFilters,
    4: transportFilters,
    5: lifeFilters,
};

import { rst } from 'rt-state';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { XTableColumn } from './common';

export const XTableRowComp = rst.create<{
    row: any;
    rowKey?: string;
    handleRowClick: (row: any, event: React.MouseEvent<any>) => void;
    columns: XTableColumn[];
}>((ctx) => {
    function _onClick(event) {
        event.stopPropagation();
        ctx.props.handleRowClick(ctx.props.row, event);
    }
    return (props) => {
        const { row, columns } = props;
        return (
            <TableRow hover onClick={_onClick} tabIndex={-1}>
                {columns.map((col, colIdx) => {
                    return (
                        <TableCell
                            key={colIdx}
                            component="th"
                            id={`enhanced-table-cell-${colIdx}`}
                            scope="row"
                            align={col.align}
                            padding="default">
                            {col.render?.(row) ?? row[col.name]}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    };
});

import { isMobileDevice } from '../../../commons/utils';
import { getThemeValue } from './PriceMapTheme';

export const MapType = {
    PLAIN: 'PLAIN',
    STA: 'STA',
};

export enum SuburbColorCatagory {
    PS,
    HS,
}

export const MapGlobalVars = {
    wholeMapDataLoaded: false,
    showingSuburbPop: false,
    showingPropertyPop: false,

    shouldStayOnPropertyLevel: false,
    suburbSearchInputShowing: false,
    clickedSuburbPolygon: null,
    clickedSuburbData: null,
    priceMapFilter: {
        period: 6,
        suburbId: '',
        suburbName: '',
        propertyType: 'House',
        bedOptions: [],
        landSize: { min: 0, max: 999999999 },
        projectScale: 999,
        buildingAge: 999,
    },
    currentMapType: MapType.PLAIN,
    currentMapZoom: 0,
    showAroundTransCircle: false,
    nearByTransEventName: '',
    transShowingSuburbData: null,
    showSuburbTransConfirm: false,
    showingMapPopmenu: false,
    showFavOnMap: false,
    currentUserLan: '',
    currentClickedPropId: '',
    currentMapCatagory: 'medianPrice',
    currentPropertyEstimate: null,
    showPropertyPriceAsRef: false,

    sectorMontlyData: null,
};

/*
    activityTypes : 
*/

export const AllStaticVarsFromBack = [];

export const MapInitParam = {
    zoom: isMobileDevice() ? 10 : 11,
    center: isMobileDevice() ? [-33.82957080595755, 151.08793258666995] : [-33.8611, 151.203],
    propertyType: 'House',
    bounds: {
        corner1: [-33.320200993413884, 152.24853515625003],
        corner2: [-34.29126107845569, 150.34790039062503],
        corner3: [-34.569906380856345, 150.3355407714844], // for mobile
        corner4: [-33.826700960286786, 151.07574462890625], //for mobile homepage
    },
};

export const MapLevel = {
    suburbLevel: {
        lower: 10,
        upper: 13,
    },
    propertyLevel: {
        lower: 14,
        mid: 16,
        upper: 19,
    },
};

export const HoverSuburbPolygonStyle = {
    fillOpacity: 0.5,
};

export const AroundTransPropColor = {
    bed0: {
        color: '#2ECAF3',
    },
    bed3: {
        color: '#62DE70',
    },
    bed4: {
        color: '#B676E8',
    },
    bed5: {
        color: '#F3A42E',
    },
};

export const mapCatagoryColors = ['#2E75B6', '#9DC3E6', '#FBC289', '#F99B3D', '#C00000'];

export const SuburbPriceColorsLabel = {
    medianPrice: { key: 'House', label: 'SUBURB_LEGEND_TITLE' },
    House: { key: 'House', label: 'SUBURB_LEGEND_TITLE' },
    Apt: { key: 'Apt', label: 'MedianPrice-Apt' },
    rate_primary: { key: 'rate_primary', label: 'Primary School' },
    score_secondary: { key: 'score_secondary', label: 'Secondary School' },
    rate_private_boys: { key: 'rate_private_boys', label: 'PrivateSchoolMixBoy' },
    rate_private_girls: { key: 'rate_private_girls', label: 'PrivateSchoolMixGirl' },
    rental_pct: { key: 'rental_pct', label: 'Tenant Proportion' },
    // train_station: { key: 'train_station', label: 'Transport' },
    chinese: { key: 'chinese', label: 'Chinese Immi Pct Pop' },
    hinduism_p_pct: { key: 'hinduism_p_pct', label: 'Indian Immi Pct Pop' },
    islam_p_pct: { key: 'islam_p_pct', label: 'Islam Immi Pct Pop' },
    family_income: { key: 'family_income', label: 'Family Income' },
    house_pct: { key: 'house_pct', label: 'House/Apartment' },
    rate_public_security: { key: 'rate_public_security', label: 'Public Security' },
    rate_waterfront: { key: 'rate_waterfront', label: 'Waterscape Onside' },
    rate_tree_cover: { key: 'rate_tree_cover', label: 'Trees' },
    rate_shopping: { key: 'rate_shopping', label: 'Area Business' },
    median_lz: { key: 'median_lz', label: 'Median lot size' },
    PriceChange3M: { key: 'PriceChange3M', label: 'House price changes (last 3 months)' },
    PriceChange6M: { key: 'PriceChange6M', label: 'House price changes (last 6 months)' },
};
export const SuburbPriceColors = {
    Apt: [
        {
            max: 0.6,
            color: '#2E75B6',
            first: true,
        },
        {
            min: 0.6,
            max: 0.8,
            color: '#9DC3E6',
        },
        {
            min: 0.8,
            max: 1,
            color: '#F99B3D',
        },
        {
            min: 1,
            max: 1.3,
            color: '#F99B3D',
        },
        {
            min: 1.3,
            color: '#C00000',
            last: true,
        },
    ],

    House: [
        {
            max: 1,
            color: '#2E75B6',
            first: true,
        },
        {
            min: 1,
            max: 1.5,
            color: '#9DC3E6',
        },

        {
            min: 1.5,
            max: 2,
            color: '#FBC289',
        },
        {
            min: 2,
            max: 2.8,
            color: '#F99B3D',
        },
        {
            min: 2.8,
            color: '#C00000',
            last: true,
        },
    ],

    rate_primary: [
        {
            max: '<90',
            center: true,
            color: '#2E75B6',
            first: true,
        },
        {
            max: '90-95',
            center: true,
            color: '#9DC3E6',
        },

        {
            max: '96-97',
            center: true,
            color: '#FBC289',
        },
        {
            max: '98-99',
            center: true,
            color: '#F99B3D',
        },
        {
            max: '100',
            center: true,
            color: '#C00000',
            last: true,
        },
    ],
    score_secondary: [
        {
            max: '<90',
            center: true,
            color: '#9DC3E6',
        },
        {
            max: '90-95',
            center: true,
            color: '#FBC289',
        },
        {
            max: '96-100',
            center: true,
            color: '#F99B3D',
            last: true,
        },
    ],
    rate_private_boys: [
        {
            max: 'None Private School',
            center: true,
            color: '#9DC3E6',
        },
        {
            max: 'One Private School',
            center: true,
            color: '#FBC289',
        },
        {
            max: 'Multi-Private School',
            center: true,
            color: '#F99B3D',
            last: true,
        },
    ],
    rate_private_girls: [
        {
            max: 'None Private School',
            center: true,
            color: '#9DC3E6',
        },
        {
            max: 'One Private School',
            center: true,
            color: '#FBC289',
        },
        {
            max: 'Multi-Private School',
            center: true,
            color: '#F99B3D',
            last: true,
        },
    ],
    rental_pct: [
        {
            max: '20%',
            color: '#9DC3E6',
        },

        {
            max: '40%',
            color: '#FBC289',
        },
        {
            max: '60%',
            color: '#F99B3D',
        },
        {
            color: '#C00000',
            last: true,
        },
    ],
    // train_station: [
    //     {
    //         max: 'None',
    //         center: true,
    //         color: '#fdf17b',
    //         first: true,
    //     },
    //     {
    //         max: 'HAVE_TRAIN',
    //         center: true,
    //         color: '#ffd700',
    //     },
    // ],
    chinese: [
        {
            max: '3%',
            color: '#9DC3E6',
        },

        {
            max: '8%',
            color: '#FBC289',
        },
        {
            max: '20%',
            color: '#F99B3D',
        },
        {
            color: '#C00000',
            last: true,
        },
    ],
    hinduism_p_pct: [
        {
            max: '3%',
            color: '#9DC3E6',
        },

        {
            max: '8%',
            color: '#FBC289',
        },
        {
            max: '20%',
            color: '#F99B3D',
        },
        {
            color: '#C00000',
            last: true,
        },
    ],
    islam_p_pct: [
        {
            max: '3%',
            color: '#9DC3E6',
        },

        {
            max: '8%',
            color: '#FBC289',
        },
        {
            max: '20%',
            color: '#F99B3D',
        },
        {
            color: '#C00000',
            last: true,
        },
    ],

    family_income: [
        {
            max: '30k',
            color: '#2E75B6',
            first: true,
        },
        {
            max: '80k',
            color: '#9DC3E6',
        },

        {
            max: '130k',
            color: '#FBC289',
        },
        {
            max: '200k',
            color: '#F99B3D',
        },
        {
            color: '#C00000',
            last: true,
        },
    ],
    house_pct: [
        {
            max: '20%',
            color: '#9DC3E6',
        },

        {
            max: '50%',
            color: '#FBC289',
        },
        {
            max: '80%',
            color: '#F99B3D',
        },
        {
            color: '#C00000',
            last: true,
        },
    ],

    rate_public_security: [
        {
            max: 'Security Worst',
            color: '#C00000',
            center: true,
        },
        {
            max: 'Security Worse',
            color: '#F99B3D',
            center: true,
        },

        {
            max: 'Security OK',
            color: '#FBC289',
            center: true,
        },
        {
            max: 'Security Good',

            color: '#9DC3E6',
            center: true,
        },
        {
            max: 'Security Very Good',

            color: '#2E75B6',
            center: true,
        },
    ],
    rate_waterfront: [
        {
            max: 'Waterscape Far',
            color: '#9DC3E6',
            center: true,
        },

        {
            max: 'Waterscape Drive',
            color: '#FBC289',
            center: true,
        },
        {
            max: 'Waterscape Near',
            color: '#F99B3D',
            center: true,
        },
        {
            max: 'Waterscape Onside',
            color: '#C00000',
            center: true,
        },
    ],
    rate_tree_cover: [
        {
            max: 'Tree Few',
            color: '#9DC3E6',
            center: true,
        },

        {
            max: 'Tree Normal',
            color: '#FBC289',
            center: true,
        },
        {
            max: 'Tree More',
            color: '#F99B3D',
            center: true,
        },
        {
            max: 'Tree Most',
            color: '#C00000',
            center: true,
        },
    ],
    rate_shopping: [
        {
            max: 'Business Few',
            color: '#9DC3E6',
            center: true,
        },

        {
            max: 'Business Shops',
            color: '#FBC289',
            center: true,
        },
        {
            max: 'Business Supermarket',
            color: '#F99B3D',
            center: true,
        },
        {
            max: 'Business Planty',
            color: '#C00000',
            center: true,
        },
    ],
    median_lz: [
        {
            max: '400',
            color: '#9DC3E6',
        },

        {
            max: '600',
            color: '#FBC289',
        },
        {
            max: '900',
            color: '#F99B3D',
        },
        {
            color: '#C00000',
            last: true,
        },
    ],
    PriceChange3M: [
        {
            max: '>-5%',
            color: '#9DC3E6',
            center: true,
        },

        {
            max: '-5%-5%',
            color: '#FBC289',
            center: true,
        },
        {
            max: '5-15%',
            color: '#F99B3D',
            center: true,
        },
        {
            max: '>15%',
            color: '#C00000',
            center: true,
        },
    ],
    PriceChange6M: [
        {
            max: '>-5%',
            color: '#9DC3E6',
            center: true,
        },

        {
            max: '-5%-5%',
            color: '#FBC289',
            center: true,
        },
        {
            max: '5-20%',
            color: '#F99B3D',
            center: true,
        },
        {
            max: '>20%',
            color: '#C00000',
            center: true,
        },
    ],
};

export const PRICE_MAP_STATUS = {
    SUBURBLEVEL: 'SUBURBLEVEL',
    PROPERTYLEVEL: 'PROPERTYLEVEL',
    ALLPROPERTYLEVEL: 'ALLPROPERTYLEVEL',
};

export const BedOptions = {
    Apt: [
        {
            key: 'total',
            label: 'ALL BEDS',
            value: 0,
        },
        {
            key: '1BedsAndLess',
            label: '1BedsAndLess',
            value: 1,
        },
        {
            key: '2Beds',
            label: '2Beds',
            value: 2,
        },
        {
            key: '3BedsAndMore',
            label: '3BedsAndMore',
            value: 3,
        },
    ],
    House: [
        {
            key: 'total',
            label: 'ALL BEDS',
            value: 0,
        },
        {
            key: '3BedsAndLess',
            label: '3BedsAndLess',
            value: 3,
        },
        {
            key: '4Beds',
            label: '4Beds',
            value: 4,
        },
        {
            key: '5BedsAndMore',
            label: '5BedsAndMore',
            value: 5,
        },
    ],
};

export const PriceMapLayerType = {
    SUBURB_POLYGON: 'SUBURB_POLYGON',
    SUBURB_CENTERPOINT: 'SUBURB_CENTERPOINT',
    PROPERTY_MARKER: 'PROPERTY_MARKER',
};

export const PropTypes = [
    {
        type: 'House',
        label: 'House',
        helpKey: 'HOUSE_INCLUDING',
    },
    {
        type: 'Apt',
        label: 'Apartment',
        helpKey: 'APT_INCLUDING',
    },
];

export const ProjectScales = [
    {
        min: 0,
        max: 9999,
        label: 'ALL',
        value: 0,
    },
    {
        min: 0,
        max: 40,
        label: 'UNITLESS40',
        value: 1,
    },
    {
        min: 0,
        max: 70,
        label: 'UNITLESS70',
        value: 2,
    },
    {
        min: 40,
        max: 9999,
        label: 'UNITMORE40',
        value: 3,
    },
    {
        min: 70,
        max: 9999,
        label: 'UNITMORE70',
        value: 4,
    },
    {
        min: 100,
        max: 9999,
        label: 'UNITMORE100',
        value: 5,
    },
];

export const ChineseImmiGrades = [
    {
        id: 1,
        l: '< 3%',
        lower: 0,
        upper: 3,
        selected: false,
    },
    {
        id: 2,
        l: '3~8%',
        lower: 3,
        upper: 8,
    },
    {
        id: 3,
        l: '8~20%',
        lower: 8,
        upper: 20,
    },
    {
        id: 4,
        l: '> 20%',
        lower: 20,
        upper: 100,
    },
];
export const IndianImmiGrades = [
    {
        id: 1,
        l: '< 3%',
        lower: 0,
        upper: 3,
        selected: false,
    },
    {
        id: 2,
        l: '3~8%',
        lower: 3,
        upper: 8,
    },
    {
        id: 3,
        l: '8~20%',
        lower: 8,
        upper: 20,
    },
    {
        id: 4,
        l: '> 20%',
        lower: 20,
        upper: 100,
    },
];
export const IslamImmiGrades = [
    {
        id: 1,
        l: '< 3%',
        lower: 0,
        upper: 3,
        selected: false,
    },
    {
        id: 2,
        l: '3~8%',
        lower: 3,
        upper: 8,
    },
    {
        id: 3,
        l: '8~20%',
        lower: 8,
        upper: 20,
    },
    {
        id: 4,
        l: '> 20%',
        lower: 20,
        upper: 100,
    },
];

export const TransportOptions = [
    {
        id: 1,
        l: 'HAVE_TRAIN',

        selected: false,
    },
    {
        id: 0,

        l: 'None',
    },
];

export const SecondarySchoolRanks = [
    {
        id: 1,

        min: 96,
        max: 100,
        selected: false,
        label: 'BETTER',
        total: 14,
    },
    {
        id: 2,
        min: 90,
        max: 95,
        label: 'BETTER1',
        selected: false,
        total: 51,
    },
    {
        id: 3,
        min: -1,
        max: 89,
        label: 'BETTER2',
        selected: false,
        total: 51,
    },
];

export const DistanceToCBDOptions = [
    {
        id: 1,
        min: 0,
        max: 10,
        label: '< 10km',
    },
    {
        id: 3,
        min: 10,
        max: 15,
        label: '10~15km',
    },
    {
        id: 4,
        min: 15,
        max: 25,
        label: '15~25km',
    },
    {
        id: 5,
        min: 25,
        max: 99999,
        label: '> 25km',
    },
];

export const SchoolRanks = [
    {
        id: 5,
        min: 100,
        selected: false,
        total: 14,
    },
    {
        id: 4,
        min: 98,
        max: 99,
        selected: false,
        total: 51,
    },
    {
        id: 3,
        min: 96,
        max: 97,
        selected: false,
        total: 51,
    },
    {
        id: 2,
        min: 90,
        max: 95,
        selected: false,
        total: 119,
    },
];
export const SchoolStarRating = [
    {
        id: 5,
        star: 5,
        selected: false,
        total: 20,
    },
    {
        id: 4,
        star: 4,
        selected: false,
        total: 30,
    },
    {
        id: 3,
        star: 3,
        selected: false,
        total: 50,
    },
    {
        id: 2,
        star: 2,
        selected: false,
        total: 100,
    },
];

export const BuildAges = [
    {
        min: 0,
        max: 9999,
        label: 'ALL',
        value: 0,
    },
    {
        min: 0,
        max: 5,
        label: 'LESS5YEAR',
        value: 1,
    },
    {
        min: 0,
        max: 10,
        label: 'LESS10YEAR',
        value: 2,
    },
    {
        min: 0,
        max: 20,
        label: 'LESS20YEAR',
        value: 3,
    },
    {
        min: 0,
        max: 30,
        label: 'LESS30YEAR',
        value: 4,
    },
    {
        min: 30,
        max: 999,
        label: 'GREAT30YEAR',
        value: 5,
    },
];

export const FullPriceGrades = [
    0.05,
    0.1,
    0.2,
    0.25,
    0.3,
    0.35,
    0.4,
    0.45,
    0.5,
    0.55,
    0.65,
    0.8,
    0.9,
    1,
    1.1,
    1.3,
    1.6,
    1.8,
    2,
    2.2,
    2.5,
    2.8,
    3,
    3.2,
    3.5,
    3.8,
    4,
    4.5,
    5,
    7,
    10,
    15,
    20,
    30,
];
export const FullPriceGradesFor2BedsRent = [
    0,
    270,
    300,
    320,
    350,
    370,
    400,
    420,
    450,
    470,
    500,
    520,
    550,
    570,
    600,
    620,
    650,
    670,
    700,
    720,
    750,
    770,
    800,
    900,
];

export const PropertyPriceLegendList = [
    {
        max: 1.2,
        color: '#91d02a',
        first: true,
    },

    {
        min: 1.2,
        max: 1.8,
        color: '#f4e800',
    },

    {
        min: 1.8,
        max: 2.4,
        color: '#f98b02',
    },
    {
        min: 2.4,
        max: 3,
        color: '#e51e09',
    },
    {
        min: 3,
        color: '#b2095a',
        last: true,
    },
];

export const suburbNamesForMapLevels = {
    10: ['BROOKLYN', 'RICHMOND', 'PENRITH', 'PARRAMATTA', 'NORTH SYDNEY', 'LIVERPOOL', 'CAMDEN', 'CAMPBELLTOWN'],
    11: [
        'BROOKLYN',
        'RICHMOND',
        'WINDSOR',
        'PENRITH',
        'PARRAMATTA',
        'NORTH SYDNEY',
        'LIVERPOOL',
        'CAMDEN',
        'CAMPBELLTOWN',
        'CHATSWOOD',
        'CASTLE HILL',
    ],
    12: [
        'BROOKLYN',
        'PALM BEACH',
        'NEW PORT',
        'BAYVIEW',
        'BEROWRA',
        'MOUNT KURING-GAI',
        'COLLLAROY',
        'BELROSE',
        'ST IVES CHASE',
        'ST IVES',
        'ASQUITH',
        'HORSBY',
        'WESTLEIGH',
        'WARRAWEE',
        'DURAL',
        'GASTON',
        'GLENHAVEN',
        'NORTH KELLYVILLE',
        'ROUSE HILL',
        'THE PONDS',
        'MARAYLYA',
        'GABLES',
        'WINDSOR',
        'RICHMOND',
        'BLIGH PARK',
        'RIVERSTONE',
        'SCHOFIELDS',
        'PARKLEA',
        'KELLYVILLE',
        'NORWEST',
        'CRANEBROOK',
        'EMU PLAINS',
        'LEONAY',
        'PENRITH',
        'CADDENS',
        'WERRINGTON',
        'ST MARYS',
        'ST CLAIR',
        'COLYTON',
        'WHALAN',
        'BIDWILL',
        'ROOTY HILL',
        'DONNSIDE',
        'BLACKTOWN',
        'LALOR PARK',
        'TOONGABBIE',
        'PROSPECT',
        'WESTMEAD',
        'PARRAMATTA',
        'OATLANDS',
        'BEECROFT',
        'WARRAWEE',
        'PYMBLE',
        'KILLARA',
        'BROOKVLE',
        'ALLAMBIE HEIGHTS',
        'MANLY',
        'MOSMAN',
        'NORTH SYDNEY',
        'ATARMON',
        'CHATSWOOD',
        'RIVERVIEW',
        'HENLEY',
        'RYDE',
        'EASTWOOD',
        'EPPING',
        'DENISTONE WEST',
        'SMITHFIELD',
        'WAKELEY',
        'ABBOSBURY',
        'YENNORA',
        'CARRAMAR',
        'BONNYRIGG',
        'CECIL HILLS',
        'AUSTRAL',
        'HOXTON PARK',
        'BUSBY',
        'LIVERPOOL',
        'CASULA',
        'SEFTON',
        'AUBURN',
        'HOMEBUSH',
        'CONCORD',
        'BURWOOD',
        'GLEBE',
        'SYDNEY',
        'VAUCLUSE',
        'BONDI',
        'WAVERLEY',
        'CLOVELLY',
        'MASCOT',
        'BOTANY',
        'MALABAR',
        'TEMPE',
        'ENMORE',
        'LAKEMBA',
        'BANKSTOWN',
        'NARWEE',
        'REVESBY',
        'MILPERRA',
        'EAST HILLS',
        'HOLSWORTHY',
        'BARDIA',
        'INGLEBURN',
        'LEPPINGTON',
        'ORAN PARK',
        'COBBITTY',
        'CAMDEN',
        'NARELLAN',
        'MOUNT ANNAN',
        'RABY',
        'MINTO',
        'CAMPBELLTOWN',
        'BRADBURY',
        'MENAI',
        'BARDEN RIDGE',
        'ENGADINE',
        'SUTHERLAND',
        'COMO',
        'GYMEA',
        'GRAYS POINT',
        'SYLVANIA',
        'WATERFALL',
        'MAIANBAR',
        'DOLANS BAY',
        'WOOLOOWARE',
        'KURNELL',
        'RAMSGATE',
        'KOGARAH',
    ],
    13: [
        'BROOKLYN',
        'PALM BEACH',
        'WHALE BEACH',
        'AVALON BEACH',
        'BILGOLA BEACH',
        'NEWPORT',
        'CHURCH POINT',
        'BAYVIEW',
        'MONA VALE',
        'WARRIEWOOD',
        'ELANORA HEIGHTS',
        'NARRABEEN',
        'TERRY HILLS',
        'BEROWRA HEIGHTS',
        'BEROWRA',
        'MOUNT KURING-GAI',
        'HORNSBY HEIGHTS',
        'MOUNT COLAH',
        'ASQUITH',
        'DURAL',
        'NORTH KELLYVILLE',
        'ROUSE HILL',
        'BEAUMONT HILLS',
        'KELLYVILLE RIDGE',
        'MARAYLYA',
        'GABLES',
        'BOX HILL',
        'WINDSOR',
        'RICHMOND',
        'BLIGH PARK',
        'SOUTH WINDSOR',
        'WINDSOR DOWNS',
        'RIVERSTONE',
        'SCHOFIELDS',
        'MARSDEN PARK',
        'JORDAN SPRINGS',
        'EMU HEIGHTS',
        'LEONAY',
        'EMU PLAINS',
        'PENRITH',
        'JAMISONTOWN',
        'GLENMORE PARK',
        'SOUTH PENRITH',
        'CADDENS',
        'CAMBRIDGE PARK',
        'WERRINTON COUNTY',
        'WERRINGTON',
        'CLAREMONT MEADOWS',
        'ST MARYS',
        'WILLMOT',
        'SHALVEY',
        'TREGEAR',
        'OXLEY PARK',
        'ST CLAIR',
        'COLYTON',
        'ERSKINE PARK',
        'MINCHINBURY',
        'MOUNT DRUITT',
        'WHALAN',
        'BIDWILL',
        'HEBERSHAM',
        'ROOTY HILL',
        'PLUMPTON',
        'COLEBEE',
        'DOONSIDE',
        'QUAKERS HILL',
        'MARAYONG',
        'BLACKTOWN',
        'KINGS PARK',
        'PARKLEA',
        'PROSPECT',
        'PEMULWUY',
        'WETHERILL PARK',
        'GREYSTANES',
        'KINGS LANGLEY',
        'LALOR PARK',
        'SEVEN HILLS',
        'TOONGABBIE',
        'GIRRAWEEN',
        'PENDLE HILL',
        'SMITHFIELD',
        'PRAIRIEWOOD',
        'FAIRFIELD WEST',
        'ST JOHNS PARK',
        'EDENSOR PARK',
        'ABBOTSBURY',
        'CECIL HILLS',
        'BONNYRIGG',
        'CAMBRAMATTA WEST',
        'GREEN VALLEY',
        'MIDDLETON GRANGE',
        'HINCHINBROOK',
        'SADLEIR',
        'AUSTRAL',
        'WEST HOXTON',
        'HOXTON PARK',
        'PRESTONS',
        'LURNEA',
        'CASULA',
        'HORNINGSEA PARK',
        'LEPPINGTON',
        'DENHAM COURT',
        'BARDIA',
        'MACQUARIE LINKS',
        'GLENFIELD',
        'INGLEBURN',
        'CATHERINE FIELD',
        'ORANPARK',
        'GLEDSWOOD HILLS',
        'RABY',
        'ST ANDREWS',
        'BOW BOWING',
        'GREGORY HILLS',
        'HARRINGTON PARK',
        'COBBITTY',
        'CAMDEN',
        'ELDERSLIE',
        'MOUNT ANNAN',
        'CURRANS HILL',
        'CAMPBELLTOWN',
        'BRADBURY',
        'RUSE',
        'LEUMEAH',
        'MINTO',
        'HOLSWORTHY',
        'MOOREBANK',
        'HAMMONDVILLE',
        'CHIPPING NORTON',
        'LANSVALE',
        'FAIRFIELD',
        'EAST HILLS',
        'PICNIC POINT',
        'PANANIA',
        'ALFORDS POINT',
        'HEALTHCOTE',
        'WATERFALL',
        'YARRAWARRAH',
        'ENGADINE',
        'HEATHCOTE',
        'LOFTUS',
        'WORONORA HEIGHTS',
        'PADSTOW HEIGHTS',
        'REVESBY',
        'MILPERRA',
        'CONDELL PARK',
        'BANKSTOWN',
        'GEORGES HALL',
        'YAGOONA',
        'BIRRONG',
        'SEFTON',
        'VILLAWOOD',
        'BERALA',
        'LIDCOMBE',
        'AUBURN',
        'SOUTH GRANVILLE',
        'GUILDFORD',
        'WOODPARK',
        'HOLROYD',
        'MAYS HILL',
        'MERRYLANDS',
        'SOUTH WENTWORTHVILLE',
        'PARRAMATTA',
        'WESTMEAD',
        'NORTHMEAD',
        'WINSTON HILLS',
        'OATLANDS',
        'DUNDAS',
        'TELOPEA',
        'CARLINGFORD',
        'NORTH ROCKS',
        'CASTLE HILL',
        'WEST PENNANT HILLS',
        'NORWEST',
        'KELLYVILLE',
        'GLENHAVEN',
        'CHERRYBROOK',
        'WESTLEIGH',
        'HORNSBY',
        'WAITARA',
        'PENNANT HILLS',
        'BEECROFT',
        'EPPING',
        'EASTWOOD',
        'DENISTONE',
        'DENISTONE WEST',
        'WEST RYDE',
        'WENTWORTH POINT',
        'NEWINGTON',
        'MOUNT LEWIS',
        'PUNCHBOWL',
        'RIVERWOOD',
        'NARWEE',
        'NORTDALE',
        'LUGARNO',
        'OATLEY',
        'COMO',
        'BONNET BAY',
        'MENAI',
        'BANGOR',
        'JANNALI',
        'SUTHERLAND',
        'NORTH WAHROONGA',
        'ST IVES CHASE',
        'WAHROONGA',
        'WARRAWEE',
        'TURRAMURRA',
        'PYMBLE',
        'SOUTH TURRAMURRA',
        'WEST PYMBLE',
        'NORTH EPPING',
        'MARSFIELD',
        'ST IVES',
        'BELROSE',
        'DAVIDSON',
        'CROMER',
        'COLLAROY',
        'FRECHS FOREST',
        'BEACON HILL',
        'DEE WHY',
        'FORESTVILLE',
        'EAST KILLARA',
        'GORDON',
        'KILLARA',
        'EAST LINDFIELD',
        'LINDFIELD',
        'KILLARNEY HEIGHTS',
        'ALLAMBIE HEIGHTS',
        'QUEENSCLIFF',
        'SEAFORTH',
        'BALGOWLAH',
        'MANLY',
        'BALGOWLAH HEIGHTS',
        'CASTLE COVE',
        'MIDDLE COVE',
        'CASTLECRAG',
        'CHATSWOOD',
        'LANE COVE NORTH',
        'ARTARMON',
        'EAST RYDE',
        'RYDE',
        'RIVERVIEW',
        'LANE COVE',
        'CAMMERAY',
        'CREMORNE',
        'MOSMAN',
        'NEUTRAL BAY',
        'NORTHWOOD',
        'HUNTERS HILL',
        'WOOLWICH',
        'PUTNEY',
        'HENLEY',
        'BIRCHGROVE',
        'LIBERTY GROVE',
        'CONCORD',
        'BALMAIN',
        'WAREEMBA',
        'LILYFIELD',
        'SYDNEY',
        'HUMBUSH',
        'VAUCLUSE',
        'POINT PIPER',
        'ROSE BAY',
        'WOOLLAHRA',
        'ULTIMO',
        'GLEBE',
        'DALE',
        'HABERFIELD',
        'BURWOOD',
        'STRATHFIELD',
        'ENFIELD',
        'ASHBURY',
        'LEWISHAM',
        'ENMORE',
        'REDFERN',
        'MOORE PARK',
        'BONDI',
        'WAVERLEY',
        'CLOVELLY',
        'ZETLAND',
        'ST PETERS',
        'CANTERBURY',
        'CAMPSIE',
        'LAKEMBA',
        'ROSELANDS',
        'KINGSGROVE',
        'EARLWOOD',
        'TEMPE',
        'MASCOT',
        'KINGSFORD',
        'COOGEE',
        'PAGEWOOD',
        'WOLLI CREEK',
        'BEXLE NORTH',
        'BANKSIA',
        'KYEEMAGH',
        'BOTANY',
        'HILLSDALE',
        'MAROUBRA',
        'MARTRAVILLE',
        'CHIFLEY',
        'LITTLE BAY',
        'HURSTVILLE',
        'KOGARAH',
        'CARLTON',
        'RAMSGATE',
        'BLAKEHURST',
        'OATLEY',
        'SYLVANIA',
        'TAREN POINT',
        'GYMEA',
        'CARINGBAH',
        'GREENHILLS BEACH',
        'WOOLOOWARE',
        'GYMEA BAY',
        'CRONULLA',
        'GRAYS POINT',
        'DOLANS',
        'BURRANEER',
        'MAIANBAR',
    ],
};

export const PrivateSchoolMixBoy = [
    {
        id: 1,
        min: 5,
        max: 6,
        selected: false,
        label: 'PRIVATE_SCHOOL_10',
    },
    {
        id: 2,
        min: 4,
        max: 4,
        selected: false,
        label: 'PRIVATE_SCHOOL_9',
    },
    {
        id: 3,
        min: 3,
        max: 3,
        selected: false,
        label: 'PRIVATE_SCHOOL_2',
    },
    {
        id: 4,
        min: 1,
        max: 2,
        selected: false,
        label: 'PRIVATE_SCHOOL_1',
    },
];
export const PrivateSchoolMixGirl = [
    {
        id: 1,
        min: 5,
        max: 6,
        selected: false,
        label: 'PRIVATE_SCHOOL_10',
    },
    {
        id: 2,
        min: 4,
        max: 4,
        selected: false,
        label: 'PRIVATE_SCHOOL_9',
    },
    {
        id: 3,
        min: 3,
        max: 3,
        selected: false,
        label: 'PRIVATE_SCHOOL_2',
    },
    {
        id: 4,
        min: 1,
        max: 2,
        selected: false,
        label: 'PRIVATE_SCHOOL_1',
    },
];
export const TenantProportion = [
    {
        id: 1,
        min: 0,
        max: 0.2,
        selected: false,
        label: '< 20%',
    },
    {
        id: 2,
        min: 0.201,
        max: 0.4,
        selected: false,
        label: '20~40%',
    },
    {
        id: 3,
        min: 0.401,
        max: 0.6,
        selected: false,
        label: '40~60%',
    },
    {
        id: 4,
        min: 0.601,
        max: 1,
        selected: false,
        label: '> 60%',
    },
];
export const HouseApt = [
    {
        id: 1,
        min: 0,
        max: 0.2,
        selected: false,
        label: '< 20%',
    },
    {
        id: 2,
        min: 0.201,
        max: 0.5,
        selected: false,
        label: '20~50%',
    },
    {
        id: 3,
        min: 0.501,
        max: 0.8,
        selected: false,
        label: '50~80%',
    },
    {
        id: 4,
        min: 0.801,
        max: 1,
        selected: false,
        label: '> 80%',
    },
];
export const FamilyIncome = [
    {
        id: 1,
        min: 0,
        max: 30000,
        selected: false,
        label: '< 30k',
    },
    {
        id: 2,
        min: 30001,
        max: 80000,
        selected: false,
        label: '30~80k',
    },
    {
        id: 3,
        min: 80001,
        max: 130000,
        selected: false,
        label: '80~130k',
    },
    {
        id: 4,
        min: 130001,
        max: 200000,
        selected: false,
        label: '130k~200k',
    },
    {
        id: 5,
        min: 200001,
        max: 1000000,
        selected: false,
        label: '> 200k',
    },
];
export const PublicSecurity = [
    {
        id: 1,
        number: 5,
        selected: false,
        label: 'Security Very Good',
    },
    {
        id: 2,
        number: 4,
        selected: false,
        label: 'Security Good',
    },
    {
        id: 3,
        number: 3,
        selected: false,
        label: 'Security OK',
    },
    {
        id: 4,
        number: 2,
        selected: false,
        label: 'Security Worse',
    },
    {
        id: 5,
        number: 1,
        selected: false,
        label: 'Security Worst',
    },
];
export const AreaBusiness = [
    {
        id: 1,
        min: 4,
        max: 5,
        selected: false,
        label: 'Business Planty',
    },
    {
        id: 2,
        min: 3,
        max: 3,
        selected: false,
        label: 'Business Supermarket',
    },
    {
        id: 3,
        min: 2,
        max: 2,
        selected: false,
        label: 'Business Shops',
    },
    {
        id: 4,
        min: 1,
        max: 1,
        selected: false,
        label: 'Business Few',
    },
];

export const Waterscape = [
    {
        id: 1,
        min: 5,
        max: 5,
        selected: false,
        label: 'Waterscape Onside',
    },
    {
        id: 2,
        min: 4,
        max: 4,
        selected: false,
        label: 'Waterscape Near',
    },
    {
        id: 3,
        min: 3,
        max: 3,
        selected: false,
        label: 'Waterscape Drive',
    },
    {
        id: 4,
        min: 1,
        max: 2,
        selected: false,
        label: 'Waterscape Far',
    },
];
export const Trees = [
    {
        id: 1,
        min: 5,
        max: 5,
        selected: false,
        label: 'Tree Most',
    },
    {
        id: 2,
        min: 4,
        max: 4,
        selected: false,
        label: 'Tree More',
    },
    {
        id: 3,
        min: 3,
        max: 3,
        selected: false,
        label: 'Tree Normal',
    },
    {
        id: 4,
        min: 1,
        max: 2,
        selected: false,
        label: 'Tree Few',
    },
];
export const LandSize = [
    {
        id: 1,
        min: 0,
        max: 400,
        selected: false,
        label: '< 400',
    },
    {
        id: 2,
        min: 401,
        max: 600,
        selected: false,
        label: '401~600',
    },
    {
        id: 3,
        min: 601,
        max: 900,
        selected: false,
        label: '601~900',
    },
    {
        id: 4,
        min: 901,
        max: 2000,
        selected: false,
        label: '> 901',
    },
];
export const PriceChange3M = [
    {
        id: 1,
        min: 0.151,
        max: 3,
        selected: false,
        label: '> 15%',
    },
    {
        id: 2,
        min: 0.051,
        max: 0.15,
        selected: false,
        label: '5~15%',
    },
    {
        id: 3,
        min: -0.049,
        max: 0.05,
        selected: false,
        label: '-5%~5%',
    },
    {
        id: 4,
        min: -1,
        max: -0.05,
        selected: false,
        label: '< -5%',
    },
];
export const PriceChange6M = [
    {
        id: 1,
        min: 0.201,
        max: 3,
        selected: false,
        label: '> 20%',
    },
    {
        id: 2,
        min: 0.051,
        max: 0.2,
        selected: false,
        label: '5~20%',
    },
    {
        id: 3,
        min: -0.049,
        max: 0.05,
        selected: false,
        label: '-5%~5%',
    },
    {
        id: 4,
        min: -1,
        max: -0.05,
        selected: false,
        label: '> -5%',
    },
];

import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import {
    findMaxInArray,
    findMinInArray,
    isMobileDevice,
    removeSpaceAndWords,
    removeZeroFromArray,
} from '../../../commons/utils';
import { HelpComponent } from '../../PriceMap/components/HelpComponent';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { ContentTitleData } from '../components/ContentTitleData';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
        school: {
            margin: '0 auto',
            padding: '0px 0px 20px 0px',
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            textAlign: 'left',
            padding: '5px 0',
        },
        part2: {
            textAlign: 'left',
            padding: '5px 0',
        },
        part3: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 0',
        },
        part4: {
            margin: '0 auto',
        },
        part4Header: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px',
            cursor: 'pointer',
        },
        HeaderArrow: {
            display: 'flex',
            justifyContent: 'flex-start',
            margin: '0px',
        },
        icon: {
            paddingTop: '5px',
            paddingLeft: '5px',
        },
        legend: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        legendItem: {
            width: '10px',
            height: '10px',
            borderRadius: '5px',
        },
        part5: {
            margin: '0 auto',
        },
    };
});

declare var Highcharts;
const chartColor = {
    nineYears: ['#ff9f40', '#0f85f3', '#20cc68', '#e80ed6'],
};
export const Card22 = rst.create<{ property: any; mobile?: boolean }>((ctx) => {
    // const isOpen = rst.stateS(false);
    // const toggleOptions = () => {
    //     isOpen.value = !isOpen.value;
    // };
    function loadChart() {
        ctx.props.property.secondarySchools.map((x, index) => {
            Highcharts.chart(`card22-${index}`, {
                chart: {
                    type: 'spline',
                    height: 180,
                    marginBottom: isMobileDevice() ? 30 : 35,
                },
                title: {
                    text: '',
                },

                subtitle: {
                    text: '',
                },

                yAxis: {
                    title: {
                        text: '',
                    },
                    max:
                        ctx.props.property.secondarySchools[index].yearlyRanking === null
                            ? 1
                            : findMaxInArray(
                                  removeZeroFromArray(ctx.props.property.secondarySchools[index].yearlyRanking),
                              ) + 1,
                    min:
                        ctx.props.property.secondarySchools[index].yearlyRanking === null
                            ? 1
                            : findMinInArray(
                                  removeZeroFromArray(ctx.props.property.secondarySchools[index].yearlyRanking),
                              ),
                    // tickInterval: 50,
                    // startOnTick: false,
                    labels: {
                        step: 1,
                        formatter: function () {
                            const _this: any = this;
                            if (_this.value >= 230) {
                                return '';
                            } else {
                                return _this.value;
                            }
                        },
                    },
                    reversed: true,
                },

                xAxis: {
                    type: 'category',
                    categories:
                        ctx.props.property.secondarySchools[index].yearlyRanking === null
                            ? [0, 0, 0, 0, 0]
                            : ctx.props.property.secondarySchools[index].yearlyRanking.year,
                    crosshair: true,
                },

                legend: {
                    enabled: false,
                },
                credits: { enabled: false },
                tooltip: {
                    enabled: false,
                    // backgroundColor: 'white', // 背景颜色
                    // borderColor: 'black', // 边框颜色
                    // borderRadius: 10, // 边框圆角
                    // borderWidth: 1, // 边框宽度
                    // animation: true, // 是否启用动画效果
                    //format the date and numbers
                    // formatter: function () {
                    //     const _this: any = this;
                    //     console.log(_this);
                    //     var d = new Date(_this.x);
                    //     var s = '<b>' + _this.x + '</b>';
                    //     s += '<br/><span>Price: ' + '$' + (_this.y / 1000000).toFixed(2) + 'm' + '</span>';
                    //     return s;
                    // },
                },
                plotOptions: {
                    spline: {
                        dataLabels: {
                            enabled: true,
                            labels: {
                                step: 1,
                            },
                            formatter: function () {
                                const _this: any = this;
                                if (_this.y >= 230) {
                                    return 'N/A';
                                } else {
                                    return _this.y;
                                }
                            },
                        },
                        enableMouseTracking: false,
                    },
                    series: {
                        enableMouseTracking: true,
                        marker: {
                            enabled: false,
                        },
                    },
                    areaspline: {
                        fillOpacity: 0.25,
                    },
                    area: {
                        stacking: 'normal',
                        lineColor: '#666666',
                        lineWidth: 2,
                        opacity: 1,

                        marker: {
                            lineWidth: 1,
                            lineColor: '#666666',
                        },
                    },
                },

                series: [
                    {
                        data:
                            ctx.props.property.secondarySchools[index].yearlyRanking === null
                                ? [0, 0, 0, 0, 0]
                                : removeZeroFromArray(ctx.props.property.secondarySchools[index].yearlyRanking),
                        color: chartColor.nineYears[index],
                        fillOpacity: 0.3,
                        opacity: 1,
                        lineColor: chartColor.nineYears[index],
                    },
                ],
            });
        });
    }
    return (props) => {
        const classes = useStyles();
        setTimeout(() => {
            loadChart();
        }, 400);
        const secondarySchoolName = removeSpaceAndWords(props.property.secondarySchools[0].schoolName, 'High School');

        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader header={props.mobile ? '' : 'Card22_header'}>
                    {props.property.secondarySchools.map((school, index) => {
                        return (
                            <div className={classes.school}>
                                <div className={classes.part1}>
                                    <ContentTitleData header="Card22_school_name" content={school.schoolName} />
                                </div>
                                <CardDivider />
                                <div className={classes.part2}>
                                    <ContentTitleData header="Card22_tol_enrolments" content={school.enrolments} />
                                </div>
                                <CardDivider />
                                <div className={classes.part2}>
                                    <ContentTitleData header="Card22_lbotePct" content={school.lbotePct + '%'} />
                                </div>
                                <CardDivider />
                                <div className={classes.part2}>
                                    <ContentTitleData header="Card22_icsea" content={school.icsea} />
                                </div>

                                <div id={`card22-${index}`} style={{ display: 'none' }}></div>
                            </div>
                        );
                    })}
                </CardWithHeader>
            </div>
        );
    };
});

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { ROUTES } from '../../../routes';
import { NewButton } from './NewButton';
import { MemberInfo } from '../../mobile/member/memberInfo';

const useStyles = makeStyles((theme) => ({
    homeMidImg: {
        background: `url(${process.env.PUBLIC_URL}/static/images/homeMidImg.png)`,
        // background: 'rgb(48 120 191)',
        maxWidth: '1920px',
        // [theme.breakpoints.down('sm')]: {
        //     width: '100vw',
        //     height: '60vh',
        // },
        height: '240px',
        margin: '0 auto',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: '200px',
        },
    },
    homeMidImgContent: {
        width: '1043px',
        margin: '0 auto',
        color: 'rgb(216 242 242)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 16px',
            width: '100vw',
        },
        '& h2': {
            fontSize: '26px',
            margin: '35px 0px 20px 0px',
            color: '#FFFFFF',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '36px',
                margin: '0px 0px 10px 0px',
            },
        },
        '& p': {
            fontSize: '16px',
            lineHeight: '22px',
            margin: '0px 0px 0px 0px',
            textAlign: 'center',
            color: '#FFFFFF',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                lineHeight: '16px',
                margin: '0px 0px 20px 0px',
            },
        },
    },
}));
export const NewHomeMidImg = rst.create((ctx) => {
    const openMemberInfo = rst.stateS(false);
    const openMember = () => {
        window.open(`${ROUTES.JoinMember.path}#pricing`, '_self');
        // location.reload();
        // location.href = `${ROUTES.JoinMember.path}#pricing`;
    };
    return (props) => {
        const classes = useStyles();

        return (
            <div className={classes.homeMidImg}>
                <div className={classes.homeMidImgContent}>
                    <h2>
                        <TR name={'NewHome_homeMidImgTextHeader'} />
                    </h2>
                    <p>
                        <TR name={'NewHome_homeMidImgTextP1'} />
                    </p>
                    <p style={{ paddingBottom: '30px' }}>
                        <TR name={'NewHome_homeMidImgTextP1b'} />
                    </p>
                    <NewButton
                        backgroundColor="white"
                        padding="10px 41px"
                        color="#009F91"
                        label="NewHome_More"
                        onClick={() => {
                            openMemberInfo.value = true;
                            openMemberInfo.forceUpdate();
                        }}
                    />
                </div>
                <MemberInfo
                    show={openMemberInfo.value}
                    popType={0}
                    onClose={() => {
                        openMemberInfo.value = false;
                        openMemberInfo.forceUpdate();
                    }}></MemberInfo>
            </div>
        );
    };
});

import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { generateGUID, TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { NearByTranscationsSimpleChart } from '../../PriceMap/Charts/NearByTranscationsSimpleChart';
import { MapGlobalVars } from '../../PriceMap/support/PriceMapConsts';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { ContentTitleData } from '../components/ContentTitleData';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            textAlign: 'left',
            padding: '5px 0',
        },
        part2: {
            textAlign: 'left',
            padding: '5px 0',
        },
    };
});

export const Card31 = rst.create<{ prop: any; refreshEstimateEvt }>((ctx) => {
    if (MapGlobalVars.nearByTransEventName == '') {
        MapGlobalVars.nearByTransEventName = generateGUID();
    }

    return (props) => {
        const classes = useStyles();
        setTimeout(() => {
            EventBus.dispatch(MapGlobalVars.nearByTransEventName, { chart: 0 });
        });
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader header="REPORT_SUBTITLE_31">
                    <div className={classes.part2}>
                        <NearByTranscationsSimpleChart
                            showForReport={true}
                            prop={props.prop}></NearByTranscationsSimpleChart>
                    </div>
                    <div
                        onClick={props.refreshEstimateEvt}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            fontSize: '12px',
                            cursor: 'pointer',
                        }}>
                        (<TR name="MISSING_ESIMATE"></TR>)
                    </div>
                </CardWithHeader>
            </div>
        );
    };
});

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons/i18n/TR';

export interface RptHeaderProps {
    zh: string;
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        //width: '396px',
        // height: '42px',
        margin: '0px',
        padding: '10px 20px',
        background: 'rgb(249 249 249)',
        '& h1': {
            padding: '0px',
            margin: '0px',
            fontSize: '18px',
            textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
        },
    },
}));

export const RptHeader = rst.createS<RptHeaderProps>((props) => {
    const { zh } = props;

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <h1>
                <TR name={zh} />
            </h1>
        </div>
    );
});

import { makeStyles, Tooltip, withStyles } from '@material-ui/core';
import ReactDOM from 'react-dom';
import React from 'react';
import { SchoolOrStationScroll } from './SchoolOrStationScrolls';
import { getPrimarySchoolsStar, getSecondarySchoolsStar, getTrains } from '../../../support/propertyFeatureHelper';
import { rst } from 'rt-state';

export const SchoolOrStationLine = rst.create<{ primary; schoolNameKey; secondary; trains; onMap?; onReport? }>(
    (ctx) => {
        return (props) => {
            const { primary, secondary, schoolNameKey, trains } = props;
            return (
                <div
                    style={{
                        display: 'flex',
                        marginTop: 15,

                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}>
                    <div>
                        <SchoolOrStationScroll
                            maxWidth={120}
                            items={getPrimarySchoolsStar(primary, schoolNameKey)}
                            image={
                                <img
                                    src="assets/images/svgs/gold_star.svg"
                                    style={{ height: '12px', paddingTop: '0px', paddingLeft: '2px' }}
                                />
                            }
                            onReport={props.onReport}></SchoolOrStationScroll>
                    </div>
                    <div>
                        {' '}
                        <SchoolOrStationScroll
                            maxWidth={120}
                            onMap={props.onMap}
                            items={getSecondarySchoolsStar(secondary, schoolNameKey)}
                            image={
                                <img
                                    src="assets/images/svgs/gold_star.svg"
                                    style={{ height: '12px', paddingTop: '0px', paddingLeft: '2px' }}
                                />
                            }></SchoolOrStationScroll>
                    </div>
                    <div>
                        <SchoolOrStationScroll items={getTrains(trains)}></SchoolOrStationScroll>
                    </div>
                </div>
            );
        };
    },
);

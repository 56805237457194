import { XTableColumn } from '../../../../components/Table';
import React from 'react';

export const commonColumns: XTableColumn[] = [
    {
        name: 'localityRanking',
        sort: true,
        align: 'center',
        label: '序列号',
        render: (row) => {
            // console.log(row);
            return <span>{row.localityRanking}</span>;
        },
    },
    { name: 'localityName', sort: true, align: 'center', label: 'suburb名称' },
];

export interface TitleAndColumns {
    [key: string]: {
        title: string;
        columns: XTableColumn[];
    };
}

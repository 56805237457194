import React from 'react';
import { useStyles } from './PrivacyPolicy.styles';
import { rst } from 'rt-state';
import { HomeFooter } from '../Home/components/HomeFooter';
import { FirstPageHead } from '../Home/components/FirstPageHead';
import { NewHomePageHead } from '../NewHome/components/01NewHomePageHead';
import { NewHomeFooter } from '../NewHome/components/11NewHomeFooter';
import { Hidden } from '@material-ui/core';
import { NewHomeFooterForMobile } from '../NewHome/components/11NewHomeFooterForMobile';
export const PrivacyPolicyPage = rst.createS(() => {
    const classes = useStyles();

    return (
        <>
            <NewHomePageHead />
            <div className={classes.root}>
                <div className={classes.content}>
                    <h1>PrivacyPolicy</h1>
                    <p>
                        This Privacy Policy governs the manner in which AIHOUSE PTY LTD (ACN: 627 431 805) ("The
                        company") collects, uses, maintains and discloses information collected from users (each, a
                        "User") of the "homeon.com.au" website. This privacy policy applies to the Website and all
                        products and services offered by The company.
                    </p>
                    <p>
                        In this Privacy Policy, "us", "we" or "our" means The company. The company is the operator of
                        the Website and the data controller for the personal data collected and processed via the
                        Website.
                    </p>
                    <p>
                        This policy sets out how we handle your personal information, including the collection, use and
                        disclosure of your personal information, as well as how you can access and change your
                        information, provide us with feedback or make a complaint.
                    </p>
                    <p>
                        We comply with the <em>Privacy Act 1988</em> (Cth). We may, from time to time, review and update
                        this Privacy Policy.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>What is Personal Information? </strong>
                    </p>
                    <p>
                        The term "personal information" has the meaning given to it in the Privacy Act or any other
                        applicable data protection law. In general terms, it is any information that can be used to
                        personally identify you. For the avoidance of doubt, "personal information" includes your notes
                        or comments in the Website.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>What personal information do we collect?&nbsp; </strong>
                    </p>
                    <p>We collect the following personal information:&nbsp;</p>
                    <ul>
                        <li>
                            Contact information such as your name, email, telephone number and mailing or street
                            address;
                        </li>
                        <li>
                            Other personal information including age or birth date, profession, occupation or job title;
                        </li>
                        <li>
                            Information in connection with your existing email accounts used to log-in to the Website;
                        </li>
                        <li>
                            Any information you input, fill or provide in the Website with respect to your property
                            purchase preference;
                        </li>
                        <li>Communications you have with us or third parties through our Website;</li>
                        <li>Services provided to you, such as saved listings and searches and email alerts;</li>
                        <li>
                            Any additional information relating to you that you provide to us directly through our
                            Website or indirectly through use of our Website or online presence through our
                            representatives or otherwise;
                        </li>
                        <li>
                            Information you provide to us through our service centre, customer surveys or visits by our
                            representatives from time to time.
                        </li>
                    </ul>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>How do we collect personal information?&nbsp; </strong>
                    </p>
                    <p>
                        We collect personal information directly from you unless it's unreasonable or impracticable to
                        do so. Some ways we collect personal information include:&nbsp;
                    </p>
                    <ul>
                        <li>
                            When you access our Website including when you sign up to become a member of the Website by
                            using your email account to log-in to the Website;
                        </li>
                        <li>When you make an enquiry;</li>
                        <li>During conversations between you and our representatives;</li>
                        <li>Data sharing between our related bodies corporate.</li>
                    </ul>
                    <p>We may also collect personal information from third parties.</p>
                    <p>
                        In some cases, we may collect your personal information through 'cookies'. We may use "cookies"
                        to enhance User experience. User may choose to set their web browser to refuse cookies, or to
                        alert you cookies are being sent. If they do so, note that some parts of the Website may not
                        function properly.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>How we use collected information? </strong>
                    </p>
                    <p>
                        The primary purpose for which we collect personal information is to enable us to perform our
                        business activities and functions and to provide best possible quality of customer experience.
                    </p>
                    <p>We may collect and use Users personal information for the following purposes:</p>
                    <ul>
                        <li>To communicate with you by mail, phone, text message or email;</li>
                        <li>To personalize user experience;</li>
                        <li>To improve and promote our services to you;</li>
                        <li>To verify your identity;</li>
                        <li>To run a promotion, contest, survey or other Site feature;</li>
                        <li>To provide products and services to you;</li>
                        <li>As required or permitted by any law (including the Privacy Act).</li>
                    </ul>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>How do we disclose your personal information?&nbsp; </strong>
                    </p>
                    <p>
                        Subject to our legal obligations, we will not disclose your personal information to any third
                        parties within two years from the date we collect your personal information, unless an exception
                        applies. We may disclose your personal information to:&nbsp;
                    </p>
                    <ul>
                        <li>Third parties to enable functionality of the Website or any of its services;</li>
                        <li>
                            External service providers to ensure the safe and efficient operation of our Website
                            including, but not limited to IT system providers and web hosting providers;
                        </li>
                        <li>
                            Third parties who assist us to provide services including marketing and market research
                            providers, security advisers and professional advisers;
                        </li>
                        <li>To government or regulatory authorities or as otherwise required or permitted by law;</li>
                        <li>
                            Our employees, related bodies corporate, partnerships, joint venture entities, contractors
                            or external service providers for the operation of our Website;
                        </li>
                        <li>Specific third parties authorised by you to receive information held by us;</li>
                        <li>As required or permitted by any law (including the Privacy Act).</li>
                    </ul>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Warranty </strong>
                    </p>
                    <ul>
                        <li>We do not sell, trade, or rent Users personal identification information to others.</li>
                        <li>
                            Subject to our legal obligations, we do not disclose your notes or comments in the Website
                            to any third parties within two years from the date you made those notes or comments in the
                            Website, unless exception applies.
                        </li>
                    </ul>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Accessing and updating your information&nbsp; </strong>
                    </p>
                    <p>
                        You may request access to any information we hold about you. However, we may refuse to allow you
                        access to information in certain circumstances as permitted by law. If we refuse to allow you
                        access to your information held by us, we will explain the grounds of refuse.&nbsp;
                    </p>
                    <p>
                        You can update your contact and profile information at any time by logging into your account or
                        by contacting us. We request that you keep your information as current as possible so that we
                        may continue improve our service to you.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Direct marketing materials</strong>
                    </p>
                    <p>
                        We may send you direct marketing information about products and services that we consider may be
                        of interest to you. We will not use your personal information for direct marketing purposes
                        unless you have provided express consent or your consent can reasonably be implied from the
                        circumstances in which we collected the information.
                    </p>
                    <p>
                        In relation to direct marketing via electronic means, we comply with the <em>Spam Act 2003</em>{' '}
                        (Cth) and in relation to telephone marketing we comply with the{' '}
                        <em>Do Not Call Register Act 2006</em>(Cth).
                    </p>
                    <p>
                        If you receive communications from us that you believe have been sent to you other than in
                        accordance with this Privacy Policy, or in breach of any law, please email us at{' '}
                        <a href="mailto:feedback@aihouse.com.au">feedback@aihouse.com.au</a> .
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>How we protect your information </strong>
                    </p>
                    <p>
                        We take all reasonable steps to store your personal information securely whether it is held
                        physically or electronically.
                    </p>
                    <p>
                        However, you use the Website at your sole risk. We will not be liable for loss resulting from
                        any unauthorized access, alteration, disclosure or destruction of your personal information,
                        username, transaction information and data stored on our Site.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>How you can make a complaint </strong>
                    </p>
                    <p>
                        If you believe your privacy has been breached by us, please email us at{' '}
                        <a href="mailto:feedback@aihouse.com.au">feedback@aihouse.com.au</a> and provide details of the
                        incident. We will endeavor to resolve all investigations within a reasonable time.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Links </strong>
                    </p>
                    <p>
                        Our Website may contain links to other websites operated by third parties. We make no
                        representations or warranties in relation to the privacy practices of any third party website
                        and we are not responsible for the privacy policies or the content of any third party website.
                        Third party websites are responsible for informing you about their own privacy practices.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Changes to this privacy policy </strong>
                    </p>
                    <p>
                        The company has the discretion to update this privacy policy at any time. You acknowledge and
                        agree that it is your responsibility to review this privacy policy periodically and become aware
                        of modifications.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Acceptance </strong>
                    </p>
                    <p>
                        By using this Website, you signify your acceptance of this policy. If you do not agree to this
                        policy, please immediately cease using our Website. If you continue to access or use the Website
                        after The updates or changes this privacy policy, then you will be taken to have agreed to this
                        policy as updated or changed.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Language discrepancy </strong>
                    </p>
                    <p>
                        The English text of the Privacy Policy shall be deemed authentic. In the event of any dispute or
                        misunderstanding as to the interpretation of the language or terms of the policy, the English
                        language version shall prevail.
                    </p>
                    <p>&nbsp;</p>
                    <p>This policy is last updated in October 2019.</p>
                    <p>&nbsp;</p>
                </div>
            </div>

            <Hidden smDown>
                <NewHomeFooter />
            </Hidden>
            <Hidden mdUp>
                <NewHomeFooterForMobile />
            </Hidden>
        </>
    );
});

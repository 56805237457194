import React, { useState, useEffect } from 'react';
import { rst } from 'rt-state';
import { AwesomeIcon } from '../../../components/fontAwesomeIcon';
import { AutoAddressComplete } from './autoAddressComplete';
import { StreetView } from '../../PriceMap/Layers/PropertyInfo';
import { Button, makeStyles, CircularProgress } from '@material-ui/core';
import { GeneralSituation } from './streetDetails/generalSituation';
import { HouseStatistics } from './streetDetails/houseStatistics';
import { HouseSale } from './streetDetails/houseSale';
import { ApartmentStatistics } from './streetDetails/apartmentStatistics';
import { queryProp_byGraphQL, query_byGraphQL, streetQuery } from '../../../commons/graphqlClient/graphAPI';
import { api_getPropertyFeatures } from '../../../services/property';
import { constructPropObjFromFeature, getTypeByFeature } from '../../PriceMap/support/propertyFeatureHelper';
import { mobileNavButton } from '../consts/styleConsts';
import { Permission } from '../../../commons/security/authz';
import { SecurityProvider } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { useHistory } from 'react-router';
import { NoVerifyEmailReceived } from '../member/verifyEmail/NoVerifyEmailReceived';
import { exceedDailyLimit, saveSearchHistory, saveUserAccessLog } from '../../../services/userCenter';
import { UserCenterEntityType } from '../../../models/backend/userCenter';
import { MemberInfo } from '../member/memberInfo';
import { VariousPopup } from '../member/signUp/TipAfterSignup';

const useStyles = makeStyles({
    button: {
        width: '100%',
        height: '42px',
        background: '#009e90',
        borderRadius: '4px',

        fontSize: '16px',
        lineHeight: '42px',
    },
});

export const StreetCard = (props: any) => {
    const history = useHistory();
    const securityProvider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const [popType, setPopType] = useState(-1);

    const { propId, choosedItem, itemChoosedEvt, backEvt, embedded, inDetails } = props;

    const [state, setState] = useState({
        property: {} as any,
        prop_ql: {} as any,
        address: '',
        propType: '',
        mixedPropObj: {} as any,
        loading: false,
        showAll: props.inDetails,
        oneProp_ql: null as any,
        inDetails: inDetails,
        showEmailVerify: false,
    });

    const [showMember, setShowMember] = useState(false);
    const [showWelcomeAreaMember, setShowWelcomeAreaMember] = useState(false);

    const isMember = () => {
        return securityProvider.hasPermission(Permission.PROPERTY_BUYER);
    };
    const isTrial = () => {
        return securityProvider.hasPermission(Permission.TRIAL);
    };
    const userExpired = () => {
        return securityProvider.isExpired(
            securityProvider.hasPermission(Permission.PROPERTY_BUYER) ? Permission.PROPERTY_BUYER : Permission.TRIAL,
        );
    };
    const showSignup = () => {
        appProvider.state.authDialog = 'signup';
    };
    const toPayment = () => {
        window.open('/joinMember', '_self');
    };

    const handleBack = () => {
        if (state.showAll) {
            setState({
                ...state,
                showAll: false,
            });
        } else {
            backEvt();
        }
    };
    const showDetails = async () => {
        try {
            await securityProvider.refreshProfile();
        } catch (e) {}
        if (!securityProvider.hasLogin()) {
            showSignup();
        } else if (securityProvider.getUserProfile().userStatus == '1') {
            setState({
                ...state,
                showEmailVerify: true,
            });
        } else {
            const userUsage: any = await exceedDailyLimit(propId);
            if (userUsage.popType != -1) {
                setShowMember(true);
                setPopType(userUsage.popType);
            } else {
                saveUserAccessLog('QUERY_STREET_CARD_DETAIL', securityProvider.getUserProfile()?.id, {
                    streetId: propId,
                });

                saveSearchHistory({
                    entityId: propId,
                    entityType: UserCenterEntityType.Street,
                });

                const currentURL = window.location.search;
                history.push(currentURL + '&showingDetails=true');
                setState({
                    ...state,
                    showAll: true,
                });
            }
        }
    };
    const classes = useStyles();
    useEffect(() => {
        console.log('初始数据：', propId);
        EventBus.dispatch(EVENT_NAMES.SHOW_MOBLIE_LOADING, true);
        setState({
            ...state,
            loading: true,
        });

        query_byGraphQL(streetQuery, propId).then(
            (prop_ql: any) => {
                if (prop_ql.data.street.streetSold?.length > 0) {
                    queryProp_byGraphQL(prop_ql.data.street.streetSold[0].propId).then((propertyResult: any) => {
                        if (propertyResult.errors) {
                        } else {
                            const {
                                middleNumber,
                                streetName,
                                streetType,
                                state,
                                post,
                                localityName,
                            } = prop_ql.data.street;
                            const address = `${middleNumber} ${streetName} ${streetType} ${localityName}, ${state} ${post}`;

                            setState({
                                ...state,
                                prop_ql: prop_ql.data,
                                address: address,
                                loading: false,
                                oneProp_ql: propertyResult.data,
                            });
                        }

                        EventBus.dispatch(EVENT_NAMES.SHOW_MOBLIE_LOADING, false);
                    });
                } else {
                    setState({
                        ...state,
                        prop_ql: prop_ql.data,
                        loading: false,
                    });

                    EventBus.dispatch(EVENT_NAMES.SHOW_MOBLIE_LOADING, false);
                }
            },
            (err) => {
                setState({
                    ...state,
                    loading: false,
                });

                EventBus.dispatch(EVENT_NAMES.SHOW_MOBLIE_LOADING, false);
            },
        );
    }, [propId]);

    return (
        <>
            {!state.loading ? (
                <>
                    <style>
                        {`
                            *{
                                box-sizing: border-box;
                            }
                            .MuiAccordionDetails-root{
                                padding: 0;
                            }
                            .MuiPaper-root.MuiAccordion-root{
                                border-radius: 0;
                                box-shadow: none;
                                border-top: 1px solid #e8ecf0;
                            }
                            div.MuiAccordionSummary-content{
                                margin: 20px 0;
                            }
                            .MuiAccordionSummary-root{
                                padding: 10px;
                            }
                            .MuiAccordionSummary-expandIcon{
                                color: rgb(131, 131, 131);
                            }
                            .MuiSvgIcon-root{
                                font-size: 35px;
                            }
                        `}
                    </style>
                    {!embedded && (
                        <div style={{ display: 'flex', marginTop: 10, alignItems: 'center', marginLeft: 5 }}>
                            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
                                <AwesomeIcon
                                    icon={<img src="assets/mobileIcons/leftArrow.svg"></img>}
                                    clickEvt={handleBack}
                                    style={{
                                        fontSize: 20,
                                        color: '#b2b2b2',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}></AwesomeIcon>
                            </div>
                            <div style={{ flex: 0.95 }}>
                                <AutoAddressComplete
                                    init_choosedItem={props.choosedItem}
                                    itemChoosedEvt={(value, newChosen) => {
                                        state.showAll = false;
                                        props.itemChoosedEvt(value, newChosen);
                                    }}
                                    queryType={'2'}></AutoAddressComplete>
                            </div>
                        </div>
                    )}
                    {!embedded && (
                        <div style={{ display: 'flex', padding: '10px', fontSize: '13px' }}>
                            {' '}
                            <TR name="oneStreetFound"></TR>
                            <TR name="colon"></TR>
                        </div>
                    )}
                    {!embedded && (
                        <div
                            style={{
                                marginTop: '1rem',
                                width: '100%',
                            }}>
                            {state.prop_ql.street && (
                                <StreetView
                                    w={window.screen.width}
                                    h={window.screen.width / 2}
                                    lat={state.prop_ql.street.lat}
                                    lng={state.prop_ql.street.lon}
                                    onMap={false}
                                    showFor="infopanel"
                                    imageBorderRadius={1}
                                    address={state.address}></StreetView>
                            )}

                            <div style={{ marginTop: 10 }}></div>
                        </div>
                    )}
                    <GeneralSituation pageData={state}></GeneralSituation>
                    {state.showAll && !state.loading ? (
                        <>
                            {state.oneProp_ql ? <HouseStatistics pageData={state}></HouseStatistics> : null}
                            <ApartmentStatistics pageData={state}></ApartmentStatistics>
                            <HouseSale pageData={state}></HouseSale>
                        </>
                    ) : (
                        <div style={{ padding: '20px 0', background: '#fff', textAlign: 'center' }}>
                            {!embedded && (
                                <>
                                    <Button
                                        onClick={showDetails}
                                        className={classes.button}
                                        style={{
                                            ...mobileNavButton,

                                            width: '80%',
                                            height: appProvider.getLanguage() == 'zh' ? '42px' : '60px',
                                            padding: 10,
                                            background: '#009e90',
                                            color: 'white',
                                        }}
                                        variant="outlined">
                                        <TR name="moreStreetDSata"></TR>
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        minHeight: embedded ? 200 : 500,
                        alignItems: 'center',
                    }}>
                    <CircularProgress size={48}></CircularProgress>
                </div>
            )}

            <NoVerifyEmailReceived
                closeMessage={() => {
                    setState({
                        ...state,
                        showEmailVerify: false,
                    });
                }}
                showVerifyEmail={state.showEmailVerify}></NoVerifyEmailReceived>

            <MemberInfo
                show={showMember}
                popType={popType}
                onSuccessAreaMember={() => {
                    setShowMember(false);
                    setShowWelcomeAreaMember(true);
                }}
                onClose={() => {
                    setShowMember(false);
                }}></MemberInfo>

            <VariousPopup
                open={showWelcomeAreaMember}
                messageTitleKey={'welcomeAsAreaMember'}
                messageKey={''}
                onClose={() => setShowWelcomeAreaMember(false)}></VariousPopup>
        </>
    );
};

import React from 'react';
import { rst } from 'rt-state';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { XText } from '../Text';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { TR } from '../../commons';
const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.primary.main,
            padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`,
            color: 'white',
            maxHeight: theme.spacing(6),
        },
        dummy: {},
    };
});
export const XDialogTitle = rst.create<{
    title: string;

    onReset?: () => void;
    onCancel?: () => void;
}>((ctx) => {
    function _onReset() {
        const { onReset } = ctx.props;
        if (onReset) {
            onReset();
        }
    }
    function _onCancel() {
        const { onCancel } = ctx.props;
        if (onCancel) {
            onCancel();
        }
    }
    return (props) => {
        const classes = useStyles(props);
        return (
            <DialogTitle className={classes.root} disableTypography>
                <Grid container alignItems={'center'}>
                    <XText variant={'subtitle1'} className={clsx(props.className)}>
                        <TR name={props.title} />
                    </XText>
                    <Box flexGrow={1} />
                    <Button onClick={_onReset}>
                        <Box color={'white'} component={'span'} fontSize={15}>
                            重置
                        </Box>
                    </Button>
                    <IconButton onClick={_onCancel}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                </Grid>
            </DialogTitle>
        );
    };
});

import { reject } from 'lodash';
import { SecurityProvider } from '../commons';

import { API } from '../commons/axios';
import { EventBus, EVENT_NAMES } from '../commons/EventBus';
import { MAP_APIS_ENDPOINTS, setEndpointParams } from './EndPoints';

export function getSuburbByPoint(latLng) {
    return new Promise((resolve, reject) => {
        const getURL = setEndpointParams(MAP_APIS_ENDPOINTS.GET_SUBURB_BY_POINT, { lat: latLng.lat, lng: latLng.lng });
        return API.get(null, getURL).then(
            (suburb) => {
                resolve(suburb);
            },
            (err) => {
                reject(err);
            },
        );
    });
}

export function getStreetRankForSuburb(suburbId: any) {
    const getURL: string = setEndpointParams(MAP_APIS_ENDPOINTS.GET_STREET_RANK_BY_SUBURB, { localityId: suburbId });

    return new Promise((resolve, reject) => {
        return API.get(null, getURL).then(
            (rankList) => {
                resolve(rankList);
            },
            (err) => {
                reject(err);
            },
        );
    });
}

export async function getRankingList(name: string) {
    return await API.get(null, `https://abstg.api.aihouse.io/localityfilter/rankinglist?filter_value=${name}`);
}

export async function getSuburbDataInMapView(suburbIds) {
    EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_MAP_PROGRESSBAR, { show: true });
    return new Promise((resolve, reject) => {
        API.post(null, MAP_APIS_ENDPOINTS.GET_SUBURBDATA_IN_MAPVIEW, suburbIds, {}).then(
            (result: any) => {
                resolve(result);
                EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_MAP_PROGRESSBAR, { show: false });
            },
            (err) => {
                resolve([]);
                EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_MAP_PROGRESSBAR, { show: false });
            },
        );
    });
}

export async function getVisibleSuburbIds(cornerData) {
    EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_MAP_PROGRESSBAR, { show: true });
    return new Promise((resolve, reject) => {
        API.post(null, MAP_APIS_ENDPOINTS.GET_SUBURBIDS_IN_MAPVIEW, cornerData, {}).then(
            (result: any) => {
                resolve(result);
                EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_MAP_PROGRESSBAR, { show: false });
            },
            (err) => {
                resolve([]);
                EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_MAP_PROGRESSBAR, { show: false });
            },
        );
    });
}

export async function getSuburbNameByIdFromLocalData(suburbId) {
    const allSuburbs: any = await API.get(null, 'static/data/suburbList.json');
    const foundSuburbs = allSuburbs.filter((s) => {
        return s.id == suburbId;
    });
    return foundSuburbs[0].locality_name;
}

// export async function getRankingList(name: string): Promise<Array<{ [key: string]: string }>> {
//     return (await API.get(null, `https://abstg.api.aihouse.io/localityfilter/rankinglist?filter_value=${name}`)) as any;
// }

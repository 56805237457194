import { XTableColumn } from '../../../../components/Table';
import { commonColumns, TitleAndColumns } from './common';

const shoppingColumns: XTableColumn[] = [
    ...commonColumns,
    { name: 'ranking', sort: true, align: 'right', label: '购物星级' },
];
const treeCoverageColumns: XTableColumn[] = [
    ...commonColumns,
    { name: 'coverage', sort: true, align: 'right', label: '树木覆盖' },
];
const nearWaterColumns: XTableColumn[] = [
    ...commonColumns,
    { name: 'score', sort: true, align: 'right', label: '亲水得分' },
];

export const lifeTitleAndColumns: TitleAndColumns = {
    1: { title: '购物星级排行榜', columns: shoppingColumns },
    2: { title: '树木覆盖排行榜', columns: treeCoverageColumns },
    3: { title: '亲水得分排行榜', columns: nearWaterColumns },
};

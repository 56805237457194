import React from 'react';
import { rst } from 'rt-state';
import { makeStyles } from '@material-ui/core/styles';
import { FirstPageHead } from '../Home/components/FirstPageHead';
import { Button, GridList, Hidden, Theme, useMediaQuery } from '@material-ui/core';
import { TR } from '../../commons';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import { HomeFooter } from '../Home/components/HomeFooter';
import { MyFavouriteCard } from './MyFavouriteCard';
import { MyFavouriteCardForMobile } from './MyFavouriteCard/MyFavouriteCardForMobile';
import Box from '@material-ui/core/Box';
import { ErrorBox } from '../PriceMap/components/ErrorBox';
import { SortMenu } from './SortMenu';
import { FavouriteDto, FavouritePo, FavouriteUpdatePo } from '../../models/backend/userCenter';
import { getFavourites, removeFavourite, updateFavourite } from '../../services/userCenter';
import { Arrays, SortFields } from '../../commons/array';
import { MapGlobalVars } from '../PriceMap/support/PriceMapConsts';
import { ROUTES } from '../../routes';
import { EventBus, EVENT_NAMES } from '../../commons/EventBus';
import { NewHomePageHead } from '../NewHome/components/01NewHomePageHead';
import { NewHomeFooter } from '../NewHome/components/11NewHomeFooter';
import { NewHomeFooterForMobile } from '../NewHome/components/11NewHomeFooterForMobile';

const useStyles = makeStyles((theme) => {
    return {
        myFavoriteContainer: {
            maxWidth: 1140,
            margin: '0 auto',
            padding: '30px 15px',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        button: {
            width: 176,
            height: 39,
            padding: '20px 0px',
            margin: '5px 0px 35px 0px',
            '& span': {
                fontSize: 16,
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
        icon: {
            paddingRight: '5px',
        },
    };
});

export const MyFavouritePage = rst.create((ctx) => {
    const favouritesState = rst.state({
        origin: [] as FavouriteDto[],
        sorted: [] as FavouriteDto[],
    });
    const sortFieldsState = rst.stateS<SortFields>({
        top: 'desc',
        'entity.propBed': null,
        'entity.landSize': null,
        'entity.closestStationDistance': null,
        updateTime: 'desc',
    });

    function showFavsOnMap() {
        if (!favouritesState.origin || favouritesState.origin.length == 0) {
            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                show: true,
                title: 'ERROR',
                content: 'NO_ANY_FAV',
            });
            return;
        }
        window.open(ROUTES.PriceMap.path + '?showPropFav=1', '_self');
    }

    async function onClickTop(dto: FavouriteDto) {
        const po = FavouriteUpdatePo.from(dto);
        po.top = !po.top;
        console.log(po);
        const ret = await updateFavourite(po);
        console.log(ret);
        await init();
    }
    async function onClickFavourite(dto: FavouriteDto) {
        const ret = await removeFavourite(dto.id);
        console.log(ret);
        await init();
    }

    rst.watch(
        (newValues) => {
            const [sortFields, origin] = newValues;
            const sorted = [...origin];
            sorted?.sort(Arrays.compareFunc(sortFields)); // eslint-disable-line
            console.log({ sorted });
            favouritesState.origin = origin;
            favouritesState.sorted = sorted;
        },
        () => [sortFieldsState.value, favouritesState.origin],
    );

    async function init() {
        getFavourites().then((data) => {
            favouritesState.origin = data ?? [];
        });
    }

    init().then();

    return (props) => {
        const classes = useStyles();
        const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

        const allCards = favouritesState.sorted?.map((dto) => {
            if (matches) {
                return (
                    <MyFavouriteCard
                        onClickTop={onClickTop}
                        onClickFavourite={onClickFavourite}
                        dto={dto}
                        key={dto.id}
                    />
                );
            }
            return (
                <MyFavouriteCardForMobile
                    onClickTop={onClickTop}
                    onClickFavourite={onClickFavourite}
                    dto={dto}
                    key={dto.id}
                />
            );
        });

        return (
            <div>
                <ErrorBox></ErrorBox>
                <NewHomePageHead />
                <Box display={'flex'} flexDirection={'column'} className={classes.myFavoriteContainer}>
                    <div className={classes.header}>
                        {/* <Button
                            onClick={showFavsOnMap}
                            className={classes.button}
                            style={{ backgroundColor: '#53CC7B' }}>
                            <MapOutlinedIcon className={classes.icon} />
                            <TR name={'MAP VIEW'} />
                        </Button> */}
                        <SortMenu sortFieldsState={sortFieldsState} />
                    </div>
                    {Arrays.hasItems(allCards) && (
                        <GridList cellHeight={160} cols={3}>
                            {allCards}
                        </GridList>
                    )}
                </Box>
                <Hidden smDown>
                    <NewHomeFooter />
                </Hidden>
                <Hidden mdUp>
                    <NewHomeFooterForMobile />
                </Hidden>
            </div>
        );
    };
});

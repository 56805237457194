import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR, TRHtml } from '../../../commons';
import { isMobileDevice } from '../../../commons/utils';
import { getThemeValue } from '../support/PriceMapTheme';
const useStyles = makeStyles((theme) => {
    return {
        root: {
            borderRadius: isMobileDevice() ? 5 : 8,
            paddingTop: isMobileDevice() ? 4 : 11,
            paddingBottom: isMobileDevice() ? 4 : 11,
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            alignItems: 'center',
            height: isMobileDevice() ? '40px' : null,
            cursor: 'pointer',
        },
        buttonBackground: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: isMobileDevice() ? '98%' : '100%',
            background: getThemeValue('MAP_BUTTON_BACKGROUND'),
            opacity: 0.3,
            height: '100%',
            borderRadius: isMobileDevice() ? 5 : 8,
            zIndex: 1,
        },
        label: {
            fontSize: isMobileDevice() ? 10 : 14,
            color: '#666666',
            zIndex: 2,
        },
    };
});
export const MapButton = rst.create<{
    bg?: string;
    w: any;
    clickEvent?: any;
    label: string;
    id?: string;
    selected?: boolean;
    textColor?: any;
    bgOpcacity?: any;
    fontSize?: any;
    disabled?: boolean;
    shadow?: any;
}>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root} id={props.id} style={{ width: props.w }} onClick={props.clickEvent}>
                <div
                    className={classes.buttonBackground}
                    style={{
                        background: props.disabled ? 'gray' : props.bg,
                        opacity: props.bgOpcacity ? props.bgOpcacity : '0.3',
                        boxShadow: props.shadow,
                    }}></div>
                <span
                    className={classes.label}
                    style={{
                        fontSize: props.fontSize ? props.fontSize : null,
                        color: props.disabled ? '#999999' : props.textColor ? props.textColor : props.bg,
                    }}>
                    <TRHtml html={props.label}></TRHtml>
                </span>
            </div>
        );
    };
});

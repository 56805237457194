export const PAGE_ACTIONS = {
    priceMap: {
        label: 'price Map',
        events: {
            filterMapByProperty: {
                description: 'click the property type button on filter panel',
            },
            searchByAddress: {
                description: 'search the property address',
            },
            searchBySuburb: {
                description: 'search suburb detail',
            },
            addAsFav: {
                description: 'set property as favoriate',
            },
        },
    },
};

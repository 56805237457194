import { makeStyles } from '@material-ui/core';

import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR, TRHtml } from '../../../../commons';
import { AppProvider } from '../../../../commons/appProvider';
import { changeToFirstCaptial, convertToM, isMobileDevice } from '../../../../commons/utils';
import { MapGlobalVars } from '../../../PriceMap/support/PriceMapConsts';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { generateUUID } from './../../../../commons/utils';

const useStyles = makeStyles((theme) => {
    return {
        chartTitleDiv: {
            display: 'flex',
        },
        chartTitle: {
            padding: '20px',
            alignItems: 'center',
            fontSize: 14,

            fontWeight: 400,
            display: 'flex',
            width: '100%',
        },
        legend: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        legendItem: {
            width: '11px',
            height: 8,
        },
    };
});
/*"locality_trends": {"year": [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019], "median": [360000, 422000, 460000, 515000, 610000, 625000, 713500, 721000, 730000, 710000]}, "city_trends": {"year": [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019], "median": [480000, 485000, 520000, 555000, 625000, 698000, 720000, 730000, 725000, 700000]}*/

declare var Highcharts;

const chartColor = {
    sydney: '#ff9f40',
    council: '#5ad8a6',
    local: '#5b8ff9',
};

export const MobileLocalityPriceTrend = rst.create<{
    localData?: any;
    locality?: any;
    soldRecords: any;
    apt?: any;
}>((ctx) => {
    console.log(ctx.props.localData);
    console.log(ctx.props.soldRecords);

    EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_LOCALDATA, { local: ctx.props.localData });

    const appProvider = AppProvider.use();

    const sampleData = {
        sydneyData: {
            yearly_median: [515000, 610000, 580000, 620000, 660000, 739000, 870000, 878000, 931000, 980000],
            year: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019],
        },
    };

    const priceHouseArray = ctx.props.apt ? ctx.props.localData.priceApt : ctx.props.localData.priceHouse;
    const fittingStart = [];
    const fittingEnd = [];
    const reformatYearlyMedian = () => {
        const newYearlyData = [];
        for (let i = 1; i < priceHouseArray.length; i++) {
            if (priceHouseArray[i] === 0 && priceHouseArray[i + 1] != 0) {
                priceHouseArray[i] = priceHouseArray[i - 1] + (priceHouseArray[i + 1] - priceHouseArray[i - 1]) / 2;
                fittingStart.push(i - 1);
                fittingEnd.push(i + 1);
            }
            if (priceHouseArray[i] === 0 && priceHouseArray[i + 1] === 0 && priceHouseArray[i + 2] != 0) {
                priceHouseArray[i] = priceHouseArray[i - 1] + (priceHouseArray[i + 2] - priceHouseArray[i - 1]) / 3;
                priceHouseArray[i + 1] = priceHouseArray[i] + (priceHouseArray[i + 2] - priceHouseArray[i - 1]) / 3;
                fittingStart.push(i - 1);
                fittingEnd.push(i + 2);
            }
            if (
                priceHouseArray[i] === 0 &&
                priceHouseArray[i + 1] === 0 &&
                priceHouseArray[i + 2] === 0 &&
                priceHouseArray[i + 3] != 0
            ) {
                priceHouseArray[i] = priceHouseArray[i - 1] + (priceHouseArray[i + 3] - priceHouseArray[i - 1]) / 4;
                priceHouseArray[i + 1] = priceHouseArray[i] + (priceHouseArray[i + 3] - priceHouseArray[i - 1]) / 4;
                priceHouseArray[i + 2] = priceHouseArray[i + 1] + (priceHouseArray[i + 3] - priceHouseArray[i - 1]) / 4;
                fittingStart.push(i - 1);
                fittingEnd.push(i + 3);
            }
            if (
                priceHouseArray[i] === 0 &&
                priceHouseArray[i + 1] === 0 &&
                priceHouseArray[i + 2] === 0 &&
                priceHouseArray[i + 3] === 0 &&
                priceHouseArray[i + 4] != 0
            ) {
                priceHouseArray[i] = priceHouseArray[i - 1] + (priceHouseArray[i + 4] - priceHouseArray[i - 1]) / 5;
                priceHouseArray[i + 1] = priceHouseArray[i] + (priceHouseArray[i + 4] - priceHouseArray[i - 1]) / 5;
                priceHouseArray[i + 2] = priceHouseArray[i + 1] + (priceHouseArray[i + 4] - priceHouseArray[i - 1]) / 5;
                priceHouseArray[i + 3] = priceHouseArray[i + 2] + (priceHouseArray[i + 4] - priceHouseArray[i - 1]) / 5;
                fittingStart.push(i - 1);
                fittingEnd.push(i + 4);
            }
            if (
                priceHouseArray[i] === 0 &&
                priceHouseArray[i + 1] === 0 &&
                priceHouseArray[i + 2] === 0 &&
                priceHouseArray[i + 3] === 0 &&
                priceHouseArray[i + 4] === 0 &&
                priceHouseArray[i + 5] != 0
            ) {
                priceHouseArray[i] = priceHouseArray[i - 1] + (priceHouseArray[i + 5] - priceHouseArray[i - 1]) / 6;
                priceHouseArray[i + 1] = priceHouseArray[i] + (priceHouseArray[i + 5] - priceHouseArray[i - 1]) / 6;
                priceHouseArray[i + 2] = priceHouseArray[i + 1] + (priceHouseArray[i + 5] - priceHouseArray[i - 1]) / 6;
                priceHouseArray[i + 3] = priceHouseArray[i + 2] + (priceHouseArray[i + 5] - priceHouseArray[i - 1]) / 6;
                priceHouseArray[i + 4] = priceHouseArray[i + 3] + (priceHouseArray[i + 5] - priceHouseArray[i - 1]) / 6;
                fittingStart.push(i - 1);
                fittingEnd.push(i + 5);
            }
        }
        console.log('fitting', fittingStart, fittingEnd);
        priceHouseArray.forEach((yearlyMedia) => {
            newYearlyData.push(yearlyMedia != 0 ? yearlyMedia : null);
        });
        return newYearlyData;
    };

    const zoneStyle = () => {
        const zoneStyleArray = [];
        for (let j = 0; j < fittingStart.length; j++) {
            const obj1 = { value: fittingStart[j], color: '#009E90' };
            const obj2 = { value: fittingEnd[j], dashStyle: 'dot' };
            zoneStyleArray.push(obj1, obj2);
        }
        zoneStyleArray.push({ color: '#009E90' });
        return zoneStyleArray;
    };

    const x_catagories = [];

    ctx.props.localData.year.forEach((value, idx) => {
        if (idx == 0) {
            x_catagories.push(value);
        } else {
            x_catagories.push((value + '').substr(2, 2));
        }
    });
    const comId = generateUUID();
    function loadChart() {
        if (document.getElementById(`suburbTrendContainer_${comId}`) == null) {
            return;
        }
        Highcharts.chart(
            `suburbTrendContainer_${comId}`,
            {
                chart: {
                    height: 224,

                    marginBottom: isMobileDevice() ? 30 : 20,
                },
                title: {
                    text: '',
                },

                subtitle: {
                    text: '',
                },

                yAxis: {
                    title: {
                        text: '',
                    },

                    opposite: true,
                    min: 0,
                    max: Math.max(...ctx.props.localData.priceHouse) * 1.2,
                },

                xAxis: {
                    accessibility: {
                        rangeDescription: '',
                    },
                    type: 'category',
                    categories: x_catagories,
                    crosshair: true,
                    endOnTick: false,
                    startOnTick: false,
                },

                legend: {
                    enabled: false,
                },
                credits: { enabled: false },
                tooltip: {
                    enabled: true,
                    backgroundColor: 'white', // 背景颜色
                    borderColor: 'black', // 边框颜色
                    borderRadius: 10, // 边框圆角
                    borderWidth: 1, // 边框宽度
                    animation: true, // 是否启用动画效果
                    //format the date and numbers
                    formatter: function () {
                        const _this: any = this;
                        console.log(_this);
                        var d = new Date(_this.x);

                        var s = '';

                        s += '<b>' + (parseInt(_this.x) < 50 ? '20' : '19') + _this.x + '</b>';
                        if (appProvider.getLanguage() === 'zh') {
                            s += '<br/><span>中位价: ' + '$' + (_this.y / 1000000).toFixed(2) + 'm' + '</span>';
                        } else {
                            s += '<br/><span>Median price: ' + '$' + (_this.y / 1000000).toFixed(2) + 'm' + '</span>';
                        }
                        return s;
                    },
                },
                plotOptions: {
                    series: {
                        enableMouseTracking: true,
                        marker: {
                            enabled: false,
                        },
                    },
                    areaspline: {
                        fillOpacity: 0.25,
                    },
                    area: {
                        stacking: 'normal',
                        lineColor: '#666666',
                        lineWidth: 2,
                        opacity: 1,

                        marker: {
                            lineWidth: 1,
                            lineColor: '#666666',
                        },
                    },
                },

                series: [
                    {
                        name: 'Local',
                        color: chartColor.local,
                        fillOpacity: 0.3,
                        data: reformatYearlyMedian(),
                        opacity: 1,
                        lineColor: '#009E90',

                        zoneAxis: 'x',
                        zones: zoneStyle(),
                    },
                ],
            },
            (chart) => {
                if (ctx.props.soldRecords?.length > 0) {
                    ctx.props.soldRecords.forEach((sold) => {
                        const yPos = chart.yAxis[0].toPixels(sold.soldPrice);
                        const firstYear = ctx.props.localData.year[0];
                        const yearPosition = parseInt(sold.soldContractDate.substr(0, 4)) - firstYear;
                        const xPos = chart.xAxis[0].toPixels(yearPosition);
                        console.log('y pos is ' + yPos);
                        console.log('x pos is ' + xPos);

                        // chart.renderer
                        //     .image('assets/mobileIcons/bg.svg', xPos - 20, yPos - 35, 66, 50)
                        //     .attr({ zIndex: 990 })
                        //     .add();
                        chart.renderer
                            .text('$' + convertToM(sold.soldPrice) + 'm', xPos - 8, yPos)
                            .css({
                                fontSize: '13px',
                                weight: '700',
                                color: '#FC6B53',
                            })
                            .attr({ zIndex: 991 })
                            .add();
                        chart.renderer
                            .image('assets/mobileIcons/houseOnChart.svg', xPos, yPos + 5, 20, 20)
                            .attr({ zIndex: 990 })
                            .add();
                    });
                }
            },
        );
    }
    const processChartData = () => {
        console.log('Will process chart data');
    };

    return (props) => {
        setTimeout(() => {
            processChartData();
            loadChart();
            console.log('processed');
        }, 400);
        const classes = useStyles();
        return (
            <>
                <div className={classes.chartTitle}>
                    <img style={{ width: '20px', height: '20px' }} src="/assets/mobileIcons/houseOnChart.svg"></img>

                    <span style={{ marginLeft: 3 }}>
                        <TR name="label_historySold"></TR>{' '}
                    </span>
                    <div style={{ marginLeft: '15px' }}></div>
                    <img style={{ width: '20px', height: '20px' }} src="/assets/mobileIcons/trendSample.svg"></img>

                    <span style={{ marginLeft: 3 }}>
                        {ctx.props.apt ? (
                            <TRHtml
                                html="label_apt30YearsTrend"
                                params={{ locality: changeToFirstCaptial(props.locality.toLowerCase()) }}></TRHtml>
                        ) : (
                            <TRHtml
                                html="label_house30YearsTrend"
                                params={{ locality: changeToFirstCaptial(props.locality.toLowerCase()) }}></TRHtml>
                        )}
                    </span>
                </div>
                <div className="HOMEON_Chart" style={{ minHeight: '225px' }} id={`tenYearsTrendChart_${comId}`}>
                    {' '}
                    <div id={`suburbTrendContainer_${comId}`}> </div>
                </div>
            </>
        );
    };
});

export const LOCALSTORAGE_KEYS = {
    KEY_MAPBORDERS: 'MAPBORDERS',
};

export const MAP_LAYER_TYPES = {
    POLYGON: '1',
    POLYLINE: '2',
    POINT: '3',
};

export const MAP_SUBURB_FILL_COLOR = [
    {
        max: 1,
        color: '#0051CF',
    },
    {
        min: 1,
        max: 1.5,
        color: '#0894DB',
    },

    {
        min: 1.5,
        max: 2,
        color: '#06CD50',
    },
    {
        min: 2,
        max: 2.8,
        color: '#C9D902',
    },
    {
        min: 2.8,
        color: '#F4880B',
    },
];

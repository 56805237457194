import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'absolute',

            left: 20,
            zIndex: 500,
            display: 'flex',
            flexDirection: 'column',

            borderRadius: '15px',
            padding: '10px',
        },
    };
});

export const FloatLayer = rst.create<{}>((ctx) => {
    return (props) => {
        const classes = useStyles();

        return (
            <div className={classes.root} style={props.style}>
                {props.children}
            </div>
        );
    };
});

import { rst } from 'rt-state';
import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Switch,
    SwitchClassKey,
} from '@material-ui/core';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { TR } from '../../../commons';
import { RankTable } from './RankTable';
import { MapGlobalVars } from '../support/PriceMapConsts';
import { getStreetRankForSuburb } from '../../../services/suburb';
import { startCase } from 'lodash';
import { isMobileDevice, stickNumTo5_10 } from '../../../commons/utils';
import { queryStreetRank } from '../../../commons/graphqlClient/graphAPI';
import { Glossary } from '../../PropertyReport/components/Glossary';
import { StreetRanks } from './streetRanks';

export const SuburbSoldRank = rst.create<{ forNewMobile?: boolean }>((ctx) => {
    EventBus.on(EVENT_NAMES.EVENT_OPEN_STREET_DIALOG, (evtData) => {
        state.expensiveStreets = [];
        state.chepestStreets = [];
        state.lowestChangeStreets = [];
        state.highestChangeStreets = [];

        const suburbId = evtData.suburbId;

        queryStreetRank(suburbId || MapGlobalVars.clickedSuburbData?.id).then((qlResult: any) => {
            const rankResult = qlResult.data.locality.streetRanking;
            state.expensiveStreets = [];
            rankResult.mostExpensive.map((loopStreet) => {
                state.expensiveStreets.push({
                    columnValues: [
                        loopStreet.streetName,
                        stickNumTo5_10(loopStreet.suburbMedianPct * 100).toFixed(0) + '%',
                    ],
                });
            });
            rankResult.mostEconomical.map((loopStreet) => {
                state.chepestStreets.push({
                    columnValues: [
                        loopStreet.streetName,
                        stickNumTo5_10(loopStreet.suburbMedianPct * 100).toFixed(0) + '%',
                    ],
                });
            });

            rankResult.lowestChanginghands.map((loopStreet) => {
                state.lowestChangeStreets.push({
                    columnValues: [loopStreet.streetName, (loopStreet.turnoverRate * 100).toFixed(0) + '%'],
                });
            });

            rankResult.highestChanginghands.map((loopStreet) => {
                state.highestChangeStreets.push({
                    columnValues: [loopStreet.streetName, (loopStreet.turnoverRate * 100).toFixed(0) + '%'],
                });
            });

            console.log(state.expensiveStreets);
        });

        state.openSuburbRank = true;
    });
    const state = rst.state({
        openSuburbRank: false,
        expensiveStreets: [],
        chepestStreets: [],
        lowestChangeStreets: [],
        highestChangeStreets: [],
    });
    const handleClose = () => {
        state.openSuburbRank = false;
    };
    return (props) => {
        return (
            <>
                <Dialog fullScreen={isMobileDevice() ? true : false} open={state.openSuburbRank}>
                    <DialogTitle id="customized-dialog-title">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <TR name={'SuburbRankTitle'}></TR>
                            <div>
                                <Glossary glossaryId="11" />
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <StreetRanks
                            expensiveStreets={state.expensiveStreets}
                            chepestStreets={state.chepestStreets}
                            highestChangeStreets={state.highestChangeStreets}
                            lowestChangeStreets={state.lowestChangeStreets}></StreetRanks>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };
});

import { Divider, Hidden, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { rst } from 'rt-state';
import { TR, TRHtml } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { isMobileDevice } from '../../../commons/utils';
import { Tooltip, Button, Box } from '@material-ui/core';
import { QRCode } from './QRCode';
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '30px 0px 30px 0px',

        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: isMobileDevice() ? 'column' : 'row',
        justifyContent: 'space-around',
        fontSize: isMobileDevice() ? '13px' : '16px',
        [theme.breakpoints.down('sm')]: {
            padding: '28px 16px',
        },
    },
    item: {
        width: '1200px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        padding: '0px 0px 0px 0px',
        background: 'rgb(255 255 255)',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '10px 10px 10px 10px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0px',
        },
    },
    title: {
        fontSize: '22px',
        textAlign: 'left',
        display: 'inline-block',
        padding: '10px 10px 10px 10px',
        // width: '50%',
        '& h4': {
            fontWeight: 'bold',
            fontSize: '30px',
            // display: 'inline-block',
            // fontWeight: 'normal',
            margin: '0px 0px 0px 0px',
            float: 'center',

            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                lineHeight: '50px',
                margin: '0px 0px 5px 0px',
            },
        },
        '& p': {
            //fontSize: '14px',
            fontSize: '20px',
            // lineHeight: '70%',
            //color: 'rgb(123 123 123)',
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: {
                margin: '0px',
                fontSize: '14px',
            },
        },
    },
    right: {
        padding: '10px 10px 10px 10px',
        textAlign: 'center',
        '& p': {
            //fontSize: '14px',
            fontSize: '14px',
            //color: 'rgb(123 123 123)',
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: {
                margin: '0px',
                fontSize: '14px',
            },
        },
        '& img': {
            //display: 'inline-block',
            fontWeight: 'normal',
            margin: '0px 0px 0px 0px',
            float: 'center',
            padding: '10px 10px 10px 10px',
            width: '300px',
            height: '300px',
            [theme.breakpoints.down('sm')]: {
                //fontSize: '14px',
                lineHeight: '26px',
                margin: '0px 0px 5px 0px',
            },
        },
    },
}));

export const NewHomeQRCode = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        const appProvider = AppProvider.use();

        if (appProvider.getLanguage() == 'zh') {
            return (
                <div style={{ background: 'rgb(255 255 255)' }}>
                    <div className={classes.root}>
                        <div className={classes.item}>
                            <div className={classes.content}>
                                <div className={classes.title}>
                                    <h4>
                                        <TR name={'qrCodeTitle1'} />
                                    </h4>
                                    <p>
                                        <TR name={'qrCodeTitle2'} />
                                    </p>
                                    <p>
                                        <TR name={'qrCodeTitle2b'} />
                                        <TR name={'qrCodeTitle2c'} />
                                    </p>
                                    <p>
                                        <TR name={'qrCodeTitle2d'} />
                                        <TR name={'qrCodeTitle2e'} />
                                    </p>
                                    {/* <p>
                                        <TR name={'qrCodeTitle3'} />，
                                       
                                        <TR name={'qrCodeTitle3b'} />
                                    </p>
                                    <p>
                                        <TR name={'qrCodeTitle4'} />
                                    </p> */}
                                </div>
                            </div>
                            <div className={classes.right}>
                                {/* <p>
                                    <TR name={'qrCodeTitle5'} />
                                </p> */}
                                <Tooltip arrow placement="bottom" title={<TRHtml html={'scanWechat'} />}>
                                    <Box>
                                        <QRCode></QRCode>
                                    </Box>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
});

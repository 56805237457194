import { MapGlobalVars } from '../support/PriceMapConsts';

export const spaceScoreMapping = [
    {
        region: 1,
        bed: 3,
        bath: 2,
        value_a: 110,
        question_a: 130,
        value_b: 150,
        question_c: 170,
        value_c: 200,
    },
    {
        region: 1,
        bed: 4,
        bath: 2,
        value_a: 130,
        question_a: 150,
        value_b: 175,
        question_c: 200,
        value_c: 220,
    },
    {
        region: 2,
        bed: 3,
        bath: 2,
        value_a: 120,
        question_a: 140,
        value_b: 155,
        question_c: 170,
        value_c: 200,
    },
    {
        region: 2,
        bed: 4,
        bath: 2,
        value_a: 120,
        question_a: 140,
        value_b: 170,
        question_c: 200,
        value_c: 230,
    },
    {
        region: 4,
        bed: 3,
        bath: 2,
        value_a: 120,
        question_a: 140,
        value_b: 155,
        question_c: 170,
        value_c: 200,
    },
    {
        region: 4,
        bed: 4,
        bath: 2,
        value_a: 120,
        question_a: 140,
        value_b: 170,
        question_c: 200,
        value_c: 230,
    },
    {
        region: 3,
        bed: 3,
        bath: 2,
        value_a: 115,
        question_a: 130,
        value_b: 145,
        question_c: 160,
        value_c: 190,
    },
    {
        region: 3,
        bed: 4,
        bath: 2,
        value_a: 140,
        question_a: 160,
        value_b: 180,
        question_c: 200,
        value_c: 240,
    },
    {
        region: 5,
        bed: 3,
        bath: 2,
        value_a: 110,
        question_a: 130,
        value_b: 145,
        question_c: 160,
        value_c: 190,
    },
    {
        region: 5,
        bed: 4,
        bath: 2,
        value_a: 135,
        question_a: 150,
        value_b: 175,
        question_c: 200,
        value_c: 230,
    },
    {
        region: 6,
        bed: 3,
        bath: 2,
        value_a: 125,
        question_a: 140,
        value_b: 160,
        question_c: 180,
        value_c: 220,
    },
    {
        region: 6,
        bed: 4,
        bath: 2,
        value_a: 165,
        question_a: 170,
        value_b: 200,
        question_c: 230,
        value_c: 260,
    },
];

export const BBCLimited = {
    bed: {
        min: 1,
        max: 7,
    },
    bath: {
        max: 6,
        min: 1,
    },
    car: {
        min: 0,
        max: 6,
    },
};
export const ALL_TAGS = {
    row1: [
        {
            label: 'Swimming pool',
            feature: 'pool_class',
            id: 1,
        },
        {
            label: 'Multi-storey',
            feature: 'storey_class',
            id: 2,
        },
        {
            label: 'Weatherboard house',
            feature: 'brick_class',
            id: 3,
        },
        {
            label: 'Recently built',
            feature: 'new_class',
            id: 4,
        },
    ],
    row2: [
        {
            label: 'Granny flat',
            feature: 'grannyflat_class',
            id: 5,
        },
        {
            label: 'BATH_IN_LOWER_GROUND',
            feature: 'attic_class',
            id: 6,
        },
        {
            label: 'Tennis court',
            feature: 'tennis_class',
            id: 7,
        },
    ],
    row3: [
        {
            label: 'Reasonable floor plan',
            feature: 'layout_class',
            id: 8,
        },
        {
            label: 'LARGE_OUTDOOR',
            feature: 'outdoor_class',
            id: 9,
        },
        {
            label: 'Indoor-outdoor connected living',
            feature: 'connection_class',
            id: 10,
        },
    ],
    row4: {
        label: 'OVERALL',
        feature: 'decor_level',
        options: [
            {
                label: 'WORSE',
                value: 1,
            },
            {
                label: 'MIDDLE',
                value: 2,
            },
            {
                label: 'HIGH_SPEC',
                value: 3,
            },
        ],
    },
    row5: {
        label: 'INDOOR_DESC',
        feature: 'space_score',
        options: [
            {
                label: '<${min}m<sup>2</sup>',
                value: 0,
            },
            {
                label: 'IN_BETWEEN',
                value: 1,
            },
            {
                label: '>${max}m<sup>2</sup>',
                value: 2,
            },
        ],
    },
};

export const ENGLISH_ALL_TAGS = {
    row1: [
        {
            label: 'Swimming pool',
            feature: 'pool_class',
            id: 1,
        },
        {
            label: 'Multi-storey',
            feature: 'storey_class',
            id: 2,
        },
        {
            label: 'Weatherboard house',
            feature: 'brick_class',
            id: 3,
        },
    ],
    row2: [
        {
            label: 'Recently built',
            feature: 'new_class',
            id: 4,
        },
        {
            label: 'Granny flat',
            feature: 'grannyflat_class',
            id: 5,
        },
        {
            label: 'BATH_IN_LOWER_GROUND',
            feature: 'attic_class',
            id: 6,
        },
    ],
    row3: [
        {
            label: 'Tennis court',
            feature: 'tennis_class',
            id: 7,
        },
        {
            label: 'Reasonable floor plan',
            feature: 'layout_class',
            id: 8,
            selected: true,
        },
        {
            label: 'Indoor-outdoor connected living',
            feature: 'connection_class',
            id: 10,
        },
    ],
    row6: [
        {
            label: 'LARGE_OUTDOOR',
            feature: 'outdoor_class',
            id: 9,
        },
    ],
    row4: {
        label: 'OVERALL',
        feature: 'decor_level',
        options: [
            {
                label: 'WORSE',
                value: 1,
            },
            {
                label: 'MIDDLE',
                value: 2,
            },
            {
                label: 'HIGH_SPEC',
                value: 3,
            },
        ],
    },
    row5: {
        label: 'INDOOR_DESC',
        feature: 'space_score',
        options: [
            {
                label: '<${min}m<sup>2</sup>',
                value: 0,
            },
            {
                label: 'IN_BETWEEN',
                value: 1,
            },
            {
                label: '>${max}m<sup>2</sup>',
                value: 2,
            },
        ],
    },
};

export const updateTagStatus = (tagFeature, selected, optionIndex?) => {
    const whichVar = MapGlobalVars.currentUserLan == 'zh' ? ALL_TAGS : ENGLISH_ALL_TAGS;
    if (tagFeature != 'decor_level' && tagFeature != 'space_score') {
        Object.keys(whichVar).map((loopRow) => {
            for (let i = 0; i < whichVar[loopRow].length; i++) {
                const loopRowTag: any = whichVar[loopRow][i];

                if (loopRowTag.feature == tagFeature) {
                    loopRowTag.selected = selected;

                    break;
                }
            }
        });
    }
};

export const resetAllTags = () => {
    const whichVar = MapGlobalVars.currentUserLan == 'zh' ? ALL_TAGS : ENGLISH_ALL_TAGS;
    Object.keys(whichVar).map((loopRow) => {
        for (let i = 0; i < whichVar[loopRow].length; i++) {
            const loopRowTag: any = whichVar[loopRow][i];
            loopRowTag.selected = false;
        }
    });
    console.log(whichVar);
};

import { rst } from 'rt-state';
import { FavouriteDto, ViewUserCenterProperty } from '../../../models/backend/userCenter';
import { useStyles } from '../styles';
import { Grid } from '@material-ui/core';
import SingleBedOutlinedIcon from '@material-ui/icons/SingleBedOutlined';
import BathtubOutlinedIcon from '@material-ui/icons/BathtubOutlined';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import SquareFootOutlinedIcon from '@material-ui/icons/SquareFootOutlined';
import React from 'react';
import { numberToString, TRHtml, TROrNA } from '../../../commons';

// bedroom, bathroom, carpark, area
export const BedroomBathroomCarparkArea = rst.createS<{
    dto: FavouriteDto;
}>((props) => {
    const classes = useStyles();
    const { dto } = props;
    const entity: ViewUserCenterProperty = dto.entity ?? ({} as ViewUserCenterProperty);
    const { propBed, propCarpark, propBath, landSize, propType } = entity;

    return (
        <Grid item container direction="row" justify="flex-start" alignItems="center" className={classes.contentRoom}>
            <Grid item xs>
                <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/bed.svg`} />
                    {/* <SingleBedOutlinedIcon /> */}
                    <TROrNA name={numberToString(propBed)} />
                </span>
            </Grid>
            <Grid item xs>
                <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/bath.svg`} />
                    {/* <BathtubOutlinedIcon /> */}
                    <TROrNA name={numberToString(propBath)} />
                </span>
            </Grid>
            <Grid item xs>
                <span>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/cars.svg`} />
                    {/* <DriveEtaOutlinedIcon /> */}
                    <TROrNA name={numberToString(propCarpark)} />
                </span>
            </Grid>
            {/* 以下为传参时使用 */}
            {propType === 'House' ? (
                <Grid item xs>
                    <span>
                        <img src={`${process.env.PUBLIC_URL}/assets/icons/square-ico.svg`} />
                        {/* <SquareFootOutlinedIcon /> */}
                        <TROrNA name={numberToString(landSize)} />
                        &nbsp;
                        <TRHtml html={'m<sup>2</m>'}></TRHtml>
                    </span>
                </Grid>
            ) : null}
        </Grid>
    );
});

import { rst } from 'rt-state';
import React from 'react';
import { TR } from '../../../commons';
import { makeStyles } from '@material-ui/core';
import classes from '*.module.css';
import { convertToM, isMobileDevice } from '../../../commons/utils';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            marginTop: 20,
        },
        barTitle: {
            marginTop: 20,
            fontSize: 14,
            fontWeight: 700,
        },

        barBorder: {
            marginTop: 10,
            background: 'white',
            width: isMobileDevice() ? 300 : 420,
            height: 20,
            borderRadius: 5,
            position: 'relative',
            paddingLeft: 10,
            paddingRight: 10,
        },
        priceRangeMax: {
            position: 'absolute',
        },
        priceRangeMin: {
            position: 'absolute',
        },
        bedPriceRange: {
            borderRadius: 5,
            background: 'linear-gradient(90deg,rgba(251,240,196,1) 0%,   rgba(249,178,98,1) 100%)',
            position: 'absolute',
            height: 20,
            paddingLeft: 5,
            paddingRight: 5,
        },
        propertyDot: {
            borderRadius: '50%',
            width: 8,
            height: 8,
            top: 4,
            background: 'white',
            position: 'absolute',
        },
    };
});
export const SuburbSoldByBedBar = rst.create<{
    title: string;
    soldData: any;
    soldPriceRange?: any;
    showNorPrice?: boolean;
}>((ctx) => {
    console.log(ctx.props.soldPriceRange);
    const mByPixel = 400 / (ctx.props.soldPriceRange.max - ctx.props.soldPriceRange.min);
    console.log('value per pixel is ' + mByPixel);

    return (props) => {
        const classes = useStyles();
        const presentPropertyDot = (soldPriceList, barPriceRange) => {
            const propertyDots = [];
            soldPriceList.forEach((loopPrice) => {
                var propertyLeft = (convertToM(loopPrice) - barPriceRange.min) * mByPixel;
                console.log('price is ' + convertToM(loopPrice) + ';left is ' + propertyLeft);
                propertyDots.push(<div className={classes.propertyDot} style={{ left: propertyLeft }}></div>);
            });

            return propertyDots;
        };
        const presentSoldBar = () => {
            const bars = [];
            props.soldData.forEach((loopBed) => {
                const bedPriceRange = {
                    min: 0,
                    max: 0,
                };
                const whichPriceList = props.showNorPrice ? loopBed.nowSoldPriceList : loopBed.soldPriceList;
                whichPriceList.forEach((loopPrice) => {
                    if (bedPriceRange.min == 0 && bedPriceRange.max == 0) {
                        bedPriceRange.min = loopPrice;
                        bedPriceRange.max = loopPrice;
                    }
                    if (loopPrice > bedPriceRange.max) {
                        bedPriceRange.max = loopPrice;
                    }
                    if (loopPrice < bedPriceRange.min) {
                        bedPriceRange.min = loopPrice;
                    }
                });

                console.log(bedPriceRange);

                bedPriceRange.min = convertToM(bedPriceRange.min);
                bedPriceRange.max = convertToM(bedPriceRange.max);

                var barWidth = (bedPriceRange.max - bedPriceRange.min) * mByPixel + 20;
                var barLeft = (bedPriceRange.min - props.soldPriceRange.min) * mByPixel;
                console.log('bar width is ' + barWidth + ';bar left is ' + barLeft);

                bars.push(
                    <div className={classes.barBorder}>
                        <div className={classes.priceRangeMin} style={{ left: barLeft - 30 }}>
                            {bedPriceRange.min}
                        </div>
                        <div className={classes.bedPriceRange} style={{ width: barWidth, left: barLeft }}>
                            <div
                                style={{
                                    position: 'relative',
                                }}>
                                {presentPropertyDot(loopBed.soldPriceList, bedPriceRange)}
                            </div>
                        </div>

                        <div style={{ left: barLeft + barWidth + 10 }} className={classes.priceRangeMax}>
                            {bedPriceRange.max}
                        </div>
                    </div>,
                );
            });
            return bars;
        };
        return (
            <div>
                <div className={classes.barTitle}>
                    <TR name={props.title}></TR>
                </div>
                <div style={{ padding: 20 }}>
                    <div>{presentSoldBar()}</div>
                </div>
            </div>
        );
    };
});

import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import { clsx, SecurityProvider, storage, TR, TRHtml } from '../../../../commons';
import {
    Button,
    Chip,
    CircularProgress,
    DialogContent,
    DialogTitle,
    makeStyles,
    MenuItem,
    Select,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { decodeB64Str, getParamValueFromURL } from '../../../../commons/utils';
import { verifyUserEmail } from '../../../../services/auth';
import { mobileNavButton } from '../../consts/styleConsts';
import { Margin } from '../../../../components/margin';
import { createXForm, string } from '../../../../components/form';
import { InputSignUp } from '../commonComponents/input_signUp';
import { USERNAME_SCHEMA } from '../../../auth/common';
import { rst } from 'rt-state';
import { getBuyPurpose, getSelectionList, getUserTypeList } from '../../../../services/data.service';
import { requestPhoneVerifyCode, saveUserDetails } from '../../../../services/userCenter';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { async } from 'validate.js';
import { AppProvider } from '../../../../commons/appProvider';
const useStyles = makeStyles({
    info1: {
        fontSize: 13,
        fontWeight: 400,
        color: '#666666',
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '18px',
        fontWeight: 500,
        color: '#333333',
    },
    specialInfo: {
        background: 'rgba(255,34,0,0.10)',
        padding: '9px',
        borderRadius: '4px',
        color: '#FF2200',
        fontSize: '12px',
        fontWeight: 400,
    },
    closeIcon: {
        position: 'absolute',
        top: '15px',
        right: '20px',
        zIndex: 99999,
    },
    purposeButton: {
        padding: '10px 16px',
        width: '232px',
        height: '42px',
        border: '1px solid #cfcbcb',
        borderRadius: '4px',
        marginTop: '20px',
        fontSize: '14px',
    },
    selectedPurpose: {
        background: 'rgba(0,158,144,0.08)',
        color: '#009e90',
    },
});

const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    verifyCode: '',
    buyPurpose: '',
    budget: '',
    careSuburbs: '',
    selectedSuburbs: [],
};

const validationPhone = {
    phone: string().required('verify your phone first'),
    verifyCode: string().required('Required'),
};

const validationSchema = {
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    phone: string().required('Required'),
    verifyCode: string().required('Required'),
    buyPurpose: string().required('choose one buy purpose'),
};
const validateSchemaWithBudget = {
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    phone: string().required('Required'),
    verifyCode: string().required('Required'),
    buyPurpose: string().required('choose one buy purpose'),
    budget: string().required('choose your budget'),
};

export const TipAfterSignup = (props: any) => {
    const securityProvider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const { snackbarController } = appProvider;
    const userProfile = securityProvider.getUserProfile();
    const defaultOpen = userProfile?.userStatus == '1';
    const incomingUser: any = {};
    const [pageOpen, setPageOpen] = useState(false);
    const [tipType, setTipType] = useState(1);
    const [verifyEmailError, setVerifyEmailError] = useState(false);

    const search = window.location.search;

    useEffect(() => {
        // this is verify email , so bring verify email page
        if (search.indexOf('verifyEmail') > -1) {
            const b64Str = getParamValueFromURL(search, 'verifyEmail');
            var originStr = decodeB64Str(b64Str);

            const email = getParamValueFromURL(originStr, 'email');
            console.log('email is ', email);
            const uuid = getParamValueFromURL(originStr, 'uuid');
            console.log(uuid);

            const verifyResult = verifyUserEmail(email, uuid).then((verifyResult) => {
                if (verifyResult.verifyResult !== 1) {
                    setTipType(2);
                    setPageOpen(false);
                    setVerifyEmailError(true);
                    EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
                        open: true,
                        title: 'ERROR',

                        cancelButtonText: 'GOT_IT',
                        cancelEvt: () => {
                            processInvlidateLink();
                        },
                        content: verifyResult.verifyResult,
                    });
                } else {
                    snackbarController.open(<TR name={'Email_Verified'} />, 'success');
                    //alert(verifyResult);
                    incomingUser.userId = verifyResult.userId;
                    incomingUser.uuid = uuid;
                    incomingUser.email = email;
                    console.log('token info is ', securityProvider.tokenInfo);
                    if (!securityProvider.tokenInfo?.value?.token) {
                        securityProvider.setToken(verifyResult.token?.accessToken, verifyResult?.expireTime);
                    }
                    setTipType(2);
                    setPageOpen(true);
                }
            });
        }
    }, []);

    const showSaveDetailsSuccess = () => {
        setTipType(3);
    };

    const processInvlidateLink = () => {
        window.location.href = '/';
    };

    useEffect(() => {
        console.log('user profile changed');
        console.log('user profile status is ', securityProvider.getUserProfile()?.userStatus);
        const windowCloseStatus = storage.get('VERIFY_TIP_WINDOW_STATUS_' + tipType).then((windowCloseStatus) => {
            if (!windowCloseStatus && tipType == 1) {
                setPageOpen(securityProvider.getUserProfile()?.userStatus == '1');
            } else {
            }
        });
    }, [securityProvider.getUserProfile()]);

    const closeTipWindow = () => {
        storage.save('VERIFY_TIP_WINDOW_STATUS_' + tipType, 'C');

        setPageOpen(false);
    };
    const classes = useStyles();

    return (
        <>
            <Dialog open={pageOpen && (tipType == 1 || tipType == 3)} maxWidth="xs">
                <DialogTitle>
                    <div className={classes.title}>
                        <TR name={tipType == 1 ? 'oneStepLeft' : 'welcomeAsAreaMember'}></TR>
                    </div>{' '}
                    <div className={classes.closeIcon} onClick={closeTipWindow}>
                        <img src="assets/mobileIcons/close.svg"></img>
                    </div>
                </DialogTitle>

                <DialogContent>
                    {tipType == 1 && (
                        <>
                            <div className={classes.info1}>
                                <TR name="oneStepLeftContent"></TR>
                            </div>

                            <div style={{ marginTop: 20 }}></div>
                            <div className={classes.specialInfo}>
                                <TR name={'validationUtil'}></TR>
                            </div>
                            <div style={{ marginBottom: 20 }}></div>
                        </>
                    )}

                    {tipType == 3 && (
                        <>
                            <div className={classes.info1}>
                                <TRHtml html={'areaMemberBenefit'}></TRHtml>
                            </div>

                            <div style={{ marginBottom: 20 }}></div>
                        </>
                    )}

                    <Margin tm={15}></Margin>
                    <Button
                        style={{
                            ...mobileNavButton,
                            background: '#009e90',
                            height: '42px',
                            color: 'white',
                            width: '100%',
                            border: '1px solid #979797',
                            borderRadius: '4px',
                        }}
                        onClick={closeTipWindow}
                        fullWidth
                        variant="contained"
                        disableElevation>
                        <TR name={'OK'} />
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={pageOpen && tipType == 2 && !verifyEmailError}>
                <DialogTitle>
                    <div className={classes.title}>
                        <TR name="WelcomeUser"></TR>
                    </div>
                    <div className={classes.closeIcon} onClick={closeTipWindow}>
                        <img src="assets/mobileIcons/close.svg"></img>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.info1}>
                        <TR name="emailVerified"></TR>
                    </div>
                    <Margin tm={15}></Margin>
                    <Button
                        style={{
                            ...mobileNavButton,
                            background: '#009e90',
                            height: '42px',
                            color: 'white',
                            width: '100%',
                        }}
                        onClick={() => {
                            setPageOpen(false);
                        }}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disableElevation>
                        <TR name={'start'} />
                    </Button>
                    <Margin tm={20}></Margin>
                    <AreaMemberSignup
                        incomingUser={incomingUser}
                        saveUserSuccessEvt={showSaveDetailsSuccess}></AreaMemberSignup>
                </DialogContent>
            </Dialog>
        </>
    );
};

export const AreaMemberSignup = (props) => {
    console.log(props);
    const { incomingUser, saveUserSuccessEvt } = props;
    const [showTrialMemberItems, setShowTrialMemberItems] = useState(false);
    const [formErrors, setFormErrors] = useState(null);
    const [userBuyPurposes, setUserBuyPurposes] = useState([]);
    const [budgets_house, setBudgets_house] = useState([]);
    const [budgets_apt, setBudgets_apt] = useState([]);
    const [selectedPurpose, setSelectedPurpose] = useState('');
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [codeSend, setCodeSend] = useState(false);
    const [waitingResendSec, setWaitingResendSec] = useState(60);
    const [budgetOption_list, setBudgetOption_list] = useState([]);
    const [selectedBudget, setSelectedBudget] = useState('');
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        getBuyPurpose().then((list: any) => {
            setUserBuyPurposes(list);
            console.log(list);
        });

        getSelectionList('budget_house').then((list: any) => {
            setBudgets_house(list);
        });

        getSelectionList('budget_apt').then((list: any) => {
            setBudgets_apt(list);
        });
    }, []);

    const saveDetails = async (data) => {
        setProcessing(true);
        console.log(data);
        data.phone = ' ' + period + data.phone;
        console.log('start to save user details');
        const saveResult = await saveUserDetails({ ...incomingUser, ...data });
        console.log(saveResult);
        if (saveResult.error) {
            form.setFieldError('verifyCode', saveResult.error.value);
            console.log(form.errors);
            setFormErrors(form.errors);
        } else {
            console.log('save user successfully');
            console.log(props.saveUserSuccessEvt);
            saveUserSuccessEvt();
            window.open('/', '_self');
        }
        setProcessing(false);
    };

    const classes = useStyles();
    const [form, setForm] = useState(
        createXForm({
            initialValues,
            validate: validationSchema,
            onSubmit: saveDetails,
        }),
    );

    var resendInterval;

    const getPhoneVerifyCode = async () => {
        if (!form.values['phone']) {
            form.setFieldError('phone', 'type_phone_number');
            setFormErrors(form.errors);
            return;
        }
        const { values } = form;
        const requestResult: any = await requestPhoneVerifyCode('+' + period + values['phone']);
        if (requestResult.error) {
            form.setFieldError('phone', requestResult.error);
            setFormErrors(form.errors);
            return;
        } else {
            form.setFieldError('verifyCode', 'type_verify_code');
            setFormErrors(form.errors);
        }
        console.log('!!!!' + '+' + period + values['phone']);
        setCodeSend(requestResult);
        setTimeout(() => {
            setWaitingResendSec(59);
            updateWaitingTime();
        }, 1000);
    };

    const updateWaitingTime = () => {
        setTimeout(() => {
            const resendButton: any = document.getElementById('resendButton');
            const currentWaitingSeconds: any = document.getElementById('waitingSeconds');
            if (resendButton != null) {
                currentWaitingSeconds.value = currentWaitingSeconds.value - 1;
                resendButton.innerHTML = 'Resend(' + currentWaitingSeconds.value + 's)';
                if (currentWaitingSeconds.value >= 0) {
                    updateWaitingTime();
                    resendButton.disabled = true;
                } else {
                    resendButton.innerHTML = 'Click to resend';
                    resendButton.disabled = false;
                    currentWaitingSeconds.value = 60;
                }
            }
        }, 1000);
    };

    const becomeTrialMember = async () => {
        console.log(form);

        if (!codeSend) {
            form.setValidate(validationPhone);
        } else if (selectedPurpose.startsWith('LIVE') || selectedPurpose.startsWith('INVEST')) {
            form.setValidate(validateSchemaWithBudget);
        } else {
            form.setValidate(validationSchema);
        }
        if (form.values['selectedSuburbs'].length == 0) {
            alert('Please enter your interest area');
            return;
        }
        if (form.values['budget'] == '') {
            if (selectedPurpose.startsWith('LIVE') || selectedPurpose.startsWith('INVEST')) {
                alert('Please choose your budget');
                return;
            }
        }
        if (form.values['buyPurpose'] == '') {
            alert('Please select whether you want to buy or sell');
            return;
        }
        if (form.values['firstName'] == '' || form.values['lastName'] == '') {
            alert('Enter your name');
            return;
        }

        if (!codeSend && !form.isValid()) {
            alert('Please get the verification code');
            return;
        }

        await form.handleSubmit();
        console.log(form.errors);
        setFormErrors(form.errors);
    };
    useEffect(() => {
        setBudgetOption_list(selectedPurpose.indexOf('HOUSE') > -1 ? budgets_house : budgets_apt);
    }, [selectedPurpose]);

    const [period, setPeriod] = useState(61);
    const handlePeriodOnchange = (event) => {
        setPeriod(event.target.value);
    };
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '18px',
                    fontWeight: 500,
                    color: '#333333',
                }}>
                <TR name="memberTry"></TR>
            </div>
            <Margin tm={15}></Margin>
            <div className={classes.info1}>
                <TR name="member1"></TR>
            </div>

            <Margin tm={5}></Margin>
            <div className={classes.info1}>
                <TR name="member2"></TR>
            </div>

            <Margin tm={15}></Margin>
            <Button
                style={{
                    ...mobileNavButton,
                    background: '#ff2200',
                    height: '42px',
                    color: 'white',
                    width: '100%',
                }}
                onClick={() => {
                    setShowTrialMemberItems(!showTrialMemberItems);
                }}
                fullWidth
                variant="contained"
                color="primary"
                disableElevation>
                <TR name={'tryMember'} />
                <Margin lm={5}></Margin>
                <img src="assets/mobileIcons/downArrowWhite.svg" />
            </Button>

            {showTrialMemberItems && (
                <div>
                    <Margin tm={15}></Margin>
                    <div style={{ display: 'flex' }}>
                        <InputSignUp
                            form={form}
                            name="careSuburbs"
                            suburbChoose
                            label=""
                            placeholder="your_care_suburbs"></InputSignUp>
                    </div>
                    <InputSignUp form={form} type="hidden" name="selectedSuburbs" label=""></InputSignUp>

                    <Margin tm={15}></Margin>
                    <div>
                        <div style={{ fontSize: 14, fontWeight: 400, color: '#000000' }}>
                            {' '}
                            <TR name="buyOrSell"></TR>
                        </div>
                    </div>

                    {userBuyPurposes.map((purpose) => {
                        return (
                            <div
                                onClick={() => {
                                    setSelectedPurpose(purpose);
                                    form.setFieldValue('buyPurpose', purpose);
                                    console.log(purpose);
                                }}
                                className={clsx(
                                    classes.purposeButton,
                                    purpose == selectedPurpose ? classes.selectedPurpose : '',
                                )}>
                                <TR name={purpose}></TR>
                            </div>
                        );
                    })}
                    <Margin tm={15}></Margin>
                    <InputSignUp formErrors={formErrors} form={form} name="buyPurpose" type="hidden"></InputSignUp>

                    <Margin tm={15}></Margin>
                    {(selectedPurpose == 'LIVE_HOUSE' ||
                        selectedPurpose == 'INVEST_HOUSE' ||
                        selectedPurpose == 'LIVE_APT' ||
                        selectedPurpose == 'INVEST_APT') && (
                        <div>
                            <div style={{ fontSize: 14, fontWeight: 400, color: '#000000' }}>
                                {' '}
                                <TR name="Budget"></TR>
                            </div>
                        </div>
                    )}

                    {(selectedPurpose.startsWith('LIVE') || selectedPurpose.startsWith('INVEST')) &&
                        budgetOption_list.map((budget) => {
                            return (
                                <div
                                    onClick={() => {
                                        setSelectedBudget(budget);

                                        form.setFieldValue('budget', budget);
                                    }}
                                    className={clsx(
                                        classes.purposeButton,
                                        budget == selectedBudget ? classes.selectedPurpose : '',
                                    )}>
                                    <TR name={budget}></TR>
                                </div>
                            );
                        })}

                    <Margin tm={15}></Margin>
                    <InputSignUp formErrors={formErrors} form={form} name="budget" type="hidden"></InputSignUp>
                    <Margin tm={15}></Margin>
                    <div style={{ display: 'flex' }}>
                        <InputSignUp
                            formErrors={formErrors}
                            form={form}
                            name="lastName"
                            label={''}
                            placeholder={'lastName_ph'}></InputSignUp>
                        <Margin lm={10}></Margin>
                        <InputSignUp
                            formErrors={formErrors}
                            form={form}
                            name="firstName"
                            label={''}
                            placeholder={'firstName_ph'}></InputSignUp>
                    </div>

                    <Margin tm={10}></Margin>

                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <Select
                            value={period}
                            onChange={handlePeriodOnchange}
                            displayEmpty
                            disableUnderline
                            inputProps={{
                                'aria-label': 'Without label',
                            }}>
                            <MenuItem value={1}>
                                <span>
                                    <TR name={'countryCode_America'} />{' '}
                                </span>
                            </MenuItem>
                            <MenuItem value={1}>
                                <span>
                                    <TR name={'countryCode_Canada'} />
                                </span>
                            </MenuItem>
                            <MenuItem value={61}>
                                <span>
                                    <TR name={'countryCode_Australia'} />
                                </span>
                            </MenuItem>
                            <MenuItem value={64}>
                                <span>
                                    <TR name={'countryCode_NewZealand'} />
                                </span>
                            </MenuItem>
                            <MenuItem value={65}>
                                <span>
                                    <TR name={'countryCode_Singpore'} />
                                </span>
                            </MenuItem>
                            <MenuItem value={86}>
                                <span>
                                    <TR name={'countryCode_China'} />
                                </span>
                            </MenuItem>
                            <MenuItem value={852}>
                                <span>
                                    <TR name={'countryCode_China_HongKong'} />
                                </span>
                            </MenuItem>
                            <MenuItem value={886}>
                                <span>
                                    <TR name={'countryCode_China_TaiWan'} />
                                </span>
                            </MenuItem>
                        </Select>

                        <InputSignUp
                            formErrors={formErrors}
                            form={form}
                            name="phone"
                            label={'phone'}
                            placeholder={''}></InputSignUp>

                        <Margin tm={10}></Margin>
                        <Button
                            id="resendButton"
                            style={{
                                ...mobileNavButton,
                                background: 'rgba(0,158,144,0.10)',
                                fontSize: '14px',
                                height: '42px',
                                color: '#009e90',

                                border: '1px solid #979797',
                                borderRadius: '4px',
                            }}
                            fullWidth
                            onClick={getPhoneVerifyCode}
                            variant="contained">
                            {waitingResendSec == 60 && <TR name={'getPhoneVerifyCode'} />}
                            {waitingResendSec !== 60 && (
                                <>
                                    {' '}
                                    <TR name={`resend`} />({waitingResendSec}
                                    <TR name="seconds"></TR>)
                                </>
                            )}
                        </Button>
                        <input type="hidden" value="60" id="waitingSeconds"></input>
                    </div>
                    <Margin tm={15}></Margin>
                    <InputSignUp
                        formErrors={formErrors}
                        form={form}
                        name="verifyCode"
                        label={'verifyCode'}
                        placeholder={''}></InputSignUp>

                    <Margin tm={15}></Margin>
                    <Button
                        style={{
                            ...mobileNavButton,
                            background: '#009e90',
                            height: '42px',
                            color: 'white',
                            width: '100%',
                            border: '1px solid #979797',
                            borderRadius: '4px',
                        }}
                        onClick={becomeTrialMember}
                        fullWidth
                        variant="contained"
                        disableElevation>
                        <TR name={'BecomeTrialMember'} />
                        {processing && <CircularProgress style={{ color: 'white' }} size={16}></CircularProgress>}
                    </Button>
                </div>
            )}
        </>
    );
};

export const VariousPopup = (props: any) => {
    const classes = useStyles();
    const { open, onClose, messageTitleKey, messageKey } = props;
    return (
        <Dialog open={open} maxWidth="xs">
            <DialogTitle>
                <div className={classes.title}>
                    <TR name={messageTitleKey}></TR>
                </div>{' '}
                <div className={classes.closeIcon} onClick={onClose}>
                    <img src="assets/mobileIcons/close.svg"></img>
                </div>
            </DialogTitle>

            <DialogContent>
                <div className={classes.info1}>
                    <TRHtml html={messageKey}></TRHtml>
                </div>

                <div style={{ marginBottom: 20 }}></div>

                <Margin tm={15}></Margin>
                <Button
                    style={{
                        ...mobileNavButton,
                        background: '#009e90',
                        height: '42px',
                        color: 'white',
                        width: '100%',
                        border: '1px solid #979797',
                        borderRadius: '4px',
                    }}
                    onClick={onClose}
                    fullWidth
                    variant="contained"
                    disableElevation>
                    <TR name={'OK'} />
                </Button>
            </DialogContent>
        </Dialog>
    );
};

import React from 'react';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import { rst } from 'rt-state';

export interface DrawerController {
    isOpen(): boolean;
    open(): void;
    close(): void;
    toggle(open?: boolean): void;
}

export const createXDrawerController = (): DrawerController => {
    const openState = rst.stateS(false);
    const open = (): void => {
        openState.value = true;
    };
    const close = (): void => {
        openState.value = false;
    };
    const isOpen = () => {
        return openState.value;
    };
    const toggle = (open?: boolean) => {
        openState.value = open ?? !openState.value;
    };
    return { isOpen, open, close, toggle };
};

export const XDrawer = rst.createS<{
    drawerProps?: Omit<DrawerProps, 'onClose' | 'open'>;
    controller: DrawerController;
    onClose?: () => void;
}>((props) => {
    const { controller: ctrl, children } = props;
    const { drawerProps } = props;

    return (
        <Drawer
            anchor={'left'}
            open={ctrl.isOpen()}
            onClose={() => {
                if (props.onClose) {
                    props.onClose();
                }
                ctrl.close();
            }}
            {...drawerProps}>
            {children}
        </Drawer>
    );
});

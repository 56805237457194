import { Chip, makeStyles } from '@material-ui/core';
import ReactDOM from 'react-dom';
import React from 'react';
import { rst, state } from 'rt-state';
import { decimalToPct, isMobileDevice, numToMillion } from '../../../../../commons/utils';
import { SchoolOrStationSimple } from './SchoolOrStationSimple';
import { SchoolOrStationScroll } from './SchoolOrStationScrolls';
import { MapGlobalVars } from '../../../support/PriceMapConsts';
import {
    getPrimarySchools,
    getSecondarySchools,
    getTrains,
    getTypeByFeature,
} from '../../../support/propertyFeatureHelper';
import { AppProvider } from '../../../../../commons/appProvider';
import { storage } from '../../../../../commons';
import { SchoolOrStationLine } from './SchoolOrStationLine';

export const CommonSchoolOrStation = rst.create<{
    prop: any;
    recentSold?: boolean;
    onMap?: boolean;
    onReport?: boolean;
}>((ctx) => {
    return (props) => {
        const { prop } = props;
        console.log(prop);
        return (
            <>
                {isMobileDevice() || props.onReport ? (
                    <SchoolOrStationSimple prop={prop} onReport={props.onReport}></SchoolOrStationSimple>
                ) : (
                    <>
                        {getTypeByFeature(prop.allFeatures) == 'House' && props.recentSold ? (
                            <div style={{ marginTop: 10 }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-end',
                                    }}>
                                    <SchoolOrStationScroll
                                        items={[
                                            {
                                                name: 'landValue',
                                                chips: [numToMillion(prop.allFeatures.land_value_reset)],
                                            },
                                        ]}></SchoolOrStationScroll>

                                    <SchoolOrStationScroll
                                        items={[
                                            {
                                                name: 'landValuePct',
                                                chips: [decimalToPct(prop.landValue / prop.price)],
                                            },
                                        ]}></SchoolOrStationScroll>

                                    <SchoolOrStationScroll
                                        items={[
                                            {
                                                name: 'HousePriceRankDesc',
                                                chips: ['Rank ' + prop.soldRank?.rank],
                                                params: {
                                                    period: MapGlobalVars.priceMapFilter?.period || 6,
                                                    transCounts: prop.soldRank?.transCount || 0,
                                                },
                                            },
                                        ]}></SchoolOrStationScroll>
                                </div>
                            </div>
                        ) : null}

                        <SchoolOrStationLine
                            schoolNameKey="school_name"
                            primary={prop.allFeatures?.primary}
                            secondary={prop.allFeatures?.secondary}
                            trains={prop.allFeatures?.closest_station}></SchoolOrStationLine>
                    </>
                )}
            </>
        );
    };
});

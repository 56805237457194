import { Button, ClickAwayListener, Collapse, Divider, Link, List, ListItem, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';
import { rst } from 'rt-state';
import { RRLink, TR } from '../../../commons';
import { XAuthDialog } from '../../../components/Dialog/XAuthDialog';
import { ROUTES } from '../../../routes';
import { ContactUsPage } from '../../ContactUs';
import { WeChatGroup } from '../../Home/components/WeChatGroup';
import { NewButton } from './NewButton';
import * as locales from '@material-ui/core/locale';
import { AppProvider } from '../../../commons/appProvider';

const useStyles = makeStyles((theme) => ({
    content: {
        margin: '0 auto',
        padding: '28px 16px',
        width: '100vw',
        '& span': {
            fontSize: '12px',
            lineHeight: '14px',
            color: 'rgb(123 123 123)',
        },
    },
}));
export const NewHomeFooterForMobile = rst.create((ctx) => {
    const handleOpenLive = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://www.youtube.com/channel/UCPbp_YpynVEVpJrr_UzMqYg';
    };
    const handleOpenTwitter = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://twitter.com/SydneyHomeOn';
    };
    const handleOpenFacebook = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://www.facebook.com/homeon.com.au';
    };
    const isOpen = rst.stateS<'shop' | 'wechat' | 'live' | 'Feedback'>(null);
    function handleForumClose(choice: 'shop' | 'wechat' | 'live' | 'Feedback') {
        switch (choice) {
            case 'shop':
            case 'wechat':
                isOpen.value = choice;
                break;
            case 'Feedback':
                isOpen.value = choice;
                break;
            case 'live':
        }
    }
    function onDialogClose() {
        isOpen.value = null;
    }
    const openMenu = rst.stateS(false);
    const handleOpenMenu = () => {
        openMenu.value = !openMenu.value;
    };
    const handleClickAway = () => {
        openMenu.value = false;
    };
    const appProvider = AppProvider.use();

    return (props) => {
        const classes = useStyles();

        return (
            <div style={{ background: '#012E2A' }}>
                <div className={classes.content}>
                    <div>
                        <span style={{ width: '80px' }}>
                            <a href="mailto:support@homeon.com.au">
                                <TR name={'NewHome_Footer_Contact'} />
                            </a>
                        </span>
                    </div>
                    <Divider style={{ background: 'rgb(123 123 123)', width: '100%', margin: '10px 0px' }} />
                    <Link
                        target="_blank"
                        component={RRLink}
                        to={ROUTES.AboutUsPage.path}
                        color="initial"
                        style={{ textDecoration: 'none' }}>
                        <span>
                            <TR name={'NewHome_Footer_compPro'} />
                        </span>
                    </Link>
                    <Divider style={{ background: 'rgb(123 123 123)', width: '100%', margin: '10px 0px' }} />
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div>
                            <ListItem style={{ padding: '0' }} button dense={true} onClick={handleOpenMenu}>
                                <span>
                                    <TR name={'NewHome_Footer_Focus'} />
                                </span>
                                {openMenu.value ? (
                                    <ExpandLess style={{ color: 'rgb(209 209 209)' }} />
                                ) : (
                                    <ExpandMore style={{ color: 'rgb(209 209 209)' }} />
                                )}
                            </ListItem>
                            <Collapse in={openMenu.value} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button dense={true} style={{ padding: '5px 0' }}>
                                        <Button style={{ marginRight: '50px' }} onClick={handleOpenTwitter}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/static/images/Twitter.svg`}
                                                alt="Twitter"
                                            />
                                            <span style={{ marginLeft: '5px' }}>Twitter</span>
                                        </Button>
                                    </ListItem>
                                    <ListItem button dense={true} style={{ padding: '5px 0' }}>
                                        <Button style={{ marginRight: '50px' }} onClick={handleOpenFacebook}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/static/images/facebook.svg`}
                                                alt="facebook"
                                            />
                                            <span style={{ marginLeft: '5px' }}>Facebook</span>
                                        </Button>
                                    </ListItem>
                                    {appProvider.getLocale() === locales.zhCN ? (
                                        <ListItem button dense={true} style={{ padding: '5px 0' }}>
                                            <Button
                                                style={{ marginRight: '50px' }}
                                                onClick={() => handleForumClose('wechat')}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/static/images/wechat.svg`}
                                                    alt="Wechat"
                                                />
                                                <span style={{ marginLeft: '5px' }}>Wechat</span>
                                            </Button>
                                        </ListItem>
                                    ) : null}
                                    <ListItem button dense={true} style={{ padding: '5px 0' }}>
                                        <Button style={{ marginRight: '50px' }} onClick={handleOpenLive}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/static/images/youtube.svg`}
                                                alt="youTube"
                                            />
                                            <span style={{ marginLeft: '5px' }}>YouTube</span>
                                        </Button>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </div>
                    </ClickAwayListener>
                </div>
            </div>
        );
    };
});

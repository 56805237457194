import * as React from 'react';
import { Box, Button, Container, Grid, Link } from '@material-ui/core';
import { rst } from 'rt-state';
//import { useStyles } from './login.styles';
import { PageWrapper, setup, PASSWORD_SCHEMA, AuthDialogHeader } from './common';
import { string } from '../../components/form';
import { clsx, TR } from '../../commons';
import { XTextField } from '../../components/form';
import { UpdateProfilePo } from '../../models/backend';
import { updateUserProfile } from '../../services/auth';
import { getErrorData } from '../../commons/axios/axios';
import { XCheckbox } from '../../components/form/mui-form/Checkbox';
import { ChangePasswordComp } from './ChangePassword';
import { changePassword } from '../../services/auth';
import { mobileNavButton } from '../mobile/consts/styleConsts';
import { InputSignUp } from '../mobile/member/commonComponents/input_signUp';
import { Margin } from '../../components/margin';
import { MemberInfo } from '../mobile/member/memberInfo';
import { Card, IconButton, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    ElementsConsumer,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { useStyles, PaymentInfo } from '../mobile/member/memberInfo';
import { loadStripe } from '@stripe/stripe-js';
import { env } from '../../environments';
import { useState } from 'react';
import { getUserProfile } from '../../services/auth';
import { extractMemberInfo } from '../../services/userCenter';

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    newPassword: '',
};

const validationSchema = {
    firstName: string().required('Required').min(1),
    lastName: string().required('Required').min(1),
};

export const UserInfoPage: React.FC<{}> = () => <UserInfoComp />;

export const UserInfoComp = rst.create<{ onClose?: () => void }>((ctx) => {
    const { securityProvider, snackbarController, form } = setup(initialValues, validationSchema, onChangeUserInfo);
    const cardInfo = rst.state({
        last4: '',
        expireYear: '',
        expireMonth: '',
    });
    rst.watch(
        (values) => {
            console.log('value is ====>');
            console.log(values);
            const userProfile = values[0];

            extractMemberInfo(userProfile.id).then((info) => {
                console.log(info);
                cardInfo.last4 = info.paymentMethod?.last4;
                cardInfo.expireMonth = info.paymentMethod?.expMonth;
                cardInfo.expireYear = info.paymentMethod?.expYear;
            });
            if (userProfile == null) {
                return;
            }
            const newValues: UpdateProfilePo = {
                nickname: '',
                email: userProfile.email,
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                password: userProfile.password,
                newPassword: userProfile.newPassword,
            };
            form.resetForm(newValues);
        },
        () => [securityProvider.getUserProfile()],
    );

    async function onChangeUserInfo(data: any) {
        console.log('onChangeUserInfo');
        console.log(data);
        try {
            const newProfile = await updateUserProfile(data);
            const { password, newPassword } = data;
            if (password || newPassword) {
                await changePassword(password, newPassword);
            }

            securityProvider.setUserProfile(newProfile);
            snackbarController.open(<TR name={'success'} />, 'success');
            ctx.props.onClose?.(); // eslint-disable-line
        } catch (e) {
            console.log(e);
            snackbarController.open(<TR name={e.message} />, 'error');
            console.error(getErrorData(e));
        }
    }
    ////////////////////////////////////////////////////////////////////////
    return (props) => {
        const classes = useStyles();
        const { onClose } = props;
        const stripePromise = loadStripe(env.stripeKey);
        const userProfile = securityProvider.getUserProfile();
        const [updateCredirCardInfo, setUpdateCredirCardInfo] = useState(false);

        return (
            <Grid container justify={'center'} direction={'column'} alignItems={'center'}>
                <Container>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid container direction={'column'} alignItems={'center'}>
                            <h2>
                                <TR name={'User Information Setting'} />
                            </h2>
                        </Grid>
                        <IconButton onClick={onClose}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                    <Grid container direction={'column'} alignItems={'flex-start'}>
                        <h3>
                            <TR name={'User Information Setting'} />
                        </h3>
                    </Grid>
                    <div style={{ display: 'flex' }}>
                        <InputSignUp form={form} name="firstName" label={''} placeholder={'firstName_ph'}></InputSignUp>
                        <Margin lm={10}></Margin>
                        <InputSignUp form={form} name="lastName" label={''} placeholder={'lastName_ph'}></InputSignUp>
                    </div>
                    <Margin tm={10}></Margin>
                    <InputSignUp
                        readOnly
                        form={form}
                        name="email"
                        label={'E-Mail Address'}
                        placeholder={'E-Mail Address ph'}></InputSignUp>
                    <Margin tm={30}></Margin>
                    <Grid container direction={'column'} alignItems={'flex-start'}>
                        <h3>
                            <TR name={'Password Setting'} />
                        </h3>
                    </Grid>
                    <InputSignUp
                        form={form}
                        name="password"
                        label={'Old Password'}
                        placeholder={'Old Password ph'}
                        type="password"></InputSignUp>
                    <Margin tm={10}></Margin>
                    <InputSignUp
                        form={form}
                        name="newPassword"
                        label={'New Password'}
                        placeholder={'New Password ph'}
                        type="password"></InputSignUp>
                </Container>
                <Margin tm={20}></Margin>
                <Container>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            style={{
                                ...mobileNavButton,
                                background: '#009e90',
                                height: '42px',
                                color: 'white',
                                width: '100%',
                                margin: '0 8px 0 0',
                            }}
                            onClick={form.handleSubmit}
                            fullWidth
                            variant="contained"
                            color="primary"
                            disableElevation>
                            <TR name={'Save1'} />
                        </Button>
                        <Margin tm={30}></Margin>
                        <Button
                            style={{
                                ...mobileNavButton,
                                background: '#D6EFEC',
                                height: '42px',
                                color: '#009e90',
                                width: '100%',
                                margin: '0 0 0 8px',
                            }}
                            onClick={() => props.onClose?.()}
                            fullWidth
                            variant="contained"
                            color="primary"
                            disableElevation>
                            <TR name={'cancelFav'} />
                        </Button>
                    </div>
                    <Box pt={1} />
                </Container>
                <Margin tm={30}></Margin>

                <Container>
                    <div>
                        {!updateCredirCardInfo &&
                            (userProfile['userStatus'] == '4' || userProfile['userStatus'] == '5') && (
                                <>
                                    <div>
                                        <h3>
                                            <TR name={'Credit Card Information'} />
                                        </h3>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ minWidth: '80px' }}>
                                            <TR name={'Credit card number with colon'} />
                                        </div>
                                        <div>**** **** **** {cardInfo.last4}</div>
                                    </div>
                                    <Margin tm={10}></Margin>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ minWidth: '80px' }}>
                                            <TR name={'Expire date with colon'} />
                                        </div>
                                        <div>
                                            {cardInfo.expireMonth}/{cardInfo.expireYear}
                                        </div>
                                    </div>
                                    <Margin tm={30}></Margin>
                                    <Button
                                        style={{
                                            ...mobileNavButton,
                                            background: '#009e90',
                                            height: '42px',
                                            color: 'white',
                                            width: '100%',
                                            border: '1px solid #979797',
                                            borderRadius: '4px',
                                        }}
                                        onClick={() => {
                                            setUpdateCredirCardInfo(true);
                                        }}
                                        fullWidth
                                        variant="contained"
                                        disableElevation>
                                        <TR name={'UpdateCreditInfoTitle'} />
                                        <Margin lm={5}></Margin>

                                        <img src="assets/mobileIcons/downArrowWhite.svg" />
                                    </Button>{' '}
                                </>
                            )}
                        <Margin tm={10}></Margin>
                    </div>
                </Container>

                <Container>
                    {updateCredirCardInfo && (
                        <>
                            <h3>
                                <TR name={'Update Credit Card Information'} />
                            </h3>
                            {(userProfile['userStatus'] == '4' || userProfile['userStatus'] == '5') && (
                                <Elements stripe={stripePromise}>
                                    <ElementsConsumer>
                                        {({ elements, stripe }) => (
                                            <form id="paymentForm">
                                                <div className={classes.elementRow}>
                                                    <div className={classes.cardElementLabel}>
                                                        <TR name="CREDIT_CARD_NUM"></TR>
                                                    </div>
                                                    <div className={classes.cardElement}>
                                                        <CardNumberElement></CardNumberElement>
                                                    </div>
                                                </div>

                                                <Margin tm={15}></Margin>

                                                <div className={classes.elementRow}>
                                                    <div className={classes.cardElementLabel}>
                                                        <TR name="CREDIT_CARD_EXP"></TR>
                                                    </div>

                                                    <div className={classes.cardElement}>
                                                        <CardExpiryElement></CardExpiryElement>
                                                    </div>
                                                </div>

                                                <Margin tm={15}></Margin>
                                                <div className={classes.elementRow}>
                                                    <div className={classes.cardElementLabel}>
                                                        <TR name="CREDIT_CARD_CVC"></TR>
                                                    </div>

                                                    <div className={classes.cardElement}>
                                                        <CardCvcElement></CardCvcElement>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    </ElementsConsumer>
                                </Elements>
                            )}

                            <Margin tm={20}></Margin>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    style={{
                                        ...mobileNavButton,
                                        background: '#009e90',
                                        height: '42px',
                                        color: 'white',
                                        width: '100%',
                                        margin: '0 8px 0 0',
                                    }}
                                    onClick={form.handleSubmit}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disableElevation>
                                    <TR name={'Save'} />
                                </Button>
                                <Margin tm={30}></Margin>
                                <Button
                                    style={{
                                        ...mobileNavButton,
                                        background: '#D6EFEC',
                                        height: '42px',
                                        color: '#009e90',
                                        width: '100%',
                                        margin: '0 0 0 8px',
                                    }}
                                    onClick={() => {
                                        setUpdateCredirCardInfo(false);
                                    }}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disableElevation>
                                    <TR name={'cancelUpdate'} />
                                </Button>
                            </div>
                        </>
                    )}
                </Container>
                <Margin tm={20}></Margin>
            </Grid>
        );
    };
});

/* eslint-disable prettier/prettier */
import { Box, makeStyles, Slider } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst, state } from 'rt-state';
import { NearByTranscationChart } from '../../Charts/NearByTranscationsChart';

import { PropertyCalculator } from '../../PropertyCalculator';

import { NonRecentSoldHouseInfo } from './house/NonRecentSoldHouseInfo';
import { UnitInfoSidebarBasic } from './apartment/UnitInfoSidebarBasic';
import { UnitCards } from './apartment/UnitCards';
import { NearByTranscationsSimpleChart } from '../../Charts/NearByTranscationsSimpleChart';
import { isMobileDevice, withinMonth, yearAgo } from '../../../../commons/utils';
import { PropertyInfo } from '../PropertyInfo';
import { RecentSoldHouseInfo } from './house/RecentSoldHouseInfo';
import { clsx, SecurityProvider, storage } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { MapGlobalVars, PRICE_MAP_STATUS } from '../../support/PriceMapConsts';
import { PropertyPeriodChooser } from '../PropertyPeriodChooser';
import { api_getAroundTranscations, api_getReportLink } from '../../../../services/property';
import { getTypeByFeature } from '../../support/propertyFeatureHelper';

import { getPriceMapLevelByZoom, triggrMemberWindowForVisitor } from '../../support/common';
import { MapButton } from '../../components/MapButton';
import { PropertyReport } from '../../../PropertyReport';
import { PropertyReportResult } from '../../../PropertyReport/PropertyReportResult';
import { HouseReport } from '../../../PropertyReport/HouseReport';
import { ROUTES } from '../../../../routes';
import { Permission } from '../../../../commons/security/authz';
import { getUserProfile } from '../../../../services/auth';
import { checkReport, consumeReport, getKVEntity, saveKVEntity } from '../../../../services/userCenter';
import { UserCenterEntityType } from '../../../../models/backend/userCenter';
import { Glossary } from '../../../PropertyReport/components/Glossary';
import { AppProvider } from '../../../../commons/appProvider';

const useStyles = makeStyles((theme) => {
  return {
    sideBar: {
      position: 'absolute',
      top: '30px',
      left: '0px',
      zIndex: 1299,
      boxShadow: '0px 0px 12px 0px rgba(51,51,51,0.1)',
      borderRadius: 10,
      width: 447,
      maxHeight: window.innerHeight,
      overflowY: 'auto',
    },
    root: {
      background: 'white',

      padding: isMobileDevice() ? 0 : 20,
      paddingBottom: isMobileDevice() ? 0 : 40,
      position: 'relative',
    },

    closeIcon: {
      position: 'absolute',
      top: 5,
      right: 5,
      cursor: 'pointer',
    },
    chartSwitch: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'center',
    },
    switcherLine: {
      width: 16,
      height: 3,
      cursor: 'pointer',
    },
  };
});
export const PropertySideBar = rst.create<{ prop: any; hide?: boolean }>((ctx) => {
  const securityProvider = SecurityProvider.use();
  const appProvider = AppProvider.use();
  const pageState = rst.state({
    currentChart: 1,
    aroundTrans: null,
    clickedProp: null,
    showReport: false,
    showReportResult: false,
  });

  const getAroundTrans = (interval?) => {
    const propType = getTypeByFeature(pageState.clickedProp.allFeatures);
    api_getAroundTranscations(
      propType,
      pageState.clickedProp.lat,
      pageState.clickedProp.lon,
      interval || 12,
      '',
    ).then((trans: any) => {
      pageState.aroundTrans = trans;
    });
  };

  EventBus.on(EVENT_NAMES.EVENT_PROP_SIDE_BAR, (evt) => {
    if (evt.showSideBar || evt.retrieveAroundTrans || evt.forMobile) {
      setTimeout(() => {
        pageState.clickedProp = evt.prop;
        getAroundTrans();
      }, 500);
    }
  });

  const changeChartEvent = (chart) => {
    pageState.currentChart = chart;
  };

  const hasLogin = () => {
    return securityProvider.hasLogin();
};

  const generateReport = async () => {

    if (
      hasLogin()
    ) {
      console.log(ctx.props.prop);
      const reportCheckedBefore = await getKVEntity(
        UserCenterEntityType.PropertyReportAddtion,
        ctx.props.prop.propId,
      );

      getUserProfile().then((userProfile) => {
        if (userProfile.reportCountProperty <= 5 && !reportCheckedBefore.entityType) {
          EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
            open: true,
            title: 'REPORT_REMAINING',
            content:
                            userProfile.reportCountProperty > 0
                              ? securityProvider.hasPermission(Permission.TRIAL)
                                ? 'REPORT_REMAINING_MESSAGE'
                                : 'REPORT_REMAINING_MESSAGE1'
                              : securityProvider.hasPermission(Permission.TRIAL)
                                ? 'REPORT_NO_REMAINING_MESSAGE_TRIAL'
                                : 'REPORT_NO_REMAINING_MESSAGE',
            contentParams: { count_viewed: 10, count_remaining: userProfile.reportCountProperty },
            confirmEvt: userProfile.reportCountProperty > 0 ? confirmReport : sendEmail,
            cancelButtonText: userProfile.reportCountProperty > 0 ? 'CANCEL' : 'CLOSE',
            confirmButtonText: userProfile.reportCountProperty == 0 ? 'CONTACT_SUPPORT' : 'CONTINUE',
          });
        } else {
          confirmReport();
        }
      });
    } else {
      appProvider.openLoginDialog(null);
    }
  };

  const requestURL = window.location.search;
  let blinkingInterval: any = 0;
  if (requestURL.indexOf('showSoldTimeline') > -1) {
    if (!isMobileDevice()) {
      setTimeout(() => {
        changeChartEvent(0);
        blinkingInterval = setInterval(() => {
          blinkChartBorder();
        }, 500);

        setTimeout(() => {
          clearInterval(blinkingInterval);
          blinkChartBorder(true);
        }, 15 * 1000);
      }, 5000);
    }
  }
  if (requestURL.indexOf('showReport') > -1) {
    setTimeout(() => {
      generateReport();
    }, 5000);
  }

  const blinkChartBorder = (removeBorder?: any) => {
    const blinkEle = document.getElementById('blinkingBorder');
    if (blinkEle != null) {
      if (blinkEle.style.display == 'block' || removeBorder) {
        document.getElementById('blinkingBorder').style.display = 'none';
      } else {
        document.getElementById('blinkingBorder').style.display = 'block';
      }
    }
  };

  const closeSideBar = () => {
    if (MapGlobalVars.showAroundTransCircle) {
      EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, { showAroundTrans: false });
    }

    EventBus.dispatch(EVENT_NAMES.EVENT_PROP_SIDE_BAR, { showSideBar: false });
  };
  const confirmReport = () => {
    let inMonthSold = false;
    try {
      inMonthSold = withinMonth(ctx.props.prop.sold_contract_date, 'YYYY-MM-DD');
    } catch (e) {}

    if (inMonthSold) {
      console.log(ctx.props.prop);

      MapGlobalVars.currentPropertyEstimate = {
        estimatePrice: { middle: ctx.props.prop.price, inMonth: true },
        guidePrice: 0,
        saleWay: '1',
        smartTags: ctx.props.prop.allFeatures?.smart_tags.filter((loopTag: any) => {
          return loopTag.selected != 0;
        }),
      };

      // save report selection to backend for the current user
      saveKVEntity(
        UserCenterEntityType.PropertyReportAddtion,
        ctx.props.prop.propId,
        MapGlobalVars.currentPropertyEstimate,
      );
      // consume report
      consumeReport(ctx.props.prop.propId);

      showReportResult();
    } else {
      pageState.showReport = true;
    }
  };
  const sendEmail = () => {
    window.location.href = 'mailto:support@homeon.com.au';
  };

  const showReportResult = async () => {
    pageState.showReport = false;
    pageState.showReportResult = true;
    await storage.save('CURRENT_REPORT_PROPERTY_FEATURE', JSON.stringify(ctx.props.prop.allFeatures));
    let reportURL = ROUTES.HouseReport.path;
    reportURL +=
            '?reportPropId=' +
            ctx.props.prop.propId +
            '&estimateInfo=' +
            JSON.stringify(MapGlobalVars.currentPropertyEstimate);

    window.open(reportURL, '_blank');
  };

  return (props) => {
    const getHouseDetailContent = () => {
      return (
        <>
          <PropertyReport
            reportEvt={showReportResult}
            cancelEvt={() => {
              pageState.showReport = false;
            }}
            property={props.prop}
            open={pageState.showReport}></PropertyReport>
          <div style={{ position: 'relative' }}>
            <div
              id="blinkingBorder"
              style={{
                display: 'none',
                top: '350px',
                left: '-10px',
                position: 'absolute',
                border: '3px dotted red',
                width: '106%',
                height: '220px',
                zIndex: 100000,
              }}></div>
            {!isMobileDevice() ? (
              <>
                <NonRecentSoldHouseInfo prop={props.prop}></NonRecentSoldHouseInfo>
                <hr
                  style={{
                    backgroundColor: '#d8d8d8',
                    height: 1,
                    border: 'none',
                    marginTop: 19,
                  }}></hr>
              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
                {/* <Glossary glossaryId="37" /> */}
              </div>
            )}

            <div style={{ marginTop: 10 }}></div>
            <MapButton
              shadow={'4px 4px #999999'}
              bg="#009E90"
              bgOpcacity={1}
              textColor={'white'}
              fontSize="14px"
              w={'100%'}
              clickEvent={generateReport}
              label={'GENERATE_REPORT'}></MapButton>

            {isMobileDevice() ? (
              <>
                <div style={{ marginTop: 10 }}></div>
                <div
                  style={{
                    display: pageState.currentChart == 1 ? 'block' : 'none',
                  }}>
                  <NearByTranscationsSimpleChart
                    changeChartEvt={changeChartEvent}
                    prop={props.prop}></NearByTranscationsSimpleChart>
                </div>

                <div
                  style={{
                    display: pageState.currentChart == 0 ? 'block' : 'none',
                  }}>
                  <NearByTranscationChart
                    chartChangeEvt={changeChartEvent}
                    changeIntervalEvt={getAroundTrans}
                    property={props.prop}
                    aroundTrans={pageState.aroundTrans}></NearByTranscationChart>
                </div>

                <hr
                  style={{
                    backgroundColor: '#d8d8d8',
                    height: 1,
                    border: 'none',
                    marginTop: 15,
                  }}></hr>
              </>
            ) : (
              <>
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
                  {/* <Glossary glossaryId="37" /> */}
                </div>

                <div
                  style={{
                    display: pageState.currentChart == 1 ? 'block' : 'none',
                  }}>
                  <NearByTranscationsSimpleChart
                    changeChartEvt={changeChartEvent}
                    prop={props.prop}></NearByTranscationsSimpleChart>
                </div>

                <div
                  style={{
                    display: pageState.currentChart == 0 ? 'block' : 'none',
                  }}>
                  <NearByTranscationChart
                    chartChangeEvt={changeChartEvent}
                    changeIntervalEvt={getAroundTrans}
                    property={props.prop}
                    aroundTrans={pageState.aroundTrans}></NearByTranscationChart>
                </div>

                <hr
                  style={{
                    backgroundColor: '#d8d8d8',
                    height: 1,
                    border: 'none',
                    marginTop: 15,
                  }}></hr>
              </>
            )}

            <PropertyCalculator property={props.prop} chart={pageState.currentChart}></PropertyCalculator>
          </div>
        </>
      );
    };
    const classes = useStyles();
    const getSidebarTop = () => {
      if (
        !isMobileDevice() &&
                getPriceMapLevelByZoom(MapGlobalVars.currentMapZoom) == PRICE_MAP_STATUS.PROPERTYLEVEL
      ) {
        return '0px';
      }
      return 0;
    };
    return (
      <div
        className={clsx(classes.root, isMobileDevice() ? '' : classes.sideBar)}
        style={{ top: getSidebarTop(), display: props.hide ? 'none' : 'block' }}>
        {isMobileDevice() ? null : (
          <div className={classes.closeIcon}>
            <img
              src="assets/icons/close.svg"
              onClick={closeSideBar}
              style={{ width: 10, height: 10, opacity: 0.3 }}></img>
          </div>
        )}

        {props.prop?.propType == 'House' ? (
          getHouseDetailContent()
        ) : (
          <>
            <UnitInfoSidebarBasic prop={props.prop}></UnitInfoSidebarBasic>

            {/* <NearByTranscationsSimpleChart></NearByTranscationsSimpleChart> */}
            {pageState.aroundTrans && pageState.aroundTrans.length > 0 ? (
              <>
                <hr style={{ backgroundColor: '#d8d8d8', height: 1, border: 'none' }}></hr>

                <NearByTranscationChart
                  changeIntervalEvt={getAroundTrans}
                  aroundTrans={pageState.aroundTrans}
                  property={props.prop}></NearByTranscationChart>
              </>
            ) : null}
          </>
        )}
      </div>
    );
  };
});
const ChartSwitcher = rst.create<{ chartChangeEvent: any; prop: any }>((ctx) => {
  const pageState = rst.state({
    currentChart: 1,
  });
  return (props) => {
    const changeChart = () => {
      if (pageState.currentChart == 0) {
        pageState.currentChart = 1;
        EventBus.dispatch(EVENT_NAMES.EVENT_REQUEST_RESEND_ESTIMATE_VALUE, {});
      } else {
        pageState.currentChart = 0;
      }
      props.chartChangeEvent(pageState.currentChart);

      setTimeout(() => {
        EventBus.dispatch(MapGlobalVars.nearByTransEventName, {
          chart: pageState.currentChart,
          prop: props.prop,
        });
      }, 100);
    };
    const classes = useStyles();
    return (
      <>
        <div style={{ padding: 10, cursor: 'pointer' }} onClick={changeChart}>
          <div
            className={classes.switcherLine}
            style={{ background: pageState.currentChart == 1 ? '#6DA1E0' : '#E7F1FD' }}></div>
        </div>
        <div style={{ padding: 10, cursor: 'pointer' }} onClick={changeChart}>
          <div
            className={classes.switcherLine}
            style={{ background: pageState.currentChart == 0 ? '#6DA1E0' : '#E7F1FD' }}></div>
        </div>
      </>
    );
  };
});

import * as React from 'react';
import { Button, Container, Grid } from '@material-ui/core';
import { rst } from 'rt-state';
import { setup, PASSWORD_SCHEMA, getPassword2Validator, PageWrapper } from './common';
import { TR } from '../../commons';
import { XTextField } from '../../components/form';
import { getErrorData } from '../../commons/axios/axios';
import { changePassword } from '../../services/auth';

const initialValues = {
    password: '',
    newPassword: '',
    newPassword2: '',
};

const validationSchema = {
    password: PASSWORD_SCHEMA,
    newPassword: PASSWORD_SCHEMA,
    newPassword2: getPassword2Validator('newPassword'),
};

export const ChangePasswordPage: React.FC<{}> = () => (
    <PageWrapper>
        <ChangePasswordComp />
    </PageWrapper>
);

export const ChangePasswordComp = rst.create<{ onClose?: () => void }>((ctx) => {
    const { snackbarController, form } = setup(initialValues, validationSchema, onChangePassword);

    async function onChangePassword(data: any) {
        try {
            const { password, newPassword } = data;
            await changePassword(password, newPassword);
            snackbarController.open(<TR name={'success'} />, 'success');
            ctx.props.onClose?.(); // eslint-disable-line
        } catch (e) {
            snackbarController.open(<TR name={'failed'} />, 'error');
            console.error(getErrorData(e));
        }
    }
    ////////////////////////////////////////////////////////////////////////
    return (props) => {
        return (
            <Grid container justify={'center'} direction={'column'} alignItems={'center'}>
                <Container>
                    <Grid container direction={'column'} alignItems={'flex-start'}>
                        <h2>
                            <TR name={'Password Setting'} />
                        </h2>
                    </Grid>
                    <XTextField form={form} name="password" label={<TR name={'Old Password'} />} type="password" />
                    <XTextField form={form} name="newPassword" label={<TR name={'New Password'} />} type="password" />
                    <XTextField
                        form={form}
                        name="newPassword2"
                        label={<TR name={'New Password Again'} />}
                        type="password"
                    />
                    <Button
                        className={'mt-2'}
                        onClick={form.handleSubmit}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!form.dirty}
                        disableElevation>
                        <TR name={'Save'} />
                    </Button>
                </Container>
            </Grid>
        );
    };
});

/* eslint-disable prettier/prettier */
import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { clsx, generateGUID, SecurityProvider, TR } from '../../../../commons';

import { PropertyFilterPanel } from './PropertyFilterPanel';
import { MapControlButtonBase } from '../../components/MapControlButtonBase';
import { MapToggleButton } from '../../components/MapToggleButton';
import { SpecialCare } from './SpecialCare';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { getPriceMapLevelByZoom, triggrMemberWindowForVisitor } from '../../support/common';
import { MapGlobalVars, PRICE_MAP_STATUS, PropTypes } from '../../support/PriceMapConsts';
import { SuburbSummaryPanel } from '../SuburbSummary/SuburbSummaryPanel';
import { getUserProfile } from '../../../../services/auth';
import { checkQuota, getAvailableCountOfSuburbSearch } from '../../../../services/userCenter';
import { Glossary } from '../../../PropertyReport/components/Glossary';
import { Permission } from '../../../../commons/security/authz';
import { AppProvider } from '../../../../commons/appProvider';

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    panel: {
      width: 428,

      background: '#ffffff',
      borderRadius: 8,
      boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.2)',
      position: 'relative',
      padding: '17px',
      display: 'block',
    },
    subFilterTitleDiv: {
      display: 'flex',
    },
    subFilterTitle: {
      fontWeight: 700,
      fontSize: '20px',
      flexGrow: 1,
    },
    folderIcon: {
      cursor: 'pointer',
    },
    toggleButtonDiv: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    toggleButton: {
      padding: '10px 30px 10px 30px',
      fontSize: '14px',
      background: 'white',
    },
    toggleSelected: {
      background: theme.palette.primary.main,
    },
    streetRank: {
      width: '100%',
      justifyContent: 'space-around',
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
    },
    streetRankButton: {
      padding: '5px 10px',
      border: '1px solid',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };
});

export const PCFilter = rst.create((ctx) => {
  const securityProvider = SecurityProvider.use();
  const appProvider = AppProvider.use();
  const state = rst.state({
    mapStaus: PRICE_MAP_STATUS.SUBURBLEVEL,
    suburbData: {},
    medianData: {},

    showFilterPanel: true,
    toggleButtonValue: 1,
    showFilterButton: true,
  });
  const PropType = rst.stateS('House');

  EventBus.on(EVENT_NAMES.EVENT_MAP_ZOOMED, (zoomLevel) => {
    state.mapStaus = getPriceMapLevelByZoom(zoomLevel);

    if (state.mapStaus != PRICE_MAP_STATUS.SUBURBLEVEL) {
      state.toggleButtonValue = 0;
    } else {
      state.toggleButtonValue = 1;
    }
  });

  EventBus.on(EVENT_NAMES.EVENT_SWITCH_MAP_ICONS, (show) => {
    state.showFilterButton = show;
  });

  EventBus.on(EVENT_NAMES.EVENT_PROP_MARKER_CLICKED, (evt) => {
    state.suburbData = MapGlobalVars.clickedSuburbData;
    // console.log(state.suburbInfo);
  });

  EventBus.on(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, (evt) => {
    setTimeout(() => {
      state.suburbData = MapGlobalVars.clickedSuburbData;
    }, 500);
  });

  EventBus.on(EVENT_NAMES.EVENT_FOLD_FILTER_PANEL, () => {
    state.showFilterPanel = false;
  });

  function foldFilterPanel() {
    state.showFilterPanel = !state.showFilterPanel;
    // if show filter panel, should fold the search input if open
    EventBus.dispatch(EVENT_NAMES.EVENT_FOLD_SEARCH_INPUT, {});
  }

  function switchFilter(toggleButtonValue) {
    state.toggleButtonValue = toggleButtonValue;
  }
  const handlePropType = (type) => {
    PropType.value = type;
    console.log(PropType.value);
  };

  return (props) => {
    const classes = useStyles();
    const checkSuburbQueryPermission = (whichButton) => {

      if (whichButton == 1) {
        EventBus.dispatch(EVENT_NAMES.EVENT_OPEN_STREET_DIALOG, {});
      }
      if (whichButton == 2) {
        EventBus.dispatch(EVENT_NAMES.EVENT_OPEN_SOLD_BY_BED_DIALOG, {});
      }
     

      
    };
    function openStreetDialog() {
      checkSuburbQueryPermission(1);
    }

    function openSoldByBedsDialog() {
      checkSuburbQueryPermission(2);
    }
    return (
      <div
        style={{
          display:
                        state.mapStaus != PRICE_MAP_STATUS.ALLPROPERTYLEVEL &&
                        state.showFilterButton &&
                        !MapGlobalVars.showAroundTransCircle
                          ? 'flex'
                          : 'none',
        }}>
        <div style={{ display: state.showFilterPanel ? 'block' : 'none' }}>
          <div className={classes.panel}>
            <div style={{ display: state.toggleButtonValue == 0 ? 'block' : 'none' }}>
              <SubFilterContainer
                title={state.mapStaus == PRICE_MAP_STATUS.PROPERTYLEVEL ? 'FilterMap' : ''}>
                <PropertyFilterPanel handlePropType={handlePropType}></PropertyFilterPanel>
              </SubFilterContainer>

              {state.mapStaus == PRICE_MAP_STATUS.PROPERTYLEVEL &&
                            state.suburbData &&
                            state.suburbData['median_data'] ? (
                  <>
                    <div style={{ marginTop: 20 }}></div>
                    <SubFilterContainer title={'Suburb Info'}>
                      <SuburbSummaryPanel
                        suburbData={state.suburbData}
                        medianData={state.medianData}
                        sideBar="true"></SuburbSummaryPanel>
                    </SubFilterContainer>
                  </>
                ) : null}

              <div className={classes.streetRank}>
                <div onClick={openStreetDialog} className={classes.streetRankButton}>
                  <TR name={'STREET_RANK_BUTTON'}></TR>
                </div>

                <div className={classes.streetRankButton} onClick={openSoldByBedsDialog}>
                  <TR name={'PRICE_BY_BED_BUTTON'}></TR>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Glossary glossaryId="17" />
              </div>
            </div>

            <div style={{ display: state.toggleButtonValue == 1 ? 'block' : 'none' }}>
              <SubFilterContainer>
                <SpecialCare PropType={PropType.value}></SpecialCare>
              </SubFilterContainer>
            </div>

            <div style={{ marginTop: '20px' }}></div>
          </div>
        </div>
        {state.showFilterPanel ? (
          <MapControlButtonBase
            clickEvent={foldFilterPanel}
            icon={'filter'}
            marginLeft={19}
            arrowPosition="LEFT"></MapControlButtonBase>
        ) : (
          <MapControlButtonBase
            clickEvent={foldFilterPanel}
            icon={'filter'}
            marginLeft={19}></MapControlButtonBase>
        )}
      </div>
    );
  };
});

export const SubFilterContainer = rst.create<{ title?: string }>((ctx) => {
  const componentID = generateGUID();
  const state = rst.state({
    fold: false,
  });
  return (props) => {
    const classes = useStyles();
    function toggle() {
      state.fold = !state.fold;
    }

    return (
      <>
        <div
          id={componentID + '_filterContainer'}
          className={classes.subFilterTitleDiv}
          style={{ display: props.title ? '' : 'none' }}>
          <div className={classes.subFilterTitle}>
            <TR name={props.title} />
          </div>
          <div className={classes.folderIcon} onClick={toggle}>
            <img
              src={
                'assets/icons/' + (state.fold ? 'arrow-lower-ico' : 'arrow-lupper-ico') + '.svg'
              }></img>
          </div>
        </div>
        {state.fold ? null : props.children}
      </>
    );
  };
});

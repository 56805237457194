import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
const useStyles = makeStyles((theme) => {
    return {};
});
export const ErrorBox = rst.create((ctx) => {
    const state = rst.state({
        show: false,
        title: '',
        content: '',
    });
    EventBus.on(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, (evt) => {
        state.show = true;
        state.title = evt.title;
        state.content = evt.content;
    });
    function handleClick(isOpen: boolean) {
        state.show = isOpen;
    }

    return (props) => {
        const classes = useStyles();
        return (
            <>
                <Dialog
                    onClose={() => handleClick(false)}
                    aria-labelledby="customized-dialog-title"
                    open={state.show}
                    fullWidth={true}>
                    <DialogTitle>
                        <TR name={state.title} />
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <TR name={state.content} />
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => handleClick(false)} color="primary">
                            <TR name={'Get it'} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };
});

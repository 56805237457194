/**
 * 这个是传递两个相对独立组件之间事件的中转站，
 * 使用方法：在发布事件的组件中dispatch 事件以及相应参数
 *           在接收事件的组件中监听on 事件以及做相应的处理
 *           在组件卸载时， remove事件监听器
 */
export const EventBus = {
    on(event, callback) {
        document.addEventListener(event, (e) => callback(e.detail));
    },
    dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
        document.removeEventListener(event, callback);
    },
};

export const EVENT_NAMES = {
    EVENT_ZOOM_MAP: 'EVENT_ZOOM_MAP',
    EVENT_CHANGE_FILTER: 'EVENT_CHANGE_FILTER',
    EVENT_MAP_ZOOMED: 'EVENT_MAP_ZOOMED',
    EVENT_TOGGLE_PRICE_LABEL: 'EVENT_TOGGLE_PRICE_LABEL',
    EVENT_SUBURB_POLYGON_CLICKED: 'EVENT_SUBURB_POLYGON_CLICKED',
    EVENT_SUBURB_NAME_CLICK: 'EVENT_SUBURB_NAME_CLICK', // triggered from suburb name, received by suburb polygon controller to show suburb info popup
    EVENT_CHANGE_PERIOD: 'EVENT_CHANGE_PERIOD', // triggered from period raido button chagne on map, received by price map controller and will empty the markers and retrieve data again from backend.
    EVENT_SHOW_SUBURB_TRANS: 'EVENT_SHOW_SUBURB_TRANS',
    EVENT_CHANGE_MAP_TYPE: 'EVENT_CHANGE_MAP_TYPE', // CHANGE MAP TYPE between satellite and plain , received by map index, which switch between 2 types
    EVENT_DRAW_MY_LOCATION: 'EVENT_DRAW_MY_LOCATION',
    EVENT_NEAR_BY_TRANS_AXIS_POINT_CLICKED: 'EVENT_NEAR_BY_TRANS_AXIS_POINT_CLICKED', // triggerred by the point clicked which on the near by transcations chart
    EVENT_PROP_POP_UP_CLOSED: 'EVENT_PROP_POP_UP_CLOSED', // trigged from property popup closed and captured by nearby transcation chart
    EVENT_PROP_MARKER_CLICKED: 'EVENT_PROP_MARKER_CLICKED',
    EVENT_PROP_SIDE_BAR: 'EVENT_PROP_SIDE_BAR',
    EVENT_CHANGE_SPECAIL_FILTER: 'EVENT_CHANGE_SPECAIL_FILTER',
    EVENT_UPDATE_PRICE_LEGEND: 'EVENT_UPDATE_PRICE_LEGEND',
    EVENT_FOLD_FILTER_PANEL: 'EVENT_FOLD_FILTER_PANEL',
    EVENT_FOLD_SEARCH_INPUT: 'EVENT_FOLD_SEARCH_INPUT',
    EVENT_UPDATE_PRICE_MAP_TITLE: 'EVENT_UPDATE_PRICE_MAP_TITLE',
    EVENT_TOGGLE_RAILMAP_LAYER: 'EVENT_TOGGLE_RAILMAP_LAYER',
    EVENT_TOGGLE_MAP_PROGRESSBAR: 'EVENT_TOGGLE_MAP_PROGRESSBAR',
    EVENT_SHOW_SEARCH_INPUT: 'EVENT_SHOW_SEARCH_INPUT',
    EVENT_CHANGE_MOBILE_TAB: 'EVENT_CHANGE_MOBILE_TAB',
    EVENT_SHOW_ERROR_MESSAGE: 'EVENT_SHOW_ERROR_MESSAGE',
    EVENT_SHOW_GENERAL_ERROR: 'EVENT_SHOW_GENERAL_ERROR',
    EVENT_DRAW_MAP_ROUTE: 'EVENT_DRAW_MAP_ROUTE',
    EVENT_DRAW_MAP_CIRCLE: 'EVENT_DRAW_MAP_CIRCLE',
    EVENT_SEARCHED_PROP: 'EVENT_SEARCHED_PROP', // triggered when searched property from search input which on map, will received in property marker controller and marker the property ,
    EVENT_CHANGE_PROP_BEDS: 'EVENT_CHANGE_PROP_BEDS', // trigger when change property beds or tags, received by property calculator and call backend to estimate value
    EVENT_RECENTER_CURRENT: 'EVENT_RECENTER_CURRENT', // trigger from property information card location icon
    EVENT_ESTIMAGE_VALUE_CHANGE: 'EVENT_ESTIMAGE_VALUE_CHANGE', // trigger after get new value from backend
    EVENT_REFRESH_SIDEBAR_AROUND_TRANS: 'EVENT_REFRESH_SIDEBAR_AROUND_TRANS', // trigger after clicked property marker to retrieve around transcations
    EVENT_REQUEST_RESEND_ESTIMATE_VALUE: 'EVENT_REQUEST_RESEND_ESTIMATE_VALUE', // trigger when switch to bed bar price chart, send the estimate value again as the first time sent event, did not receive by chat
    EVENT_SHOW_TRANS_FROM_SIMPLE_CHART: 'EVENT_SHOW_TRANS_FROM_SIMPLE_CHART',
    EVENT_SYNC_INTERVAL_BETWEEN_2_CHARTS: 'EVENT_SYNC_INTERVAL_BETWEEN_2_CHARTS',
    EVENT_FULL_MAP_FROM_INDEX: 'EVENT_FULL_MAP_FROM_INDEX',
    EVENT_SHOW_POP_MENU_ON_MAP: 'EVENT_SHOW_POP_MENU_ON_MAP',
    EVENT_CLOSE_POP_MENU_ON_MAP: 'EVENT_CLOSE_POP_MENU_ON_MAP',
    EVENT_SHOW_CLICKED_SUBURB_TRANS: 'EVENT_SHOW_CLICKED_SUBURB_TRANS',
    EVENT_SHOW_ALL_TRANS: 'EVENT_SHOW_ALL_TRANS',
    EVENT_SWITCH_TO_ALL_PROPERTIES_LEVEL: 'EVENT_SWITCH_TO_ALL_PROPERTIES_LEVEL',
    EVENT_REQEUST_SEARCHED_PROP_SUBURB_DATA: 'EVENT_REQEUST_SEARCHED_PROP_SUBURB_DATA',
    EVENT_VERIFY_PERMISSION: 'EVENT_VERIFY_PERMISSION',
    EVENT_TOGGLE_FAV_ON_MAP: 'EVENT_TOGGLE_FAV_ON_MAP',
    EVENT_SET_FAV_BUTTON_STATUS: 'EVENT_SET_FAV_BUTTON_STATUS',
    EVENT_HIDE_ALL_PROPERTIES: 'EVENT_HIDE_ALL_PROPERTIES',
    EVENT_RECALCULATE_PROPERTY_PRICE: 'EVENT_RECALCULATE_PROPERTY_PRICE',
    EVENT_SHOW_LOADING_INDICATOR: 'EVENT_SHOW_LOADING_INDICATOR',
    EVENT_CHANGE_SUBURB_COLOR_BY: 'EVENT_CHANGE_SUBURB_COLOR_BY',
    EVENT_SWITCH_MAP_ICONS: 'EVENT_SWITCH_MAP_ICONS',
    EVENT_SHOW_PRICE_MAP_TITLE: 'EVENT_SHOW_PRICE_MAP_TITLE',
    EVENT_OPEN_STREET_DIALOG: 'EVENT_OPEN_STREET_DIALOG',
    EVENT_OPEN_SOLD_BY_BED_DIALOG: 'EVENT_OPEN_SOLD_BY_BED_DIALOG',
    EVENT_OPEN_BUY_MEMBER_DIALOG: 'EVENT_OPEN_BUY_MEMBER_DIALOG',
    EVENT_SHOW_REFER_CURRENT_SOLD_PRICE: 'EVENT_SHOW_REFER_CURRENT_SOLD_PRICE',
    EVENT_RESETMAP_AFTER_LOGIN: 'EVENT_RESETMAP_AFTER_LOGIN',
    EVENT_SWITCH_DIALOG: 'EVENT_SWITCH_DIALOG',
    EVENT_SHOW_FLOAT_MESSAGE: 'EVENT_SHOW_FLOAT_MESSAGE',
    EVENT_AVOID_OVERLAY: 'EVENT_AVOID_OVERLAY',
    EVENT_SHOW_VIDEO_PLAYER: 'EVENT_SHOW_VIDEO_PLAYER',
    EVENT_RECALCULATE_PROPERTY_PRICE_ISCLICKED: 'EVENT_RECALCULATE_PROPERTY_PRICE_ISCLICKED',
    EVENT_SWITCH_ENLARGE_STREETVIEW: 'EVENT_SWITCH_ENLARGE_STREETVIEW',
    EVENT_MOBILE_IN_DETAIL_PAGE: 'EVENT_MOBILE_IN_DETAIL_PAGE',
    EVENT_MOBILE_SHOW_SUBURB: 'EVENT_MOBILE_SHOW_SUBURB',
    EVENT_MOBILE_SHOW_FUNC: 'EVENT_MOBILE_SHOW_FUNC',
    EVENT_MOBILE_CARD_NOPERMISSION: 'EVENT_MOBILE_CARD_NOPERMISSION',
    EVENT_MOBILE_SHOW_STREET: 'EVENT_MOBILE_SHOW_STREET',
    EVENT_MOBILE_SHOW_PROPERTY: 'EVENT_MOBILE_SHOW_PROPERTY',
    SHOW_MOBLIE_LOADING: 'SHOW_MOBLIE_LOADING',
    EVENT_MOBILE_BACK_TO_HOME: 'EVENT_MOBILE_BACK_TO_HOME',
    EVENT_SHOW_LOGIN: 'EVENT_SHOW_LOGIN',
    EVENT_RETRIEVE_PROPERTY_ADJUST: 'EVENT_RETRIEVE_PROPERTY_ADJUST',
    EVENT_RETRIEVE_COMP_PROP_NUM: 'EVENT_RETRIEVE_COMP_PROP_NUM',
    SHOW_PRIMARY_INFO_FOR_MAP: 'SHOW_PRIMARY_INFO_FOR_MAP',
    EVENT_SHOW_GOOGLE_SEARCH_RESULT: 'EVENT_SHOW_GOOGLE_SEARCH_RESULT',
    EVENT_MOBILE_SHOW_ALL_COMPARABLE: 'EVENT_MOBILE_SHOW_ALL_COMPARABLE', //show all comparable property
    EVENT_MOBILE_SHOW_LESS_COMPARABLE: 'EVENT_MOBILE_SHOW_LESS_COMPARABLE', //show less comparable property
    EVENT_MOBILE_ORIGINAL_PROPERTY_FOR_ALL_COMPARABLE: 'EVENT_MOBILE_ORIGINAL_PROPERTY_FOR_ALL_COMPARABLE', //transfer original peoperty data for all comparable property
    EVENT_MOBILE_SHOW_ALL_COMPARABLE_BACK: 'EVENT_MOBILE_SHOW_ALL_COMPARABLE_BACK', //transfer back the state of showing all comparable
    EVENT_MOBILE_ALL_COMPARABLE_GOOGLESEARCH: 'EVENT_MOBILE_ALL_COMPARABLE_GOOGLESEARCH',
    EVENT_SWITCH_TO_APARTMENT: 'EVENT_SWITCH_TO_APARTMENT',
    EVENT_MOBILE_LOCALDATA: 'EVENT_MOBILE_LOCALDATA',
    EVENT_TRANSFER_SHOW_ALL_COMPARABLE: 'EVENT_TRANSFER_SHOW_ALL_COMPARABLE',
};

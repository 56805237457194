import * as _ from 'lodash';

export class Arrays {
    static hasItems(arr: any) {
        return Array.isArray(arr) && arr.length > 0;
    }

    static compareFunc(orderFields: SortFields) {
        const keys = Object.keys(orderFields);

        return function (a: any, b: any) {
            for (const key of keys) {
                const order = orderFields[key];
                if (order == null) {
                    continue;
                }
                const v1 = _.get(a, key);
                const v2 = _.get(b, key);
                if (v1 == null && v2 == null) {
                    continue;
                }
                if (v1 === v2) {
                    continue;
                }
                const isAsc = order === 'asc';
                if (v1 == null) {
                    return isAsc ? -1 : 1;
                }
                if (v2 == null) {
                    return isAsc ? 1 : -1;
                }
                if (isAsc) {
                    return v1 > v2 ? 1 : -1;
                }
                return v1 > v2 ? -1 : 1;
            }
            return 0;
        };
    }

    static getNewSortType(orderFields: SortFields, fieldName: string) {
        const value = _.get(orderFields, fieldName);
        switch (value) {
            case 'asc':
                return 'desc';
            case 'desc':
                return null;
            default:
                return 'asc';
        }
    }
}

export type SortFields = {
    [key: string]: 'asc' | 'desc' | null;
};

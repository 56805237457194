import { addDays, addMonths, differenceInDays, format, isLastDayOfMonth, parse, parseISO, toDate } from 'date-fns';
import { enUS, zhCN } from 'date-fns/locale';
import i18next from 'i18next';
import moment from 'moment';

export const MAX_DATE = new Date(8640000000000000);
export const MIN_DATE = new Date(-8640000000000000);

function getCurrentLocale() {
    let locale = enUS;
    switch (i18next.language) {
        case 'zh':
            locale = zhCN;
            break;
        default:
    }
    return locale;
}

export const formatDate = (date: Date | string | null, dateFormat?: string): string | null => {
    if (date == null) {
        return null;
    }
    const dt = typeof date === 'string' ? new Date(date) : date;
    return format(dt, dateFormat ?? 'yyyy.MM.dd', { locale: getCurrentLocale() });
};
export const formatDateForEn = (date: Date | string | null, dateFormat?: string): string | null => {
    if (date == null) {
        return null;
    }
    const dt = typeof date === 'string' ? new Date(date) : date;
    return format(dt, dateFormat ?? 'dd/MM/yyyy', { locale: getCurrentLocale() });
};

export const formatDateTime = (date: Date | string | null, dateFormat?: string): string | null => {
    return formatDate(date, dateFormat ?? 'yyyy.MM.dd HH:mm');
};

export function isInvalidDate(value) {
    return value instanceof Date && isNaN(value.getTime());
}

export function getDaysBetweenTwoDate(date1, date2) {
    return differenceInDays(parseDate(date1), parseDate(date2));
}
export function parseDate(date1, dateFormatStr?) {
    return parseISO(date1);

    // return parse(date1, dateFormatStr || 'yyyy-MM-ddTHH:mm:ss.SSSZ', 0);
}

/**
 *
 * @param date1
 * @param addAmount
 * @param part  : 'D -- days
 *                 M --- months
 */
export function addDate(date1, addAmount, part) {
    if (part == 'D') {
        return addDays(parseDate(date1), addAmount);
    }
    if (part == 'M') {
        return addMonths(parseDate(date1), addAmount);
    }
}

export function moment_addDate(date1, addAmount, part) {
    return moment(date1).add(addAmount, part);
}
export function moment_formatDate(date1, formatStr) {
    return moment(date1).format(formatStr || 'YYYY.MM.dd');
}
export function compare2Date(d1, d2) {
    return moment(d1).diff(moment(d2)) > 0;
}

import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { addDate, formatDate, moment_addDate, moment_formatDate, TR, TRHtml } from '../../../../commons';
import { changeToFirstCaptial } from '../../../../commons/utils';
import { MobileAroundPriceBar } from './mobileAroundPriceBar';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        width: '100%',
        fontSize: '13px',
        fontFamily: 'PingFangSC, PingFangSC-Regular',
        fontWeight: 400,
        color: '#333333',
    },
    bg: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',

        background: 'rgba(216,216,216,0.10)',
        border: '1px solid #d8d8d8',
        borderRadius: '4px',
    },
    content: {
        display: 'flex',
        paddingBottom: '9px',
        flexDirection: 'column',
        padding: '14px',
    },
});
export const AdjacentResult = (props) => {
    const { propAddress, conditions, solds, showPrice, locality, apt } = props;

    const fromWhenY = () => {
        const date1 = moment_addDate(moment().valueOf(), -conditions.interval, 'M');
        return moment_formatDate(date1, 'YYYY');
    };
    const fromWhenM = () => {
        const date1 = moment_addDate(moment().valueOf(), -conditions.interval, 'M');
        return moment_formatDate(date1, 'M');
    };
    const fromWhen1 = () => {
        const date1 = moment_addDate(moment().valueOf(), -conditions.interval, 'M');
        return moment_formatDate(date1, 'MMMM YYYY');
    };

    const resizeBg = () => {
        const ele_adjacentResult = document.getElementById('adjacentResult_dom');
        if (ele_adjacentResult != null) {
            console.log(ele_adjacentResult.offsetHeight);
            document.getElementById('adjacentResult_bg_dom').style.height = ele_adjacentResult.offsetHeight + 'px';
        }
    };
    setTimeout(() => {
        resizeBg();
    }, 500);

    const classes = useStyles();
    return (
        <div className={classes.root} id="adjacentResult_dom">
            <div className={classes.bg} id="adjacentResult_bg_dom"></div>
            <div className={classes.content}>
                <TRHtml
                    html={
                        conditions.distance == 0
                            ? apt
                                ? 'searchResultInSuburbApt'
                                : 'searchResultInSuburb'
                            : apt
                            ? 'searchResultApt'
                            : 'searchResult'
                    }
                    params={{
                        propAddress: propAddress.trim(),
                        locality: changeToFirstCaptial(locality).trim(),
                        distance: conditions.distance,
                        fromWhenY: fromWhenY(),
                        fromWhenM: fromWhenM(),
                        fromWhen1: fromWhen1(),
                        totalSold: solds?.length,
                        totalSold1: solds?.length == 0 ? ' no ' : solds?.length,
                        homes: solds?.length <= 1 ? 'home' : 'homes',
                        bedRoom1:
                            conditions.bedrooms != 0
                                ? conditions.bedrooms <= 4
                                    ? conditions.bedrooms + '房'
                                    : '5房以上'
                                : '',
                        priceRange1:
                            solds?.length == 0
                                ? ''
                                : solds?.length == 1
                                ? '，价格为： '
                                : solds?.length == 2
                                ? '，价格是： '
                                : '，成交价格范围如下：',

                        priceRange:
                            solds?.length == 0
                                ? ''
                                : solds?.length == 1
                                ? ', the price is '
                                : solds?.length == 2
                                ? ' the prices are '
                                : ', the price range is as follows:',
                    }}></TRHtml>

                <div style={{ marginTop: 17 }}></div>
                {solds?.length >= 1 && (
                    <MobileAroundPriceBar solds={solds} showPrice={showPrice}></MobileAroundPriceBar>
                )}
            </div>
        </div>
    );
};

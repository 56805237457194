import { Divider, makeStyles } from '@material-ui/core';
import { slice } from 'lodash';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR, TRHtml } from '../../../commons/i18n/TR';
import { isMobileDevice } from '../../../commons/utils';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { ContentTitleData } from '../components/ContentTitleData';
import { Tips } from '../components/Tips';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '20px auto',
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            textAlign: 'left',
            padding: '5px 0',
        },
        part2: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px',
            paddingLeft: '-20px',
        },
        legendGroup: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        },
        legend: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 7,
        },
        legendItem: {
            width: '10px',
            height: '10px',
            borderRadius: '5px',
        },
        part3: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 0',
            '& span': {
                fontSize: '14px',
            },
        },
        part4: {
            background: 'rgb(250 252 255)',
            border: '1px solid rgb(236 239 246)',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px 0px',
            '& h2': {
                fontSize: '14px',
                margin: '0px 0px 8px 0px',
                color: '#B43B3B',
                background: 'linear-gradient(to bottom, rgb(250 252 255) 70%, rgb(249 235 237) 0%)',
                width: 'fit-content',
            },
            '& span': {
                fontSize: '13px',
                color: '#848383',
            },
        },
    };
});
declare var Highcharts;
const chartColor = {
    Suburb: '#ff9f40',
    Street: '#0f85f3',
};
export const Card11 = rst.create<{ property: any; mobile?: boolean }>((ctx) => {
    console.log('ctx.props: ', ctx.props);
    const originStreetData = ctx.props.property.property.street.streetSold;
    const originSuburbData = ctx.props.property.property.locality.priceTrendHouse;
    // console.log(typeof originStreetData[0].soldContractDate);
    // console.log(originStreetData[0].soldContractDate);
    // console.log(originStreetData);
    function swapThenTrans(array) {
        return [
            Date.UTC(
                parseInt(array[1].slice(0, 4), 10),
                parseInt(array[1].slice(5, 7), 10) - 1,
                parseInt(array[1].slice(8, 10), 10),
            ),
            array[0],
        ];
    }
    function getArrayFromObjArray(data) {
        const newStreetArray = [];
        if (data && data.length > 0) {
            data.map((house) => {
                newStreetArray.push(swapThenTrans(Object.values(house)));
            });
        }
        return newStreetArray;
    }
    function getArrayFromPriceTrendHouse(data: { year: string[]; yearly_median: number[] }) {
        const newSuburbArray = [];
        if (data.year && data.year.length > 0 && data.yearly_median && data.yearly_median.length > 0) {
            // choose last 5 year suburb data
            const newYear = data.year.slice(-5);
            const newYearly_median = data.yearly_median.slice(-5);
            newYear.map((year, index) => {
                newSuburbArray.push([Date.UTC(parseInt(year), 6, 1), newYearly_median[index]]);
            });
        }
        return newSuburbArray;
    }

    const Data = {
        suburbData: getArrayFromPriceTrendHouse(originSuburbData),
        streetData: getArrayFromObjArray(originStreetData),
    };
    console.log(Data);
    const sampleData = {
        suburbData: [
            [Date.UTC(2016, 6, 1), 1100000],
            [Date.UTC(2017, 6, 1), 1300000],
            [Date.UTC(2018, 6, 1), 1300000],
            [Date.UTC(2019, 6, 1), 1600000],
            [Date.UTC(2020, 6, 1), 1700000],
        ],
        streetData: [
            [Date.UTC(2019, 5, 3), 1100000],
            [Date.UTC(2018, 6, 3), 1200000],
            [Date.UTC(2019, 11, 2), 1300000],
            [Date.UTC(2019, 11, 2), 1400000],
            [Date.UTC(2020, 0, 3), 1500000],
            [Date.UTC(2016, 5, 3), 1600000],
        ],
    };
    function loadChart() {
        Highcharts.chart('card11', {
            chart: {
                height: 220,
                marginBottom: isMobileDevice() ? 30 : 35,
            },
            title: {
                text: '',
            },

            subtitle: {
                text: '',
            },

            yAxis: {
                title: {
                    text: '',
                },
            },
            xAxis: [
                {
                    title: {
                        text: '',
                    },
                    type: 'datetime',
                    // crosshair: true,
                    // 这里需要设置动态范围
                    min: Date.UTC(parseInt(originSuburbData.year.slice(-5, -4)), 0, 1),
                    max: Date.UTC(parseInt(originSuburbData.year.slice(-1)) + 1, 0, 1),
                    adaptToUpdatedData: false,
                    tickInterval: 15552000000,
                    labels: {
                        style: {
                            fontSize: '8px',
                        },
                    },
                },
                {
                    title: {
                        text: '',
                    },
                    type: 'datetime',
                    // type: 'category',
                    // categories: sampleData.streetData.date,
                    // crosshair: true,
                    // 这里需要设置动态范围
                    min: Date.UTC(2016, 0, 1),
                    max: Date.UTC(2020, 11, 31),
                    adaptToUpdatedData: false,
                    tickInterval: 15552000000,
                    labels: {
                        style: {
                            fontSize: '8px',
                        },
                    },
                },
            ],
            legend: {
                enabled: false,
            },
            credits: { enabled: false },
            tooltip: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    enableMouseTracking: true,
                    marker: {
                        enabled: true,
                    },
                },
                areaspline: {
                    fillOpacity: 0.25,
                },
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 2,
                    opacity: 1,

                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666',
                    },
                },
            },

            series: [
                {
                    type: 'spline',
                    data: Data.suburbData,
                    // color: chartColor.Suburb,
                    color: '#009E90',
                    fillOpacity: 0.3,
                    opacity: 1,
                    // lineColor: chartColor.Suburb,
                    lineColor: '#009E90',
                    zIndex: -1,
                },
                {
                    type: 'scatter',
                    // xAxis: 1,
                    // yAxis: 1,
                    data: Data.streetData,
                    color: chartColor.Street,
                    // fillOpacity: 0.3,
                    opacity: 1,
                    // lineColor: chartColor.Street,
                    marker: {
                        symbol: 'url(assets/mobileIcons/houseOnChart.svg)',
                        height: 15,
                        width: 15,
                    },
                },
            ],
        });
    }
    return (props) => {
        console.log('props: ', props);
        const classes = useStyles();
        setTimeout(() => {
            loadChart();
        }, 400);
        const compareStreetMedian = (value: number) => {
            if (value <= -0.2) {
                return <TR name={'Card11_chart1'} />;
            }
            if (value > -0.2 && value <= 0.2) {
                return <TR name={'Card11_chart2'} />;
            }
            if (value > 0.2 && value <= 0.5) {
                return <TR name={'Card11_chart3'} />;
            }
            if (value > 0.5) {
                return <TR name={'Card11_chart4'} />;
            }
        };

        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                {/* <CardWithHeader header={props.mobile ? '' : 'Card11_header'} mobile={props.mobile}> */}
                {/* <div className={classes.part1}>
                        <ContentTitleData header="Card11_street" content={props.property.property.street.streetName} />
                    </div> */}
                {/* <CardDivider /> */}
                {/* <div className={classes.part1}>
                        <ContentTitleData
                            header="Card11_suburb"
                            content={props.property.property.locality.localityName}
                        />
                    </div> */}
                <div className={classes.part2}>
                    {/* <div style={{ fontSize: '14px' }}>
                            <span>
                                <TR name={'Card11_chart'} />
                            </span>
                        </div> */}
                    <div className={classes.legendGroup}>
                        <div className={classes.legend}>
                            {/* <div className={classes.legendItem} style={{ background: chartColor.Street }}></div> */}
                            <img
                                style={{ width: '20px', height: '20px' }}
                                src="assets/mobileIcons/houseOnChart.svg"></img>
                            <div style={{ marginLeft: 7 }}>
                                <span>
                                    <TR name={'Street5YearsSold'} />
                                </span>
                            </div>
                        </div>
                        <div className={classes.legend}>
                            {/* <div className={classes.legendItem} style={{ background: chartColor.Suburb }}></div> */}
                            <img
                                style={{ width: '20px', height: '20px' }}
                                src="assets/mobileIcons/trendSample.svg"></img>
                            <div style={{ marginLeft: 7 }}>
                                <span>
                                    {props.property.property.locality.localityName}
                                    <TR name={'houseMedianTrend'} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.part3} id="card11"></div>
                <div className={classes.part4}>
                    {/* <h2>
                            <TR name="Card_Description" />
                        </h2> */}
                    <img src="assets/mobileIcons/help-ico.svg"></img>
                    <span>
                        <TRHtml
                            html="compareStreetSuburb"
                            params={{
                                streetName: props.property.property.street.streetName,
                                suburbName: props.property.property.locality.localityName,
                                streetName1: props.property.property.street.streetName,
                                suburbName1: props.property.property.locality.localityName,
                                highOrLow: (props.property.property.street.suburbMedianPct - 1) * 100 > 0 ? '高' : '低',
                                percent: Math.abs((props.property.property.street.suburbMedianPct - 1) * 100).toFixed(),
                            }}></TRHtml>
                        {/* <TR name="copmare" />
                            {props.property.property.street.streetName}
                            <TR name="fiveYearsSoldData" />
                            {props.property.property.locality.localityName}
                            <TR name="suburbSituation" />
                            {props.property.property.street.streetName}
                            {((props.property.property.street.suburbMedianPct - 1) * 100).toFixed()}
                            <TR name="CARD11_TIPS2" />
                            {(props.property.property.street.suburbMedianPct * 100).toFixed()}
                            <TR name="CARD11_TIPS3" /> */}
                    </span>
                    {/* <span>
                            <TR name="CARD11_TIPS" />
                            {compareStreetMedian(props.property.property.street.suburbMedianPct - 1)}
                        </span> */}
                </div>
                {/* </CardWithHeader> */}
            </div>
        );
    };
});

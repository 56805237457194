import { env } from '../environments';
import { API } from '../commons';
import {
    FavouriteDto,
    FavouritePo,
    FavouriteUpdatePo,
    SearchHistoryDto,
    SearchHistoryPo,
} from '../models/backend/userCenter';
import { KVEntityDto } from '../models/backend/userCenter/KVEntityDto';
import { differenceInSeconds } from 'date-fns';
import { PropertyReportDto } from '../models/backend/userCenter/PropertyReportDto';
import moment from 'moment';
import { APIResponse } from '../models/APIResponse';
import { MAP_APIS_ENDPOINTS, setEndpointParams } from './EndPoints';

const { detect } = require('detect-browser');
const browser = detect();

const SEARCH_HISTORY_API = `${env.USER_API_HOST}/user/api/userCenter/searchHistory`;

export async function getSearchHistory() {
    return await API.getArray(SearchHistoryDto, SEARCH_HISTORY_API);
}

export async function countUserDailySearch(queryData) {
    return await API.post(null, MAP_APIS_ENDPOINTS.COUNT_DAILY_SEARCH, queryData);
}

export async function saveSearchHistory(po: SearchHistoryPo) {
    return await API.post(null, SEARCH_HISTORY_API, po);
}
///////////////////////////////////////////////
const FAVOURITE_API = `${env.USER_API_HOST}/user/api/userCenter/favourite`;

export async function getFavourites(): Promise<FavouriteDto[]> {
    return await API.getArray(FavouriteDto, FAVOURITE_API);
}

export async function saveFavourite(po: FavouritePo): Promise<any> {
    return await API.post(null, FAVOURITE_API, po);
}

export async function updateFavourite(po: FavouriteUpdatePo): Promise<any> {
    return await API.put(null, FAVOURITE_API, po);
}

export async function removeFavourite(id: string): Promise<any> {
    return await API.delete(null, FAVOURITE_API, { id });
}

export async function getFavouriteDtoForProperty(propId: string): Promise<FavouriteDto> {
    const ret: any = await API.get(null, `${FAVOURITE_API}/property`, { propId });

    return ret;
}
///////////////////////////////////////////////
const KV_ENTITY_API = `${env.USER_API_HOST}/user/api/userCenter/kvEntity`;

export async function getKVEntity(entityType: number, entityId: string): Promise<KVEntityDto> {
    return await API.get(KVEntityDto, `${KV_ENTITY_API}/${entityType}/${entityId}`);
}

export async function saveKVEntity(entityType: number, entityId: string, content: string): Promise<KVEntityDto> {
    return await API.post(KVEntityDto, `${KV_ENTITY_API}/${entityType}/${entityId}`, content, {
        'Content-Type': 'text/plain',
    });
}

///////////////////////////////////////////////
const PROPERTY_REPORT_API = `${env.USER_API_HOST}/user/api/userCenter/propertyReport`;
export async function checkReport(propId: string): Promise<boolean> {
    const ret = (await API.get(null, `${PROPERTY_REPORT_API}/${propId}`)) as any;
    return ret?.data ?? false;
}

export async function consumeReport(propId: string): Promise<boolean> {
    const ret = (await API.post(null, `${PROPERTY_REPORT_API}/${propId}`)) as any;
    return ret?.data ?? false;
}

export async function getPropertyReports(skip: number, take: number): Promise<APIResponse<PropertyReportDto>> {
    const ret = await API.get(null, `${PROPERTY_REPORT_API}`);
    return APIResponse.convert(PropertyReportDto, ret);
}

///////////////////////////////////////////////
const SUBURB_SEARCH_API = `${env.USER_API_HOST}/user/api/userCenter/suburbSearch`;
export async function getAvailableCountOfSuburbSearch(): Promise<number> {
    const ret = (await API.get(null, `${SUBURB_SEARCH_API}/count`)) as any;
    return ret.data ?? 0;
}

export async function checkQuota(suburbId: string): Promise<boolean> {
    const ret = (await API.post(null, `${SUBURB_SEARCH_API}/${suburbId}`)) as any;
    return ret?.data ?? false;
}

///////////////////////////////////////////////
const USER_LOG_API = `${env.USER_API_HOST}/user/api/userCenter/userLog`;
const USER_LOG_INTERVAL_IN_SECONDS = 60;
const USER_LOG_STATE = {
    isLoading: false,
    lastTime: null,
};

export function saveUserAccessLog(activity: string, userID, addtionInfo?) {
    const browserName = browser.name;
    const browserVersion = browser.version;
    const os = browser.os;
    console.log(`name : ${browserName}, version : ${browserVersion}, os : ${os}`);
    API.post(null, MAP_APIS_ENDPOINTS.LOG_USER_ACTIVITY, {
        activity,
        addtionInfo,
        userID,
        deviceInfo: { browserName, browserVersion, os },
    });
}

export function whichDayForTrial(createDate) {
    const createMoment = moment(createDate).valueOf();
    const nowMoment = moment().valueOf();
    if (nowMoment - createMoment <= 24 * 60 * 60 * 1000) {
        return 1;
    }
    if (nowMoment - createMoment > 24 * 60 * 60 * 1000 && nowMoment - createMoment <= 2 * 24 * 60 * 60 * 1000) {
        return 2;
    }
    if (nowMoment - createMoment > 2 * 24 * 60 * 60 * 1000 && nowMoment - createMoment <= 3 * 24 * 60 * 60 * 1000) {
        return 3;
    }
    return -1;
}

export async function recordUserLog(): Promise<boolean> {
    if (USER_LOG_STATE.isLoading) {
        return;
    }
    let diff = USER_LOG_INTERVAL_IN_SECONDS;
    if (USER_LOG_STATE.lastTime != null) {
        diff = differenceInSeconds(new Date(), USER_LOG_STATE.lastTime);
    }
    if (diff < USER_LOG_INTERVAL_IN_SECONDS) {
        return;
    }

    USER_LOG_STATE.isLoading = true;
    let ret;
    try {
        const data = (await API.put(null, USER_LOG_API, null)) as { data: boolean };
        ret = data.data ?? false;
    } catch (e) {
        ret = false;
    }

    if (ret) {
        USER_LOG_STATE.lastTime = new Date();
    }

    USER_LOG_STATE.isLoading = false;
    return ret;
}

export async function saveUserDetails(userDetails): Promise<any> {
    const saveResult: any = await API.post(null, MAP_APIS_ENDPOINTS.SAVE_USER_DETAILS, userDetails);
    return saveResult;
}

export async function requestPhoneVerifyCode(phone): Promise<boolean> {
    const endPoint = setEndpointParams(MAP_APIS_ENDPOINTS.REQUEST_PHONE_VERIFYCODE, { phone });
    const requestResult: boolean = await API.get(null, endPoint);
    return requestResult;
}
export async function becomeAdvanceMember(memberType): Promise<any> {
    const requestResult: any = await API.post(null, MAP_APIS_ENDPOINTS.CONFIRMASMEMBER, { memberType }, {});
    return requestResult;
}

export async function changeMemberType(memberType): Promise<any> {
    const requestResult: any = await API.post(null, MAP_APIS_ENDPOINTS.CHANGE_MEMBER_SUB_TYPE, { memberType }, {});
    return requestResult;
}
export async function cancelAdvanceMember(): Promise<any> {
    const requestResult: any = await API.post(null, MAP_APIS_ENDPOINTS.CANCELMEMBER, {}, {});
    return requestResult;
}

export async function requestVerifyEmail(language): Promise<any> {
    const requestResult: any = await API.post(null, MAP_APIS_ENDPOINTS.RESEND_VERIFY_EMAIL, { language });
    return requestResult;
}

export async function exceedDailyLimit(propId): Promise<boolean> {
    console.log('checking exceeed');
    const requestResult: any = await countUserDailySearch({ queryingId: propId });
    console.log('request result is ');

    console.log(requestResult);
    return requestResult;
}

export async function updateAreaMemberCareSuburbs(careSuburbs): Promise<boolean> {
    console.log('updating care suburbs ');
    const requestResult: any = await API.post(null, MAP_APIS_ENDPOINTS.UPDATE_AREA_MEMBER_CARE_SUBURBS, {
        selectedSuburbs: careSuburbs,
    });
    return requestResult;
}

export async function getUserSubInfo(userId): Promise<any> {
    const paymentIntentURL = env.apiHost + `/payment/subscription?userId=${userId}`;
    const subscriptionResult: any = await API.get(null, paymentIntentURL, {});

    return subscriptionResult;
}

export async function extractMemberInfo(userId): Promise<any> {
    const subResult = await getUserSubInfo(userId);
    if (subResult) {
        const subInfo = subResult[0];
        console.log(subInfo);
        const memberInfo: any = {};
        memberInfo.created = subInfo?.subscription?.created;
        memberInfo.paymentMethod = {
            last4: subInfo?.subscription?.default_payment_method?.card?.last4,
            expYear: subInfo?.subscription?.default_payment_method?.card?.exp_year,
            expMonth: subInfo?.subscription?.default_payment_method?.card?.exp_month,
        };
        // memberInfo.plan = subInfo?.

        return memberInfo;
    }
    return {};
}

import { Divider, Theme } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { makeStyles, withTheme } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { isMobileDevice } from '../../../commons/utils';
import { OrContentData } from '../components/OrContentData';
import { getIncomeDesc } from '../../PriceMap/support/common';
import { TR } from '../../../commons/i18n/TR';

const useStyles = makeStyles({
    root: {
        margin: '0 auto',
    },
    // wrapper: {
    //     display: 'flex',
    //     displayDirection: 'column',
    // },
    part1: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // alignItems: 'center',
        padding: '5px 0',
        '& span': {
            fontSize: '14px',
        },
    },

    part1Row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px',
    },
    part1RowContent: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        alignItems: 'center',
    },
    part2: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 0px',
    },
    legendGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    legend: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 2,
    },
    legendItem: {
        width: '10px',
        height: '10px',
        borderRadius: '5px',
    },

    part3: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '5px 0',
        '& span': {
            fontSize: '14px',
        },
    },
    part3Content: {
        display: 'flex',
        flexFlow: 'row wrap',
    },
    part3ContentItem: {
        padding: '3px 23px 3px 0px',
    },
});

declare var Highcharts;

const chartColor = {
    noChildCouple: 'rgb(15 134 243)',
    childCouple: 'rgb(255 152 47)',
    childSingle: 'rgb(32 204 104)',
    others: 'rgb(108 226 177)',
};
export const Card14 = rst.create<{ localityData: any; mobile?: boolean }>((ctx) => {
    const sampleData = {
        noChildCouple: ctx.props.localityData.familyWithChildren * 100,
        childCouple: ctx.props.localityData.familyWithoutChildren * 100,
        childSingle: 20,
        others: ctx.props.localityData.familyOther * 100,
    };
    function loadChart() {
        Highcharts.chart('card14', {
            chart: {
                height: 180,
                type: 'bar',
                marginBottom: isMobileDevice() ? 30 : 35,
            },
            title: {
                text: '',
            },

            subtitle: {
                text: '',
            },

            yAxis: {
                title: {
                    text: '',
                },
                labels: {
                    step: 1,
                    formatter: function () {
                        const _this: any = this;
                        return _this.value + '%';
                    },
                },
                min: 0,
            },
            xAxis: {
                title: {
                    text: null,
                },
                type: 'category',
                categories: [''],
                // crosshair: true,
            },
            legend: {
                enabled: false,
            },
            borderRadiusTopLeft: 10,
            plotOptions: {
                bar: {
                    borderWidth: 0,
                    borderRadius: 10,
                    dataLabels: {
                        enabled: true,
                        inside: true,
                        color: 'white',
                        format: '{point.y}%',
                    },
                },
                series: {
                    groupPadding: 0.08,
                },
            },
            tooltip: {
                enabled: false,
            },
            credits: { enabled: false },
            series: [
                {
                    name: 'childCouple',
                    data: [parseFloat(sampleData.noChildCouple.toFixed(2))],
                    color: 'rgb(255 152 47)',
                },
                {
                    name: 'noChildCouple',
                    data: [parseFloat(sampleData.childCouple.toFixed(2))],
                    color: 'rgb(15 134 243)',
                },
                // {
                //     name: 'childSingle',
                //     data: [sampleData.childSingle],
                //     color: 'rgb(32 204 104)',
                // },
                {
                    name: 'others',
                    data: [parseFloat(sampleData.others.toFixed(2))],
                    color: 'rgb(108 226 177)',
                },
            ],
        });
    }
    return (props) => {
        const classes = useStyles();
        setTimeout(() => {
            loadChart();
        }, 400);
        const topOccupations = props.localityData.topOccupations;
        console.log(topOccupations);
        const occArr = [];
        Object.keys(topOccupations).forEach((key) => {
            const arr = {};
            arr[key] = topOccupations[key];
            occArr.push(topOccupations[key]);
        });
        console.log(occArr);
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader header={props.mobile ? '' : 'Card14_header'}>
                    <div className={classes.part1}>
                        <div className={classes.part1Row}>
                            <div className={classes.part1RowContent}>
                                <ContentTitle hasArrow={false} header="Card14_income" />
                                <ContentData content={<TR name={getIncomeDesc(props.localityData.familyIncome)} />} />
                            </div>
                            <div className={classes.part1RowContent}>
                                <ContentTitle hasArrow={false} header="Card14_rent" />
                                <ContentData content={(props.localityData.rentalPct * 100).toFixed(1) + '%'} />
                            </div>
                            <div className={classes.part1RowContent}>
                                <ContentTitle hasArrow={false} header="Card14_age" />
                                <ContentData content={props.localityData.medianAge} />
                            </div>
                        </div>
                        <div className={classes.part1Row}>
                            <div className={classes.part1RowContent}>
                                <ContentTitle hasArrow={false} header="Card14_chinese" />
                                <ContentData content={(props.localityData.chinese * 100).toFixed(1) + '%'} />
                            </div>
                            <div className={classes.part1RowContent}>
                                <ContentTitle hasArrow={false} header="Card14_hinduism" />
                                <ContentData content={(props.localityData.hinduismPPpct * 100).toFixed(1) + '%'} />
                            </div>
                            <div className={classes.part1RowContent}>
                                <ContentTitle hasArrow={false} header="Card14_islam" />
                                <ContentData content={(props.localityData.islamPPct * 100).toFixed(1) + '%'} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.part2}>
                        <div style={{ fontSize: '14px' }}>
                            <ContentTitle hasArrow={false} header="Card14_family" />
                        </div>
                        <div className={classes.legendGroup}>
                            <div className={classes.legend}>
                                <div
                                    className={classes.legendItem}
                                    style={{ background: chartColor.childCouple }}></div>
                                <div style={{ marginLeft: 3 }}>
                                    <span>
                                        <TR name={'Card14_childCouple'} />
                                    </span>
                                </div>
                            </div>
                            <div className={classes.legend}>
                                <div
                                    className={classes.legendItem}
                                    style={{ background: chartColor.noChildCouple }}></div>
                                <div style={{ marginLeft: 3 }}>
                                    <span>
                                        <TR name={'Card14_noChildCouple'} />
                                    </span>
                                </div>
                            </div>
                            {/* <div className={classes.legend}>
                                <div
                                    className={classes.legendItem}
                                    style={{ background: chartColor.childSingle }}></div>
                                <div style={{ marginLeft: 3 }}>
                                    <span>
                                        <TR name={'Card14_childSingle'} />
                                    </span>
                                </div>
                            </div> */}
                            <div className={classes.legend}>
                                <div className={classes.legendItem} style={{ background: chartColor.others }}></div>
                                <div style={{ marginLeft: 3 }}>
                                    <span>
                                        <TR name={'Card14_others'} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="card14"></div>
                    <div className={classes.part3}>
                        <ContentTitle hasArrow={false} header="Card14_occupation" />
                        <div className={classes.part3Content}>
                            {occArr.map((occupation) => {
                                return (
                                    <div className={classes.part3ContentItem}>
                                        <OrContentData content={<TR name={occupation.name} />} />
                                        {/* <OrContentData content={occupation.name} /> */}
                                    </div>
                                );
                            })}
                            {/* {Object.keys(topOccupations).forEach((key) => {
                                return (
                                    <div className={classes.part3ContentItem}>
                                        <OrContentData content={topOccupations[key].name} />
                                    </div>
                                );
                            })} */}
                        </div>
                    </div>
                </CardWithHeader>
            </div>
        );
    };
});

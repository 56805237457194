import localForage from 'localforage';

export const storage = {
    save: async (key: string, value: any) => {
        return await localForage.setItem(key, value);
    },
    get: async (key: string) => {
        return await localForage.getItem(key);
    },
    remove: async (key: string) => {
        return await localForage.removeItem(key);
    },
};

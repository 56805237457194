import classes from '*.module.css';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { API, generateGUID, TR, TRHtml } from '../../../../commons';
import { MAP_APIS_ENDPOINTS } from '../../../../services/EndPoints';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import {
    decimalToPct,
    getDatePart,
    getStreetImageForProp,
    isMobileDevice,
    numToMillion,
    priceToK,
} from '../../../../commons/utils';

import { MapButton } from '../../components/MapButton';

import { HouseBasicInfo } from './HouseBasicInfo';
import { UnitInfoMobilePanel, UnitInfoPanel } from './UnitBasicInfo';

import { swtchToStreetView } from '../../support/propertyFeatureHelper';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { domReady } from '../../../mobile/supportUtils/htmlUtils';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            paddingTop: isMobileDevice() ? 7 : 20,
            paddingLeft: isMobileDevice() ? 6 : 13,
            paddingBottom: isMobileDevice() ? 10 : 20,
            paddingRight: isMobileDevice() ? 6 : 20,
        },

        streetView: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        clickHandle: {
            position: 'absolute',
            bottom: '20px',
            color: 'white',
            zIndex: 100,
            cursor: 'pointer',
        },

        streetViewImg: {},
    };
});
/*
showFor : map or infoPanel
*/
export const PropertyInfo = rst.create<{ showFor: string; prop: any }>((ctx) => {
    console.log(ctx.props.prop);
    return (props) => {
        const classes = useStyles();
        const { showFor, prop } = props;

        const getContentByPropType = () => {
            if (prop.propType == 'House') {
                return <HouseBasicInfo prop={prop} showFor={showFor}></HouseBasicInfo>;
            } else {
                return isMobileDevice() && showFor != 'infoPanel' ? (
                    <UnitInfoMobilePanel prop={prop}></UnitInfoMobilePanel>
                ) : (
                    <UnitInfoPanel prop={prop}></UnitInfoPanel>
                );
            }
        };
        const getContent = () => {
            setTimeout(() => {
                const favIcon = document.getElementById('favoriteProp');
                if (favIcon) {
                    favIcon.addEventListener('click', () => {
                        alert('favorite house');
                    });
                }
            }, 500);
            if (props.prop) {
                return (
                    <div className={classes.root} style={{ padding: showFor == 'infoPanel' ? '0' : '' }}>
                        <div style={{ display: 'flex' }}>
                            <StreetView
                                lat={props.prop.lat}
                                lng={props.prop.lon}
                                w={!isMobileDevice() || props.showFor == 'infoPanel' ? 135 : 50}
                                h={
                                    !isMobileDevice() || props.showFor == 'infoPanel'
                                        ? props.showFor == 'infoPanel'
                                            ? 100
                                            : 124
                                        : 50
                                }
                                showFor={showFor}
                                address={prop.address}></StreetView>
                            {getContentByPropType()}
                        </div>
                        {showFor == 'map' ? (
                            <>
                                <div
                                    style={{
                                        marginTop: isMobileDevice() ? 4 : 21,
                                        display: 'flex',
                                    }}>
                                    <div>
                                        <img src="assets/icons/star.svg" id="favoriteProp" width="18px"></img>
                                        <img src="assets/icons/share.svg" width="18px"></img>
                                    </div>
                                    <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                                        <MapButton
                                            id="showPropertyReport"
                                            bg="#F89E62"
                                            w={isMobileDevice() ? 70 : 195}
                                            label={'Show Report'}></MapButton>
                                    </div>

                                    {/* <MapButton w={isMobileDevice() ? 100 : 195} label={'Show Suburb trans'}></MapButton> */}
                                </div>
                            </>
                        ) : null}
                    </div>
                );
            } else {
                return (
                    <>
                        <TR name="EMPTY_PROP_MESSAGE"></TR>
                    </>
                );
            }
        };
        return <>{getContent()}</>;
    };
});

export const StreetView = rst.create<{
    address: string;
    w: number;
    h: number;
    showFor: string;
    lat;
    lng;
    onMap?;
    imageBorderRadius?;
}>((ctx) => {
    const componentID = generateGUID();
    const openStreetView = (e) => {
        swtchToStreetView(ctx.props.lat, ctx.props.lng);
    };

    const showEnlargeImg = () => {
        EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_ENLARGE_STREETVIEW, { show: true, address: ctx.props.address });
    };

    const hideEnlargeImg = () => {
        EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_ENLARGE_STREETVIEW, { show: false });
    };

    const zoomInImage = () => {
        EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_ENLARGE_STREETVIEW, { zoomIn: true });
        return false;
    };

    const zoomOutImage = () => {
        EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_ENLARGE_STREETVIEW, { zoomOut: true });
        return false;
    };

    domReady('streetViewDiv_' + componentID).then((domRef: any) => {
        domRef.addEventListener('click', () => {
            swtchToStreetView(ctx.props.lat, ctx.props.lng);
        });
    });

    return (props) => {
        const classes = useStyles();
        return (
            <div onMouseOver={showEnlargeImg} onMouseLeave={hideEnlargeImg} className={classes.streetView}>
                <div
                    style={{
                        position: 'absolute',
                        display: props.onMap || isMobileDevice() ? 'none' : 'block',
                        left: 0,
                        top: 0,
                    }}>
                    <div onClick={zoomOutImage}>
                        <ExpandLessIcon></ExpandLessIcon>
                    </div>
                    <div onClick={zoomInImage}>
                        <ExpandMoreIcon></ExpandMoreIcon>
                    </div>
                </div>
                <img
                    id={'streetViewDiv_' + componentID}
                    width={props.w}
                    onClick={openStreetView}
                    height={props.h}
                    style={{ borderRadius: props.imageBorderRadius || 10 }}
                    className={classes.streetViewImg}
                    src={getStreetImageForProp(props.address)}
                />
                {isMobileDevice() && props.showFor == 'map' ? null : (
                    <div onClick={openStreetView} className={classes.clickHandle}>
                        <TR name="VIEW_STREEVIEW" />
                    </div>
                )}
            </div>
        );
    };
});

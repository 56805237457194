import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../../commons';
import { PopupComponent, PopupComponentGray } from '../../../PriceMap/components/popupComponent';

export const MobileDescCard = rst.create<{ label?: string }>((ctx) => {
    return (props) => {
        return (
            <div style={{ padding: '1.875rem 1rem 1.4rem', background: '#f7f7f7' }}>
                <h3 style={{ margin: 0, fontSize: '1.375rem' }}>
                    <TR name="mobile_DescCardTitle"></TR>
                </h3>
                <div style={{ fontSize: '0.8125rem', lineHeight: '1.125rem' }}>
                    <p style={{ color: '#888' }}>
                        <TR name="mobile_DescCard_1"></TR>
                    </p>
                    <p style={{ color: '#888' }}>
                        <TR name="mobile_DescCard_2"></TR>
                        <span>
                            <PopupComponentGray
                                link="mobile_DescCard_3"
                                content="mobile_DescCard_3_help"
                                maxWidth="xl"
                            />
                        </span>
                        <TR name="mobile_DescCard_4"></TR>
                    </p>
                    <p style={{ color: '#888' }}>
                        <TR name="mobile_DescCard_5"></TR>
                    </p>
                </div>
            </div>
        );
    };
});

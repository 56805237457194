import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { rst } from 'rt-state';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { TR } from '../../../commons';
import { NewButton } from './NewButton';
import { isMobileDevice } from '../../../commons/utils';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';

export interface NewNewContentTextProps {
    onClick?: () => void;
    header?: string;
    subHeader?: string;
    hasBackText?: string;
    textContent?: string[];
    textLeft?: boolean;
    background?: string;
    hasButton?: boolean;
    video?: string;
}
const useStyles = makeStyles((theme) => ({
    root: (props: NewNewContentTextProps) => {
        const background = props.background;
        return {
            background,
            margin: '0px',
            padding: '0px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '428px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& h2': {
                fontSize: '26px',
                margin: '0px 0px 15px 0px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '16px',
                    padding: '0px 0px 0px 0px',
                    margin: '0px 0px 15px 0px',
                },
            },
            '& h3': {
                fontSize: '16px',
                margin: '0px 0px 20px 0px',
                fontWeight: 500,
                color: 'rgb(46 46 46)',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '12px',
                    lineHeight: '16px',
                    padding: '5px 0px 0px 0px',
                    margin: '0px 0px 10px 0px',
                },
            },
        };
    },
    divider: {
        borderTop: 'solid 4px rgb(206 206 206)',
        width: '40px',
        [theme.breakpoints.down('sm')]: {
            borderTop: 'solid 2px rgb(206 206 206)',
        },
    },
    hasBackText: {
        margin: '16px 0px 15px 0px',
        padding: '7px 0px',
        fontSize: '18px',
        fontWeight: 500,
        color: '#009E90',
        lineHeight: '26px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
            lineHeight: '18px',
            padding: '11px 7px',
            margin: '0px 0px 0px 0px',
        },
    },
    // hasBackText: (props: NewNewContentTextProps) => {
    //     const background =
    //         props.background === 'rgb(249 249 249)'
    //             ? 'linear-gradient(270deg, rgb(249 249 249), rgb(215 237 237))'
    //             : 'linear-gradient(270deg, rgb(255 255 255), rgb(215 237 237))';
    //     return {
    //         background,
    //         margin: '40px 0px 15px 0px',
    //         padding: '7px',
    //         fontSize: '18px',
    //         fontWeight: 500,
    //         color: '#009E90',
    //         lineHeight: '26px',
    //         [theme.breakpoints.down('sm')]: {
    //             fontSize: '13px',
    //             lineHeight: '18px',
    //             padding: '11px 7px',
    //             margin: '20px 0px 15px 0px',
    //         },
    //     };
    // },
    content: {
        marginBottom: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
            width: '100%',
        },
    },
    contentItem: {
        margin: '0px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '& p': {
            padding: '5px',
            margin: '0px',
            fontSize: '16px',
            lineHeight: '22px',
            width: '450px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px',
                lineHeight: '18px',
                padding: '0px 0px 8px 8px',
                width: '100%',
            },
        },
    },
    contentGreen: {
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        background: 'rgb(189 226 223)',
        marginTop: '13px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '5px',
        },
    },
}));

export const NewNewContentText = rst.create<NewNewContentTextProps>((ctx) => {
    const openVideo = (videoNo) => {
        EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_VIDEO_PLAYER, videoNo);
    };
    return (props) => {
        const classes = useStyles(props);
        const { header, subHeader, hasBackText, textContent, onClick, textLeft, hasButton, video } = props;
        return (
            <div className={classes.root}>
                <h2>
                    <TR name={header} />
                </h2>
                {/* <h3>
                    <TR name={subHeader} />
                </h3>
                <div className={classes.divider} />
                <div className={classes.hasBackText}>
                    <TR name={hasBackText} />
                </div> */}
                <div className={classes.content}>
                    {textContent.map((content, idx) => {
                        return (
                            <div key={idx} className={classes.contentItem}>
                                <div className={classes.contentGreen}></div>
                                <p>
                                    <TR name={content} />
                                </p>
                            </div>
                        );
                    })}
                </div>
                {hasButton ? (
                    <NewButton
                        backgroundColor="#FF4600"
                        padding={isMobileDevice() ? '10px 35px' : '10px 58px'}
                        color="white"
                        label="NewHome_Video"
                        onClick={() => {
                            openVideo(video);
                        }}
                        icon={1}
                    />
                ) : null}
            </div>
        );
    };
});

import { Box, makeStyles, Slider } from '@material-ui/core';
import React from 'react';
import { rst, state } from 'rt-state';
import { TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';

const useStyles = makeStyles((theme) => {
    return {
        errorDiv: {
            background: 'white',
            borderRadius: 8,
            position: 'relative',
        },
        titleDiv: {
            width: 253,
            height: 94,
        },
        closeIcon: {
            position: 'absolute',
            right: 5,
            top: 7,
            zIndex: 100,
        },
        title: {
            position: 'absolute',
            zIndex: 100,
            top: 22,
            left: 0,
            color: 'white',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mainTitle: {
            fontSize: 18,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        subTitle: {
            fontSize: 15,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        body: {
            padding: '20px 14px 33px 17px',
        },
    };
});
export const EmptySelectError = rst.create<{ title: string; subTitle: string }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const closeDialog = () => {
            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_ERROR_MESSAGE, {
                show: false,
            });
        };
        return (
            <div className={classes.errorDiv}>
                <div className={classes.closeIcon} onClick={closeDialog}>
                    <img src="assets/icons/close-icn@1x.png"></img>
                </div>
                <div className={classes.titleDiv}>
                    <img style={{ filter: 'blur(1px)' }} src="assets/icons/error_bg_1x.png"></img>
                    <div className={classes.title}>
                        <div className={classes.mainTitle}>
                            <TR name={props.title}></TR>
                        </div>
                        <div className={classes.subTitle}>
                            <TR name={props.subTitle}></TR>
                        </div>
                    </div>
                </div>

                <div className={classes.body}>
                    <img src="assets/icons/Map@1x.png"></img>
                </div>
            </div>
        );
    };
});

import { API, generateGUID, storage } from '../../../../commons';
import { MAP_APIS_ENDPOINTS } from '../../../../services/EndPoints';
import { getItemFromArray, getLatlngArrayFromEncodedPath } from '../../../../commons/utils';
import { LOCALSTORAGE_KEYS, MAP_LAYER_TYPES } from './MapConsts';

import { MapMarker } from './MapMarker';

const win: any = window;
const L = win.L;

interface MapData {
    type: string; // 1 -- suburb, 2. - school , 3. - POI point
    id: string;
    name: string;
    data: any; // save the encoded path for path
}

export class MapController {
    mapData: MapData[] = [];

    map: any;

    mapBounds: any;
    mapLayerRepo: any = []; // save all elements on the map, including marker, polygon, polyline etc.
    // format : {suburbId : }
    constructor(map: any) {
        this.map = map;
        this.setMapEvents();
    }

    async initController() {
        const ls_mapBorders: any = await storage.get(LOCALSTORAGE_KEYS.KEY_MAPBORDERS);
        this.mapData = ls_mapBorders || [];
        this.updateMap();
    }

    setMapEvents() {
        this.map.on('zoomend', () => {});

        this.map.on('moveend', () => {
            this.mapBounds = this.map.getBounds();
            this.updateMap();
        });
    }

    /**
     * 1. get suburb ids which are in map view
     * 2. get suburb borders which are in map view and not in local storage
     */
    getSuburbsInMapView() {
        return new Promise((resolve, reject) => {
            if (!this.mapBounds) {
                this.mapBounds = this.map.getBounds();
            }
            const postData = {
                topRight: { lat: this.mapBounds._northEast.lat, lon: this.mapBounds._northEast.lng },
                bottomLeft: { lat: this.mapBounds._southWest.lat, lon: this.mapBounds._southWest.lng },
            };

            API.post(null, MAP_APIS_ENDPOINTS.GET_SUBURBIDS_IN_MAPVIEW, postData, {}).then(
                async (result) => {
                    await this.getSurburbsBorder(result);
                    resolve({ done: true });
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }

    /**
     *
     * @param get all icons from map view port and put them into mapData
     */
    async getMapViewSpecialIcons(iconType) {
        let retrieveIcons: any = await API.post(null, MAP_APIS_ENDPOINTS.GET_MARKERS, { iconType: iconType }, {});
        retrieveIcons = [
            {
                id: '1',
                lat: 0,
                lng: 0,
            },
        ];
        const filteredMarkers = this.mapData.filter((loopItem) => {
            return loopItem.type == '3';
        });

        //check is it in the mapData, if it is not in, then generate marker class and save it to map data
        retrieveIcons.forEach((loopIcon) => {
            let iconInMapData = false;
            for (let i = 0; i < filteredMarkers.length; i++) {
                if (filteredMarkers[i].id == loopIcon.id) {
                    iconInMapData = true;
                    break;
                }
            }

            if (!iconInMapData) {
                this.mapData.push({
                    id: retrieveIcons.id,
                    type: '3',
                    name: '',
                    data: new MapMarker(iconType, { lat: loopIcon.lat, lng: loopIcon.lng }),
                });
            }
        });
    }

    /***
     *get suburb border data and save it to repo
     */
    getSurburbsBorder(suburbIds) {
        return new Promise(async (resolve, reject) => {
            // first check the suburb ids already retrieved or not, then check local storage
            const requestIds = [];
            let suburbMapData = this.mapData.filter((loopItem) => {
                return loopItem.type == '1';
            });

            suburbIds.forEach((loopId) => {
                if (getItemFromArray(suburbMapData, 'id', loopId) == null) {
                    requestIds.push(loopId);
                }
            });

            API.post(null, MAP_APIS_ENDPOINTS.GET_MAPBORDERS, requestIds, {}).then(
                (result: any) => {
                    if (result && result.length > 0) {
                        result.forEach((loopSuburb) => {
                            console.log(loopSuburb);
                            const suburbMapDataItem = {
                                type: '1',
                                id: loopSuburb.id,
                                name: '',
                                data: loopSuburb.polygon_encoded,
                            };
                            this.mapData.push(suburbMapDataItem);
                        });
                    }

                    //put it back to local storage
                    suburbMapData = this.mapData.filter((loopItem) => {
                        return loopItem.type == '1';
                    });
                    storage.save(LOCALSTORAGE_KEYS.KEY_MAPBORDERS, suburbMapData);
                    resolve({ done: true });
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }

    async updateMap() {
        await this.getSuburbsInMapView();

        this.drawSuburbPolygons();
    }

    drawSuburbPolygons() {
        const suburbLayers = this.mapLayerRepo.filter((loopLayer) => {
            return loopLayer.type == MAP_LAYER_TYPES.POLYGON;
        });
        const suburbsData = this.mapData.filter((loopData) => {
            return loopData.type == '1';
        });

        if (suburbsData && suburbsData.length > 0) {
            suburbsData.forEach((loopData) => {
                // if not draw this layer, then initilize and draw it
                if (getItemFromArray(suburbLayers, 'id', loopData.id) == null) {
                    loopData.data.forEach((loopPolygonData: any) => {
                        const polygonPath = getLatlngArrayFromEncodedPath(loopPolygonData);

                        const suburbPolygon = L.polygon(polygonPath, {
                            fillColor: '#ff0000',
                            //color: suburbColor,
                            color: 'white',
                            stoke: true,
                            weight: 2,
                            opacity: 0.3,
                            fillOpacity: 0.2,
                        });

                        suburbPolygon.addTo(this.map);

                        this.mapLayerRepo.push({
                            type: MAP_LAYER_TYPES.POLYGON,
                            uuid: generateGUID(),
                            id: loopData.id,
                            layerObj: suburbPolygon,
                        });
                    });
                }
            });
        }
    }
}

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
        },
        mapContainer: {
            flexGrow: 1,
            display: 'flex',
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
        },
        drawerContent: {
            minWidth: 600,
            // width: '40%',
            // overflow: 'hidden',
        },
        table: {
            height: '100%',
        },
    };
});

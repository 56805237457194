import { Button, CircularProgress, makeStyles, Tooltip } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { addDate, API, clsx, formatDate, generateGUID, getDaysBetweenTwoDate, TR, TRHtml } from '../../../commons';
import { MAP_APIS_ENDPOINTS, setEndpointParams } from '../../../services/EndPoints';
import { useRunOnce } from '../../../commons/hooks';
import {
    convertToM,
    decimalToPct,
    getItemFromArray,
    numToMillion,
    removeItemFromArray,
    reverseToD,
    sortDateArray,
    sortNumArray,
} from '../../../commons/utils';
import ReactDOM from 'react-dom/server';
import classes from '*.module.css';
import { PeriodChooseItem } from '../Layers/PropertyPeriodChooser';
import { isLastDayOfMonth } from 'date-fns';
import { PriceLegend } from '../components/PriceLegend';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';

import { getTypeByFeature } from '../support/propertyFeatureHelper';
import { MapButton } from '../components/MapButton';
import { ToggleButton } from '@material-ui/lab';
import { calculateMedianPrice } from '../support/common';
import { MapGlobalVars } from '../support/PriceMapConsts';
import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
    return {
        nearTranLayer: {
            marginTop: 20,
        },
        root: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            width: '100%',
            height: 120,
            position: 'relative',
        },
        title: {
            fontSize: 16,
            color: '#333333',
            fontWeight: 500,
        },
        loading: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'black',
            opacity: 0.8,
            zIndex: 2,
        },
        axisFrame: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
        },
        dataLayer: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            zIndex: 2,

            // verticalAlign: 'bottom',
        },
        axis: {
            stroke: 'rgb(49, 170, 255)',
            strokeWidth: 1,
        },
        lineArrow: {
            fill: 'rgb(49, 170, 255)',
            strokeWidth: 1,
        },
        bedOptionIcon: {
            width: 9,
            height: 9,
            borderRadius: '50%',
        },
        scaleLine: { stroke: 'rgb(230, 230, 230)', strokeWidth: 1 },
        priceRangeRectDiv: {
            position: 'absolute',
            display: 'flex',
            width: '100%',
            zIndex: 10,
        },
        priceRangeRect: {
            flexGrow: 1,
            height: 50,
            background: '#FF3000',
            opacity: 0.08,
        },
        priceRangeBorderLine: {
            borderTop: '2px #FF3000 solid',
            flexGrow: 1,
        },
        refLineDiv: {
            position: 'absolute',
            left: 0,
            display: 'flex',
            width: '100%',
            fontSize: 11,
        },
        refDateDiv: {
            position: 'absolute',
            left: 0,
            width: '100%',
            bottom: '0',
        },
        propertyDotsDiv: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '95%',
            zIndex: 9,
        },
        refLine: {
            flexGrow: 1,
            borderTop: '1px rgb(230, 230, 230) solid',
        },
        bedOption: {
            cursor: 'pointer',

            display: 'flex',
            alignItems: 'center',
        },
        selectedBedOption: {
            border: '1px solid red',
        },
    };
});

var nearTransList;

var chartWidth;
var totalDays;
var soldContractDate = [];
const x_start = 40;
const y_start = '95%';
var soldPriceList = [];

export const NearByTranscationChart = rst.create<{
    aroundTrans: any;
    property: any;
    changeIntervalEvt: any;
    chartChangeEvt?: any;
    mobileVersion?: boolean;
}>((ctx) => {
    const { aroundTrans } = ctx.props;

    const pageState = rst.state({
        showAroundPoints: MapGlobalVars.showAroundTransCircle,
        transcations: ctx.props.aroundTrans,
        choosedInterval: 12,
        estimateValue: null,
    });

    EventBus.on(EVENT_NAMES.EVENT_ESTIMAGE_VALUE_CHANGE, (estimateValue) => {
        pageState.estimateValue = estimateValue;
        console.log('ESTIMATE value is ' + pageState.estimateValue);
    });

    function filterProeprtyByBeds(choosedBeds) {
        console.log(choosedBeds);
    }

    return (props) => {
        const { property } = props;

        console.log(props);
        const propType = getTypeByFeature(property.allFeatures);

        const getTransByInterval = (interval) => {
            props.changeIntervalEvt(interval);
            pageState.choosedInterval = interval;

            EventBus.dispatch(EVENT_NAMES.EVENT_SYNC_INTERVAL_BETWEEN_2_CHARTS, interval);
        };
        const switchAroundPointsOnMap = () => {
            pageState.showAroundPoints = !pageState.showAroundPoints;
            EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, {
                property: props.property,
                aroundTrans: props.aroundTrans,
                propType: propType,
                showAroundTrans: pageState.showAroundPoints,
            });
            MapGlobalVars.showAroundTransCircle = pageState.showAroundPoints;
        };

        const dotClickEvt = (dot_propId) => {
            if (!MapGlobalVars.showAroundTransCircle) {
                switchAroundPointsOnMap();
            }
            setTimeout(() => {
                EventBus.dispatch(EVENT_NAMES.EVENT_NEAR_BY_TRANS_AXIS_POINT_CLICKED, { propId: dot_propId });
            }, 200);
        };
        console.log(props.aroundTrans);

        const classes = useStyles();
        return (
            <div className={classes.nearTranLayer}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={classes.title}>
                        <TR name="NEAR_TRANS_TITLE"></TR>
                        <span style={{ fontSize: '10px', color: '#999999' }}>
                            {' '}
                            (<TR name="AROUND_500m"></TR>)
                        </span>
                    </div>
                    {props.mobileVersion ? null : (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                            <Button
                                variant={'outlined'}
                                color="primary"
                                onClick={() => {
                                    props.chartChangeEvt(1);
                                }}
                                value="left">
                                <TR name="SWITCH_TO_TYPE_CHART"></TR>
                            </Button>

                            <div style={{ marginLeft: 5 }}></div>

                            <Button
                                variant={pageState.showAroundPoints ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={switchAroundPointsOnMap}
                                value="left">
                                <TR name="showOnMap"></TR>
                            </Button>
                        </div>
                    )}
                </div>

                <div style={{ display: 'flex', marginTop: 7 }}>
                    <BedChoose propType={propType} changeBedEvt={filterProeprtyByBeds}></BedChoose>
                    {props.mobileVersion ? null : (
                        <IntervalChoose
                            choosed={pageState.choosedInterval}
                            changeIntervalEvt={getTransByInterval}></IntervalChoose>
                    )}
                </div>

                <div style={{ marginTop: 10 }}></div>
                <div className={classes.root}>
                    <div className={classes.axisFrame}>
                        {' '}
                        <AxisFrame></AxisFrame>{' '}
                    </div>
                    <div className={classes.dataLayer} id="nearTransDataLayer">
                        {props.aroundTrans != null ? (
                            <TransDataLayer
                                dotClickEvt={dotClickEvt}
                                propType={propType}
                                estimateValue={pageState.estimateValue}
                                transList={props.aroundTrans}></TransDataLayer>
                        ) : null}

                        {/* <content from TransDataLayer ></content> */}
                    </div>
                </div>
            </div>
        );
    };
});
const TransDataLayer = rst.create<{ transList: any; estimateValue: any; propType; dotClickEvt }>((ctx) => {
    const pageState = rst.state({
        clickedPropDot_id: null,
    });

    EventBus.on(EVENT_NAMES.EVENT_PROP_POP_UP_CLOSED, () => {
        pageState.clickedPropDot_id = null;
    });

    function propDotClickEvt(dot_propId) {
        ctx.props.dotClickEvt(dot_propId);
        setTimeout(() => {
            pageState.clickedPropDot_id = dot_propId;
        }, 500);
    }
    return (props) => {
        console.log(props.transList);
        const nearDataLayer = document.getElementById('nearTransDataLayer');
        const chartWidth = 360;
        // if (nearDataLayer) {
        //     chartWidth = nearDataLayer.offsetWidth - x_start - 20;
        // }
        console.log('chart width is ' + chartWidth);

        const classes = useStyles();

        if (props.propType == 'House' && props.transList) {
            soldPriceList = props.transList.map((loopItem) => {
                return loopItem.sold_price;
            });
            soldContractDate = props.transList.map((loopItem) => {
                return loopItem.sold_contract_date;
            });
        } else {
            props.transList.map((loopProj) => {
                const projSoldPriceList = [];
                if (loopProj.properties) {
                    loopProj.properties.map((loopApt) => {
                        if (!soldPriceList) {
                            soldPriceList = [];
                        }
                        if (!soldContractDate) {
                            soldContractDate = [];
                        }
                        projSoldPriceList.push(loopApt.sold_price);

                        soldPriceList.push(loopApt.sold_price);
                        soldContractDate.push(loopApt.sold_contract_date);

                        loopProj.sold_contract_date = loopApt.sold_contract_date; // put any sold date as project date
                        loopProj.prop_id = loopApt.prop_id;
                    });
                }

                loopProj.sold_price = calculateMedianPrice(projSoldPriceList)?.median; // put median price as projet sold price
            });
        }

        soldPriceList = sortNumArray(soldPriceList);
        console.log(soldPriceList);

        let maxPrice = 0;
        if (props.propType == 'House') {
            maxPrice = Math.ceil(
                convertToM(
                    soldPriceList[soldPriceList.length - 1] > props.estimateValue?.upper
                        ? soldPriceList[soldPriceList.length - 1]
                        : props.estimateValue?.upper,
                ),
            );
        } else {
            maxPrice = Math.ceil(convertToM(soldPriceList[soldPriceList.length - 1]));
        }

        soldContractDate = sortDateArray(soldContractDate);
        console.log(soldContractDate);

        const drawRefLines = () => {
            const lines = [];
            const totalRefLines = 3;
            for (let i = 0; i < totalRefLines; i++) {
                const currentLinePrice = ((maxPrice / totalRefLines) * (i + 1)).toFixed(1);

                lines.push(<RefLine maxPrice={maxPrice} price={currentLinePrice}></RefLine>);
            }

            return lines;
        };

        const drawRefDates = () => {
            totalDays = getDaysBetweenTwoDate(soldContractDate[soldContractDate.length - 1], soldContractDate[0]);

            const dateScales = [];
            for (let i = -1; i < totalDays; i++) {
                const loopDate = addDate(soldContractDate[0], i, 'D');
                if (isLastDayOfMonth(loopDate)) {
                    dateScales.push(
                        <div style={{ position: 'absolute', left: ((i + 1) / totalDays) * chartWidth }}>
                            {formatDate(loopDate, 'dd/MM')}
                        </div>,
                    );
                }
            }

            return dateScales;
        };

        const drawProperty = () => {
            const propertiesDots = [];

            props.transList.forEach((loopProp) => {
                const prop_top = decimalToPct(((maxPrice - convertToM(loopProp.sold_price)) / maxPrice) * 0.95);

                const daysFromFirstTran = getDaysBetweenTwoDate(loopProp.sold_contract_date, soldContractDate[0]);
                const prop_left = (daysFromFirstTran / totalDays) * chartWidth;

                propertiesDots.push(
                    <PropertyDot
                        dotClickEvt={propDotClickEvt}
                        pos_left={prop_left}
                        pos_top={prop_top}
                        propType={props.propType}
                        clicked={loopProp.prop_id == pageState.clickedPropDot_id}
                        property={loopProp}></PropertyDot>,
                );
            });
            console.log(propertiesDots);
            return propertiesDots;
        };

        return (
            <>
                {drawRefLines()}
                {props.propType == 'House' ? (
                    <PriceRangeRect estimateValue={props.estimateValue} maxPrice={maxPrice}></PriceRangeRect>
                ) : null}

                <div
                    className={classes.refDateDiv}
                    style={{ left: x_start, width: chartWidth - x_start }}
                    id="dateRefDiv">
                    <div style={{ position: 'relative', width: '100%' }}>{drawRefDates()}</div>
                </div>
                <div className={classes.propertyDotsDiv} style={{ left: x_start, width: chartWidth - x_start }}>
                    {drawProperty()}
                </div>
            </>
        );
    };
});
const PropertyDot = rst.create<{
    pos_left: any;
    pos_top: any;
    property: any;
    dotClickEvt: any;
    clicked?: boolean;
    propType: string;
}>((ctx) => {
    return (props) => {
        return (
            <>
                <div
                    className={props.clicked ? 'animate__infinite animate__animated animate__heartBeat' : ''}
                    onClick={() => {
                        props.dotClickEvt(props.property.prop_id);
                    }}
                    id={props.property.prop_id}
                    style={{ position: 'absolute', left: props.pos_left, top: props.pos_top }}>
                    <PriceLegend
                        background={
                            getItemFromArray(bedsOption[props.propType], 'value', props.property.prop_bed || '-1')
                                ?.color
                        }></PriceLegend>
                </div>
            </>
        );
    };
});
const RefDate = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        return <div></div>;
    };
});
const RefLine = rst.create<{ price: any; maxPrice: any; cssClass?: string; notShowPrice?: boolean }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const linePos_top = decimalToPct(
            (parseFloat(props.maxPrice) - parseFloat(props.price)) / parseFloat(props.maxPrice),
        );
        console.log(linePos_top);
        return (
            <div className={classes.refLineDiv} style={{ top: linePos_top }}>
                <div style={{ width: x_start }}>{!props.notShowPrice ? props.price + 'm' : ''}</div>
                <div className={props.cssClass ? classes[props.cssClass] : classes.refLine}></div>
            </div>
        );
    };
});

const PriceRangeRect = rst.create<{ estimateValue; maxPrice }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const start_y = decimalToPct(
            (props.maxPrice * 1000000 - parseFloat(props.estimateValue?.upper)) / (props.maxPrice * 1000000),
        );
        console.log(start_y);
        const rectHeight: any =
            ((parseFloat(props.estimateValue?.upper) - parseFloat(props.estimateValue?.lower)) /
                (props.maxPrice * 1000000)) *
            120;

        return (
            <>
                <RefLine
                    price={convertToM(props.estimateValue?.upper)}
                    cssClass="priceRangeBorderLine"
                    notShowPrice={true}
                    maxPrice={props.maxPrice}></RefLine>
                <PriceRangeTooltip
                    arrow
                    title={
                        ReactDOM.renderToString(<TR name="priceRange"></TR>) +
                        '$' +
                        convertToM(props.estimateValue?.lower) +
                        '~' +
                        convertToM(props.estimateValue?.upper) +
                        'm'
                    }>
                    <div id="priceRangeRectDiv" className={classes.priceRangeRectDiv} style={{ top: start_y }}>
                        <div style={{ width: x_start }}></div>

                        <div className={classes.priceRangeRect} style={{ height: rectHeight }}></div>
                    </div>
                </PriceRangeTooltip>

                <RefLine
                    cssClass="priceRangeBorderLine"
                    notShowPrice={true}
                    price={convertToM(props.estimateValue?.lower)}
                    maxPrice={props.maxPrice}></RefLine>
            </>
        );
    };
});

export const IntervalChoose = rst.create<{ changeIntervalEvt: any; choosed: any }>((ctx) => {
    const choosedPeriod = rst.stateS(ctx.props.choosed);
    const periodGroupName = generateGUID();
    return (props) => {
        choosedPeriod.value = ctx.props.choosed;
        const changeInterval = (interval) => {
            choosedPeriod.value = interval;
            choosedPeriod.forceUpdate();

            props.changeIntervalEvt(interval);
        };
        console.log('choosed interval is ' + choosedPeriod.value);
        return (
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                <div
                    id={generateGUID() + '_interval_12'}
                    onClick={() => {
                        changeInterval(12);
                    }}>
                    <PeriodChooseItem
                        name={periodGroupName}
                        label="12Months"
                        selected={choosedPeriod.value == 12}
                        value={12}></PeriodChooseItem>
                </div>
                <div style={{ marginLeft: 10 }}></div>
                <div
                    id={generateGUID() + '_interval_6'}
                    onClick={() => {
                        changeInterval(6);
                    }}>
                    <PeriodChooseItem
                        name={periodGroupName}
                        label="6Months"
                        selected={choosedPeriod.value == 6}
                        value={6}></PeriodChooseItem>
                </div>
            </div>
        );
    };
});

const bedsOption = {
    House: [
        {
            id: '5Beds',
            label: '5Beds',
            value: 5,
            color: '#3E7AED',
        },
        {
            id: '4Beds',
            label: '4Beds',
            value: 4,
            color: '#FFB979',
        },
        {
            id: '3Beds',
            label: '3Beds',
            value: 3,
            color: '#A5E16F',
        },
        {
            id: 'unknownBeds',
            label: 'unknownBeds',
            value: -1,
            color: '#43C5E7',
        },
    ],
    Apt: [
        {
            id: '3Beds',
            label: '3Beds',
            value: 3,
            color: '#3E7AED',
        },
        {
            id: '2Beds',
            label: '2Beds',
            value: 2,
            color: '#FFB979',
        },
        {
            id: '1Beds',
            label: '1Beds',
            value: 1,
            color: '#A5E16F',
        },
        {
            id: 'unknownBeds',
            label: 'unknownBeds',
            value: -1,
            color: '#43C5E7',
        },
    ],
};

const BedChoose = rst.create<{ changeBedEvt: any; propType }>((ctx) => {
    const state = rst.state({
        choosedBeds: [],
    });

    return (props) => {
        const changeChoosedBeds = (clickedValue) => {
            if (state.choosedBeds.indexOf(clickedValue) > -1) {
                state.choosedBeds = removeItemFromArray(state.choosedBeds, clickedValue);
            } else {
                state.choosedBeds.push(clickedValue);
            }

            ctx.props.changeBedEvt(state.choosedBeds);
            console.log(state.choosedBeds.indexOf(clickedValue));
        };
        const classes = useStyles();
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.propType
                    ? bedsOption[props.propType].map((loopBed) => {
                          return (
                              <>
                                  <div
                                      className={classes.bedOption}
                                      style={{
                                          border: state.choosedBeds.indexOf(loopBed.value) > -1 ? '1px solid red' : '',
                                      }}
                                      onClick={() => {
                                          changeChoosedBeds(loopBed.value);
                                      }}
                                      id={loopBed.id}>
                                      <div
                                          className={classes.bedOptionIcon}
                                          style={{ backgroundColor: loopBed.color }}></div>
                                      <div style={{ marginLeft: 10 }}></div>
                                      <TR name={loopBed.label}></TR>
                                  </div>
                                  <div style={{ marginLeft: 5 }}></div>
                              </>
                          );
                      })
                    : null}
            </div>
        );
    };
});
const AxisFrame = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <svg width="100%" height="100%" id="chart">
                <defs>
                    <marker
                        id="X_arrow"
                        markerWidth="10"
                        markerHeight="7"
                        refX="0"
                        refY="3.5"
                        orient="auto-start-reverse">
                        <polygon points="0 0, 10 3.5, 0 7" className={classes.lineArrow} />
                    </marker>
                    <marker id="Y_arrow" markerWidth="7" refX="3.5" refY="5" markerHeight="10">
                        <polygon points="0,10,3.5,0,7,10" className={classes.lineArrow} orient="auto" />
                    </marker>
                </defs>

                <line
                    x1={x_start}
                    y1={y_start}
                    x2="95%"
                    y2={y_start}
                    className={classes.axis}
                    markerEnd="url(#X_arrow)"
                />

                <line
                    x1={x_start}
                    y1="3%"
                    x2={x_start}
                    y2={y_start}
                    className={classes.axis}
                    markerStart="url(#Y_arrow)"
                />
            </svg>
        );
    };
});

const PriceRangeTooltip = withStyles({
    tooltip: {
        color: '#495b6b',
        backgroundColor: 'white',
        fontSize: '12px',
    },
})(Tooltip);

import { CircularProgress, Dialog } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';

export const BackdropWithMessage = rst.create((ctx) => {
    const state = rst.state({
        show: false,
        message: '',
    });
    EventBus.on(EVENT_NAMES.EVENT_SHOW_LOADING_INDICATOR, (evt) => {
        state.message = evt.message;
        state.show = evt.show;
    });
    return (props) => {
        return (
            <Backdrop style={{ zIndex: 9999999, color: '#fff' }} open={state.show}>
                <CircularProgress color="inherit" />
                <TR name={state.message}></TR>
            </Backdrop>
        );
    };
});

import { makeStyles } from '@material-ui/styles';
import { CardContent } from '@material-ui/core';
import React, { useState } from 'react';
import { rst } from 'rt-state';
import { Card21 } from '../../../PropertyReport/ReportCards/Card21';
import { Card22 } from '../../../PropertyReport/ReportCards/Card22';
import { CardTitle } from './cardTitle';
import Tabs, { Tab } from 'react-best-tabs';
import { TR } from '../../../../commons';
import { CardPermission } from '../cardPermission';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { AppProvider } from '../../../../commons/appProvider';
const useStyles = makeStyles({
    tab: {
        fontSize: '8px',
        padding: '8px 10px !important',
    },
    tabs: {
        width: '50%',
    },
});
export const SchoolCard = (props: any) => {
    const { property_ql } = props;
    console.log(property_ql);
    const [closeContent, setCloseContent] = useState(true);
    const [tab, setTab] = useState(1);
    const [hasPermission, setHasPermission] = useState(true);
    EventBus.on(EVENT_NAMES.EVENT_MOBILE_CARD_NOPERMISSION, (evtData: any) => {
        setHasPermission(evtData.permission);
    });
    const closeContentEvt = () => {
        setCloseContent(!closeContent);
    };
    const changeTab = (evt, tab) => {
        setTab(tab);
    };
    const classes = useStyles();

    const getPrimarySchoolRank = () => {
        const yearlyRankingArr = property_ql.primarySchools[0].yearlyRanking?.ranking;
        const starRating = property_ql.primarySchools[0].starRating;

        return (
            <>
                {starRating ? starRating : 1}
                <img
                    src="assets/images/svgs/gold_star.svg"
                    style={{ height: '15px', paddingTop: '3px', paddingLeft: '2px' }}
                />
            </>
        );
    };

    const getSecondarySchoolRank = () => {
        const starRating = property_ql.secondarySchools[0].starRating;

        return (
            <>
                {starRating ? starRating : 1}
                <img
                    src="assets/images/svgs/gold_star.svg"
                    style={{ height: '15px', paddingTop: '3px', paddingLeft: '2px' }}
                />
            </>
        );
    };

    return (
        <div style={{ width: '100%', padding: '10px' }}>
            <CardTitle closeContent={closeContent} closeEvt={closeContentEvt} title={'learnAboutSchool'}></CardTitle>

            <CardContent style={{ padding: 0 }}>
                {!closeContent ? (
                    <div style={{ position: 'relative', minHeight: 150 }}>
                        <Tabs
                            activeTab="1"
                            className={classes.tabs}
                            ulClassName=""
                            activityClassName="bg-success"
                            onClick={changeTab}>
                            <Tab title={<TR name="primary_school"></TR>} className={classes.tab}></Tab>
                            <Tab title={<TR name="secondary_school"></TR>} className={classes.tab}></Tab>
                        </Tabs>
                        {tab == 1 ? <Card21 mobile={true} property={property_ql} /> : null}
                        {tab == 2 ? <Card22 mobile={true} property={property_ql} /> : null}
                    </div>
                ) : (
                    <>
                        <div style={{ fontWeight: 400, fontSize: '13px' }}>
                            <div>
                                <TR name="Primary School"></TR>： {property_ql.primarySchools[0].schoolName}
                                <span>({getPrimarySchoolRank()})</span>
                            </div>
                            <div style={{ marginTop: 5 }}>
                                <TR name="Secondary School"></TR>： {property_ql.secondarySchools[0].schoolName}
                                <span>({getPrimarySchoolRank()})</span>
                            </div>
                        </div>
                    </>
                )}
            </CardContent>
        </div>
    );
};

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { rst } from 'rt-state';

export interface NewContentImgProps {
    img: string;
    onClick?: () => void;
}
const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            //     height: '186px',
        },
    },
    img: {
        width: '300px',
        transition: 'all 0.6s ease',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            //     height: '186px',
        },

        '& img': {
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                //     height: '186px',
            },
        },
    },
}));

export const NewContentImg = rst.create<NewContentImgProps>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const { img, onClick } = props;
        return (
            <div className={classes.root} onClick={onClick}>
                <div className={classes.img}>
                    <img src={`${process.env.PUBLIC_URL}/static/images/${img}.png`} alt={img} />
                </div>
            </div>
        );
    };
});

import { List, ListItem, ListItemText, makeStyles, Popover } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR, TRHtml } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { isMobileDevice } from '../../../../commons/utils';
import { BedButton } from '../../components/BedButton';
import { HelpComponent } from '../../components/HelpComponent';
import { ItemLabel } from '../../components/ItemLabel';
import { MapButton } from '../../components/MapButton';

import { SliderItem } from '../../components/SliderItem';
import {
    SchoolRanks,
    SchoolStarRating,
    TransportOptions,
    ChineseImmiGrades,
    IndianImmiGrades,
    IslamImmiGrades,
    FullPriceGrades,
    FullPriceGradesFor2BedsRent,
    SecondarySchoolRanks,
    DistanceToCBDOptions,
    PrivateSchoolMixBoy,
    PrivateSchoolMixGirl,
    TenantProportion,
    HouseApt,
    FamilyIncome,
    PublicSecurity,
    AreaBusiness,
    Waterscape,
    Trees,
    SuburbColorCatagory,
    LandSize,
    PriceChange3M,
    PriceChange6M,
} from '../../support/PriceMapConsts';
const useStyles = makeStyles((theme) => {
    return {
        root: {
            paddingTop: 0,
            // paddingTop: isMobileDevice() ? 0 : 16,
        },
        row: {
            display: 'flex',
            width: '100%',
            alignItems: isMobileDevice() ? 'center' : '',
            '&:first-child': {
                marginTop: 0,
            },
            marginTop: isMobileDevice() ? 7 : 14,
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
            },
        },
        ranks: {
            display: 'flex',
            flexWrap: 'wrap',
            flexGrow: 1,
        },
        cornorSchoolNumber: {
            position: 'absolute',
            top: 0,
            right: 0,
            background: '#FA6400',
            borderBottomLeftRadius: 10,
            fontSize: 8,
            color: 'white',
            paddingLeft: 5,
            paddingRight: 5,
        },
        moreOptions: {
            cursor: 'pointer',
            margin: '20px 30px 0px 0px',
            padding: '5px 0px 0px 0px',
            '& span': {
                fontSize: '14px',
                margin: 0,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '14px',
                },
            },
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        moreOptionsIcon: {
            padding: '0px 10px',
        },
        moreOptionsContent: {
            height: isMobileDevice() ? '' : '35vh',
            // overflow: isMobileDevice() ? '' : 'auto',
            paddingTop: '10px',
        },
        optionsContent: {
            overflow: isMobileDevice() ? '' : 'auto',
        },
    };
});

export const SpecialCare = rst.create<{ PropType: any }>((ctx) => {
    const specialCare = rst.stateS({
        schoolRanks: [],
        SchoolStarRating: [],
        secondarySchoolRanks: [],
        trans: [],
        distanceOptions: [],
        chineseImmi: [],
        indianImmi: [],
        islamImmi: [],
        medianPriceRange: { lower: FullPriceGrades[0], upper: FullPriceGrades[FullPriceGrades.length - 1] },
        RentPriceRange: {
            lower: FullPriceGradesFor2BedsRent[0],
            upper: FullPriceGradesFor2BedsRent[FullPriceGradesFor2BedsRent.length - 1],
        },
        PrivateSchoolMixBoy: [],
        PrivateSchoolMixGirl: [],
        TenantProportion: [],
        HouseApt: [],
        FamilyIncome: [],

        choosedColorCatagory: -1,
        PublicSecurity: [],
        AreaBusiness: [],
        Waterscape: [],
        Trees: [],
        LandSize: [],
        PriceChange3M: [],
        PriceChange6M: [],
    });

    const immiPopState = rst.state({
        showImmiPop: false,
        ImmiPopAnchorEl: null,
        immiType: 1,
    });

    const BasicOptions = rst.stateS(false);
    const toggleBasicOptions = () => {
        BasicOptions.value = !BasicOptions.value;
    };
    const moreOptions = rst.stateS(true);
    const toggleMoreOptions = () => {
        moreOptions.value = !moreOptions.value;
    };

    return (props) => {
        const classes = useStyles();

        function changeMedianPrice(priceRange) {
            specialCare.value.medianPriceRange.lower = priceRange[0];
            specialCare.value.medianPriceRange.upper = priceRange[1];

            specialCare.forceUpdate();
            //console.log(specialCare.value);
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        function change2BedsRent(priceRange) {
            specialCare.value.RentPriceRange.lower = priceRange[0];
            specialCare.value.RentPriceRange.upper = priceRange[1];

            specialCare.forceUpdate();
            //console.log(specialCare.value);
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }

        function secondarySchoolRankClick(loopSchoolId) {
            const idx = specialCare.value.secondarySchoolRanks.indexOf(loopSchoolId);
            if (idx > -1) {
                specialCare.value.secondarySchoolRanks.splice(idx, 1);
            } else {
                specialCare.value.secondarySchoolRanks.push(loopSchoolId);
            }
            specialCare.forceUpdate();

            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }

        function schoolRankClick(loopSchoolId) {
            const idx = specialCare.value.schoolRanks.indexOf(loopSchoolId);
            if (idx > -1) {
                specialCare.value.schoolRanks.splice(idx, 1);
            } else {
                specialCare.value.schoolRanks.push(loopSchoolId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }

        function distanceOptionClick(distanceId) {
            const idx = specialCare.value.distanceOptions.indexOf(distanceId);
            if (idx > -1) {
                specialCare.value.distanceOptions.splice(idx, 1);
            } else {
                specialCare.value.distanceOptions.push(distanceId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }

        function transItemClick(tranItemId) {
            const idx = specialCare.value.trans.indexOf(tranItemId);
            if (idx > -1) {
                specialCare.value.trans.splice(idx, 1);
            } else {
                specialCare.value.trans.push(tranItemId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }

        function getRightImmiOptions() {
            let immiSelectedOptions;
            if (immiPopState.immiType == 1) {
                immiSelectedOptions = specialCare.value.chineseImmi;
            }
            if (immiPopState.immiType == 2) {
                immiSelectedOptions = specialCare.value.indianImmi;
            }
            if (immiPopState.immiType == 3) {
                immiSelectedOptions = specialCare.value.islamImmi;
            }
            return immiSelectedOptions;
        }
        function immiClick(itemId) {
            var immiSelectedOptions = getRightImmiOptions();
            const idx = immiSelectedOptions.indexOf(itemId);
            if (idx > -1) {
                immiSelectedOptions.splice(idx, 1);
            } else {
                immiSelectedOptions.push(itemId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }

        const getTransOptions = () => {
            var content = [];
            TransportOptions.forEach((tranItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 8 : 17 }}></div>);
                }
                const label = tranItem.l;

                content.push(
                    <BedButton
                        value={tranItem.id}
                        selected={specialCare.value.trans.indexOf(tranItem.id) > -1}
                        clickEvent={() => transItemClick(tranItem.id)}
                        label={label}></BedButton>,
                );
            });
            return content;
        };
        const getSchoolRanks = () => {
            var content = [];
            SchoolRanks.forEach((loopSchool, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 8 : 17 }}></div>);
                }
                let label = '';
                if (loopSchool.min && loopSchool.max) {
                    label = loopSchool.min + '-' + loopSchool.max;
                }
                if (loopSchool.min && !loopSchool.max) {
                    label = loopSchool.min + '';
                }
                content.push(
                    <BedButton
                        value={loopSchool.id}
                        selected={specialCare.value.schoolRanks.indexOf(loopSchool.id) > -1}
                        clickEvent={() => schoolRankClick(loopSchool.id)}
                        label={label}>
                        <div className={classes.cornorSchoolNumber}>{loopSchool.total}</div>
                    </BedButton>,
                );
            });
            return content;
        };
        const getSchoolStarRating = () => {
            var content = [];
            SchoolStarRating.forEach((loopSchool, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 8 : 17 }}></div>);
                }

                const label = loopSchool.star;

                content.push(
                    <BedButton
                        value={loopSchool.id}
                        selected={specialCare.value.schoolRanks.indexOf(loopSchool.id) > -1}
                        clickEvent={() => schoolRankClick(loopSchool.id)}
                        label={label}
                        image={
                            <img
                                src="assets/images/svgs/gold_star.svg"
                                style={{ height: '15px', paddingTop: '3px', paddingLeft: '2px' }}
                            />
                        }>
                        <div className={classes.cornorSchoolNumber}>{loopSchool.total}</div>
                    </BedButton>,
                );
            });
            return content;
        };

        const getSecondarySchoolRanks = () => {
            var content = [];
            SecondarySchoolRanks.forEach((loopSchool, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 8 : 17 }}></div>);
                }

                content.push(
                    <BedButton
                        value={loopSchool.id}
                        selected={specialCare.value.secondarySchoolRanks.indexOf(loopSchool.id) > -1}
                        clickEvent={() => secondarySchoolRankClick(loopSchool.id)}
                        label={loopSchool.label}></BedButton>,
                );
            });
            return content;
        };

        const getDistanceOptions = () => {
            var content = [];
            DistanceToCBDOptions.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.distanceOptions.indexOf(loopItem.id) > -1}
                            clickEvent={() => distanceOptionClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };

        function ChineseClick(ChineseId) {
            const idx = specialCare.value.chineseImmi.indexOf(ChineseId);
            if (idx > -1) {
                specialCare.value.chineseImmi.splice(idx, 1);
            } else {
                specialCare.value.chineseImmi.push(ChineseId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const getChineseOptions = () => {
            const content = [];
            ChineseImmiGrades.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.chineseImmi.indexOf(loopItem.id) > -1}
                            clickEvent={() => ChineseClick(loopItem.id)}
                            label={loopItem.l}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function IndianClick(IndianId) {
            const idx = specialCare.value.indianImmi.indexOf(IndianId);
            if (idx > -1) {
                specialCare.value.indianImmi.splice(idx, 1);
            } else {
                specialCare.value.indianImmi.push(IndianId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const getIndianOptions = () => {
            const content = [];
            IndianImmiGrades.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.indianImmi.indexOf(loopItem.id) > -1}
                            clickEvent={() => IndianClick(loopItem.id)}
                            label={loopItem.l}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function IslamClick(IslamId) {
            const idx = specialCare.value.islamImmi.indexOf(IslamId);
            if (idx > -1) {
                specialCare.value.islamImmi.splice(idx, 1);
            } else {
                specialCare.value.islamImmi.push(IslamId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const getIslamOptions = () => {
            const content = [];
            IslamImmiGrades.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.islamImmi.indexOf(loopItem.id) > -1}
                            clickEvent={() => IslamClick(loopItem.id)}
                            label={loopItem.l}></BedButton>
                    </>,
                );
            });
            return content;
        };

        // const getChineseImmiOptions = () => {
        //     var content = [];
        //     ChineseImmiGrades.forEach((loopItem, idx) => {
        //         if (idx >= 1) {
        //             content.push(<div style={{ marginLeft: isMobileDevice() ? 8 : 17 }}></div>);
        //         }

        //         content.push(
        //             <BedButton
        //                 value={loopItem.id}
        //                 selected={
        //                     immiPopState.immiType == 1
        //                         ? specialCare.value.chineseImmi.indexOf(loopItem.id) > -1
        //                         : immiPopState.immiType == 2
        //                         ? specialCare.value.indianImmi.indexOf(loopItem.id) > -1
        //                         : specialCare.value.islamImmi.indexOf(loopItem.id) > -1
        //                 }
        //                 clickEvent={() => immiClick(loopItem.id)}
        //                 label={loopItem.l}></BedButton>,
        //         );
        //     });
        //     return content;
        // };

        // const showImmiPop = (evt) => {
        //     if (evt.target) {
        //         immiPopState.ImmiPopAnchorEl = evt.target;
        //         immiPopState.showImmiPop = true;
        //     }
        // };
        // const changeImmiType = (immiType) => {
        //     //if change the immi type, then need to empty other 2 immi options
        //     if (immiType != immiPopState.immiType) {
        //         specialCare.value.chineseImmi = [];
        //         specialCare.value.indianImmi = [];
        //         specialCare.value.islamImmi = [];
        //         immiPopState.immiType = immiType;
        //         immiPopState.showImmiPop = false;
        //     }
        // };

        console.log(specialCare.value);

        function PrivateSchoolMixBoyClick(PrivateSchoolMixBoyId) {
            const idx = specialCare.value.PrivateSchoolMixBoy.indexOf(PrivateSchoolMixBoyId);
            if (idx > -1) {
                specialCare.value.PrivateSchoolMixBoy.splice(idx, 1);
            } else {
                specialCare.value.PrivateSchoolMixBoy.push(PrivateSchoolMixBoyId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const getPrivateSchoolMixBoyOptions = () => {
            const content = [];
            PrivateSchoolMixBoy.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.PrivateSchoolMixBoy.indexOf(loopItem.id) > -1}
                            clickEvent={() => PrivateSchoolMixBoyClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function PrivateSchoolMixGirlClick(PrivateSchoolMixGirlId) {
            const idx = specialCare.value.PrivateSchoolMixGirl.indexOf(PrivateSchoolMixGirlId);
            if (idx > -1) {
                specialCare.value.PrivateSchoolMixGirl.splice(idx, 1);
            } else {
                specialCare.value.PrivateSchoolMixGirl.push(PrivateSchoolMixGirlId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const getPrivateSchoolMixGirlOptions = () => {
            const content = [];
            PrivateSchoolMixGirl.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.PrivateSchoolMixGirl.indexOf(loopItem.id) > -1}
                            clickEvent={() => PrivateSchoolMixGirlClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function TenantProportionClick(TenantProportionId) {
            const idx = specialCare.value.TenantProportion.indexOf(TenantProportionId);
            if (idx > -1) {
                specialCare.value.TenantProportion.splice(idx, 1);
            } else {
                specialCare.value.TenantProportion.push(TenantProportionId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const TenantProportionOptions = () => {
            const content = [];
            TenantProportion.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.TenantProportion.indexOf(loopItem.id) > -1}
                            clickEvent={() => TenantProportionClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function HouseAptClick(HouseAptId) {
            const idx = specialCare.value.HouseApt.indexOf(HouseAptId);
            if (idx > -1) {
                specialCare.value.HouseApt.splice(idx, 1);
            } else {
                specialCare.value.HouseApt.push(HouseAptId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const HouseAptOptions = () => {
            const content = [];
            HouseApt.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.HouseApt.indexOf(loopItem.id) > -1}
                            clickEvent={() => HouseAptClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function FamilyIncomeClick(FamilyIncomeId) {
            const idx = specialCare.value.FamilyIncome.indexOf(FamilyIncomeId);
            if (idx > -1) {
                specialCare.value.FamilyIncome.splice(idx, 1);
            } else {
                specialCare.value.FamilyIncome.push(FamilyIncomeId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const FamilyIncomeOptions = () => {
            const content = [];
            FamilyIncome.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.FamilyIncome.indexOf(loopItem.id) > -1}
                            clickEvent={() => FamilyIncomeClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function PublicSecurityClick(PublicSecurityId) {
            const idx = specialCare.value.PublicSecurity.indexOf(PublicSecurityId);
            if (idx > -1) {
                specialCare.value.PublicSecurity.splice(idx, 1);
            } else {
                specialCare.value.PublicSecurity.push(PublicSecurityId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const PublicSecurityOptions = () => {
            const content = [];
            PublicSecurity.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.PublicSecurity.indexOf(loopItem.id) > -1}
                            clickEvent={() => PublicSecurityClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function AreaBusinessClick(AreaBusinessId) {
            const idx = specialCare.value.AreaBusiness.indexOf(AreaBusinessId);
            if (idx > -1) {
                specialCare.value.AreaBusiness.splice(idx, 1);
            } else {
                specialCare.value.AreaBusiness.push(AreaBusinessId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const AreaBusinessOptions = () => {
            const content = [];
            AreaBusiness.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.AreaBusiness.indexOf(loopItem.id) > -1}
                            clickEvent={() => AreaBusinessClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function WaterscapeClick(WaterscapeId) {
            const idx = specialCare.value.Waterscape.indexOf(WaterscapeId);
            if (idx > -1) {
                specialCare.value.Waterscape.splice(idx, 1);
            } else {
                specialCare.value.Waterscape.push(WaterscapeId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const WaterscapeOptions = () => {
            const content = [];
            Waterscape.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.Waterscape.indexOf(loopItem.id) > -1}
                            clickEvent={() => WaterscapeClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function TreesClick(TreesId) {
            const idx = specialCare.value.Trees.indexOf(TreesId);
            if (idx > -1) {
                specialCare.value.Trees.splice(idx, 1);
            } else {
                specialCare.value.Trees.push(TreesId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const TreesOptions = () => {
            const content = [];
            Trees.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.Trees.indexOf(loopItem.id) > -1}
                            clickEvent={() => TreesClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function LandSizeClick(LandSizeId) {
            const idx = specialCare.value.LandSize.indexOf(LandSizeId);
            if (idx > -1) {
                specialCare.value.LandSize.splice(idx, 1);
            } else {
                specialCare.value.LandSize.push(LandSizeId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const LandSizeOptions = () => {
            const content = [];
            LandSize.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.LandSize.indexOf(loopItem.id) > -1}
                            clickEvent={() => LandSizeClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function PriceChange3MClick(PriceChange3MId) {
            const idx = specialCare.value.PriceChange3M.indexOf(PriceChange3MId);
            if (idx > -1) {
                specialCare.value.PriceChange3M.splice(idx, 1);
            } else {
                specialCare.value.PriceChange3M.push(PriceChange3MId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const PriceChange3MOptions = () => {
            const content = [];
            PriceChange3M.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.PriceChange3M.indexOf(loopItem.id) > -1}
                            clickEvent={() => PriceChange3MClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };
        function PriceChange6MClick(PriceChange6MId) {
            const idx = specialCare.value.PriceChange6M.indexOf(PriceChange6MId);
            if (idx > -1) {
                specialCare.value.PriceChange6M.splice(idx, 1);
            } else {
                specialCare.value.PriceChange6M.push(PriceChange6MId);
            }
            specialCare.forceUpdate();
            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SPECAIL_FILTER, specialCare.value);
        }
        const PriceChange6MOptions = () => {
            const content = [];
            PriceChange6M.forEach((loopItem, idx) => {
                if (idx >= 1) {
                    content.push(<div style={{ marginLeft: isMobileDevice() ? 2 : 17 }}></div>);
                }
                content.push(
                    <>
                        <BedButton
                            value={loopItem.id}
                            selected={specialCare.value.PriceChange6M.indexOf(loopItem.id) > -1}
                            clickEvent={() => PriceChange6MClick(loopItem.id)}
                            label={loopItem.label}></BedButton>
                    </>,
                );
            });
            return content;
        };

        return (
            <div className={classes.root}>
                {isMobileDevice() ? (
                    <>
                        <div className={classes.row}>
                            <ItemLabel
                                style={{
                                    minWidth: 55,
                                }}>
                                <TR name={'Primary School'} />
                                <HelpComponent content="HELP_PRIMARY_SCHOOL" maxWidth="xs" />
                            </ItemLabel>
                            <div style={{ marginLeft: 18 }}></div>
                            <div className={classes.ranks}>{getSchoolStarRating()}</div>
                        </div>

                        {/* <div className={classes.row}>
                            <ItemLabel
                                style={{
                                    minWidth: 55,
                                    background:
                                        specialCare.value.choosedColorCatagory == SuburbColorCatagory.HS ? 'red' : '',
                                }}>
                                <TR name={'Secondary School'} />
                            </ItemLabel>
                            <div style={{ marginLeft: 18 }}></div>

                            <div className={classes.ranks}>{getSecondarySchoolRanks()}</div>
                        </div> */}

                        <div className={classes.row}>
                            <ItemLabel style={{ minWidth: 55 }}>
                                <TR name={'Transport'} />
                            </ItemLabel>
                            <div style={{ marginLeft: 18 }}></div>
                            <div className={classes.ranks}>{getTransOptions()}</div>
                        </div>

                        <div className={classes.row}>
                            <ItemLabel style={{ minWidth: 55 }}>
                                <TR name={'DistanceToCBD'} />
                            </ItemLabel>
                            <div style={{ marginLeft: 18 }}></div>
                            <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                {getDistanceOptions()}
                            </div>
                        </div>

                        <div className={classes.row}>
                            <ItemLabel style={{ minWidth: 55 }}>
                                <TR name={'Chinese Immi Pct Pop'} />
                            </ItemLabel>
                            <div style={{ marginLeft: 18 }}></div>
                            <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                {getChineseOptions()}
                            </div>
                        </div>
                        <div className={classes.row}>
                            <ItemLabel style={{ minWidth: 55 }}>
                                <TR name={'Indian Immi Pct Pop'} />
                            </ItemLabel>
                            <div style={{ marginLeft: 18 }}></div>
                            <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                {getIndianOptions()}
                            </div>
                        </div>
                        <div className={classes.row}>
                            <ItemLabel style={{ minWidth: 55 }}>
                                <TR name={'Islam Immi Pct Pop'} />
                            </ItemLabel>
                            <div style={{ marginLeft: 18 }}></div>
                            <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                {getIslamOptions()}
                            </div>
                        </div>
                        <div className={classes.row} style={{ flexDirection: 'column' }}>
                            <ItemLabel style={{ width: 300 }}>
                                <TR name={'Median lot size'} />
                            </ItemLabel>
                            {/* <div style={{ marginLeft: 18 }}></div> */}
                            <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                {LandSizeOptions()}
                            </div>
                        </div>
                        <div className={classes.row} style={{ flexDirection: 'column' }}>
                            <ItemLabel style={{ width: 300 }}>
                                <TR name={'House price changes (last 3 months)'} />
                            </ItemLabel>
                            {/* <div style={{ marginLeft: 18 }}></div> */}
                            <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                {PriceChange3MOptions()}
                            </div>
                        </div>
                        <div className={classes.row} style={{ flexDirection: 'column' }}>
                            <ItemLabel style={{ width: 300 }}>
                                <TR name={'House price changes (last 6 months)'} />
                            </ItemLabel>
                            {/* <div style={{ marginLeft: 18 }}></div> */}
                            <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                {PriceChange6MOptions()}
                            </div>
                        </div>

                        {/* <div className={classes.row}>
                        <ItemLabel style={{ minWidth: 42 }}>
                            <TRHtml
                                html={
                                    immiPopState.immiType == 1
                                        ? 'Chinese Immi Pct'
                                        : immiPopState.immiType == 2
                                        ? 'Indian Immi Pct'
                                        : 'Islam Immi Pct'
                                }></TRHtml>
                        </ItemLabel>
                        <img
                            src="assets/icons/arrow-lower-ico.svg"
                            style={{ cursor: 'pointer' }}
                            onClick={showImmiPop}></img>
                        <ImmiChoosePopover
                            anchorEl={immiPopState.ImmiPopAnchorEl}
                            open={immiPopState.showImmiPop}
                            changeImmiEvt={changeImmiType}
                            closeEvent={() => {
                                immiPopState.showImmiPop = false;
                            }}></ImmiChoosePopover>
                        <div style={{ marginLeft: 18 }}></div>
                        <div className={classes.ranks}>{getChineseImmiOptions()}</div>
                    </div> */}
                        {props.PropType === 'House' ? (
                            <div className={classes.row}>
                                <SliderItem
                                    valueRange={FullPriceGrades}
                                    itemName={'MedianPrice-House'}
                                    valueUnit={'m'}
                                    slideChangeEvent={changeMedianPrice}></SliderItem>
                            </div>
                        ) : (
                            //this is for 2 beds rent price range, but no api
                            <>
                                <div className={classes.row}>
                                    <SliderItem
                                        valueRange={FullPriceGrades}
                                        itemName={'MedianPrice-Apt'}
                                        valueUnit={'m'}
                                        slideChangeEvent={changeMedianPrice}></SliderItem>
                                </div>
                                <div className={classes.row}>
                                    <SliderItem
                                        valueRange={FullPriceGradesFor2BedsRent}
                                        itemName={'Rent-2beds'}
                                        valueUnit={''}
                                        slideChangeEvent={change2BedsRent}></SliderItem>
                                </div>
                            </>
                        )}
                        <MoreOptions
                            header="More Special Care"
                            toggleOptions={toggleMoreOptions}
                            isFold={moreOptions.value}>
                            <div className={classes.moreOptionsContent}>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel
                                        style={{
                                            width: 300,
                                            background:
                                                specialCare.value.choosedColorCatagory == SuburbColorCatagory.HS
                                                    ? 'red'
                                                    : '',
                                        }}>
                                        <TR name={'Secondary School'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks}>{getSecondarySchoolRanks()}</div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 370 }}>
                                        <TR name={'PrivateSchoolMixBoy'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {getPrivateSchoolMixBoyOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 370 }}>
                                        <TR name={'PrivateSchoolMixGirl'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {getPrivateSchoolMixGirlOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Tenant Proportion'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {TenantProportionOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'House/Apartment'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {HouseAptOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Family Income'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {FamilyIncomeOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Public Security'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {PublicSecurityOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Area Business'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {AreaBusinessOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Waterscape'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {WaterscapeOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Trees'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {TreesOptions()}
                                    </div>
                                </div>
                                {/* <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Median lot size'} />
                                    </ItemLabel>
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {LandSizeOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'House price changes (last 3 months)'} />
                                    </ItemLabel>
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {PriceChange3MOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'House price changes (last 6 months)'} />
                                    </ItemLabel>
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {PriceChange6MOptions()}
                                    </div>
                                </div> */}
                            </div>
                        </MoreOptions>
                    </>
                ) : (
                    <div className={classes.optionsContent} style={moreOptions.value ? null : { height: '75vh' }}>
                        <h2>
                            <TR name={'Special Care'} />
                        </h2>
                        <MoreOptions
                            header="Basic Special Care"
                            toggleOptions={toggleBasicOptions}
                            isFold={BasicOptions.value}>
                            <div className={classes.row}>
                                <ItemLabel
                                    style={{
                                        minWidth: 55,
                                    }}>
                                    <TR name={'Primary School'} />
                                    <HelpComponent content="HELP_PRIMARY_SCHOOL" maxWidth="xs" />
                                </ItemLabel>
                                <div style={{ marginLeft: 18 }}></div>
                                <div className={classes.ranks}>{getSchoolStarRating()}</div>
                            </div>

                            {/* <div className={classes.row}>
                                <ItemLabel
                                    style={{
                                        minWidth: 55,
                                        background:
                                            specialCare.value.choosedColorCatagory == SuburbColorCatagory.HS
                                                ? 'red'
                                                : '',
                                    }}>
                                    <TR name={'Secondary School'} />
                                </ItemLabel>
                                <div style={{ marginLeft: 18 }}></div>

                                <div className={classes.ranks}>{getSecondarySchoolRanks()}</div>
                            </div> */}

                            <div className={classes.row}>
                                <ItemLabel style={{ minWidth: 55 }}>
                                    <TR name={'Transport'} />
                                </ItemLabel>
                                <div style={{ marginLeft: 18 }}></div>
                                <div className={classes.ranks}>{getTransOptions()}</div>
                            </div>

                            <div className={classes.row}>
                                <ItemLabel style={{ minWidth: 55 }}>
                                    <TR name={'DistanceToCBD'} />
                                </ItemLabel>
                                <div style={{ marginLeft: 18 }}></div>
                                <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                    {getDistanceOptions()}
                                </div>
                            </div>

                            <div className={classes.row}>
                                <ItemLabel style={{ minWidth: 55 }}>
                                    <TR name={'Chinese Immi Pct Pop'} />
                                </ItemLabel>
                                <div style={{ marginLeft: 18 }}></div>
                                <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                    {getChineseOptions()}
                                </div>
                            </div>
                            <div className={classes.row}>
                                <ItemLabel style={{ minWidth: 55 }}>
                                    <TR name={'Indian Immi Pct Pop'} />
                                </ItemLabel>
                                <div style={{ marginLeft: 18 }}></div>
                                <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                    {getIndianOptions()}
                                </div>
                            </div>
                            <div className={classes.row}>
                                <ItemLabel style={{ minWidth: 55 }}>
                                    <TR name={'Islam Immi Pct Pop'} />
                                </ItemLabel>
                                <div style={{ marginLeft: 18 }}></div>
                                <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                    {getIslamOptions()}
                                </div>
                            </div>
                            <div className={classes.row} style={{ flexDirection: 'column' }}>
                                <ItemLabel style={{ width: 300 }}>
                                    <TR name={'Median lot size'} />
                                </ItemLabel>
                                <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                    {LandSizeOptions()}
                                </div>
                            </div>
                            <div className={classes.row} style={{ flexDirection: 'column' }}>
                                <ItemLabel style={{ width: 300 }}>
                                    <TR name={'House price changes (last 3 months)'} />
                                </ItemLabel>
                                <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                    {PriceChange3MOptions()}
                                </div>
                            </div>
                            <div className={classes.row} style={{ flexDirection: 'column' }}>
                                <ItemLabel style={{ width: 300 }}>
                                    <TR name={'House price changes (last 6 months)'} />
                                </ItemLabel>
                                <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                    {PriceChange6MOptions()}
                                </div>
                            </div>
                            {/* <div className={classes.row}>
                        <ItemLabel style={{ minWidth: 42 }}>
                            <TRHtml
                                html={
                                    immiPopState.immiType == 1
                                        ? 'Chinese Immi Pct'
                                        : immiPopState.immiType == 2
                                        ? 'Indian Immi Pct'
                                        : 'Islam Immi Pct'
                                }></TRHtml>
                        </ItemLabel>
                        <img
                            src="assets/icons/arrow-lower-ico.svg"
                            style={{ cursor: 'pointer' }}
                            onClick={showImmiPop}></img>
                        <ImmiChoosePopover
                            anchorEl={immiPopState.ImmiPopAnchorEl}
                            open={immiPopState.showImmiPop}
                            changeImmiEvt={changeImmiType}
                            closeEvent={() => {
                                immiPopState.showImmiPop = false;
                            }}></ImmiChoosePopover>
                        <div style={{ marginLeft: 18 }}></div>
                        <div className={classes.ranks}>{getChineseImmiOptions()}</div>
                    </div> */}
                            {props.PropType === 'House' ? (
                                <div className={classes.row}>
                                    <SliderItem
                                        valueRange={FullPriceGrades}
                                        itemName={'MedianPrice-House'}
                                        valueUnit={'m'}
                                        slideChangeEvent={changeMedianPrice}></SliderItem>
                                </div>
                            ) : (
                                //this is for 2 beds rent price range, but no api
                                <>
                                    <div className={classes.row}>
                                        <SliderItem
                                            valueRange={FullPriceGrades}
                                            itemName={'MedianPrice-Apt'}
                                            valueUnit={'m'}
                                            slideChangeEvent={changeMedianPrice}></SliderItem>
                                    </div>
                                    <div className={classes.row}>
                                        <SliderItem
                                            valueRange={FullPriceGradesFor2BedsRent}
                                            itemName={'Rent-2beds'}
                                            valueUnit={''}
                                            slideChangeEvent={change2BedsRent}></SliderItem>
                                    </div>
                                </>
                            )}
                        </MoreOptions>
                        <MoreOptions
                            header="More Special Care"
                            toggleOptions={toggleMoreOptions}
                            isFold={moreOptions.value}>
                            <div className={classes.moreOptionsContent}>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel
                                        style={{
                                            minWidth: 55,
                                            background:
                                                specialCare.value.choosedColorCatagory == SuburbColorCatagory.HS
                                                    ? 'red'
                                                    : '',
                                        }}>
                                        <TR name={'Secondary School'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks}>{getSecondarySchoolRanks()}</div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 370 }}>
                                        <TR name={'PrivateSchoolMixBoy'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {getPrivateSchoolMixBoyOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 370 }}>
                                        <TR name={'PrivateSchoolMixGirl'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {getPrivateSchoolMixGirlOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Tenant Proportion'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {TenantProportionOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'House/Apartment'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {HouseAptOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Family Income'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {FamilyIncomeOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Public Security'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {PublicSecurityOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Area Business'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {AreaBusinessOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Waterscape'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {WaterscapeOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Trees'} />
                                    </ItemLabel>
                                    {/* <div style={{ marginLeft: 18 }}></div> */}
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {TreesOptions()}
                                    </div>
                                </div>
                                {/* <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'Median lot size'} />
                                    </ItemLabel>
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {LandSizeOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'House price changes (last 3 months)'} />
                                    </ItemLabel>
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {PriceChange3MOptions()}
                                    </div>
                                </div>
                                <div className={classes.row} style={{ flexDirection: 'column' }}>
                                    <ItemLabel style={{ width: 300 }}>
                                        <TR name={'House price changes (last 6 months)'} />
                                    </ItemLabel>
                                    <div className={classes.ranks} style={{ justifyContent: 'flex-start' }}>
                                        {PriceChange6MOptions()}
                                    </div>
                                </div> */}
                            </div>
                        </MoreOptions>
                    </div>
                )}
            </div>
        );
    };
});

const ImmiChoosePopover = rst.create<{ anchorEl: any; closeEvent: any; open: boolean; changeImmiEvt: any }>((ctx) => {
    return (props) => {
        return (
            <Popover anchorEl={props.anchorEl} onClose={props.closeEvent} open={props.open}>
                <List component="nav" aria-label="secondary mailbox folders">
                    <ListItem
                        button
                        onClick={() => {
                            props.changeImmiEvt(1);
                        }}>
                        <ListItemText>
                            <TRHtml html="Chinese Immi Pct Pop"></TRHtml>
                        </ListItemText>
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => {
                            props.changeImmiEvt(2);
                        }}>
                        <ListItemText>
                            <TRHtml html="Indian Immi Pct Pop"></TRHtml>
                        </ListItemText>
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => {
                            props.changeImmiEvt(3);
                        }}>
                        <ListItemText>
                            <TRHtml html="Islam Immi Pct Pop"></TRHtml>
                        </ListItemText>
                    </ListItem>
                </List>
            </Popover>
        );
    };
});

const MoreOptions = rst.create<{ header?: string; toggleOptions?: () => void; isFold: boolean }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <>
                <div className={classes.moreOptions} onClick={props.toggleOptions}>
                    <span>
                        <TR name={props.header} />
                    </span>
                    <div className={classes.moreOptionsIcon}>
                        <img
                            src={
                                'assets/icons/' + (props.isFold ? 'arrow-lower-ico' : 'arrow-lupper-ico') + '.svg'
                            }></img>
                    </div>
                </div>
                {props.isFold ? null : props.children}
            </>
        );
    };
});

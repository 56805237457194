import { devEnv } from './dev';
import { prodEnv } from './prod';
import { stgEnv } from './stg';
import { localEnv } from './local';
import { ENV } from './common';

const baseEnv = {};
const APP_ENV = (process.env.REACT_APP_ENV ?? '').trim();
console.log(`env: ${APP_ENV}`);

const getEnv = (): ENV => {
    const configs = {
        local: localEnv,
        dev: devEnv,
        stg: stgEnv,
        prod: prodEnv,
    };
    return { ...baseEnv, ...configs[APP_ENV] };
};
export const env = getEnv();

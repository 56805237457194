import { plainToClass } from 'class-transformer';
import { axios } from './axios';

const api = {
    get: async (url: string, params?: any, headers?: any) => {
        const ret = await axios.get(url, { params, headers });
        return ret.data;
    },
    post: async (url: string, data?: any, headers?: any) => {
        const ret = await axios.post(url, data, { headers });
        return ret.data;
    },
    put: async (url: string, data?: any, headers?: any) => {
        const ret = await axios.put(url, data, { headers });
        return ret.data;
    },
    delete: async (url: string, params?: any, headers?: any) => {
        const ret = await axios.delete(url, { params, headers });
        return ret.data;
    },
};

export class API {
    static async get<T>(TCreator: { new (): T }, url: string, params?: any, headers?: any): Promise<T> {
        const ret = await api.get(url, params, headers);

        return TCreator != null && ret != null ? plainToClass(TCreator, ret) : ret;
    }

    static async getArray<T>(TCreator: { new (): T }, url: string, params?: any, headers?: any): Promise<T[]> {
        return (await API.get<T>(TCreator, url, params, headers)) as any;
    }

    static async post<T>(TCreator: { new (): T }, url: string, data?: any, headers?: any): Promise<T> {
        console.log(headers);
        const ret = await api.post(url, data, headers);

        return TCreator != null && ret != null ? plainToClass(TCreator, ret) : ret;
    }

    static async put<T>(TCreator: { new (): T }, url: string, data?: any, headers?: any): Promise<T> {
        const ret = await api.put(url, data, headers);
        return TCreator != null && ret != null ? plainToClass(TCreator, ret) : ret;
    }

    static async delete<T>(TCreator: { new (): T }, url: string, params?: any, headers?: any): Promise<T> {
        const ret = await api.delete(url, params, headers);
        return TCreator != null && ret != null ? plainToClass(TCreator, ret) : ret;
    }
}

import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { TR } from '../../../../commons';
import { getYearDifferenceFromNow } from '../../../../commons/utils';
import { InfoValue } from '../standard/infoValue';
import { InfoLabel } from '../standard/label';
import { CardTitle } from './cardTitle';
import { Station } from './station';
const useStyles = makeStyles({
    line: {
        display: 'flex',
        fontSize: '13px',
        fontWeight: 400,
        color: '#888888',
        marginTop: 5,
        justifyContent: 'flex-start',
    },
    itemValue: {
        marginLeft: 20,
    },
});
export const ApartmentProjectInfo = (props: any) => {
    const { property, inDetails, closest_station } = props;
    const [closeContent, setCloseContent] = useState(true);

    const classes = useStyles();
    return (
        <div style={{ padding: inDetails ? 10 : 0 }}>
            {inDetails ? (
                <CardTitle
                    closeEvt={() => {
                        setCloseContent(!closeContent);
                    }}
                    closeContent={closeContent}
                    title={'apartmentBuildingAndDeveloper'}></CardTitle>
            ) : null}
            {!inDetails || !closeContent ? (
                <div style={{ marginTop: 20 }}>
                    <div className={classes.line}>
                        <InfoLabel>ProjectScale</InfoLabel>
                        <InfoValue>{property.number_of_lots}</InfoValue>
                    </div>
                    <div className={classes.line}>
                        <InfoLabel>BuildingAge</InfoLabel>
                        <InfoValue> {getYearDifferenceFromNow(property.built_date, 'yyyy-MM-dd')}</InfoValue>
                    </div>
                    <div className={classes.line}>
                        <InfoLabel>builder</InfoLabel>
                        <InfoValue>
                            {' '}
                            {property.developer && property.developer != 0 ? (
                                property.developer
                            ) : (
                                <>
                                    <TR name="unknown"></TR>
                                </>
                            )}
                        </InfoValue>
                    </div>

                    {inDetails ? <Station station={closest_station}></Station> : null}
                </div>
            ) : null}
        </div>
    );
};

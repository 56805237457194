import { getItemFromArray, sortJsonArrayByAttr, sortNumArray } from '../../../commons/utils';
import { allSoldPropertiyData } from '../MapContainer/PropertyMarkerController';
import { calculateMedianPrice, getSecondarySchoolDesc, getSecondarySchoolRankByScore } from './common';
import { BedOptions, MapGlobalVars } from './PriceMapConsts';

/**
 *
 * @param propFeature
 * @return "House" or "Apt"
 */
export const getTypeByFeature = (propFeature) => {
    if (propFeature?.prop_type == 0 || propFeature?.prop_type == 1) {
        return 'House';
    }
    if (propFeature?.prop_type == 3 || propFeature?.prop_type == 4) {
        return 'Apt';
    }
};

export const getWalkingTime = (propFeature) => {
    if (propFeature.closest_station) {
        return propFeature.closest_station.walk_time;
    }
};

export const extractFirstPrimarySchool = (propFeatures) => {
    if (!propFeatures || !propFeatures.primary || !propFeatures.primary[0]) {
        return {};
    } else {
        return propFeatures.primary[0] || {};
    }
};

export const extractFirstSecondarySchool = (propFeatures) => {
    if (!propFeatures || !propFeatures.secondary || !propFeatures.secondary[0]) {
        return {};
    } else {
        return propFeatures.secondary[0] || {};
    }
};

export const getPrimarySchools = (primarySchoolList, nameKey) => {
    const result = [];
    if (primarySchoolList && primarySchoolList.length > 0) {
        primarySchoolList.forEach((loopSchool) => {
            result.push({
                name: loopSchool[nameKey],
                chips: [
                    (loopSchool.rank_disp != 'average' ? 'Top ' : '') + loopSchool.rank_disp,
                    loopSchool.score + (MapGlobalVars.currentUserLan == 'zh' ? '分' : 'Score'),
                ],
            });
        });
    }
    return result;
};

export const getSecondarySchools = (secondarySchoolList, nameKey) => {
    const result = [];
    if (secondarySchoolList && secondarySchoolList.length > 0) {
        secondarySchoolList.forEach((loopSchool) => {
            result.push({
                name: loopSchool[nameKey],
                chips:
                    loopSchool.score == 0
                        ? [getSecondarySchoolDesc(loopSchool.score)]
                        : [
                              getSecondarySchoolDesc(loopSchool.score),
                              loopSchool.score + (MapGlobalVars.currentUserLan == 'zh' ? '分' : 'Score'),
                          ],
            });
        });
    }
    return result;
};

export const getPrimarySchoolsStar = (primarySchoolList, nameKey) => {
    const result = [];
    if (primarySchoolList && primarySchoolList.length > 0) {
        primarySchoolList.forEach((loopSchool) => {
            result.push({
                name: loopSchool[nameKey],
                chips: [loopSchool.star_rating ? loopSchool.star_rating : 1],
            });
        });
    }
    return result;
};

export const getSecondarySchoolsStar = (secondarySchoolList, nameKey) => {
    const result = [];
    if (secondarySchoolList && secondarySchoolList.length > 0) {
        secondarySchoolList.forEach((loopSchool) => {
            result.push({
                name: loopSchool[nameKey],
                chips: [loopSchool.star_rating ? loopSchool.star_rating : 1],
            });
        });
    }
    return result;
};

export const getTrains = (tranStation) => {
    return [
        {
            name: tranStation?.name,
            chips: [tranStation?.distance?.toFixed(2) + 'km'],
        },
    ];
};
export const getClosedTrainDistance = (propFeature) => {
    return propFeature.closest_station?.distance?.toFixed(2) + 'km';
};

export const showGoogleResultByAddress = (address) => {
    window.open(
        'https://www.google.com/search?q=' +
            address +
            '&chips=online_chips:property&newwindow=1&sxsrf=ALeKk00F2ahxDEJOzn57H0_gkbLZzVWRdQ:1597495178558&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiyvpelnZ3rAhUPU30KHZ-cDrcQ_AUoA3oECAwQBQ&biw=1467&bih=721',
        '_blank',
    );
};

export const swtchToStreetView = (lat, lng) => {
    const url =
        'https://www.google.com/maps/@' +
        lat +
        ',' +
        lng +
        ',3a,75y,260h,90t/data=!3m5!1e1!3m3!1sqFJRfFf-Wx9e7SjRvlZD7g!2e0!6s%2F%2Fgeo3.ggpht.com%2Fcbk%3Fpanoid%3DqFJRfFf-Wx9e7SjRvlZD7g%26output%3Dthumbnail%26cb_client%3Dmaps_sv.tactile.gps%26thumb%3D2%26w%3D203%26h%3D100%26yaw%3D260%26pitch%3D0%26thumbfov%3D100';

    window.open(url);

    //this.navCtrl.navigateForward("show-google-street-view", { queryParams: { propertyPos: { prop_y: this.propObj.propFeatures.prop_y, prop_x: this.propObj.propFeatures.prop_x }, propId: this.propId } });
};

export const get = (propFeature) => {
    const result = [];
    propFeature.secondary.forEach((loopSchool) => {
        result.push({
            name: loopSchool.school_name,
            chips: [loopSchool.rating, loopSchool.ranking],
        });
    });
    return result;
};

export const getPropLastRecord = (propFeature) => {
    console.log(propFeature);
    if (propFeature) {
        if (propFeature.sold_record && propFeature.sold_record.length > 0) {
            const sortedSoldRecords = sortJsonArrayByAttr(propFeature.sold_record, 'sold_contract_date', true, 'DESC');

            return sortedSoldRecords[0];
        } else {
            return {};
        }
    } else {
        return {};
    }
};

export const getSuburbSoldPriceList = (suburbId) => {
    let soldPriceList;
    const querySuburbSoldData = getItemFromArray(allSoldPropertiyData, 'localityId', suburbId);
    if (querySuburbSoldData && querySuburbSoldData.houseSold) {
        soldPriceList = querySuburbSoldData.houseSold.map((loopItem) => {
            return loopItem.sold_price;
        });
        soldPriceList = sortNumArray(soldPriceList);
    }
    return soldPriceList;
};

export const getHouseSoldRank = (givenPrice, suburbId) => {
    let rank = 1;
    let transCount = 0;
    const querySuburbSoldData = getItemFromArray(allSoldPropertiyData, 'localityId', suburbId);
    console.log(querySuburbSoldData);
    if (querySuburbSoldData && querySuburbSoldData.houseSold) {
        transCount = querySuburbSoldData.houseSold.length;
        let soldPriceList = querySuburbSoldData.houseSold.map((loopItem) => {
            return loopItem.sold_price;
        });
        soldPriceList = sortNumArray(soldPriceList).reverse();
        console.log(soldPriceList);
        soldPriceList.forEach((loopPrice, idx) => {
            if (givenPrice <= loopPrice) {
                rank = idx + 1;
            }
        });
    }
    console.log('sending response .....');
    const result = {
        rank: rank,
        transCount: transCount,
    };
    console.log(result);
    return result;
};

export const constructPropObjFromFeature = (propertyFeatures) => {
    if (propertyFeatures[0] == null) {
        return {};
    }
    console.log(propertyFeatures);
    let prop: any = {};
    const lastPropertySoldRecord: any = getPropLastRecord(propertyFeatures[0]);
    prop = {
        propId: propertyFeatures[0].prop_id,
        lat: propertyFeatures[0].prop_y,
        lon: propertyFeatures[0].prop_x,
        address: propertyFeatures[0].prop_address,
        sold_contract_date: lastPropertySoldRecord.sold_contract_date,
        price: lastPropertySoldRecord.sold_price,
        landSize: propertyFeatures[0].landSize,
        landValue: propertyFeatures[0].land_value_reset,
        suburbName: propertyFeatures[0].prop_locality,
        primary: propertyFeatures[0].primary,
        secondary: propertyFeatures[0].secondary,
        sydneyData: propertyFeatures[0].price_trends.city_trends,
        suburbData: propertyFeatures[0].price_trends.locality_trends,
        council: propertyFeatures[0].council_name,
    };
    prop.allFeatures = propertyFeatures[0];
    return prop;
};

export const getAptSoldMedianPrice = (propId) => {
    const foundClickedApt = false;
    var prjOfApt: any;
    for (let idx = 0; idx < allSoldPropertiyData.length; idx++) {
        const suburbSoldData = allSoldPropertiyData[idx];
        if (suburbSoldData.aptSold && suburbSoldData.aptSold.length > 0) {
            for (let prjIdx = 0; prjIdx < suburbSoldData.aptSold.length; prjIdx++) {
                const loopPrj = suburbSoldData.aptSold[prjIdx];
                if (loopPrj && loopPrj.properties && loopPrj.properties.length > 0) {
                    for (let aptIdx = 0; aptIdx < loopPrj.properties.length; aptIdx++) {
                        const loopApt = loopPrj.properties[aptIdx];
                        if (loopApt.prop_id == propId) {
                            prjOfApt = loopPrj;
                            break;
                        }
                    }
                }
            }
        }
    }
    // after found all sold apartments, then need to calculate the median price
    if (prjOfApt) {
        const selectedBedOption = getItemFromArray(BedOptions.Apt, 'key', MapGlobalVars.priceMapFilter.bedOptions[0]);

        const filterSoldApt = prjOfApt.properties.filter((loopApt) => {
            if (MapGlobalVars.priceMapFilter.propertyType == 'Apt' && selectedBedOption.value != 0) {
                return loopApt.prop_bed == selectedBedOption;
            } else {
                return true;
            }
        });

        const aptPropertyPriceList = filterSoldApt.map((loopItem) => {
            return loopItem.sold_price;
        });
        const medianPrice = calculateMedianPrice(aptPropertyPriceList);

        return {
            medianPrice: medianPrice.median,
            soldProperties: filterSoldApt,
        };
    } else {
        return {
            medianPrice: 0,
            soldProperties: [],
        };
    }
};

// used for the price switch button to show what price if sold now
export const getPropertyPriceIfNowSell = (soldDate, soldPrice) => {
    console.log(MapGlobalVars.sectorMontlyData);
    console.log(soldDate);
    if (MapGlobalVars.sectorMontlyData && MapGlobalVars.sectorMontlyData.priceHouseTrend) {
        var soldMedianIdx = -1;
        MapGlobalVars.sectorMontlyData.priceHouseTrend.month.forEach((loopMonth, loopIdx) => {
            if (soldDate == loopMonth) {
                soldMedianIdx = loopIdx;
            }
        });
        if (soldMedianIdx != -1) {
            return (
                soldPrice *
                (MapGlobalVars.sectorMontlyData.priceHouseTrend.monthlyMedian[
                    MapGlobalVars.sectorMontlyData.priceHouseTrend.monthlyMedian.length - 1
                ] /
                    MapGlobalVars.sectorMontlyData.priceHouseTrend.monthlyMedian[soldMedianIdx])
            );
        } else {
            return soldPrice;
        }
    }
};

export const getTotalAroundTrans = (trans, propType) => {
    const stats = {};
    if (propType == 'House') {
        trans.forEach((tran) => {
            if (!stats[tran.prop_bed]) {
                stats[tran.prop_bed] = [];
            }
            stats[tran.prop_bed].push(tran.sold_price);
        });
    } else {
        trans.forEach((proj) => {
            proj.properties.forEach((apt) => {
                if (!stats[apt.prop_bed]) {
                    stats[apt.prop_bed] = [];
                }
                stats[apt.prop_bed].push(apt.sold_price);
            });
        });
    }
    Object.keys(stats).forEach((bed) => {
        stats[bed].medianPrice = calculateMedianPrice(stats[bed]);
    });
    console.log(stats);

    return stats;
};

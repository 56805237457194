import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
            padding: '8px 0',
        },
    };
});

export const CardDivider = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                <Divider variant="fullWidth" />
            </div>
        );
    };
});

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { API } from '../../commons';
import { env } from '../../environments';
import { storage } from '../../commons/storage';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, Paper } from '@material-ui/core';
import { getUserProfile } from '../../services/auth';

function createData(name, period, price) {
    return { name, period, price };
}

export default function SubscriptionList() {
    const [token, setToken] = useState('');
    const [userProfile, setUserProfile] = useState(null);
    const [subscriptionRows, setSubscriptionRows] = useState([]);

    useEffect(() => {
        storage.get('token').then((data: any) => {
            setToken(data);
            console.log(data);
        });
        getUserProfile().then((data: any) => {
            setUserProfile(data);
            console.log(userProfile);
            console.log(data.id);
            alert('hi');
            API.get(null, env.apiHost + '/payment/subscription?userId=' + data.id, {
                'Content-Type': 'application/json',
                Authorization: token,
            }).then((data: any) => {
                console.log(data);
                setSubscriptionRows(
                    data.map((row) => {
                        return {
                            name: row.id,
                            period: row.plan.interval,
                            price: row.plan.amount / 100,
                        };
                    }),
                );
            });
        });
    }, []);

    const cancel = async (subscriptionId) => {
        const resp = await API.delete(
            null,
            env.apiHost + '/payment/subscription',
            {
                subscriptionId,
            },
            {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        );
        console.log(resp);
    };

    return (
        <Paper>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Subscription Name</TableCell>
                            <TableCell align="right">Period</TableCell>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subscriptionRows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.period}</TableCell>
                                <TableCell align="right">${row.price}</TableCell>
                                <TableCell align="right">
                                    <Button
                                        onClick={() => {
                                            cancel(row.name);
                                        }}>
                                        Cancel
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

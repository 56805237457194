import { storage } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { convertToM, getItemFromArray } from '../../../commons/utils';
import { spaceScoreMapping } from '../PropertyCalculator/CalculatorConst';
import {
    MapLevel,
    PRICE_MAP_STATUS,
    SuburbPriceColors,
    PropertyPriceLegendList,
    FullPriceGrades,
    ChineseImmiGrades,
    IndianImmiGrades,
    IslamImmiGrades,
    MapGlobalVars,
    SecondarySchoolRanks,
    DistanceToCBDOptions,
    PrivateSchoolMixBoy,
    PrivateSchoolMixGirl,
    TenantProportion,
    HouseApt,
    FamilyIncome,
    PublicSecurity,
    AreaBusiness,
    Waterscape,
    Trees,
    LandSize,
    PriceChange3M,
    PriceChange6M,
    mapCatagoryColors,
} from './PriceMapConsts';

export const isProperType = (propType) => {
    if (propType && (propType.startsWith('Apt') || propType.startsWith('House'))) {
        return true;
    } else {
        return false;
    }
};

export const getClickedSuburbByLatLng = (latLng) => {
    let foundSuburbData;

    console.log(foundSuburbData);
};

// all Markers are json object, the key is prop id
export const hasMarkerInSamePosition = (allMarkers, latLng) => {
    let hasMarker = false;
    try {
        Object.keys(allMarkers).forEach((key) => {
            const loopMarker = allMarkers[key];
            const loopMarkerProperty = loopMarker.getMarkerProperty();
            if (loopMarkerProperty.lat == latLng.lat && loopMarkerProperty.lon == latLng.lon) {
                hasMarker = true;
                throw ' found';
            }
        });
    } catch (e) {
        console.log(e);
    }

    return hasMarker;
};
export const mapHasLayerOnThisPoint = (map, latLng) => {
    let hasLayer = false;
    try {
        map.eachLayer((layer) => {
            const layerLatLng = layer.getLatLng();
            console.log('========================');
            console.log(layerLatLng);
            console.log(latLng);
            if ((layerLatLng.lat = latLng.lat && layerLatLng.lng == latLng.lng)) {
                hasLayer = true;
                throw 'Interrupt the loop';
            }
        });
    } catch (e) {
        console.log(e);
    }

    return hasLayer;
};

export const getPriceMapLevelByZoom = (zoom) => {
    if (MapGlobalVars.shouldStayOnPropertyLevel && zoom < MapLevel.propertyLevel.lower) {
        return PRICE_MAP_STATUS.PROPERTYLEVEL;
    }
    if (zoom >= MapLevel.suburbLevel.lower && zoom <= MapLevel.suburbLevel.upper) {
        return PRICE_MAP_STATUS.SUBURBLEVEL;
    }
    if (zoom >= MapLevel.propertyLevel.lower && zoom <= MapLevel.propertyLevel.mid) {
        return PRICE_MAP_STATUS.PROPERTYLEVEL;
    }
    if (zoom > MapLevel.propertyLevel.mid && zoom <= MapLevel.propertyLevel.upper) {
        return PRICE_MAP_STATUS.ALLPROPERTYLEVEL;
    }
};

export const getSuitablePriceArray = (givenPrice) => {
    givenPrice = convertToM(givenPrice);
    for (let colorIdx = 0; colorIdx < FullPriceGrades.length; colorIdx++) {
        const loopColorPrice = FullPriceGrades[colorIdx];

        if (givenPrice >= loopColorPrice) {
            PropertyPriceLegendList[0].max = FullPriceGrades[colorIdx - 1];

            PropertyPriceLegendList[1].min = FullPriceGrades[colorIdx - 1];
            PropertyPriceLegendList[1].max = FullPriceGrades[colorIdx];

            PropertyPriceLegendList[2].min = FullPriceGrades[colorIdx];
            PropertyPriceLegendList[2].max = FullPriceGrades[colorIdx + 1];

            PropertyPriceLegendList[3].min = FullPriceGrades[colorIdx + 1];
            PropertyPriceLegendList[3].max = FullPriceGrades[colorIdx + 2];

            PropertyPriceLegendList[4].min = FullPriceGrades[colorIdx + 2];
        }
    }
};

export const getPriceLegendIndx = (price) => {
    let foundColor = '';
    let returnIndex = 1;
    for (let i = 0; i < PropertyPriceLegendList.length; i++) {
        const loopPrice = PropertyPriceLegendList[i];

        if (!loopPrice.min) {
            if (price <= loopPrice.max * 1000000) {
                foundColor = loopPrice.color;
                returnIndex = i + 1;
                break;
            }
        }
        if (!loopPrice.max) {
            if (price >= loopPrice.min * 1000000) {
                foundColor = loopPrice.color;
                returnIndex = i + 1;
                break;
            }
        }

        if (loopPrice.min && loopPrice.max) {
            if (price >= loopPrice.min * 1000000 && price < loopPrice.max * 1000000) {
                foundColor = loopPrice.color;
                returnIndex = i + 1;
                break;
            }
        }
    }

    return returnIndex;
};

export const getPriceLabelColor = (price) => {
    let foundColor = '';

    for (let i = 0; i < PropertyPriceLegendList.length; i++) {
        const loopPrice = PropertyPriceLegendList[i];

        if (!loopPrice.min) {
            if (price <= loopPrice.max * 1000000) {
                foundColor = loopPrice.color;

                break;
            }
        }
        if (!loopPrice.max) {
            if (price >= loopPrice.min * 1000000) {
                foundColor = loopPrice.color;

                break;
            }
        }

        if (loopPrice.min && loopPrice.max) {
            if (price >= loopPrice.min * 1000000 && price < loopPrice.max * 1000000) {
                foundColor = loopPrice.color;

                break;
            }
        }
    }

    return foundColor;
};

export const getSuburbColor = (propertyType, medianPrice) => {
    console.log('getting suburb color....');
    const subColorData = SuburbPriceColors[propertyType];
    let foundColor = '';
    for (let idx = 0; idx < subColorData.length; idx++) {
        const loopPrice = subColorData[idx];

        if (!loopPrice.min) {
            if (medianPrice <= loopPrice.max * 1000000) {
                foundColor = loopPrice.color;
                break;
            }
        }
        if (!loopPrice.max) {
            if (medianPrice >= loopPrice.min * 1000000) {
                foundColor = loopPrice.color;
                break;
            }
        }

        if (loopPrice.min && loopPrice.max) {
            if (medianPrice >= loopPrice.min * 1000000 && medianPrice < loopPrice.max * 1000000) {
                foundColor = loopPrice.color;
                break;
            }
        }
    }
    return foundColor;
};

export const changeAddressURL = (dest) => {
    // Current URL: https://my-website.com/page_a
    const nextURL = dest;
    const nextTitle = '';
    const nextState = { additionalInformation: 'Updated the URL with JS' };

    // This will create a new entry in the browser's history, without reloading
    window.history.pushState(nextState, nextTitle, nextURL);

    // This will replace the current entry in the browser's history, without reloading
    window.history.replaceState(nextState, nextTitle, nextURL);
};

export const triggrMemberWindowForVisitor = (appProvider) => {
    EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
        open: true,
        title: 'ERROR',
        confirmEvt: () => {
            appProvider.state.authDialog = 'signup';
        },
        confirmButtonText: 'Signup',
        cancelButtonText: 'Login',
        cancelEvt: () => {
            appProvider.state.authDialog = 'login';
        },
        content: 'FUNCTION_FOR_MEMBER',
        thirdButton: 'LEARN_MEMBER_SHIP',
        thirdButtonEvt: () => {
            window.open('./JoinMember', '_blank');
        },
    });
};
export const calculateMedianPrice = (priceList) => {
    const medianPrice = {
        range: { min: 0, max: 0 },
        median: 0,
    };
    const orderPriceList = priceList
        ?.filter((loopItem) => {
            return loopItem != null;
        })
        .sort((p1, p2) => {
            p1 = parseInt(p1);
            p2 = parseInt(p2);
            if (p1 - p2 > 0) {
                return 1;
            }
            if (p1 - p2 == 0) {
                return 0;
            }
            if (p1 - p2 < 0) {
                return -1;
            }
        });

    if (orderPriceList && orderPriceList.length > 0) {
        medianPrice.range = {
            min: orderPriceList[0],
            max: orderPriceList[orderPriceList.length - 1],
        };
        if (orderPriceList.length % 2 == 0) {
            medianPrice.median =
                (orderPriceList[orderPriceList.length / 2 - 1] + orderPriceList[orderPriceList.length / 2]) / 2;
        } else {
            medianPrice.median = orderPriceList[(orderPriceList.length - 1) / 2];
        }
    }

    return medianPrice;
};

export const getTranDesc = (value) => {
    if (value == null || value == 'F' || value == 'None') {
        return 'NOT_HAVE';
    }

    if (value == 'M' || value == 'T') {
        return 'HAVE';
    }
    if (value == 'L') {
        return 'LIGHTRAIL';
    }
};
export const getSecondarySchoolRankByScore = (score) => {
    console.log('school score is ' + score);
    let schoolRank = 0;
    for (let i = 0; i < SecondarySchoolRanks.length; i++) {
        const loopItem = SecondarySchoolRanks[i];
        if (loopItem.min && score >= loopItem.min && ((loopItem.max && score <= loopItem.max) || !loopItem.max)) {
            schoolRank = loopItem.id;
            break;
        }
    }
    return schoolRank;
};
export const getTranValue = (value) => {
    if (value == null) {
        return '0';
    } else {
        return '1';
    }
};

export const getDistanceGrade = (value) => {
    let distanceGrade = 0;
    for (let i = 0; i < DistanceToCBDOptions.length; i++) {
        const loopItem = DistanceToCBDOptions[i];
        if (value >= loopItem.min && value < loopItem.max) {
            distanceGrade = loopItem.id;
            break;
        }
    }
    return distanceGrade;
};

export const findSuburbValueIndex = (value, valueType) => {
    if (valueType == 'rate_primary') {
        return parseInt(value) - 1;
    }
    if (valueType == 'score_secondary') {
        if (value >= 96) {
            return 4;
        } else if (value >= 90 && value <= 95) {
            return 3;
        } else {
            return 2;
        }
    }

    if (valueType == 'chinese') {
        return getChineseImmiValue(value);
    }
    if (valueType == 'hinduism_p_pct') {
        return getIndianImmiValue(value);
    }
    if (valueType == 'islam_p_pct') {
        return getIslamImmiValue(value);
    }
    if (valueType == 'rate_private_boys') {
        return 5 - getPrivateSchoolMixBoy(value);
    }
    if (valueType == 'rate_private_girls') {
        return 5 - getPrivateSchoolMixGirl(value);
    }
    if (valueType == 'rental_pct') {
        return getTenantProportion(value) - 0;
    }
    // if (valueType == 'train_station') {
    //     return getTranValue(value);
    // }
    if (valueType == 'house_pct') {
        return getHouseApt(value) - 1;
    }
    if (valueType == 'family_income') {
        return getFamilyIncome(value) - 1;
    }
    if (valueType == 'rate_public_security') {
        return getPublicSecurity(value) - 1;
    }
    if (valueType == 'rate_waterfront') {
        return 5 - getWaterscape(value);
    }
    if (valueType == 'rate_tree_cover') {
        return 5 - getTrees(value);
    }

    if (valueType == 'rate_shopping') {
        return 5 - getAreaBusiness(value);
    }
    if (valueType == 'median_lz') {
        return getLandSize(value);
    }
    if (valueType == 'PriceChange3M') {
        var color_3m = getPriceChange3M(value);
        if (color_3m) {
            return 5 - color_3m;
        } else {
            return 4;
        }
    }
    if (valueType == 'PriceChange6M') {
        var color_6m = getPriceChange6M(value);
        if (color_6m) {
            return 5 - color_6m;
        } else {
            return 4;
        }
    }
};

export const getChineseImmiValue = (value) => {
    value = value * 100;
    for (let idx = 0; idx < ChineseImmiGrades.length; idx++) {
        const loopChineseImmiGrade = ChineseImmiGrades[idx];
        if (value >= loopChineseImmiGrade.lower && value <= loopChineseImmiGrade.upper) {
            return loopChineseImmiGrade.id;
        }
    }
};
export const getIndianImmiValue = (value) => {
    value = value * 100;
    for (let idx = 0; idx < IndianImmiGrades.length; idx++) {
        const loopIndianImmiGrade = IndianImmiGrades[idx];
        if (value >= loopIndianImmiGrade.lower && value <= loopIndianImmiGrade.upper) {
            return loopIndianImmiGrade.id;
        }
    }
};
export const getIslamImmiValue = (value) => {
    value = value * 100;
    for (let idx = 0; idx < IslamImmiGrades.length; idx++) {
        const loopIslamImmiGrade = IslamImmiGrades[idx];
        if (value >= loopIslamImmiGrade.lower && value <= loopIslamImmiGrade.upper) {
            return loopIslamImmiGrade.id;
        }
    }
};

export const getPrivateSchoolMixBoy = (value) => {
    let privateSchoolMixBoy = 0;
    for (let i = 0; i < PrivateSchoolMixBoy.length; i++) {
        const loopItem = PrivateSchoolMixBoy[i];
        if (value >= loopItem.min && value <= loopItem.max) {
            privateSchoolMixBoy = loopItem.id;
            break;
        }
    }
    return privateSchoolMixBoy;
};

export const getPrivateSchoolMixGirl = (value) => {
    let privateSchoolMixGirl = 0;
    for (let i = 0; i < PrivateSchoolMixGirl.length; i++) {
        const loopItem = PrivateSchoolMixGirl[i];
        if (value >= loopItem.min && value <= loopItem.max) {
            privateSchoolMixGirl = loopItem.id;
            break;
        }
    }
    return privateSchoolMixGirl;
};
export const getTenantProportion = (value) => {
    let tenantProportion = 0;
    for (let i = 0; i < TenantProportion.length; i++) {
        const loopItem = TenantProportion[i];
        if (value >= loopItem.min && value <= loopItem.max) {
            tenantProportion = loopItem.id;
            console.log(loopItem.id);
            break;
        }
    }
    return tenantProportion;
};
export const getHouseApt = (value) => {
    let houseApt = 0;
    for (let i = 0; i < HouseApt.length; i++) {
        const loopItem = HouseApt[i];
        if (value >= loopItem.min && value <= loopItem.max) {
            houseApt = loopItem.id;
            break;
        }
    }
    return houseApt;
};
export const getFamilyIncome = (value: any) => {
    value = value * 52;
    for (let i = 0; i < FamilyIncome.length; i++) {
        const loopItem = FamilyIncome[i];
        if (value >= loopItem.min && value <= loopItem.max) {
            return loopItem.id;
        }
    }
};
export const getPublicSecurity = (value) => {
    let publicSecurity = 0;
    for (let i = 0; i < PublicSecurity.length; i++) {
        const loopItem = PublicSecurity[i];
        if (value === loopItem.number) {
            publicSecurity = loopItem.id;
            break;
        }
    }
    return publicSecurity;
};
export const getAreaBusiness = (value) => {
    let areaBusiness = 0;
    for (let i = 0; i < AreaBusiness.length; i++) {
        const loopItem = AreaBusiness[i];
        if (value >= loopItem.min && value <= loopItem.max) {
            areaBusiness = loopItem.id;
            break;
        }
    }
    return areaBusiness;
};
export const getWaterscape = (value) => {
    let waterscape = 0;
    for (let i = 0; i < Waterscape.length; i++) {
        const loopItem = Waterscape[i];
        if (value >= loopItem.min && value <= loopItem.max) {
            waterscape = loopItem.id;
            break;
        }
    }
    return waterscape;
};
export const getTrees = (value) => {
    let trees = 0;
    for (let i = 0; i < Trees.length; i++) {
        const loopItem = Trees[i];
        if (value >= loopItem.min && value <= loopItem.max) {
            trees = loopItem.id;
            break;
        }
    }
    return trees;
};
export const getLandSize = (value) => {
    let landSize = 0;
    for (let i = 0; i < LandSize.length; i++) {
        const loopItem = LandSize[i];
        if (value >= loopItem.min && value <= loopItem.max) {
            landSize = loopItem.id;
            console.log(loopItem.id);
            break;
        }
    }
    return landSize;
};
export const getPriceChange3M = (value) => {
    let priceChange3M = 0;
    console.log('==============');
    console.log(PriceChange3M);
    console.log(value);
    for (let i = 0; i < PriceChange3M.length; i++) {
        const loopItem = PriceChange3M[i];
        if (value >= loopItem.min && value <= loopItem.max) {
            priceChange3M = loopItem.id;
            console.log(loopItem.id);
            break;
        }
    }
    return priceChange3M;
};
export const getPriceChange6M = (value) => {
    let priceChange6M = 0;
    for (let i = 0; i < PriceChange6M.length; i++) {
        const loopItem = PriceChange6M[i];
        if (value >= loopItem.min && value <= loopItem.max) {
            priceChange6M = loopItem.id;
            console.log(loopItem.id);
            break;
        }
    }
    return priceChange6M;
};

export const getIncomeDesc = (value) => {
    if (!value || value == 0) {
        return 'NO_RESI';
    }
    if (value >= 200 && value < 1400) {
        return 'LOWEST';
    }
    if (value >= 1400 && value < 1700) {
        return 'LOWER';
    }
    if (value >= 1700 && value < 2300) {
        return 'MIDDLE_INCOME';
    }

    if (value >= 2300 && value < 3000) {
        return 'HIGHER';
    }

    if (value > 3000) {
        return 'HIGHEST';
    }
};

export const getPrimarySchoolDescByScore = (score) => {
    if (score == 100) {
        return 'BEST';
    }

    if (score >= 98 && score <= 99) {
        return 'BETTER';
    }
    if (score >= 96 && score <= 97) {
        return 'GOOD';
    }
    if (score < 96) {
        return 'NORMAL';
    }
};

export const getPrimarySchoolDesc = (value) => {
    if (value == 5) {
        return 'BEST';
    }

    if (value == 4) {
        return 'BETTER';
    }
    if (value == 3) {
        return 'GOOD';
    }
    if (value == 1 || value == 2) {
        return 'NORMAL';
    }
};

export const getSecondarySchoolDesc = (value) => {
    if (value >= 96) {
        return 'BETTER';
    } else if (value >= 90 && value < 95) {
        return 'BETTER1';
    } else {
        return 'BETTER2';
    }
};

export const getShoppingCenterDesc = (value) => {
    if (value == 1) {
        return 'LESS';
    }
    if (value == 2) {
        return 'SMALL_SHOPPING';
    }
    if (value == 3) {
        return 'WITH_SHOPPING';
    }
    if (value >= 4) {
        return 'LOTS_SHOPPING';
    }
};

export const getIndoorQuestionValue = (region, bed, bath) => {
    const refJson = spaceScoreMapping;

    for (let i = 0; i < refJson.length; i++) {
        const loopRef = refJson[i];
        if (loopRef.region == region && loopRef.bed == bed && loopRef.bath == bath) {
            return loopRef;
        }
    }

    return null;
};

export const saveSearchAddressToHistory = async (propId, address) => {
    const SEARCH_ADDRESS_HISTORY = 'SEARCH_ADDRESS_HISTORY';
    const currentHistoryList: any = await storage.get(SEARCH_ADDRESS_HISTORY);
    if (currentHistoryList) {
        const historyJsonObj = JSON.parse(currentHistoryList);
        const savedAddress = getItemFromArray(historyJsonObj, 'id', propId);
        if (!savedAddress) {
            if (historyJsonObj.length >= 10) {
                historyJsonObj.slice(-1, 1);
            }
            historyJsonObj.unshift({
                type: 'property',
                id: propId,
                name: address,
                inHistory: true,
            });
            storage.save(SEARCH_ADDRESS_HISTORY, JSON.stringify(historyJsonObj));
        }
    } else {
        storage.save(
            SEARCH_ADDRESS_HISTORY,
            JSON.stringify([
                {
                    type: 'property',
                    id: propId,
                    name: address,
                    inHistory: true,
                },
            ]),
        );
    }
};

export const getSearchedHistory = async () => {
    const currentHistoryList: any = await storage.get('SEARCH_ADDRESS_HISTORY');

    if (!currentHistoryList) {
        return [];
    } else {
        return JSON.parse(currentHistoryList);
    }
};

export const getMobileSearchedHistory = async (cacheKey) => {
    const currentHistoryList: any = await storage.get(cacheKey);

    console.log(currentHistoryList);
    if (!currentHistoryList) {
        return [];
    } else {
        return currentHistoryList;
    }
};

export const detectInputSearchStr = (checkingStr) => {
    const firstLetter = checkingStr.trim().substr(0, 1);
    if (firstLetter >= 1 && firstLetter <= 9) {
        return 'N';
    } else if ((firstLetter >= 'a' && firstLetter <= 'z') || (firstLetter >= 'A' && firstLetter <= 'Z')) {
        return 'S';
    }
    return '';
};

import AppsIcon from '@material-ui/icons/Apps';
import ExploreIcon from '@material-ui/icons/Explore';
import PhotoIcon from '@material-ui/icons/Photo';
import React from 'react';
// import { HomePage } from '../Home';

const items = [
    // {
    //     path: '/',
    //     name: 'Home',
    //     type: 'link',
    //     icon: ExploreIcon,
    //     component: () => <HomePage />,
    // },
    {
        path: '/apps',
        name: 'Apps',
        type: 'submenu',
        icon: AppsIcon,
        badge: {
            type: 'primary',
            value: '5',
        },
        children: [
            {
                path: '/calendar',
                name: 'Calendar',
                component: () => <div>Calendar</div>,
            },
            {
                path: '/media',
                name: 'Media',
                component: () => <div>Media</div>,
            },
            {
                path: '/messages',
                name: 'Messages',
                component: () => <div>Messages</div>,
            },
            {
                path: '/social',
                name: 'Social',
                component: () => <div>Social</div>,
            },
            {
                path: '/chat',
                name: 'Chat',
                component: () => <div>Chat</div>,
            },
        ],
    },
    {
        path: '/widgets',
        name: 'Widgets',
        type: 'link',
        icon: PhotoIcon,
        component: () => <div>Widgets</div>,
    },
];

export const routes = {
    items,
};

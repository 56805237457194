import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { clsx, TR, TRHtml } from '../../../commons';
import { isMobileDevice } from '../../../commons/utils';
import { PriceMapTheme } from '../support/PriceMapTheme';

const useStyles = makeStyles((theme) => {
    return {
        pc: {
            whiteSpace: 'nowrap',
            borderRadius: 4,
            background: '#F6F7F9',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
            padding: '7px 7px',
            fontWeight: 400,
            cursor: 'pointer',
            marginTop: '5px',
            position: 'relative',
            color: '#999999',
        },
        pc_selected: {
            color: theme.palette.primary.main,
            background: PriceMapTheme[PriceMapTheme.currentTheme].UNSELECT_BUTTON_BACKGROUND,
        },
        mobile: {
            height: 33,
            padding: '7px 10px',
            background: '#F6F7F9',
            fontSize: '12px',
            fontWeight: 400,
            borderRadius: 4,
            flexWrap: 'nowrap',
            position: 'relative',
            color: '#999999',
        },
        mobile_selected: {
            color: theme.palette.primary.main,
            background: PriceMapTheme[PriceMapTheme.currentTheme].UNSELECT_BUTTON_BACKGROUND,
        },
    };
});

export const BedButton = rst.create<{ label: any; image?: any; value: any; selected: boolean; clickEvent: any }>(
    (ctx) => {
        return (props) => {
            const classes = useStyles();
            return (
                <>
                    <div
                        onClick={props.clickEvent}
                        className={
                            isMobileDevice()
                                ? clsx(classes.mobile, props.selected ? classes.mobile_selected : '')
                                : clsx(classes.pc, props.selected ? classes.pc_selected : '')
                        }
                        {...props}>
                        <TRHtml html={props.label}></TRHtml>
                        {props.children}
                        {props.image}
                    </div>
                </>
            );
        };
    },
);

import ReactDOM from 'react-dom/server';
import React from 'react';
import { getLatlngArrayFromEncodedPath } from '../../../../commons/utils';
import { BusinessParkMarker, SimilarTransMarker } from '../../../PriceMap/components/PriceLabel';

const win: any = window;
const L = win.L;

export class BusinessPark {
    mapInstance = null;
    constructor(mapInstance) {
        this.mapInstance = mapInstance;
    }

    drawBusinessParks(propCenter, businessPark) {
        L.circle([propCenter.lat, propCenter.lon], { radius: 16000, fill: false }).addTo(this.mapInstance);

        businessPark.forEach((loopItem, idx) => {
            const marker = L.marker([loopItem.lat, loopItem.lon]);
            const iconHTML = ReactDOM.renderToString(React.createElement(BusinessParkMarker, {}, idx + 1));

            const propertyIcon = new L.DivIcon({
                className: '',
                html: iconHTML,
            });

            marker.setIcon(propertyIcon).addTo(this.mapInstance);
        });
    }
}

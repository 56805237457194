/* eslint-disable prettier/prettier */
import React from 'react';
import { rst } from 'rt-state';
import { isMobileDevice } from '../../commons/utils';

import { PriceMapStatus } from './support/DataModels';

import { PriceMapTitle } from './Layers/PriceMapTitle';
import { SuburbSearchAutoComplete } from './Layers/SuburbSearchInput';

import { PriceMapContainer } from './MapContainer';
import { Filter_InfoPanel } from './Layers/Filter_InfoPanel';
import { Box, LinearProgress, makeStyles, Modal } from '@material-ui/core';
import { EventBus, EVENT_NAMES } from '../../commons/EventBus';
import { EmptySelectError } from './Layers/EmptySelectError';
import { MapGlobalVars, MapInitParam, PRICE_MAP_STATUS } from './support/PriceMapConsts';

import { ErrorBox } from './components/ErrorBox';
import { MapControlLayer } from './Layers/MapControlLayer';
import { recordPageView } from './../../commons/ga/ga';
import { ROUTES } from '../../routes';
import { BackdropWithMessage } from './components/BackdropWithMessage';
import { MapCatagory } from './Layers/mapCatagory';
import { UserAuthButton } from '../auth/UserAuthButton';
import { Tip } from '../../components/Tip';
import { FloatTip } from '../../components/FloatingTip';
import { SecurityProvider, storage } from '../../commons';
import { AppProvider } from '../../commons/appProvider';
import { EnlargeStreetView } from './components/EnlargeStreetView';
/** Overrid the console log and console error */
{
    // var console: any = {};
    // console.log = function () {};
    // console.error = function () {};
    // console.warn = function () {};
    // console.group = function () {};
    // window.console = console;
}

recordPageView();
const useStyles = makeStyles((theme) => {
    return {
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        loginInMap: {
            position: 'absolute',
            top: 5,
            right: 30,
            [theme.breakpoints.down('sm')]: {
                top: 2,
                right: 10,
                // marginRight: '-10px',
                padding: '0',
                '& svg': {
                    width: '25px',
                },
                '& button': {
                    padding: '1px 0px 3px 0px',
                    minWidth: '50px',
                },
            },
        },
    };
});

const suburbSearchInputStyles = {
    background: 'white',
    padding: !isMobileDevice() ? '10px 17px 10px 17px' : '',
    borderRadius: '8px 8px 0px 0px',

    inputStyles: {
        background: '#f7f7f7',
        height: 40,
        fontSize: isMobileDevice() ? 12 : 14,
        placholder: 'Search',
        borderRadius: 19,
        paddingLeft: 14,
        paddingRight: 14,
    },
};
export const PriceMap = rst.create<{ fromIndex?: boolean }>((ctx) => {
    const appProvider = AppProvider.use();
    const securityProvider = SecurityProvider.use();
    if (ctx.props.fromIndex) {
        MapInitParam.center = [-33.822988734416285, 151.06201171875003];
        MapInitParam.zoom = 12;
    }

    // if (
    //     !securityProvider.getUserProfile().userStatus &&
    //     appProvider.state.authDialog !== 'login' &&
    //     appProvider.state.authDialog !== 'signup'
    // ) {
    //     setTimeout(() => {
    //         setInterval(() => {
    //             appProvider.state.authDialog = 'login';
    //         }, 1000);
    //     }, 30 * 1000);
    // }

    const state = rst.stateS<PriceMapStatus>({
        zoom: 10,
        showingProgressBar: false,
        showingEmptyError: false,
        errorTitle: '',
        errorSubTitle: '',
        showNearbyTranscationChart: false,
        showSearchInput: false,
    });
    const showAuthButton = rst.stateS(true);

    // EventBus.on(EVENT_NAMES.EVENT_AVOID_OVERLAY, (evt) => {
    //     showAuthButton.value = evt.showIcon;
    //     showAuthButton.forceUpdate;
    // });

    function mapZoomHandler(zoom) {
        state.value.zoom = zoom;

        state.forceUpdate();
    }
    EventBus.on(EVENT_NAMES.EVENT_TOGGLE_MAP_PROGRESSBAR, (evt) => {
        state.value.showingProgressBar = evt.show;
        state.forceUpdate();
    });
    EventBus.on(EVENT_NAMES.EVENT_SHOW_ERROR_MESSAGE, (evt) => {
        state.value.showingEmptyError = evt.show;
        state.value.errorTitle = evt.title;
        state.value.errorSubTitle = evt.subTitle;

        state.forceUpdate();
    });

    EventBus.on(EVENT_NAMES.EVENT_SHOW_SEARCH_INPUT, (evt) => {
        state.value.showSearchInput = evt.showSearchInput;
        state.forceUpdate();
        setTimeout(() => {
            document.getElementById('searchInputElement').focus();
        }, 500);
    });
    function searchSuburbEvt(suburbId, suburbName) {
        if (suburbId) {
            window.location.href = `${ROUTES.PriceMap.path}?suburbId=${suburbId}&suburbName=${suburbName}`;
        }
    }

    function searchPropertyItem(propId?, suburbId?, suburbName?) {
        if (propId) {
            state.value.showSearchInput = false;
            EventBus.dispatch(EVENT_NAMES.EVENT_SEARCHED_PROP, { propId: propId });
        }
    }

    // const presentTip = () => {
    //     storage.get('token').then((data) => {
    //         if (data) {
    //         } else {
    //             EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_FLOAT_MESSAGE, {
    //                 open: true,
    //                 title: 'START_FREE_TRIAL',
    //                 content: 'FREE_TRIAL_MSG',
    //                 confirmEvt: () => {
    //                     // window.open('/joinMember', '_self');
    //                     appProvider.state.authDialog = 'signup';
    //                 },
    //             });
    //         }
    //     });
    // };

    return (props) => {
        const classes = useStyles();
        // presentTip();

        const getMapController = () => {
            if (props.fromIndex) {
                return <MapControlLayer fromIndex={true}></MapControlLayer>;
            } else {
                return null;
            }
        };
        return (
            <div>
                <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 100001, width: '100%' }}>
                    {state.value.showingProgressBar ? <LinearProgress /> : null}
                </div>

                <div style={{ display: state.value.showSearchInput ? 'none' : 'block' }}>
                    <PriceMapContainer zoomHandler={mapZoomHandler} fromIndex={props.fromIndex}></PriceMapContainer>

                    <PriceMapTitle mapZoom={state.value.zoom} fromIndex={props.fromIndex}></PriceMapTitle>

                    <MapCatagory fromIndex={props.fromIndex}></MapCatagory>
                    {props.fromIndex ? null : (
                        <Box className={classes.loginInMap} pl={2}>
                            {showAuthButton.value ? <UserAuthButton /> : null}
                        </Box>
                    )}
                    {props.fromIndex ? null : <Filter_InfoPanel></Filter_InfoPanel>}
                    {getMapController()}
                </div>

                <div style={{ display: state.value.showSearchInput ? 'block' : 'none' }}>
                    <SuburbSearchAutoComplete
                        cssStyle={suburbSearchInputStyles}
                        searchAddress={true}
                        caller=""
                        foldEvent={searchSuburbEvt}
                        showCancel={true}
                        propertySelectedEvent={searchPropertyItem}
                        searchSuburb={true}></SuburbSearchAutoComplete>
                </div>
                <Modal
                    className={classes.modal}
                    style={{ zIndex: 99999 }}
                    open={state.value.showingEmptyError}
                    onClose={() => {}}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description">
                    <EmptySelectError
                        title={state.value.errorTitle}
                        subTitle={state.value.errorSubTitle}></EmptySelectError>
                </Modal>

                <ErrorBox></ErrorBox>

                <BackdropWithMessage></BackdropWithMessage>

                <Tip></Tip>

                {/* <FloatTip position={'BR'}></FloatTip> */}

                <EnlargeStreetView></EnlargeStreetView>
            </div>
        );
    };
});

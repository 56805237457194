import { Hidden, makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { SecurityProvider, storage, TR } from '../../commons';
import { NewButton } from './components/NewButton';
import { NewHomeImg } from './components/02NewHomeImg';
import { NewHomeMidImg } from './components/06NewHomeMidImg';
import { NewHomePageHead } from './components/01NewHomePageHead';
import { NewHomeData } from './components/07NewHomeData';
import { NewHomeContent } from './components/05NewHomeContent';
import { NewHomeAnswer } from './components/08NewHomeAnswer';
import { NewHomeSSPT } from './components/04NewHomeSSPT';
import { NewHomeFooter } from './components/11NewHomeFooter';
import { NewHomeAbout } from './components/09NewHomeAbout';
import { NewHomeAbout_rstState } from './components/09NewHomeAbout_rstState';
import { Tip } from '../../components/Tip';
import { getUserProfile } from '../../services/auth';
import { Permission } from '../../commons/security/authz';
import { whichDayForTrial } from '../../services/userCenter';
import { FloatTip } from '../../components/FloatingTip';
import { EventBus, EVENT_NAMES } from '../../commons/EventBus';
import moment from 'moment';
import { AppProvider } from '../../commons/appProvider';
import { NewHomeFooterForMobile } from './components/11NewHomeFooterForMobile';
import { VideoPlayer } from './videoPlayer';
import { NewHomeThreePic } from './components/04NewHomeThreePic';
import * as locales from '@material-ui/core/locale';
import { NewNewHomeContent } from './components/05NewNewHomeContent';
import { Lockdown } from './temporary/Lockdown';
import { FunctionChooser } from '../Home/FunctionChooser';
import { isMobileDevice } from '../../commons/utils';
import { TipAfterSignup } from '../mobile/member/signUp/TipAfterSignup';
import { NewHomeTransactionCard } from './components/12NewHomeTransactionCard';
import { PriceIncRanking } from './components/PriceIncRanking';
import { BlogAndLive } from './components/blogAndLive';
import { NewHomeQRCode } from './components/13NewHomeQRCode';

const useStyles = makeStyles((theme) => ({
    homeImg: {
        background: `url(${process.env.PUBLIC_URL}/static/images/newBanner.png)`,
        // background: 'rgb(48 120 191)',
        maxWidth: '1920px',
        // [theme.breakpoints.down('sm')]: {
        //     width: '100vw',
        //     height: '60vh',
        // },
        height: '450px',
        margin: '0 auto',
        position: 'relative',
    },

    homeImgContent: {
        width: '1200px',
        margin: '0 auto',
    },
    homeImgText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '757px',
        '& h1': {
            fontSize: '36px',
            margin: '70px 0px 10px 0px',
        },
        '& p': {
            fontSize: '20px',
            lineHeight: '28px',
            margin: '0px 0px 20px 0px',
        },
    },
    homeImgButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    space: {
        height: '80px',
        [theme.breakpoints.down('sm')]: {
            height: '62px',
        },
    },
}));
export const HomePage = rst.create((ctx) => {
    if (isMobileDevice()) {
        window.location.href = 'mobileHome';
    }
    const openVideo = rst.stateS(false);
    const videoNo = rst.stateS(1);
    const appProvider = AppProvider.use();
    const securityProvider = SecurityProvider.use();

    const showPdf = () => {
        window.open(
            appProvider.getLocale() === locales.zhCN
                ? `${process.env.PUBLIC_URL}/assets/help/HELP-ZH.html`
                : `${process.env.PUBLIC_URL}/assets/help/HELP-EN.html`,
            '_blank',
        );
    };
    const closeVideoEvt = () => {
        openVideo.value = false;
        openVideo.forceUpdate();
    };

    EventBus.on(EVENT_NAMES.EVENT_SHOW_VIDEO_PLAYER, (no) => {
        videoNo.value = no;
        openVideo.value = true;
        openVideo.forceUpdate();
    });
    const presentTip = () => {
        storage.get('token').then((data) => {
            if (data) {
                getUserProfile().then(
                    (userProfile: any) => {
                        console.log(userProfile);
                        const expireTime = userProfile.roles['2'] || userProfile.roles['4'];
                        const daysExprire = Math.floor((expireTime - moment().valueOf()) / (24 * 60 * 60 * 1000));
                        console.log('dayExpire is ' + daysExprire);
                        // if user has expired

                        if (expireTime < moment().valueOf()) {
                            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_FLOAT_MESSAGE, {
                                open: true,
                                title: 'CHOOSE_YOUR_PLAN',
                                confirmEvt: () => {
                                    window.open('/joinMember', '_self');
                                },
                                content: userProfile.roles['2'] ? 'CHOOSE_TRIAL_EXPIRE_MSG' : 'MEMBER_EXPIRE',
                            });
                        } else {
                            if (userProfile.roles['2'] && !userProfile.roles['4']) {
                                const daysAfterRegiForTrial = whichDayForTrial(userProfile.createTime);
                                console.log('current is ' + daysAfterRegiForTrial);
                                if (daysAfterRegiForTrial >= 2 && daysAfterRegiForTrial <= 3) {
                                    EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
                                        open: true,
                                        title: 'USEFUL_TIP',
                                        confirmEvt: daysAfterRegiForTrial == 2 ? showPdf : null,
                                        confirmButtonText: 'CHECK_GLOSSARY',
                                        cancelButtonText: 'GOT_IT',
                                        content:
                                            daysAfterRegiForTrial == 2 ? 'SECOND_DAY_TOOLTIP' : 'THIRD_DAY_TOOLTIP',
                                    });
                                }

                                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_FLOAT_MESSAGE, {
                                    open: true,
                                    title: 'CHOOSE_YOUR_PLAN',
                                    content: daysExprire == 0 ? 'LAST_DAY_TRIAL' : 'CHOOSE_YOUR_PLAN_MSG',
                                    confirmEvt: () => {
                                        window.open('/joinMember', '_self');
                                    },
                                    contentParams: { remainDays: daysExprire },
                                });
                            }

                            if (userProfile.roles['4']) {
                            }
                        }
                    },
                    (error) => {
                        EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_FLOAT_MESSAGE, {
                            open: true,
                            title: 'START_FREE_TRIAL',
                            content: 'FREE_TRIAL_MSG',
                            confirmEvt: () => {
                                window.open('/joinMember', '_self');
                            },
                        });
                    },
                );
            } else {
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_FLOAT_MESSAGE, {
                    open: true,
                    title: 'START_FREE_TRIAL',
                    content: 'FREE_TRIAL_MSG',
                    confirmEvt: () => {
                        // window.open('/joinMember', '_self');
                        appProvider.state.authDialog = 'login';
                    },
                });
            }
        });
    };

    return (props) => {
        const classes = useStyles();
        presentTip();
        return (
            <div>
                <Tip></Tip>
                <TipAfterSignup></TipAfterSignup>
                <div style={{ position: 'fixed', zIndex: 100 }}>
                    <NewHomePageHead />
                </div>
                <div className={classes.space}></div>
                <NewHomeImg />
                {/* <Lockdown></Lockdown> */}
                <NewHomeQRCode />
                <PriceIncRanking />

                <NewHomeThreePic />

                <NewNewHomeContent />
                {/* <NewHomeContent /> */}
                <FunctionChooser></FunctionChooser>
                <NewHomeMidImg />

                <NewHomeTransactionCard />

                <NewHomeData />
                <BlogAndLive></BlogAndLive>
                <NewHomeAnswer />
                <NewHomeAbout />
                {/* <NewHomeAbout_rstState /> */}
                <Hidden smDown>
                    <NewHomeFooter />
                </Hidden>
                <Hidden mdUp>
                    <NewHomeFooterForMobile />
                </Hidden>

                {/* <FloatTip></FloatTip> */}
                {openVideo.value ? (
                    <VideoPlayer
                        open={openVideo.value}
                        videoNo={videoNo.value}
                        closeVideoEvt={closeVideoEvt}></VideoPlayer>
                ) : null}
            </div>
        );
    };
});

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { CardWithHeader } from '../components/CardWithHeader';
import * as locales from '@material-ui/core/locale';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
            paddingBottom: '0px',
        },
    };
});

export const CardAbout = rst.create((ctx) => {
    const appProvider = AppProvider.use();

    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader>
                    {appProvider.getLocale() === locales.zhCN ? null : (
                        <p>
                            <TR name="Card_AboutUsContent_P1" />
                        </p>
                    )}
                    <p>
                        <TR name="Card_AboutUsContent_P2" />
                    </p>
                    <p>
                        <TR name="Card_AboutUsContent_P3" />
                    </p>
                </CardWithHeader>
            </div>
        );
    };
});

import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { ReportMap } from '../components/reportMap/ReportMap';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 0',
            '& span': {
                fontSize: '14px',
            },
        },
    };
});

export const Card13 = rst.create<{ prop: any; propQL: any; mobile? }>((ctx) => {
    console.log(ctx.props.propQL);
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader header={props.mobile ? '' : 'Card13_header'}>
                    <div style={{ fontSize: '14px' }}>
                        <span>
                            <TR name={'Card13_chart'} />
                        </span>
                    </div>
                    <div className={classes.part1}>
                        <ReportMap
                            propCenter={{
                                lat: props.prop.allFeatures.prop_y,
                                lon: props.prop.allFeatures.prop_x,
                            }}
                            drawingData={props.propQL.property.hazard}
                            drawingObject={'2'}></ReportMap>
                    </div>

                    <div style={{ display: 'flex' }}>
                        <img src="assets/icons/cemetery-ico.svg"></img> <TR name="CEMETERY"></TR>
                        <img src="assets/icons/gas station-ico.svg"></img> <TR name="GAS_STATION"></TR>
                        <img src="assets/icons/base statiom-ico.svg"></img> <TR name="BASE_STATIOM"></TR>
                    </div>
                </CardWithHeader>
            </div>
        );
    };
});

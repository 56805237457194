import { Chip, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TR } from '../../../../commons';
import { Margin } from '../../../../components/margin';
import { onDefaultChange } from '../../../../components/form';
import { api_querySuburbs } from '../../../../services/property';
import { getSuburbNameByIdFromLocalData } from '../../../../services/suburb';
const useStyles = makeStyles({
    inputComb: {
        background: '#eff1f1',
        borderRadius: '4px',
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        paddingRight: '12px',
    },
    inputLabel: {
        fontSize: '14px',
        color: '#000000',
        fontWeight: 400,
        padding: '11px',
    },
    inputControl: {
        background: '#eff1f1 !important',
        width: '100%',
        height: 40,
        border: 'none',

        outline: 'none',
        fontWeight: 400,
        fontSize: '16px',
    },
    suburbChip: {
        background: 'white',
        borderRadius: '10px',
        padding: '5px 10px',
        display: 'flex',
        alignItems: 'center',
    },
    suburbPanel: {
        display: 'flex',
        gap: 5,
        maxWidth: '70%',
        flexWrap: 'wrap',
    },
    suburbSelection: {
        padding: '5px 10px ',
        border: '1px solid #999999',
    },
    suburbSelectionItem: {
        paddingTop: '5px',
    },
});
export const InputSignUp = (props: any) => {
    const {
        label,
        placeholder,
        form,
        name: fieldName,
        formErrors,
        suburbChoose,
        tipMsg,
        existingSuburbs,
        ...otherProps
    } = props;

    const { values } = form;
    const [showError, setShowError] = useState(false);
    const [searchedSuburbList, setSearchedSuburbList] = useState([]);
    const [selectedSuburbs, setSelectedSuburbs] = useState([]);
    const inputRef = useRef();

    const classes = useStyles();
    const { t } = useTranslation();
    const [fieldValue, setFieldValue] = useState(values[fieldName]);
    console.log('has errors : ' + JSON.stringify(formErrors));

    useEffect(() => {
        if (existingSuburbs) {
            console.log('existing suburbs', existingSuburbs);
            const currentSurburbs = [];
            existingSuburbs.forEach(async (loopS) => {
                const suburbName = await getSuburbNameByIdFromLocalData(loopS);
                currentSurburbs.push({
                    localityId: loopS,
                    localityName: suburbName,
                });
            });

            console.log('current suburbs');
            const localityIds = currentSurburbs.map((s) => {
                return s.localityId;
            });
            console.log(localityIds);
            form.setFieldValue('selectedSuburbs', existingSuburbs);
            setSelectedSuburbs(currentSurburbs);
        }
    }, []);

    const fieldChange = async (e) => {
        console.log(e.target.value);
        if (suburbChoose && selectedSuburbs.length >= 3) {
            return;
        }
        setFieldValue(e.target.value);
        values[fieldName] = e.target.value;
        console.log(form);
        if (suburbChoose) {
            const searchedList: any = await api_querySuburbs(e.target.value);
            const filteredList = searchedList.filter((suburb) => {
                const selectedIds = selectedSuburbs.map((s) => {
                    return s.localityId;
                });
                return selectedIds.indexOf(suburb.localityId) == -1;
            });
            console.log(searchedList);
            setSearchedSuburbList(filteredList);
        } else {
        }
    };
    const chooseLocality = async (locality) => {
        console.log(locality);
        const currentSuburbs = selectedSuburbs;
        currentSuburbs.push(locality);
        console.log(currentSuburbs);
        setSelectedSuburbs(currentSuburbs);
        setSearchedSuburbList([]);
        setFieldValue('');
        const currentRef: any = inputRef.current;
        if (currentRef) {
            currentRef.focus();
        }
        await form.setFieldValue(
            'selectedSuburbs',
            currentSuburbs.map((s) => {
                return '' + s.localityId;
            }),
        );

        console.log(form);
    };
    const presentSuburbs = () => {
        console.log('selected suburbs ');
        console.log(selectedSuburbs);
        const elements = [];
        selectedSuburbs.forEach((suburb) => {
            console.log(suburb);
            elements.push(
                <Chip
                    style={{ fontSize: 10 }}
                    onDelete={() => {
                        removeSuburb(suburb.localityId);
                    }}
                    label={suburb.localityName}></Chip>,
            );
        });

        return elements;
    };
    const removeSuburb = async (localityId) => {
        const currentSelects = selectedSuburbs;
        const newSuburbs = currentSelects.filter((suburb) => {
            return suburb.localityId !== localityId;
        });
        setSelectedSuburbs(newSuburbs);
        await form.setFieldValue(
            'selectedSuburbs',
            newSuburbs.map((s) => {
                return s.localityId;
            }),
        );
    };

    return (
        <div style={{ width: '100%' }}>
            <div
                className={classes.inputComb}
                style={{
                    border: formErrors && formErrors[fieldName] ? '1px red solid' : '',
                    width: props.type == 'hidden' ? 0 : 'auto',
                    height: props.type == 'hidden' ? 0 : 'auto',
                }}>
                <div className={classes.inputLabel}>
                    <TR name={label}></TR>{' '}
                </div>

                {suburbChoose && <div className={classes.suburbPanel}>{presentSuburbs()}</div>}

                <div style={{ flex: 1 }}>
                    <input
                        className={classes.inputControl}
                        name={fieldName}
                        onKeyDown={() => {
                            form.clearFieldError(fieldName);
                        }}
                        type="text"
                        value={fieldValue}
                        placeholder={suburbChoose && selectedSuburbs.length >= 1 ? '' : t(placeholder)}
                        {...otherProps}
                        autoComplete="new-password"
                        onChange={fieldChange}></input>
                </div>
            </div>
            {fieldValue !== '' && suburbChoose && searchedSuburbList.length > 0 && (
                <div className={classes.suburbSelection}>
                    {searchedSuburbList.map((suburb) => {
                        return (
                            <div
                                className={classes.suburbSelectionItem}
                                onClick={() => {
                                    chooseLocality(suburb);
                                }}>
                                {suburb.localityName}
                            </div>
                        );
                    })}
                </div>
            )}
            {((formErrors && formErrors[fieldName]) || tipMsg) && (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', color: 'red' }}>
                    <TR name={tipMsg || formErrors[fieldName].msg || formErrors[fieldName]}></TR>
                </div>
            )}
        </div>
    );
};

import { makeStyles, Tooltip } from '@material-ui/core';
import ReactDOM from 'react-dom';
import React from 'react';
import { rst, state } from 'rt-state';
import { TR } from '../../../../../commons';
import {
    extractFirstPrimarySchool,
    extractFirstSecondarySchool,
    getClosedTrainDistance,
    getTrains,
} from '../../../support/propertyFeatureHelper';
import { Chip } from '../../../../../components/Chip';
import { isMobileDevice } from '../../../../../commons/utils';
import { getPrimarySchoolDesc, getPrimarySchoolDescByScore, getSecondarySchoolDesc } from '../../../support/common';
import { withStyles } from '@material-ui/styles';
import { MapGlobalVars } from '../../../support/PriceMapConsts';

const useStyles = makeStyles((theme) => {
    return {
        propAddtionInfo: {
            fontSize: 13,
            color: '#999999',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginTop: 15,
            padding: '0px 10px',
        },
        propAddtionInfoItem: {
            display: 'flex',
            alignItems: 'center',
            fontSize: isMobileDevice() ? 10 : 14,
        },

        EnglishPropAddtionInfoItem: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            minWidth: 90,
            fontSize: isMobileDevice() ? 10 : 14,
        },
    };
});
export const SchoolOrStationSimple = rst.create<{ prop: any; onReport? }>((ctx) => {
    return (props) => {
        console.log(props.prop);
        const classes = useStyles();
        return (
            <>
                <div className={classes.propAddtionInfo}>
                    <div
                        className={
                            MapGlobalVars.currentUserLan == 'zh'
                                ? classes.propAddtionInfoItem
                                : classes.EnglishPropAddtionInfoItem
                        }>
                        <TR name="Primary School"></TR>

                        <div style={{ marginLeft: 10 }}></div>
                        {props.onReport ? (
                            <Chip
                                fontSize={10}
                                label={extractFirstPrimarySchool(props.prop.allFeatures).school_name}></Chip>
                        ) : (
                            <Chip
                                label={getPrimarySchoolDescByScore(
                                    extractFirstPrimarySchool(props.prop.allFeatures)[
                                        props.onReport ? 'school_name' : 'score'
                                    ],
                                )}
                            />
                        )}
                    </div>
                    <div
                        className={
                            MapGlobalVars.currentUserLan == 'zh'
                                ? classes.propAddtionInfoItem
                                : classes.EnglishPropAddtionInfoItem
                        }>
                        <TR name="Secondary School"></TR>
                        <div style={{ marginLeft: 10 }}></div>

                        {props.onReport ? (
                            <Chip
                                fontSize={10}
                                label={extractFirstSecondarySchool(props.prop.allFeatures).school_name}></Chip>
                        ) : (
                            <Chip
                                label={getSecondarySchoolDesc(
                                    extractFirstSecondarySchool(props.prop.allFeatures).score,
                                )}
                            />
                        )}
                    </div>
                    <div
                        className={
                            MapGlobalVars.currentUserLan == 'zh'
                                ? classes.propAddtionInfoItem
                                : classes.EnglishPropAddtionInfoItem
                        }>
                        <TR name="TRAIN_STATION"></TR>
                        <div style={{ marginLeft: 10 }}></div>
                        {props.onReport ? (
                            <Chip
                                fontSize={10}
                                label={
                                    getTrains(props.prop.allFeatures.closest_station)[0]['name'] +
                                    '(' +
                                    getTrains(props.prop.allFeatures.closest_station)[0]['chips'][0] +
                                    ')'
                                }
                            />
                        ) : (
                            <Chip label={getClosedTrainDistance(props.prop.allFeatures)} />
                        )}
                    </div>
                </div>
                {/* <div className={classes.propAddtionInfo}>
                    <div
                        className={
                            MapGlobalVars.currentUserLan == 'zh'
                                ? classes.propAddtionInfoItem
                                : classes.EnglishPropAddtionInfoItem
                        }>
                        <div style={{ width: '30px' }}>
                            <TR name={'Zoning'}></TR>
                        </div>
                        <div style={{ marginLeft: 10 }}></div>
                        <Chip label={props.prop.zoningStr}></Chip>
                    </div>
                    <div
                        className={
                            MapGlobalVars.currentUserLan == 'zh'
                                ? classes.propAddtionInfoItem
                                : classes.EnglishPropAddtionInfoItem
                        }>
                        <div style={{ width: '30px' }}>
                            <TR name={'PriceToStreet'}></TR>
                        </div>
                        <div style={{ marginLeft: 10 }}></div>
                        <Chip
                            label={
                                props.prop.streetPct == 0 ? '-' : (props.prop.streetPct * 100).toFixed(1) + '%'
                            }></Chip>
                    </div>
                    <div
                        className={
                            MapGlobalVars.currentUserLan == 'zh'
                                ? classes.propAddtionInfoItem
                                : classes.EnglishPropAddtionInfoItem
                        }>
                        <div style={{ width: '30px' }}>
                            <TR name={'StreetHouseChangeHand'}></TR>
                        </div>
                        <div style={{ marginLeft: 10 }}></div>
                        <Chip
                            label={
                                (props.prop.streetChangeHandRate == 0
                                    ? '-'
                                    : (props.prop.streetChangeHandRate * 100).toFixed(1) + '%') +
                                '(' +
                                (props.prop.suburbChangeHandRate == 0
                                    ? '-'
                                    : (props.prop.suburbChangeHandRate * 100).toFixed(1) + '%') +
                                ')'
                            }></Chip>
                    </div>
                </div> */}
            </>
        );
    };
});

const ScrollItemTooltip = withStyles({
    tooltip: {
        color: '#495b6b',
        backgroundColor: 'white',
        fontSize: '12px',
    },
})(Tooltip);

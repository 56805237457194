import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, makeStyles } from '@material-ui/core';
import { clsx, SecurityProvider, TR, TRHtml } from '../../commons';
export const Confirmation = (props: any) => {
    const { open, title, content, confirmEvt, cancelEvt, confirmText, cancelText, contentParams } = props;

    return (
        <Dialog open={open}>
            <DialogTitle>
                <TR name={title}></TR>{' '}
            </DialogTitle>
            <DialogTitle>
                <TRHtml html={content} params={contentParams}></TRHtml>
            </DialogTitle>
            <DialogActions>
                <Button
                    onClick={() => {
                        confirmEvt && confirmEvt();
                    }}>
                    <TR name={confirmText || 'OK'}></TR>
                </Button>
                <Button
                    onClick={() => {
                        cancelEvt && cancelEvt();
                    }}>
                    <TR name={cancelText || 'CANCEL'}></TR>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

import { makeStyles, Theme } from '@material-ui/core/styles';
import { rst } from 'rt-state';
import { Box, Divider, Grid, Link, ListItemIcon } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { clsx, formatDate, formatDateForEn, RRLink, SecurityProvider, TR } from '../../../commons';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { XAuthDialog } from '../../../components/Dialog/XAuthDialog';
import { UserInfoComp, UserInfoPage } from '../UserInfo';
import { ChangePasswordComp } from '../ChangePassword';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import StarsIcon from '@material-ui/icons/Stars';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import { ROUTES } from '../../../routes';
import { LoginOrSignupButton } from './LoginOrSignupButton';
import { AppProvider } from '../../../commons/appProvider';
import { Permission } from '../../../commons/security/authz';
import { addDays, differenceInDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router-dom';
import { addTrial } from '../../../services/auth';
import * as locales from '@material-ui/core/locale';
import { TrialButton } from './TrialButton';
import { MemberInfo } from '../../mobile/member/memberInfo';
import { VariousPopup } from '../../mobile/member/signUp/TipAfterSignup';
import { MemberIcon } from '../../mobile/member/commonComponents/memberIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '160px',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            width: '50px',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
}));

export const UserAuthButton = rst.create((ctx) => {
    const securityProvider = SecurityProvider.use();
    return (props) => {
        const classes = useStyles();
        if (securityProvider.getUserProfile().userStatus) {
            return <UserAvatarButton />;
        }
        return (
            <div className={classes.root}>
                <LoginOrSignupButton />
            </div>
        );
    };
});

const useCenterStyles = makeStyles((theme: Theme) => ({
    // root: {
    //     width: '100%',
    //     maxWidth: 360,
    //     backgroundColor: theme.palette.background.paper,
    //     padding: '2rem',
    //     margin: '2rem',
    // },
    nested: {
        paddingLeft: theme.spacing(3),
        padding: '0.8rem 0rem',
        margin: 'auto',
        width: '300px',
    },
    icon: {
        minWidth: '40px',
    },
    divider: {
        margin: '0px 20px',
    },
    loginIcon: {
        width: '50px',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
        },
    },
}));

export const UserAvatarButton = rst.create((ctx) => {
    const provider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const anchorState = rst.stateS(null);
    const isOpen = rst.stateS<'info' | 'password'>(null);
    const isVIP = rst.stateS(false);
    const { state } = appProvider;
    const popType = rst.stateS(-1);
    const showWelcomeAreaMember = rst.stateS(false);

    function handleClick(event) {
        anchorState.value = event.currentTarget;
    }

    function handleMenuClose(choice: 'info' | 'password' | 'logout') {
        anchorState.value = null;
        switch (choice) {
            case 'info':
                if (provider.getUserProfile().userStatus == '3') {
                    popType.value = 30;
                    popType.forceUpdate();
                }
                if (provider.getUserProfile().userStatus == '1') {
                    popType.value = 10;
                    popType.forceUpdate();
                }
                if (provider.getUserProfile().userStatus == '2') {
                    popType.value = 20;

                    popType.forceUpdate();
                }
                if (provider.getUserProfile().userStatus == '5' || provider.getUserProfile().userStatus == '4') {
                    popType.value = 50;

                    popType.forceUpdate();
                }
                if (provider.getUserProfile().userStatus == '6' || provider.getUserProfile().userStatus == '7') {
                    popType.value = 60;
                    popType.forceUpdate();
                }

                isOpen.value = choice;
                break;
            case 'password':
                isOpen.value = choice;
                break;
            case 'logout':
                console.log('logout');
                provider.logout();
                state.isLogOut = true;
            // window.open('/', '_self');
        }
    }
    function onDialogClose() {
        isOpen.value = null;
    }

    function getInfoTexts(t: TFunction) {
        const expireTime = provider.getExpireTime(Permission.PROPERTY_BUYER);
        const { reportCountProperty } = provider.getUserProfile() ?? {};
        const infoTexts = [];
        const now = new Date();
        if (expireTime > now && expireTime < addDays(now, 10)) {
            const days = differenceInDays(expireTime, now);
            infoTexts.push(t('Membership_will_expire_in_X_days', { day: days }));
        }
        if (reportCountProperty >= 0 && reportCountProperty < 10) {
            infoTexts.push(<br key={'br'} />);
            infoTexts.push(t('Only {{reportCountProperty}} reports left', { reportCountProperty }));
        }
        console.log(infoTexts, provider.getUserProfile());
        return infoTexts;
    }

    return (props) => {
        const { t } = useTranslation();
        const infoTexts = getInfoTexts(t);
        const classes = useCenterStyles();
        return (
            <Grid container justify={'center'}>
                <IconButton onClick={handleClick}>
                    {/* <img
                        style={{
                            border: infoTexts.length > 0 ? '5px solid red' : null,
                            // borderRadius: 80,
                            height: '21px',
                            width: '20px',
                        }}
                        className={infoTexts.length > 0 ? clsx(classes.loginIcon, 'blink') : classes.loginIcon}
                        src={`${process.env.PUBLIC_URL}/assets/icons/myselficon.svg`}
                    /> */}
                    <MemberIcon onMenuBar></MemberIcon>
                </IconButton>

                <Menu
                    anchorEl={anchorState.value}
                    keepMounted
                    open={Boolean(anchorState.value)}
                    onClose={handleMenuClose}>
                    <Box>
                        <MenuHeader infoTexts={infoTexts} />
                    </Box>
                    {/* <Box>
                        <MemberShipItem />
                    </Box> */}
                    <Link
                        target="_blank"
                        component={RRLink}
                        to={ROUTES.MyFavoritePage.path}
                        color="initial"
                        style={{ textDecoration: 'none' }}>
                        <MenuItem className={classes.nested}>
                            <ListItemIcon className={classes.icon}>
                                <StarsIcon />
                            </ListItemIcon>
                            <TR name={'My Favorite'} />
                        </MenuItem>
                    </Link>
                    <Divider className={classes.divider} />
                    <Link
                        target="_blank"
                        component={RRLink}
                        to={ROUTES.SearchHistoryPage.path}
                        color="initial"
                        style={{ textDecoration: 'none' }}>
                        <MenuItem className={classes.nested}>
                            <ListItemIcon className={classes.icon}>
                                <HistoryOutlinedIcon />
                            </ListItemIcon>
                            <TR name={'Search History'} />
                        </MenuItem>
                    </Link>
                    <Divider className={classes.divider} />
                    <Link
                        target="_blank"
                        component={RRLink}
                        to={ROUTES.PropertyReportsPage.path}
                        color="initial"
                        style={{ textDecoration: 'none' }}>
                        <MenuItem className={classes.nested}>
                            <ListItemIcon className={classes.icon}>
                                <HistoryOutlinedIcon />
                            </ListItemIcon>
                            <TR name={'Property Reports'} />
                        </MenuItem>
                    </Link>
                    {/* </MenuItem> */}
                    <Divider className={classes.divider} />
                    <MenuItem onClick={() => handleMenuClose('info')} className={classes.nested}>
                        <ListItemIcon className={classes.icon}>
                            <AccountCircleOutlinedIcon />
                        </ListItemIcon>

                        <TR name={'memberInfo'} />
                    </MenuItem>
                    <Divider className={classes.divider} />
                    <MenuItem onClick={() => handleMenuClose('password')} className={classes.nested}>
                        <ListItemIcon className={classes.icon}>
                            <LockOutlinedIcon />
                        </ListItemIcon>
                        <TR name={'User Information Setting'} />
                    </MenuItem>
                    <Divider className={classes.divider} />
                    <MenuItem onClick={() => handleMenuClose('logout')} className={classes.nested}>
                        <ListItemIcon className={classes.icon}>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <TR name={'Logout'} />
                    </MenuItem>
                </Menu>
                {rst.view(() => {
                    return (
                        <XAuthDialog open={isOpen.value === 'info'} onClose={onDialogClose}>
                            <MemberInfo
                                popType={popType.value}
                                show={true}
                                onClose={onDialogClose}
                                onSuccessAreaMember={() => {
                                    showWelcomeAreaMember.value = true;
                                }}></MemberInfo>
                        </XAuthDialog>
                    );
                })}
                {rst.view(() => {
                    return (
                        <XAuthDialog open={isOpen.value === 'password'} onClose={onDialogClose}>
                            <UserInfoComp onClose={onDialogClose} />
                        </XAuthDialog>
                    );
                })}

                <VariousPopup
                    popType={popType.value}
                    open={showWelcomeAreaMember.value}
                    messageTitleKey={'welcomeAsAreaMember'}
                    messageKey={'areaMemberBenefit'}
                    onClose={() => {
                        showWelcomeAreaMember.value = false;
                        showWelcomeAreaMember.forceUpdate();
                        onDialogClose();
                    }}></VariousPopup>
            </Grid>
        );
    };
});

const MenuHeader = rst.createS<{ infoTexts: string[] }>((props) => {
    const classes = useCenterStyles();
    const provider = SecurityProvider.use();
    const profile = provider.getUserProfile();
    return (
        <Box display={'flex'} m={1} style={{ outline: 'none' }} flexDirection={'column'}>
            <Box display={'flex'} m={1} style={{ outline: 'none' }}>
                <Box pl={2} display={'flex'} alignItems={'center'}>
                    <MemberIcon onMenuBar></MemberIcon>
                    {/* <img
                        className={classes.loginIcon}
                        src={`${process.env.PUBLIC_URL}/assets/icons/myselficon.svg`}
                        style={{ height: '21px', width: '20px' }}
                    /> */}
                </Box>
                <Box pl={2} flexGrow={1} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant={'h6'}>{profile?.nickname}</Typography>
                    <Typography variant={'body1'}>{profile?.email}</Typography>
                </Box>
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} pr={2}>
                <span style={{ color: 'red' }}>{props.infoTexts}</span>
            </Box>
        </Box>
    );
});

const MemberShipItem = rst.createS<{}>((props) => {
    const classes = useCenterStyles();
    const provider = SecurityProvider.use();
    const isBuyer = provider.hasPermission(Permission.PROPERTY_BUYER);
    const hasTried = provider.getUserProfile()?.hasTried ?? true;
    const expireTime = provider.getExpireTime(Permission.PROPERTY_BUYER);
    const history = useHistory();
    const appProvider = AppProvider.use();

    return (
        <MenuItem
            style={{ color: '#904918', background: 'linear-gradient(270deg,#fee7c9 98%, #fdce91 36%)' }}
            onClick={() => {
                history.push('/JoinMember');
            }}
            className={classes.nested}>
            <ListItemIcon className={classes.icon}>
                <img src="assets/images/svgs/member-ico.svg" width="18px" />
            </ListItemIcon>
            {isBuyer ? (
                appProvider.getLocale() === locales.zhCN ? (
                    <>
                        <TR name={'HomeOn Member, VALID TO '} /> {formatDate(expireTime)}
                    </>
                ) : (
                    <>
                        <TR name={'HomeOn Member, VALID TO '} /> {formatDateForEn(expireTime)}
                    </>
                )
            ) : (
                <TR name={'Upgrade'} />
            )}
            &nbsp;&nbsp;&nbsp;
            {!hasTried && !isBuyer ? (
                <Link
                    style={{ color: '#904918' }}
                    onClick={async () => {
                        const profile = await addTrial();
                        provider.setUserProfile(profile);
                    }}>
                    <TR name={'Apply for trial'} />
                </Link>
            ) : null}
        </MenuItem>
    );
});

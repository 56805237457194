import { XTableColumn } from '../../../../components/Table';
import { commonColumns, TitleAndColumns } from './common';
import { decimalToPct } from '../../../../commons/utils';
import React from 'react';

const neighbourSafetyColumns: XTableColumn[] = [
    ...commonColumns,
    { name: 'neighbourSafety', sort: true, align: 'center', label: '治安' },
];
const ChinesePercentageColumns: XTableColumn[] = [
    ...commonColumns,
    {
        name: 'localityChinese',
        sort: true,
        align: 'center',
        label: '华人占比',
        render: (row) => {
            return <span>{decimalToPct(row.localityChinese)}</span>;
        },
    },
];
const IndianPercentageColumns: XTableColumn[] = [
    ...commonColumns,
    {
        name: 'localityHinduism',
        sort: true,
        align: 'center',
        label: '印度教占比',
        render: (row) => {
            return <span>{decimalToPct(row.localityHinduism)}</span>;
        },
    },
];
const MuslimPercentageColumns: XTableColumn[] = [
    ...commonColumns,
    {
        name: 'localityIslam',
        sort: true,
        align: 'center',
        label: '伊斯兰教占比',
        render: (row) => {
            return <span>{decimalToPct(row.localityIslam)}</span>;
        },
    },
];
const tenantPercentageColumns: XTableColumn[] = [
    ...commonColumns,
    {
        name: 'localityRenter',
        sort: true,
        align: 'center',
        label: '租客占比',
        render: (row) => {
            return <span>{decimalToPct(row.localityRenter)}</span>;
        },
    },
];

export const residentTitleAndColumns: TitleAndColumns = {
    1: { title: '治安排行榜', columns: neighbourSafetyColumns },
    localityChinese: { title: '华人占比排行榜', columns: ChinesePercentageColumns },
    localityHinduism: { title: '印度教占比排行榜', columns: IndianPercentageColumns },
    localityIslam: { title: '伊斯兰教占比排行榜', columns: MuslimPercentageColumns },
    localityRenter: { title: '租客占比排行榜', columns: tenantPercentageColumns },
};

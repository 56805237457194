/* eslint-disable prettier/prettier */
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { clsx, TR } from '../../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../../commons/EventBus';
import { isMobileDevice } from '../../../../../commons/utils';

import {
  BedOptions,
  MapLevel,
  PropTypes,
  ProjectScales,
  BuildAges,
  MapGlobalVars,
} from '../../../support/PriceMapConsts';
import { BedButton } from '../../../components/BedButton';
import { ItemLabel } from '../../../components/ItemLabel';
import { SliderItem } from '../../../components/SliderItem';
import { SelectItem } from '../../../components/SelectItem';
import { PropertyPeriodChooser } from '../../PropertyPeriodChooser';
import { HelpComponent } from '../../../components/HelpComponent';
import { recordUserActionsToGA } from '../../../../../commons/ga/ga';
import { PAGE_ACTIONS } from '../../../../../commons/ga/gaConsts';

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    propertyTypeDiv: {
      display: 'flex',
      marginTop: 10,
    },
    bedOptionDiv: {
      display: 'flex',
    },

    mobile_propertyTypeTab: {
      borderRadius: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '3px 10px 3px 10px',
      fontSize: '13px',
      fontWeight: 700,
      color: '#999999',
    },
    mobile_propertyTypeTab_selected: {
      background: theme.palette.primary.main,
      color: '#fff',
    },
    pc_propertyTypeTab: {
      display: 'flex',
      cursor: 'pointer',
      fontSize: '16px',
      color: '#666666',
    },
    pc_proeprtyTypeTab_selected: {
      color: theme.palette.primary.main,
      fonWeight: '700',
      borderBottomWidth: '3px',
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: 'solid',
      paddingBottom: '2px',
    },
  };
});

export const PropertyFilterPanel = rst.create<{ handlePropType: (any) => void }>((ctx) => {
  const filter = rst.stateS({
    propertyType: 'House',
    bedOptions: ['total'],
    suburbId: '',
    suburbName: '',
    landSize: { min: 0, max: 0 },
    projectScale: 0,
    buildingAge: 0,
  });
  const mapZoom = rst.stateS(10);
  EventBus.on(EVENT_NAMES.EVENT_MAP_ZOOMED, (currentZoom) => {
    mapZoom.value = currentZoom;
  });
  EventBus.on(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, (evt) => {
    filter.value.suburbId = evt.suburbId;
    filter.value.suburbName = evt.suburbName;
  });

  const presentPropTypes = () => {
    console.log(filter);
    const changeTab = (type) => {
      recordUserActionsToGA(
        PAGE_ACTIONS.priceMap.label,
        PAGE_ACTIONS.priceMap.events.filterMapByProperty.description,
      );
      console.log(filter.value);
      filter.value.propertyType = type;
      console.log(type);
      ctx.props.handlePropType(type);
      filter.value.bedOptions = ['total'];
      EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_FILTER, filter.value);

      filter.forceUpdate();
    };
    const presentNode = [];
    PropTypes.map((loopPropType: any, idx) => {
      if (idx == 1) {
        presentNode.push(<div style={{ marginLeft: isMobileDevice() ? 10 : 40 }} />);
      }
      presentNode.push(
        <PropertyTypeTab
          helpKey={loopPropType.helpKey}
          value={loopPropType.type}
          label={loopPropType.label}
          selected={filter.value.propertyType == loopPropType.type}
          changeTab={changeTab}></PropertyTypeTab>,
      );
    });
    return presentNode;
  };

  return (props) => {
    const classes = useStyles();

    const presentBedOptions = () => {
      const bedClick = (clickedBed) => {
        filter.value.bedOptions = [clickedBed];
        filter.forceUpdate();
        EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_FILTER, filter.value);
      };
      const presentNode = [];
      BedOptions[filter.value.propertyType].forEach((loopOption, idx) => {
        if (idx >= 1) {
          presentNode.push(<div style={{ marginLeft: isMobileDevice() ? 11 : 13 }} />);
        }
        presentNode.push(
          <BedButton
            clickEvent={() => bedClick(loopOption.key)}
            label={loopOption.label}
            value={loopOption.key}
            selected={filter.value.bedOptions.indexOf(loopOption.key) > -1}></BedButton>,
        );
      });
      return presentNode;
    };

    const presentAdditionFilter = () => {
      const changeProjectScaleOption = (projectScale) => {
        filter.value.projectScale = projectScale;
        EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_FILTER, filter.value);
      };
      const changeBuildAgeOption = (buildAge) => {
        filter.value.buildingAge = buildAge;
        EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_FILTER, filter.value);
      };
      const changeLandSize = (landSizeRange) => {
        filter.value.landSize.min = landSizeRange[0];
        filter.value.landSize.max = landSizeRange[1] == 3000 ? 0 : landSizeRange[1];
        EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_FILTER, filter.value);
      };
      if (filter.value.propertyType == 'House') {
        return (
          <>
            <SliderItem
              valueRange={[0, 3000]}
              itemName={'LANDSIZE'}
              valueUnit={'SQM'}
              slideChangeEvent={changeLandSize}></SliderItem>
          </>
        );
      } else {
        return (
          <div style={{ display: 'flex' }}>
            <SelectItem
              width={116}
              itemName={'ProjectScale'}
              changeEvent={changeProjectScaleOption}
              options={ProjectScales}></SelectItem>
            <div style={{ width: 12 }}></div>
            <SelectItem
              width={96}
              itemName={'BuildingAge'}
              changeEvent={changeBuildAgeOption}
              options={BuildAges}></SelectItem>
          </div>
        );
      }
    };

    return (
      <>
        {' '}
        <div className={classes.propertyTypeDiv}>{presentPropTypes()}</div>
        <div style={{ marginTop: isMobileDevice() ? 9 : 16 }}></div>
        <div className={classes.bedOptionDiv}>{presentBedOptions()}</div>
        <div style={{ marginTop: isMobileDevice() ? 9 : 16 }}></div>
        {mapZoom.value >= MapLevel.propertyLevel.lower || MapGlobalVars.shouldStayOnPropertyLevel ? (
          <>
            {presentAdditionFilter()}
            <div style={{ marginTop: isMobileDevice() ? 9 : 16 }}></div>
            <div
              style={{
                marginTop: isMobileDevice ? 5 : 10,
                display: 'flex',
                width: '100%',
                justifyContent: isMobileDevice ? 'flex-start' : 'flex-end',
              }}>
              <ItemLabel style={{ minWidth: 80 }}>
                <TR name="SOLD_DATE"></TR>
              </ItemLabel>
              <PropertyPeriodChooser></PropertyPeriodChooser>
            </div>
          </>
        ) : null}
      </>
    );
  };
});

const PropertyTypeTab = rst.create<{
    label: string;
    value: string;
    selected?: boolean;
    changeTab?: any;
    helpKey: string;
}>((ctx) => {
  return (props) => {
    const classes = useStyles();
    const getContent = () => {};
    return (
      <div
        className={
          isMobileDevice()
            ? clsx(
              classes.mobile_propertyTypeTab,
              props.selected ? classes.mobile_propertyTypeTab_selected : '',
            )
            : clsx(classes.pc_propertyTypeTab, props.selected ? classes.pc_proeprtyTypeTab_selected : '')
        }>
        <div
          {...props}
          onClick={() => {
            props.changeTab(props.value);
            console.log(props.value);
          }}>
          <TR name={props.label} />
        </div>
        {/* <div style={{ marginLeft: '9px', cursor: 'pointer' }}>
                    <img src="assets/icons/help.svg" />
                </div> */}
        <HelpComponent content={props.helpKey} maxWidth="xs" />
      </div>
    );
  };
});

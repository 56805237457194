/***
 * 
 * Usage demo : 
 *              <div style={{ width: 78, height: 66 }}>
                    <TooltipLayer
                        cssStyle={{
                            padding: 5,
                            background: 'white',
                            radius: 10,
                            color: 'black',
                            borderColor: 'grey',
                            arrowPosition : 'LEFT'
                        }}>
                        This is a test
                    </TooltipLayer>
                </div>


 */

import { makeStyles } from '@material-ui/core';
import { BorderBottom } from '@material-ui/icons';
import React from 'react';
import { rst } from 'rt-state';
import { DownArrow, LeftArrow, UpArrow } from '../Arrow';
import { RightArrow } from '../Arrow';

const useStyles = makeStyles((theme) => {
    return {
        root: {},
    };
});
interface XPanelProps {
    cssStyle?: {
        arrowPosition?: string; // UP/DOWN/LEFT/RIGHT
        background?: string;
        padding?: number;
        radius?: number;
        color?: string;
        borderColor?: string;
    };
    clickEvent?: any;
}
export const XPanel = rst.create<XPanelProps>((ctx) => {
    return (props) => {
        const { cssStyle } = ctx.props;
        const outerDivStyle = {
            display: 'flex',

            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',

            flexDirection:
                cssStyle?.arrowPosition == 'UP' || cssStyle?.arrowPosition == 'DOWN'
                    ? ('column' as 'column')
                    : ('row' as 'row'),
            borderColor: cssStyle?.borderColor || '#fff',
            position: 'relative' as 'relative',
        };

        const innerStyle = {
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            borderRadius: cssStyle?.radius || 2,
            background: cssStyle?.background || 'white',
            padding: cssStyle?.padding || 0,
            color: cssStyle?.color || 'black',
            borderColor: cssStyle?.borderColor || '#fff',
            borderWidth: '1px',
            borderStyle: 'solid',
        };
        const arrowStyle = {
            borderColor: cssStyle?.borderColor || '#fff',
            background: cssStyle?.background || 'white',
            outline: cssStyle?.borderColor ? true : false,
        };

        const getContent = () => {
            if (!cssStyle?.arrowPosition) {
                return <div style={innerStyle}>{props.children}</div>;
            }
            if (cssStyle?.arrowPosition == 'LEFT') {
                return (
                    <>
                        {cssStyle?.arrowPosition ? <LeftArrow cssStyle={arrowStyle}></LeftArrow> : null}
                        <div style={innerStyle}>{props.children}</div>
                    </>
                );
            }

            if (cssStyle.arrowPosition == 'RIGHT') {
                return (
                    <>
                        <div style={innerStyle}>{props.children}</div>
                        {cssStyle?.arrowPosition ? <RightArrow cssStyle={arrowStyle}></RightArrow> : null}
                    </>
                );
            }

            if (cssStyle.arrowPosition == 'UP') {
                return (
                    <>
                        {cssStyle?.arrowPosition ? <UpArrow cssStyle={arrowStyle}></UpArrow> : null}
                        <div style={innerStyle}>{props.children}</div>
                    </>
                );
            }

            if (cssStyle.arrowPosition == 'DOWN') {
                return (
                    <>
                        <div style={innerStyle}>{props.children}</div>

                        {cssStyle?.arrowPosition ? <DownArrow cssStyle={arrowStyle}></DownArrow> : null}
                        <DownArrow cssStyle={arrowStyle}></DownArrow>
                    </>
                );
            }
        };
        return (
            <div onClick={props.clickEvent} style={outerDivStyle}>
                {getContent()}
            </div>
        );
    };
});

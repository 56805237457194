import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { NonRecentSoldHouseInfo } from '../../PriceMap/Layers/PropertySidebar/house/NonRecentSoldHouseInfo';
import { ContentTitleData } from '../components/ContentTitleData';
import { Tips } from '../components/Tips';
import { TR } from '../../../commons';
import { MapGlobalVars } from '../../PriceMap/support/PriceMapConsts';
import { useTranslation } from 'react-i18next';
import { showGoogleResultByAddress } from '../../PriceMap/support/propertyFeatureHelper';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            textAlign: 'left',
            padding: '5px 0',
            cursor: 'pointer',
        },
        part2: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '5px 0',
        },
        part3: {
            background: 'rgb(250 252 255)',
            border: '1px solid rgb(236 239 246)',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px 0px',
            '& h2': {
                fontSize: '14px',
                margin: '0px',
            },
            '& span': {
                fontSize: '14px',
            },
        },
        content: {
            padding: '0px 0px 0px 5px',
            margin: '0px',
        },
    };
});

export const OverViewCard = rst.create<{ property: any; prop: any; estimateInfo: any }>((ctx) => {
    return (props) => {
        const { t } = useTranslation();

        console.log(props.property);
        const classes = useStyles();
        const getYear = (value) => {
            if (!value) {
                return <TR name="moreThan20yr" />;
            } else {
                const date = new Date();
                const thisYear = date.getFullYear();
                return (thisYear - value.slice(0, 4)).toString() + t('YEAR');
                // return `${(thisYear - value.slice(0, 4)).toString()}${(<TR name="YAER" />)}`;
            }
        };
        const getGuidePrice = (value) => {
            if (value === '' || value === 0) {
                return 'N/A';
            } else {
                return value + 'm';
            }
        };
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader>
                    <div
                        className={classes.part1}
                        onClick={() => {
                            showGoogleResultByAddress(props.property.address);
                        }}>
                        <ContentTitleData header="PROPERTY_ADDRESS" content={props.property.address} />
                    </div>
                    <CardDivider />
                    <div className={classes.part2}>
                        <ContentTitleData header="PROPERTY_COUNCIL" content={props.property.council} />
                        <div style={{ paddingLeft: '20px' }}>
                            <ContentTitleData header="Zoning" content={props.prop.property.propZoning} />
                        </div>
                        {/* <ContentTitle hasArrow={false} header="PROPERTY_ADDRESS" />
                        <div className={classes.content}>
                            <ContentData content={props.property.address} />
                        </div> */}
                    </div>
                    {props.estimateInfo.guidePrice === '' || props.estimateInfo.guidePrice === 0 ? null : (
                        <div className={classes.part1}>
                            <CardDivider />
                            <ContentTitleData
                                header="PROPERTY_PRICE"
                                // content="3"
                                content={getGuidePrice(props.estimateInfo.guidePrice)}
                                // content={getGuidePrice(MapGlobalVars.currentPropertyEstimate.guidePrice)}
                            />
                        </div>
                    )}
                    <CardDivider />
                    <div className={classes.part1} style={{ marginBottom: '10px' }}>
                        <ContentTitleData
                            header="PROPERTY_YEAR"
                            // content={getYear(props.prop.property.soldContractDate)}
                            content={getYear(props.property.sold_contract_date)}
                        />
                    </div>
                    <NonRecentSoldHouseInfo onReport={true} prop={props.property}></NonRecentSoldHouseInfo>
                    <Tips header="Card_Tips" content="OVERVIEWCARD_TIPS" />
                </CardWithHeader>
            </div>
        );
    };
});

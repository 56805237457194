import * as React from 'react';
import { Button, Container, Grid, IconButton, Link } from '@material-ui/core';
import { clsx, handleRedirect, saveLocationForRedirect, storage } from '../../../commons';
import { hooks, rst } from 'rt-state';
import { XTextField } from '../../../components/form';
import { TR } from '../../../commons';
import { getUserProfile, signupSession, signup, SignupType } from '../../../services/auth';
import { useStyles } from '../login.styles';
import { getErrorData } from '../../../commons/axios/axios';
import {
    getPassword2Validator,
    AuthDialogHeader,
    PageWrapper,
    PASSWORD_SCHEMA,
    setup,
    USERNAME_SCHEMA,
    PASSWORD_HELPER_TEXT,
} from '../common';
import { useRunOnceAsync } from '../../../commons/hooks';
import { LoadingIndicator } from '../../../components/Loading';
import Box from '@material-ui/core/Box';
import { SignupInfo } from './SignupInfo';
import { XCheckbox } from '../../../components/form/mui-form/Checkbox';
import { SessionPo } from '../../../models/backend/session_po';
import { InputSignUp } from '../../mobile/member/commonComponents/input_signUp';
import { Margin } from '../../../components/margin';
import { mobileNavButton } from '../../mobile/consts/styleConsts';
import { env } from '../../../environments';
import { FcGoogle } from 'react-icons/fc';
import { useHistory } from 'react-router-dom';

const initialValues = {
    username: '',
    password: '',
    password2: '',
    termOfUse: false,
};

const validationSchema = {
    username: USERNAME_SCHEMA,
    password: PASSWORD_SCHEMA,
    //password2: getPassword2Validator('password'),
    termOfUse: (value) => {
        if (value) {
            return true;
        }
        return 'Terms of Use must be accepted';
    },
};

export const SignupPage = () => (
    <PageWrapper>
        <SignupComp />
    </PageWrapper>
);

export const SignupComp = rst.create<{ onClose?: () => void; goToLogin?: () => void }>((ctx) => {
    const { securityProvider, appProvider, snackbarController, hooksRef, hasToken, form } = setup(
        initialValues,
        validationSchema,
        onSignup,
    );

    const signupState = rst.state({
        formErrors: null,
    });
    const isLoading = rst.stateS(false);
    const validateFrom = () => {
        signupState.formErrors = form.errors;
        form.handleSubmit();
    };

    async function onSignup(data: any) {
        isLoading.value = true;
        try {
            // delete the storage for close window status
            storage.remove('VERIFY_TIP_WINDOW_STATUS_1');
            storage.remove('VERIFY_TIP_WINDOW_STATUS_2');

            const { username, password } = data;
            const language = appProvider.getLanguage();

            // from oauth or email
            const tokens: SessionPo = await signup(language, SignupType.EMAIL, username, password);
            const jwt = await signupSession(tokens);
            const signUpUserExpireTime = new Date();

            signUpUserExpireTime.setHours(signUpUserExpireTime.getHours() + env.signUpExpireHours);
            console.log('expire time is ', signUpUserExpireTime);
            await securityProvider.setToken(jwt, signUpUserExpireTime);
            //snackbarController.open(<TR name={'success'} />, 'success');

            setTimeout(() => {
                window.location.reload();
            }, 300);
            // snackbarController.open(
            //     <TR name={'Email was sent. Please click on a link in the email to complete your registration.'} />,
            //     'success',
            // );
            ctx.props.onClose?.(); // eslint-disable-line
        } catch (e) {
            try {
                const errorData = getErrorData(e);
                console.error(errorData);
                if (errorData.code === 10001) {
                    snackbarController.open(<TR name={'User already exists'} />, 'error');
                } else {
                    snackbarController.open(<TR name={'failed'} />, 'error');
                }
            } catch (e) {
                snackbarController.open(<TR name={'FailedToSignup_TryLater'} />, 'error');
            }
        }
        isLoading.value = false;
    }

    async function init() {
        const { sessionPo, history } = hooksRef.current;
        console.log(sessionPo);
        if (!hasToken()) {
            return;
        }

        try {
            const jwt = await signupSession(sessionPo);
            await securityProvider.setToken(jwt);
            snackbarController.open(<TR name={'success'} />, 'success');
            const userProfile = await getUserProfile();
            securityProvider.setUserProfile(userProfile);
            await handleRedirect(history);
        } catch (e) {
            console.log(getErrorData(e));
            if (sessionPo.source === 'EMAIL') {
                history.push('/signup');
                return;
            }

            history.push('/login');
        }
    }

    async function onOauthLogin() {
        const history = hooksRef.current.history;
        await saveLocationForRedirect(history);
        window.open(`${env.USER_API_HOST}/user/api/oauth/render/google`, '_self');
    }

    ////////////////////////////////////////////////////////////////////////
    return (props) => {
        useRunOnceAsync(init);
        const classes = useStyles(props);

        if (hasToken()) {
            return <div>loading</div>;
        }

        return (
            <Grid container justify={'center'} direction={'column'} alignItems={'center'}>
                <Container>
                    <AuthDialogHeader signUp onClose={props.onClose} />

                    <SignupInfo />
                    <Margin tm={15}></Margin>
                    <InputSignUp
                        formErrors={signupState.formErrors}
                        form={form}
                        name="username"
                        label={'emailAddress'}
                        placeholder={'emailAddress_ph'}></InputSignUp>
                    <Margin tm={15}></Margin>
                    <InputSignUp
                        formErrors={signupState.formErrors}
                        form={form}
                        name="password"
                        label={'Password'}
                        tipMsg={'Use 6 or more characters with a mix of letters, numbers & symbols'}
                        type={'password'}
                        placeholder={'password_ph'}></InputSignUp>
                    {/* <XTextField form={form} name="password2" label="Password Again" type="password" /> */}
                    <XCheckbox
                        form={form}
                        name={'termOfUse'}
                        label={
                            <div style={{ fontSize: 13 }}>
                                <TR name={'Yes! I understand and agree to the HomeOn '} />
                                <Link href={'/termOfUse'} target={'_blank'}>
                                    <TR name={'Terms of Use'} />
                                </Link>{' '}
                                <TR name={' and '} />{' '}
                                <Link href={'/privacyPolicy'} target={'_blank'}>
                                    <TR name={'Privacy Policy'} />
                                </Link>
                            </div>
                        }
                    />
                    <div className={'mt-2'} />
                    <LoadingIndicator isLoading={isLoading.value}>
                        <Button
                            style={{
                                ...mobileNavButton,
                                background: '#009e90',
                                height: '42px',
                                color: 'white',
                                width: '100%',
                            }}
                            onClick={validateFrom}
                            fullWidth
                            variant="contained"
                            color="primary"
                            disableElevation>
                            <TR name={'SignupButton'} />
                        </Button>
                    </LoadingIndicator>

                    <Margin tm={15}></Margin>
                    <Button
                        style={{
                            ...mobileNavButton,
                            background: 'white',
                            height: '42px',
                            color: '#333333',
                            width: '100%',
                            border: '1px solid #979797',
                            borderRadius: '4px',
                        }}
                        onClick={onOauthLogin}
                        fullWidth
                        variant="contained"
                        disableElevation>
                        <FcGoogle size={24} />
                        <Margin lm={6}></Margin> <TR name={'SignIn with Google'} />
                    </Button>

                    <Margin tm={15}></Margin>
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        style={{ fontSize: 16 }}
                        mt={1}
                        mb={2}>
                        <TR name="Member_click"></TR>
                        <div style={{ textDecoration: 'underline', color: '#009e90' }} onClick={props.goToLogin}>
                            <TR name={'Login'} />
                        </div>
                    </Box>
                </Container>
            </Grid>
        );
    };
});

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { TR } from '../../../../commons';
import { convertToM } from '../../../../commons/utils';
import {
    getIncomeDesc,
    getPrimarySchoolDesc,
    getSecondarySchoolDesc,
    getShoppingCenterDesc,
} from '../../../PriceMap/support/common';
import { mobileNavButton } from '../../consts/styleConsts';
import { InfoLabel } from '../standard/label';
import { InfoValue } from '../standard/infoValue';
import { AppProvider } from '../../../../commons/appProvider';

const useStyles = makeStyles({
    root: {
        display: 'flex',

        alignItems: 'flex-start',

        width: '100%',
        paddingLeft: 10,
    },
    value: {
        fontWeight: 800,
        fontSize: 16,
    },
    label: {
        color: '#888888',
        fontSize: 13,
    },
    value1: {
        fontSize: '13px',
        color: '#000000',
    },
});
export const SuburbMedianInfo = (props: any) => {
    const classes = useStyles();
    const { suburbData, tab } = props;
    const suburbMedianData = suburbData.median_data;

    console.log(suburbData);

    const getIncreasePct = (propType: any) => {
        const localIncreasePct = suburbData['median_' + propType + '_6m_change'];
        const sydneyIncreasePct = suburbData['syd_median_' + propType + '_6m_change'];

        // const localTrend = suburbData['price_trend_' + propType];
        // const sydneyTrend = suburbData['syd_price_trend_' + propType];
        // console.log(localTrend);
        // const localMonthlyTrend = localTrend['monthly_median'];
        // const sydneyMonthlyTrend = sydneyTrend['monthly_median'];

        // const localIncreasePct =
        //     (localMonthlyTrend[localMonthlyTrend.length - 1] - localMonthlyTrend[localMonthlyTrend.length - 7]) /
        //     localMonthlyTrend[localMonthlyTrend.length - 7];

        // const sydneyIncreasePct =
        //     (sydneyMonthlyTrend[sydneyMonthlyTrend.length - 1] - sydneyMonthlyTrend[sydneyMonthlyTrend.length - 7]) /
        //     sydneyMonthlyTrend[sydneyMonthlyTrend.length - 7];

        const result = {
            localIncreasePct: localIncreasePct,
            sydneyIncreasePct: sydneyIncreasePct,
            higherThanSydney: localIncreasePct - sydneyIncreasePct,
        };
        console.log(result);

        return result;
    };
    const houseMedianIncreaseData = getIncreasePct('house');
    const aptMedianIncreaseData = getIncreasePct('apt');

    return (
        <>
            {!tab || tab == 1 ? (
                <>
                    <div className={classes.root}>
                        <div style={{ flex: 2 }}>
                            <div className={classes.value}>
                                {!suburbMedianData.House.total.median
                                    ? 'N/A'
                                    : `$${convertToM(suburbMedianData.House.total.median)}m`}
                            </div>
                            <div className={classes.label}>
                                <TR name={'houseSoldInHalfYear'}></TR>
                            </div>
                        </div>

                        <div style={{ flex: 1 }}>
                            <div className={classes.value}>{suburbMedianData.House.total.numberOfSold}</div>
                            <div className={classes.label}>
                                {' '}
                                <TR name={'soldTotal'}></TR>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: 10, paddingLeft: 10, color: '#888888', fontSize: '13px' }}>
                        <div>
                            <TR name="HouseIncreasePct"></TR>:{' '}
                            <span className={classes.value1}>
                                {'   '}
                                {!houseMedianIncreaseData.localIncreasePct ? (
                                    'N/A'
                                ) : houseMedianIncreaseData.localIncreasePct > 0 ? (
                                    <>
                                        <TR name="increase"></TR>{' '}
                                        {(Math.abs(houseMedianIncreaseData.localIncreasePct) * 100).toFixed(2)}%
                                    </>
                                ) : (
                                    <>
                                        <TR name="decrease"></TR>{' '}
                                        {(Math.abs(houseMedianIncreaseData.localIncreasePct) * 100).toFixed(2)}%
                                    </>
                                )}
                            </span>
                        </div>
                        {AppProvider.use().getLanguage() == 'zh' ? (
                            <>
                                <div>
                                    {' '}
                                    {!houseMedianIncreaseData.localIncreasePct ||
                                    !houseMedianIncreaseData.sydneyIncreasePct ? (
                                        <>
                                            <TR name="higherThanSydPct"></TR>
                                            <span className={classes.value1}>N/A</span>
                                        </>
                                    ) : houseMedianIncreaseData.localIncreasePct > 0 ? (
                                        <>
                                            <TR name="higherThanSydPct"></TR>
                                            <span className={classes.value1}>
                                                {houseMedianIncreaseData.higherThanSydney > 0 ? (
                                                    <TR name="changeHigher"></TR>
                                                ) : (
                                                    <TR name="changeLower"></TR>
                                                )}{' '}
                                                {(Math.abs(houseMedianIncreaseData.higherThanSydney) * 100).toFixed(2)}%
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <TR name="lowerThanSydPct"></TR>
                                            <span className={classes.value1}>
                                                {houseMedianIncreaseData.higherThanSydney > 0 ? (
                                                    <TR name="changeLower"></TR>
                                                ) : (
                                                    <TR name="changeHigher"></TR>
                                                )}{' '}
                                                {(Math.abs(houseMedianIncreaseData.higherThanSydney) * 100).toFixed(2)}%
                                            </span>
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    {' '}
                                    {!houseMedianIncreaseData.localIncreasePct ||
                                    !houseMedianIncreaseData.sydneyIncreasePct ? (
                                        <>
                                            <TR name="higherThanSydPct"></TR>
                                            <span className={classes.value1}>N/A</span>
                                        </>
                                    ) : houseMedianIncreaseData.localIncreasePct > 0 ? (
                                        <>
                                            {houseMedianIncreaseData.higherThanSydney > 0 ? (
                                                <>
                                                    <TR name="higherThanSydPct"></TR>
                                                    <span className={classes.value1}>
                                                        {Math.abs(
                                                            houseMedianIncreaseData.higherThanSydney * 100,
                                                        ).toFixed(2)}
                                                        %{'  '}
                                                        <TR name="changeHigher"></TR>
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <TR name="higherThanSydPct"></TR>
                                                    <span className={classes.value1}>
                                                        {Math.abs(
                                                            houseMedianIncreaseData.higherThanSydney * 100,
                                                        ).toFixed(2)}
                                                        %{'  '}
                                                        <TR name="changeLower"></TR>
                                                    </span>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {houseMedianIncreaseData.higherThanSydney > 0 ? (
                                                <>
                                                    <TR name="lowerThanSydPct"></TR>
                                                    <span className={classes.value1}>
                                                        {Math.abs(
                                                            houseMedianIncreaseData.higherThanSydney * 100,
                                                        ).toFixed(2)}
                                                        %{'  '}
                                                        <TR name="changeLower"></TR>
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <TR name="lowerThanSydPct"></TR>
                                                    <span className={classes.value1}>
                                                        {Math.abs(
                                                            houseMedianIncreaseData.higherThanSydney * 100,
                                                        ).toFixed(2)}
                                                        %{'  '}
                                                        <TR name="changeHigher"></TR>
                                                    </span>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            ) : null}
            {!tab ? <div style={{ marginTop: 20 }}></div> : null}
            {!tab || tab == 2 ? (
                <>
                    <div className={classes.root}>
                        <div style={{ flex: 2 }}>
                            <div className={classes.value}>
                                {!suburbMedianData.Apt.total.median
                                    ? 'N/A'
                                    : `$${convertToM(suburbMedianData.Apt.total.median)}m`}
                            </div>
                            <div className={classes.label}>
                                {' '}
                                <TR name={'AptSoldInHalfYear'}></TR>
                            </div>
                        </div>

                        <div style={{ flex: 1 }}>
                            <div className={classes.value}>{suburbMedianData.Apt.total.numberOfSold}</div>
                            <div className={classes.label}>
                                {' '}
                                <TR name={'soldTotal'}></TR>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: 10, paddingLeft: 10, color: '#888888', fontSize: '13px' }}>
                        <div>
                            <TR name="HouseIncreasePct"></TR>:
                            <span className={classes.value1}>
                                {'   '}
                                {!aptMedianIncreaseData.localIncreasePct ? (
                                    'N/A'
                                ) : aptMedianIncreaseData.localIncreasePct > 0 ? (
                                    <>
                                        <TR name="increase"></TR>
                                        {'  '}
                                        {Math.abs(aptMedianIncreaseData.localIncreasePct * 100).toFixed(2)}%
                                    </>
                                ) : (
                                    <>
                                        <TR name="decrease"></TR>
                                        {'  '}
                                        {Math.abs(aptMedianIncreaseData.localIncreasePct * 100).toFixed(2)}%
                                    </>
                                )}
                            </span>
                        </div>
                        {AppProvider.use().getLanguage() == 'zh' ? (
                            <div>
                                {'  '}
                                {!aptMedianIncreaseData.localIncreasePct || !aptMedianIncreaseData.sydneyIncreasePct ? (
                                    <>
                                        <TR name="higherThanSydPct"></TR>
                                        <span className={classes.value1}>N/A</span>
                                    </>
                                ) : aptMedianIncreaseData.localIncreasePct > 0 ? (
                                    <>
                                        {aptMedianIncreaseData.higherThanSydney > 0 ? (
                                            <>
                                                <TR name="higherThanSydPct"></TR>
                                                <span className={classes.value1}>
                                                    <TR name="changeHigher"></TR>
                                                    {'  '}
                                                    {Math.abs(aptMedianIncreaseData.higherThanSydney * 100).toFixed(2)}%
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <TR name="higherThanSydPct"></TR>
                                                <span className={classes.value1}>
                                                    <TR name="changeLower"></TR>
                                                    {'  '}
                                                    {Math.abs(aptMedianIncreaseData.higherThanSydney * 100).toFixed(2)}%
                                                </span>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {aptMedianIncreaseData.higherThanSydney > 0 ? (
                                            <>
                                                <TR name="lowerThanSydPct"></TR>
                                                <span className={classes.value1}>
                                                    <TR name="changeLower"></TR>
                                                    {'  '}
                                                    {Math.abs(aptMedianIncreaseData.higherThanSydney * 100).toFixed(2)}%
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <TR name="lowerThanSydPct"></TR>
                                                <span className={classes.value1}>
                                                    <TR name="changeHigher"></TR>
                                                    {'  '}
                                                    {Math.abs(aptMedianIncreaseData.higherThanSydney * 100).toFixed(2)}%
                                                </span>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        ) : (
                            <div>
                                {' '}
                                {!aptMedianIncreaseData.localIncreasePct || !aptMedianIncreaseData.sydneyIncreasePct ? (
                                    <>
                                        <TR name="higherThanSydPct"></TR>
                                        <span className={classes.value1}>N/A</span>
                                    </>
                                ) : aptMedianIncreaseData.localIncreasePct > 0 ? (
                                    <>
                                        {aptMedianIncreaseData.higherThanSydney > 0 ? (
                                            <>
                                                <TR name="higherThanSydPct"></TR>
                                                <span className={classes.value1}>
                                                    {Math.abs(aptMedianIncreaseData.higherThanSydney * 100).toFixed(2)}%
                                                    {'  '}
                                                    <TR name="changeHigher"></TR>
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <TR name="higherThanSydPct"></TR>
                                                <span className={classes.value1}>
                                                    {Math.abs(aptMedianIncreaseData.higherThanSydney * 100).toFixed(2)}%
                                                    {'  '}
                                                    <TR name="changeLower"></TR>
                                                </span>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {aptMedianIncreaseData.higherThanSydney > 0 ? (
                                            <>
                                                <TR name="lowerThanSydPct"></TR>
                                                <span className={classes.value1}>
                                                    {Math.abs(aptMedianIncreaseData.higherThanSydney * 100).toFixed(2)}%
                                                    {'  '}
                                                    <TR name="changeLower"></TR>
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <TR name="lowerThanSydPct"></TR>
                                                <span className={classes.value1}>
                                                    {Math.abs(aptMedianIncreaseData.higherThanSydney * 100).toFixed(2)}%
                                                    {'  '}
                                                    <TR name="changeHigher"></TR>
                                                </span>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </>
            ) : null}
        </>
    );
};

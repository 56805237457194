import { Box, Container, makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { getItemFromArray } from '../../../commons/utils';
import { FloatLayer } from '../../../components/FloatLayer';
import { FilterOption } from '../../../models/filterOption';
import { ALL_FILTER_OPTIONS, SuburbPageState } from '../common';

const legendStyle = {
    bottom: '20px',
    background: 'white',
    padding: '10px',
};

const useStyles = makeStyles((theme) => {
    return {
        legendItemDiv: {
            color: 'black',
            padding: '2px',
            display: 'flex',

            width: '100%',
        },
        legendColorBox: {
            border: '1px solid black',
            width: '16px',
            height: '16px',
            background: 'red',
        },
        legendLabel: {
            marginLeft: '5px',
        },
    };
});

interface legendState {}

export interface LegendProps {
    suburbPageState: SuburbPageState;
}

export const MapLegend = rst.create<LegendProps>((ctx) => {
    return (props) => {
        const getLegends = () => {
            const legendList = [];
            const selectedFilter: FilterOption = getItemFromArray(
                ALL_FILTER_OPTIONS[props.suburbPageState.tabOption.value],
                'value',
                props.suburbPageState.filterOption.value,
            );
            if (selectedFilter && selectedFilter.grades) {
                selectedFilter.grades.forEach((colorItem) => {
                    legendList.push(<LengendItem legendItem={colorItem}></LengendItem>);
                });
            }
            console.log(legendList);
            return legendList;
        };

        return <FloatLayer style={legendStyle}>{getLegends()}</FloatLayer>;
    };
});

interface LegendItemProps {
    legendItem: any;
}
const LengendItem = rst.create<LegendItemProps>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.legendItemDiv}>
                <div className={classes.legendColorBox} style={{ background: props.legendItem.color }}></div>
                <div className={classes.legendLabel}>
                    {props.legendItem.min} - {props.legendItem.max}
                </div>
            </div>
        );
    };
});

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import withStyles from '@material-ui/styles/withStyles';
import { rst, state } from 'rt-state';
import classes from '*.module.css';
import { isMobileDevice } from '../../commons/utils';
import { TR } from '../../commons';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            borderRadius: 4,
            position: 'relative',
            padding: 6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        background: {
            position: 'absolute',
            background: '#1875F0',
            opacity: 0.1,
            width: '100%',
            height: '100%',
            borderRadius: 4,
            zIndex: 1,
        },
        label: {
            fontSize: isMobileDevice() ? 10 : 12,
            color: '#1875f0',
            fontWeight: 400,
        },
    };
});
export const Chip = rst.create<{ label: string; image?: any; fontSize? }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                <div className={classes.background}></div>
                <div className={classes.label} style={{ fontSize: props.fontSize }}>
                    <TR name={props.label}></TR>
                    {props.image}
                </div>
            </div>
        );
    };
});

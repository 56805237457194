import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { getPriceMapLevelByZoom } from '../support/common';
import { MapGlobalVars, MapLevel } from '../support/PriceMapConsts';
import { PropertyMarker } from './PropertyMarker';

const win: any = window;
const L = win.L;
export class MapOverlayController {
    map: any;
    aroundTransCircle;
    aroundTransMarkers = {};

    constructor(map) {
        this.map = map;

        EventBus.on(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, (evt: any) => {
            console.log(evt);
            this.removeAroundTransMarker();
            if (evt.showAroundTrans) {
                MapGlobalVars.showAroundTransCircle = true;
                this.drawAroundCircle(evt.property);

                this.addAroundTransMarkers(evt.aroundTrans, evt.propType);
            } else {
                MapGlobalVars.showAroundTransCircle = false;
                this.map.removeLayer(this.aroundTransCircle);

                this.map.setZoom(MapLevel.propertyLevel.mid + 1);
            }
        });

        EventBus.on(EVENT_NAMES.EVENT_NEAR_BY_TRANS_AXIS_POINT_CLICKED, (evt) => {
            console.log(evt.propId);
            console.log(this.aroundTransMarkers);
            console.log(this.aroundTransMarkers[evt.propId]);
            this.aroundTransMarkers[evt.propId]?.propMarkerClickEvent();
        });

        EventBus.on(EVENT_NAMES.EVENT_MAP_ZOOMED, (zoom) => {
            console.log(MapGlobalVars.showAroundTransCircle);
            if (MapGlobalVars.showAroundTransCircle) {
                if (zoom < MapLevel.propertyLevel.lower) {
                    console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxx');
                    this.switchAroundTrans(false);
                } else {
                    this.switchAroundTrans(true);
                }
            }
        });
    }

    switchAroundTrans(show) {
        if (!show) {
            Object.keys(this.aroundTransMarkers).forEach((prop_id) => {
                const markerObj = this.aroundTransMarkers[prop_id];
                this.map.removeLayer(markerObj.getPropertyMarker());
            });
        } else {
            Object.keys(this.aroundTransMarkers).forEach((prop_id) => {
                const markerObj = this.aroundTransMarkers[prop_id];
                this.map.addLayer(markerObj.getPropertyMarker());
            });
        }
    }

    drawAroundCircle(property) {
        if (this.aroundTransCircle) {
            this.map.removeLayer(this.aroundTransCircle);
        }
        this.aroundTransCircle = L.circle([property.lat, property.lon], { radius: 500, fill: false });
        this.aroundTransCircle.addTo(this.map);
        this.map.fitBounds(this.aroundTransCircle.getBounds());
    }

    removeAroundTransMarker() {
        Object.keys(this.aroundTransMarkers).forEach((prop_id) => {
            const markerObj = this.aroundTransMarkers[prop_id];
            this.map.removeLayer(markerObj.getPropertyMarker());
        });
        this.aroundTransMarkers = {};
    }

    addAroundTransMarkers(trans, propType) {
        if (!trans || trans.length == 0) {
            return;
        }
        for (let idx = 0; idx < trans.length; idx++) {
            const loopProp = trans[idx];
            var propMarker = this.aroundTransMarkers[loopProp.prop_id];
            if (propMarker) {
                if (!this.map.hasLayer(propMarker.getPropertyMarker())) {
                    this.map.addLayer(propMarker.getPropertyMarker());
                }
                continue;
            }
            const markerProperty: any = {};
            markerProperty.lat = loopProp.lat;
            markerProperty.lon = loopProp.lon;
            markerProperty.propId = loopProp.prop_id;
            markerProperty.propType = propType;
            markerProperty.sold_contract_date = loopProp.sold_contract_date;
            markerProperty.prop_bed = loopProp.prop_bed;
            markerProperty.price = loopProp.sold_price;

            propMarker = new PropertyMarker(markerProperty);
            propMarker.markerInMaxZoom = true;
            if (propType == 'House') {
                propMarker.setupMarkerByBeds();
            } else {
                propMarker.setIconByProeprtyType(false, true);
            }

            propMarker.getPropertyMarker().addTo(this.map);
            this.aroundTransMarkers[loopProp.prop_id] = propMarker;
        }
    }
}

import React from 'react';
import { rst, stateS } from 'rt-state';
import { XTab, XTabs } from './Tabs';
import { TR } from '../../commons/i18n';
import { withStyles } from '@material-ui/core/styles';
import { Tabs } from '@material-ui/core';
import { SelectOptionProps } from '../common';

const CTab = withStyles((theme) => {
    return {
        root: {
            margin: 0,
            width: '100px',
            height: '45px',
            fontSize: '16px',
            minHeight: theme.spacing(4),
            minWidth: theme.spacing(9),
            '&$selected': {
                color: 'white',
                backgroundColor: theme.palette.primary.main,
                borderRadius: '10px 10px 0 0',
            },
        },
        selected: {},
    };
})(XTab);

const CTabs = withStyles({
    root: {},
    indicator: {
        backgroundColor: 'transparent',
    },
})(XTabs);

export type CategoryTabsProps = {
    isPrimary?: boolean;
} & SelectOptionProps;

export const CategoryTabs = rst.create<CategoryTabsProps>((ctx) => {
    const initIdx = ctx.props.options?.findIndex((opt) => opt.value === ctx.props.value?.value);
    const state = stateS({
        idx: initIdx ?? 0,
    });

    const handleTabChange = (e, newValue) => {
        const { value: selected } = state;
        selected.idx = newValue;
        const value = ctx.props.options?.[newValue];
        ctx.props.onSelect?.(value); // eslint-disable-line
        state.forceUpdate();
    };

    return (props) => {
        const { options, isPrimary } = props;
        const Tabs = isPrimary ? XTabs : CTabs;
        const Tab = isPrimary ? XTab : CTab;
        return (
            <Tabs value={state.value.idx} onChange={handleTabChange}>
                {options.map((filter, idx) => {
                    const label = <TR name={filter.label ?? (filter.value as string)} />;
                    return <Tab key={idx} label={label} />;
                })}
            </Tabs>
        );
    };
});

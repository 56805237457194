import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { isMobileDevice, yearAgo } from '../../../commons/utils';
import { PriceLabel } from '../components/PriceLabel';
import { PriceLegend } from '../components/PriceLegend';
import { getPriceMapLevelByZoom } from '../support/common';
import { PRICE_MAP_STATUS } from '../support/PriceMapConsts';
const useStyles = makeStyles((theme) => {
    return {
        root: {
            color: 'red',
            position: 'relative',
        },
        searchIcon: {
            position: 'absolute',
            left: isMobileDevice() ? '-5px' : '-11px',
            top: isMobileDevice() ? '-10px' : '-25px',
        },
        clickableDiv: {
            width: 20,
            height: 20,
            background: 'transparent',
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
        },
        transparentMarker: {
            width: 8,
            height: 8,
            background: 'grey',

            opacity: 0.1,
            borderRadius: '50%',
        },
        roundPropIcon: {
            border: '2px solid #BCDDFB',
            boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.1)',
            borderRadius: '50%',
            cursor: 'pointer',
            background: 'transparent',
            opacity: 0.8,
            width: 12,
            height: 12,
        },
    };
});

export const PropertyIcon = rst.create<{
    propId: string; // or project id
    color: any;
    showLabel?: boolean;
    price?: any;
    labelColor?: string;
    notShowMarkPoint?: boolean;
    currentSearching?: boolean;
    icon?: string;
    zoom?: number;
}>((ctx) => {
    return (props) => {
        if (props.propId && props.currentSearching) {
            setTimeout(() => {
                const searchIconEL = document.getElementById('searchIcon_' + props.propId);
                if (searchIconEL) {
                    searchIconEL.classList.add('animate__bounce');
                    searchIconEL.classList.add('animate__animated');
                }
            }, 200);
        }
        const classes = useStyles();
        return (
            <div className={classes.root}>
                {props.showLabel ? (
                    <PriceLabel color={props.color} labelColor={props.labelColor}>
                        {props.price}
                    </PriceLabel>
                ) : null}
                {props.currentSearching ? (
                    <img
                        src="assets/icons/placeholder.svg"
                        id={props.propId ? 'searchIcon_' + props.propId : ''}
                        className={classes.searchIcon}
                        width="18px"
                        height="18px"></img>
                ) : null}
                {props.notShowMarkPoint ? null : <PriceLegend background={props.color} zoom={props.zoom}></PriceLegend>}
            </div>
        );
    };
});

export const p_icon = rst.create<{
    propType?: string;
    soldContractDate?: string;
    clicked?: boolean;
    notTrans?: boolean;
    mapStatus?: any;
    zoom?: any;
    reportMapCenter?: boolean;
    icon?: string;
}>((ctx) => {
    return (props) => {
        const renderIcon = () => {
            if (props.icon) {
                return (
                    <>
                        <img
                            src={'assets/icons/' + props.icon + '.svg'}
                            className={classes.searchIcon}
                            width={isMobileDevice() ? 18 : 30}
                            height={isMobileDevice() ? 18 : 30}></img>
                    </>
                );
            }
            if (!props.reportMapCenter) {
                return (
                    <>
                        <img
                            src={'assets/icons/' + getPropertyIcon() + '.svg'}
                            className={classes.searchIcon}
                            width={isMobileDevice() ? 18 : 30}
                            height={isMobileDevice() ? 18 : 30}></img>
                    </>
                );
            } else {
                return (
                    <>
                        <img src={'assets/icons/placeholder.svg'}></img>
                    </>
                );
            }
        };
        const classes = useStyles();
        const getPropertyIcon = () => {
            if (props.clicked) {
                if (props.zoom) {
                    if (getPriceMapLevelByZoom(props.zoom) == PRICE_MAP_STATUS.PROPERTYLEVEL) {
                        return 'placeholder';
                    }
                }

                if (props.propType == 'Apt') {
                    return 'apartmentclick@1x';
                } else {
                    if (yearAgo(props.soldContractDate, 'YYYY-MM-DD')) {
                        return 'homeNotRecent_clicked';
                    } else {
                        return 'home_recentSold_clicked';
                    }
                }
            } else {
                if (props.propType == 'Apt') {
                    return 'apartment-ico@1x';
                } else {
                    if (yearAgo(props.soldContractDate, 'YYYY-MM-DD')) {
                        return 'homeNotRecent';
                    } else {
                        return 'home_recentSold';
                    }
                }
            }
        };
        return <>{renderIcon()} </>;
    };
});
export const favPropIcon = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <img
                src={'assets/icons/favOnMap.svg'}
                className={classes.searchIcon}
                width={isMobileDevice() ? 18 : 30}
                height={isMobileDevice() ? 18 : 30}></img>
        );
    };
});

export const RoundPropIcon = rst.create<{ propType: string }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return <div className={classes.roundPropIcon}></div>;
    };
});

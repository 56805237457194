import { FormControl, InputLabel, MenuItem, NativeSelect, Select, Slider } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { rst } from 'rt-state';
import React from 'react';
import { ItemLabel } from './ItemLabel';
import { TR } from '../../../commons';
const useStyles = makeStyles((theme) => {
    return {};
});
export const SelectItem = rst.create<{ itemName: string; options?: any; width?: number; changeEvent: any }>((ctx) => {
    return (props) => {
        const classes = useStyles();

        const getOptions = () => {
            const optionList: any = [];
            props.options.forEach((loopOption) => {
                optionList.push(
                    <MenuItem value={loopOption.value}>
                        <TR name={loopOption.label}></TR>
                    </MenuItem>,
                );
            });
            return optionList;
        };
        return (
            <div style={{ display: 'flex' }}>
                <ItemLabel style={{ minWidth: 60 }}>
                    <TR name={props.itemName} />
                </ItemLabel>
                <div style={{ marginLeft: '9px' }}></div>
                <Select
                    style={{ width: props.width }}
                    onChange={(evt) => props.changeEvent(evt.target.value)}
                    variant="standard">
                    {getOptions()}
                </Select>
            </div>
        );
    };
});

const SelectBase = withStyles({})(Select);

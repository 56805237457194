import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { rst } from 'rt-state';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { TR } from '../../../commons';

export interface NewButtonProps {
    onClick?: any;
    label: string;
    color?: string;
    icon?: number;
    backgroundColor?: string;
    padding?: string;
}
const useStyles = makeStyles((theme) => ({
    button: (props: NewButtonProps) => {
        const backgroundColor = props.backgroundColor;
        const padding = props.padding;
        return {
            height: '40px',
            backgroundColor,
            padding,
            borderRadius: '4px',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                height: '30px',
                borderRadius: '15px',
            },
            // !!not working below!!
            // position: 'relative',
            // '& :after': {
            //     content: '',
            //     position: 'absolute',
            //     width: '100%',
            //     height: '100%',
            //     top: 0,
            //     left: 0,
            //     transition: 'all 0.2s',
            // },
            // '& :hover:after': {
            //     background: 'rgba(0,0,0,0.1)',
            // },
            // '& :active:after': {
            //     background: 'rgba(0,0,0,0.3)',
            // },
        };
    },
    label: (props: NewButtonProps) => {
        const color = props.color;
        return {
            color,
            fontSize: '16px',
            fontWeight: 600,
            position: 'relative',
            top: '-1px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                fontWeight: 600,
            },
        };
    },
    icon: (props: NewButtonProps) => {
        const color = props.color;
        return {
            color,
            fontSize: '16px',
            fontWeight: 'normal',
            paddingLeft: '5px',
            // position: 'relative',
            // top: '2px',
        };
    },
}));

export const NewButton = rst.create<NewButtonProps>((ctx) => {
    return (props) => {
        const classes = useStyles(props);
        const { label, icon, onClick } = props;
        if (icon && icon === 1) {
            return (
                <button className={classes.button} onClick={onClick}>
                    <div className={classes.label}>
                        <span>
                            <TR name={label} />
                        </span>
                    </div>
                    <div className={classes.icon}>
                        <PlayCircleFilledIcon style={{ fill: 'white', fontSize: 15 }} />
                    </div>
                </button>
            );
        } else if (icon && icon === 2) {
            return (
                <button className={classes.button} onClick={onClick}>
                    <div className={classes.label}>
                        <span>
                            <TR name={label} />
                        </span>
                    </div>
                    <div className={classes.icon}>
                        <DoubleArrowIcon style={{ fill: 'white', fontSize: 15 }} />
                    </div>
                </button>
            );
        } else {
            return (
                <button className={classes.button} onClick={onClick}>
                    <div className={classes.label}>
                        <span>
                            <TR name={label} />
                        </span>
                    </div>
                </button>
            );
        }
    };
});

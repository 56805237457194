import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { TR } from '../../../../commons';
import { convertToM } from '../../../../commons/utils';
import { CardTitle } from './cardTitle';
import { Station } from './station';
import { InfoLabel } from '../standard/label';
import { InfoValue } from '../standard/infoValue';
const useStyles = makeStyles({
    root: {
        justifyContent: 'space-between',
        width: '100%',
    },
    landValue: {
        color: '#009e90',
        fontWeight: 800,
        fontSize: 14,
    },
    landLabel: {
        color: '#888888',
        fontSize: 13,
    },
});
export const LandZoning = (props: any) => {
    const { landValue, zoning, inDetails, closest_station } = props;
    const [closeContent, setCloseContent] = useState(false);
    const classes = useStyles();
    return (
        <div style={{ padding: inDetails ? 10 : 0 }}>
            {inDetails ? (
                <CardTitle
                    closeEvt={() => {
                        setCloseContent(!closeContent);
                    }}
                    closeContent={closeContent}
                    title={'landValueAndZoning'}></CardTitle>
            ) : null}
            {!inDetails || !closeContent ? (
                <div style={{ width: '100%' }}>
                    <div className={classes.root}>
                        <div style={{ display: 'flex' }}>
                            <InfoLabel>NSWlandValue</InfoLabel>
                            <InfoValue> ${convertToM(landValue)}m</InfoValue>
                        </div>

                        <div style={{ display: 'flex', marginTop: 5 }}>
                            <InfoLabel>zoning</InfoLabel>
                            <InfoValue> R{zoning}</InfoValue>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', marginTop: 5 }}></div>
                    {inDetails ? <Station station={closest_station}></Station> : null}
                </div>
            ) : null}
        </div>
    );
};

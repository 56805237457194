import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { useRunOnce } from '../../../commons/hooks';
import { getCurrentYear, isMobileDevice } from '../../../commons/utils';
import { Glossary } from '../../PropertyReport/components/Glossary';
const useStyles = makeStyles((theme) => {
    return {
        chartTitleDiv: {
            display: 'flex',
        },
        chartTitle: {
            fontSize: 14,
            color: '#333333',
            fontWeight: 500,
        },
        legend: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        legendItem: {
            width: '11px',
            height: 8,
        },
    };
});
/*"locality_trends": {"year": [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019], "median": [360000, 422000, 460000, 515000, 610000, 625000, 713500, 721000, 730000, 710000]}, "city_trends": {"year": [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019], "median": [480000, 485000, 520000, 555000, 625000, 698000, 720000, 730000, 725000, 700000]}*/

declare var Highcharts;

const chartColor = {
    sydney: '#ff9f40',
    council: '#5ad8a6',
    local: '#5b8ff9',
};

export const SuburbHalfYearAuction = rst.create<{
    sydneyData?: any;
    councilData?: any;
    localData?: any;
    suburbName?: string;
    councilName?: string;
}>((ctx) => {
    console.log(ctx.props.localData);
    const sampleData = {
        sydneyData: {
            monthly_median: [515000, 610000, 580000, 620000, 660000, 739000, 870000, 878000, 931000, 980000],
            month: [
                '2020-01',
                '2020-02',
                '2020-03',
                '2020-04',
                '2020-05',
                '2020-06',
                '2020-07',
                '2020-08',
                '2020-09',
                '2020-10',
                '2020-11',
                '2020-12',
            ],
        },

        localData: {
            month: [
                '2020-01',
                '2020-02',
                '2020-03',
                '2020-04',
                '2020-05',
                '2020-06',
                '2020-07',
                '2020-08',
                '2020-09',
                '2020-10',
                '2020-11',
                '2020-12',
            ],
            monthly_median: [360000, 422000, 460000, 515000, 610000, 625000, 713500, 721000, 730000, 710000],
        },
    };
    const x_catagories = [];

    ctx.props.localData?.month.forEach((value, idx) => {
        x_catagories.push(value.substr(5, 2) + '/' + value.substr(2, 2));
    });

    let sydneyYAxisMin: number = 0;
    let sydneyYAxisMax: number = 0;
    let localYAxisMin: number = 0;
    let localYAxisMax: number = 0;

    let chart = null;
    function loadChart() {
        chart = Highcharts.chart('chart_container_halfYearAuction', {
            chart: {
                height: 120,

                marginBottom: isMobileDevice() ? 30 : 35,
            },
            title: {
                text: '',
            },

            subtitle: {
                text: '',
            },

            yAxis: [
                {
                    title: {
                        text: ctx.props.suburbName,
                    },
                    labels: {
                        style: {
                            color: chartColor.local,
                        },
                    },
                    tickPositions: autoSetYAxis ? null : [localYAxisMin, localYAxisMax],
                },
                {
                    title: {
                        text: 'Sydney',
                    },
                    labels: {
                        style: {
                            color: chartColor.sydney,
                        },
                    },
                    tickPositions: autoSetYAxis ? null : [sydneyYAxisMin, sydneyYAxisMax],
                    opposite: true,
                },
            ],

            xAxis: {
                accessibility: {
                    rangeDescription: '',
                },
                type: 'category',
                categories: x_catagories,
                crosshair: true,
                endOnTick: false,
                startOnTick: false,
            },

            legend: {
                enabled: false,
            },
            credits: { enabled: false },
            tooltip: {
                enabled: true,
                backgroundColor: 'white', // 背景颜色
                borderColor: 'black', // 边框颜色
                borderRadius: 10, // 边框圆角
                borderWidth: 1, // 边框宽度
                animation: true, // 是否启用动画效果
                //format the date and numbers
                formatter: function () {
                    const _this: any = this;
                    console.log(_this);
                    var d = new Date(_this.x);
                    var s = '<b>' + _this.x + '</b>';
                    s += '<br/><span>Price: ' + '$' + (_this.y / 1000000).toFixed(2) + 'm' + '</span>';
                    return s;
                },
            },
            plotOptions: {
                series: {
                    enableMouseTracking: true,
                    marker: {
                        enabled: false,
                    },
                },
                areaspline: {
                    fillOpacity: 0.25,
                },
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 2,
                    opacity: 1,

                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666',
                    },
                },
            },

            series: [
                {
                    name: 'Sydney',
                    data: sydneyChartData,
                    color: chartColor.sydney,
                    fillOpacity: 0.3,
                    opacity: 1,
                    yAxis: 1,
                    lineColor: chartColor.sydney,
                },

                {
                    name: 'Local',
                    color: chartColor.local,
                    fillOpacity: 0.3,
                    data: localChartData,
                    opacity: 1,
                    lineColor: chartColor.local,
                },
            ],
        });
    }
    let sydneyChartData = ctx.props.sydneyData?.monthly_median;
    let localChartData = ctx.props.localData.monthly_median;
    let autoSetYAxis = false;
    const processChartData = () => {
        let K = 0;
        if (localChartData[0] > 0 && sydneyChartData[0] > 0) {
            K = localChartData[0] / sydneyChartData[0];
        } else {
            let localAvg = 0;
            let localAmt = 0;
            let localCount = 0;

            let sydneyAvg = 0;
            let sydneyAmt = 0;
            let sydneyCount = 0;
            localChartData.forEach((element) => {
                if (element > 0) {
                    localAmt += element;
                    localCount += 1;
                }
            });
            localAvg = localAmt / localCount;

            sydneyChartData.forEach((element) => {
                if (element > 0) {
                    sydneyAmt += element;
                    sydneyCount += 1;
                }
            });

            sydneyAvg = sydneyAmt / sydneyCount;

            K = localAvg / sydneyAvg;
        }
        const allNewLocalChartData = localChartData.map((loopData) => {
            return loopData / K;
        });

        const allData = allNewLocalChartData.concat(sydneyChartData).filter((loopItem) => {
            return loopItem > 0;
        });

        const min = Math.min(...allData);
        const max = Math.max(...allData);
        console.log(ctx.props.localData.monthly_median);
        console.log('K is ' + K);
        console.log(allNewLocalChartData);
        localYAxisMin = Math.floor((0.9 * min * K) / 1000) * 1000;
        localYAxisMax = Math.ceil((1.1 * max * K) / 1000) * 1000;
        sydneyYAxisMin = Math.floor((0.9 * min) / 1000) * 1000;
        sydneyYAxisMax = Math.ceil((1.1 * max) / 1000) * 1000;
        autoSetYAxis = false;

        localChartData = localChartData.map((loop) => {
            if (loop == 0) {
                return null;
            } else {
                return loop;
            }
        });

        sydneyChartData = sydneyChartData.map((loop) => {
            if (loop == 0) {
                return null;
            } else {
                return loop;
            }
        });
        console.log(localChartData);
        console.log(sydneyChartData);

        console.log(localYAxisMin + ';' + localYAxisMax + ';' + sydneyYAxisMin + ';' + sydneyYAxisMax);
    };

    return (props) => {
        setTimeout(() => {
            processChartData();
            loadChart();
        }, 400);
        const classes = useStyles();
        return (
            // the reason set class name is to hide before showing the right chart
            <div className="HOMEON_Chart" style={{ minHeight: '160px' }} id="halfYearAuction">
                <div className={classes.chartTitleDiv}>
                    <div className={classes.chartTitle}>
                        <TR name="HALF_YEAR_AUCTION_CHART"></TR>
                    </div>
                    <div className={classes.legend}>
                        <div className={classes.legendItem} style={{ background: chartColor.sydney }}></div>
                        <div style={{ marginLeft: 7 }}>
                            <TR name="SYDNEY_OVERALL"></TR>
                        </div>

                        <div style={{ marginLeft: 17 }}></div>

                        <div className={classes.legendItem} style={{ background: chartColor.local }}></div>
                        <div style={{ marginLeft: 7 }}>{props.suburbName}</div>
                    </div>
                </div>

                <div style={{ marginTop: 15 }}></div>
                <div id="chart_container_halfYearAuction"> </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Glossary glossaryId="13" />
                </div>
            </div>
        );
    };
});

/* eslint-disable prettier/prettier */
import { IconButton, InputAdornment, List, ListItem, ListItemText, makeStyles, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { SecurityProvider, storage, TR } from '../../../../commons';
import ReactDOM from 'react-dom/server';

import React from 'react';
import { rst, state, unstable_disableDelay } from 'rt-state';
import { API } from '../../../../commons';
import { BaseAutoComplete } from '../../../../components/AutoComplete/BaseAutoComplete';
import { isMobileDevice } from '../../../../commons/utils';
import { MAP_APIS_ENDPOINTS } from '../../../../services/EndPoints';
import { MapGlobalVars, PropTypes } from '../../support/PriceMapConsts';
import { getThemeValue } from '../../support/PriceMapTheme';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { useTranslation } from 'react-i18next';
import { saveSearchHistory } from '../../../../services/userCenter';
import { UserCenterEntityType } from '../../../../models/backend/userCenter';
import { recordUserActionsToGA } from '../../../../commons/ga/ga';
import { PAGE_ACTIONS } from '../../../../commons/ga/gaConsts';
import { getSearchedHistory, saveSearchAddressToHistory } from '../../support/common';
import { AppProvider } from '../../../../commons/appProvider';
import { domReady } from '../../../mobile/supportUtils/htmlUtils';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            background: 'white',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingRight: 10,
        },
        inputCombine: {
            display: 'flex',
            flexGrow: 1,
            // [theme.breakpoints.down('sm')]: {
            //     fontSize: '16px',
            // },
        },
        cancelButton: {
            fontSize: 14,
            cursor: 'pointer',
        },
        textInput: {
            border: 'none',
            outline: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            height: '100%',
            width: '100%',
            // [theme.breakpoints.down('sm')]: {
            //     width: '50vw',
            //     // minWidth: '190px', // when there is scan button
            // },
        },
        searchHistoryTitle: {
            color: '#999999',
            display: 'flex',
            fontSize: isMobileDevice() ? 12 : 14,
            justifyContent: 'center',
        },
        chooseList: {
            background: 'white',
            padding: 10,
            position: 'absolute',
            top: 50,
            height: 260,
            overflowY: 'auto',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
        },
        selectItem: {
            padding: isMobileDevice() ? 5 : 15,

            fontSize: isMobileDevice() ? 12 : 14,
            cursor: 'pointer',
            '&:hover': {
                background: getThemeValue('SELECT_ITEM_HOVER_COLOR'),
            },
        },
        searchIcon: {
            width: 30,
            height: 30,
            background: '#009e90',
            borderRadius: '5px',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
});
interface SearchInputProps {
    cssStyle?: {
        background?: string;
        width?: number;
        padding: string;
        inputStyles?: InputCombineStyle;
        borderRadius?: any;
    };
    searchIcon?: string;
    searchAddress?: boolean;
    searchSuburb?: boolean;
    foldEvent: any;
    caller?: string; // priceMap/
    propertySelectedEvent?: any;
    showCancel?: boolean;
    id?: string;
}
// const keyword: any = rst.stateS('');

export const SuburbSearchAutoComplete = rst.create<SearchInputProps>((ctx) => {
    window.addEventListener('mouseup', (e) => {
        //console.log(chooseDivEle.contains(e.target));
        if (chooseDivEle && !chooseDivEle.contains(e.target) && ctx.props.caller == 'home') {
            chooseList.value = [];
            searchHistoryList.value = [];
            ctx.props.foldEvent();
            EventBus.dispatch(EVENT_NAMES.EVENT_FOLD_SEARCH_INPUT, {});
        }
    });

    const securityProvider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const { state: appState } = appProvider;
    const searchHistoryList: any = rst.stateS([]);
    const chooseList: any = rst.stateS([]);
    const suburbList: any = rst.stateS([]);
    const getKeyword: any = rst.stateS('');
    const state = rst.state({
        choosedItem: {},
    });
    function searchAddressFromBackend(keyword) {
        return new Promise(async (resolve, reject) => {
            API.get(null, MAP_APIS_ENDPOINTS.SEARCH_ADDRESS_BY_KEY + '?address_strings=' + keyword, {}).then(
                (result: any) => {
                    if (result && result.length > 0) {
                        result.map((loopItem) => {
                            chooseList.value.push({
                                type: 'property',
                                id: loopItem.propId,
                                name: loopItem.address,
                                nameBeforeKey: loopItem.address.slice(
                                    0,
                                    loopItem.address.toLowerCase().indexOf(keyword.toLowerCase()) === -1
                                        ? 0
                                        : loopItem.address.toLowerCase().indexOf(keyword.toLowerCase()),
                                ),
                                nameKey: loopItem.address.substr(
                                    loopItem.address.toLowerCase().indexOf(keyword.toLowerCase()) === -1
                                        ? 0
                                        : loopItem.address.toLowerCase().indexOf(keyword.toLowerCase()),
                                    keyword.length,
                                ),
                                nameAfterKey: loopItem.address.slice(
                                    loopItem.address.toLowerCase().indexOf(keyword.toLowerCase()) + keyword.length,
                                ),
                            });
                            console.log(loopItem.address);
                        });
                    }
                    resolve(true);
                },
                (err) => {
                    resolve(false);
                },
            );
            getKeyword.value = keyword;
            console.log(getKeyword.value);
        });
    }

    function cancelSearch() {
        chooseList.value = [];
        searchHistoryList.value = [];
        ctx.props.foldEvent();
        EventBus.dispatch(EVENT_NAMES.EVENT_FOLD_SEARCH_INPUT, {});

        //EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_SEARCH_INPUT, { showSearchInput: false });
    }
    var allSuburbs: any = [];
    function searchSuburb(suburbKey) {
        return new Promise(async (resolve, reject) => {
            if (allSuburbs.length == 0) {
                allSuburbs = await API.get(null, 'static/data/suburbList.json');
            }

            const searchedSuburbs = allSuburbs.filter((loopSuburb: any) => {
                if (
                    loopSuburb.locality_name &&
                    loopSuburb.locality_name.toLowerCase().indexOf(suburbKey.toLowerCase()) > -1
                ) {
                    //new list for ordering the suburb @Bruce
                    suburbList.value.push({
                        type: 'suburb',
                        id: loopSuburb.id,
                        name: loopSuburb.locality_name,
                        nameBeforeKey: loopSuburb.locality_name.slice(
                            0,
                            loopSuburb.locality_name.toLowerCase().indexOf(suburbKey.toLowerCase()),
                        ),
                        nameKey: loopSuburb.locality_name.substr(
                            loopSuburb.locality_name.toLowerCase().indexOf(suburbKey.toLowerCase()),
                            suburbKey.length,
                        ),
                        nameAfterKey: loopSuburb.locality_name.slice(
                            loopSuburb.locality_name.toLowerCase().indexOf(suburbKey.toLowerCase()) + suburbKey.length,
                        ),
                    });
                }
            });
            //order the list by the No. of indexOf the keyword in suburb name @Bruce
            function compare(property) {
                return function (obj1, obj2) {
                    var value1 = obj1[property].toLowerCase().indexOf(suburbKey.toLowerCase());
                    var value2 = obj2[property].toLowerCase().indexOf(suburbKey.toLowerCase());
                    return value1 - value2;
                };
            }
            suburbList.value = suburbList.value.sort(compare('name'));
            chooseList.value = suburbList.value.concat(chooseList.value); //combine the suburb and address array @Bruce
            console.log(chooseList);
            console.log(suburbList);

            resolve(searchedSuburbs);
            getKeyword.value = suburbKey;
            console.log(getKeyword.value);
        });
    }

    async function autocomplete(keyword) {
        chooseList.value = [];
        suburbList.value = [];
        if (ctx.props.searchAddress) {
            await searchAddressFromBackend(keyword.replace(/^\s*|\s*$/g, ''));
        }
        if (ctx.props.searchSuburb) {
            await searchSuburb(keyword.replace(/^\s*|\s*$/g, ''));
        }
        chooseList.forceUpdate();
    }

    function chooseItemEvent(choosedItem) {
        console.log(choosedItem);
        chooseList.value = [];
        if (choosedItem.type == 'suburb') {
            recordUserActionsToGA(PAGE_ACTIONS.priceMap.label, PAGE_ACTIONS.priceMap.events.searchBySuburb.description);
            if (ctx.props.caller == 'priceMap' && !isMobileDevice()) {
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, {
                    suburbId: choosedItem.id,
                    suburbName: choosedItem.name,
                });

                ctx.props.foldEvent();
            } else {
                ctx.props.foldEvent(choosedItem.id, choosedItem.name);
            }
        }
        if (choosedItem.type == 'property') {
            recordUserActionsToGA(
                PAGE_ACTIONS.priceMap.label,
                PAGE_ACTIONS.priceMap.events.searchByAddress.description,
            );
            // if showing around, should cancel it , then do rest
            if (MapGlobalVars.showAroundTransCircle) {
                EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, {
                    showAroundTrans: false,
                });
                MapGlobalVars.showAroundTransCircle = false;
            }

            ctx.props.propertySelectedEvent(choosedItem.id);
            state.choosedItem = choosedItem;

            //if for mobile , need to show or not show the bottom according showing the input or not
            if (isMobileDevice()) {
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_SEARCH_INPUT, { showSearchInput: false });
            }

            saveSearchHistory({ entityId: `${choosedItem.id}`, entityType: UserCenterEntityType.Property });
            if (!choosedItem.inHistory) {
                saveSearchAddressToHistory(choosedItem.id, choosedItem.name); // save to client side search history
            }
        }
    }

    let panelWidth = 0;
    let panelHeight = 0;
    let chooseDivEle;

    const setChooseListPosition = (chooseListRef) => {
        if (ctx.props.id) {
            domReady(ctx.props.id).then((addressBar: any) => {
                const addressBarWidth = addressBar.offsetWidth;
                const addressBarLeft = addressBar.offsetLeft;
                const addressBarTop = addressBar.offsetTop;
                const addressBarHeight = addressBar.offsetHeight;
                chooseListRef.style.width = addressBarWidth + 'px';
                chooseListRef.style.top = addressBarTop + addressBarHeight + 'px';
                chooseListRef.style.right = addressBarLeft + 'px';
            });
        }
    };

    function setupChooseListDiv(chooseListRef) {
        if (chooseListRef) {
            // in firstpage change the width and position of search result
            if (ctx.props.caller == 'firstPage' && !isMobileDevice()) {
                chooseListRef.style.width = '992px';
                chooseListRef.style.top = '85px';
                chooseListRef.style.right = '0px';
            } else if (ctx.props.caller == 'firstPage' && isMobileDevice()) {
                chooseListRef.style.width = '100vw';
                chooseListRef.style.top = '52px';
                chooseListRef.style.right = '0px';
            } else if (ctx.props.caller === 'home' && !isMobileDevice()) {
                chooseListRef.style.width = '623px';
                chooseListRef.style.top = '52px';
                chooseListRef.style.left = '0px';
            } else if (ctx.props.caller === 'home' && isMobileDevice()) {
                chooseListRef.style.width = '253px';
                chooseListRef.style.top = panelHeight + 15 + 'px';
                chooseListRef.style.left = '0px';
            } else if (ctx.props.caller == 'homePop' && !isMobileDevice()) {
                chooseListRef.style.width = '450px';
                chooseListRef.style.top = '50px';
                chooseListRef.style.left = '20px';
                chooseListRef.style.borderRadius = '10px';
            } else if (ctx.props.caller == 'homePop' && isMobileDevice()) {
                chooseListRef.style.width = '210px';
                chooseListRef.style.top = '40px';
                chooseListRef.style.height = '100px';
                chooseListRef.style.left = '10px';
                chooseListRef.style.borderRadius = '10px';
            } else {
                chooseListRef.style.width = panelWidth + 'px';
                chooseListRef.style.top = isMobileDevice() ? panelHeight + 30 + 'px' : panelHeight + 100 + 'px';
            }

            if (ctx.props.caller == 'mobileHome') {
                setChooseListPosition(chooseListRef);
            }
        }
    }

    const searchListIndex = rst.stateS(0);

    //use arrow key up and down @Bruce
    const moveUp = () => {
        if (searchListIndex.value > 0) {
            searchListIndex.value = searchListIndex.value - 1;
        }
    };
    const moveDown = () => {
        if (searchListIndex.value < chooseList.value.length - 1) {
            searchListIndex.value = searchListIndex.value + 1;
        }
    };

    const handleKeyDown = (e, choosedItem) => {
        if (e.keyCode === 38) {
            console.log('up');
            moveUp();
        } else if (e.keyCode === 40) {
            console.log('down');
            moveDown();
        } else if (e.keyCode === 13) {
            console.log('enter');
            chooseItemEvent(choosedItem);
        } else {
            searchListIndex.value = 0;
        }
    };

    const retrieveHistory = async () => {
        // if (!securityProvider.hasLogin()) {
        //     appState.authDialog = 'login';
        //     console.log(appState.authDialog);
        // } else {
        if (ctx.props.searchAddress) {
            searchHistoryList.value = await getSearchedHistory();
        }

        // }
    };
    const clearAllListOnLF = () => {};

    return (props) => {
        const classes = useStyles();

        return (
            <div
                id={props.id}
                ref={(chooseDiv) => {
                    chooseDivEle = chooseDiv;
                }}>
                <div
                    onKeyDown={(e) => handleKeyDown(e, chooseList.value[searchListIndex.value])}
                    ref={(inputPanelRef) => {
                        if (panelWidth == 0 && inputPanelRef) {
                            panelWidth = inputPanelRef.offsetWidth;
                            panelHeight = inputPanelRef.offsetHeight;
                        }
                    }}
                    className={classes.root}
                    style={{
                        background: props?.cssStyle?.background,
                        padding: props.cssStyle?.padding,
                        borderRadius: props.cssStyle?.borderRadius,
                    }}>
                    <InputCombine
                        inputStyles={props.cssStyle?.inputStyles}
                        searchIcon={props.searchIcon ? props.searchIcon : 'search'}
                        choosedItem={state.choosedItem}
                        focusEvent={retrieveHistory}
                        caller={props.caller}
                        blurEvent={clearAllListOnLF}
                        searchEvent={autocomplete}></InputCombine>
                    {props.showCancel ? (
                        <div className={classes.cancelButton} onClick={cancelSearch}>
                            <TR name={'CANCEL'}></TR>
                        </div>
                    ) : null}
                </div>

                {chooseList.value.length == 0 && searchHistoryList.value.length == 0 ? null : (
                    <div
                        className={classes.chooseList}
                        ref={(chooseListRef) => {
                            setupChooseListDiv(chooseListRef);
                        }}>
                        {searchHistoryList.value.length == 0 || chooseList.value.length > 0 ? null : (
                            <>
                                <div className={classes.searchHistoryTitle}>
                                    <TR name={'searchHistoryTitle'}></TR>
                                </div>
                                <div style={{ marginTop: 5 }}></div>
                                {searchHistoryList.value.map((loopItem, i) => {
                                    return (
                                        <CompleteSingleItem
                                            chooseItemEvent={() => {
                                                chooseItemEvent(loopItem);
                                            }}
                                            selectItem={loopItem}
                                            isSelect={searchListIndex.value === i}
                                        />
                                    );
                                })}
                            </>
                        )}
                        {chooseList.value.map((loopItem, i) => {
                            return (
                                <CompleteSingleItem
                                    chooseItemEvent={() => {
                                        chooseItemEvent(loopItem);
                                    }}
                                    selectItem={loopItem}
                                    isSelect={searchListIndex.value === i}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };
});

const CompleteSingleItem = rst.create<{
    selectItem: any;
    chooseItemEvent: any;
    isSelect: boolean;
    // changeSearchListIndex: any;
}>((ctx) => {
    return (props) => {
        const classes = useStyles();
        console.log(props.selectItem);
        return (
            <div
                className={classes.selectItem}
                onClick={props.chooseItemEvent}
                style={props.isSelect ? { background: 'rgb(240 240 240)' } : null}>
                {props.selectItem.inHistory ? (
                    props.selectItem.name
                ) : (
                    <>
                        {props.selectItem.nameBeforeKey}
                        <strong>{props.selectItem.nameKey}</strong>
                        {props.selectItem.nameAfterKey}
                    </>
                )}
            </div>
        );
    };
});

interface InputCombineStyle {
    background?: string;
    borderRadius?: number;
    height?: number;
    fontSize?: number;
    placholder?: string;
    paddingLeft?: number;
    paddingRight?: number;
}
const InputCombine = rst.create<{
    inputStyles?: InputCombineStyle;
    searchIcon: string;
    searchEvent: any;
    choosedItem: any;
    focusEvent: any;
    blurEvent: any;
    caller: any;
}>((ctx) => {
    const typedValue = rst.stateS('');
    const state = rst.state({
        clear: false,
    });

    let searchHandler: any;
    const inputChangeHandle = (evt) => {
        console.log(evt.target.value);
        unstable_disableDelay(() => {
            typedValue.value = evt.target.value;
        });
        // keyword.value = evt.target.value;
        // console.log(typedValue.value);
        // console.log(keyword.value);

        if (searchHandler) {
            clearTimeout(searchHandler);
        }
        searchHandler = setTimeout(() => {
            ctx.props.searchEvent(typedValue.value);
        }, 500);
    };
    //click the search in firstPage then scroll to top in mobile version @Bruce
    const handleScrollToAnchor = (anchorName: string) => {
        if (isMobileDevice()) {
            if (anchorName) {
                const anchorElement = document.getElementById(anchorName);
                if (anchorElement) {
                    anchorElement.scrollIntoView();
                }
            }
        }
    };
    const inputFocus = (inputEle) => {
        if (ctx.props.caller == 'priceMap') {
            if (inputEle) {
                inputEle.focus();
            }
        }
    };

    const clearAddress = () => {
        typedValue.value = '';
        state.clear = true;
    };
    if (ctx.props.choosedItem) {
        typedValue.value = ctx.props.choosedItem.name;
    }
    return (props) => {
        const classes = useStyles();
        const { t } = useTranslation();

        const { inputStyles } = props;

        state.clear = false;
        return (
            <div
                className={classes.inputCombine}
                style={{
                    height: inputStyles?.height,
                    background: inputStyles?.background,
                    borderRadius: inputStyles?.borderRadius,
                    paddingLeft: inputStyles?.paddingLeft,
                    paddingRight: inputStyles?.paddingRight,
                }}>
                <input
                    type="text"
                    autoComplete="off"
                    value={typedValue.value}
                    id="searchInputElement"
                    onFocus={props.focusEvent}
                    onBlur={props.blurEvent}
                    onChange={inputChangeHandle}
                    // onClick={() => handleScrollToAnchor('scrollToTop')}
                    ref={(inputRef) => {
                        inputFocus(inputRef);
                    }}
                    className={classes.textInput}
                    placeholder={t(inputStyles?.placholder)}
                    style={{
                        fontSize: inputStyles?.fontSize,
                        width:
                            props.caller == 'homePop'
                                ? isMobileDevice()
                                    ? '210px'
                                    : '410px'
                                : props.caller == 'home' && isMobileDevice()
                                ? '50vw'
                                : null,
                    }}></input>
                {typedValue.value ? (
                    <div style={{ position: 'absolute', right: 5, top: 10 }} className={classes.searchIcon}>
                        <img
                            src={'assets/icons/searchIcon.svg'}
                            width="24"
                            style={{ cursor: 'pointer' }}
                            onClick={clearAddress}
                        />
                    </div>
                ) : (
                    <div style={{ position: 'absolute', right: 5, top: 10 }} className={classes.searchIcon}>
                        <img src={'assets/icons/' + 'searchIcon.svg'} style={{ width: 24 }} />
                    </div>
                )}
            </div>
        );
    };
});

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { TR } from '../../../../commons';
import { convertToM, sortNumArray } from '../../../../commons/utils';
import { calculateMedianPrice } from '../../../PriceMap/support/common';

const useStyles = makeStyles({
    blueBar: {
        background: '#009e90',
        height: '6px',
        width: '50%',
        borderTopLeftRadius: '3px',
        borderBottomLeftRadius: '3px',
    },
    redBar: {
        background: '#ff2200',
        height: '6px',
        width: '50%',
        borderTopRightRadius: '3px',
        borderBottomRightRadius: '3px',
    },
    greyBar: {
        position: 'absolute',
        left: 0,
        zIndex: 1,
        top: 10,
        background: '#d8d8d8',
        height: '6px',
        width: '100%',
        borderRadius: '3px',
    },
});
export const MobileAroundPriceBar = (props) => {
    const { showPrice, solds } = props;
    const prices = solds.map((sold) => {
        return sold.soldPrice;
    });
    console.log(prices);
    var medians: any = {};
    if (prices.length > 2) {
        medians = calculateMedianPrice(prices);
    } else if (prices.length == 2) {
        medians.range = {
            min: prices[0] < prices[1] ? prices[0] : prices[1],
            max: prices[0] < prices[1] ? prices[1] : prices[0],
        };
    } else {
        medians.median = prices[0];
    }
    console.log(medians);

    console.log(prices);
    return (
        <>{solds.length <= 4 ? <ShowPriceBar solds={solds}></ShowPriceBar> : <NoShowPriceBar medians={medians} />}</>
    );
};

const NoShowPriceBar = (props) => {
    const { medians } = props;
    const classes = useStyles();
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <TR name="lowestPrice"></TR>
                </div>

                {medians.median > 0 && (
                    <div>
                        {' '}
                        <TR name="MiddlePrice"></TR>
                    </div>
                )}
                <div>
                    {' '}
                    <TR name="HighestPrice"></TR>
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: '9px' }}>
                <div className={classes.blueBar}></div>
                <div className={classes.redBar}></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '3px', fontWeight: 700 }}>
                <div>${convertToM(medians.range?.min)}m</div>
                {medians.median && <div>${convertToM(medians.median)}m</div>}
                <div>${convertToM(medians.range?.max)}m</div>
            </div>
        </div>
    );
};

const ShowPriceBar = (props) => {
    const { solds } = props;
    const soldPrices = solds.map((sold) => {
        return sold.soldPrice;
    });
    const sortedSoldPrices = sortNumArray(soldPrices);
    const classes = useStyles();
    return (
        <div>
            <div style={{ position: 'relative', width: '100%' }}>
                <div className={classes.greyBar}></div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: solds.length == 1 ? 'center' : 'space-between',
                        position: 'absolute',
                        zIndex: 2,
                        width: '100%',
                    }}>
                    {solds.map((sold) => {
                        return <img src="/assets/mobileIcons/house-ico.svg"></img>;
                    })}
                </div>
            </div>
            <div style={{ width: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: solds.length == 1 ? 'center' : 'space-between',
                        marginTop: '29px',
                        fontWeight: 700,
                        width: '100%',
                    }}>
                    {sortedSoldPrices.map((price) => {
                        return <div>${convertToM(price)}m</div>;
                    })}
                </div>
            </div>
        </div>
    );
};

import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { rst } from 'rt-state';
import React from 'react';
import { isMobileDevice } from '../../../commons/utils';
import { TR } from '../../../commons';
import { ItemLabel } from './ItemLabel';

export const SliderItem = rst.create<{ itemName: string; valueUnit?: string; slideChangeEvent: any; valueRange: any }>(
    (ctx) => {
        function valuetext(value, idx) {
            const slideLabel = `${value}${ctx.props.valueUnit || ''}`;

            if (
                (ctx.props.valueRange.length == 2 &&
                    (value == ctx.props.valueRange[0] ||
                        value == ctx.props.valueRange[ctx.props.valueRange.length - 1])) ||
                (ctx.props.valueRange.length > 2 && (value == 0 || value == ctx.props.valueRange.length - 1))
            ) {
                return <TR name="ALL"></TR>;
            } else {
                if (ctx.props.valueRange.length == 2) {
                    return (
                        <>
                            {value}

                            {ctx.props.valueUnit == 'SQM' ? 'm' : ctx.props.valueUnit}
                            {ctx.props.valueUnit == 'SQM' ? <sup>2</sup> : null}
                        </>
                    );
                } else {
                    return (
                        <>
                            {ctx.props.valueRange[value]}

                            {ctx.props.valueUnit == 'SQM' ? 'm' : ctx.props.valueUnit}
                            {ctx.props.valueUnit == 'SQM' ? <sup>2</sup> : null}
                        </>
                    );
                }
            }
        }

        const slideValue = rst.stateS([0, 0]);
        let slideRange = [];

        if (ctx.props.valueRange.length == 2) {
            slideRange = ctx.props.valueRange;
        } else {
            slideRange = [0, ctx.props.valueRange.length - 1];
        }
        slideValue.value = slideRange;

        function handleSlide(evt, newValue) {
            slideValue.value = newValue;
        }
        function changeEnd() {
            if (ctx.props.valueRange.length == 2) {
                ctx.props.slideChangeEvent(slideValue.value);
            } else {
                ctx.props.slideChangeEvent([
                    ctx.props.valueRange[slideValue.value[0]],
                    ctx.props.valueRange[slideValue.value[1]],
                ]);
            }
        }
        return (props) => {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '27px',
                        width: '100%',
                        alignItems: 'center',
                    }}>
                    <ItemLabel style={{ minWidth: 80 }}>
                        <TR name={props.itemName} />
                    </ItemLabel>

                    <SliderBase
                        style={{ padding: 5, width: isMobileDevice() ? '65%' : '70%' }}
                        valueLabelDisplay="on"
                        min={slideRange[0]}
                        max={slideRange[1]}
                        onChange={handleSlide}
                        value={slideValue.value}
                        onChangeCommitted={() => {
                            changeEnd();
                        }}
                        valueLabelFormat={valuetext}></SliderBase>
                </div>
            );
        };
    },
);

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const SliderBase = withStyles({
    root: {
        color: '#3880ff',
        height: 2,
        padding: '15px 0',
    },
    thumb: {
        height: isMobileDevice() ? 12 : 16,
        width: isMobileDevice() ? 12 : 16,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        marginTop: isMobileDevice() ? -6 : -8,
        marginLeft: isMobileDevice() ? -6 : -8,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: isMobileDevice() ? 'calc(-50%-2px)' : 'calc(-50% + 3px)',
        fontSize: isMobileDevice() ? '10px' : '12px',
        top: isMobileDevice() ? 15 : 22,
        '& *': {
            background: 'transparent',
            color: ' #1875f0;',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);

import { Divider, Hidden, makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { isMobileDevice } from '../../../commons/utils';
import { NewButton } from './NewButton';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '1200px',
        padding: '10px 10px 30px 10px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '28px 16px',
            width: '100vw',
            // justifyContent: 'space-between',
        },
        '& h2': {
            fontSize: '26px',
            margin: '45px 0px 40px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '19px',
                margin: '0px 0px 10px 0px',
            },
        },
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '30px',
        '& img': {
            marginTop: '5px',
            [theme.breakpoints.down('sm')]: {
                width: '30px',
                margin: '5px 5px 0px 0px',
            },
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingLeft: '31px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0px',
        },

        '& h3': {
            fontSize: '20px',
            margin: '0px 0px 13px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '26px',
                margin: '0px 0px 5px 0px',
            },
        },
        '& p': {
            fontSize: '16px',
            lineHeight: '22px',
            margin: '0px 0px 0px 0px',
            // textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                margin: '0px',
                fontSize: '13px',
                lineHeight: '18px',
            },
        },
    },
    button: {
        margin: '40px 0px 20px 0px',
        [theme.breakpoints.down('sm')]: {
            margin: '20px 0px 0px 0px',
        },
    },
}));
export const NewHomeData = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();

        return (
            <div style={{ background: 'rgb(255 255 255)' }}>
                <div className={classes.root}>
                    {/* <h2>
                        <TR name={'NewHome_NewHomeDataHeader'} />
                    </h2> */}
                    <div className={classes.item}>
                        {/* <Hidden smDown> */}
                        <img src={`${process.env.PUBLIC_URL}/static/images/data-ico.png`} alt="data-ico.png" />
                        {/* </Hidden> */}
                        <div className={classes.content}>
                            <h3>
                                <TR name={'NewHome_NewHomeDataSubHeader1'} />
                            </h3>
                            <p>
                                <TR name={'NewHome_NewHomeDataContent1'} />
                            </p>
                        </div>
                    </div>
                    <Hidden mdUp>
                        <Divider />
                    </Hidden>
                    <div className={classes.item}>
                        {/* <Hidden smDown> */}
                        <img src={`${process.env.PUBLIC_URL}/static/images/artificial-ico.png`} alt="data-ico.png" />
                        {/* </Hidden> */}
                        <div className={classes.content}>
                            <h3>
                                <TR name={'NewHome_NewHomeDataSubHeader2'} />
                            </h3>
                            <p>
                                <TR name={'NewHome_NewHomeDataContent2_1'} />
                            </p>
                            {/* <p>
                                <TR name={'NewHome_NewHomeDataContent2_2'} />
                            </p> */}
                        </div>
                    </div>
                    <Hidden mdUp>
                        <Divider />
                    </Hidden>
                    <div className={classes.item} style={{ marginBottom: '0px' }}>
                        {/* <Hidden smDown> */}
                        <img src={`${process.env.PUBLIC_URL}/static/images/analysis-ico.png`} alt="data-ico.png" />
                        {/* </Hidden> */}
                        <div className={classes.content}>
                            <h3>
                                <TR name={'NewHome_NewHomeDataSubHeader3'} />
                            </h3>
                            <p>
                                <TR name={'NewHome_NewHomeDataContent3'} />
                            </p>
                            {/* <div className={classes.button}>
                                <NewButton
                                    backgroundColor="#FF4600"
                                    padding={isMobileDevice() ? '10px 40px' : '10px 58px'}
                                    color="white"
                                    label="NewHome_NewHomeDataButton"
                                    icon={1}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
});

import { rst } from 'rt-state';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { TR } from '../../../commons';
import { XAuthDialog } from '../../../components/Dialog/XAuthDialog';
import { LoginComp } from '../Login';
import { SignupComp } from '../Signup';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppProvider } from '../../../commons/appProvider';
import { isMobileDevice } from '../../../commons/utils';

const useUserStyles = makeStyles((theme) => ({
    tabLabel: {
        fontSize: '14px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    greenLogin: {
        // background: '#FF4600',
        background: 'rgb(74 165 154)',
        color: 'white',
    },
}));

export const LoginOrSignupButton = rst.create(() => {
    const appProvider = AppProvider.use();

    return () => {
        const classes = useUserStyles();
        return (
            <Grid container justify={'center'}>
                {isMobileDevice() ? (
                    <Button
                        onClick={() => appProvider.openLoginDialog(null)}
                        size="small"
                        variant="contained"
                        className={classes.greenLogin}
                        style={{ marginLeft: 10 }}>
                        <Typography className={classes.tabLabel}>
                            <TR name={'Login'} />
                        </Typography>
                    </Button>
                ) : (
                    <Button
                        onClick={() => appProvider.openLoginDialog(null)}
                        size="small"
                        variant="contained"
                        className={classes.greenLogin}
                        // style={{ boxShadow: 'none' }}
                    >
                        <Typography className={classes.tabLabel}>
                            <TR name={'NewHome_login'} />
                        </Typography>
                    </Button>
                )}
                <LoginOrSignupDialog />
            </Grid>
        );
    };
});

export const LoginOrSignupDialog = rst.create((ctx) => {
    const appProvider = AppProvider.use();
    const { state } = appProvider;

    return (props) => {
        return (
            <XAuthDialog
                open={state.authDialog === 'login' || state.authDialog === 'signup'}
                onClose={appProvider.onCloseAuthDialog}>
                {state.authDialog === 'login' && (
                    <LoginComp
                        onClose={appProvider.onCloseAuthDialog}
                        goToSignup={() => (state.authDialog = 'signup')}
                    />
                )}
                {state.authDialog === 'signup' && (
                    <SignupComp
                        onClose={appProvider.onCloseAuthDialog}
                        goToLogin={() => (state.authDialog = 'login')}
                    />
                )}
            </XAuthDialog>
        );
    };
});

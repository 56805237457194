import { Divider, Hidden, makeStyles, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../../commons';
import { AppProvider } from '../../../../commons/appProvider';
import { QRCode } from '../../../NewHome/components/QRCode';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { styled } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '30px 0px 30px 0px',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        //flexDirection: isMobileDevice() ? 'column' : 'row',
        justifyContent: 'center',
        //fontSize: isMobileDevice() ? '13px' : '16px',
        [theme.breakpoints.down('sm')]: {
            padding: '28px 16px 0px 16px',
        },
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        // marginBottom: '30px',
        padding: '0px 0px 0px 0px',
        background: 'rgb(255 255 255)',
        // '& img': {
        //     marginTop: '0px',
        //     [theme.breakpoints.down('sm')]: {
        //         width: '50%',
        //         margin: '0px 0px 0px 0px',
        //     },
        // },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '10px 10px 10px 10px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0px',
        },
    },
    title: {
        fontSize: '44px',
        textAlign: 'center',
        display: 'inline-block',
        //padding: '10px 10px 10px 10px',
        '& h4': {
            fontWeight: 'bold',
            fontSize: '30px',
            // display: 'inline-block',
            // fontWeight: 'normal',
            margin: '0px 0px 0px 0px',
            float: 'center',

            [theme.breakpoints.down('sm')]: {
                fontSize: '26px',
                lineHeight: '50px',
                margin: '0px 0px 5px 0px',
            },
        },
        '& p': {
            //fontSize: '14px',
            fontSize: '18px',
            //color: 'rgb(123 123 123)',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                margin: '0px',
                fontSize: '16px',
            },
        },
    },
    right: {
        padding: '10px 10px 10px 10px',
        textAlign: 'center',
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center',
        '& p': {
            //fontSize: '14px',
            fontSize: '16px',
            //color: 'rgb(123 123 123)',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                margin: '0px',
                fontSize: '14px',
            },
        },
        '& img': {
            //display: 'inline-block',
            fontWeight: 'normal',
            margin: '0px 0px 0px 0px',
            float: 'center',
            padding: '10px 10px 10px 10px',
            width: '300px',
            height: '300px',
            [theme.breakpoints.down('sm')]: {
                //fontSize: '14px',
                lineHeight: '26px',
                margin: '0px 0px 5px 0px',
            },
        },
    },
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    // border: `1px solid ${theme.palette.divider}`,
    width: '90%',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },

    '& .Mui-expanded': {
        minHeight: 0,
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon htmlColor="rgb(255, 255, 255)" />} {...props} />
))(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    // flexDirection: 'row-reverse',
    backgroundColor: 'rgb(0, 158, 144)',
    color: 'rgb(255, 255, 255)',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '0px',
    height: '40px',

    // '& .Mui-expanded': {
    //     minHeight: '0px',
    //     backgroundColor: 'white',
    // },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        flexGrow: 'inherit',
    },
    '& .MuiAccordionSummary.Mui-expanded': {
        minHeight: '0px',
        backgroundColor: 'blue',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
}));

export const MobileQRCode = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        const appProvider = AppProvider.use();

        if (appProvider.getLanguage() == 'zh') {
            return (
                <div style={{ background: 'rgb(255 255 255)' }}>
                    <div className={classes.root}>
                        <div className={classes.item}>
                            <div className={classes.content}>
                                <div className={classes.title}>
                                    <h4>
                                        <TR name={'qrCodeTitle1'} />
                                    </h4>
                                    <p>
                                        <TR name={'qrCodeTitle2'} />
                                    </p>
                                    <p>
                                        <TR name={'qrCodeTitle2b'} />
                                    </p>
                                    <p>
                                        <TR name={'qrCodeTitle2c'} />
                                    </p>
                                    <p>
                                        <TR name={'qrCodeTitle2d'} />
                                    </p>
                                    <p>
                                        <TR name={'qrCodeTitle2e'} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.right}>
                        <Accordion defaultExpanded={false}>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                // class={{ expanded: classes.ex }}
                            >
                                <Typography>
                                    <div
                                    // style={{ fontSize: '14px' }}
                                    >
                                        <TR name="contactUs"></TR>
                                    </div>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <QRCode></QRCode>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            );
        }
    };
});

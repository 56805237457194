import classes from '*.module.css';
import { Backdrop, Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import React, { useRef } from 'react';
import { rst, state } from 'rt-state';
import { isMobileDevice } from '../../commons/utils';

const useStyles = makeStyles((theme) => {
    return {
        videoPlayerDiv: {
            opacity: 1,
            width: '100%',
            height: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'black',
        },
        closeButton: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            fontSize: '16px',
            color: 'white',
            borderRadius: '50%',
            border: '1px white solid',
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
            zIndex: 999999999,
        },
    };
});

export const VideoPlayer = rst.create<{ open; closeVideoEvt; videoNo }>((ctx) => {
    const stateVars = rst.state({
        videoWidth: 100,
        videoHeight: 100,
    });
    setTimeout(() => {
        const videoEl: any = document.getElementById('videoPlayer');
        console.log('loading video');
        videoEl.addEventListener('loadeddata', () => {
            if (!isMobileDevice()) {
                stateVars.videoHeight = videoEl.videoHeight;
                stateVars.videoWidth = videoEl.videoWidth;
            } else {
                stateVars.videoWidth = window.screen.width;
                stateVars.videoHeight = stateVars.videoWidth * (videoEl.videoWidth / videoEl.videoHeight);
                console.log(stateVars.videoWidth + ';' + stateVars.videoHeight);
            }
        });
    });
    return (props) => {
        const videoURL = './videos/' + props.videoNo + '.mp4';

        console.log(videoURL);
        const classes = useStyles();
        return (
            <div className={classes.videoPlayerDiv}>
                <div className={classes.closeButton} onClick={props.closeVideoEvt}>
                    X
                </div>
                <video
                    autoPlay={true}
                    id="videoPlayer"
                    controls
                    width={stateVars.videoWidth}
                    height={stateVars.videoHeight}>
                    {/* <video autoPlay={true} id="videoPlayer" controls> */} <source src={videoURL} type="video/mp4" />
                </video>
            </div>
        );
    };
});

import { makeStyles, Popover } from '@material-ui/core';
import { map } from 'lodash';
import React from 'react';
import { rst } from 'rt-state';
import { SecurityProvider, storage } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { useRunOnce } from '../../../commons/hooks';
import { Permission } from '../../../commons/security/authz';
import { getCurrentYear, getParamValueFromURL, isMobileDevice } from '../../../commons/utils';
import { Tip } from '../../../components/Tip';
import { ROUTES } from '../../../routes';
import { api_getAroundTranscations } from '../../../services/property';
import { ConfirmDialog } from '../components/ConfirmDialog';
import { MapPopup } from '../components/MapPopup';
import { PriceSwitchButton } from '../Layers/MapControlLayer/PriceSwitchButton';

import { PropertySideBar } from '../Layers/PropertySidebar';
import { SuburbSoldByBed } from '../suburbSoldRank/SuburbSoldByBed';
import { SuburbSoldRank } from '../suburbSoldRank/SuburbSoldRank';

import { getPriceMapLevelByZoom } from '../support/common';

import {
    MapGlobalVars,
    MapInitParam,
    MapLevel,
    MapType,
    PRICE_MAP_STATUS,
    SuburbPriceColors,
} from '../support/PriceMapConsts';
import { getTypeByFeature } from '../support/propertyFeatureHelper';
import { PriceMapController } from './PriceMapController';

/*** Set suburb color if there is in url  */
const url = window.location.href;
if (url.indexOf('colors=') > -1) {
    const suburbColors = url.substring(url.indexOf('colors=') + 7);

    const suburbColorArray = suburbColors.split(',');

    suburbColorArray.forEach((loopColor, idx) => {
        SuburbPriceColors.Apt[idx].color = suburbColorArray[idx];
        SuburbPriceColors.House[idx].color = suburbColorArray[idx];
    });
}

const win: any = window;
const L = win.L;

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 0,
            fontFamily: theme.typography.fontFamily,
        },

        dummy: {},
    };
});

export const PriceMapContainer = rst.create<{ zoomHandler: any; fromIndex?: boolean }>((ctx) => {
    const securityProvider = SecurityProvider.use();
    let mapInstance;
    let mapController;

    storage.get('CLIENT_LAN').then((lan: any) => {
        MapGlobalVars.currentUserLan = lan;
    });

    document.getElementById('root').style.overflow = 'hidden';

    const state = rst.state({
        center:
            ctx.props.fromIndex && isMobileDevice() ? [-33.85060151680233, 151.20878219604495] : MapInitParam.center,
        zoom: MapInitParam.zoom,

        suburbInfo: {
            suburbData: {},
            medianData: {},
        },
        suburbPopPosition: {
            x: 0,
            y: 0,
        },
        showingProperty: {},
        showNonRecentSoldPropertyInfo: false,
        clickedProperty: null,
        clickedProp_aroundTrans: null,
        showingPopMenu: false,
        showingPoisition: { x: 0, y: 0 },
        clickedLocation: { lat: 0, lng: 0 },
        openDialog: false,
        showSwitchButton: false,
    });

    // const noLableLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
    //     maxZoom: 19,
    //     minZoom: 10,
    //     attribution: '&copy; <span style="color:red;fontWeight:bold;font-size : 12px">Homeon 2020</span>',
    // });

    const noLableLayer = L.tileLayer(
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png',
        {
            attribution:
                '&copy; <span style="color:red;fontWeight:bold;font-size : 12px">HomeOn 2018-' +
                getCurrentYear() +
                '</span>',
            subdomains: 'abcd',
            maxZoom: MapLevel.propertyLevel.upper,
            minZoom: MapLevel.suburbLevel.lower,
        },
    );

    const staliteLayer = L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        {
            attribution:
                'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            maxZoom: MapLevel.propertyLevel.upper,
            minZoom: MapLevel.suburbLevel.lower,
        },
    );

    const labelLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        attribution:
            '&copy; <span style="color:red;fontWeight:bold;font-size : 12px">Homeon 2018-' +
            getCurrentYear() +
            '</span>',
        subdomains: 'abcd',
        maxZoom: MapLevel.propertyLevel.upper,
        minZoom: MapLevel.suburbLevel.lower,
    });

    const railMapLayer = L.tileLayer('https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png', {
        maxZoom: 19,
        minZoom: MapLevel.suburbLevel.lower,
        attribution:
            'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Map style: &copy; <a href="https://www.OpenRailwayMap.org">OpenRailwayMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    });

    // const labelLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //     maxZoom: MapLevel.propertyLevel.upper,
    //     minZoom: MapLevel.suburbLevel.lower,
    //     attribution: '&copy; <span style="color:red;fontWeight:bold;font-size : 12px">Homeon 2020</span>',
    // });

    EventBus.on(EVENT_NAMES.EVENT_SHOW_POP_MENU_ON_MAP, (evt) => {
        state.showingPopMenu = true;
        state.showingPoisition = evt.point;
        state.clickedLocation = evt.location;
        MapGlobalVars.showingMapPopmenu = true;
    });

    EventBus.on(EVENT_NAMES.EVENT_CLOSE_POP_MENU_ON_MAP, () => {
        closeMapMenu();
    });

    EventBus.on(EVENT_NAMES.EVENT_SUBURB_POLYGON_CLICKED, (eventData) => {
        state.suburbInfo = {
            suburbData: eventData.suburbData,
            medianData: eventData.medianData,
        };
    });

    EventBus.on(EVENT_NAMES.EVENT_PROP_SIDE_BAR, (evt) => {
        state.showNonRecentSoldPropertyInfo = evt.showSideBar;
        if (evt.prop != null) {
            state.clickedProperty = evt.prop;
        }
    });

    EventBus.on(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, () => {
        state.showSwitchButton = true;
    });

    EventBus.on(EVENT_NAMES.EVENT_RESETMAP_AFTER_LOGIN, () => {
        if (mapInstance) {
            mapInstance.setMaxZoom(null);
        }
    });

    EventBus.on(EVENT_NAMES.EVENT_CHANGE_MAP_TYPE, () => {
        setMapType();
    });

    EventBus.on(EVENT_NAMES.EVENT_TOGGLE_RAILMAP_LAYER, () => {
        if (mapInstance.hasLayer(railMapLayer)) {
            mapInstance.removeLayer(railMapLayer);
        } else {
            mapInstance.addLayer(railMapLayer);
        }
    });

    EventBus.on(EVENT_NAMES.EVENT_MAP_ZOOMED, (zoom) => {
        // if from index and zoom greater than suburb level, need to give user prompt to confirm go into full screen or keep current
        if (zoom >= MapLevel.propertyLevel.lower && ctx.props.fromIndex) {
            state.openDialog = true;
            return;
        }
        if (getPriceMapLevelByZoom(zoom) != PRICE_MAP_STATUS.SUBURBLEVEL) {
            mapInstance.removeLayer(noLableLayer);
            setMapType();
        } else {
            if (mapInstance.hasLayer(labelLayer)) {
                mapInstance.removeLayer(labelLayer);
            } else {
                mapInstance.removeLayer(staliteLayer);
            }

            mapInstance.addLayer(noLableLayer);
            state.showSwitchButton = false;
        }

        if (getPriceMapLevelByZoom(zoom) == PRICE_MAP_STATUS.SUBURBLEVEL && state.showNonRecentSoldPropertyInfo) {
            state.showNonRecentSoldPropertyInfo = false;
        }
    });

    function closeMapMenu() {
        state.showingPopMenu = false;
        MapGlobalVars.showingMapPopmenu = false;
    }

    function setMapType() {
        if (MapGlobalVars.currentMapType == MapType.PLAIN) {
            mapInstance.removeLayer(staliteLayer);
            mapInstance.addLayer(labelLayer);
        } else {
            mapInstance.removeLayer(labelLayer);
            mapInstance.addLayer(staliteLayer);
        }
    }
    function initMap() {
        const mapBounds = L.latLngBounds(
            L.latLng(MapInitParam.bounds.corner1[0], MapInitParam.bounds.corner1[1]),
            !isMobileDevice()
                ? L.latLng(MapInitParam.bounds.corner2[0], MapInitParam.bounds.corner2[1])
                : L.latLng(MapInitParam.bounds.corner3[0], MapInitParam.bounds.corner3[1]),
        );
        const maxZoom = 99;

        // setTimeout(() => {
        //     if (
        //         securityProvider.hasPermission(Permission.PROPERTY_BUYER) ||
        //         securityProvider.hasPermission(Permission.TRIAL)
        //     ) {
        //         maxZoom = 99;
        //     } else {
        //         maxZoom = 12;
        //     }
        //     mapInstance.setMaxZoom(maxZoom);
        // }, 3000);

        mapInstance = L.map('mapid', {
            zoomControl: false,
            doubleClickZoom: false,
            maxBounds: mapBounds,
            maxZoom: maxZoom,
            gestureHandling: ctx.props.fromIndex ? true : false,
        }).setView(state.center, ctx.props.fromIndex ? 11 : state.zoom);

        noLableLayer.addTo(mapInstance);

        mapController = new PriceMapController(
            mapInstance,
            ctx.props.zoomHandler,
            ctx.props.fromIndex,
            state,
            securityProvider,
        );

        L.control.scale({ maxWidth: 200 }).addTo(mapInstance);
    }

    return (props) => {
        const okToGoFullMap = () => {
            state.openDialog = false;

            let fullScreenURL = ROUTES.PriceMap.path;
            if (mapController.priceMapFilter.suburbId) {
                fullScreenURL += '?suburbId=' + mapController.priceMapFilter.suburbId;
                fullScreenURL += '&suburbName=' + mapController.priceMapFilter.suburbName;
            } else {
                fullScreenURL += '?zoomFromIndex=1';
            }
            window.open(fullScreenURL, '_self');
        };
        const notOkToFullMap = () => {
            state.openDialog = false;
            mapController.priceMapFilter.suburbId = '';
            mapController.priceMapFilter.suburbName = '';
            mapInstance.setZoom(MapLevel.suburbLevel.upper);
        };
        useRunOnce(initMap);
        const classes = useStyles();
        return (
            <>
                <div className={classes.root} style={{ height: isMobileDevice() && !props.fromIndex ? '75%' : '100%' }}>
                    <div id="mapid" />
                </div>

                <PropertySideBar
                    hide={!state.showNonRecentSoldPropertyInfo || !state.clickedProperty.allFeatures}
                    prop={state.clickedProperty}></PropertySideBar>
                <div
                    style={{
                        display: state.showSwitchButton ? '' : 'none',
                    }}>
                    <PriceSwitchButton></PriceSwitchButton>
                </div>

                <SuburbSoldRank></SuburbSoldRank>
                <SuburbSoldByBed></SuburbSoldByBed>

                <MapPopup
                    popProps={{
                        open: state.showingPopMenu,
                        position: state.showingPoisition,
                        location: state.clickedLocation,
                    }}
                    closeEvent={closeMapMenu}></MapPopup>

                <ConfirmDialog okEvt={okToGoFullMap} cancelEvt={notOkToFullMap} open={state.openDialog}></ConfirmDialog>
            </>
        );
    };
});

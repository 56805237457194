import { Exclude, plainToClassFromExist, Type } from 'class-transformer';

export class APIResponse<T> {
    @Exclude()
    private _type: Function;

    @Type((options) => {
        return (options.newObject as APIResponse<T>)._type;
    })
    items: T[];
    totalCount: number;

    constructor(type: Function) {
        this._type = type;
    }

    static convert<T>(clazz: { new (): T }, data: any) {
        return plainToClassFromExist(new APIResponse<T>(clazz), data);
    }
}

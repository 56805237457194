import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import * as locales from '@material-ui/core/locale';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            background: 'rgb(249 249 249)',
            //width: '396px',
            padding: '15px 19px 20px 19px',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
        },
        part1: {
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        part1Bl: {
            background: 'rgb(51 119 230)',
            borderRadius: '10px',
            width: '182px',
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'center',
            padding: '8px 13px',
            // height: '40px',
            '& h2': {
                fontSize: '15px',
                color: 'white',
                margin: '0',
                letterSpacing: '-1px',
            },
            // '& span': {
            //     // borderLeft: '1px solid black',
            //     border: '1px solid rgb(51 119 230)',
            //     width: '200px',
            //     position: 'relative',
            //     right: '30px',
            //     top: '300px',
            //     zIndex: 999,
            // },
        },
        part1Or: {
            background: 'rgb(225 119 53)',
            borderRadius: '10px',
            width: '148px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px 13px',
            position: 'relative',
            top: '20px',
            '& h2': {
                fontSize: '15px',
                color: 'white',
                margin: '0',
                letterSpacing: '-1px',
                width: '133px',
            },
        },
        part3: {
            margin: '0 auto',
            '& img': {
                width: '400px',
                display: 'flex',
                justifyContent: 'center',
                margin: '0 auto',
                zIndex: 1,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            },
        },
        part4: {
            padding: '0px 0px 0px 0px',
            '& span': {
                fontSize: '14px',
                color: '#666666',
            },
        },
    };
});

export const CardFrontpage = rst.create((ctx) => {
    const appProvider = AppProvider.use();

    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                {/* <div className={classes.part1}> */}
                {/* <div className={classes.part1Bl}>
                        <h2>
                            <TR name={'CardFrontpage_Blue'} />
                        </h2>
                    </div> */}
                {/* <span
                        style={{
                            borderBottom: '1px solid rgb(51 119 230)',
                            width: '40px',
                            position: 'relative',
                            right: '130px',
                            top: '60px',
                            zIndex: 999,
                        }}></span> */}
                {/* <div className={classes.part1Or}>
                        <h2>
                            <TR name={'CardFrontpage_Orange'} />
                        </h2>
                    </div> */}
                {/* </div> */}
                <div className={classes.part3}>
                    <img
                        src={
                            appProvider.getLocale() === locales.zhCN
                                ? `${process.env.PUBLIC_URL}/static/images/RptHeader-zh-1.png`
                                : `${process.env.PUBLIC_URL}/static/images/RptHeader-en-1.png`
                        }
                        alt="card.png"
                    />
                </div>
                {/* <div className={classes.part4}>
                    <span>
                        <TR name={'CardFrontpage_Content'} />
                    </span>
                </div> */}
            </div>
        );
    };
});

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { rst } from 'rt-state';
import { BorderRight } from '@material-ui/icons';
import { generateGUID } from '../../commons';
const useStyles = makeStyles((theme) => {
    return {
        progress: {
            position: 'relative',
            margin: '4px',
            float: 'left',
            textAlign: 'center',
        },
        barOverflow: {
            /* Wraps the rotating .bar */ position: 'relative',
            width: '36px',
            height: '18px' /* Half circle (overflow) */,
            marginBottom: '-14px' /* bring the numbers up */,
            overflow: 'hidden',
        },
        bar: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: 36,
            height: 36 /* full circle! */,
            borderRadius: '50%',
            boxSizing: 'border-box',
            border: '3px solid #eeeeee' /* half gray, */,

            borderBottomColor: '#FF9F40' /* half azure */,

            borderRightColor: '3px solid #0bf',
        },
    };
});
export const SemiCircle = rst.create<{ value: number; borderColor?: string }>((ctx) => {
    const componentId = generateGUID();
    return (props) => {
        setTimeout(() => {
            var $bar = document.getElementById(componentId + '_semiCircleBar');
            var $val = document.getElementById(componentId + '_semiCircleBarLabel');
            if ($val) {
                var perc = parseInt($val.innerText, 10);

                const rotateDeg = props.value;
                if (props.value > 50) {
                    $bar.style.borderColor = props.borderColor || '#0bf';
                    $bar.style.borderBottomColor = '#eeeeee';

                    $bar.style.transform = 'rotate(' + -(45 + (100 - props.value) * 1.8) + 'deg)';
                } else {
                    $bar.style.borderColor = '#eeeeee';
                    $bar.style.borderBottomColor = props.borderColor || '#0bf';

                    $bar.style.transform = 'rotate(' + (45 + props.value * 1.8) + 'deg)';
                }
            }

            //$bar.style.transform = 'rotate(-135deg)';
        }, 500);
        const classes = useStyles();
        return (
            <div className={classes.progress}>
                <div className={classes.barOverflow}>
                    <div id={componentId + '_semiCircleBar'} className={classes.bar}></div>
                </div>
                <span id={componentId + '_semiCircleBarLabel'} style={{ color: props.borderColor }}>
                    {props.value}%
                </span>
            </div>
        );
    };
});

const semiMatrix = {
    0: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    1: 'matrix(0.661312, 0.750111, -0.750111, 0.661312, 0, 0)',
    2: 'matrix(0.661312, 0.750111, -0.750111, 0.661312, 0, 0)',
    3: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    4: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    5: 'matrix(0.535827, 0.844328, -0.844328, 0.535827, 0, 0)',
    6: 'matrix(0.535827, 0.844328, -0.844328, 0.535827, 0, 0)',
    7: 'matrix(0.535827, 0.844328, -0.844328, 0.535827, 0, 0)',
    8: 'matrix(0.706498, 0.707715, -0.707715, 0.706498, 0, 0)',
    9: 'matrix(0.706498, 0.707715, -0.707715, 0.706498, 0, 0)',
    10: 'matrix(0.397148, 0.917755, -0.917755, 0.397148, 0, 0)',
    11: 'matrix(0.397148, 0.917755, -0.917755, 0.397148, 0, 0)',
    12: 'matrix(0.397148, 0.917755, -0.917755, 0.397148, 0, 0)',
    13: 'matrix(0.706193, 0.708019, -0.708019, 0.706193, 0, 0)',
    14: 'matrix(0.706193, 0.708019, -0.708019, 0.706193, 0, 0)',
    15: 'matrix(0.24869, 0.968583, -0.968583, 0.24869, 0, 0)',
    16: 'matrix(0.24869, 0.968583, -0.968583, 0.24869, 0, 0)',
    17: 'matrix(0.24869, 0.968583, -0.968583, 0.24869, 0, 0)',
    18: 'matrix(0.705889, 0.708323, -0.708323, 0.705889, 0, 0)',
    19: 'matrix(0.705889, 0.708323, -0.708323, 0.705889, 0, 0)',
    20: 'matrix(0.0941083, 0.995562, -0.995562, 0.0941083, 0, 0)',
    21: 'matrix(0.0941083, 0.995562, -0.995562, 0.0941083, 0, 0)',
    22: 'matrix(0.0941083, 0.995562, -0.995562, 0.0941083, 0, 0)',
    23: 'matrix(0.705584, 0.708626, -0.708626, 0.705584, 0, 0)',
    24: 'matrix(0.705584, 0.708626, -0.708626, 0.705584, 0, 0)',
    25: 'matrix(-0.0627905, 0.998027, -0.998027, -0.0627905, 0, 0)',
    26: 'matrix(-0.0627905, 0.998027, -0.998027, -0.0627905, 0, 0)',
    27: 'matrix(-0.0627905, 0.998027, -0.998027, -0.0627905, 0, 0)',
    28: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    29: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    30: 'matrix(-0.218143, 0.975917, -0.975917, -0.218143, 0, 0)',
    31: 'matrix(-0.218143, 0.975917, -0.975917, -0.218143, 0, 0)',
    32: 'matrix(-0.218143, 0.975917, -0.975917, -0.218143, 0, 0)',
    33: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    34: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    35: 'matrix(-0.368125, 0.929776, -0.929776, -0.368125, 0, 0)',
    36: 'matrix(-0.368125, 0.929776, -0.929776, -0.368125, 0, 0)',
    37: 'matrix(-0.368125, 0.929776, -0.929776, -0.368125, 0, 0)',
    38: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    39: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    40: 'matrix(-0.509041, 0.860742, -0.860742, -0.509041, 0, 0)',
    41: 'matrix(-0.509041, 0.860742, -0.860742, -0.509041, 0, 0)',
    42: 'matrix(-0.509041, 0.860742, -0.860742, -0.509041, 0, 0)',
    43: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    44: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    45: 'matrix(-0.637424, 0.770513, -0.770513, -0.637424, 0, 0)',
    46: 'matrix(-0.637424, 0.770513, -0.770513, -0.637424, 0, 0)',
    47: 'matrix(-0.637424, 0.770513, -0.770513, -0.637424, 0, 0)',
    48: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    49: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    50: 'matrix(-0.750111, 0.661312, -0.661312, -0.750111, 0, 0)',
    51: 'matrix(-0.750111, 0.661312, -0.661312, -0.750111, 0, 0)',
    52: 'matrix(-0.750111, 0.661312, -0.661312, -0.750111, 0, 0)',
    53: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    54: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    55: 'matrix(-0.844328, 0.535827, -0.535827, -0.844328, 0, 0)',
    56: 'matrix(-0.844328, 0.535827, -0.535827, -0.844328, 0, 0)',
    57: 'matrix(-0.844328, 0.535827, -0.535827, -0.844328, 0, 0)',
    58: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    59: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    60: 'matrix(-0.917755, 0.397148, -0.397148, -0.917755, 0, 0)',
    61: 'matrix(-0.917755, 0.397148, -0.397148, -0.917755, 0, 0)',
    62: 'matrix(-0.917755, 0.397148, -0.397148, -0.917755, 0, 0)',
    63: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    64: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    65: 'matrix(-0.968583, 0.24869, -0.24869, -0.968583, 0, 0)',
    66: 'matrix(-0.968583, 0.24869, -0.24869, -0.968583, 0, 0)',
    67: 'matrix(-0.968583, 0.24869, -0.24869, -0.968583, 0, 0)',
    68: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    69: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    70: 'matrix(-0.995562, 0.0941083, -0.0941083, -0.995562, 0, 0)',
    71: 'matrix(-0.995562, 0.0941083, -0.0941083, -0.995562, 0, 0)',
    72: 'matrix(-0.995562, 0.0941083, -0.0941083, -0.995562, 0, 0)',
    73: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    74: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    75: 'matrix(-0.998027, -0.0627905, 0.0627905, -0.998027, 0, 0)',
    76: 'matrix(-0.998027, -0.0627905, 0.0627905, -0.998027, 0, 0)',
    77: 'matrix(-0.998027, -0.0627905, 0.0627905, -0.998027, 0, 0)',
    78: 'matrix(0.702222, 0.711958, -0.711958, 0.702222, 0, 0)',
    79: 'matrix(0.702222, 0.711958, -0.711958, 0.702222, 0, 0)',
    80: 'matrix(-0.975917, -0.218143, 0.218143, -0.975917, 0, 0)',
    81: 'matrix(-0.975917, -0.218143, 0.218143, -0.975917, 0, 0)',
    82: 'matrix(-0.975917, -0.218143, 0.218143, -0.975917, 0, 0)',
    83: 'matrix(0.701916, 0.71226, -0.71226, 0.701916, 0, 0)',
    84: 'matrix(0.701916, 0.71226, -0.71226, 0.701916, 0, 0)',
    85: 'matrix(-0.929776, -0.368125, 0.368125, -0.929776, 0, 0)',
    86: 'matrix(-0.929776, -0.368125, 0.368125, -0.929776, 0, 0)',
    87: 'matrix(-0.929776, -0.368125, 0.368125, -0.929776, 0, 0)',
    88: 'matrix(0.701609, 0.712562, -0.712562, 0.701609, 0, 0)',
    89: 'matrix(0.701609, 0.712562, -0.712562, 0.701609, 0, 0)',
    90: 'matrix(-0.860742, -0.509041, 0.509041, -0.860742, 0, 0)',
    91: 'matrix(-0.860742, -0.509041, 0.509041, -0.860742, 0, 0)',
    92: 'matrix(-0.860742, -0.509041, 0.509041, -0.860742, 0, 0)',
    93: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    94: 'matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)',
    95: 'matrix(-0.770513, -0.637424, 0.637424, -0.770513, 0, 0)',
    96: 'matrix(-0.770513, -0.637424, 0.637424, -0.770513, 0, 0)',
    97: 'matrix(-0.770513, -0.637424, 0.637424, -0.770513, 0, 0)',
    98: 'matrix(-0.728969, -0.684547, 0.684547, -0.728969, 0, 0)',
    99: 'matrix(-0.728969, -0.684547, 0.684547, -0.728969, 0, 0)',
};

import { API } from '../commons';
import { AllStaticVarsFromBack } from '../pages/PriceMap/support/PriceMapConsts';
import { MAP_APIS_ENDPOINTS, setEndpointParams } from './EndPoints';
export async function getAllStaticData() {
    const listAPI = setEndpointParams(MAP_APIS_ENDPOINTS.GET_SELECTION_LISTS, { list_code: 'activityTypes' });

    const userActivityTypes = await API.get(null, listAPI);
    AllStaticVarsFromBack['activityTypes'] = userActivityTypes;
}
export async function getUserTypeList() {
    const listAPI = setEndpointParams(MAP_APIS_ENDPOINTS.GET_SELECTION_LISTS, { list_code: 'userTypeOptions' });

    const userTypes = await API.get(null, listAPI);
    return userTypes;
}

export async function getBuyPurpose() {
    const listAPI = setEndpointParams(MAP_APIS_ENDPOINTS.GET_SELECTION_LISTS, { list_code: 'buyPurpose' });

    const userTypes = await API.get(null, listAPI);
    return userTypes;
}

export async function getSelectionList(selectionCode) {
    const listAPI = setEndpointParams(MAP_APIS_ENDPOINTS.GET_SELECTION_LISTS, { list_code: selectionCode });

    const resultList = await API.get(null, listAPI);
    return resultList;
}

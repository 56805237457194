import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SecurityProvider, TR } from '../../../../commons';
import { AppProvider } from '../../../../commons/appProvider';
import {
    api_searchGoogleResult,
    queryLocalityHistoryMedian,
    queryPropEnhance_byGraphQL,
    queryProp_byGraphQL,
    query_byGraphQL,
    streetQuery,
} from '../../../../commons/graphqlClient/graphAPI';
import { Permission } from '../../../../commons/security/authz';
import { mobileNavButton } from '../../consts/styleConsts';
import { AroundPriceMap } from '../aroundPriceMap';
import { MobileLocalityPriceTrend } from '../chart/mobileLocalityPriceTrend';
import { CombinedAddressSearchBar } from '../combinedAddressSearchBar';
import { CardLine } from '../cardSeperate';
import { SalePriceHistory } from '../salePriceHistory';
import { HouseLabel } from '../houseLabel';
import { Margin } from '../../../../components/margin';
import { ComparableHouse } from '../comparableHouse';
import { MedianPrice } from './../../../Home/MedianPrice';
import { ComparableHouseLabel } from '../comparableHouse/comparabelHouseLabel';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { PrimarySchoolInfo } from '../../../PriceMap/components/popupComponentForLabel';
import { makeStyles } from '@material-ui/styles';
import { GoogleSearchDialog } from '../../component/googleSearchDialog';
import { changeToFirstCaptial, convertToM } from '../../../../commons/utils';
import { NoVerifyEmailReceived } from '../../member/verifyEmail/NoVerifyEmailReceived';
import { exceedDailyLimit, saveSearchHistory, saveUserAccessLog } from '../../../../services/userCenter';
import { UserCenterEntityType } from '../../../../models/backend/userCenter';
import { MemberInfo } from '../../member/memberInfo';
import { VariousPopup } from '../../member/signUp/TipAfterSignup';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

declare var window: any;
const useStyles = makeStyles({});

export const Level1PropertyCard = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [showVerifyEmail, setShowVerifyEmail] = useState(false);
    const [showMember, setShowMember] = useState(false);
    const securityProvider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const { propId, choosedItem, itemChoosedEvt, backEvt, inDetails, embedded } = props;
    const [property, setProperty] = useState(null);
    const [suburbHistoryPrices, setSuburbHistoryPrices] = useState(null);
    const [schoolCatchments, setSchoolCatchments] = useState(null);
    const [street, setStreet] = useState(null);

    const [showGoogleSearch, setShowGoogleSearch] = useState(false);
    const [googleSearchResult, setGooglSearchResult] = useState(null);
    const [showWelcomeAreaMember, setShowWelcomeAreaMember] = useState(false);
    const [popType, setPopType] = useState(-1);
    const history = useHistory();
    const [updatedPropId, setUpdatedPropId] = useState('');
    const [updatedAddress, setUpdatedAddress] = useState('');

    window.gtag('config', 'UA-148014445-2', {
        page_title: 'First Level Page',
        page_path: '/FirstLevelInfo',
    });
    // const [showingAllComparable, setShowingAllComparable] = useState(Boolean);

    useEffect(() => {
        EventBus.on(EVENT_NAMES.EVENT_TRANSFER_SHOW_ALL_COMPARABLE, async (event) => {
            console.log(event);
            const doNext = await canDoNext();
            if (doNext) {
                saveUserAccessLog('QUERY_PROP_AROUND', securityProvider.getUserProfile()?.id, {
                    propId: propId,
                });

                const showingAllComparable = event.detail;
                const property = event.property;

                EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_SHOW_ALL_COMPARABLE, { detail: showingAllComparable });
                EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_ORIGINAL_PROPERTY_FOR_ALL_COMPARABLE, { property });
            }
        });
        // if (
        //     !securityProvider.getUserProfile().userStatus &&
        //     appProvider.state.authDialog !== 'login' &&
        //     appProvider.state.authDialog !== 'signup'
        // ) {
        //     setTimeout(() => {
        //         setInterval(() => {
        //             appProvider.state.authDialog = 'login';
        //         }, 1000);
        //     }, 30 * 1000);
        // }
    }, []);

    // console.log(showingAllComparable);

    useEffect(() => {
        getProperty(propId);
    }, [propId]);

    const getProperty = async (id) => {
        setLoading(true);
        try {
            const propertyResult: any = await queryPropEnhance_byGraphQL(id);

            if (!propertyResult.errors) {
                if (propertyResult.data.property.__typename != 'House') {
                    EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_TO_APARTMENT, {
                        propId: propertyResult.data.property.propId,

                        address: propertyResult.data.property.stdAddress,
                    });
                } else {
                    console.log(propertyResult);
                    const currentYear = moment().year();
                    const historyPricesResult: any = await queryLocalityHistoryMedian(
                        propertyResult.data.property.locality.localityId,
                        currentYear - 30,
                        currentYear,
                    );
                    const streetResult: any = await query_byGraphQL(
                        streetQuery,
                        propertyResult.data.property.street.streetId,
                    );

                    setProperty(propertyResult.data.property);

                    setSuburbHistoryPrices(historyPricesResult.data.locality.historicalTransactions);
                    setSchoolCatchments(historyPricesResult.data.locality.intersectedPrimary);
                    setStreet(streetResult.data.street);
                }

                history.push(
                    `mobileHome?queryType=1&queryId=${propertyResult.data.property.propId}&queryAddress=${propertyResult.data.property.stdAddress}&showingDetails=false&propertyType=House`,
                );
                setUpdatedPropId(propertyResult.data.property.propId);
                setUpdatedAddress(propertyResult.data.property.stdAddress);
            } else {
                EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
                    open: true,
                    title: 'QUERY_FAILED',

                    cancelButtonText: 'GOT_IT',

                    content: propertyResult.errors[0]?.message,
                });
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);

            console.log('has error ===>', e);
        }
    };

    const isMember = () => {
        const result = securityProvider.hasPermission(Permission.PROPERTY_BUYER);
        console.log('check member result is ' + result);
        return result;
    };

    const getUserStatus = () => {
        return securityProvider.getUserProfile().userStatus;
    };
    const isTrial = () => {
        const result = securityProvider.hasPermission(Permission.TRIAL);
        console.log('checking result is ' + result);
        return result;
    };
    const userExpired = () => {
        return securityProvider.isExpired(
            securityProvider.hasPermission(Permission.PROPERTY_BUYER) ? Permission.PROPERTY_BUYER : Permission.TRIAL,
        );
    };

    const logged = () => {
        return securityProvider.hasLogin();
    };

    const showSignup = () => {
        return appProvider.openLoginDialog(null);
    };
    const toPayment = () => {
        window.open('/joinMember', '_self');
    };
    const changeProperty = async (property) => {
        getProperty(property.value);
    };

    // console.log('miaomiaomiao', property);

    // const showMoreComparableProperty = () => {
    //     const streetData = property.street;
    //     const streetAddress = `${streetData.streetName},${streetData.localityName} ${streetData.state} ${streetData.post}`;
    //     window.open(`/mobileHome?queryType=2&queryId=${streetData.streetId}&queryAddress=${streetAddress}`, '_self');
    // };
    const showStreetDetail = () => {
        const streetData = property.street;
        saveUserAccessLog('QUERY_STREET_FIRST_LEVEL', securityProvider.getUserProfile()?.id, {
            streetId: streetData?.streetId,
        });

        const streetAddress = `${streetData.streetName},${streetData.localityName} ${streetData.state} ${streetData.post}`;
        window.open(
            `/mobileHome?queryType=2&queryId=${streetData.streetId}&queryAddress=${streetAddress}&showingDetails=true`,
            '_self',
        );
    };

    const showSuburbDetails = () => {
        saveUserAccessLog('QUERY_SUBURB_FIRST_LEVEL', securityProvider.getUserProfile()?.id, {
            suburbId: property.locality.localityId,
        });
        window.open(
            `/mobileHome?queryType=3&queryId=${property.locality.localityId}&queryAddress=${property.locality.localityName}&showingDetails=true`,
            '_self',
        );
    };

    const showDetails = async () => {
        const doNext = await canDoNext();
        if (doNext) {
            saveUserAccessLog('QUERY_PROP_DETAILS', securityProvider.getUserProfile()?.id, {
                propId: propId,
            });
            saveSearchHistory({
                entityId: propId + '',
                entityType: UserCenterEntityType.Property,
            });

            window.open(
                `/mobileHome?queryType=1&queryId=${updatedPropId || propId}&queryAddress=${
                    updatedAddress || property.stdAddress
                }&showingDetails=true`,
                '_self',
            );
        }
    };

    const canDoNext = async () => {
        try {
            await securityProvider.refreshProfile();
        } catch (e) {}

        if (!logged()) {
            showSignup();
            return false;
        } else if (getUserStatus() == '1') {
            setShowVerifyEmail(true);
            return false;
        } else {
            const userUsage: any = await exceedDailyLimit(updatedPropId || propId);

            // if execeed the user usage level , should pop up window
            if (userUsage.popType != -1) {
                setShowMember(true);
                setPopType(userUsage.popType);
                return false;
            } else {
            }
        }
        return true;
    };

    // EventBus.on(EVENT_NAMES.EVENT_MOBILE_SHOW_LESS_COMPARABLE, () => {
    //     setShowingAllComparable(true);
    // });
    // console.log(showingAllComparable);

    var inRequest = false;
    const searchGoogle = async (propId) => {
        if (!inRequest) {
            inRequest = true;
            const searchResult: any = await api_searchGoogleResult(propId);
            console.log(searchResult);
            inRequest = false;
            setGooglSearchResult(searchResult.data.property);
            setShowGoogleSearch(true);
        }
    };
    return (
        <>
            {loading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100vh',
                        alignItems: 'center',
                    }}>
                    <CircularProgress size={128}></CircularProgress>
                </div>
            )}
            {!loading && property && (
                <>
                    <div>
                        <CombinedAddressSearchBar
                            currentAddress={property.stdAddress}
                            changePropertyEvt={changeProperty}></CombinedAddressSearchBar>

                        <div>
                            <HouseLabel
                                googleSearchEvt={searchGoogle}
                                primary={property.primarySchools[0]}
                                property={property}
                                street={street}
                                // localData={suburbHistoryPrices}
                            />
                        </div>

                        <CardLine></CardLine>

                        <AroundPriceMap
                            propId={property.propId}
                            locality={property.locality?.localityName}
                            schools={{
                                primary: property.primarySchools,
                                secondary: property.secondarySchools,
                                selective: property.selectiveSchools,
                                private: property.privateSchools,
                                schoolCatchments: schoolCatchments,
                            }}
                            propCenter={{
                                lat: property.propY,
                                lon: property.propX,
                            }}></AroundPriceMap>
                    </div>

                    <CardLine></CardLine>
                    <div>
                        <ComparableHouse
                            googleSearchEvt={searchGoogle}
                            property={property}
                            showingAllComparable={false}
                            apt={false}
                        />
                    </div>

                    <CardLine></CardLine>

                    <div>
                        <SalePriceHistory
                            soldRecords={property.soldRecords}
                            landSize={property.landSize}
                            localData={suburbHistoryPrices}
                        />
                    </div>

                    <div>
                        <MobileLocalityPriceTrend
                            locality={property.locality?.localityName}
                            soldRecords={property.soldRecords}
                            localData={suburbHistoryPrices}></MobileLocalityPriceTrend>
                    </div>

                    <div></div>

                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            margin: '10px 0px',
                            marginTop: '31px',
                        }}>
                        {!embedded ? (
                            <Button
                                style={{
                                    ...mobileNavButton,
                                    background: '#009e90',
                                    height: '42px',
                                    color: 'white',
                                    width: '80%',
                                }}
                                onClick={showDetails}
                                variant="outlined">
                                {loading ? <CircularProgress /> : null}

                                <TR name="getPriceAndMoreData"></TR>
                            </Button>
                        ) : null}
                    </div>

                    <CardLine></CardLine>

                    <div
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'center',
                            paddingBottom: 20,
                        }}>
                        <Button
                            style={{
                                ...mobileNavButton,
                                background: 'rgba(0,158,144,0.15)',
                                borderRadius: '4px',
                                width: '80%',
                                height: 40,
                            }}
                            onClick={showStreetDetail}>
                            <TR name="getStreetProfile"></TR>
                        </Button>
                        <Margin tm={20}></Margin>
                        <Button
                            style={{
                                ...mobileNavButton,
                                background: 'rgba(0,158,144,0.15)',
                                borderRadius: '4px',
                                width: '80%',
                                height: 40,
                            }}
                            onClick={showSuburbDetails}>
                            <TR name="getSuburbProfile"></TR>
                        </Button>
                    </div>
                </>
            )}

            {googleSearchResult && showGoogleSearch && (
                <GoogleSearchDialog
                    propCenter={{
                        lat: googleSearchResult.propY,
                        lon: googleSearchResult.propX,
                    }}
                    closeDialogEvt={() => {
                        setShowGoogleSearch(false);
                    }}
                    address={googleSearchResult.stdAddress}
                    searchResult={googleSearchResult.moreInfoGoogle}
                    open={showGoogleSearch}></GoogleSearchDialog>
            )}
            <NoVerifyEmailReceived
                closeMessage={() => {
                    setShowVerifyEmail(false);
                }}
                showVerifyEmail={showVerifyEmail}></NoVerifyEmailReceived>

            <MemberInfo
                show={showMember}
                popType={popType}
                onSuccessAreaMember={() => {
                    setShowMember(false);
                    setShowWelcomeAreaMember(true);
                }}
                onClose={() => {
                    setShowMember(false);
                }}></MemberInfo>

            <VariousPopup
                open={showWelcomeAreaMember}
                messageTitleKey={'welcomeAsAreaMember'}
                messageKey={'areaMemberBenefit'}
                onClose={() => setShowWelcomeAreaMember(false)}></VariousPopup>
        </>
    );
};

import { rst } from 'rt-state';
import IconButton from '@material-ui/core/IconButton';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import React from 'react';

function handleFullscreenToggle() {
    const element = document.querySelector('#root') as any;
    const doc = document as any;
    const isFullscreen = doc.webkitIsFullScreen || doc.mozFullScreen || false;

    element.requestFullScreen =
        element.requestFullScreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        function () {
            return false;
        };
    doc.cancelFullScreen =
        doc.cancelFullScreen ||
        doc.webkitCancelFullScreen ||
        doc.mozCancelFullScreen ||
        function () {
            return false;
        };
    isFullscreen ? doc.cancelFullScreen() : element.requestFullScreen();
}

export const FullScreenButton = rst.createS<{}>((props) => {
    return (
        <IconButton color="inherit" onClick={handleFullscreenToggle}>
            <FullscreenIcon />
        </IconButton>
    );
});

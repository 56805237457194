import classes from '*.module.css';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { isMobileDevice } from '../../../commons/utils';

import { MapControlButtonBase } from '../components/MapControlButtonBase';
import { PropertyIcon } from '../MapContainer/PropertyIcon';
import { getPriceMapLevelByZoom } from '../support/common';
import {
    MapGlobalVars,
    PRICE_MAP_STATUS,
    PropertyPriceLegendList,
    SuburbPriceColors,
    SuburbPriceColorsLabel,
} from '../support/PriceMapConsts';

const useStyles = makeStyles((theme) => {
    return {
        root: {},
        suburbLegendPanel: {
            display: 'flex',
            height: isMobileDevice() ? 35 : '100%',
            width: isMobileDevice() ? '' : 667,
            alignItems: 'center',
            justifyContent: 'flex-start',

            paddingRight: isMobileDevice() ? 10 : 19,
            paddingBottom: 10,
        },
        englishSuburbLegendPanel: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            paddingRight: '10px',
            paddingBottom: '10px',
            paddingLeft: '5px',
            width: '300px',
        },
        suburbLegendItem: {
            position: 'relative',
        },
        suburbLegendBox: {
            width: isMobileDevice() ? 39 : 77,
            height: isMobileDevice() ? 4 : 12,
        },
        suburbLegendLabel: {
            position: 'absolute',
            top: isMobileDevice() ? 5 : 10,
            right: isMobileDevice() ? -25 : -5,
            fontSize: isMobileDevice() ? 10 : 12,
            [theme.breakpoints.down('sm')]: {
                width: 30,
            },
        },
        suburbLegendLabelCenter: {
            position: 'absolute',
            top: isMobileDevice() ? 5 : 10,
            right: isMobileDevice() ? 0 : 27,
            fontSize: isMobileDevice() ? 10 : 12,
            [theme.breakpoints.down('sm')]: {
                width: 30,
            },
        },
        suburbLegendTitle: {
            marginLeft: 11,
            fontSize: isMobileDevice() ? 12 : 14,
        },
        priceLegendList: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'space-around',
        },
        priceLegendItem: {
            display: 'flex',
            fontSize: isMobileDevice() ? 12 : 14,
            alignItems: 'center',
            marginLeft: isMobileDevice() ? 5 : 0,
        },
        legendDiv: {
            marginLeft: isMobileDevice() ? 9 : 18,
            display: 'flex',
            flexDirection: isMobileDevice() ? 'column' : 'row',
            background: 'white',
            borderRadius: 8,
        },
        mapIconLegendDiv: {
            display: 'flex',
            fontSize: isMobileDevice() ? 12 : 14,
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 5,
        },
    };
});

const MapIconLegendList = [
    {
        type: 'house',
        label: 'HOUSE',
        icon: 'home_recentSold.svg',
    },
    {
        type: 'nonRecentSold',
        label: 'NON_RECENT_SOLD',
        icon: 'homeNotRecent.svg',
    },
    {
        type: 'apartment',
        label: 'APT',
        icon: 'apartment-ico@1x.svg',
    },
];

export const PriceMapLegend = rst.create<{ mapZoom; propertyType; legendFoldEvent?; showingLegendPanel? }>((ctx) => {
    const open = rst.stateS(true);
    const state = rst.state({
        mapStaus: PRICE_MAP_STATUS.SUBURBLEVEL,
    });
    EventBus.on(EVENT_NAMES.EVENT_MAP_ZOOMED, (zoomLevel) => {
        state.mapStaus = getPriceMapLevelByZoom(zoomLevel);
    });

    const initialPriceLegendList = [
        {
            max: 1.2,
            color: '#91d02a',
            first: true,
        },

        {
            min: 1.2,
            max: 1.8,
            color: '#f4e800',
        },

        {
            min: 1.8,
            max: 2.4,
            color: '#62DE70',
        },
        {
            min: 2.4,
            max: 3,
            color: '#B676E8',
        },
        {
            min: 3,
            color: '#F3A42E',
            last: true,
        },
    ];
    const priceLegendList = rst.stateS(initialPriceLegendList);
    function foldLegend() {
        open.value = !open.value;
        open.forceUpdate();

        if (ctx.props.legendFoldEvent) {
            ctx.props.legendFoldEvent();
        }
    }
    EventBus.on(EVENT_NAMES.EVENT_UPDATE_PRICE_LEGEND, () => {
        const newPriceList = [];
        PropertyPriceLegendList.forEach((loopItem) => {
            newPriceList.push(loopItem);
        });
        priceLegendList.value = newPriceList;

        priceLegendList.forceUpdate();
    });
    return (props) => {
        const classes = useStyles();
        console.log(props);
        const getFoldIcon = () => {
            if (!isMobileDevice()) {
                if (open.value) {
                    return (
                        <MapControlButtonBase
                            icon={'list'}
                            arrowPosition={'LEFT'}
                            clickEvent={foldLegend}></MapControlButtonBase>
                    );
                } else {
                    return <MapControlButtonBase icon={'list'} clickEvent={foldLegend}></MapControlButtonBase>;
                }
            } else {
                return null;
            }
        };
        return (
            <>
                {MapGlobalVars.showAroundTransCircle ? null : (
                    <div style={{ display: 'flex', flexDirection: isMobileDevice() ? 'column' : 'row-reverse' }}>
                        {getFoldIcon()}
                        {open.value ? (
                            <div className={classes.legendDiv}>
                                {isMobileDevice() ? (
                                    <div
                                        onClick={props.legendFoldEvent}
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'center',
                                            paddingTop: 6,
                                        }}>
                                        <img src="assets/icons/downArrow.svg"></img>
                                    </div>
                                ) : null}
                                {getPriceMapLevelByZoom(props.mapZoom) == PRICE_MAP_STATUS.ALLPROPERTYLEVEL ? (
                                    <AllPropertyLegend></AllPropertyLegend>
                                ) : null}
                                {getPriceMapLevelByZoom(props.mapZoom) == PRICE_MAP_STATUS.PROPERTYLEVEL ? (
                                    <PropertyPriceLegend legendItems={priceLegendList.value}></PropertyPriceLegend>
                                ) : null}

                                {getPriceMapLevelByZoom(props.mapZoom) == PRICE_MAP_STATUS.SUBURBLEVEL ? (
                                    <SuburbLegend
                                        legendItems={SuburbPriceColors[props.propertyType]}
                                        legendItemsLabel={
                                            SuburbPriceColorsLabel[props.propertyType].label
                                        }></SuburbLegend>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                )}
            </>
        );
    };
});

const SuburbLegend = rst.create<{ legendItems; legendItemsLabel }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return MapGlobalVars.currentUserLan == 'zh' || !isMobileDevice() ? (
            <div className={classes.suburbLegendPanel}>
                <div className={classes.suburbLegendTitle}>
                    <TR name={props.legendItemsLabel}></TR>
                </div>
                <div style={{ marginLeft: isMobileDevice() ? 9 : 18 }}></div>
                {props.legendItems.map((legendItem) => {
                    return <SuburbLegendItem legendItem={legendItem}></SuburbLegendItem>;
                })}
            </div>
        ) : (
            <div className={classes.englishSuburbLegendPanel} style={{ paddingBottom: 20 }}>
                <div className={classes.suburbLegendTitle}>
                    <TR name={props.legendItemsLabel}></TR>
                </div>
                <div style={{ marginTop: 2 }}></div>
                <div style={{ display: 'flex', marginLeft: 20 }}>
                    {props.legendItems.map((legendItem) => {
                        return <SuburbLegendItem legendItem={legendItem}></SuburbLegendItem>;
                    })}
                </div>
            </div>
        );
    };
});
const SuburbLegendItem = rst.create<{ legendItem }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.suburbLegendItem}>
                <div className={classes.suburbLegendBox} style={{ background: props.legendItem.color }}></div>
                <div className={props.legendItem.center ? classes.suburbLegendLabelCenter : classes.suburbLegendLabel}>
                    <TR name={props.legendItem.max} />
                </div>
            </div>
        );
    };
});

const AllPropertyLegend = rst.create((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.mapIconLegendDiv}>
                {MapIconLegendList.map((loopMapIcon, idx) => {
                    return (
                        <>
                            {idx > 0 ? <div style={{ marginLeft: 10 }}></div> : null}
                            <div style={{ display: 'flex' }}>
                                <img
                                    src={'assets/icons/' + loopMapIcon.icon}
                                    width={isMobileDevice() ? 18 : 30}
                                    height={isMobileDevice() ? 18 : 30}></img>

                                <div style={{ marginLeft: 5 }}></div>

                                <TR name={loopMapIcon.label}></TR>
                            </div>
                        </>
                    );
                })}
            </div>
        );
    };
});

const PropertyPriceLegend = rst.create<{ legendItems }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return MapGlobalVars.currentUserLan == 'zh' || !isMobileDevice() ? (
            <div className={classes.suburbLegendPanel}>
                <div className={classes.suburbLegendTitle}>
                    <TR name={'PRICE_LEGEND_TITLE'}></TR>
                </div>
                <div style={{ marginLeft: isMobileDevice() ? 5 : 0 }}></div>
                <div className={classes.priceLegendList}>
                    {props.legendItems.map((loopPriceLegend) => {
                        return (
                            <div className={classes.priceLegendItem}>
                                <PropertyIcon propId="" color={loopPriceLegend.color} showLabel={false}></PropertyIcon>
                                <div style={{ marginLeft: isMobileDevice() ? 5 : 0 }}></div>
                                {!loopPriceLegend.min && loopPriceLegend.max ? '<' + loopPriceLegend.max : ''}
                                {loopPriceLegend.min && loopPriceLegend.max
                                    ? loopPriceLegend.min + '-' + loopPriceLegend.max
                                    : ''}
                                {loopPriceLegend.min && !loopPriceLegend.max ? '>' + loopPriceLegend.min : ''}
                            </div>
                        );
                    })}
                </div>
            </div>
        ) : (
            <div className={classes.englishSuburbLegendPanel}>
                <div className={classes.suburbLegendTitle}>
                    <TR name={'PRICE_LEGEND_TITLE'}></TR>
                </div>
                <div style={{ marginTop: 3 }}></div>
                <div className={classes.priceLegendList}>
                    {props.legendItems.map((loopPriceLegend) => {
                        return (
                            <div className={classes.priceLegendItem}>
                                <PropertyIcon propId="" color={loopPriceLegend.color} showLabel={false}></PropertyIcon>
                                <div style={{ marginLeft: 7 }}></div>
                                {!loopPriceLegend.min && loopPriceLegend.max ? '<' + loopPriceLegend.max : ''}
                                {loopPriceLegend.min && loopPriceLegend.max
                                    ? loopPriceLegend.min + '-' + loopPriceLegend.max
                                    : ''}
                                {loopPriceLegend.min && !loopPriceLegend.max ? '>' + loopPriceLegend.min : ''}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };
});

import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { compareStringsIgnoreCase } from '../../../commons/utils';
import { getPriceMapLevelByZoom } from '../support/common';
import { PRICE_MAP_STATUS, suburbNamesForMapLevels } from '../support/PriceMapConsts';
import { suburbDataList } from './SuburbPolygonController';

const win: any = window;
const L = win.L;

export class SuburbNameController {
    suburbNameLabels = [];
    map: any;
    constructor(map: any) {
        this.map = map;

        EventBus.on(EVENT_NAMES.EVENT_MAP_ZOOMED, (zoom) => {
            if (getPriceMapLevelByZoom(zoom) != PRICE_MAP_STATUS.SUBURBLEVEL) {
                this.clearAllCurrentSuburbName();
            }
        });
    }

    drawSuburbNames() {
        this.clearAllCurrentSuburbName();
        const drawingSuburbNames = suburbNamesForMapLevels[this.map.getZoom()];
        if (getPriceMapLevelByZoom(this.map.getZoom()) != PRICE_MAP_STATUS.SUBURBLEVEL) {
            return;
        }
        if (drawingSuburbNames) {
            drawingSuburbNames.forEach((loopSuburbName) => {
                if (suburbDataList) {
                    for (let i = 0; i < suburbDataList.length; i++) {
                        const loopSuburbData = suburbDataList[i];
                        if (compareStringsIgnoreCase(loopSuburbData.locality_name, loopSuburbName)) {
                            const suburbNameDivIcon = new L.DivIcon({
                                className: '',
                                html: loopSuburbName,
                                iconAnchor: [20, 0],
                            });

                            const suburbNameMarker = L.marker([loopSuburbData.lat, loopSuburbData.lon], {
                                icon: suburbNameDivIcon,
                            }).addTo(this.map);
                            suburbNameMarker.on('click', () => {
                                EventBus.dispatch(EVENT_NAMES.EVENT_SUBURB_NAME_CLICK, { suburbId: loopSuburbData.id });
                            });
                            this.suburbNameLabels.push(suburbNameMarker);
                        }
                    }
                }
            });
        }
    }
    clearAllCurrentSuburbName() {
        if (this.suburbNameLabels) {
            this.suburbNameLabels.forEach((loopItem) => {
                this.map.removeLayer(loopItem);
            });
        }
    }
}

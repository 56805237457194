import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';

const mockAdapter = new MockAdapter(axios, { delayResponse: 150 });

// mockAdapter.onGet('/user/api/login').reply((config) => {
//     const token = 'this is a token';
//     return [200, token];
// });

// mockAdapter.onGet('/user/api/profile').reply((config) => {
//     const profile = {
//         username: 'xvv',
//         email: 'xvv@gmail.com',
//         age: 18,
//     };
//     return [200, profile];
// });
//////////////////////////////////
mockAdapter.onAny().passThrough();

import { ENV } from './common';

export const prodEnv: ENV = {
    apiHost: 'https://ab.api.aihouse.io',
    USER_API_HOST: 'https://ab.api.aihouse.io',
    ql_token:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxNDA0NjkzMjgwMDc5MjE2NjUiLCJyb2xlcyI6eyIyIjoxNjMwMTU5MjAwMDAwfSwiaWF0IjoxNjQ1MTAzMjgwLCJleHAiOjE2NTAyODcyODB9.mHRYVVa1mpMkFnWZoToqBCWAWKCg5bhWJ4HCqpF9JwQ',
    stripeKey:
        'pk_live_51HVTK6AD12lyhQjm2dyFYqkGyMXRhzShr7QXCdICrEs6t2p4EhdPsZbbQWlCDk6tOEGyM26Lkl6yBmFsZdP6MMKW00iOSVANUd',
    signUpExpireHours: 24,
};

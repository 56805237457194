import { XTableColumn } from '../../../../components/Table';
import { commonColumns, TitleAndColumns } from './common';

const primarySchoolScoreColumns: XTableColumn[] = [
    ...commonColumns,
    { name: 'schoolName', align: 'right', label: '小学名称' },
    {
        name: 'score',
        sort: true,
        align: 'right',
        label: '小学分数',
    },
];
const primarySchoolNonEnglishPercentageColumns: XTableColumn[] = [
    ...commonColumns,
    { name: 'schoolName', align: 'right', label: '小学名称' },
    {
        name: 'percent',
        sort: true,
        align: 'right',
        label: '非英语占比',
    },
];
const secondarySchoolScoreColumns: XTableColumn[] = [
    ...commonColumns,
    { name: 'schoolName', align: 'right', label: '中学名称' },
    {
        name: 'score',
        sort: true,
        align: 'right',
        label: '中学分数',
    },
];

export const schoolTitleAndColumns: TitleAndColumns = {
    1: { title: '小学分数排行榜', columns: primarySchoolScoreColumns },
    2: { title: '非英语占比排行榜', columns: primarySchoolNonEnglishPercentageColumns },
    3: { title: '中学分数排行榜', columns: secondarySchoolScoreColumns },
    4: null,
    5: null,
};

import { Divider, Hidden, makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { isMobileDevice } from '../../../commons/utils';
import { NewButton } from './NewButton';
import * as locales from '@material-ui/core/locale';
import { AppProvider } from '../../../commons/appProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '1200px',
        padding: '10px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '60px',
        [theme.breakpoints.down('sm')]: {
            padding: '28px 16px',
            width: '100vw',
            // justifyContent: 'space-between',
        },
        '& h2': {
            fontSize: '26px',
            margin: '45px 0px 0px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '19px',
                margin: '0px 0px 10px 0px',
            },
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        '& p': {
            fontSize: '16px',
            lineHeight: '19px',
            margin: '8px 0px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px',
                lineHeight: '18px',
                margin: '5px 0px',
            },
        },
    },
}));
export const NewHomeAbout = rst.create((ctx) => {
    const appProvider = AppProvider.use();

    return (props) => {
        const classes = useStyles();
        return (
            <div style={{ background: 'rgb(255 255 255)' }}>
                <div className={classes.root}>
                    <h2>
                        <TR name={'NewHome_About'} />
                    </h2>
                    <div className={classes.content}>
                        {appProvider.getLocale() === locales.zhCN ? null : (
                            <p>
                                <TR name="Card_AboutUsContent_P1" />
                            </p>
                        )}
                        <p>
                            <TR name="Card_AboutUsContent_P2" />
                        </p>
                        <p>
                            <TR name="Card_AboutUsContent_P3" />
                        </p>
                    </div>
                </div>
            </div>
        );
    };
});

import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';

import { rst } from 'rt-state';
import { TR } from '../../../../commons';
import { AwesomeIcon } from '../../../../components/fontAwesomeIcon';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 0px',
        width: '100%',
    },
    title: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#333333',
    },
});
export const CardTitle = (props: any) => {
    const { closeEvt, closeContent, noSwitch } = props;

    const classes = useStyles();

    const switchContent = () => {
        closeEvt(!closeContent);
    };

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <TR name={props.title}></TR>
            </div>
            {noSwitch ? null : (
                <AwesomeIcon
                    clickEvt={switchContent}
                    icon={
                        closeContent ? (
                            <img src="assets/mobileIcons/downArrow.svg"></img>
                        ) : (
                            <img src="assets/mobileIcons/upArrow.svg"></img>
                        )
                    }
                    style={{ fontSize: 20, color: '#838383', marginRight: '10px' }}></AwesomeIcon>
            )}
        </div>
    );
};

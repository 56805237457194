import ReactDOM from 'react-dom/server';
import React from 'react';
import { convertToM, getLatlngArrayFromEncodedPath } from '../../../../commons/utils';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { getPriceLabelColor, getPriceLegendIndx } from '../../../PriceMap/support/common';

const win: any = window;
const L = win.L;

export class MobileAroundpricemap {
    mapInstance = null;
    soldMarkers = [];
    suburbBorders = [];
    schoolMarkers = [];
    schoolBorders = [];
    mapId = '';

    constructor(mapInstance, mapId) {
        this.mapInstance = mapInstance;
        this.mapId = mapId;
    }

    drawCircle(radius, propCenter) {
        L.circle([propCenter.lat, propCenter.lon], { color: 'red', radius: radius, fill: false, weight: 1 }).addTo(
            this.mapInstance,
        );
    }
    getHousePopup(soldProp) {
        return `
        <div>
            <div>${soldProp.fullAddress}</div>
            <div>$${convertToM(soldProp.soldPrice)}m</div>
            <div>${soldProp.soldContractDate}</div>
        </div>
        `;
    }
    drawSuburbBorder(borderEncodes) {
        borderEncodes.forEach((borderEncode) => {
            const borderPath = getLatlngArrayFromEncodedPath(borderEncode);
            const borerPolyline = L.polyline(borderPath, {
                color: 'red',
                weight: 1,
            }).addTo(this.mapInstance);
            this.suburbBorders.push(borerPolyline);
            this.mapInstance.fitBounds(borerPolyline.getBounds());
        });
    }

    schoolCatchColors = ['#f63b3b', '#f77013', '#ff0000'];

    drawSchoolCatchments(schools, primarys) {
        console.log('primarys', primarys);
        if (schools) {
            schools.forEach((school, idx) => {
                school.polyEncoded.forEach((borderEncode) => {
                    const borderPath = getLatlngArrayFromEncodedPath(borderEncode);
                    const borerPolyline = L.polygon(borderPath, {
                        fillColor: this.schoolCatchColors[idx],

                        weight: 0,

                        fillOpacity: 0.3,
                    }).addTo(this.mapInstance);
                    borerPolyline.on('click', () => {
                        EventBus.dispatch(EVENT_NAMES.SHOW_PRIMARY_INFO_FOR_MAP + this.mapId, {
                            type: 'pri',
                            school: school.school,
                        });
                    });
                    // borerPolyline.bindPopup(school.schoolName);
                    this.schoolBorders.push(borerPolyline);
                    //this.mapInstance.fitBounds(borerPolyline.getBounds());
                });
            });
        }
    }
    drawSchools(schools, showSchool) {
        const oldBounds = this.mapInstance.getBounds();
        console.log(oldBounds);
        if (this.schoolMarkers.length > 0) {
            this.schoolMarkers.forEach((marker) => {
                this.mapInstance.removeLayer(marker);
            });
            this.schoolMarkers = [];
        }
        console.log('mapschools', schools);
        if (true) {
            console.log(schools.schoolCatchments);
            if (showSchool) {
                this.drawSchoolCatchments(schools.schoolCatchments, schools.primary);
            }

            const schoolIcon = new L.DivIcon({
                className: '',
                html: `<div style="margin-left:-10px;margin-top:-10px"><img src="/assets/mobileIcons/school.svg" /></div>`,
            });
            const schoolArray = [...schools.primary, ...schools.secondary, ...schools.selective, ...schools.private];
            if (schools.primary.length > 0) {
                schools.primary.forEach((school, idx) => {
                    this.drawSchoolMarker(school, idx, 'pri', schoolIcon);
                });
            }
            if (schools.selective.length > 0) {
                schools.selective.forEach((school, idx) => {
                    this.drawSchoolMarker(school, idx, 'sel', schoolIcon);
                });
            }
            if (schools.private.length > 0) {
                schools.private.forEach((school, idx) => {
                    this.drawSchoolMarker(school, idx, 'priv', schoolIcon);
                });
            }
            if (schools.secondary.length > 0) {
                schools.secondary.forEach((school, idx) => {
                    const schoolLocation = [school.lat, school.lon];
                    this.drawSchoolMarker(school, idx, 'sec', schoolIcon);
                    // oldBounds.extend(schoolLocation);
                });
            }

            //this.mapInstance.fitBounds(oldBounds);
        }
    }
    drawSchoolMarker(school, idx, type, schoolIcon) {
        //console.log(school);
        const schoolLocation = [school.lat, school.lon];
        console.log(schoolIcon);
        const schoolMarker = L.marker(schoolLocation).addTo(this.mapInstance);
        schoolMarker.setIcon(schoolIcon);
        // schoolMarker.bindPopup(school.schoolName);
        schoolMarker.on('click', () => {
            EventBus.dispatch(EVENT_NAMES.SHOW_PRIMARY_INFO_FOR_MAP + this.mapId, {
                school: school,
                type: type,
            });
        });
        this.schoolMarkers.push(schoolMarker);
    }
    drawSolds(solds, showPrice, distance) {
        if (this.soldMarkers.length > 0) {
            console.log(this.soldMarkers);
            this.soldMarkers.forEach((marker) => {
                this.mapInstance.removeLayer(marker);
            });
            this.soldMarkers = [];
        }
        solds.forEach((sold) => {
            var houseMarkerIcon = '';
            if (!showPrice) {
                let soldIcon = '';
                if (distance == 0) {
                    const priceIndex = getPriceLegendIndx(sold.soldPrice);
                    soldIcon = `price${priceIndex}`;
                } else {
                    if (sold.propBed >= 5) {
                        soldIcon = '5bed';
                    } else {
                        soldIcon = `${sold.propBed == 1 ? 2 : sold.propBed || 2}bed`;
                    }
                }

                houseMarkerIcon = new L.DivIcon({
                    className: '',
                    html: `<div style="margin-left:-10px;margin-top:-10px"><img src="/assets/mobileIcons/${soldIcon}.svg" /></div>`,
                });
            } else {
                var bedColor = '';
                if (distance == 0) {
                    bedColor = getPriceLabelColor(sold.soldPrice);
                } else {
                    if (sold.propBed <= 2 || !sold.propBed) {
                        bedColor = '#2cd2eb';
                    }
                    if (sold.propBed == 3) {
                        bedColor = '#2ceb84';
                    }
                    if (sold.propBed == 4) {
                        bedColor = '#ebb82e';
                    }
                    if (sold.propBed >= 5) {
                        bedColor = '#eb8f2c';
                    }
                }

                houseMarkerIcon = new L.DivIcon({
                    className: '',
                    html: `<div style="margin-left:-10px;margin-top:-10px;font-size:12px;color:white;display:flex;flex-direction:column;align-items:center">
                    <div style="background:${bedColor};width:53px;height:18px;border-radius:10px;display:flex;justify-content:center">

                    $${convertToM(sold.soldPrice)}m
                    </div>
                    <div style=" width: 0;height: 0; border-left: 6px solid transparent; border-right: 6px solid transparent;border-top: 6px solid ${bedColor};">
                    </div>
                    </div>`,
                });
            }

            const houseMarker = L.marker([sold.propY, sold.propX]).addTo(this.mapInstance);
            const popupContent = this.getHousePopup(sold);
            houseMarker.on('click', () => {
                console.log('sold is');
                console.log(sold);
                EventBus.dispatch(EVENT_NAMES.SHOW_PRIMARY_INFO_FOR_MAP + this.mapId, {
                    school: sold,
                    type: 'mapHouse',
                });
            });

            houseMarker.setIcon(houseMarkerIcon);
            this.soldMarkers.push(houseMarker);
        });
    }
}

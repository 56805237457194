import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { rst } from 'rt-state';
import { TR } from '../../../commons';
import { HelpComponent } from '../../PriceMap/components/HelpComponent';

export interface NewHomeThreePicItemProps {
    header: string;
    content: string;
    img: string;
    helpContent?: string;
    onClick?: () => void;
    cursorSet?: string;
}
const useStyles = makeStyles((theme) => ({
    root: {
        width: '328px',
        margin: '15px 20px',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
        '& img': {
            margin: '0 auto',
        },
        '& h3': {
            margin: '5px auto',
            padding: '0xp',
            fontSize: '20px',
            lineHeight: '26px',
            textAlign: 'center',
        },
        '& p': {
            margin: '0 auto',
            padding: '10px',
            fontSize: '16px',
            lineHeight: '22px',
            color: 'rgb(123 123 123)',
            textAlign: 'left',
        },
    },
}));

export const NewHomeThreePicItem = rst.create<NewHomeThreePicItemProps>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const { header, content, img, helpContent, onClick, cursorSet } = props;
        return (
            <div className={classes.root} onClick={onClick} style={{ cursor: cursorSet }}>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/static/images/${img}.png`} alt={img} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h3>
                        <TR name={header} />
                    </h3>
                    {helpContent ? <HelpComponent content={helpContent} maxWidth="xs" /> : null}
                </div>
                <p>
                    <TR name={content} />
                </p>
                {props.children}
            </div>
        );
    };
});

import { rst } from 'rt-state';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TR } from '../../../../commons';
import { isMobileDevice } from '../../../../commons/utils';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            fontSize: 10,
            width: 50,
        },
        rank: {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            borderRadius: 2,
            padding: 2,
            color: 'white',
        },
        schoolName: {
            width: '100px',
            fontSize: '6px',
            paddingTop: '5px',
        },
    };
});
export const schoolMapInfo = rst.create<{ schoolName; rank; starRating?; background: string }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                <div
                    className={classes.rank}
                    style={{ background: props.background, display: props.rank ? '' : 'none' }}>
                    {/* <TR name="schoolRank1"></TR>
                    {props.rank}gg
                    <TR name="schoolRank2"></TR> */}
                    {props.starRating ? props.starRating : 1}
                    <img src="assets/images/svgs/gold_star.svg" style={{ height: '12px', paddingLeft: '2px' }} />
                </div>
                <div className={classes.schoolName}>{props.schoolName}</div>
            </div>
        );
    };
});

import React from 'react';
import { rst } from 'rt-state';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DrawerController, XDrawer } from '../../components/Drawer';

const useStyles = makeStyles((theme) => {
    return {
        ctrlBtnClosed: {
            position: 'absolute',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            margin: 'auto',
            top: 0,
            bottom: 0,
            right: 0,
            borderRadius: '8px 0 0 8px',

            width: 12,
            height: 100,
        },
        ctrlBtnOpened: {
            position: 'absolute',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            margin: 'auto',
            top: 0,
            bottom: 0,
            left: 0,
            borderRadius: '0 8px 8px 0',

            width: 12,
            height: 100,
        },
        drawerRoot: {
            flexGrow: (props: any) => (props.isOpen ? 1 : 0),
            position: 'relative',
            height: '100%',
        },
        drawerPaper: {
            position: 'absolute',
        },
    };
});
export const RightDrawer = rst.create<{
    ctrl: DrawerController;
}>((ctx) => {
    return (props) => {
        const isOpen = props.ctrl.isOpen();
        const classes = useStyles({ ...props, isOpen });

        return (
            <>
                <XDrawer
                    controller={props.ctrl}
                    drawerProps={{
                        anchor: 'right',
                        variant: 'persistent',
                        className: classes.drawerRoot,
                        classes: {
                            paper: classes.drawerPaper,
                        },
                    }}>
                    {props.children}
                    <div className={classes.ctrlBtnOpened} onClick={() => props.ctrl.toggle()} />
                </XDrawer>
                <div className={classes.ctrlBtnClosed} onClick={() => props.ctrl.toggle()} />
            </>
        );
    };
});

import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { TR } from '../../commons';
const useStyles = makeStyles({
    select: {
        '&:before': {
            borderColor: 'red',
        },
        '&:after': {
            borderColor: 'red',
        },
    },
    icon: {
        marginLeft: 0,
    },
});
export const HomeOnSelect = (props: any) => {
    const { list, defaultValue, changeEvt, type } = props;
    const [state, setState] = useState({
        currentSelect: defaultValue,
    });
    const handleChange = (evt) => {
        setState({
            ...state,
            currentSelect: evt.target.value,
        });
        if (changeEvt) {
            changeEvt(type, evt);
        }
    };
    const classes = useStyles();
    return (
        <div style={{ padding: 10, paddingLeft: 0 }}>
            <FormControl variant="outlined">
                <Select
                    style={{ fontSize: 10 }}
                    className={classes.select}
                    inputProps={{ style: { borderColor: 'red' } }}
                    autoWidth
                    value={state.currentSelect}
                    onChange={handleChange}>
                    {list.map((option) => {
                        const { label, value } = option;
                        return (
                            <MenuItem style={{ fontSize: 10 }} value={value}>
                                <TR name={label}></TR>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Tabs, { Tab } from 'react-best-tabs';
import { TR } from '../../../../commons';
import 'react-best-tabs/dist/index.css';
import { ReportMap } from '../../../PropertyReport/components/reportMap/ReportMap';
import { api_getSoldProperties } from '../../../../services/property';
import { Button, CardContent } from '@material-ui/core';
import { CardTitle } from '../propertyDetails/cardTitle';
import { SuburbMedianInfo } from './suburbMedianInfo';
const useStyles = makeStyles({
    tabs: {
        width: '80%',
    },
    tab: {
        fontSize: '8px',
    },
    tabsTitle: {
        width: '100%',
    },
});
export const RecentSuburbSold = (props: any) => {
    const { suburbData, soldData, lat, lon, polygon_encoded } = props;
    const [propType, setPropType] = useState('house');

    const [closeContent, setCloseContent] = useState(false);

    const closeContentEvt = () => {
        setCloseContent(!closeContent);
    };

    const [tab, setTab] = useState(1);
    const changeTab = (evt, tab) => {
        setTab(tab);
    };

    const showHouseSold = () => {
        setPropType('house');
    };
    const showAptSold = () => {
        setPropType('apt');
    };
    const classes = useStyles();
    return (
        <>
            <div style={{ width: '100%', padding: '10px' }}>
                <CardTitle
                    noSwitch
                    closeContent={closeContent}
                    closeEvt={closeContentEvt}
                    title={'RecentSold'}></CardTitle>
                <CardContent style={{ padding: 0 }}>
                    <>
                        <Tabs
                            activeTab="1"
                            className="tabs"
                            ulClassName=""
                            activityClassName="bg-success"
                            onClick={changeTab}>
                            <Tab title={<TR name="House"></TR>} className={classes.tab}></Tab>
                            <Tab title={<TR name="Apartment"></TR>} className={classes.tab}></Tab>
                        </Tabs>

                        <div
                            style={{
                                visibility: tab == 1 ? 'visible' : 'hidden',
                                height: tab == 1 ? 'auto' : '0px',
                            }}>
                            <SuburbMedianInfo tab={tab} suburbData={suburbData}></SuburbMedianInfo>
                            <ReportMap
                                propCenter={{
                                    lat: lat,
                                    lon: lon,
                                }}
                                drawingObject={'6'}
                                drawingData={{
                                    border: polygon_encoded,
                                    soldData: soldData,
                                    propType: 'house',
                                }}></ReportMap>
                        </div>
                        <div
                            style={{
                                visibility: tab == 2 ? 'visible' : 'hidden',
                                height: tab == 2 ? 'auto' : '0px',
                            }}>
                            <SuburbMedianInfo tab={tab} suburbData={suburbData}></SuburbMedianInfo>
                            <ReportMap
                                propCenter={{
                                    lat: lat,
                                    lon: lon,
                                }}
                                drawingObject={'6'}
                                drawingData={{
                                    border: polygon_encoded,
                                    soldData: soldData,
                                    propType: 'apt',
                                }}></ReportMap>
                        </div>
                    </>
                </CardContent>
            </div>
        </>
    );
};

import { Card, CardContent, makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { rst, setState } from 'rt-state';
import { generateGUID, TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { generateUUID, isMobileDevice } from '../../../commons/utils';
import { SuburbSearchAutoComplete } from '../../PriceMap/Layers/SuburbSearchInput';
import { MapGlobalVars } from '../../PriceMap/support/PriceMapConsts';
import { domReady } from '../supportUtils/htmlUtils';
import { AutoAddressComplete } from './autoAddressComplete';
import { PropertyCard } from './propertyCard';
import { StandardCard } from './standardCard';
import { StreetCard } from './streetCard';
import { MobileSuburbDetail } from './suburbDetails';
import { LocalityCard } from './localityCard';
import { useHistory } from 'react-router';
import { Level1PropertyCard } from './propertyDetails/Level1PropertyCard';
import { api_getPropertyType } from '../../../commons/graphqlClient/graphAPI';
import { saveUserAccessLog } from '../../../services/userCenter';
import { SecurityProvider } from '../../../commons';
import { Level1PropertyCard_Apt } from './propertyDetails/Level1PropertyCard_Apt';

const useStyles = makeStyles({
    root: {},
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    btnGroup: {
        border: 'none',
        color: 'red',
        '.MuiToggleButton-root.Mui-selected': {
            backgroundColor: 'inheirt',
        },
    },
});

export const MobileAddressCard = rst.create<{
    backToMainPageEvt;
    queryType;
    queryId;
    queryAddress;
    showDetails;
    propertyType;
}>((ctx) => {
    const history = useHistory();
    const securityProvider = SecurityProvider.use();
    const state = rst.state({
        choosedAddress: null as any,
        currentFunc: ctx.props.queryType || '1',
        choosedPropId: ctx.props.queryId || '', // 111440000293
        pathStack: [],
        cursorOnAddress: false,
        inDetail: ctx.props.showDetails,
        propertyType: ctx.props.propertyType,
    });

    const changeFunc = (evt: any, newValue: any) => {
        state.currentFunc = newValue;
    };

    EventBus.on(EVENT_NAMES.EVENT_MOBILE_BACK_TO_HOME, () => {
        state.choosedPropId = null;
        state.choosedAddress = null;
    });
    EventBus.on(EVENT_NAMES.EVENT_MOBILE_SHOW_FUNC, (evtData) => {
        state.currentFunc = evtData.queryType;
    });

    EventBus.on(EVENT_NAMES.EVENT_MOBILE_SHOW_SUBURB, (evtData) => {
        changeFunc(null, '3');

        state.choosedAddress = { label: evtData.name, value: evtData.suburbId };
        state.choosedPropId = evtData.suburbId;

        console.log(JSON.stringify(state.pathStack));
        state.pathStack.push({
            queryType: '3',
            value: evtData.suburbId,
            label: evtData.name,
        });
        console.log(JSON.stringify(state.pathStack));
    });

    EventBus.on(EVENT_NAMES.EVENT_MOBILE_SHOW_PROPERTY, (evtData) => {
        changeFunc(null, '1');

        state.choosedAddress = { label: evtData.name, value: evtData.propId };
        state.choosedPropId = evtData.propId;
    });

    EventBus.on(EVENT_NAMES.EVENT_MOBILE_SHOW_STREET, (evtData) => {
        changeFunc(null, '2');

        state.choosedAddress = { label: evtData.name, value: evtData.streetId };
        state.choosedPropId = evtData.streetId;

        console.log(JSON.stringify(state.pathStack));
        state.pathStack.push({
            queryType: '2',
            value: evtData.streetId,
            label: evtData.name,
        });
        console.log(JSON.stringify(state.pathStack));
    });

    const suburbSearchInputStyles = {
        padding: '5px 15px',
        borderRadius: '10px',
        inputStyles: {
            background: 'white',
            height: 30,

            fontSize: 12,
            //fontSize: 18,
            placholder: isMobileDevice()
                ? 'Enter a Sydney address or suburb'
                : 'Enter a Sydney address or suburb to reveal the data insights you’ve never seen',
            borderRadius: 19,
            paddingLeft: isMobileDevice() ? 0 : 0,
            paddingRight: isMobileDevice() ? 0 : 0,
        },
    };

    EventBus.on(EVENT_NAMES.EVENT_SWITCH_TO_APARTMENT, (evt) => {
        itemChoosedEvt(evt.propId, { value: evt.propId, label: evt.address });
    });

    const itemChoosedEvt = async (propId: string, choosedAddress) => {
        console.log('prop id ===>', propId);
        saveUserAccessLog('QUERY_PROP_STREET_SUBURB', securityProvider.getUserProfile()?.id, {
            queryType: state.currentFunc,
            queryId: choosedAddress.value,
        });
        state.pathStack = [
            {
                queryType: state.currentFunc,
                value: choosedAddress.value,
                label: choosedAddress.label,
            },
        ];
        const propertyTypeResult: any = await api_getPropertyType(propId);

        // window.history.pushState('/', '', 'priceMap');
        history.push(
            `mobileHome?queryType=${state.currentFunc}&queryId=${choosedAddress.value}&queryAddress=${choosedAddress.label}&showingDetails=${ctx.props.showDetails}&propertyType=${propertyTypeResult.data.property?.__typename}`,
        );
        console.log(state.pathStack);
        state.choosedAddress = choosedAddress;
        state.choosedPropId = propId;
        state.propertyType = propertyTypeResult.data.property?.__typename;

        EventBus.dispatch(EVENT_NAMES.EVENT_MOBILE_IN_DETAIL_PAGE, { inDetail: true });

        if (MapGlobalVars.nearByTransEventName == '') {
            MapGlobalVars.nearByTransEventName = generateGUID();
        }
    };
    const backEvt = () => {
        history.goBack();
    };

    if (ctx.props.queryId) {
        itemChoosedEvt(ctx.props.queryId, { value: ctx.props.queryId, label: ctx.props.queryAddress });
    }

    return (props) => {
        const classes = useStyles();
        return (
            <>
                <style>
                    {`
                    #mobileIndexToggleButtonGroup .MuiToggleButton-root{
                        font-size: .85rem;
                        border: none;
                        color: rgba(255, 255, 255, 0.75);
                    }
                    #mobileIndexToggleButtonGroup .MuiToggleButton-root.Mui-selected{
                        position: relative;
                        font-size: 1rem;
                        color: #fff;
                        background: inherit;
                    }
                    #mobileIndexToggleButtonGroup .MuiToggleButton-root.Mui-selected::after{
                        position: absolute;
                        display: block;
                        content: '';
                        bottom: 0;
                        left: 50%;
                        margin-left: -7px;
                        border: 7px solid transparent;
                        border-bottom: 7px solid #fff;
                    }
                    .mobileIndexSearch>div>div{
                        border: none;
                    }
                    .mobileIndexSearch .css-1okebmr-indicatorSeparator, .mobileIndexSearch svg{
                        display: none;
                    }
                    .mobileIndexSearch div[class*='indicatorContainer'] {
                        width: 38px;
                        height: 100%;
                        background: url(${process.env.PUBLIC_URL}/static/images/mobile-search.png);
                        background-size: 60%;
                        background-repeat: no-repeat;   
                        background-position: center;
                    }
                `}
                </style>
                {!state.choosedPropId ? (
                    <StandardCard>
                        <>
                            <div style={{ marginTop: 8 }}></div>
                            <div style={{ fontSize: '34px', color: '#fff', fontWeight: 'bold' }}>
                                <TR name="AddressCardDesc"></TR>
                            </div>
                            <div style={{ fontSize: '16px', color: '#fff' }}>
                                <TR name="AddressCardSubtitle"></TR>
                            </div>

                            <div style={{ fontSize: '16px', color: '#fff' }}>
                                <TR name="AddressCardSubtitle1"></TR>
                            </div>
                            <div style={{ marginTop: 10 }}></div>
                        </>

                        <div style={{ width: '100%' }} id="mobileIndexToggleButtonGroup">
                            <ToggleButtonGroup
                                exclusive
                                color="primary"
                                onChange={changeFunc}
                                value={state.currentFunc}>
                                <ToggleButton value="1" style={{ border: 'none' }}>
                                    <TR name="searchProperty"></TR>
                                </ToggleButton>
                                <ToggleButton value="2">
                                    <TR name="searchStreet"></TR>
                                </ToggleButton>
                                <ToggleButton value="3">
                                    <TR name="searchSuburb"></TR>
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <div className="mobileIndexSearch" style={{ height: state.cursorOnAddress ? 120 : 'auto' }}>
                                <AutoAddressComplete
                                    focusEvt={() => {
                                        state.cursorOnAddress = true;
                                    }}
                                    blurEvt={() => {
                                        state.cursorOnAddress = false;
                                    }}
                                    id="combo-box-demo"
                                    init_choosedItem={state.choosedAddress}
                                    itemChoosedEvt={itemChoosedEvt}
                                    queryType={state.currentFunc}></AutoAddressComplete>
                            </div>
                        </div>
                    </StandardCard>
                ) : null}
                {state.choosedPropId ? (
                    <>
                        {state.currentFunc == '1' ? (
                            state.propertyType != 'House' ? (
                                state.inDetail ? (
                                    <PropertyCard
                                        backEvt={backEvt}
                                        inDetails={props.queryId != null}
                                        itemChoosedEvt={itemChoosedEvt}
                                        choosedItem={state.choosedAddress}
                                        propId={state.choosedPropId}></PropertyCard>
                                ) : (
                                    <Level1PropertyCard_Apt propId={state.choosedPropId}></Level1PropertyCard_Apt>
                                )
                            ) : state.inDetail ? (
                                <PropertyCard
                                    backEvt={backEvt}
                                    inDetails={props.queryId != null}
                                    itemChoosedEvt={itemChoosedEvt}
                                    choosedItem={state.choosedAddress}
                                    propId={state.choosedPropId}></PropertyCard>
                            ) : (
                                <Level1PropertyCard propId={state.choosedPropId}></Level1PropertyCard>
                            )
                        ) : null}
                        {state.currentFunc == '2' ? (
                            <StreetCard
                                backEvt={backEvt}
                                inDetails={props.showDetails}
                                itemChoosedEvt={itemChoosedEvt}
                                choosedItem={state.choosedAddress}
                                propId={state.choosedPropId}></StreetCard>
                        ) : null}

                        {state.currentFunc == '3' && state.choosedAddress ? (
                            <MobileSuburbDetail
                                backEvt={backEvt}
                                itemChoosedEvt={itemChoosedEvt}
                                choosedItem={state.choosedAddress}></MobileSuburbDetail>
                        ) : // <LocalityCard
                        //     backEvt={backEvt}
                        //     itemChoosedEvt={itemChoosedEvt}
                        //     choosedItem={state.choosedAddress}
                        //     propId={state.choosedPropId}></LocalityCard>
                        null}
                    </>
                ) : null}
            </>
        );
    };
});

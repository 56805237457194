import { rst } from 'rt-state';
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { TR } from '../../../commons';
import { RankTable } from './RankTable';
import { allSoldPropertiyData } from '../MapContainer/PropertyMarkerController';
import { MapGlobalVars } from '../support/PriceMapConsts';
import { calculateMedianPrice } from '../support/common';
import { convertToM, getYearMonthFromSoldDate, isMobileDevice } from '../../../commons/utils';
import { SuburbSoldByBedBar } from './SuburbSoldByBedBar';
import { getAptSoldMedianPrice, getPropertyPriceIfNowSell } from '../support/propertyFeatureHelper';

export const SuburbSoldByBed = rst.create((ctx) => {
    EventBus.on(EVENT_NAMES.EVENT_OPEN_SOLD_BY_BED_DIALOG, () => {
        state.openSuburbByBed = true;
    });
    const state = rst.state({
        openSuburbByBed: false,
    });
    const handleClose = () => {
        state.openSuburbByBed = false;
    };
    return (props) => {
        let soldPropertiesInThisSuburb: any = [];
        if (allSoldPropertiyData && allSoldPropertiyData.length > 0) {
            allSoldPropertiyData.forEach((loopSuburbSold) => {
                if (
                    loopSuburbSold.localityId ==
                    (MapGlobalVars.transShowingSuburbData?.id || MapGlobalVars.clickedSuburbData?.id)
                ) {
                    soldPropertiesInThisSuburb = loopSuburbSold.houseSold;
                }
            });
        }
        var soldPriceRange = {
            min: 0,
            max: 0,
        };

        var soldNowPriceRange = {
            min: 0,
            max: 0,
        };
        const rowData = [
            {
                columnValues: ['3Beds', 0, '12', 'abcdefg', '12m', '12'],
                soldProperties: [],
                soldPriceList: [],
                nowSoldPriceList: [],
                soldLandSize: [],
                soldLandValue: [],
            },
            {
                columnValues: ['4Beds', 0, '12', 'abcdefg', '12m', '12'],
                soldProperties: [],
                soldPriceList: [],
                nowSoldPriceList: [],
                soldLandSize: [],
                soldLandValue: [],
            },
            {
                columnValues: ['5Beds', 0, '12', 'abcdefg', '12m', '12'],
                soldProperties: [],
                soldPriceList: [],
                nowSoldPriceList: [],
                soldLandSize: [],
                soldLandValue: [],
            },
        ];

        if (soldPropertiesInThisSuburb && soldPropertiesInThisSuburb.length > 0) {
            soldPropertiesInThisSuburb.forEach((loopProperty) => {
                console.log(loopProperty);
                if (soldPriceRange.max == 0 && soldPriceRange.min == 0) {
                    soldPriceRange = {
                        min: loopProperty.sold_price,
                        max: loopProperty.sold_price,
                    };
                }

                const nowSoldPrice = getPropertyPriceIfNowSell(
                    getYearMonthFromSoldDate(loopProperty.sold_contract_date),
                    loopProperty.sold_price,
                );
                console.log('===========');
                console.log(nowSoldPrice);

                if (soldNowPriceRange.max == 0 && soldNowPriceRange.min == 0) {
                    soldNowPriceRange = {
                        min: nowSoldPrice,
                        max: nowSoldPrice,
                    };
                }

                if (loopProperty.sold_price > soldPriceRange.max) {
                    soldPriceRange.max = loopProperty.sold_price;
                }
                if (loopProperty.sold_price < soldPriceRange.min) {
                    soldPriceRange.min = loopProperty.sold_price;
                }

                if (nowSoldPrice > soldNowPriceRange.max) {
                    soldNowPriceRange.max = nowSoldPrice;
                }
                if (nowSoldPrice < soldNowPriceRange.min) {
                    soldNowPriceRange.min = nowSoldPrice;
                }

                if (loopProperty.prop_bed <= 3) {
                    console.log('3 bedrooms');
                    rowData[0].columnValues[1] = Number(rowData[0].columnValues[1]) + 1;
                    rowData[0].soldProperties.push(loopProperty);
                    rowData[0].soldPriceList.push(loopProperty.sold_price);
                    rowData[0].nowSoldPriceList.push(nowSoldPrice);
                    rowData[0].soldLandSize.push(loopProperty.land_size);
                    rowData[0].soldLandValue.push(loopProperty.land_value);
                }
                if (loopProperty.prop_bed == 4) {
                    console.log('4 bedrooms');
                    rowData[1].columnValues[1] = Number(rowData[1].columnValues[1]) + 1;
                    rowData[1].soldProperties.push(loopProperty);
                    rowData[1].soldPriceList.push(loopProperty.sold_price);
                    rowData[1].nowSoldPriceList.push(nowSoldPrice);
                    rowData[1].soldLandSize.push(loopProperty.land_size);
                    rowData[1].soldLandValue.push(loopProperty.land_value);
                }
                if (loopProperty.prop_bed >= 5) {
                    rowData[2].columnValues[1] = Number(rowData[2].columnValues[1]) + 1;
                    rowData[2].soldProperties.push(loopProperty);
                    rowData[2].soldPriceList.push(loopProperty.sold_price);
                    rowData[2].nowSoldPriceList.push(nowSoldPrice);
                    rowData[2].soldLandSize.push(loopProperty.land_size);
                    rowData[2].soldLandValue.push(loopProperty.land_value);
                }
            });
            console.log('=====================');
            soldPriceRange = {
                min: Math.floor(convertToM(soldPriceRange.min)),
                max: Math.ceil(convertToM(soldPriceRange.max)),
            };

            soldNowPriceRange = {
                min: Math.floor(convertToM(soldNowPriceRange.min)),
                max: Math.ceil(convertToM(soldNowPriceRange.max)),
            };
            console.log(soldPriceRange);
        }

        rowData.forEach((loopRow) => {
            const medianPrice = calculateMedianPrice(loopRow.soldPriceList);
            const medianLandSize = calculateMedianPrice(loopRow.soldLandSize);
            const medianLandValue = calculateMedianPrice(loopRow.soldLandValue);
            if (medianPrice && medianPrice.median) {
                loopRow.columnValues[2] = convertToM(medianPrice.median);
                loopRow.columnValues[3] = convertToM(calculateMedianPrice(loopRow.nowSoldPriceList)?.median);
                loopRow.columnValues[4] = medianLandSize.median.toFixed(2);
                loopRow.columnValues[5] = convertToM(medianLandValue.median);
            }
        });

        console.log(rowData);
        return (
            <Dialog fullScreen={isMobileDevice() ? true : false} open={state.openSuburbByBed}>
                <DialogTitle id="customized-dialog-title">
                    <TR name={'SuburbRankTitle1'}></TR>
                </DialogTitle>
                <DialogContent>
                    <RankTable
                        title={'SOLD_BY_BED_REPORT'}
                        rowData={rowData}
                        columns={[
                            { headerName: 'BEDS', columnWidth: '16%' },
                            { headerName: 'TOTAL_SOLD', columnWidth: '16%' },
                            { headerName: 'SOLD_MEDIAN_PRICE', columnWidth: '16%' },
                            { headerName: 'SOLD_CONVERT_MEDIAN_PRICE', columnWidth: '16%' },
                            { headerName: 'MEDIAN_LAND_SIZE', columnWidth: '16%' },
                            { headerName: 'MEDIAN_LAND_VALUE', columnWidth: '16%' },
                        ]}></RankTable>

                    <SuburbSoldByBedBar
                        soldPriceRange={soldPriceRange}
                        soldData={rowData}
                        title="SOLD_BY_BED_PRICE_DIST"></SuburbSoldByBedBar>

                    <SuburbSoldByBedBar
                        soldPriceRange={soldNowPriceRange}
                        soldData={rowData}
                        title="SOLD_BY_BED_NOW_PRICE_DIST"
                        showNorPrice={true}></SuburbSoldByBedBar>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
});

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    appBar: {
        boxShadow: '0 1px 8px rgba(0,0,0,.3)',
        position: 'relative',
        zIndex: theme.zIndex.drawer + 100,
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
        },
        maxHeight: 60,
    },
    toolBar: {
        paddingLeft: theme.spacing(1) / 2,
        paddingRight: theme.spacing(1) / 2,
    },
    branding: {
        display: 'flex',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        margin: 'auto 0',
        width: 300,
        lineHeight: 50,
        padding: '0 64px 0 0',
    },
    logo: {
        margin: 'auto',
        maxWidth: 180,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 150,
        },
    },
}));

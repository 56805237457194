import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { isMobileDevice } from '../../../../commons/utils';
import { PriceMapFilter } from '../../support/DataModels';
import { SuburbSummaryPanel } from './SuburbSummaryPanel';
const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: isMobileDevice() ? '10px 11px 15px' : '11px 20px 20px',
        },
    };
});
/*
showFor : map or infoPanel
*/
export const SuburbSummary = rst.create<{
    suburbData: any;
    medianData: any;
    showFor: string;
    changeSuburbChartTypeEvt?: any;
    chartType?: any;
    userRegister?: boolean;
}>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div
                className={classes.root}
                style={{ paddingLeft: props.showFor == 'map' ? (isMobileDevice() ? 15 : 20) : 0 }}>
                <SuburbSummaryPanel
                    suburbData={props.suburbData}
                    showFor={props.showFor}
                    changeSuburbChartTypeEvt={props.changeSuburbChartTypeEvt}
                    chartType={props.chartType}
                    medianData={props.medianData}></SuburbSummaryPanel>
            </div>
        );
    };
});

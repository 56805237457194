import { rst } from 'rt-state';
import React from 'react';
import { makeStyles, Switch, SwitchClassKey } from '@material-ui/core';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import classes from '*.module.css';
import { isMobileDevice } from '../../../../commons/utils';
import { global_screenSize } from '../../../../commons/globalVars';
import { SwitchProps } from 'react-router';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { MapGlobalVars } from '../../support/PriceMapConsts';
import { SecurityProvider, storage, TR } from '../../../../commons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { AppProvider } from '../../../../commons/appProvider';
import { triggrMemberWindowForVisitor } from '../../support/common';
import { Permission } from '../../../../commons/security/authz';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'absolute',
            top: isMobileDevice() ? 50 : 5,
            right: isMobileDevice() ? 20 : global_screenSize.width / 2,
            zIndex: 1299,
            background: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: isMobileDevice() ? '5px' : 20,
            paddingRight: isMobileDevice() ? '5px' : 20,
            fontSize: isMobileDevice() ? '12px' : '14px',
            borderRadius: 5,
        },
    };
});
export const PriceSwitchButton = rst.create((ctx) => {
    const appProvider = AppProvider.use();
    const securityProvider = SecurityProvider.use();
    const pageState = rst.state({
        showButton: true,
        isMember: false,
    });

    const hasLogin = () => {
        return securityProvider.hasLogin();
    };

    EventBus.on(EVENT_NAMES.EVENT_AVOID_OVERLAY, (evt) => {
        pageState.showButton = evt.showIcon;
    });
    const showButtonHelp = () => {
        EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_DIALOG, {
            open: true,
            title: 'HELP',
            content: 'PRICE_SWITCH_BUTTON_HELP',
            cancelButtonText: 'GOT_IT',
        });
    };
    const buttonState = rst.state({ buttonChecked: false });
    const checkPermission = () => {
        return true;
        // if (hasLogin()) {

        // } else {
        //     appProvider.openLoginDialog(null);
        // }
    };
    return (props) => {
        if (
            securityProvider.hasPermission(Permission.PROPERTY_BUYER) ||
            securityProvider.hasPermission(Permission.TRIAL)
        ) {
            pageState.isMember = true;
        } else {
            pageState.isMember = false;
        }

        const classes = useStyles();

        return (
            <div className={classes.root} style={{ display: pageState.showButton ? 'flex' : 'none' }}>
                {isMobileDevice() ? null : <TR name="PRICE_SWITCH"></TR>}
                <div onClick={checkPermission}>
                    <IOSSwitch canUse={true}></IOSSwitch>
                </div>
                <span style={{ cursor: 'pointer', marginLeft: 5 }} onClick={showButtonHelp}>
                    <HelpOutlineIcon style={{ color: 'gray', fontSize: 14 }}></HelpOutlineIcon>
                </span>
            </div>
        );
    };
});

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
    canUse?: boolean;
}

const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#52d869',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#52d869',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }),
)(({ classes, ...props }: Props) => {
    let checked = false;
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            disabled={false}
            onChange={() => {
                checked = !checked;
                MapGlobalVars.showPropertyPriceAsRef = checked;
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_REFER_CURRENT_SOLD_PRICE, { showRefPrice: checked });
            }}
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

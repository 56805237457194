import { XTableColumn } from '../../../../components/Table';
import React from 'react';
import { commonColumns, TitleAndColumns } from './common';
import { numToMillion, numToMoney, decimalToPct } from '../../../../commons/utils';

const houseColumns: XTableColumn[] = [
    ...commonColumns,
    {
        name: 'localityHouseMedian',
        sort: true,
        align: 'center',
        label: '别墅中位价',
        render: (row) => {
            return <span>{numToMillion(row.localityHouseMedian)}</span>;
        },
    },
];

const aptMedianPriceColumns: XTableColumn[] = [
    ...commonColumns,
    {
        name: 'localityAptMedian',
        sort: true,
        align: 'center',
        label: '公寓中位价',
        render: (row) => {
            return <span>{numToMillion(row.localityAptMedian)}</span>;
        },
    },
];

const aptWeeklyRentalColumns: XTableColumn[] = [
    ...commonColumns,
    {
        name: 'localityAptWeeklyRent',
        sort: true,
        align: 'center',
        label: '公寓周租金',
        render: (row) => {
            return <span>{numToMoney(row.localityAptWeeklyRent)}</span>;
        },
    },
];

const aptReturnColumns: XTableColumn[] = [
    ...commonColumns,
    {
        name: 'localityAptReturn',
        sort: true,
        align: 'center',
        label: '公寓回报率',
        render: (row) => {
            return <span>{decimalToPct(row.localityAptReturn)}</span>;
        },
    },
];

export const priceTitleAndColumns: TitleAndColumns = {
    localityHouseMedian: { title: '别墅中位价排行榜', columns: houseColumns },
    localityAptMedian: { title: '公寓中位价排行榜', columns: aptMedianPriceColumns },
    localityAptWeeklyRent: { title: '公寓周租金排行榜', columns: aptWeeklyRentalColumns },
    localityAptReturn: { title: '公寓回报率排行榜', columns: aptReturnColumns },
};

/* eslint-disable prettier/prettier */
import { Divider, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { rst } from 'rt-state';
import { TR, TRHtml } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import { queryProp_byGraphQL } from '../../../commons/graphqlClient/graphAPI';
import { convertToM, getYearMonthFromSoldDate } from '../../../commons/utils';
import {
    api_estimatePropertyValue,
    api_getAroundTranscations,
    api_getPropertyFeatures,
} from '../../../services/property';
import { RecentSoldHouseInfo } from '../../PriceMap/Layers/PropertySidebar/house/RecentSoldHouseInfo';
import {
    constructPropObjFromFeature,
    getPropLastRecord,
    showGoogleResultByAddress,
} from '../../PriceMap/support/propertyFeatureHelper';
import { BlContentData } from '../components/BlContentData';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentTitle } from '../components/ContentTitle';
import { ContentTitleData } from '../components/ContentTitleData';
import { RdContentData } from '../components/RdContentData';
import { ReportMap } from '../components/reportMap/ReportMap';
import { Tips } from '../components/Tips';
import * as locales from '@material-ui/core/locale';
import { max, min } from 'lodash';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            textAlign: 'left',
            padding: '5px 0',
        },
        part2: {
            textAlign: 'left',
            padding: '5px 0',
        },
        part3: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            paddingTop: '10px',
            // alignItems: 'flex-start',
        },
        address: {
            display: 'flex',
            fontSize: '14px',

            color: '#b43b3b',
            fontWeight: 700,
            justifyContent: 'space-between',
        },
        addressContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            // alignItems: 'center',
            padding: '5px 0',
            '& span': {
                fontSize: '14px',
            },
        },
        distance: {
            width: '74px',
            height: '41px',
            borderBottomLeftRadius: 5,
            background: 'rgba(69,150,196,0.10)',
            paddingLeft: 10,
            paddingTop: 3,
            paddingBottom: 3,
            paddingRight: 10,
            fontSize: 13,
            color: '#4f94b7',
        },
        part3Row: {
            display: 'flex',

            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 15px',
        },
        part3RowPrice: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 10px',
            width: '100%',
        },
        propertyCard: {
            marginTop: 10,
            background: 'rgba(172,211,233,0.10)',
            border: ' 1px solid rgba(118,131,160,0.09)',
            borderRadius: 5,
        },
        part3RowContent: {
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between',
            alignItems: 'center',
            '& h2': {
                fontSize: '12px',
                color: 'rgb(156 156 156)',
                margin: '5px 0',
                fontWeight: '400',
                textAlign: 'center',
            },
        },
        part3RowOTHER_FEATURE: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            '& h2': {
                fontSize: '12px',
                color: 'rgb(156 156 156)',
                margin: '5px 0',
                fontWeight: '400',
                textAlign: 'center',
            },
        },
        part3RowContentPrice: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            '& h2': {
                fontSize: '14px',
                color: 'rgb(48 48 48)',
                margin: '5px 0',
                fontWeight: '600',
                width: '65%',
            },
            '& span': {
                fontSize: '14px',
                color: 'rgb(168 68 53)',
                fontWeight: '600',
                // marginLeft: '20px',
                background: 'linear-gradient(to bottom, rgb(250 252 255) 70%, rgb(249 235 237) 0%)',
                width: 'fit-content',
            },
        },
        priceExpDetail: {
            background: 'rgb(250 252 255)',
            border: '1px solid rgb(236 239 246)',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px 0px',
        },
    };
});

export const Card32 = rst.create<{ prop: any; pql: any; estimateMiddle: any; mobile?: boolean }>((ctx) => {
    console.log(ctx.props.pql);
    const appProvider = AppProvider.use();

    const pageState = rst.state({
        similarTrans: [],
        p1: null,
        p2: null,
        p3: null,
        p4: null,
        p5: null,
        p_ql1: null,
        p_ql2: null,
        p_ql3: null,
        p_ql4: null,
        p_ql5: null,
        p_estValue: null,
        p_estValue1: null,
        p_estValue2: null,
        p_estValue3: null,
        p_estValue4: null,
        p_estValue5: null,

        p_priceExplain1_lower1: 0,
        p_priceExplain1_upper1: 0,
        p_priceExplain2_lower1: 0,
        p_priceExplain2_upper1: 0,
        p_priceExplain3_lower1: 0,
        p_priceExplain3_upper1: 0,

        p_priceExplain1_lower2: 0,
        p_priceExplain1_upper2: 0,
        p_priceExplain2_lower2: 0,
        p_priceExplain2_upper2: 0,
        p_priceExplain3_lower2: 0,
        p_priceExplain3_upper2: 0,

        p_priceExplain1_lower3: 0,
        p_priceExplain1_upper3: 0,
        p_priceExplain2_lower3: 0,
        p_priceExplain2_upper3: 0,
        p_priceExplain3_lower3: 0,
        p_priceExplain3_upper3: 0,

        p_priceExplain1_lower4: 0,
        p_priceExplain1_upper4: 0,
        p_priceExplain2_lower4: 0,
        p_priceExplain2_upper4: 0,
        p_priceExplain3_lower4: 0,
        p_priceExplain3_upper4: 0,

        p_priceExplain1_lower5: 0,
        p_priceExplain1_upper5: 0,
        p_priceExplain2_lower5: 0,
        p_priceExplain2_upper5: 0,
        p_priceExplain3_lower5: 0,
        p_priceExplain3_upper5: 0,
        p_explain_show1: false,
        p_explain_show2: false,
        p_explain_show3: false,
        p_explain_show4: false,
        p_explain_show5: false,
    });

    api_estimatePropertyValue(ctx.props.prop.allFeatures, null).then((estResult) => {
        pageState.p_estValue = estResult;
        console.log(estResult);
    });

    api_getAroundTranscations(
        'House',
        ctx.props.prop.lat,
        ctx.props.prop.lon,
        6,
        ctx.props.prop.allFeatures.prop_id,
        1,
        ctx.props.estimateMiddle,
        ctx.props.prop.allFeatures.prop_bed,
        ctx.props.prop.allFeatures.prop_car,
        ctx.props.prop.allFeatures.prop_bath,
        'similarity',
    ).then((trans: any) => {
        pageState.similarTrans = trans.slice(0, 5);
        // .sort((a1, a2) => {
        //     var score1 = parseFloat(a1.score);
        //     var score2 = parseFloat(a2.score);

        //     if (score1 < score2) {
        //         return -1;
        //     }
        //     if (score1 == score2) {
        //         return 0;
        //     }
        //     if (score1 > score2) {
        //         return 1;
        //     }
        // })
        console.log(pageState.similarTrans);

        pageState.similarTrans.forEach((loopEach, idx) => {
            console.log(ctx.props.prop);
            api_getPropertyFeatures(loopEach.prop_id).then((propFeatures) => {
                console.log('=======xxxxxxxx');
                console.log(propFeatures);
                pageState['p' + (idx + 1)] = constructPropObjFromFeature(propFeatures);
                console.log(pageState['p' + (idx + 1)]);

                const calculatePropFeatures = JSON.parse(JSON.stringify(pageState['p' + (idx + 1)].allFeatures));

                calculatePropFeatures.prop_x = ctx.props.prop.allFeatures.prop_x;
                calculatePropFeatures.prop_y = ctx.props.prop.allFeatures.prop_y;
                calculatePropFeatures.x_wynyard = ctx.props.prop.allFeatures.x_wynyard;
                calculatePropFeatures.y_wynyard = ctx.props.prop.allFeatures.y_wynyard;
                calculatePropFeatures.land_value_reset = ctx.props.prop.allFeatures.land_value_reset;
                calculatePropFeatures.prop_address = ctx.props.prop.allFeatures.prop_address;
                calculatePropFeatures.prop_area = ctx.props.prop.allFeatures.prop_area;
                calculatePropFeatures.prop_area_type = ctx.props.prop.allFeatures.prop_area_type;
                calculatePropFeatures.prop_zoning = ctx.props.prop.allFeatures.prop_zoning;
                calculatePropFeatures.region = ctx.props.prop.allFeatures.region;

                calculatePropFeatures.sec_pri_lv_time = ctx.props.prop.allFeatures.sec_pri_lv_time;
                calculatePropFeatures.sec_pri_sold_time = ctx.props.prop.allFeatures.sec_pri_sold_time;

                console.log(calculatePropFeatures);
                api_estimatePropertyValue(calculatePropFeatures, null).then((estResult) => {
                    pageState['p_estValue' + (idx + 1)] = estResult;

                    console.log(estResult);
                });
            });

            queryProp_byGraphQL(loopEach.prop_id).then((QLResult) => {
                pageState['p_ql' + (idx + 1)] = QLResult;
                console.log(pageState['p_ql' + (idx + 1)]);
            });
        });

        console.log(pageState.similarTrans);
    });
    const getSectorIncrease = (soldDate, sectorData) => {
        console.log(sectorData);
        if (sectorData && sectorData.priceTrendHouse) {
            var soldMedianIdx = -1;
            sectorData.priceTrendHouse.month.forEach((loopMonth, loopIdx) => {
                if (soldDate == loopMonth) {
                    console.log(soldDate);
                    soldMedianIdx = loopIdx;
                }
            });
            console.log(
                'Current median price is ' +
                    sectorData.priceTrendHouse.monthlyMedian[sectorData.priceTrendHouse.monthlyMedian.length - 1],
            );
            console.log('last July median price is ' + sectorData.priceTrendHouse.monthlyMedian[soldMedianIdx]);
            if (soldMedianIdx != -1) {
                return (
                    sectorData.priceTrendHouse.monthlyMedian[sectorData.priceTrendHouse.monthlyMedian.length - 1] /
                    sectorData.priceTrendHouse.monthlyMedian[soldMedianIdx]
                );
            }
        }
    };

    const getBuildingValueRange = (soldIdx) => {
        console.log(pageState.p_estValue);

        pageState['p_priceExplain3_lower' + (soldIdx + 1)] = convertToM(
            pageState.p_estValue?.lowerPrice - pageState['p_estValue' + (soldIdx + 1)]?.lowerPrice,
        );
        pageState['p_priceExplain3_upper' + (soldIdx + 1)] = convertToM(
            pageState.p_estValue?.upperPrice - pageState['p_estValue' + (soldIdx + 1)]?.upperPrice,
        );

        return (
            pageState['p_priceExplain3_lower' + (soldIdx + 1)] +
            ' - ' +
            pageState['p_priceExplain3_upper' + (soldIdx + 1)]
        );
    };

    return (props) => {
        const classes = useStyles();
        const getExp1 = (idx, pct) => {
            const prop = pageState['p' + (idx + 1)];
            const pql = pageState['p_ql' + (idx + 1)];
            if (prop && pql) {
                const priceIncrease: any = getMarketTrend(prop, pql);
                if (pct) {
                    return (
                        (((priceIncrease * 1.5) / parseFloat(prop.price)) * 98).toFixed(2) +
                        '%' +
                        '-' +
                        (((priceIncrease * 1.5) / parseFloat(prop.price)) * 102).toFixed(2) +
                        '%'
                    );
                } else {
                    pageState['p_priceExplain1_lower' + (idx + 1)] = convertToM(
                        parseInt(priceIncrease) - parseFloat(prop.price) * 0.02,
                    );
                    pageState['p_priceExplain1_upper' + (idx + 1)] = convertToM(
                        parseInt(priceIncrease) + parseFloat(prop.price) * 0.02,
                    );

                    return (
                        convertToM(parseInt(priceIncrease) - parseFloat(prop.price) * 0.02) +
                        '-' +
                        convertToM(parseInt(priceIncrease) + parseFloat(prop.price) * 0.02)
                    );
                }
            }
        };
        const getPriceIncreaseRange = (idx, prop, pct, landUPdateDate?, land?) => {
            // console.log('=====similar idx ===>' + idx);
            console.log(props.pql);
            if (prop && props.pql) {
                let lastSoldDate = getYearMonthFromSoldDate(prop.sold_contract_date);
                if (landUPdateDate) {
                    lastSoldDate = '2021-07';
                }
                const sectorIncreasePct = getSectorIncrease(lastSoldDate, ctx.props.pql.property.locality.sector);

                if (pct) {
                    return (
                        ((sectorIncreasePct - 1.02) * 100).toFixed(0) +
                        '%' +
                        ' - ' +
                        ((sectorIncreasePct - 0.98) * 100).toFixed(0) +
                        '%'
                    );
                } else {
                    if (land) {
                        pageState['p_priceExplain2_lower' + (idx + 1)] = convertToM(
                            (sectorIncreasePct - 0.02) * (props.prop.landValue - pageState['p' + (idx + 1)]?.landValue),
                        );
                        pageState['p_priceExplain2_upper' + (idx + 1)] = convertToM(
                            (sectorIncreasePct + 0.02) * (props.prop.landValue - pageState['p' + (idx + 1)]?.landValue),
                        );
                        return (
                            pageState['p_priceExplain2_lower' + (idx + 1)] +
                            ' - ' +
                            pageState['p_priceExplain2_upper' + (idx + 1)]
                        );
                    } else {
                        pageState['p_priceExplain1_lower' + (idx + 1)] = convertToM(
                            (sectorIncreasePct - 1.02) * prop.price,
                        );
                        pageState['p_priceExplain1_upper' + (idx + 1)] = convertToM(
                            (sectorIncreasePct - 0.98) * prop.price,
                        );
                        return (
                            pageState['p_priceExplain1_lower' + (idx + 1)] +
                            ' - ' +
                            pageState['p_priceExplain1_upper' + (idx + 1)]
                        );
                    }
                }
            }
            return '';
        };
        const getPriceIncrease = (prop, pql) => {
            console.log(pql);
            if (prop && pql) {
                console.log(pql);
                const lastSoldDate = getYearMonthFromSoldDate(prop.sold_contract_date);
                console.log(lastSoldDate);
                const sectorIncreasePct = getSectorIncrease(lastSoldDate, pql.data.property.locality.sector);
                const propIncreasePrice = prop.price * sectorIncreasePct - prop.price;
                return convertToM(propIncreasePrice);
            }

            return '';
        };
        const getMarketTrend = (prop, pql) => {
            if (prop && pql) {
                const soldPrice = parseFloat(prop.price);
                const sectorData = pql.data.property.locality.sector.priceTrendHouse.monthlyMedian;
                console.log(sectorData);
                const currentSectorData = parseFloat(sectorData[sectorData.length - 1]);
                const lastSectorData = parseFloat(sectorData[sectorData.length - 2]);
                const lastSoldDate = getYearMonthFromSoldDate(prop.sold_contract_date);

                var soldMedianIdx = -1;
                pql.data.property.locality.sector.priceTrendHouse.month.forEach((loopMonth, loopIdx) => {
                    if (lastSoldDate == loopMonth) {
                        soldMedianIdx = loopIdx;
                    }
                });

                const soldSectorData = parseFloat(sectorData[soldMedianIdx]);

                console.log(
                    'sold price is ==>' +
                        soldPrice +
                        'current sector : ' +
                        currentSectorData +
                        ';last sector ===>' +
                        lastSectorData +
                        ';sold sector===>' +
                        soldSectorData,
                );
                return ((((currentSectorData + lastSectorData) / 2 / soldSectorData - 1) * 2) / 3) * soldPrice;
            } else {
                return 0;
            }
        };

        const getLandValueDiff = (idx) => {
            console.log(parseFloat(pageState['p_priceExplain2_lower' + idx]));
            console.log(parseFloat(pageState['p_priceExplain2_upper' + idx]));
            const landValueDiff =
                (parseFloat(pageState['p_priceExplain2_lower' + idx]) +
                    parseFloat(pageState['p_priceExplain2_upper' + idx])) /
                2;
            return landValueDiff.toFixed(2);
        };

        const showPropTag = (prop) => {
            const tags = [];
            if (prop && prop.smart_tags) {
                console.log(prop.smart_tags);
                const selectedTags = prop.smart_tags.filter((loopTag) => {
                    return loopTag.selected == 1;
                });
                selectedTags.forEach((loopTag) => {
                    tags.push(<BlContentData content={loopTag.tag_name}></BlContentData>);
                });
            }
            console.log(tags);

            return tags;
        };

        const getReferPrice = (idx, lower) => {
            const price1 =
                parseFloat(convertToM(pageState['p' + (idx + 1)]?.price)) +
                parseFloat(pageState['p_priceExplain1_lower' + (idx + 1)]) +
                parseFloat(pageState['p_priceExplain2_lower' + (idx + 1)]) +
                parseFloat(pageState['p_priceExplain3_lower' + (idx + 1)]);

            const price2 =
                parseFloat(convertToM(pageState['p' + (idx + 1)]?.price)) +
                parseFloat(pageState['p_priceExplain1_upper' + (idx + 1)]) +
                parseFloat(pageState['p_priceExplain2_upper' + (idx + 1)]) +
                parseFloat(pageState['p_priceExplain3_upper' + (idx + 1)]);

            console.log(
                price1 +
                    '===>' +
                    pageState['p_priceExplain1_lower' + (idx + 1)] +
                    '====>' +
                    parseFloat(pageState['p_priceExplain2_lower' + (idx + 1)]) +
                    '====>' +
                    parseFloat(pageState['p_priceExplain3_lower' + (idx + 1)]),
            );
            console.log(price2);
            if (price1 && price2) {
                if (lower) {
                    return min([price1, price2]).toFixed(2);
                } else {
                    return max([price1, price2]).toFixed(2);
                }
            } else {
                return 0;
            }
        };

        return (
            <div className={classes.root}>
                {!pageState.similarTrans ? (
                    <TR name="NO_SIMILAR_TRANS"></TR>
                ) : (
                    <CardWithHeader header={props.mobile ? '' : 'REPORT_SUBTITLE_32'}>
                        <Tips header="Card_Tips" content="CARD32_TIPS" />
                        <div style={{ fontSize: '14px' }}>
                            <span>
                                <TR name={'Card32_chart'} />
                            </span>
                        </div>
                        <div className={classes.part2}>
                            <ReportMap
                                propCenter={{
                                    lat: props.prop?.allFeatures?.prop_y,
                                    lon: props.prop?.allFeatures?.prop_x,
                                }}
                                zoom={14}
                                drawingObject={'3'}
                                drawingData={pageState.similarTrans}></ReportMap>
                        </div>
                        <div className={classes.part3}>
                            {pageState.similarTrans.map((loopSold, idx) => {
                                return (
                                    <div className={classes.propertyCard}>
                                        {' '}
                                        <div className={classes.address}>
                                            <span
                                                onClick={() => showGoogleResultByAddress(loopSold.full_address)}
                                                style={{ cursor: 'pointer' }}>
                                                #{idx + 1}
                                                <span style={{ marginLeft: 10 }}></span>
                                                {loopSold.full_address}
                                            </span>
                                            <div className={classes.distance}>
                                                <TR name={'DISTANCE_TO_THIS_HOUSE'}></TR>
                                                {loopSold.distance.toFixed(2)}km
                                            </div>
                                        </div>
                                        {pageState['p' + (idx + 1)] ? (
                                            <div>
                                                <RecentSoldHouseInfo
                                                    onReport={true}
                                                    prop={pageState['p' + (idx + 1)]}></RecentSoldHouseInfo>
                                            </div>
                                        ) : null}
                                        {loopSold.smart_tags ? (
                                            <div className={classes.part3RowOTHER_FEATURE}>
                                                <div className={classes.part3RowContent}>
                                                    <h2>
                                                        <TR name={'OTHER_FEATURE'}></TR>
                                                    </h2>
                                                </div>
                                                <div>{showPropTag(loopSold)}</div>
                                            </div>
                                        ) : null}
                                        <div className={classes.part3RowPrice}>
                                            <div className={classes.part3RowContentPrice}>
                                                <h2>
                                                    <TR name={'PRICE_REFER_TO_THIS'}></TR>
                                                </h2>
                                                <div>
                                                    <span>${getReferPrice(idx, true)}</span>
                                                    <span>-</span>
                                                    <span>{getReferPrice(idx, false)}m</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.part3Row} style={{ alignItems: 'flex-start' }}>
                                            <div className={classes.part3RowContent} style={{ alignItems: 'center' }}>
                                                <h2>
                                                    <TR name={'MARKET_TREND'}></TR>
                                                </h2>
                                                <BlContentData
                                                    content={convertToM(
                                                        getMarketTrend(
                                                            pageState['p' + (idx + 1)],
                                                            pageState['p_ql' + (idx + 1)],
                                                        ),
                                                    )}
                                                />
                                            </div>

                                            <div className={classes.part3RowContent}>
                                                <h2>
                                                    <TR name={'DIFFERENCE_LAND'}></TR>
                                                </h2>
                                                <BlContentData content={getLandValueDiff(idx + 1)} />
                                            </div>

                                            <div className={classes.part3RowContent}>
                                                <h2>
                                                    <TR name={'DIFFERENCE_BUILDING'}></TR>
                                                </h2>
                                                <BlContentData
                                                    content={
                                                        pageState.p_estValue != null &&
                                                        pageState['p_estValue' + (idx + 1)] != null
                                                            ? convertToM(
                                                                  pageState.p_estValue?.midPrice -
                                                                      pageState['p_estValue' + (idx + 1)]?.midPrice,
                                                              )
                                                            : 0
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 5, padding: 10 }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    cursor: 'pointer',
                                                    justifyContent: 'space-between',
                                                }}
                                                onClick={() => {
                                                    pageState['p_explain_show' + (idx + 1)] = !pageState[
                                                        'p_explain_show' + (idx + 1)
                                                    ];
                                                }}>
                                                <TR name={'PRICE_MEANING'}></TR>
                                                <img
                                                    src={
                                                        !pageState['p_explain_show' + (idx + 1)]
                                                            ? 'assets/icons/arrow-lower-ico.svg'
                                                            : 'assets/icons/arrow-lupper-ico.svg'
                                                    }></img>
                                            </div>

                                            <div
                                                className={classes.priceExpDetail}
                                                style={{
                                                    display: pageState['p_explain_show' + (idx + 1)] ? 'block' : 'none',
                                                }}>
                                                <div>
                                                    <TRHtml
                                                        html={'PRICE_MEANING_1'}
                                                        params={{
                                                            MEANING_EXP_1_1: getExp1(idx, true),
                                                            MEANING_EXP_1_2: getExp1(
                                                                idx,

                                                                false,
                                                            ),
                                                        }}></TRHtml>
                                                </div>

                                                <div style={{ marginTop: 10 }}>
                                                    {appProvider.getLocale() === locales.zhCN ? (
                                                        <>
                                                            <TRHtml
                                                                html={'PRICE_MEANING_2'}
                                                                params={{
                                                                    MEANING_EXP_2_1: convertToM(
                                                                        props.prop.landValue -
                                                                            pageState['p' + (idx + 1)]?.landValue,
                                                                    ),
                                                                    MEANING_EXP_2_2: '07/2020',
                                                                    MEANING_EXP_2_3: getPriceIncreaseRange(
                                                                        idx,
                                                                        pageState['p' + (idx + 1)],

                                                                        true,
                                                                        true,
                                                                    ),
                                                                    MEANING_EXP_2_4: getPriceIncreaseRange(
                                                                        idx,
                                                                        pageState['p' + (idx + 1)],

                                                                        false,
                                                                        true,
                                                                        true,
                                                                    ),
                                                                }}></TRHtml>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <TRHtml
                                                                html={'PRICE_MEANING_2_ForEng'}
                                                                params={{
                                                                    MEANING_EXP_2_1: convertToM(
                                                                        props.prop.landValue -
                                                                            pageState['p' + (idx + 1)]?.landValue,
                                                                    ),
                                                                    MEANING_EXP_2_2: '07/2020',
                                                                    MEANING_EXP_2_3: getPriceIncreaseRange(
                                                                        idx,
                                                                        pageState['p' + (idx + 1)],

                                                                        true,
                                                                        true,
                                                                    ),
                                                                    MEANING_EXP_2_4: getPriceIncreaseRange(
                                                                        idx,
                                                                        pageState['p' + (idx + 1)],

                                                                        false,
                                                                        true,
                                                                        true,
                                                                    ),
                                                                }}></TRHtml>
                                                        </>
                                                    )}
                                                </div>
                                                <div style={{ marginTop: 10 }}>
                                                    <TRHtml
                                                        html={'PRICE_MEANING_3'}
                                                        params={{
                                                            MEANING_EXP_3_1: getBuildingValueRange(idx),
                                                        }}></TRHtml>
                                                </div>

                                                <div style={{ marginTop: 20, color: '#848383' }}>
                                                    <TR name={'PRICE_MEANING_4'}></TR>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </CardWithHeader>
                )}
            </div>
        );
    };
});

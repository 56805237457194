import lightBlue from '@material-ui/core/colors/lightBlue';
import yellow from '@material-ui/core/colors/yellow';

// Colors
export const infoColor = lightBlue[500];
export const warningColor = yellow[500];

// Spacing
export const drawerWidth = 240;
export const notificationCenterWidth = 300;
export const MobileBreakpoint = 960;

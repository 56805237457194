import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { TR } from '../../../../commons';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        zIndex: 499,
        right: '11px',
        bottom: '15px',
        background: '#f5f5f5',
        borderRadius: '4px',
        boxShadow: '0px 2px 6px 0px rgba(1,2,2,0.16)',
        padding: '10px 6px',
        opacity: 0.5,
    },
    legendCircel: {
        width: '8px',
        height: '8px',
        borderRadius: '50%',
    },
    legendLabel: {
        fontSize: '12px',
        color: '#333333',
    },
});
export const MapLegendForPropertyType = (props: any) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div style={{ background: '#2cd2eb' }} className={classes.legendCircel}></div>
            <div style={{ marginLeft: '2px' }}></div>
            <TR name="2Beds"></TR>

            <div style={{ marginLeft: '12px' }}></div>
            <div style={{ background: '#2ceb84' }} className={classes.legendCircel}></div>
            <div style={{ marginLeft: '2px' }}></div>
            <TR name="3Beds"></TR>

            <div style={{ marginLeft: '12px' }}></div>
            <div style={{ background: '#ebb82e' }} className={classes.legendCircel}></div>
            <div style={{ marginLeft: '2px' }}></div>
            <TR name="4Beds"></TR>

            <div style={{ marginLeft: '12px' }}></div>
            <div style={{ background: '#eb8f2c' }} className={classes.legendCircel}></div>
            <div style={{ marginLeft: '2px' }}></div>
            <TR name="5MoreBeds"></TR>
        </div>
    );
};

import { priceTitleAndColumns } from './price';
import { schoolTitleAndColumns } from './school';
import { residentTitleAndColumns } from './resident';
import { transportTitleAndColumns } from './transport';
import { lifeTitleAndColumns } from './life';
import { TitleAndColumns } from './common';

export const allTitleAndColumns: {
    [key: string]: TitleAndColumns;
} = {
    1: priceTitleAndColumns,
    2: schoolTitleAndColumns,
    3: residentTitleAndColumns,
    4: transportTitleAndColumns,
    5: lifeTitleAndColumns,
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { rst } from 'rt-state';
import { ROUTES } from '../../../routes';
import { RRLink } from '../../../commons';
import { TR, TRHtml } from '../../../commons';
import { AppProvider } from '../../../commons/appProvider';
import * as locales from '@material-ui/core/locale';
import { domReady } from '../supportUtils/htmlUtils';
import { MemberInfo } from '../../mobile/member/memberInfo';
import { XAuthDialog } from '../../../components/Dialog/XAuthDialog';
import { WeChatGroup } from '../../Home/components/WeChatGroup';

export const MobileFooter = rst.create<{ inDetails: boolean; addressSelected: boolean }>((ctx) => {
    const appProvider = AppProvider.use();
    const openMemberInfo = rst.stateS(false);
    const handleOpenBlogs = () => {
        const newPage = window.open('about:blank');
        newPage.location.href =
            appProvider.getLocale() === locales.zhCN
                ? 'https://www.cnblog.homeon.com.au/'
                : 'https://blog.homeon.com.au/';
    };
    const handleOpenGlossary = () => {
        window.open(
            appProvider.getLocale() === locales.zhCN
                ? 'https://www.cnblog.homeon.com.au/blog/8n7c5ly7e7hx2kc8zfcw7tzr69gmfz'
                : `${process.env.PUBLIC_URL}/assets/help/HELP-EN.html`,
        );
    };
    const openShop = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://store.homeon.com.au/collections/all';
    };
    const handleOpenLive = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://www.youtube.com/channel/UCPbp_YpynVEVpJrr_UzMqYg';
    };
    const handleOpenXiaohongshu = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://www.xiaohongshu.com/user/profile/5f84ba2900000000010034c8';
    };
    const handleOpenTwitter = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://twitter.com/SydneyHomeOn';
    };
    const handleOpenFacebook = () => {
        const newPage = window.open('about:blank');
        newPage.location.href = 'https://www.facebook.com/homeon.com.au';
    };
    const isOpen = rst.stateS<'shop' | 'wechat' | 'live' | 'Feedback'>(null);
    function handleForumClose(choice: 'shop' | 'wechat' | 'live' | 'Feedback') {
        switch (choice) {
            case 'shop':
            case 'wechat':
                isOpen.value = choice;
                break;
            case 'Feedback':
                isOpen.value = choice;
                break;
            case 'live':
        }
    }
    function onDialogClose() {
        isOpen.value = null;
    }
    return (props) => {
        domReady('mobileFooter').then(() => {
            setTimeout(() => {
                const mobileFooterEle = document.getElementById('mobileFooter');
                const windowHeight = window.screen.height;
                const footerTop = mobileFooterEle.offsetTop;
                const footerHeight = mobileFooterEle.offsetHeight;
                const footerDec = document.getElementById('footerDec');
                if (footerTop < windowHeight) {
                    footerDec.style.height = windowHeight - footerHeight - footerTop + 'px';
                } else {
                    footerDec.style.height = '30px';
                }
            }, 1000);

            //alert('window h=>' + windowHeight + ';foot top==>' + footerTop + ';footer Hei=>' + footerHeight);
        });

        return (
            <div id="mobileFooter" style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                <div style={{ background: '#21211F', color: '#fff', padding: '1rem 10px' }}>
                    <div style={{ display: 'flex' }}>
                        <p style={{ paddingRight: '10px' }}>
                            <span onClick={handleOpenBlogs}>
                                <TR name={'NewHome_originalArticle'} />
                            </span>
                        </p>
                        <p style={{ paddingRight: '10px' }}>
                            {/* <Link target="_blank" component={RRLink} to={ROUTES.JoinMember.path} color="initial"> */}

                            <span
                                onClick={() => {
                                    openMemberInfo.value = true;
                                    openMemberInfo.forceUpdate();
                                }}>
                                <TR name={'NewHome_memberPricing'} />
                            </span>
                            {/* </Link> */}
                        </p>
                        <p style={{ paddingRight: '10px' }}>
                            <span onClick={handleOpenGlossary}>
                                <TR name={'Glossary'} />
                            </span>
                        </p>
                        {/* <p style={{ paddingRight: '10px' }}>
                            <span onClick={openShop}>
                                <TR name={'ONLINE_SHOP'} />
                            </span>
                        </p> */}
                        <p style={{ paddingRight: '10px' }}>
                            <a href="mailto:support@homeon.com.au">
                                <TR name={'Contact Us'} />
                            </a>
                        </p>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}>
                        <div style={{ marginRight: '20px' }} onClick={handleOpenTwitter}>
                            <img src={`${process.env.PUBLIC_URL}/static/images/Twitter.svg`} alt="Twitter" />
                        </div>
                        <div style={{ marginRight: '20px' }} onClick={handleOpenFacebook}>
                            <img src={`${process.env.PUBLIC_URL}/static/images/facebook.svg`} alt="facebook" />
                        </div>
                        {appProvider.getLocale() === locales.zhCN ? (
                            <div style={{ marginRight: '20px' }} onClick={() => handleForumClose('wechat')}>
                                <img src={`${process.env.PUBLIC_URL}/static/images/wechat.svg`} alt="Wechat" />
                            </div>
                        ) : null}
                        {appProvider.getLocale() === locales.zhCN ? (
                            <div style={{ marginRight: '20px' }} onClick={handleOpenLive}>
                                <img src={`${process.env.PUBLIC_URL}/static/images/youtube.svg`} alt="youTube" />
                            </div>
                        ) : null}
                        {/* {appProvider.getLocale() === locales.zhCN ? (
                            <div style={{ marginRight: '20px' }} onClick={handleOpenXiaohongshu}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/static/images/xiaohongshu.png`}
                                    alt="Xiaohongshu"
                                    style={{ width: 20, height: 20 }}
                                />
                            </div>
                        ) : null} */}
                    </div>
                    <div>
                        <p>
                            <TRHtml html={'copyright'} />
                        </p>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                        }}>
                        <p style={{ paddingRight: '1rem' }}>
                            <Link target="_blank" component={RRLink} to={ROUTES.AboutUsPage.path} color="initial">
                                <span>
                                    <TR name={'Privacy Policy'}></TR>
                                </span>
                            </Link>
                        </p>
                        <p>
                            <Link target="_blank" component={RRLink} to={ROUTES.TermOfUsePage.path} color="initial">
                                <span>
                                    <TR name={'Terms and Conditions'}></TR>
                                </span>
                            </Link>
                        </p>
                    </div>
                </div>
                <div
                    id="footerDec"
                    style={{ height: 30, minHeight: 30, width: '100%', background: 'rgb(241, 241, 239)' }}></div>
                <MemberInfo
                    show={openMemberInfo.value}
                    popType={0}
                    onClose={() => {
                        openMemberInfo.value = false;
                        openMemberInfo.forceUpdate();
                    }}></MemberInfo>
                {rst.view(() => {
                    return (
                        <XAuthDialog open={isOpen.value === 'wechat'} onClose={onDialogClose}>
                            <WeChatGroup />
                        </XAuthDialog>
                    );
                })}
            </div>
        );
    };
});

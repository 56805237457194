import { makeStyles, Tooltip, withStyles } from '@material-ui/core';
import ReactDOM from 'react-dom';
import React from 'react';
import { rst, state } from 'rt-state';
import { generateUUID } from '../../../../../commons/utils';
import { TR, TRHtml } from '../../../../../commons';
import { Chip } from '../../../../../components/Chip';
import { useTranslation } from 'react-i18next';
export const SchoolOrStationScroll = rst.create<{
    items: any;
    image?: any;
    onMap?: boolean;
    maxWidth?: number;
    onReport?;
}>((ctx) => {
    const state = rst.state({
        currentItem: 0,
    });

    return (props) => {
        const componentId = generateUUID();

        setTimeout(() => {
            console.log(componentId);
            const switchItemEle = document.getElementById(componentId + '_switchItem');
            if (switchItemEle) {
                switchItemEle.addEventListener('click', changeToNextRecord);
            }
        });

        const changeToNextRecord = () => {
            if (state.currentItem == ctx.props.items.length - 1) {
                state.currentItem = 0;
            } else {
                state.currentItem = state.currentItem + 1;
            }
            const scrollContentDiv = document.getElementById(componentId + '_scrollContent');
            if (scrollContentDiv) {
                if (ctx.props.items && ctx.props.items.length > 0) {
                    ReactDOM.render(
                        <SchoolOrStationItem
                            name={props.items[state.currentItem].name}
                            params={props.items[state.currentItem].params}
                            maxWidth={props.maxWidth}
                            chips={props.items[state.currentItem].chips}></SchoolOrStationItem>,
                        scrollContentDiv,
                    );
                }
            }

            // if not on map , it is on sidebar, then need to remove the current click listener ,otherwise when click down arrow , it will keep firing event
            if (!props.onMap) {
                const switchItemEle = document.getElementById(componentId + '_switchItem');
                if (switchItemEle) {
                    switchItemEle.removeEventListener('click', changeToNextRecord);
                }
            }
        };
        console.log('props.items[state.currentItem]', props.items[state.currentItem]);
        return (
            <>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div id={componentId + '_scrollContent'}>
                        <SchoolOrStationItem
                            name={props.items[state.currentItem]?.name}
                            params={props.items[state.currentItem]?.params}
                            maxWidth={props.maxWidth}
                            chips={props.items[state.currentItem]?.chips}
                            image={props.image}></SchoolOrStationItem>
                    </div>
                    {props.items.length > 1 ? (
                        <div>
                            <img
                                style={{ cursor: 'pointer', marginTop: 5, marginLeft: 15 }}
                                src="assets/icons/arrow-lower-ico.svg"
                                width="16px"
                                height="12px"
                                id={componentId + '_switchItem'}></img>
                        </div>
                    ) : null}
                </div>
                <div style={{ marginLeft: 10 }}></div>
            </>
        );
    };
});

const SchoolOrStationItem = rst.create<{
    name: string;
    chips: any;
    image?: any;
    params?: any;
    maxWidth?: number;
}>((ctx) => {
    return (props) => {
        const combineChipContent = (originContent) => {};
        return (
            <div style={{ fontSize: 10 }}>
                {props.maxWidth ? (
                    <ScrollItemTooltip title={props.name} arrow>
                        <div style={{ maxWidth: props.maxWidth || 999, whiteSpace: 'nowrap', cursor: 'pointer' }}>
                            <TRHtml
                                html={props.maxWidth ? props.name?.substr(0, 20) : props.name}
                                params={props.params}></TRHtml>
                            <span style={{ fontWeight: 900 }}>{props.name?.trim().length > 20 ? '...' : ''}</span>
                        </div>
                    </ScrollItemTooltip>
                ) : (
                    <TRHtml html={props.name} params={props.params}></TRHtml>
                )}

                <div style={{ display: 'flex', marginTop: 8 }}>
                    {props.chips
                        ? props.chips.map((loopChip, idx) => {
                              return (
                                  <>
                                      <Chip label={loopChip} image={props.image} />

                                      <div style={{ marginLeft: 5 }}></div>
                                  </>
                              );
                          })
                        : null}
                </div>
            </div>
        );
    };
});

const ScrollItemTooltip = withStyles({
    tooltip: {
        color: '#495b6b',
        backgroundColor: 'white',
        fontSize: '12px',
    },
})(Tooltip);

export enum UserCenterEntityType {
    Unknown,
    Property,
    Suburb,
    PropertyReportAddtion,
    ///////////////////////
    SECRET_PART = 999,
    PROPERTY_REPORT = 1000,
    OWNER_PROPERTIES = 1001,
    Street,
}

export function isValidEntityType(entityType: number): boolean {
    return entityType < UserCenterEntityType.SECRET_PART;
}

import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { queryAdjacent_byGraphQL } from '../../../../commons/graphqlClient/graphAPI';
import { HomeOnSelect } from '../../../../components/Select';

const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
});
const intervalOptions = [
    {
        label: 'Recent_3_months',
        value: 3,
    },
    {
        label: 'Recent_6_months',
        value: 6,
    },
    {
        label: 'Recent_12_months',
        value: 12,
    },
];
const distanceOptions = [
    {
        label: '500meters',
        value: 500,
    },
    {
        label: '1000meters',
        value: 1000,
    },
    {
        label: 'localSuburb',
        value: 0,
    },
];
const propertyTypeOptions = [
    {
        label: '2Beds',
        value: [2],
    },
    {
        label: '3Beds',
        value: [3],
    },
    {
        label: '4Beds',
        value: [4],
    },
    {
        label: '5MoreBeds',
        value: [5, 6, 7, 8, 9, 10],
    },
    {
        label: 'AllBeds',
        value: 0,
    },
];

export const PriceMapCondition = (props: any) => {
    const { changeConditionEvt, conditions } = props;

    const classes = useStyles();

    const changeCondition = (type, evt) => {
        changeConditionEvt(type, evt.target.value);
    };
    return (
        <div className={classes.root}>
            {' '}
            <HomeOnSelect
                list={intervalOptions}
                defaultValue={6}
                changeEvt={changeCondition}
                type="interval"></HomeOnSelect>
            {props.comparableAll ? null : (
                <HomeOnSelect
                    defaultValue={500}
                    list={distanceOptions}
                    changeEvt={changeCondition}
                    type="distance"></HomeOnSelect>
            )}
            <HomeOnSelect
                defaultValue={0}
                list={propertyTypeOptions}
                changeEvt={changeCondition}
                type="bedrooms"></HomeOnSelect>
        </div>
    );
};

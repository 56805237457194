import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1140px',
        padding: '20px',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
        },
    },
    content: {
        textAlign: 'left',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& h1': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
            },
        },
    },
}));

import { rst } from 'rt-state';
import { TR } from '../../../commons/i18n';
import React from 'react';
import { Link, makeStyles, Typography } from '@material-ui/core';
import { XForm, XRadioGroup } from '../../../components/form';
import { residentFilters, schoolFilters, transportFilters } from '../common';
import { SearchTabContent } from './SearchTabContent';

const useStyles = makeStyles((theme) => {
    return {
        radioLabel: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
    };
});

export const SimpleSuburbSearch = rst.create<{
    switchToComplex: () => void;
    form: XForm;
}>((ctx) => {
    return (props) => {
        const { form } = props;
        const classes = useStyles(props);

        const simpleSearchOptions = [];
        simpleSearchOptions.push({ fieldName: 'chinesePercentage', ...residentFilters[1] });
        simpleSearchOptions.push({ fieldName: 'primarySchoolRate', ...schoolFilters[0] });
        simpleSearchOptions.push({ fieldName: 'trainRate', ...transportFilters[0] });

        return (
            <>
                <SearchTabContent form={form} listOptions={simpleSearchOptions}></SearchTabContent>
                <Typography>
                    <Link onClick={props.switchToComplex}>更多维度筛选</Link>
                </Typography>
            </>
        );
    };
});

import ReactDOM from 'react-dom/server';
import classes from '*.module.css';
import { makeStyles } from '@material-ui/core';

import React from 'react';
import { rst } from 'rt-state';
import { generateGUID, TR } from '../../../../commons';
import { getCurrentYear } from '../../../../commons/utils';
import { PropertyIcon, p_icon } from '../../../PriceMap/MapContainer/PropertyIcon';
import { AroundRiskMap } from './AroundRiskMap';
import { AroundSoldMap } from './AroundSoldMap';
import { AroundPrivateSeletive } from './AroundPrivateSelective';
import { SchoolCatchmentMap } from './SchoolCatchmentMap';
import { BusinessPark } from './BusinessPark';
import { SuburbSold } from './SuburbSold';
import { MobileAroundpricemap } from './MobileAroundpricemap';

const win: any = window;
const L = win.L;

const useStyles = makeStyles((theme) => {
    return {
        root: {
            width: '100%',
            height: '300px',
        },
    };
});

/**
 *  drawingObject : what object will be draw on map
 *              // 1. 小学学区划片
 *              // 2. 周边风险提示
 *              // 3. 周边成交
 *              // 4. 周边精英/私立
 *              // 6. 近期成交
 *              // 7. 周围成交
 *              // 8.
 *              // 9. 新版手机上周边成交
 *              //10. 谷歌搜索结果图
 *
 *  drawingData :the drawing data which will be draw on map,
 */

export const ReportMap = rst.create<{
    propCenter: { lat: any; lon: any };
    zoom?: number;
    drawingObject?: string;
    drawingData?: any;
    notDrawCenter?: boolean;
    addtionData?: any;
    draggable?: boolean;
    version?: string;
}>((ctx) => {
    var mobileAroundSoldMap = null;

    var componentMapInstance = null;
    const componentID = generateGUID();

    const noLableLayer = L.tileLayer(
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png',
        {
            attribution: false,
        },
    );

    const initMap = () => {
        console.log('start init suburb map');
        try {
            componentMapInstance = L.map('reportMap' + componentID, {
                zoomControl: false,
                doubleClickZoom: false,
                scrollWheelZoom: false,
                attribution: false,
                dragging: ctx.props.draggable,
            }).setView([ctx.props.propCenter.lat, ctx.props.propCenter.lon], ctx.props.zoom || 14);

            if (ctx.props.drawingObject !== '9') {
                const iconHTML = ReactDOM.renderToString(
                    React.createElement(p_icon, {
                        reportMapCenter: true,
                    }),
                );

                const houseMarkerIcon = new L.DivIcon({
                    className: '',
                    html: iconHTML,
                });
                if (!ctx.props.notDrawCenter) {
                    const houseMarker = L.marker([ctx.props.propCenter.lat, ctx.props.propCenter.lon]).addTo(
                        componentMapInstance,
                    );
                    houseMarker.setIcon(houseMarkerIcon);
                }
            } else {
                const houseMarkerIcon = new L.DivIcon({
                    className: '',
                    html:
                        '<div style="margin-left:-10px;margin-top:-10px"><img src="assets/mobileIcons/houseSelf.svg" /></div>',
                });
                if (!ctx.props.notDrawCenter) {
                    const houseMarker = L.marker([ctx.props.propCenter.lat, ctx.props.propCenter.lon]).addTo(
                        componentMapInstance,
                    );
                    houseMarker.setIcon(houseMarkerIcon);
                }
            }

            componentMapInstance.addLayer(noLableLayer);
            //L.control.scale({ maxWidth: 100 }).addTo(componentMapInstance);
        } catch (e) {}
    };

    setTimeout(() => {
        initMap();
        const copyrightElement = document.getElementsByClassName('leaflet-control-attribution')[0] as any;
        if (copyrightElement) {
            copyrightElement.style.display = 'none';
        }
    }, 500);

    const clearMap = () => {
        for (const i in componentMapInstance._layers) {
            if (componentMapInstance._layers[i]._path != undefined) {
                try {
                    componentMapInstance.removeLayer(componentMapInstance._layers[i]);
                } catch (e) {
                    console.log('problem with ' + e + componentMapInstance._layers[i]);
                }
            }
        }
    };
    return (props) => {
        const classes = useStyles();
        setTimeout(() => {
            try {
                if (ctx.props.drawingObject == '1') {
                    const schoolCatchmentMap: SchoolCatchmentMap = new SchoolCatchmentMap(componentMapInstance);
                    schoolCatchmentMap.drawCatchment(ctx.props.drawingData);
                }
                if (ctx.props.drawingObject == '2') {
                    console.log(ctx.props);
                    const aroudRiskMap: AroundRiskMap = new AroundRiskMap(componentMapInstance);
                    aroudRiskMap.drawAround(ctx.props.propCenter, ctx.props.drawingData);
                }
                if (ctx.props.drawingObject == '3') {
                    const aroundSoldMap: AroundSoldMap = new AroundSoldMap(componentMapInstance);
                    aroundSoldMap.drawAround(ctx.props.propCenter, ctx.props.drawingData);
                }
                if (ctx.props.drawingObject == '4') {
                    const aroundPrivateSelective: AroundPrivateSeletive = new AroundPrivateSeletive(
                        componentMapInstance,
                    );
                    aroundPrivateSelective.drawAround(ctx.props.propCenter, ctx.props.drawingData);
                    aroundPrivateSelective.drawSchoolMarkers(ctx.props.drawingData);
                }
                if (ctx.props.drawingObject == '5') {
                    console.log(ctx.props.drawingData);
                    const businessPark: BusinessPark = new BusinessPark(componentMapInstance);
                    businessPark.drawBusinessParks(ctx.props.propCenter, ctx.props.drawingData);
                }
                if (ctx.props.drawingObject == '6') {
                    console.log(ctx.props.drawingData);
                    const suburbSold: SuburbSold = new SuburbSold(componentMapInstance);

                    suburbSold.drawBorder(ctx.props.drawingData.border);
                    suburbSold.drawRecentSold(ctx.props.drawingData.soldData, ctx.props.drawingData.propType);
                }

                if (ctx.props.drawingObject == '7') {
                    console.log(props.addtionData);
                    clearMap();
                    console.log(ctx.props.drawingData);
                    const suburbSold: SuburbSold = new SuburbSold(componentMapInstance);

                    suburbSold.drawBorder(ctx.props.drawingData.border);
                    suburbSold.drawPoints(ctx.props.addtionData);
                    suburbSold.drawSuburbName(ctx.props.drawingData.suburbName, props.propCenter);
                }

                if (ctx.props.drawingObject == '8') {
                    clearMap();
                    const aroundSoldMap: AroundSoldMap = new AroundSoldMap(componentMapInstance);
                    //aroundSoldMap.drawAround(ctx.props.propCenter, ctx.props.drawingData);
                    aroundSoldMap.addAroundTransMarkers(
                        ctx.props.propCenter,
                        ctx.props.drawingData,
                        ctx.props.addtionData.propType,
                    );
                }
                if (ctx.props.drawingObject == '9') {
                    console.log(ctx.props.drawingData.solds);
                    clearMap();
                    if (mobileAroundSoldMap == null) {
                        mobileAroundSoldMap = new MobileAroundpricemap(componentMapInstance, '');
                    }
                    if (ctx.props.drawingData.distance == 500) {
                        componentMapInstance.setZoom(15);
                    }
                    if (ctx.props.drawingData.distance == 1000) {
                        componentMapInstance.setZoom(14);
                    }
                    if (ctx.props.drawingData.distance > 0) {
                        componentMapInstance.setView([ctx.props.propCenter.lat, ctx.props.propCenter.lon]);
                        mobileAroundSoldMap.drawCircle(ctx.props.drawingData.distance, ctx.props.propCenter);
                    } else {
                        mobileAroundSoldMap.drawSuburbBorder(ctx.props.drawingData.suburbBorder);
                    }
                    mobileAroundSoldMap.drawSolds(ctx.props.drawingData.solds, ctx.props.drawingData.showPrice);
                }
            } catch (e) {}
        }, 500);

        return <div className={classes.root} id={'reportMap' + componentID}></div>;
    };
});

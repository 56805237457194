import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '350px',
        [theme.breakpoints.down('sm')]: {
            width: '280px',
        },
        margin: '20px auto',
        lineHeight: '25px',
        backgroundColor: 'rgb(255, 255, 255)',
        textAlign: 'center',
        borderRadius: '1.3rem',
        boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)',
        '& h2': {
            padding: '3rem 0 0 0',
            margin: '0 auto',
            fontSize: '2rem',
            textAlign: 'center',
            fontWeight: '100',
            [theme.breakpoints.down('sm')]: {
                padding: '1rem 0 0 0',
                fontSize: '1rem',
            },
        },
    },
    form: {
        margin: '0 auto',
        padding: '2rem',
        [theme.breakpoints.down('sm')]: {
            padding: '1rem',
            fontSize: '12px',
            width: '250px',
        },
        // '& input': {
        //     margin: '2rem',
        //     border: '1px rgba(102, 175, 233, 0.6)',
        //     fontSize: '1rem',
        //     height: '4rem',
        //     width: '35rem',
        //     paddingLeft: '1rem',
        //     borderRadius: '1.3rem',
        //     boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)',
        //     outline: 'none',
        // },
        // '& textarea': {
        //     paddingLeft: '1rem',
        //     paddingTop: '1rem',
        //     margin: '2rem',
        //     outline: 'none',
        //     border: '1px rgba(102, 175, 233, 0.6)',
        //     fontSize: '1rem',
        //     fontFamily: 'Arial, Helvetica, sans-serif',
        //     borderRadius: '1.3rem',
        //     boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)',
        //     width: '35rem',
        //     height: '10rem',
        // },
        '& button': {
            margin: '1.5rem 0 1rem 0',
            backgroundColor: 'rgba(102, 175, 233, 1)',
        },
    },
}));

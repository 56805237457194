import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { rst, state } from 'rt-state';
import { clsx, SecurityProvider } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { Permission } from '../../../../commons/security/authz';
import { getParamValueFromURL, isMobileDevice } from '../../../../commons/utils';
import { ROUTES } from '../../../../routes';
import { getSuburbByPoint } from '../../../../services/suburb';
import { getFavourites } from '../../../../services/userCenter';
import { HelpComponentAuto } from '../../components/HelpComponentAuto';

import { MapControlButtonBase } from '../../components/MapControlButtonBase';

import { getPriceMapLevelByZoom } from '../../support/common';
import { MapGlobalVars, MapType, PRICE_MAP_STATUS } from '../../support/PriceMapConsts';
import { SuburbSearchAutoComplete } from '../SuburbSearchInput';
import { MapZoomController } from './MapZoomController';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'absolute',

            right: isMobileDevice() ? '10px' : '24px',
            zIndex: 1299,
            background: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },
        mobile: {
            top: '50px',
        },
        pc: {
            top: 100,
        },
        switchIcon: {
            position: 'absolute',
            top: isMobileDevice() ? 30 : 140,
            right: -20,
            zIndex: 1299,
            height: 600,
            transition: 'all 0.5s ease-out',
            // animation: 'slide 0.5s forwards',
            '&:hover': {
                transition: 'all 0.5s ease-out',
                right: 0,
            },
        },
        switchIconDiv: {
            cursor: 'pointer',
        },
    };
});

export const MapControlLayer = rst.create<{ fromIndex?: boolean }>((ctx) => {
    const securityProvider = SecurityProvider.use();
    const requestURL = window.location.search;
    console.log(requestURL);

    const loadSpecifiedProperty = (propId) => {
        EventBus.dispatch(EVENT_NAMES.EVENT_FOLD_FILTER_PANEL, {});
        if (!MapGlobalVars.wholeMapDataLoaded) {
            console.log('map data loaded?' + MapGlobalVars.wholeMapDataLoaded);
            setTimeout(() => {
                loadSpecifiedProperty(propId);
            }, 200);
        } else {
            processAfterChooseProperty(propId);
        }
    };

    // when internet speed is very slow, the show property before whole map data loaded, it will cause problem
    // so need to monitor the whole map data loaded , then load the specified property.
    // the way I solve it to set a global var to monitor the map data loaded , if not then wait 200ms and check again.
    if (requestURL.indexOf('propId') > -1) {
        const propId = getParamValueFromURL(requestURL, 'propId');
        EventBus.dispatch(EVENT_NAMES.EVENT_FOLD_FILTER_PANEL, {});
        loadSpecifiedProperty(propId);
    }
    EventBus.on(EVENT_NAMES.EVENT_AVOID_OVERLAY, (evt) => {
        pageState.showIcons = evt.showIcon;
    });

    EventBus.on(EVENT_NAMES.EVENT_FOLD_SEARCH_INPUT, () => {
        pageState.showSearch = false;
    });
    EventBus.on(EVENT_NAMES.EVENT_MAP_ZOOMED, (zoom) => {
        pageState.mapStatus = getPriceMapLevelByZoom(zoom);
        console.log(pageState.mapStatus);
        if (pageState.mapStatus != PRICE_MAP_STATUS.SUBURBLEVEL) {
            pageState.showMapTypeToggle = true;
        } else {
            pageState.showMapTypeToggle = false;
        }
    });

    EventBus.on(EVENT_NAMES.EVENT_SET_FAV_BUTTON_STATUS, (status) => {
        pageState.showFav = status;
    });
    const pageState = rst.state({
        showSearch: false,
        showMapTypeToggle: false,
        currentMapType: MapGlobalVars.currentMapType,
        mapStatus: '',
        showFav: false,
        showIcons: true,
        isHelpOpen: false,
        helpContent: null,
    });
    function zoomMap(inOut) {
        EventBus.dispatch(EVENT_NAMES.EVENT_ZOOM_MAP, { zoomDirect: inOut });
    }
    function togglePriceLabel() {
        EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_PRICE_LABEL, {});
    }
    function searchSuburbEvt(suburbId, suburbName) {
        if (suburbId) {
            window.location.href = `${ROUTES.PriceMap.path}?suburbId=${suburbId}&suburbName=${suburbName}`;
        }
    }
    // control if the search bar showing in pricemap
    function foldSearch() {
        pageState.showSearch = !pageState.showSearch;
        // pageState.showSearch = false;
        MapGlobalVars.suburbSearchInputShowing = pageState.showSearch;
        console.log(pageState.showSearch);

        if (!isMobileDevice()) {
            if (pageState.showSearch) {
                EventBus.dispatch(EVENT_NAMES.EVENT_FOLD_FILTER_PANEL, {});
            }
        }
    }

    function helpOpenHandler(isOpen) {
        pageState.isHelpOpen = isOpen;
        console.log(pageState.isHelpOpen);
    }
    function helpOpenHandlerCloseDelay() {
        setTimeout(() => {
            pageState.isHelpOpen = false;
            console.log('5s');
        }, 5000);
    }
    if (requestURL.indexOf('priceMapShowCho') > -1) {
        helpOpenHandlerCloseDelay();
        pageState.isHelpOpen = true;
        pageState.helpContent = 'choTip';
    }
    if (requestURL.indexOf('priceMapShowEst') > -1) {
        pageState.showSearch = !pageState.showSearch;
        // pageState.showSearch = false;
        MapGlobalVars.suburbSearchInputShowing = pageState.showSearch;
        console.log(pageState.showSearch);
        if (!isMobileDevice()) {
            if (pageState.showSearch) {
                EventBus.dispatch(EVENT_NAMES.EVENT_FOLD_FILTER_PANEL, {});
            }
        }
        helpOpenHandlerCloseDelay();
        pageState.isHelpOpen = true;
        pageState.helpContent = 'estTip';
    }
    if (requestURL.indexOf('priceMapShowSrch') > -1) {
        helpOpenHandlerCloseDelay();
        pageState.isHelpOpen = true;
        pageState.helpContent = 'srchTip';
    }
    if (requestURL.indexOf('priceMapShowAnls') > -1) {
        pageState.showSearch = !pageState.showSearch;
        // pageState.showSearch = false;
        MapGlobalVars.suburbSearchInputShowing = pageState.showSearch;
        console.log(pageState.showSearch);
        if (!isMobileDevice()) {
            if (pageState.showSearch) {
                EventBus.dispatch(EVENT_NAMES.EVENT_FOLD_FILTER_PANEL, {});
            }
        }
        helpOpenHandlerCloseDelay();
        pageState.isHelpOpen = true;
        pageState.helpContent = 'anlsTip';
    }
    function toggleRealWays() {
        EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_RAILMAP_LAYER, {});
    }
    function toggleMapType() {
        if (pageState.currentMapType == MapType.PLAIN) {
            pageState.currentMapType = MapType.STA;
        } else {
            pageState.currentMapType = MapType.PLAIN;
        }
        MapGlobalVars.currentMapType = pageState.currentMapType;
        EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_MAP_TYPE, {});
    }
    function processAfterChooseProperty(id) {
        EventBus.dispatch(EVENT_NAMES.EVENT_SEARCHED_PROP, { propId: id });
        pageState.showSearch = false;
    }
    return (props) => {
        const zoomToFullMap = () => {
            EventBus.dispatch(EVENT_NAMES.EVENT_FULL_MAP_FROM_INDEX, {});
        };
        const suburbSearchInputStyles = {
            background: 'white',
            padding: !isMobileDevice() ? '10px 17px 10px 17px' : '10px',
            borderRadius: '8px 8px 8px 8px',

            inputStyles: {
                background: '#f7f7f7',
                height: isMobileDevice() ? 30 : 40,
                fontSize: isMobileDevice() ? 12 : 14,
                placholder: 'Search',
                borderRadius: 19,
                paddingLeft: 14,
                paddingRight: 14,
            },
        };

        const toggleFav = async () => {
            try {
                const myFavs = await getFavourites();

                if (!myFavs || myFavs.length == 0) {
                    EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                        show: true,
                        title: 'ERROR',
                        content: 'NO_ANY_FAV',
                    });
                } else {
                    // if showing around, should cancel it , then do rest
                    if (MapGlobalVars.showAroundTransCircle) {
                        EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MAP_CIRCLE, {
                            showAroundTrans: false,
                        });
                        MapGlobalVars.showAroundTransCircle = false;
                    }

                    pageState.showFav = !pageState.showFav;

                    MapGlobalVars.showFavOnMap = pageState.showFav;
                    EventBus.dispatch(EVENT_NAMES.EVENT_TOGGLE_FAV_ON_MAP, { allFavs: myFavs });
                }
            } catch (e) {
                alert(e);
            }
        };

        const getSearchButton = () => {
            if (pageState.showSearch) {
                return (
                    <>
                        <SuburbSearchAutoComplete
                            cssStyle={suburbSearchInputStyles}
                            searchAddress={true}
                            caller="priceMap"
                            showCancel={true}
                            foldEvent={searchSuburbEvt}
                            propertySelectedEvent={processAfterChooseProperty}
                            searchSuburb={true}></SuburbSearchAutoComplete>
                        <div style={{ marginLeft: isMobileDevice() ? 8 : 17 }}></div>
                        <MapControlButtonBase
                            clickEvent={() => {
                                foldSearch();
                            }}
                            arrowPosition="LEFT"
                            icon="search"></MapControlButtonBase>
                    </>
                );
            } else {
                return (
                    <MapControlButtonBase
                        tooltip={
                            MapGlobalVars.currentUserLan == 'zh' ? '搜索房屋或者Suburb' : 'Search property or suburb'
                        }
                        clickEvent={() => {
                            foldSearch();
                        }}
                        icon="search"></MapControlButtonBase>
                );
            }
        };
        const classes = useStyles();

        const switchMapControllers = () => {
            pageState.showIcons = !pageState.showIcons;

            EventBus.dispatch(EVENT_NAMES.EVENT_SWITCH_MAP_ICONS, pageState.showIcons);
        };

        const handleMySuburb = () => {
            if (navigator.geolocation) {
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_LOADING_INDICATOR, {
                    show: true,
                    message: 'LOCATING_YOUR_LOCATION',
                });
                console.log(navigator.geolocation);

                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        console.log(position);
                        const userSuburb: any = await getSuburbByPoint({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                        console.log(userSuburb);
                        if (!userSuburb || !userSuburb.id) {
                            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                                show: true,
                                title: 'ERROR',
                                content: 'FAILED_GET_USER_POSITION',
                            });

                            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_LOADING_INDICATOR, {
                                show: false,
                            });
                        } else {
                            if (!props.fromIndex && isMobileDevice()) {
                                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_SUBURB_TRANS, {
                                    suburbId: userSuburb.id,
                                    suburbName: userSuburb.locality_name,
                                });

                                EventBus.dispatch(EVENT_NAMES.EVENT_DRAW_MY_LOCATION, {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude,
                                });
                            } else {
                                window.location.href = `${ROUTES.PriceMap.path}?suburbId=${userSuburb.id}&suburbName=${userSuburb.locality_name}&lat=${position.coords.latitude}&lng=${position.coords.longitude}`;
                            }

                            EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_LOADING_INDICATOR, {
                                show: false,
                            });
                        }
                    },
                    (err) => {
                        EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_LOADING_INDICATOR, {
                            show: false,
                        });

                        EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                            show: true,
                            title: 'ERROR',
                            content: 'FAILED_GET_USER_POSITION',
                        });
                    },
                );
            } else {
                EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_GENERAL_ERROR, {
                    show: true,
                    title: 'ERROR',
                    content: 'FAILED_GET_USER_POSITION',
                });
            }
        };

        const presentContent = () => {
            return (
                <>
                    {/* {requestURL.indexOf('priceMapShowCho') > -1 || requestURL.indexOf('priceMapShowSrch') > -1 ? ( */}
                    <HelpComponentAuto
                        isHelpOpen={pageState.isHelpOpen}
                        helpContent={pageState.helpContent}
                        helpOpenHandler={helpOpenHandler}
                        maxWidth="xs"
                    />
                    {/* ) : null} */}
                    {isMobileDevice() ? null : (
                        <div className={classes.switchIcon}>
                            <div style={{ marginTop: 10 }}></div>
                            {pageState.showSearch || props.fromIndex ? null : (
                                <div className={classes.switchIconDiv} onClick={switchMapControllers}>
                                    <img
                                        src={
                                            'assets/icons/' +
                                            (pageState.showIcons ? 'arrow-right-ico.svg' : 'arrow-rleft-ico.svg')
                                        }></img>
                                </div>
                            )}
                        </div>
                    )}

                    <div
                        style={{ top: props.fromIndex ? 10 : isMobileDevice() ? 80 : 120 }}
                        className={clsx(isMobileDevice() ? classes.mobile : classes.pc, classes.root)}>
                        <div style={{ marginTop: props.fromIndex ? 10 : isMobileDevice() ? 30 : 100 }}></div>
                        <div style={{ display: pageState.showIcons ? 'block' : 'none' }}>
                            {props.fromIndex ? (
                                <div className="animate__infinite animate__animated animate__heartBeat">
                                    <MapControlButtonBase
                                        icon="fullScreen"
                                        clickEvent={zoomToFullMap}></MapControlButtonBase>
                                </div>
                            ) : (
                                <div style={{ display: 'flex' }}>{getSearchButton()}</div>
                            )}

                            {isMobileDevice() ? (
                                <>
                                    <div
                                        style={{
                                            marginTop: 10,

                                            display:
                                                pageState.mapStatus != PRICE_MAP_STATUS.ALLPROPERTYLEVEL &&
                                                !pageState.showSearch
                                                    ? 'block'
                                                    : 'none',
                                        }}>
                                        <MapControlButtonBase
                                            icon="dollar"
                                            width={14}
                                            clickEvent={togglePriceLabel}></MapControlButtonBase>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{ marginTop: 10 }}></div>

                                    <div
                                        style={{
                                            marginTop: 10,

                                            display:
                                                pageState.mapStatus != PRICE_MAP_STATUS.ALLPROPERTYLEVEL &&
                                                !pageState.showSearch
                                                    ? 'block'
                                                    : 'none',
                                        }}>
                                        <MapControlButtonBase
                                            icon="dollar"
                                            tooltip={
                                                props.fromIndex
                                                    ? ''
                                                    : MapGlobalVars.currentUserLan == 'zh'
                                                    ? '显示价格标签'
                                                    : 'Show prices on the map'
                                            }
                                            clickEvent={togglePriceLabel}></MapControlButtonBase>
                                    </div>
                                </>
                            )}
                            <div style={{ marginTop: 10 }}></div>
                            {props.fromIndex || pageState.showSearch ? null : (
                                <MapControlButtonBase
                                    icon={pageState.showFav ? 'favorated' : 'collection-ico'}
                                    type="svg"
                                    tooltip={
                                        MapGlobalVars.currentUserLan == 'zh'
                                            ? '我收藏的房屋'
                                            : 'My favourite properties'
                                    }
                                    clickEvent={toggleFav}></MapControlButtonBase>
                            )}

                            {!props.fromIndex || isMobileDevice() ? (
                                <div
                                    style={{
                                        marginTop: props.fromIndex ? 0 : 10,
                                        display: !pageState.showSearch ? 'block' : 'none',
                                    }}>
                                    <MapControlButtonBase
                                        icon="mySuburb"
                                        width={14}
                                        type={'svg'}
                                        clickEvent={handleMySuburb}></MapControlButtonBase>
                                </div>
                            ) : null}

                            {!isMobileDevice() ? (
                                <>
                                    <div style={{ marginTop: 10 }}></div>
                                    <div style={{ display: !pageState.showSearch ? 'block' : 'none' }}>
                                        <MapZoomController></MapZoomController>
                                    </div>
                                </>
                            ) : null}

                            {/* {pageState.showMapTypeToggle ? (
                            <MapControlButtonBase
                                icon={pageState.currentMapType == MapType.PLAIN ? 'map_flat' : 'map_sate'}
                                type="png"
                                clickEvent={toggleMapType}></MapControlButtonBase>
                        ) : null} */}

                            {/* <div style={{ marginTop: 24 }}></div>
        
                        <MapControlButtonBase icon="layers" clickEvent={toggleRealWays}></MapControlButtonBase> */}
                        </div>
                    </div>
                </>
            );
        };

        return <>{presentContent()}</>;
    };
});

import * as React from 'react';
import { useState } from 'react';
import { Button, Container, Grid, IconButton, Link } from '@material-ui/core';
import { getQueryParams, handleRedirect, SecurityProvider, saveLocationForRedirect, generateGUID } from '../../commons';
import { hooks, rst } from 'rt-state';
import { TR, TRHtml } from '../../commons';
import { getUserProfile, loginSession, login, forgetPassword } from '../../services/auth';
import { FcGoogle } from 'react-icons/fc';
import { useStyles } from './login.styles';
import { getErrorData } from '../../commons/axios/axios';
import { AuthDialogHeader, PageWrapper, PASSWORD_SCHEMA, USERNAME_SCHEMA } from './common';
import { useHistory } from 'react-router-dom';
import { SessionPo } from '../../models/backend/session_po';
import { AppProvider } from '../../commons/appProvider';
import { useRunOnceAsync } from '../../commons/hooks';
import { createXForm, XTextField } from '../../components/form';
import Box from '@material-ui/core/Box';
import { env } from '../../environments';
import { LoadingIndicator } from '../../components/Loading';
import Typography from '@material-ui/core/Typography';
import { InputSignUp } from '../mobile/member/commonComponents/input_signUp';
import { Margin } from '../../components/margin';
import { XCheckbox } from '../../components/form/mui-form/Checkbox';
import { mobileNavButton } from '../mobile/consts/styleConsts';

const initialValues = {
    username: '',
    password: '',
    termOfUse: true,
};
const validationSchema = {
    username: USERNAME_SCHEMA,
    termOfUse: (value) => {
        if (value) {
            return true;
        }
        return 'Terms of Use must be accepted';
    },
    // password: PASSWORD_SCHEMA,
};
const validationSchemaWithoutPassword = {
    username: USERNAME_SCHEMA,
    termOfUse: (value) => {
        if (value) {
            return true;
        }
        return 'Terms of Use must be accepted';
    },
};

export const LoginPage = () => (
    <PageWrapper>
        <LoginComp />
    </PageWrapper>
);

export const LoginComp = rst.create<{ onClose?: () => void; goToSignup?: () => void }>((ctx) => {
    const isLoading = rst.stateS(false);
    const isForgetPassword = rst.stateS(false);
    const securityProvider = SecurityProvider.use();
    const appProvider = AppProvider.use();
    const historyRef = hooks(useHistory);
    const loginState = rst.state({
        formErrors: null,
    });

    const form = createXForm({
        initialValues,
        validate: validationSchema,
        onSubmit,
    });
    const valiadteLogin = () => {
        loginState.formErrors = form.errors;
        form.handleSubmit();
    };
    const { snackbarController } = appProvider;
    const { state } = appProvider;
    function getSessionPo() {
        return getQueryParams(historyRef.current) as SessionPo;
    }

    function hasToken() {
        return !!getSessionPo().token;
    }

    async function onSubmit(data: any) {
        if (isForgetPassword.value) {
            await onForgetPassword(data);
        } else {
            await onLogin(data);
        }
    }

    async function onLogin(data: any) {
        isLoading.value = true;
        const history = historyRef.current;
        try {
            const { username, password } = data;
            const jwt = await login(username, password);
            const redirect = !ctx.props.onClose;
            ctx.props.onClose?.(); // eslint-disable-line
            await prepareAndRedirect(jwt, redirect);
        } catch (e) {
            snackbarController.open(<TR name={'Login failed'} />, 'error');
            console.log(getErrorData(e));
            if (!ctx.props.onClose) {
                history.push('/login');
            }
        }
        isLoading.value = false;
        state.isLogOut = false;
    }

    async function onForgetPassword(data: any) {
        isLoading.value = true;
        try {
            const { username } = data;
            // from oauth or email
            await forgetPassword(username);
            snackbarController.open(
                <TR name={'Email was sent. Please click on a link in the email to change your password.'} />,
                'success',
            );
        } catch (e) {
            snackbarController.open('failed', 'error');
            console.error(getErrorData(e));
        }
        ctx.props.onClose?.(); // eslint-disable-line
        isLoading.value = false;
    }

    async function handleForgetPassword() {
        isForgetPassword.value = !isForgetPassword.value;
    }

    async function prepareAndRedirect(token: string, redirect = true) {
        const history = historyRef.current;
        await securityProvider.setToken(token);
        const userProfile = await getUserProfile();
        securityProvider.setUserProfile(userProfile);
        snackbarController.open(<TR name={'success'} />, 'success');
        if (redirect) {
            await handleRedirect(history);
        }
    }

    async function onOauthLogin() {
        const history = historyRef.current;
        await saveLocationForRedirect(history);
        window.open(`${env.USER_API_HOST}/user/api/oauth/render/google`, '_self');
    }

    rst.watch(
        (values) => {
            form.setValidate(values[0] ? validationSchemaWithoutPassword : validationSchema);
        },
        () => [isForgetPassword.value],
    );

    async function init() {
        if (!hasToken()) {
            return;
        }
        const history = historyRef.current;
        try {
            const sessionPo = getSessionPo();

            const jwt = await loginSession(sessionPo);

            await prepareAndRedirect(jwt, true);
        } catch (e) {
            snackbarController.open('Login failed.', 'error');
            console.log(getErrorData(e));
            history.push('/login');
        }
    }

    ////////////////////////////////////////////////////////////////////////
    return (props) => {
        useRunOnceAsync(init);
        ////////////////////////////////////////////////////////
        const classes = useStyles(props);

        if (hasToken()) {
            return <div>loading</div>;
        }

        return (
            <Grid container justify={'center'} direction={'column'} alignItems={'center'}>
                <Container>
                    <AuthDialogHeader onClose={props.onClose} forgetPass={isForgetPassword.value} />
                    {!isForgetPassword.value && (
                        <div className={classes.info}>
                            <Typography>
                                <TR name={'Welcome2'} />
                            </Typography>
                        </div>
                    )}
                    {isForgetPassword.value && (
                        <div className={classes.info}>
                            <Typography>
                                <TR name={'EMAIL_WHEN_SIGNUP'} />
                            </Typography>
                        </div>
                    )}

                    <Margin tm={15}></Margin>
                    <InputSignUp
                        formErrors={loginState.formErrors}
                        form={form}
                        name="username"
                        label={'emailAddress'}
                        placeholder={'emailAddress_ph'}></InputSignUp>

                    {!isForgetPassword.value && (
                        <>
                            <Margin tm={15}></Margin>
                            <InputSignUp
                                formErrors={loginState.formErrors}
                                form={form}
                                name="password"
                                label={'Password'}
                                type={'password'}
                                placeholder={'password_ph'}></InputSignUp>
                        </>
                    )}

                    {!isForgetPassword.value && (
                        <XCheckbox
                            form={form}
                            name={'termOfUse'}
                            label={
                                <div style={{ fontSize: 13 }}>
                                    <TR name={'Yes! I understand and agree to the HomeOn '} />
                                    <Link href={'/termOfUse'} target={'_blank'}>
                                        <TR name={'Terms of Use'} />
                                    </Link>{' '}
                                    <TR name={' and '} />{' '}
                                    <Link href={'/privacyPolicy'} target={'_blank'}>
                                        <TR name={'Privacy Policy'} />
                                    </Link>
                                </div>
                            }
                        />
                    )}
                    <Margin tm={10}></Margin>
                    {rst.view(() => {
                        return (
                            <LoadingIndicator isLoading={isLoading.value}>
                                <Button
                                    style={{
                                        ...mobileNavButton,
                                        background: '#009e90',
                                        height: '42px',
                                        color: 'white',
                                        width: '100%',
                                    }}
                                    onClick={valiadteLogin}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disableElevation>
                                    <TR name={isForgetPassword.value ? 'Reset your Password' : 'Login'} />
                                </Button>
                            </LoadingIndicator>
                        );
                    })}

                    {!isForgetPassword.value && (
                        <>
                            <Box display={'flex'} justifyContent={'center'} width={'100%'} mt={1}>
                                <Button style={{ color: '#333333', fontSize: 16 }} onClick={handleForgetPassword}>
                                    <TR name={'Forget your password?'} />
                                </Button>
                            </Box>
                        </>
                    )}

                    <Margin tm={10}></Margin>

                    {!isForgetPassword.value && (
                        <>
                            <Button
                                style={{
                                    ...mobileNavButton,
                                    background: 'white',
                                    height: '42px',
                                    color: '#333333',
                                    width: '100%',
                                    border: '1px solid #979797',
                                    borderRadius: '4px',
                                }}
                                onClick={onOauthLogin}
                                fullWidth
                                variant="contained"
                                disableElevation>
                                <FcGoogle size={24} />
                                <Margin lm={6}></Margin> <TR name={'Login with Google'} />
                            </Button>

                            <Margin bm={10}></Margin>
                        </>
                    )}

                    {!isForgetPassword.value && (
                        <>
                            <Box display={'flex'} style={{ width: '100%' }} justifyContent={'center'} mt={1}>
                                <Button style={{ color: '', fontSize: 16 }} onClick={props.goToSignup}>
                                    <TRHtml html={'Signup_here'} />
                                    {/* <p }
                                        <TR name={'Signup_here1'} />
                                        <TR name={'Signup_here2'} />
                                        <TR name={'Signup_here3'} />
                                    </p> */}
                                </Button>
                            </Box>
                        </>
                    )}

                    {isForgetPassword.value && (
                        <>
                            <Grid container justify={'flex-end'}>
                                <Button
                                    style={{ fontSize: 16 }}
                                    color="primary"
                                    onClick={() => {
                                        isForgetPassword.value = !isForgetPassword.value;
                                    }}>
                                    <TR name={'Back to Login'} />
                                </Button>
                            </Grid>
                        </>
                    )}
                </Container>
            </Grid>
        );
    };
});

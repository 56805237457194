import React from 'react';
import { create, stateS } from 'rt-state';
import { UserCenterPage } from './UserCenterPage/UserCenterPage';
import { UserInfoPage } from '../auth/UserInfo';
import { ChangePasswordPage } from '../auth/ChangePassword';

export const UserCenter = create((ctx) => {
    const isUserCenterOpen = stateS(true);
    const isUserInfoOpen = stateS(false);
    const isPasswordSettingOpen = stateS(false);
    function handleOpenUserInfo() {
        isUserCenterOpen.value = false;
        isUserInfoOpen.value = true;
        // console.log(`isUserCenterOpen.value=${isUserCenterOpen.value}`);
        // console.log(`isUserInfoOpen.value=${isUserInfoOpen.value}`);
    }
    function handleOpenPasswordSetting() {
        isUserCenterOpen.value = false;
        isPasswordSettingOpen.value = true;
    }
    function handleOpenUserCenter() {
        isUserCenterOpen.value = true;
        isUserInfoOpen.value = false;
        isPasswordSettingOpen.value = false;
    }

    return (props) => {
        return isUserCenterOpen.value ? (
            <UserCenterPage
                openUserInfo={() => handleOpenUserInfo()}
                openPasswordSetting={() => handleOpenPasswordSetting()}
            />
        ) : isUserInfoOpen.value ? (
            <UserInfoPage />
        ) : (
            <ChangePasswordPage />
        );
    };
});

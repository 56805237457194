import { Box, makeStyles, Slider } from '@material-ui/core';
import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { rst, state } from 'rt-state';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { isMobileDevice } from '../../../../commons/utils';
import { XPanel } from '../../../../components/Layout/XPanel';
import { MapInitParam, MapLevel } from '../../support/PriceMapConsts';

const useStyles = makeStyles((theme) => {
    return {
        zoomDiv: {
            display: 'flex',
            flexDirection: 'column',
            background: 'white',
            borderRadius: 6,
            width: isMobileDevice() ? 40 : 50,
            padding: 18,
            justifyContent: 'center',
            alignItems: 'center',
        },
        bar: {
            width: 8,
            height: 126,
            borderRadius: 4,
            background: '#F5F5F5',
            display: 'flex',
            alignItems: 'flex-end',
        },
        innerBar: {
            background: '#0091FF',
            borderRadius: 4,
            width: 8,
            height: 50,
        },
        zoomButton: {},
        touchArea: {
            height: 20,
            width: '30',
            display: 'flex',
            alignItems: 'flex-start',
            cursor: 'pointer',
        },
    };
});
export const MapZoomController = rst.create((ctx) => {
    const zoomControllerState = rst.state({ zoom: MapInitParam.zoom });

    EventBus.on(EVENT_NAMES.EVENT_MAP_ZOOMED, (zoom) => {
        zoomControllerState.zoom = zoom;
    });

    return (props) => {
        const handlers = useSwipeable({
            onSwipedUp: (eventData) => {
                zoomMap('in');
            },
            onSwipedDown: (eventData) => {
                zoomMap('out');
            },
        });

        const classes = useStyles();
        const getInnerBarHeight = () => {
            const minLevel = MapLevel.suburbLevel.lower;
            const maxLevel = MapLevel.propertyLevel.upper;
            const innerBarHeight = (126 / (maxLevel - minLevel)) * (zoomControllerState.zoom - minLevel);

            return innerBarHeight;
        };
        const zoomMap = (in_out, value?) => {
            console.log('new zoom value ' + value);
            if (in_out == 'in') {
                if (zoomControllerState.zoom < MapLevel.propertyLevel.upper) {
                    if (value) {
                        zoomControllerState.zoom = value;
                    } else {
                        zoomControllerState.zoom++;
                    }

                    EventBus.dispatch(EVENT_NAMES.EVENT_ZOOM_MAP, {
                        zoomDirect: in_out,
                        zoomValue: zoomControllerState.zoom,
                    });
                }
            }
            if (in_out == 'out') {
                if (zoomControllerState.zoom > MapLevel.suburbLevel.lower) {
                    if (value) {
                        zoomControllerState.zoom = value;
                    } else {
                        zoomControllerState.zoom--;
                    }
                    EventBus.dispatch(EVENT_NAMES.EVENT_ZOOM_MAP, {
                        zoomDirect: in_out,
                        zoomValue: zoomControllerState.zoom,
                    });
                }
            }
        };
        const handleZoomSliderChange = (event, newValue) => {
            if (zoomControllerState.zoom > newValue) {
                zoomMap('out', newValue);
            } else {
                zoomMap('in', newValue);
            }
        };
        return (
            <div className={classes.zoomDiv} {...handlers}>
                <div
                    onClick={() => {
                        zoomMap('in');
                    }}
                    className={classes.touchArea}>
                    <img
                        src={'assets/icons/plus.svg'}
                        className={classes.zoomButton}
                        width={isMobileDevice() ? 12 : 18}
                    />
                </div>
                <div style={{ marginTop: 10 }}></div>

                <div
                    onClick={() => {
                        zoomMap('out');
                    }}
                    className={classes.touchArea}
                    style={{ alignItems: 'flex-end' }}>
                    {' '}
                    <img
                        src={'assets/icons/minus.svg'}
                        className={classes.zoomButton}
                        width={isMobileDevice() ? 12 : 18}
                    />
                </div>
            </div>
        );
    };
});

import React from 'react';
import { rst } from 'rt-state';
import { Button, Card, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import { BasicInfo } from './BasicInfo';
import { BedroomBathroomCarparkArea } from './BedroomBathroomCarparkArea';
import { SchoolsAndTrainInfo } from './SchoolsAndTrainInfo';
import { FavouriteDto } from '../../../models/backend/userCenter';
import { useStyles } from '../styles';
import { TR } from '../../../commons';
import { getStreetImageForProp } from '../../../commons/utils';

export const MyFavouriteCardForMobile = rst.createS<{
    dto: FavouriteDto;
    onClickTop(dto: FavouriteDto): void;
    onClickFavourite(dto: FavouriteDto): void;
}>((props) => {
    const { dto, onClickTop, onClickFavourite } = props;
    const { top } = dto;
    const propType = dto.entity.propType;
    const street = dto?.entity?.stdAddress.split(',')[0];
    const suburb = dto?.entity?.stdAddress.split(',')[1];

    const imgSrc = getStreetImageForProp(dto.entity?.stdAddress);

    const classes = useStyles({ imgSrc });
    return (
        <>
            <Card className={classes.root}>
                {/* 上半部 */}
                <Grid container spacing={0}>
                    {/* 街景图片 */}
                    <Grid item xs={5}>
                        <div className={classes.pic}>
                            <button className={classes.buttonForMobile}>
                                <span>
                                    <TR name={'St. View'} />
                                </span>
                            </button>
                        </div>
                    </Grid>
                    {/* 图片右侧 */}
                    <Grid container item direction="column" xs={7}>
                        <Grid
                            item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            className={classes.address}
                            spacing={0}>
                            <Grid item xs={9}>
                                <h3>{street}</h3>
                                <span>{suburb}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    display={'flex'}
                                    flexGrow={1}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}>
                                    <IconButton onClick={() => onClickTop(dto)}>
                                        <VerticalAlignTopIcon color={top ? 'primary' : 'disabled'} />
                                    </IconButton>
                                    <IconButton onClick={() => onClickFavourite(dto)}>
                                        <FavoriteIcon color={'secondary'} />
                                    </IconButton>
                                </Box>
                                {/* <span>up</span>
                                <span>star</span> */}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            className={classes.priceForMobile}
                            spacing={0}>
                            <BasicInfo dto={dto} />
                        </Grid>
                        <BedroomBathroomCarparkArea dto={dto} />
                    </Grid>
                </Grid>
                {/* 下半部 */}
                <SchoolsAndTrainInfo dto={dto} />
            </Card>
        </>
    );
});

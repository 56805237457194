import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst, state } from 'rt-state';
import { generateGUID, SecurityProvider, TR } from '../../../../commons';
import { AppProvider } from '../../../../commons/appProvider';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { recordUserActionsToGA } from '../../../../commons/ga/ga';
import { PAGE_ACTIONS } from '../../../../commons/ga/gaConsts';
import { useRunOnce } from '../../../../commons/hooks';
import { getItemFromArray, isMobileDevice } from '../../../../commons/utils';
import { UserCenterEntityType } from '../../../../models/backend/userCenter';
import { removeFavourite, saveFavourite } from '../../../../services/userCenter';

const useStyles = makeStyles((theme) => {
    return {
        icon: {
            width: isMobileDevice() ? 12 : 20,
            height: isMobileDevice() ? 12 : 20,
            cursor: 'pointer',
        },
    };
});
export const ShareOrFavOrOther = rst.create<{ prop: any }>((ctx) => {
    const componentID = generateGUID();
    const securityProvider = SecurityProvider.use();
    const state = rst.state({
        favourited: ctx.props.prop.isFav,
    });

    const appProvider = AppProvider.use();

    const hasLogin = () => {
        return securityProvider.hasLogin();
    };

    function saveToFav() {
        if (hasLogin()) {
            recordUserActionsToGA(PAGE_ACTIONS.priceMap.label, PAGE_ACTIONS.priceMap.events.addAsFav.description);
            saveFavourite({ entityType: UserCenterEntityType.Property, entityId: ctx.props.prop.propId }).then(
                () => {
                    if (appProvider) {
                        appProvider.snackbarController.open(<TR name="FAV_SUCCESS"></TR>, 'success');
                    }

                    state.favourited = true;
                    ctx.props.prop.isFav = true;
                },
                (err) => {
                    if (appProvider) {
                        appProvider.snackbarController.open(<TR name="FAV_FAIL"></TR>, 'error');
                    }
                },
            );
        } else {
            appProvider.openLoginDialog(null);
        }
    }
    function cancelFav() {
        removeFavourite(ctx.props.prop.propId).then(
            () => {
                if (appProvider) {
                    appProvider.snackbarController.open(<TR name="CANCEL_FAV_SUCCESS"></TR>, 'success');
                }

                state.favourited = false;
                ctx.props.prop.isFav = false;
            },
            (err) => {},
        );
        state.favourited = false;
    }

    setTimeout(() => {
        const saveFavBtn = document.getElementById(componentID + '_saveFavBtn');
        const cancelFavBtn = document.getElementById(componentID + '_cancelFavBtn');
        console.log(saveFavBtn);
        if (saveFavBtn) {
            saveFavBtn.addEventListener('click', () => {
                saveToFav();
                saveFavBtn.style.display = 'none';
                cancelFavBtn.style.display = 'block';
            });

            cancelFavBtn.addEventListener('click', () => {
                cancelFav();
                saveFavBtn.style.display = 'block';
                cancelFavBtn.style.display = 'none';
            });
        }
    }, 500);

    return (props) => {
        console.log(props.prop);
        state.favourited = props.prop['isFav'];
        console.log('prop faved?' + state.favourited + ';' + props.prop['isFav']);
        const reCenterCurrent = () => {
            EventBus.dispatch(EVENT_NAMES.EVENT_RECENTER_CURRENT, {});
        };
        const classes = useStyles();

        return (
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'flex-start',
                    marginLeft: 5,
                }}>
                <>
                    <div
                        style={{ display: !state.favourited ? 'block' : 'none', padding: 4 }}
                        id={componentID + '_saveFavBtn'}>
                        <img
                            // onClick={saveToFav}
                            src="assets/icons/collection-ico.svg"
                            className={classes.icon}></img>
                    </div>
                    <div
                        style={{ display: state.favourited ? 'block' : 'none', padding: 4 }}
                        id={componentID + '_cancelFavBtn'}>
                        <img
                            // onClick={cancelFav}
                            src="assets/icons/favorated.svg"
                            className={classes.icon}></img>
                    </div>
                </>
                {/* {!isMobileDevice() ? (
                    <>
                        <div style={{ marginLeft: 5 }}></div>
                        <img
                            src="assets/icons/placeholder_black.svg"
                            onClick={reCenterCurrent}
                            className={classes.icon}></img>
                    </>
                ) : null} */}
            </div>
        );
    };
});

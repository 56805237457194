import { Dialog, DialogContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import { LeafletAdjacentMap } from './LeafletAjacentMap';

const win: any = window;
const L = win.L;
export const FullScreenAdjacentMap = (props: any) => {
    const { open, closeFull, mapProps } = props;

    return (
        <Dialog fullScreen={true} open={open}>
            <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 999999 }} onClick={closeFull}>
                <img src="/assets/mobileIcons/close.svg"></img>
            </div>
            <DialogContent style={{ padding: 0 }}>
                <LeafletAdjacentMap
                    full
                    mapId="2"
                    redrawVar={mapProps.redrawMapVar}
                    ref={mapProps.mapRef}
                    key={mapProps.mapComponentKey}
                    propCenter={mapProps.propCenter}
                    drawingData={mapProps.drawingData}></LeafletAdjacentMap>
            </DialogContent>
        </Dialog>
    );
};

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { SecurityProvider } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { Permission } from '../../../../commons/security/authz';
import { isMobileDevice } from '../../../../commons/utils';
import { getPriceMapLevelByZoom } from '../../support/common';
import { PriceMapFilter } from '../../support/DataModels';
import {
    MapGlobalVars,
    MapInitParam,
    MapLevel,
    PRICE_MAP_STATUS,
    SuburbPriceColorsLabel,
} from '../../support/PriceMapConsts';
import { MapControlLayer } from '../MapControlLayer';
import { PriceMapLegend } from '../PriceMapLegend';
import { MobileFilterTabs } from './MobileFilterTabs';
import { PCFilter } from './PCFilter';

const useStyles = makeStyles((theme) => {
    return {
        mobileFilter: {
            position: 'absolute',
            bottom: '0px',

            width: '100%',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',

            //background: 'white',
        },
        pcFilter: {
            position: 'absolute',
            right: '24px',
            top: '60px',
            display: 'flex',
        },
        pcMapLegend: {
            position: 'absolute',
            bottom: 33,
            right: 25,
        },
    };
});
export const Filter_InfoPanel = rst.create((ctx) => {
    const securityProvider = SecurityProvider.use();
    const state = rst.state({
        mapZoom: MapInitParam.zoom,
        proeprtyType: MapInitParam.propertyType,
        showMapController: true,
    });
    EventBus.on(EVENT_NAMES.EVENT_MAP_ZOOMED, (zoom) => {
        state.mapZoom = zoom;
    });
    EventBus.on(EVENT_NAMES.EVENT_CHANGE_FILTER, (mapFilter: PriceMapFilter) => {
        state.proeprtyType = mapFilter.propertyType;
        console.log(mapFilter.propertyType);
    });
    EventBus.on(EVENT_NAMES.EVENT_CHANGE_SUBURB_COLOR_BY, (colorBy) => {
        state.proeprtyType = SuburbPriceColorsLabel[colorBy].key;
        console.log(colorBy);
    });

    EventBus.on(EVENT_NAMES.EVENT_SWITCH_MAP_ICONS, (showIcons) => {
        state.showMapController = showIcons;
    });

    return (props) => {
        const classes = useStyles();
        const getContent = () => {
            if (isMobileDevice()) {
                return <MobileFilterTabs></MobileFilterTabs>;
            } else {
                return <PCFilter></PCFilter>;
            }
        };
        const presentContent = () => {
            return (
                <>
                    <div className={isMobileDevice() ? classes.mobileFilter : classes.pcFilter}>{getContent()}</div>
                    <MapControlLayer></MapControlLayer>
                    {!isMobileDevice() && !MapGlobalVars.showAroundTransCircle ? (
                        <div
                            className={classes.pcMapLegend}
                            style={{ display: state.showMapController ? 'block' : 'none' }}>
                            <PriceMapLegend mapZoom={state.mapZoom} propertyType={state.proeprtyType}></PriceMapLegend>
                        </div>
                    ) : null}
                </>
            );
        };
        return <>{presentContent()}</>;
    };
});

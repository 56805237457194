import React from 'react';
import { rst } from 'rt-state';
import { Button, Card, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import { BasicInfo } from './BasicInfo';
import { BedroomBathroomCarparkArea } from './BedroomBathroomCarparkArea';
import { SchoolsAndTrainInfo } from './SchoolsAndTrainInfo';
import { FavouriteDto } from '../../../models/backend/userCenter';
import { useStyles } from '../styles';
import { TR } from '../../../commons';
import { getStreetImageForProp } from '../../../commons/utils';
import { SchoolOrStationLine } from '../../PriceMap/Layers/PropertySidebar/SchoolOrTrainStation/SchoolOrStationLine';

export const MyFavouriteCard = rst.createS<{
    dto: FavouriteDto;
    onClickTop(dto: FavouriteDto): void;
    onClickFavourite(dto: FavouriteDto): void;
}>((props) => {
    const { dto, onClickTop, onClickFavourite } = props;
    const { top } = dto;
    const [street, suburb] = dto?.entity?.stdAddress.split(',');

    const imgSrc = getStreetImageForProp(dto.entity?.stdAddress);
    const classes = useStyles({ imgSrc, x: 23 });
    return (
        <Card className={classes.root}>
            <div className={classes.pic}>
                {/* 图内地址及按钮 apt及house共用*/}
                <div className={classes.picContent}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid container item xs={8} alignItems={'center'}>
                            <h3>{street}</h3>
                            <span>{suburb}</span>
                        </Grid>
                        <Grid container item xs={3} alignItems={'center'}>
                            <Button
                                size="small"
                                variant="outlined"
                                style={{ color: 'rgb(200 200 200)', padding: '2px 5px' }}
                                className={classes.button}>
                                <span>
                                    <TR name={'St. View'} />
                                </span>
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className={classes.content}>
                <Grid container direction="column" justify="space-between" alignItems="flex-start">
                    {/* 内容中成交价及面积 house*/}
                    {/* {price} */}

                    <Grid item container xs={12}>
                        <Box flexGrow={4}>
                            <BasicInfo dto={dto} />
                        </Box>
                        <Box display={'flex'} flexGrow={1} justifyContent={'space-between'} alignItems={'center'}>
                            <IconButton onClick={() => onClickTop(dto)}>
                                <VerticalAlignTopIcon color={top ? 'primary' : 'disabled'} />
                            </IconButton>
                            <IconButton onClick={() => onClickFavourite(dto)}>
                                <FavoriteIcon color={'secondary'} />
                            </IconButton>
                        </Box>
                    </Grid>

                    {/* bedroom, bathroom, carPark, area */}
                    <BedroomBathroomCarparkArea dto={dto} />
                    {/* 学校及火车站 apt及house共用*/}

                    <SchoolsAndTrainInfo dto={dto} />
                </Grid>
            </div>
        </Card>
    );
});

import { Box, makeStyles, Slider } from '@material-ui/core';
import React from 'react';
import { rst, state } from 'rt-state';
import { clsx, SecurityProvider, TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { Permission } from '../../../commons/security/authz';
import { isMobileDevice } from '../../../commons/utils';
import { getPriceMapLevelByZoom } from '../support/common';
import { PRICE_MAP_STATUS } from '../support/PriceMapConsts';
import { SubFilterContainer } from './Filter_InfoPanel/PCFilter';
import { PropertyFilterPanel } from './Filter_InfoPanel/PropertyFilterPanel';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'absolute',
            top: isMobileDevice() ? '60px' : '60px',
            left: '10px',
            zIndex: 1255,
        },
        catagoryDiv: {
            background: '#ffffff',
            borderRadius: 8,
            paddingTop: 8,
            paddingBottom: 8,
            minWidth: 200,
            height: '63vh',
            // [theme.breakpoints.down('sm')]: {
            //     height: '30vh',
            // },
            overflow: 'auto',
        },
        singleItem: {
            color: '#333333',
            padding: 10,
            background: '#ffffff',
            fontSize: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                padding: '5px 10px',
                fontSize: 12,
            },
        },
        selectedSingleItem: {
            color: '#1875f0',
        },
    };
});

const MapCatagoryList = [
    {
        field: 'medianPrice',
        label: 'medianPrice',
        public: true,
    },
    {
        label: 'House price changes (last 3 months)',
        field: 'PriceChange3M',
        public: true,
    },
    {
        label: 'House price changes (last 6 months)',
        field: 'PriceChange6M',
        public: true,
    },
    {
        label: 'Median lot size',
        field: 'median_lz',
        public: true,
    },
    {
        label: 'Primary School',
        field: 'rate_primary',
        public: true,
    },
    {
        label: 'Secondary School',
        field: 'score_secondary',
    },
    {
        label: 'PrivateSchoolMixBoy',
        field: 'rate_private_boys',
    },
    {
        label: 'PrivateSchoolMixGirl',
        field: 'rate_private_girls',
    },
    {
        label: 'Tenant Proportion',
        field: 'rental_pct',
        public: true,
    },
    // {
    //     label: 'Transport',
    //     field: 'train_station',
    // },
    {
        label: 'Chinese Immi Pct Pop',
        field: 'chinese',
    },
    {
        label: 'Indian Immi Pct Pop',
        field: 'hinduism_p_pct',
    },
    {
        label: 'Islam Immi Pct Pop',
        field: 'islam_p_pct',
    },
    {
        label: 'Family Income',
        field: 'family_income',
        public: true,
    },
    {
        label: 'House/Apartment',
        field: 'house_pct',
    },
    {
        label: 'Public Security',
        field: 'rate_public_security',
        public: true,
    },
    // {
    //     label: 'Waterscape',
    //     field: 'rate_waterfront',
    // },
    {
        label: 'Trees',
        field: 'rate_tree_cover',
    },
    {
        label: 'BUSINESS',
        field: 'rate_shopping',
    },
];

export const MapCatagory = rst.create<{ fromIndex: boolean }>((ctx) => {
    const securityProvider = SecurityProvider.use();
    const PropType = rst.stateS('House');
    EventBus.on(EVENT_NAMES.EVENT_SHOW_PRICE_MAP_TITLE, (show) => {
        pageState.show = show;
    });
    EventBus.on(EVENT_NAMES.EVENT_MAP_ZOOMED, (zoomLevel) => {
        pageState.mapStatus = getPriceMapLevelByZoom(zoomLevel);
    });
    const pageState = rst.state({
        selectedCatagory: 'medianPrice',
        show: false,
        mapStatus: PRICE_MAP_STATUS.SUBURBLEVEL,
    });

    const handlePropType = (type) => {
        PropType.value = type;
    };

    return (props) => {
        const presentMapCatagory = () => {
            const presentContent = [];
            MapCatagoryList.map((loopCatagory) => {
                if (
                    loopCatagory.public ||
                    (!loopCatagory.public &&
                        (securityProvider.hasPermission(Permission.PROPERTY_BUYER) ||
                            securityProvider.hasPermission(Permission.TRIAL)))
                ) {
                    presentContent.push(
                        <MapCatagoryItem
                            categoryItem={loopCatagory}
                            clickedEvent={catagoryClickEvt}
                            currentSelected={pageState.selectedCatagory}></MapCatagoryItem>,
                    );
                }
            });

            return presentContent;
        };
        const catagoryClickEvt = (clickedItem) => {
            pageState.selectedCatagory = clickedItem.field;

            EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_SUBURB_COLOR_BY, clickedItem.field);
            pageState.show = false;
        };
        const classes = useStyles();
        return (
            <div
                className={classes.root}
                style={{
                    display:
                        !props.fromIndex && pageState.show && pageState.mapStatus == PRICE_MAP_STATUS.SUBURBLEVEL
                            ? 'flex'
                            : 'none',
                }}>
                <div className={classes.catagoryDiv}>{presentMapCatagory()}</div>
                <div
                    style={{
                        display: pageState.selectedCatagory == 'medianPrice' && !isMobileDevice() ? 'block' : 'none',
                        marginLeft: 10,
                    }}>
                    <div style={{ background: 'white', padding: 10, borderRadius: 8 }}>
                        <PropertyFilterPanel handlePropType={handlePropType}></PropertyFilterPanel>
                    </div>
                </div>
            </div>
        );
    };
});

const MapCatagoryItem = rst.create<{ categoryItem; clickedEvent; currentSelected }>((ctx) => {
    const itemState = rst.state({});

    return (props) => {
        const classes = useStyles();

        return (
            <>
                <div
                    onClick={() => props.clickedEvent(props.categoryItem)}
                    className={clsx(
                        classes.singleItem,
                        props.currentSelected == props.categoryItem.field ? classes.selectedSingleItem : '',
                    )}>
                    <TR name={props.categoryItem.label}></TR>
                </div>
                <div style={{ borderBottom: '1px solid #D8D8D8', opacity: 0.3, height: 1 }}></div>
            </>
        );
    };
});

import React from 'react';
import { Theme } from '@material-ui/core/styles';
import withStyles from '@material-ui/styles/withStyles';
import { XBaseDialog } from './XBaseDialog';

export const XAuthDialog = withStyles((theme: Theme) => {
    return {
        paper: {
            width: 400,
        },
    };
})(XBaseDialog);

import React from 'react';

const win: any = window;
const L = win.L;

export const SearchResultMap = (props: any) => {
    const { propCenter } = props;
    var mapInstance = null;
    const noLableLayer = L.tileLayer(
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png',
        {
            attribution: false,
        },
    );
    const initMap = () => {
        var container = L.DomUtil.get('googleSearchResultMap');
        if (container != null) {
            container._leaflet_id = null;
        }

        if (mapInstance == null) {
            try {
                mapInstance = L.map('googleSearchResultMap', {
                    zoomControl: false,
                    doubleClickZoom: false,
                    scrollWheelZoom: false,
                    attribution: false,
                    dragging: false,
                }).setView([propCenter.lat, propCenter.lon], 14);
                mapInstance.addLayer(noLableLayer);
                drawHouseMarker();
            } catch (e) {
                console.log(e);
            }
        }
    };

    const drawHouseMarker = () => {
        const houseMarkerIcon = new L.DivIcon({
            className: '',
            html:
                '<div style="margin-left:-10px;margin-top:-10px"><img src="assets/mobileIcons/houseSelf.svg" /></div>',
        });

        const houseMarker = L.marker([propCenter.lat, propCenter.lon]).addTo(mapInstance);
        houseMarker.setIcon(houseMarkerIcon);
    };

    setTimeout(() => {
        initMap();
    }, 300);
    return <div id="googleSearchResultMap" style={{ width: '100%', height: 127, borderRadius: '10px' }}></div>;
};

import { useEffect, useMemo, useState } from 'react';

export function useDebounce<T>(value: T, delayInMilliseconds: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const ref = useMemo(
        () => ({
            handler: null,
        }),
        [],
    );
    useEffect(() => {
        let active = true;
        if (ref.handler) {
            clearTimeout(ref.handler);
        }
        ref.handler = setTimeout(() => {
            ref.handler = null;
            if (active) {
                setDebouncedValue(value);
            }
        }, delayInMilliseconds);
        return () => {
            active = false;
            if (ref.handler) {
                clearTimeout(ref.handler);
            }
        };
    }, value as any);
    return debouncedValue;
}

export interface UseAsyncResponse<T> {
    loading: boolean;
    result: T;
}
// don't change the state of the component within 'func'.
export function useAsync<T>(func: () => Promise<T>, deps: any[]): UseAsyncResponse<T> {
    const [response, setResponse] = useState<UseAsyncResponse<T>>({ loading: true, result: null as any });
    useEffect(() => {
        let active = true;
        const newResponse = { loading: true, result: null } as UseAsyncResponse<T>;
        setResponse(newResponse);
        func().then((data) => {
            if (active) {
                setResponse({ loading: false, result: data });
            }
        });
        return () => {
            active = false;
        };
    }, deps);

    return response;
}

export function useRunOnceAsync<T>(func: () => Promise<T>): UseAsyncResponse<T> {
    return useAsync<T>(func, []);
}

export function useRunOnce<T>(func: () => void) {
    return useEffect(func, []);
}

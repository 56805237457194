import { makeStyles } from '@material-ui/core';
import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../../commons';
import { getDatePart, isMobileDevice, numToMillion, priceToK } from '../../../../commons/utils';

import { PropBeds } from './PropBeds';

const useStyles = makeStyles((theme) => {
    return {
        housePrice: {
            fontSize: isMobileDevice() ? 12 : 20,
            fontWeight: 700,
            color: '#e02020',
            flexGrow: 1,
        },
        uninInfoDiv: { paddingLeft: 9 },
        unitMedian: {
            fontSize: 12,
            fontWeight: 700,
            color: '#333333',
            flexGrow: 1,
        },
        unitAddr: {
            fontSize: isMobileDevice() ? 13 : 16,
            fontWeight: 500,
            color: '#333333',
        },
        unitList: {
            display: 'flex',
            flexWrap: 'wrap',
            height: 70,
            overflowY: 'auto',
        },

        unitInfo: {
            background: 'rgba(24,117,240,0.1)',
            padding: '3px 2px',
            width: 130,

            '&:nth-child(even)': {
                marginLeft: 3,
            },
            marginTop: 3,
            paddingRight: 10,
        },
        unitPrice: {
            fontSize: isMobileDevice() ? 12 : 13,
            color: '#e02020',
            fontWeight: 400,
        },
        unitNo: {},
    };
});

export const UnitInfoMobilePanel = rst.create<{ prop: any }>((ctx) => {
    return (props) => {
        const { prop } = props;
        const classes = useStyles();
        return (
            <div className={classes.uninInfoDiv}>
                <div style={{ display: 'flex' }}>
                    <div className={classes.unitMedian}>
                        <TR name={'MEDIAN_PRICE'}></TR>
                    </div>

                    <div className={classes.housePrice}>{numToMillion(prop.price)}</div>
                </div>
                <div style={{ marginTop: 6 }}></div>
                <div>
                    <TR name={'This project scale'}></TR>
                    {prop.lots || '-'}
                    <TR name={'units'}></TR>
                </div>
                <div style={{ marginTop: 6 }}></div>
                <div>
                    <TR name={'selectedPropertyType'}></TR>
                    {prop.soldNumber}
                    <TR name={'unit'}></TR>
                </div>
            </div>
        );
    };
});

export const UnitInfoPanel = rst.create<{ prop: any }>((ctx) => {
    return (props) => {
        const { prop } = props;
        const classes = useStyles();
        const getUnitList = () => {
            var unitList = [];
            if (prop.units) {
                prop.units.map((loopUnit) => {
                    unitList.push(
                        <UnitInfo
                            soldPrice={loopUnit.sold_price}
                            unitNo={loopUnit.unit_number}
                            soldDate={loopUnit.sold_contract_date}
                            propBeds={{
                                beds: loopUnit.prop_bed,
                                baths: loopUnit.prop_bath,
                                cars: loopUnit.prop_carpark,
                            }}></UnitInfo>,
                    );
                });
            }

            return unitList;
        };
        return (
            <div className={classes.uninInfoDiv}>
                <div className={classes.unitAddr}>
                    {prop.address}
                    {prop.builtYear ? '(' : ''}
                    {prop.builtYear ? getDatePart(prop.builtYear, 'Y') : ''}
                    {prop.builtYear ? <TR name={'MOVEIN'}></TR> : ''}
                    {prop.builtYear ? ')' : ''}
                </div>

                <div style={{ marginTop: 6 }}></div>
                <div className={classes.unitList}>{getUnitList()}</div>
            </div>
        );
    };
});

const UnitInfo = rst.create<{
    soldPrice: number;
    unitNo: string;
    soldDate: string;
    propBeds: { beds: number; baths: number; cars: number };
}>((ctx) => {
    return (props) => {
        const { propBeds } = props;
        const classes = useStyles();
        return (
            <div className={classes.unitInfo}>
                <div className={classes.unitPrice}>{priceToK(props.soldPrice)}</div>
                <div className={classes.unitNo}>
                    {props.unitNo} {props.soldDate}
                </div>
                <PropBeds propType="Apt" baths={propBeds.baths} beds={propBeds.beds} cars={propBeds.cars}></PropBeds>
            </div>
        );
    };
});

import classes from '*.module.css';
import {
    Button,
    Card,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { storage, TR } from '../../commons';
import { EventBus, EVENT_NAMES } from '../../commons/EventBus';
import { getItemFromArray, isMobileDevice, withinMonth } from '../../commons/utils';
import { UserCenterEntityType } from '../../models/backend/userCenter';
import { api_estimatePropertyValue } from '../../services/property';
import { consumeReport, saveKVEntity } from '../../services/userCenter';
import { Tags } from '../PriceMap/PropertyCalculator';
import { updateTagStatus } from '../PriceMap/PropertyCalculator/CalculatorConst';
import { MapGlobalVars } from '../PriceMap/support/PriceMapConsts';
import { getPropLastRecord } from '../PriceMap/support/propertyFeatureHelper';

const useStyles = makeStyles((theme) => {
    return {
        card: {
            fontSize: '25px',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
        },

        reportDiv: {
            width: 595,
            margin: 10,
        },
        reportTitle: {
            padding: 10,
            background: 'rgba(255,121,137,0.10)',
            border: '1px solid rgba(255,61,89,0.28)',
            borderRadius: '5px',
            fontWeight: 400,
            color: '#ff2c4d',
        },
        reportTags: {
            padding: '22px 13px',
            background: '#ffffff',
            bordeRadius: '8px',
            boxShadow: '0px 2px 12px 0px #f3f3f3',
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 10,
        },
        label: {
            fontSize: isMobileDevice() ? 12 : 14,
            minWidth: 100,
        },
        addtionInfo: {
            fontSize: '14px',
            fontWeight: 400,
            color: '#333333;',
            paddingTop: '17px',
        },
        guidePrice: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 20,
            background: '#eff1fa',
            borderRadius: 4,
        },
        button: {
            fontSize: isMobileDevice() ? 12 : 14,
        },
        textField: {
            '&&&:before': {
                borderBottom: 'none',
            },
            '&&:after': {
                borderBottom: 'none',
            },
        },
    };
});

export const PropertyReport = rst.create<{ open: boolean; property: any; cancelEvt: any; reportEvt: any }>((ctx) => {
    const estimateResult = {
        lower: 0,
        middle: 0,
        upper: 0,
        inMonth: false,
    };

    const estimatePrice = (tag, selected?, choosedValue?) => {
        return new Promise(async (resolve, reject) => {
            var spaceParam = '';

            if (tag) {
                if (tag != 'decor_level' && tag != 'space_score') {
                    const updatingTag = getItemFromArray(ctx.props.property.allFeatures?.smart_tags, 'feature', tag);
                    if (updatingTag) {
                        updatingTag.selected = selected;
                        console.log(updatingTag);
                        updateTagStatus(tag, selected);
                    }
                } else {
                    if (tag == 'decor_level') {
                        const dectorTags = ctx.props.property.allFeatures?.smart_tags.filter((loopItem) => {
                            return loopItem.feature == tag;
                        });

                        if (choosedValue == 1) {
                            dectorTags[0].selected = 1;
                            dectorTags[1].selected = 0;
                        }
                        if (choosedValue == 2) {
                            dectorTags[0].selected = 0;
                            dectorTags[1].selected = 0;
                        }
                        if (choosedValue == 3) {
                            dectorTags[0].selected = 0;
                            dectorTags[1].selected = 1;
                        }
                    }

                    if (tag == 'space_score') {
                        spaceParam = tag + '=' + choosedValue;

                        const updatingTag = getItemFromArray(
                            ctx.props.property.allFeatures?.smart_tags,
                            'feature',
                            tag,
                        );
                        const space_scoreTag = {
                            id: ctx.props.property.allFeatures?.smart_tags?.length,
                            feature: tag,
                            selected: choosedValue,
                            tag_name: 'space score',
                        };

                        if (updatingTag) {
                            updatingTag.selected = choosedValue;
                        } else {
                            ctx.props.property.allFeatures?.smart_tags.push(space_scoreTag);
                        }
                    }
                }
            }

            const estimateFromBK: any = await api_estimatePropertyValue(ctx.props.property.allFeatures, spaceParam);
            let isPropertySoldInMonth = false;
            const lastPropertySold = getPropLastRecord(ctx.props.property.allFeatures);
            isPropertySoldInMonth = withinMonth(lastPropertySold.sold_contract_date, 'YYYY-MM-DD');

            if (isPropertySoldInMonth) {
                estimateResult.middle = lastPropertySold.sold_price;
            } else {
                estimateResult.middle = estimateFromBK.midPrice;
            }
            estimateResult.inMonth = isPropertySoldInMonth;

            estimateResult.lower = estimateFromBK.lowerPrice;
            estimateResult.upper = estimateFromBK.upperPrice;

            resolve(true);
        });
    };
    const pageState = rst.state({
        guidePrice: 0,
        salesWay: 0,
    });

    const showReport = async () => {
        await estimatePrice(null);
        await saveReportAddtion();
        ctx.props.reportEvt();
    };

    const saveReportAddtion = () => {
        return new Promise(async (resolve, reject) => {
            const savingPropertyObj = {
                propFeature: ctx.props.property,
                guidePrice: pageState.guidePrice,
                saleWay: pageState.salesWay,
            };

            MapGlobalVars.currentPropertyEstimate = {
                estimatePrice: estimateResult,
                guidePrice: pageState.guidePrice,
                saleWay: pageState.salesWay,
                smartTags: ctx.props.property.allFeatures?.smart_tags.filter((loopTag: any) => {
                    return loopTag.selected != 0;
                }),
            };

            console.log(MapGlobalVars.currentPropertyEstimate);

            // save report selection to backend for the current user
            saveKVEntity(
                UserCenterEntityType.PropertyReportAddtion,
                ctx.props.property.propId,
                MapGlobalVars.currentPropertyEstimate,
            );
            // consume report
            consumeReport(ctx.props.property.propId);

            resolve(true);
        });
    };
    const handleGuidePrice = (evt) => {
        pageState.guidePrice = evt.target.value;
    };
    const handleSaleWay = (evt) => {
        pageState.salesWay = evt.target.value;
        console.log(pageState.salesWay);
    };

    console.log(window.location.href);

    return (props) => {
        const classes = useStyles();
        return (
            <Dialog open={props.open} fullScreen={isMobileDevice()}>
                <DialogContent
                    style={
                        isMobileDevice()
                            ? { background: 'rgb(248 248 248)' }
                            : { width: '490px', background: 'rgb(248 248 248)' }
                    }>
                    <div className={classes.reportTags}>
                        <div className={classes.reportTitle}>
                            <TR name="REPORT_DESC"></TR>
                        </div>
                        <Tags
                            showAllParams={true}
                            tagEvent={estimatePrice}
                            prop={props.property}
                            tags={props.property.allFeatures?.smart_tags}></Tags>
                    </div>

                    <div className={classes.addtionInfo}>
                        <TR name={'REPORT_ADDTION'}></TR>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            {' '}
                            <TR name="GUIDE_PRICE"></TR>
                        </div>
                        <div className={classes.guidePrice}>
                            ${' '}
                            <TextField
                                onChange={handleGuidePrice}
                                className={classes.textField}
                                value={pageState.guidePrice}
                                style={{ marginLeft: 5, width: '80px' }}></TextField>
                            (m)<TR name={'AUD'}></TR>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.label}>
                            {' '}
                            <TR name="SALE_AUCTION"></TR>
                        </div>
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            value={pageState.salesWay}
                            onChange={handleSaleWay}>
                            <FormControlLabel
                                value="onSale"
                                control={<Radio color="primary" />}
                                label={<TR name="ON_SALE"></TR>}
                            />
                            <FormControlLabel
                                value="auction"
                                control={<Radio color="primary" />}
                                label={<TR name="Auction"></TR>}
                            />
                        </RadioGroup>
                    </div>
                </DialogContent>

                <DialogActions style={{ background: 'rgb(248 248 248)' }}>
                    <Button className={classes.button} onClick={showReport} color="primary">
                        <TR name="Generate Report"></TR>
                    </Button>

                    <Button className={classes.button} onClick={props.cancelEvt} color="primary">
                        <TR name="CANCEL"></TR>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
});

import React from 'react';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AppBar from '@material-ui/core/AppBar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LanguageIcon from '@material-ui/icons/Language';
import Toolbar from '@material-ui/core/Toolbar';
import { useStyles } from './Header.styles';
import { rst } from 'rt-state';
import BrightIcon from '@material-ui/icons/Brightness1';
import { useHistory } from 'react-router-dom';
import * as locales from '@material-ui/core/locale';
import { FullScreenButton } from './FullScreenButton';
import { AppProvider } from '../../../commons/appProvider';
import { SecurityProvider } from '../../../commons/security';

export const Header = rst.create<{
    logo: string;
    logoAltText: string;
    toggleDrawer: any;
    toogleNotifications: any;
}>((ctx) => {
    const appProvider = AppProvider.use();
    const securityProvider = SecurityProvider.use();
    const hooksRef = rst.hooks(() => useHistory());
    const anchorElState = rst.stateS(null);
    const searchExpanded = rst.stateS(false);
    const handleSettingsToggle = (event) => {
        anchorElState.value = event.currentTarget;
    };

    const handleThemeModeToggle = () => {
        appProvider.toggleThemeMode();
        anchorElState.value = null;
    };

    const handleCloseMenu = () => {
        anchorElState.value = null;
    };

    const handleProfile = () => {
        const profile = securityProvider.getUserProfile();
        console.log(profile);
        handleCloseMenu();
    };

    const onLogout = async () => {
        anchorElState.value = null;
        await securityProvider.setToken('');
        hooksRef.current.push('/');
    };

    const handleSearchExpandToggle = () => {
        searchExpanded.value = !searchExpanded.value;
    };

    const handleDrawerToggle = () => {
        ctx.props.toggleDrawer();
        if (searchExpanded.value) {
            handleSearchExpandToggle();
        }
    };

    const handleNotificationToggle = () => {
        ctx.props.toogleNotifications();
        if (searchExpanded.value) {
            handleSearchExpandToggle();
        }
    };

    return (props) => {
        const classes = useStyles();
        return (
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                    <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>

                    <div className={classes.branding}>
                        <img src={props.logo} alt={props.logoAltText} className={classes.logo} />
                    </div>

                    <span className="flexSpacer" />

                    <Hidden xsDown>
                        <FullScreenButton />
                    </Hidden>

                    <IconButton
                        aria-label="User Settings"
                        aria-owns={anchorElState.value ? 'user-menu' : null}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleSettingsToggle}>
                        <MoreVertIcon />
                    </IconButton>

                    <Menu
                        id="user-menu"
                        anchorEl={anchorElState.value}
                        open={Boolean(anchorElState.value)}
                        onClose={handleCloseMenu}>
                        <MenuItem onClick={handleThemeModeToggle}>
                            <ListItemIcon>
                                <BrightIcon />
                            </ListItemIcon>
                            <ListItemText primary={`Theme ${appProvider.getNextThemeMode()}`} />
                        </MenuItem>
                        <MenuItem onClick={handleProfile}>
                            <ListItemIcon>
                                <AccountBoxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Profile" />
                        </MenuItem>
                        <MenuItem onClick={onLogout}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sign out" />
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        );
    };
});

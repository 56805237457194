import React from 'react';
import { LoginPage, SignupPage } from './pages/auth';
import { ForgetPasswordPage } from './pages/auth/ForgetPassword';
import { AboutUsPage } from './pages/AboutUs';
import { ContactUsPage } from './pages/ContactUs';
import { TermOfUsePage } from './pages/TermOfUse';
import { PrivacyPolicyPage } from './pages/PrivacyPolicy';
import { DemoPage } from './pages/demo';
import { Dashboard } from './pages/Dashboard';
import { SuburbPage } from './pages/Suburb';
import { MapSelector } from './pages/Home/MapSelector/MapSelector';
import PriceRange from './pages/Home/PriceRange/PriceRange';
import { PriceMap } from './pages/PriceMap';
import { UserCenter } from './pages/UserCenter/UserCenter';
import { TestPage } from './pages/Test';
import { FirstPage } from './pages/Home/FirstPage';
import { ChangePasswordPage } from './pages/auth/ChangePassword';
import { UserInfoPage } from './pages/auth/UserInfo';
import { LanguageDemo } from './pages/demo/LanguageDemo';
import { UserAvatarButton } from './pages/auth/UserAuthButton';
import MemberShipDemo from './pages/MemberShipDemo';
import { MyFavouritePage } from './pages/MyFavorite/MyFavouritePage';
import { SearchHistoryPage } from './pages/SearchHistory/SearchHistoryPage';
import { HouseReport } from './pages/PropertyReport/HouseReport';
import { JoinMember0 } from './pages/Home/JoinMember0';
import { JoinMember1 } from './pages/Home/JoinMember1';
import { JoinMember2 } from './pages/Home/JoinMember2';
import { JoinMember } from './pages/Home/JoinMember';
import { HomePage } from './pages/NewHome/HomePage';
import { MyPropertyReportsPage } from './pages/MyPropertyReports';
import { Member } from './pages/NewHome/Member';
import { MobileHome } from './pages/mobile';
import { PropertyCard } from './pages/mobile/component/propertyCard';
import { PriceMapCondition } from './pages/mobile/component/aroundPriceMap/priceMapCondition';
import { VerifyEmailPage } from './pages/mobile/member/signUp/verifyEmail';
import AdjacentSoldDisplay from './pages/forExternal/adjacentSold';

export const ROUTES = {
    TestPage: {
        path: '/testPage',
        component: () => <PriceMapCondition />,
    },
    SuburbsPage: {
        path: '/suburbs',
        component: () => <SuburbPage />,
    },
    LoginPage: {
        path: '/login',
        component: () => <LoginPage />,
    },
    ChangePasswordPage: {
        path: '/forgetPassword',
        component: () => <ForgetPasswordPage />,
    },
    SignupPage: {
        path: '/signup',
        component: () => <SignupPage />,
    },
    VerifyEmailPage: {
        path: '/verifyEmail',
        component: () => <VerifyEmailPage />,
    },
    AboutUsPage: {
        path: '/aboutUs',
        component: () => <AboutUsPage />,
    },
    ContactUsPage: {
        path: '/contactUs',
        component: () => <ContactUsPage />,
    },
    TermOfUsePage: {
        path: '/termOfUse',
        component: () => <TermOfUsePage />,
    },
    PrivacyPolicyPage: {
        path: '/privacyPolicy',
        component: () => <PrivacyPolicyPage />,
    },
    DemoPage: {
        path: '/demo',
        component: () => <DemoPage />,
    },
    Dashboard: {
        path: '/dashboard',
        component: Dashboard,
    },
    MapSelector: {
        path: '/mapSelector',
        component: () => <MapSelector />,
    },
    PriceRange: {
        path: '/priceRange',
        component: () => <PriceRange />,
    },
    externalAdjacentSold: {
        path: '/externalAdjacentSold/:propId',
        component: () => <AdjacentSoldDisplay />,
    },

    PriceMap: {
        path: '/priceMap',
        component: () => <PriceMap />,
    },
    PasswordSettingPage: {
        path: '/changePassword',
        component: () => <ChangePasswordPage />,
    },
    UserInfo: {
        path: '/UserInfo',
        component: () => <UserInfoPage />,
    },
    // UserCenterPage: {
    //     path: '/UserCenterPage',
    //     component: () => <UserCenterPage />,
    // },
    UserCenter: {
        path: '/UserCenter',
        component: () => <UserCenter />,
    },
    FirstPage: {
        path: '/FirstPage',
        component: () => <HomePage />,
        // auth: true,
    },
    UserAvatarButton: {
        path: '/UserAvatarButton',
        component: () => <UserAvatarButton />,
    },
    ChangeLanguageTest: {
        path: '/ChangeLanguage',
        component: () => <LanguageDemo />,
    },
    MyFavoritePage: {
        path: '/MyFavoritePage',
        auth: true,
        component: () => <MyFavouritePage />,
    },
    PropertyReportsPage: {
        path: '/PropertyReportsPage',
        auth: true,
        component: () => <MyPropertyReportsPage />,
    },
    SearchHistoryPage: {
        path: '/SearchHistoryPage',
        auth: true,
        component: () => <SearchHistoryPage />,
    },
    MemberShipDemo: {
        path: '/MemberShipDemo',
        component: () => <MemberShipDemo />,
    },
    HouseReport: {
        path: '/HouseReport',
        component: () => <HouseReport />,
        auth: true,
    },
    // JoinMember0: {
    //     path: '/JoinMember0',
    //     component: () => <JoinMember0 />,
    // },
    // JoinMember1: {
    //     path: '/JoinMember1',
    //     component: () => <JoinMember1 />,
    // },
    // JoinMember2: {
    //     path: '/JoinMember2',
    //     component: () => <JoinMember2 />,
    // },
    // JoinMember: {
    //     path: '/JoinMember',
    //     component: () => <JoinMember />,
    // },
    JoinMember: {
        path: '/JoinMember',
        component: () => <HomePage />,
    },
    // PropertyReportResult: {
    //     path: '/PropertyReportResult',
    //     component: () => <PropertyReportResult closeReportEvt={() => {}} open={true} />,
    // },
    HomePage: {
        path: '/home',
        component: () => <HomePage />,
    },
    MobilePage: {
        path: '/mobileHome',
        component: () => <MobileHome />,
    },
    ProeprtyCard: {
        path: '/propertyCard/',
        component: () => <PropertyCard></PropertyCard>,
    },
};

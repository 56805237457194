import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { rst } from 'rt-state';
import { getWalkingTime } from '../../PriceMap/support/propertyFeatureHelper';
import { BusinessTable } from '../components/BusinessTable';
import { CardDivider } from '../components/CardDivider';
import { CardWithHeader } from '../components/CardWithHeader';
import { ContentData } from '../components/ContentData';
import { ContentTitle } from '../components/ContentTitle';
import { ContentTitleData } from '../components/ContentTitleData';
import { Glossary } from '../components/Glossary';
import { ReportMap } from '../components/reportMap/ReportMap';
import { Tips } from '../components/Tips';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '0 auto',
        },
        // wrapper: {
        //     display: 'flex',
        //     displayDirection: 'column',
        // },
        part1: {
            textAlign: 'left',
            padding: '5px 0',
        },
        part2: {
            textAlign: 'left',
            padding: '5px 0 10px 0',
        },
        part3: {
            margin: '0 auto',
        },
        part4: {
            margin: '0 auto',
        },
    };
});

export const Card25 = rst.create<{ prop: any; property: any; mobile? }>((ctx) => {
    console.log(ctx.props.prop);
    const { t } = useTranslation();

    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                {/* <CardWithHeader className={classes.wrapper}> */}
                <CardWithHeader header={props.mobile ? '' : 'Card25_header'}>
                    <Tips content="CARD25_TIPS" />
                    <div className={classes.part1}>
                        <ContentTitleData
                            header="Card25_carToCBD"
                            content={t('About') + props.property.locality.transport.drive_in_trafic + 'min'}
                        />
                    </div>
                    <CardDivider />
                    <div className={classes.part1}>
                        <ContentTitleData
                            header="Card25_busToCBD"
                            content={t('About') + props.property.locality.transport.transit_in_trafic + 'min'}
                        />
                    </div>
                    <CardDivider />
                    <div className={classes.part1}>
                        <ContentTitleData
                            header="Card25_desToCBD"
                            content={props.property.locality.cbdDistance.toFixed(1) + 'km'}
                            // content="km"
                        />
                    </div>
                    <CardDivider />
                    <div className={classes.part2}>
                        <ContentTitleData
                            header="Card25_desToStation"
                            content={t('About') + getWalkingTime(props.prop.allFeatures) + 'min'}
                        />
                    </div>
                    <div className={classes.part3} style={{ pageBreakBefore: 'always' }}>
                        <ReportMap
                            propCenter={{
                                lat: props.prop.allFeatures.prop_y,
                                lon: props.prop.allFeatures.prop_x,
                            }}
                            zoom={10}
                            drawingData={props.property.businessPark.filter((loopB) => {
                                return loopB.distance <= 15;
                            })}
                            drawingObject={'5'}></ReportMap>
                    </div>
                    <div className={classes.part4}>
                        <BusinessTable
                            BusinessParks={props.property.businessPark.filter((loopB) => {
                                return loopB.distance <= 15;
                            })}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Glossary glossaryId="35" />
                    </div>
                </CardWithHeader>
            </div>
        );
    };
});

import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../commons';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // maxWidth: '',
            // padding: '10px',
            '& h2': {
                // display: 'flex',
                // justifyContent: 'center',
                fontSize: '14px',
                color: '#B4823B',
                margin: '5px 0',
            },
        },
        arrow: {
            padding: '0px',
            margin: '0px',
            display: 'flex',
            position: 'relative',
            paddingLeft: '3px',
        },
        arrow1: {
            width: '10px',
            height: '10px',
            borderTop: '2px solid #B4823B',
            borderLeft: '2px solid #B4823B',
            transform: 'rotate(135deg)',
        },
        arrow2: {
            width: '10px',
            height: '10px',
            borderTop: '2px solid rgb(238 226 210)',
            borderLeft: '2px solid rgb(238 226 210)',
            transform: 'rotate(135deg)',
            position: 'absolute',
            left: '9px',
        },
    };
});

export const ContentTitle = rst.create<{ header?: string; hasArrow: boolean }>((ctx) => {
    return (props) => {
        const classes = useStyles();
        const { header, hasArrow } = props;
        return (
            <div className={classes.root}>
                <h2>
                    <TR name={header}></TR>
                </h2>
                {hasArrow ? (
                    <div className={classes.arrow}>
                        <div className={classes.arrow1}></div>
                        <div className={classes.arrow2}></div>
                    </div>
                ) : null}
            </div>
        );
    };
});

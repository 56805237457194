import React from 'react';
import { rst } from 'rt-state';
import { TR } from '../../../../commons';
import { EventBus, EVENT_NAMES } from '../../../../commons/EventBus';
import { AppProvider } from '../../../../commons/appProvider';
import * as locales from '@material-ui/core/locale';

export const MobileVideoCard = rst.create<{ label?: string }>((ctx) => {
    const appProvider = AppProvider.use();
    const video = appProvider.getLocale() === locales.zhCN ? '4' : '5';
    console.log('video: ', video);
    console.log('locales.zhCN: ', locales.zhCN);
    const openVideo = (videoNo) => {
        EventBus.dispatch(EVENT_NAMES.EVENT_SHOW_VIDEO_PLAYER, videoNo);
    };
    return (props) => {
        return (
            <div style={{ padding: '1.875rem 1rem 2rem' }}>
                <h3 style={{ margin: 0, fontSize: '1.375rem' }}>
                    <TR name="mobile_videoCardTitle"></TR>
                </h3>
                <div style={{ color: 'rgb(136, 136, 136)', fontSize: 13, fontWeight: 400 }}>
                    {' '}
                    <TR name="diff_pc_mobile"></TR>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        marginTop: '1.4rem',
                    }}>
                    {appProvider.getLanguage() == 'zh' ? (
                        <img src={`${process.env.PUBLIC_URL}/static/images/tp-4.png`} width="341" height="341" />
                    ) : (
                        <img src={`${process.env.PUBLIC_URL}/static/images/tp-4en.png`} width="341" height="341" />
                    )}
                    <div
                        style={{
                            position: 'absolute',
                            left: '0',
                            top: '0',
                            width: '100%',
                            height: '100%',
                            background: 'rgba(0,0,0,.3)',
                            borderRadius: '5px',
                        }}
                        onClick={() => {
                            openVideo(video);
                        }}>
                        <div
                            style={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                textAlign: 'center',
                                color: '#fff',
                                transform: 'translate(-50%,-50%)',
                            }}>
                            <img
                                style={{
                                    opacity: 0.5,
                                }}
                                src={`${process.env.PUBLIC_URL}/static/images/mobile-videoPause.png`}
                                alt=""
                            />
                            <p style={{ fontSize: '1rem' }}>
                                <TR name={'mobile_videoTip'}></TR>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
});

import React from 'react';
import { rst } from 'rt-state';
import { SecurityProvider, TR } from '../../../../commons';
import { AppProvider } from '../../../../commons/appProvider';
import { Permission } from '../../../../commons/security/authz';
import { convertToM } from '../../../../commons/utils';
import { CardPermission } from '../cardPermission';
import { CardTitle } from './cardTitle';

export const SoldHistoryCard = rst.create((ctx: any) => {
    const state = rst.state({
        closeContent: false,
    });
    const closeContentEvt = () => {
        state.closeContent = !state.closeContent;
    };

    return (props: any) => {
        const { soldHistory, inSummary } = props;

        const presentHistory = (summary) => {
            const content: any = [];
            if (soldHistory.length == 0) {
                content.push(
                    <div
                        style={{
                            fontSize: '16px',

                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',

                            width: '98%',
                        }}>
                        <TR name="noSoldRecord"></TR>
                    </div>,
                );
            } else {
                if (!inSummary) {
                    soldHistory.sort((y, x) => +new Date(x.sold_contract_date) - +new Date(y.sold_contract_date));
                    soldHistory.forEach((history: any, idx: any) => {
                        if ((summary && idx == 0) || !summary) {
                            content.push(
                                <div
                                    style={{
                                        display: 'flex',
                                        fontSize: '13px',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}>
                                    <div style={{ fontSize: '13px', flex: 2 }}>{history.sold_contract_date} </div>
                                    <div style={{ flex: 1 }}>${convertToM(history.sold_price)}m</div>
                                </div>,
                            );
                        }
                    });
                } else {
                    soldHistory.forEach((history: any, idx: any) => {
                        if ((summary && idx == 0) || !summary) {
                            content.push(
                                <div
                                    style={{
                                        display: 'flex',
                                        fontSize: '13px',

                                        width: '100%',
                                    }}>
                                    <div style={{ flex: 2 }}>
                                        <span style={{ fontSize: '18px', fontWeight: 700 }}>
                                            ${convertToM(history.sold_price)}m
                                        </span>
                                        <div style={{ marginTop: 6, color: '#888888' }}>
                                            <TR name="last_sold_price"></TR>
                                        </div>
                                    </div>
                                    <div style={{ position: 'relative', fontSize: '13px', flex: 1 }}>
                                        <span style={{ fontSize: '18px', fontWeight: 700 }}>
                                            {history.sold_contract_date}
                                        </span>
                                        <div style={{ marginTop: 6, color: '#888888' }}>
                                            <TR name="last_sold_date"></TR>
                                        </div>{' '}
                                    </div>
                                </div>,
                            );
                        }
                    });
                }
            }

            return content;
        };

        return (
            <div style={{ position: 'relative', width: '100%', padding: inSummary ? 0 : '10px' }}>
                {!inSummary ? (
                    <CardTitle
                        noSwitch={soldHistory.length == 1}
                        closeContent={state.closeContent}
                        closeEvt={closeContentEvt}
                        title={'Sold History'}></CardTitle>
                ) : null}

                <div style={{ position: 'relative' }}>
                    {/* {inSummary ? null : <CardPermission cardType="soldHistory"></CardPermission>} */}
                    {presentHistory(inSummary)}
                </div>
            </div>
        );
    };
});

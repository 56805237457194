import classes from '*.module.css';
import { Box, makeStyles, Slider } from '@material-ui/core';
import React from 'react';
import { rst, state } from 'rt-state';
import { clsx, generateGUID, TR } from '../../../commons';
import { EventBus, EVENT_NAMES } from '../../../commons/EventBus';
import { isMobileDevice } from '../../../commons/utils';

const useStyles = makeStyles((theme) => {
    return {
        root: {},
        periodDiv: {
            background: 'white',
            borderRadius: 14,
            //padding: '6px 8px',
            opacity: 0.8,
            fontSize: isMobileDevice() ? 11 : 14,
            alignItems: 'center',
            display: 'flex',
            marginLeft: isMobileDevice() ? -15 : -15,
        },
        periodLabel: {
            fontSize: isMobileDevice() ? 10 : 12,
        },
        periodDropdown: {
            fontSize: 10,
        },
    };
});

export const ProeprtyPeriodDropdown = rst.create<{ changeInterval: any }>((ctx) => {
    const changePeriod = (evt) => {
        ctx.props.changeInterval(evt.target.value);
    };

    return (props) => {
        const classes = useStyles();

        return (
            <select onChange={changePeriod} className={classes.periodDropdown}>
                <option value="12">12 Months </option>
                <option value="6">6 Months</option>
            </select>
        );
    };
});

export const PropertyPeriodChooser = rst.create((ctx) => {
    const choosedPeriod = rst.stateS(6);
    const periodGroupName = generateGUID();
    const changePeriod = (value) => {
        choosedPeriod.value = value;
        choosedPeriod.forceUpdate();

        EventBus.dispatch(EVENT_NAMES.EVENT_CHANGE_PERIOD, { period: value });
    };
    return (props) => {
        const classes = useStyles();
        return (
            <div className={clsx(isMobileDevice() ? classes.root : '', classes.periodDiv)}>
                <PeriodChooseItem
                    name={periodGroupName}
                    label="12Months"
                    value={12}
                    checkEvent={changePeriod}
                    selected={choosedPeriod.value == 12}></PeriodChooseItem>
                <div style={{ marginLeft: 5 }}></div>
                <PeriodChooseItem
                    label="6Months"
                    name={periodGroupName}
                    value={6}
                    checkEvent={changePeriod}
                    selected={choosedPeriod.value == 6}></PeriodChooseItem>
                <div style={{ marginLeft: 5 }}></div>
                <PeriodChooseItem
                    label="3Months"
                    name={periodGroupName}
                    value={3}
                    checkEvent={changePeriod}
                    selected={choosedPeriod.value == 3}></PeriodChooseItem>
            </div>
        );
    };
});

export const PeriodChooseItem = rst.create<{
    name: string;
    label: string;
    value: number;
    selected?: boolean;
    checkEvent?: any;
}>((ctx) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    type="radio"
                    name={props.name}
                    onChange={() => {
                        if (props.checkEvent) {
                            props.checkEvent(props.value);
                        }
                    }}
                    checked={props.selected}></input>{' '}
                <span className={classes.periodLabel}>
                    <TR name={props.label}></TR>
                </span>
            </div>
        );
    };
});

import i18next from 'i18next';
import * as locales from '@material-ui/core/locale';

import { storage } from '../storage';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export async function initI18n() {
    const randomVer = Math.random();
    await i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            preload: ['en', 'zh'],
            ns: ['translation'],
            defaultNS: 'translation',
            backend: {
                loadPath: `${process.env.PUBLIC_URL}/static/locales1/{{lng}}/{{ns}}.json?ver=` + randomVer,
            },
            keySeparator: false,
            interpolation: {
                escapeValue: false,
            },
        });
}

export const initLanguage = async (language: string) => {
    const preferredLanguage = language ?? (await getLocaleFromStorage());
    if (i18next.language !== preferredLanguage) {
        await i18next.changeLanguage(preferredLanguage);
    }
    let locale;
    switch (preferredLanguage) {
        case 'zh':
            locale = locales.zhCN;
            break;
        default:
            locale = locales.enUS;
    }
    return locale;
};

const getLocaleFromStorage = async (): Promise<string> => {
    let lanInStorage = (await storage.get('CLIENT_LAN')) as string;
    if (!lanInStorage) {
        lanInStorage = 'en';
    }
    return lanInStorage;
};

export const changeLocale = async (locale: locales.Localization) => {
    let language = 'unknown';
    switch (locale) {
        case locales.zhCN:
            language = 'zh';
            break;
        default:
            language = 'en';
    }
    await i18next.changeLanguage(language);
    await storage.save('CLIENT_LAN', language);
};
